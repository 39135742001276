import React, { useState } from "react";
import axios from "axios";
import "./styles.scss";
import { AutoComplete, Pagination } from "antd";

const Index = (props) => {
  return (
    <>
      <div className={"d-flex flex-row"}>
        {/* <Pagination
          defaultCurrent={1}
          total={50}
          onChange={onChange}
          onclick={onclick}
        /> */}

        <Pagination
          defaultCurrent={1}
          total={props.total || 100}
          pageSize={props.pageSize}
          onChange={props.onChange}
          onclick={props.onclick}
        />
      </div>
    </>
  );
};

export default Index;
