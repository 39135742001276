import axios from "axios";
import * as mockResponses from "../mock_data/apiResponses";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const saveCategory = async (categoryName, tags, iconName) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      categoryName: categoryName,
      tags: tags,
      iconName: iconName,
    };

    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/category`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_CATEGORY_API:", err);
    throw err;
  }
};

export const getCategory = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/categories`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_CATEGORY_API:", err);
    throw err;
  }
};
