import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {
  ButtonIcon,
  CustomTag,
  InfoRowView,
  SelectBox,
  Tag,
} from "../../index";
import moment from "moment";
import { OpenNotification } from "../../../config/notification";
import { GetIcon } from "../../../config/icon";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../store/actions/types";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { bankData } = useSelector(({ bankReducer }) => bankReducer);
  const { isSelectedExistingTerms } = useSelector(
    ({ termsReducer }) => termsReducer
  );
  const dispatch = useDispatch();

  const handleToggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const confirm = (e) => {
    if (props.numOfOfferByMerchant > 0) {
      OpenNotification(
        "Unable to Delete Offer",
        "It seems there's an issue with deleting the offer.",
        "success"
      );
    } else {
      // delete
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const handleBankChange = (bankId) => {
    console.log("handleBankChange", props?.termsAndCondition[bankId]);
    if (!selectedBankId) {
      setSelectedBankId(bankId);
      dispatch({
        type: actionTypes.SELECTED_EXISTING_TERMS,
        payload: { terms: props?.termsAndCondition[bankId], bankId: bankId },
      });
    } else {
      setSelectedBankId(null);
      dispatch({
        type: actionTypes.REMOVE__SELECTED_EXISTING_TERMS,
        payload: null,
      });
    }
  };

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  const { TabPane } = Tabs;
  return (
    <>
      <div
        className={`cco__merchant-detail-card__wrapper sm d-flex flex-row justify-content-between ${
          props.isActive ? "active-me" : ""
        } ${props.cardType}`}
      >
        <div className={"d-flex flex-row"}>
          <div className={"img-wrapper mdcw__merchant-log sm"}>
            <img src={props.logoURL} alt="creditcard offers" />
            {props.cardType === "category" && (
              <div className={"cco__mdcw__category-icon"}>
                {GetIcon(props?.iconName)}
              </div>
            )}
          </div>
          <div className={"d-flex flex-column"} style={{ paddingLeft: "12px" }}>
            <div className={"cco__mdcw__details sm"}>{props.merchantName}</div>

            <div className={"cco__mdcw__name sm"}>
              <a
                href={props.webURL}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                {props.webURL}
              </a>
            </div>
            <div className={"cco__mdcw__tags d-flex flex-row"}>
              <Tag type={"pending"} tagName={props?.status} />
              {props.cardType === "category" && (
                <Tag type={"default"} tagName={props?.iconName} />
              )}
            </div>
            <div className={"cco__mdcw__time"}>
              {moment(props.createdAt).startOf("day").fromNow()}
            </div>

            {props.location && (
              <div className={"cco__mdcw__time sm d-flex"}>
                {(props?.location || []).map((item, index) => (
                  <CustomTag
                    key={index}
                    manual={index}
                    remove={false}
                    format={"text"}
                    style={"light sm"}
                    text={item.tagName}
                  />
                ))}
              </div>
            )}

            <div className={"cco__mdcw__terms_count sm d-flex mt-1"}>
              {props.termsAndCondition && (
                <>
                  {isArray(props.termsAndCondition) ? (
                    <div className={"terms-old"}>
                      No.of.terms - {props.termsAndCondition?.length}
                    </div>
                  ) : (
                    <div className={"terms-update"}>
                      No.of.terms -{" "}
                      {Object.keys(props.termsAndCondition)?.length}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className={"merchant-details-action"}>
          <ButtonIcon
            onClick={handleToggleExpand}
            buttonIcon={GetIcon(isExpand ? "arrowUp" : "arrowDown")}
            type={"collapse-icon"}
            size={"lg"}
          />
        </div>
      </div>
      {isExpand && (
        <div className="expanded-content">
          {/* Content to show when expanded */}
          <div className={"list-view-terms_condition mt-3"}>
            {isArray(props.termsAndCondition) ? (
              <>
                {props.termsAndCondition &&
                  (props.termsAndCondition || []).map((item, index) => (
                    <InfoRowView
                      type="default"
                      text={item.value}
                      isSelect={item.isActive}
                      style={"mb-1 justify-content-between align-items-center"}
                    />
                  ))}
              </>
            ) : (
              <div>
                <div className={"cco__mtc-tab mt-2"}>
                  <Tabs defaultActiveKey="1">
                    {props.termsAndCondition &&
                      Object.keys(props.termsAndCondition)?.map((bankId) => (
                        <TabPane tab={`${getBankName(bankId)}`} key={bankId}>
                          <div
                            className={`cco__terms-tab-wrapper ${
                              selectedBankId === bankId
                                ? "selected-terms"
                                : null
                            }`}
                          >
                            <div className={"cco__terms_selection-wrapper"}>
                              <SelectBox
                                index={bankId}
                                remove={false}
                                isSelect={selectedBankId === bankId}
                                select={true}
                                onClick={() => handleBankChange(bankId)}
                                // onRemove={() =>
                                //     //this.onSelectLocation(item, index)
                                // }
                                format={"text"}
                                text={getBankName(bankId)}
                              />
                            </div>
                            {props.termsAndCondition[bankId].map((term) => (
                              <InfoRowView
                                key={term.id}
                                type="default"
                                text={term.value}
                                isSelect={term.isActive}
                                style={
                                  "mb-1 justify-content-between align-items-center"
                                }
                              />
                            ))}
                          </div>
                        </TabPane>
                      ))}
                  </Tabs>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
