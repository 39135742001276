import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  data: null,
  users: [],
  isLogin: false,
  privateUser: [],
  error: null,
  loading: false,
  isPrivateUser: false,
  userData: null,
  selectUserData: null,
  refreshUsers: false,
};
let newData = [];

function $set(...objects) {
  return Object.assign({}, ...objects);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case actionTypes.GET_USER_DETAILS:
      return {
        ...state,
        error: null,
        isLogin: true,
        data: action.data,
        loading: false,
      };
    case actionTypes.ADD_LOGIN_USER:
      console.log("ADD_LOGIN_USER", action.payload);
      return {
        ...state,
        error: null,
        isLogin: true,
        data: action.payload,
        loading: false,
      };
    case actionTypes.SET_SELECT_USER_DATA:
      console.log("SET_USER_DATA", action.payload);
      return {
        ...state,
        selectUserData: action.payload,
      };
    case actionTypes.CLEAR_SELECT_USER_DATA:
      console.log("SET_USER_DATA", action.payload);
      return {
        ...state,
        selectUserData: null,
      };
    case actionTypes.REFRESH_USER_LIST:
      return {
        ...state,
        refreshUsers: true,
      };
    case actionTypes.DONE_REFRESH_USERS:
      return {
        ...state,
        refreshUsers: false,
      };
    case actionTypes.GET_USER_BY_TYPE_PENDING:
      return {
        ...state,
        error: null,
        users: [],
        loading: true,
      };
    case actionTypes.GET_USER_BY_TYPE:
      return {
        ...state,
        error: null,
        users: action.data,
        loading: false,
      };
    case actionTypes.GET_USER_BY_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        users: [],
        loading: false,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        error: null,
        users: [],
        isLogin: false,
        loading: false,
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_PENDING:
      return {
        ...state,
        error: null,
        privateUser: [],
        loading: true,
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID:
      console.warn(actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID);
      return {
        ...state,
        error: null,
        privateUser: action.data,
        loading: false,
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        privateUser: [],
        loading: false,
      };

    case actionTypes.UPDATE_PRIVATE_USER_PROFILE_IMAGE:
      console.warn(actionTypes.UPDATE_PRIVATE_USER_PROFILE_IMAGE);
      console.log(action);
      console.log(state.privateUser);
      state.privateUser.logoUrl = action.imageId;
      console.log(state.privateUser.logoUrl);
      return {
        ...state,
        error: null,
        privateUser: state.privateUser,
        loading: false,
      };
    default:
      return state;
  }
};
