import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import moment from "moment";
import { Button, ButtonIcon, InfoRowView, Tag } from "../..";
import { GetIcon } from "../../../config/icon";
import { Popconfirm, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteOffer, deleteOfferTerms } from "../../../api";
import { OpenNotification } from "../../../config/notification";
import * as actionTypes from "../../../store/actions/types";
import { deleteDraft } from "../../../api/draft";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { bankData } = useSelector(({ bankReducer }) => bankReducer);
  const dispatch = useDispatch();

  const handleToggleExpand = () => {
    setIsExpand(!isExpand);
    let bankId =
      props?.termsAndCondition &&
      Object.keys(props?.termsAndCondition).map((x) => x);
    console.log({ bankId });
    setSelectedBankId(bankId && bankId[0]);
  };

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };
  const onClickDeleteOffer = async (offerId) => {
    if (offerId) {
      let res = await deleteOffer(offerId);
      console.log("deleteOffer:::", res);
      if (res && res.data && res.data.status) {
        OpenNotification(res?.data?.message);

        dispatch({
          type: actionTypes.REFRESH_OFFERS_LIST,
        });
      }
    }
  };

  const onClickDeleteDraft = async (draftId) => {
    if (draftId) {
      let res = await deleteDraft(draftId);
      console.log("deleteOffer:::", res);
      if (res && res.data && res.data.status) {
        OpenNotification(res?.data?.message);

        dispatch({
          type: actionTypes.REFRESH_OFFERS_LIST,
        });
      }
    }
  };

  const onClickDeleteTerms = async (offerId) => {
    if (selectedBankId) {
      let res = await deleteOfferTerms(offerId, selectedBankId);

      if (res?.data?.status) {
        OpenNotification(res?.data?.message);

        dispatch({
          type: actionTypes.REFRESH_MERCHANT_LIST,
        });
      }
    }
  };

  const correctTimeZone = (createAtUTC) => {
    // Convert the UTC time to Sri Lankan time (UTC+05:30)
    const createAtSL = moment(createAtUTC).utcOffset("+05:30");

    // Display time from now (e.g., "3 days ago")
    const timeFromNow = createAtSL.fromNow();

    return timeFromNow;
  };

  const { TabPane } = Tabs;

  return (
    <div className={`${isExpand ? "offer-expanded" : ""}`}>
      <div
        className={`cco__offer-card__wrapper d-flex flex-row ${
          props.isActive ? "active" : ""
        } ${
          props.type === "expire" ? (props.expireDays < 0 ? "expired" : "") : ""
        }`}
      >
        {/* <div className={`cco__ocw-image`}> */}
        <div className={"img-wrapper"}>
          <img src={props.imgUrl} alt="creditcard offers" />
        </div>
        <div className={"cco__ocw__logo"}>
          <img src={props.logo} alt="creditcard offers" />
        </div>
        {/* </div> */}
        <div
          className={
            "cco__ocw-body d-flex flex-row justify-content-between align-items-center"
          }
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column"}>
            <div className={"cco__ocw__details"}>{props.details}</div>
            <div className={"cco__ocw__name"}>{props.name}</div>
            <div className={"cco__ocw__tags d-flex flex-row"}>
              <Tag
                type={
                  props.status === "new"
                    ? "new"
                    : props.status === "Accepted" || props.status === "accepted"
                    ? "accept"
                    : props.status === "Rejected" || props.status === "rejected"
                    ? "reject"
                    : "new"
                }
                tagName={props.status}
              />
              <Tag type={"username"} tagName={props.userName} />
              <Tag type={"companyName"} tagName={props.bankName} />
              <Tag type={"companyName"} tagName={props.categoryName} />

              {props.type != "expire" && (
                <>
                  {props.expireDays < 0 ? (
                    <>
                      <Tag type={"new"} tagName={"Expired"} />
                    </>
                  ) : (
                    <>
                      <Tag type={"expired"} tagName={"Expired Soon"} />
                    </>
                  )}
                </>
              )}
            </div>
            {props.type === "expire" && (
              <div className={"cco__ocw__time"}>
                {correctTimeZone(props.createAt)} |
                {/* {" "}
                <span className={"cco__ocwt-endDate"}>
                  End Date {props.offerEndDate}
                </span>
                | */}
                {props.expireDays < 0 ? " expired " : " expires "}
                {correctTimeZone(props.offerEndDate)}
              </div>
            )}
            {props.type != "expire" && (
              <div className={"cco__ocw__time"}>
                {correctTimeZone(props.createAt)} |{" "}
                <span className={"cco__ocwt-endDate"}>
                  End Date {props.offerEndDate}
                </span>
              </div>
            )}
            {props.offerId && (
              <div
                className="cco__offer-url"
                onClick={() => {
                  props.offerId &&
                    window.open(
                      "https://creditcardoffers.lk/offer-view/" +
                        props.offerId +
                        "/" +
                        props.bankName +
                        "/" +
                        props.categoryName,
                      "_blank"
                    );
                }}
              >
                Preview
              </div>
            )}
          </div>
          {props.type === "expire" && (
            <div className={"cco__ocwb__menu"}>
              <div className={"card-action-wrapper d-flex"}>
                <div className={"mr-1"}>
                  <Button
                    text={"Expire soon"}
                    onClick={props.onClickExpireSoon}
                    type={"btn-edit"}
                  />
                </div>
                <div className={"mr-1"}>
                  <Button
                    text={"More"}
                    onClick={props.onClickViewOffer}
                    type={"btn-edit"}
                  />
                </div>
                <div className={"mr-1"}>
                  <Button
                    text={"Expired"}
                    onClick={props.onClickExpired}
                    type={"btn-delete"}
                  />
                </div>
              </div>
            </div>
          )}
          {props.type !== "view" && props.type != "expire" && (
            <div className={"cco__ocwb__menu"}>
              <div className={"card-action-wrapper d-flex"}>
                {/*<div className={"mr-1"}>*/}
                {/*  {props.status === "New" && (*/}
                {/*    <Button*/}
                {/*      text={"Approve"}*/}
                {/*      onClick={props.onClickViewOffer}*/}
                {/*      type={"btn-edit"}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*</div>*/}
                <div className={"mr-1"}>
                  <Button
                    text={"Edit"}
                    onClick={props.onClickEdit}
                    type={"btn-edit"}
                  />
                </div>
                <div className={"mr-1"}>
                  <Button
                    text={"More"}
                    onClick={props.onClickViewOffer}
                    type={"btn-edit"}
                  />
                </div>
                <Popconfirm
                  title={
                    props.type != "draft"
                      ? "Delete this offer?"
                      : "Delete this draft?"
                  }
                  description={
                    props.type != "draft"
                      ? "Are you sure to delete this offer?"
                      : "Are you sure to delete this draft?"
                  }
                  placement="topRight"
                  onConfirm={async () => {
                    if (props.offerId && props.type != "draft") {
                      await onClickDeleteOffer(props.offerId);
                    } else if (props.draftId) {
                      await onClickDeleteDraft(props.draftId);
                    }
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    text={"Delete"}
                    onClick={() => {}}
                    type={"btn-delete"}
                  />
                </Popconfirm>
                {/*<Dropdown overlay={menu}>*/}
                {/*<span className={"ml-1 pass-info"}>*/}
                {/*  <RiMore2Fill />*/}
                {/*</span>*/}
                {/*</Dropdown>*/}
              </div>
              {/* <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <span className={"ml-1 pass-info"}>
              <RiMore2Fill />
            </span>
          </Dropdown> */}
            </div>
          )}
          {props.type === "view" && (
            <div className={"cco__ocwb__menu"}>
              <div className={"card-action-wrapper d-flex"}>
                <Button
                  text={"More"}
                  onClick={props.onClickViewOffer}
                  type={"btn-edit"}
                />
              </div>
            </div>
          )}
          {props.isCollapse && (
            <div className={"cco__ocwb__menu"}>
              <ButtonIcon
                onClick={handleToggleExpand}
                buttonIcon={GetIcon(isExpand ? "arrowUp" : "arrowDown")}
                type={"collapse-icon"}
                size={"lg"}
              />
            </div>
          )}
        </div>
      </div>
      <>
        {isExpand && (
          <div className="expanded-content">
            <div className="coo__merchant-dc_delete-btn">
              <Popconfirm
                title={
                  "Remove this " + getBankName(selectedBankId) + "'s terms?"
                }
                description={
                  "Are you sure to remove this " +
                  getBankName(selectedBankId) +
                  "'s terms?"
                }
                placement="topRight"
                onConfirm={async () => {
                  if (props.offerId) {
                    await onClickDeleteTerms(props.offerId);
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  text={
                    selectedBankId
                      ? "Delete (" + getBankName(selectedBankId) + ")"
                      : "Delete"
                  }
                  type={"btn-edit mt-2"}
                  isLoading={false}
                  onClick={() => {}}
                  size={"sm primary"}
                />
              </Popconfirm>
            </div>
            {/* Content to show when expanded */}
            <div className={"list-view-terms_condition mt-3"}>
              {isArray(props.termsAndCondition) ? (
                <>
                  {props.termsAndCondition &&
                    (props.termsAndCondition || [])?.map((item, index) => (
                      // <InfoRowView
                      //   type="default"
                      //   text={item.value}
                      //   isSelect={item.isActive}
                      //   style={
                      //     "mb-1 justify-content-between align-items-center"
                      //   }
                      // />
                      <InfoRowView
                        type={"terms"}
                        text={item.value}
                        isSelect={item.isActive}
                        style={
                          "mb-1 justify-content-between align-items-center"
                        }
                        onClickConfirm={item.onClickDelete}
                        onClick={item.onClick}
                        onClickEdit={item.onClickEdit}
                      />
                    ))}
                </>
              ) : (
                <div>
                  <div className={"cco__mtc-tab mt-2"}>
                    <Tabs
                      defaultActiveKey="1"
                      onChange={(bankId) => {
                        setSelectedBankId(bankId);
                      }}
                    >
                      {Object.keys(props?.termsAndCondition).map((bankId) => (
                        <TabPane tab={`${getBankName(bankId)}`} key={bankId}>
                          <div className={`cco__terms-tab-wrapper `}>
                            <div
                              className={"cco__terms_selection-wrapper"}
                            ></div>
                            {props?.termsAndCondition[bankId].map((term) => (
                              // <InfoRowView
                              //   key={term.id}
                              //   type="default"
                              //   text={term.value}
                              //   isSelect={term.isActive}
                              //   style={
                              //     "mb-1 justify-content-between align-items-center"
                              //   }
                              // />
                              <InfoRowView
                                key={term.id}
                                type={"terms"}
                                text={term.value}
                                isSelect={term.isActive}
                                style={
                                  "mb-1 justify-content-between align-items-center"
                                }
                                onClickConfirm={term.onClickDelete}
                                onClick={term.onClick}
                                onClickEdit={term.onClickEdit}
                              />
                            ))}
                          </div>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
