import React from "react";
import "./styles.scss";
import { GetIcon } from "../../../config/icon";
import { TbChevronCompactRight, TbChevronRight } from "react-icons/tb";

const Index = (props) => (
  <>
    <div
      className={`menu-card-new-wrapper d-flex flex-row justify-content-between align-items-center ${
        props.isActive ? "active" : ""
      } ${props.type} ${props.size}`}
      onClick={props.onClick}
    >
      <div className={"d-flex flex-row align-items-center"}>
        <div className={`mcnw__icon`}>{props.icon}</div>
        <div className={"mcnw__text"}>{props.text}</div>
      </div>
      <div className={`mcnw__navigation`}>{<TbChevronRight />}</div>
    </div>
  </>
);
export default Index;
