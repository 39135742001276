import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../../../../store/actions/types";
import { Spin } from "antd";
import { userDetails, userLogOut } from "../../../../../utils";
import {
  getAdminUser,
  getOffer,
  getOfferByUser,
  getSessionValidateStatus,
} from "../../../../../api";
import { Button, OfferCard, PaymentOfferCard } from "../../../../../components";
import { v4 as uuidv4 } from "uuid";

class Rejected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      userDataSource: [],
      categoryOptions: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
      dataForm: {
        searchOffer: {
          key: "searchOffer",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search offer title here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        keywords: {
          key: "keywords",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "Search Merchant here...",
            options: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Pending Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Active Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 3,
          tabName: "Rejected Offers",
          content: "",
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  // async getAll() {
  //   this.setState({ isLoading: true });
  //   let res = await getOffer();

  //   const offersData = res && res.data;
  //   console.log({ res });
  //   let newData = null;
  //   let indices = null;
  //   if (offersData) {
  //     indices = offersData.map((_, index) => index);

  //     indices.sort((a, b) => b - a);
  //   }

  //   newData = indices.map((index) => offersData[index]);
  //   this.setState({
  //     dataSource: newData,
  //     isLoading: false,
  //   });
  // }

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true });
      let user = await userDetails();
      if (user && user.userId) {
        let resOffer = await getOfferByUser(user.userId);
        let resUser = await getAdminUser();
        const offersData = resOffer && resOffer.data;
        let newData = null;
        let indices = null;
        if (offersData) {
          indices = offersData.map((_, index) => index);

          indices.sort((a, b) => b - a);
        }

        newData = indices.map((index) => offersData[index]);
        this.setState({
          dataSource: newData,
          userDataSource: resUser && resUser.data,
          isLoading: false,
        });
      }
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  pushAcceptedItems = async () => {
    const acceptedItems = this.state.dataSource
      .filter((item) => item.status === "accepted")
      .slice(0, 10);
    this.setState((prevState) => ({
      acceptedOffers: [...prevState.acceptedOffers, ...acceptedItems],
      pageNo: prevState.pageNo + 1,
    }));
  };

  pushPendingItems = async () => {
    const acceptedItems = this.state.dataSource
      .filter((item) => item.status === "new")
      .slice(0, 10);
    this.setState((prevState) => ({
      pendingOffers: [...prevState.pendingOffers, ...acceptedItems],
      pendingPageNo: prevState.pendingPageNo + 1,
    }));
  };

  pushRejectedItems = async () => {
    const acceptedItems = this.state.dataSource
      .filter((item) => item.status === "accepted")
      .slice(0, 10);
    this.setState((prevState) => ({
      rejectedOffers: [...prevState.rejectedOffers, ...acceptedItems],
      rejectPageNo: prevState.rejectPageNo + 1,
    }));
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.dataSource !== this.state.dataSource) {
      const { userData } = this.props;
      let offerList = this.state.dataSource;
      let pendingOffers = [];
      let acceptedOffers = [];
      let rejectedOffers = [];
      let acceptedTotalCount = 0;
      let rejectedTotalCount = 0;
      let pendingTotalCount = 0;
      if (offerList.length > 0) {
        const isAdmin = userData?.userType === "admin";
        const addedPendingOffers = new Set();
        const addedAcceptedOffers = new Set();
        const addedRejectedOffers = new Set();

        offerList.forEach((item) => {
          if (isAdmin) {
            if (
              item.status === "new" &&
              pendingOffers.length < 10 &&
              !addedPendingOffers.has(item.offerId)
            ) {
              pendingOffers.push(item);
              addedPendingOffers.add(item.offerId);
            } else if (
              item.status === "accepted" &&
              acceptedOffers.length < 10 &&
              !addedAcceptedOffers.has(item.offerId)
            ) {
              acceptedOffers.push(item);
              addedAcceptedOffers.add(item.offerId);
            } else if (
              item.status === "rejected" &&
              rejectedOffers.length < 10 &&
              !addedRejectedOffers.has(item.offerId)
            ) {
              rejectedOffers.push(item);
              addedRejectedOffers.add(item.offerId);
            }
          } else if (item.userId === userData?.userId) {
            if (
              item.status === "new" &&
              pendingOffers.length < 10 &&
              !addedPendingOffers.has(item.offerId)
            ) {
              pendingOffers.push(item);
              addedPendingOffers.add(item.offerId);
            } else if (
              item.status === "accepted" &&
              acceptedOffers.length < 10 &&
              !addedAcceptedOffers.has(item.offerId)
            ) {
              acceptedOffers.push(item);
              addedAcceptedOffers.add(item.offerId);
            }
          }
        });

        acceptedTotalCount = offerList.filter(
          (item) => item.status === "accepted"
        ).length;
        rejectedTotalCount = offerList.filter(
          (item) => item.status === "rejected"
        ).length;
        pendingTotalCount = offerList.filter(
          (item) => item.status === "new"
        ).length;
      }
      // console.log({ pendingOffers, acceptedOffers });

      this.setState((prevState) => ({
        ...prevState,
        pendingOffers: pendingOffers,
        acceptedOffers: acceptedOffers,
        rejectedOffers,
        acceptedTotalCount,
        rejectedTotalCount,
        pendingTotalCount,
      }));
    }
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      this.setState({ isLoading: true });
      await this.getAll();
      if (!this.state.isLoading) {
        this.props.doneRefresh();
      }
    }
  }

  render() {
    const {
      dataSource,
      tags,
      viewPointHeight,
      pendingOffers,
      acceptedOffers,
      isLoading,
      rejectedOffers,
      userDataSource,
      categoryOptions,
      dataForm,
    } = this.state;
    const {} = this.props;

    return (
      <>
        <div
          style={{
            paddingBottom: "24px",
          }}
        >
          {isLoading && (
            <div style={{ margin: "10% 50%" }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
          {!isLoading && (
            <>
              {/* Rejected offers */}
              <div>
                <Scrollbars
                  style={{ height: viewPointHeight - 210 }}
                  thumbMinSize={30}
                  universal={true}
                  autoHide
                >
                  {rejectedOffers && rejectedOffers.length > 0 && (
                    <>
                      {rejectedOffers
                        .filter((item) => {
                          if (dataForm.searchOffer.value === "") {
                            return item; // Corrected the return statement
                          } else if (
                            item.header
                              ?.toLowerCase()
                              .includes(
                                dataForm.searchOffer.value.toLowerCase()
                              )
                          ) {
                            return item; // Corrected the return statement
                          }
                        })
                        .map((item, index) => (
                          <PaymentOfferCard
                            details={item && item.header}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() => {
                              this.props.onClickOpenOfferView(item);

                              this.props.onClickClosePayment();
                            }}
                          />
                        ))}
                    </>
                  )}
                  {!isLoading &&
                    this.state.rejectedTotalCount -
                      this.state.rejectPageNo * this.state.pageViewCount >
                      0 && (
                      <div
                        className={
                          "console__load-more-wrp d-flex justify-content-center mt-3 "
                        }
                      >
                        <Button
                          size={"sm"}
                          type={"btn-primary light"}
                          isLoading={isLoading}
                          onClick={async () => {
                            if (dataSource?.length > 0) {
                              await this.pushRejectedItems();
                            }
                          }}
                          text={`Load more offers (${
                            this.state.rejectedTotalCount -
                            this.state.rejectPageNo * this.state.pageViewCount
                          })`}
                        />
                      </div>
                    )}
                  {this.state.isLoading && (
                    <div style={{ margin: "0 50%", opacity: 0.5 }}>
                      <Spin size="large" spinning={isLoading} />
                    </div>
                  )}
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickClosePayment: () =>
      dispatch({ type: actionTypes.CLOSE_PAYMENT_MODAL, status: false }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rejected);
