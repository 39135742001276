import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

import "./styles.scss";
import {
  Button,
  MerchantDetailCard,
  PageHeader,
  Pagination,
} from "../../components";
import { AutoComplete, Image, Space, Spin } from "antd";
import {
  cooFindMerchantByName,
  cooGetAllMerchantByPage,
  cooGetMerchantOfferCount,
} from "./service/merchant.service";
import * as actionTypes from "../../store/actions/types";
import { cooGetOfferByMerchant } from "../modal/ViewMerchantOffers/service/viewMerchantOffers.service";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      activeMerchant: null,
      selectedMerchantID: null,
      selectedMerchant: null,
      dataSource: [],
      pageNo: 1,
      totalCount: 0,
      pageViewCount: 10,
      hasMore: true,
      suggestions: [],
      option: [],
      dataForm: {
        searchMerchant: {
          key: "searchMerchant",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    this.getAll();
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  // async getAll() {
  //   let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 10 });
  //   this.setState({
  //     dataSource: res && res.data && res.data.data,
  //     isLoading: false,
  //   });
  // }

  async onChangePage(pageNo, pageSize) {
    if (this.state.isLoading || !this.state.hasMore) return;

    this.setState({ isLoading: true, pageViewCount: pageSize });

    try {
      let res = await cooGetAllMerchantByPage({
        pageNo: pageNo,
        pageSize: pageSize,
      });
      console.log("cooGetAllMerchantByPage", res.data);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        this.setState((prevState) => ({
          dataSource: [...prevState.dataSource, ...res.data.data],
          pageNo: pageNo + 1,
          totalCount: res.data.totalCount,
          isLoading: false,
        }));
      } else {
        this.setState({ hasMore: false, isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  async getAll() {
    if (this.state.isLoading || !this.state.hasMore) return;

    this.setState({ isLoading: true });

    try {
      let res = await cooGetAllMerchantByPage({
        pageNo: this.state.pageNo,
        pageSize: 10,
      });
      console.log("cooGetAllMerchantByPage", res.data);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        this.setState((prevState) => ({
          dataSource: [...prevState.dataSource, ...res.data.data],
          pageNo: prevState.pageNo + 1,
          totalCount: res.data.totalCount,
          isLoading: false,
        }));
        this.props.doneEditMerchant();
      } else {
        this.setState({ hasMore: false, isLoading: false });

        // === to do ====
        // OpenNotification({
        //   title: "Refresh",
        //   message: "Refresh complete",
        //   type: "success",
        //   container: "top-center",
        // });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshMerchant !== this.props.refreshMerchant) {
      if (this.props.refreshMerchant) {
        this.setState({ isLoading: true });
        await this.getAll();
        if (this.state.dataForm.searchMerchant.value != "") {
          await this.fetchSuggestions(this.state.dataForm.searchMerchant.value);
        }
        if (!this.state.isLoading) {
          this.props.doneEditMerchant();
        }
      }
    }
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  autoCompleteInputHandleChange = (event, key) => {
    const updatedDataForm = {
      ...this.state.dataForm,
      [key]: {
        ...this.state.dataForm[key],
        value: event,
        touched: true,
      },
    };
    this.setState({ dataForm: updatedDataForm });
  };

  async fetchSuggestions(query) {
    console.log("query", query);
    if (!query.trim()) {
      console.log("query", query.length);
      this.setState({ option: [] });
      return;
    }
    try {
      const response = await cooFindMerchantByName(query?.trim());
      console.log("merchantSearch", response);
      const suggestions = response?.data?.data.map((merchant) => ({
        id: merchant.merchantId,
        value: merchant.merchantName,
        label: merchant.merchantName,
      }));
      this.setState({ option: suggestions });
      if (response?.data?.data?.length > 0) {
        this.setState({
          dataSource: response?.data?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  onSelectAutoComplete(data) {
    console.log("onSelect", data);
  }

  render() {
    const {
      dataSource,
      tags,
      isLoading,
      viewPointHeight,
      activeMerchant,
      dataForm,
      selectedMerchantID,
      suggestions,
      option,
    } = this.state;
    const { selectMerchantData, numOfOfferByMerchant } = this.props;
    const columns = [
      {
        title: "",
        key: "logoURL",
        render: (text, record) => {
          return <Image width={60} height={60} src={record.logoURL} />;
        },
      },
      {
        title: "#",
        dataIndex: "merchantId",
        key: "merchantId",
      },
      {
        title: "Merchant Name",
        dataIndex: "merchantName",
        key: "merchantName",
      },
      {
        title: "Contact no",
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: "Web URL",
        dataIndex: "webURL",
        key: "webURL",
      },
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <a href="#">View</a>
            <a href="#" onClick={() => this.props.onClickEditMerchant(record)}>
              Edit
            </a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={true}
            isDate={false}
            isLoading={isLoading}
            onClickRefresh={async () => {
              await this.getAll();
            }}
            title={"Merchant"}
          />
        </div>
        <div className={"search-bar-wrapper mt-1"}>
          <AutoComplete
            value={dataForm.searchMerchant.value}
            options={option}
            style={{ width: "100%" }}
            onSelect={(data) => {
              this.onSelectAutoComplete(data);
            }}
            onChange={(event) =>
              this.autoCompleteInputHandleChange(
                event,
                dataForm.searchMerchant.key
              )
            }
            onSearch={async () => {
              await this.fetchSuggestions(dataForm.searchMerchant.value);
              // this.props.onClickClearSelected();
              // this.props.clearSearchMerchant();
            }}
            placeholder="Search merchant name here..."
          />
          {/* <AutoCompleteBox
            value={dataForm.searchMerchant.value}
            options={option}
            style={{ width: "100%" }}
            onSelect={(data) => {
              this.onSelectAutoComplete(data);
            }}
            onChange={(event) =>
              this.autoCompleteInputHandleChange(
                event,
                dataForm.searchMerchant.key
              )
            }
            onSearch={async () => {
              await this.fetchSuggestions(dataForm.searchMerchant.value);
              // this.props.onClickClearSelected();
              // this.props.clearSearchMerchant();
            }}
            placeholder="Search merchant name here..."
          /> */}

          {/*<div className={"search-action-left-wrp"}></div>*/}
        </div>
        <Scrollbars
          style={{ height: viewPointHeight - 210 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div className={"cco__page-body-wrapper"}>
            {dataSource && dataSource.length > 0 && (
              <>
                {dataSource
                  .filter((item) => {
                    if (dataForm.searchMerchant.value === "") {
                      return item; // Corrected the return statement
                    } else if (
                      item.merchantName
                        ?.toLowerCase()
                        .includes(dataForm.searchMerchant.value.toLowerCase())
                    ) {
                      return item; // Corrected the return statement
                    }
                  })
                  .map((item, index) => (
                    <div className={"mt-1"}>
                      <MerchantDetailCard
                        logoURL={item?.logoURL}
                        merchantName={item?.merchantName}
                        merchantId={item?.merchantId}
                        isActive={
                          item?.merchantId ===
                          this.props.selectMerchantData?.merchantId
                        }
                        webURL={item?.webURL}
                        termsAndCondition={item?.termsAndCondition}
                        status={item?.status}
                        createdAt={item?.createdAt}
                        location={item?.location}
                        favoriteArea={item?.favoriteArea}
                        cardType={"merchant"}
                        onClickDelete={async () => {
                          console.log("event fire....");
                        }}
                        onClickEdit={async () => {
                          this.props.onClickEditMerchant(item);
                          // this.props.onClearTerms();
                          const { termsAndCondition } = item || {};
                          this.props.onLoadTermsAndCondition(
                            item?.termsAndCondition
                          );
                          // if (Array.isArray(termsAndCondition)) {
                          //   // Handle case when termsAndCondition is an array
                          //   if (termsAndCondition?.length > 0) {
                          //     this.props.onLoadTermsAndCondition(
                          //       item?.termsAndCondition
                          //     );
                          //   }
                          //   console.log("termsAndCondition is an array");
                          // } else if (
                          //   termsAndCondition &&
                          //   typeof termsAndCondition === "object"
                          // ) {
                          //   console.log("termsAndCondition is an object");
                          //   this.props.onLoadTermsAndCondition(
                          //     item?.termsAndCondition
                          //   );
                          // } else {
                          //   console.log(
                          //     "termsAndCondition is neither an array nor an object"
                          //   );
                          // }
                          // this.props.onLoadTermsAndCondition(termsAndCondition);

                          //offer count
                          let res = await cooGetMerchantOfferCount(
                            item?.merchantId
                          );
                          if (res) {
                            console.log("totalCount", res);
                            this.props.setNoOfCount(
                              Number(res?.data?.data[0]?.totalCount)
                            );
                          }
                          const resOfferByMerchant =
                            await cooGetOfferByMerchant(item.merchantId);
                          console.log(
                            "resOfferByMerchant===>",
                            resOfferByMerchant
                          );
                          if (
                            resOfferByMerchant &&
                            resOfferByMerchant.data &&
                            resOfferByMerchant.data.status
                          ) {
                            this.setState({
                              recentOffers:
                                resOfferByMerchant &&
                                resOfferByMerchant.data &&
                                resOfferByMerchant.data.data,
                            });
                          }
                        }}
                      />
                    </div>
                  ))}
              </>
            )}
            {!isLoading &&
              this.state.totalCount -
                this.state.pageNo * this.state.pageViewCount >
                0 && (
                <div
                  className={
                    "console__load-more-wrp d-flex justify-content-center mt-3 "
                  }
                >
                  <Button
                    size={"sm"}
                    type={"btn-primary light"}
                    isLoading={isLoading}
                    onClick={async () => {
                      if (dataSource?.length > 0) {
                        await this.getAll();
                      }
                    }}
                    text={`Load more (${
                      this.state.totalCount -
                      this.state.pageNo * this.state.pageViewCount
                    })`}
                  />
                </div>
              )}
            {this.state.isLoading && (
              <div style={{ margin: "0 50%", opacity: 0.5 }}>
                <Spin size="large" spinning={isLoading} />
              </div>
            )}
            {!this.state.hasMore && (
              <p className="loading">No more items to load</p>
            )}

            {/*<Table*/}
            {/*  size={"small"}*/}
            {/*  loading={isLoading}*/}
            {/*  dataSource={dataSource}*/}
            {/*  columns={columns}*/}
            {/*/>*/}
          </div>
        </Scrollbars>
        <div className={"cco__pagination-wrapper"}>
          <Pagination
            pageSize={this.state.pageViewCount}
            onChange={async (page, pageSize) => {
              if (dataSource?.length > 0) {
                await this.onChangePage(page, pageSize);
              }
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  refreshMerchant: state.merchantReducer.refreshMerchant,
  selectMerchantData: state.merchantReducer.selectMerchantData,
  numOfOfferByMerchant: state.offerReducer.numOfOfferByMerchant,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickEditMerchant: (selectedData) =>
      dispatch({
        type: actionTypes.EDIT_MERCHANT,
        payload: selectedData,
      }),
    setNoOfCount: (count) =>
      dispatch({
        type: actionTypes.SET_NO_OFFER,
        payload: Number(count),
      }),
    doneEditMerchant: () =>
      dispatch({
        type: actionTypes.DONE_MERCHANT_UPDATE,
      }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.LOAD_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    onClearTerms: () =>
      dispatch({
        type: actionTypes.CLEAR_TERMS_AND_CONDITION,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
