import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import Manual from "./manual";
import Upload from "./upload";
import { v4 as uuidv4 } from "uuid";

const { TabPane } = Tabs;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      tags: [],
      tag: null,
      isLoading: false,
      isImageUploading: false,
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Manual",
          content: <Manual />,
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Upload",
          content: <Upload />,
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { tabJson } = this.state;
    return (
      <div className={"cco__cnc-right-content-wrapper p0"}>
        <Tabs>
          {((tabJson && tabJson) || []).map((i, t) => (
            <TabPane tab={i.tabName} key={i.key}>
              <>{i.content}</>
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
