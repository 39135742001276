import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";
import { OpenNotification } from "../../../config/notification";

import {
  Button,
  ButtonIcon,
  Input,
  OfferCardFooterItem,
  OfferDetailCard,
  Tag,
  TermsConditionsCard,
} from "../../../components";

import { Collapse, Tabs } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import moment from "moment";
import {
  getUserById,
  saveOffer,
  saveSyncOffer,
  updateSyncOffer,
} from "../../../api";
import { userDetails } from "../../../utils";
import { cooFindMerchantById } from "../../merchant/service/merchant.service";
import { cooGetBankById } from "../../bank/service/bank.service";

const { TabPane } = Tabs;

const momentTimezone = require("moment-timezone");

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      userObject: null,
      selectedOffer: null,
      validLocation: [],
      formIsValid: false,
      dataForm: {
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };
  async fetchMyAPI() {
    //let response =  getToken();
    let response = await userDetails();
    console.log("response", response);
    this.setState({ userObject: response });
    let resUser = await getUserById(response?.userId);
    console.log("getUserById::", resUser);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    this.setOfferData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  setOfferData() {
    this.setState({
      selectedOffer: null,
    });
    this.clearAll();
    const selectOffer = this.props.selectOffer;

    if (selectOffer?.offerId) {
      console.log("{ selectOffer }", selectOffer?.location);
      this.setState({
        selectedOffer: selectOffer,
        validLocation: selectOffer?.location,
      });
    } else {
      this.setState({
        selectedOffer: null,
      });
    }
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", updateForm);
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value && value.trim() !== "" && isValid;
    }
    return isValid;
  }

  clearAll() {
    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      updateForm[key].value = "";
      updateForm[key].valid = false;
      updateForm[key].touched = false;
    }
    this.setState({ dataForm: updateForm });
  }

  isArray = (value) => {
    return Array.isArray(value);
  };

  onClickConfirm = async () => {
    let selectOffer = this.props.selectOffer;
    if (selectOffer && selectOffer.offerId) {
      this.setState({ isLoading: true });

      let user = await userDetails();

      let body = {
        offerId: selectOffer?.offerId,
        userId: user?.userId,
        categoryId: selectOffer?.categoryId,
        merchantId: selectOffer?.merchantId,
        bankId: selectOffer?.bankId,
        referenceId: selectOffer?.referenceId,
        label: selectOffer?.label,
        header: selectOffer?.header,
        offerDateType: selectOffer?.offerDateType,
        offerStart: selectOffer?.offerStart,
        offerEnd: selectOffer?.offerEnd,
        customizeDate: selectOffer?.customizeDate,
        validDescription: selectOffer?.validDescription,
        cardTypes: selectOffer?.cardTypes,
        offerType: selectOffer?.offerType,
        offerRate: selectOffer?.offerRate,
        location: selectOffer?.location,
        hotLine: selectOffer?.hotLine,
        description: selectOffer?.description,
        termsConditions: selectOffer?.termsConditions,
        bannerImageURL:
          this.state.dataForm?.logoURL?.value !== ""
            ? this.state.dataForm?.logoURL?.value
            : selectOffer?.bannerImageURL,
        referenceURL: selectOffer?.referenceURL,
        promoCode: selectOffer?.promoCode,
        gallery: selectOffer?.gallery,
        validDate: selectOffer?.validDate,
        startTime: selectOffer?.startTime,
        endTime: selectOffer?.endTime,
        validTimeDateDescription: selectOffer?.validTimeDateDescription,
        status: "accepted",
      };

      let res = await saveSyncOffer(body);
      // let resPayable = await createNewPayable({
      //   offerId: selectOffer?.offerId,
      //   userId: selectOffer?.user?.userId,
      //   paymentStatus: "pending",
      //   offerStatus: "accepted",
      // });

      if (res && res.data && res.data.status) {
        this.props.refreshSyncOffers();
        this.setState({ isLoading: false });
        this.props.closeOfferViewModal();
      }
    } else {
      return false;
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectOffer !== this.props.selectOffer) {
      this.setState({
        selectedOffer: null,
      });
      this.clearAll();
      const selectOffer = this.props.selectOffer;

      if (selectOffer?.offerId) {
        console.log("{ selectOffer }", selectOffer?.location);
        this.setState({
          selectedOffer: selectOffer,
          validLocation: selectOffer?.location,
        });
      } else {
        this.setState({
          selectedOffer: null,
        });
      }
    }
  }

  render() {
    const { viewPointHeight, selectedOffer, isLoading, dataForm } = this.state;
    const {
      isViewSyncOffer,

      isCreateOffer,
      onClickFavorite,
      isLogin,
    } = this.props;

    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isViewSyncOffer ? "show-me" : "hide-me"
          } `}
        >
          <div
            className={
              "coo_modal-body-content fix-906 cco__mbc-view d-flex flex-column"
            }
            style={{
              top: 0,
            }}
          >
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"coo_mbc__header"}>{"Offer View"}</div>
              <div
                className={`close-modal-wrapper `}
                onClick={() => this.props.closeOfferViewModal()}
              >
                <RiCloseLine />

                {/* <ButtonIcon
                type={"close"}
                onClick={() => this.props.onClickMe()}
                size={"md"}
                buttonIcon={GetIcon("close")}
              /> */}
              </div>
            </div>

            <div
              className={
                "coo_modal-offer-title d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"d-flex flex-row align-items-center"}>
                <div className={"coo_modal_merchant-logo"}>
                  <img
                    src={selectedOffer && selectedOffer?.merchant?.logoURL}
                  />
                </div>
                <div className={"d-flex flex-column"}>
                  <div className={"coo__offer-title"}>
                    {selectedOffer &&
                      selectedOffer?.merchant &&
                      selectedOffer?.merchant?.merchantName}{" "}
                    - {selectedOffer?.offerId}
                  </div>
                  <h3> {selectedOffer && selectedOffer?.header}</h3>
                  <div className={"coo__offer-refLink"}>
                    <a
                      href={selectedOffer && selectedOffer?.referenceURL}
                      target={"_blank"}
                    >
                      {selectedOffer && selectedOffer?.referenceURL}
                    </a>
                  </div>
                </div>
              </div>

              <Tag
                type={
                  selectedOffer?.status === "new"
                    ? "new"
                    : selectedOffer?.status === "Accepted" ||
                      selectedOffer?.status === "accepted"
                    ? "accept"
                    : selectedOffer?.status === "Rejected" ||
                      selectedOffer?.status === "rejected"
                    ? "reject"
                    : "new"
                }
                tagName={selectedOffer?.status}
                size={"lg"}
              />
            </div>
            <div className={"coo_modal_banner_wrapper mb-2"}>
              {dataForm.logoURL.value === "" && (
                <img src={selectedOffer && selectedOffer?.bannerImageURL} />
              )}
              {dataForm.logoURL.value !== "" && (
                <div className={"coo_modal_banner_wrapper mb-4"}>
                  <img src={dataForm.logoURL.value} />

                  <div className={"cco__image-delete-wrapper"}>
                    <ButtonIcon
                      onClick={() => this.clearAll()}
                      buttonIcon={GetIcon("close")}
                      type={"close"}
                      size={"md"}
                    />
                  </div>
                </div>
              )}
              <div className={"coo__offer-tag"}>
                {selectedOffer?.offerType === "off" && (
                  <>{selectedOffer?.offerRate}% off</>
                )}
                {selectedOffer?.offerType === "upto" && (
                  <>upto {selectedOffer?.offerRate}% off</>
                )}
                {selectedOffer?.offerType === "customize" && (
                  <>{selectedOffer?.offerRate}</>
                )}
                {selectedOffer?.offerType === "discount" && (
                  <>{selectedOffer?.offerRate}% discount</>
                )}
              </div>
            </div>
            <div className={"pb-2 border-bottom"}>
              {/* <UploadCard
                        title={"Basic profile"}
                        onSelectFile={this.onSelectFile.bind(this)}
                        isImageUploading={isImageUploading}
                        logoURL={dataForm.logoURL.value}
                      /> */}
              <div className={"d-flex mb-2 align-items-end"}>
                <Button
                  text={"find image (google)"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={() => {
                    window.open(
                      `http://www.google.com/search?q=${
                        selectedOffer && selectedOffer?.merchant?.merchantName
                      }`,
                      "_blank"
                    );
                  }}
                />
              </div>
              {/*{!dataForm.logoURL.value &&}*/}

              <div className={"d-flex flex-row align-items-center"}>
                <div style={{ width: "100%" }}>
                  <Input
                    elementConfig={dataForm.logoURL.elementConfig}
                    elementType={dataForm.logoURL.elementType}
                    required={dataForm.logoURL.validation.required}
                    isValid={!dataForm.logoURL.valid}
                    touched={dataForm.logoURL.touched}
                    value={dataForm.logoURL.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.logoURL.label}
                    changed={(event) =>
                      this.inputHandleChange(event, dataForm.logoURL.key)
                    }
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <Button
                    text={"Save"}
                    type={"btn-edit"}
                    size={"md secondary ml-2"}
                    onClick={async () => {
                      this.setState({ isLoading: true });
                      if (dataForm.logoURL.value !== "") {
                        let offerBody = {
                          ...selectedOffer,
                          bannerImageURL: dataForm.logoURL.value,
                        };

                        let resOffer = await updateSyncOffer(
                          offerBody,
                          this.props.selectOffer?.offerId
                        );
                        this.setState({ isLoading: false });
                        this.props.closeOfferViewModal();
                        console.log(resOffer);
                        this.props.onClickOpenOfferView(resOffer?.data?.data);
                      } else {
                        this.setState({ isLoading: false });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Scrollbars
              style={{ height: viewPointHeight - 530 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"coo_modal-content-wrapper d-flex flex-column"}>
                <div
                  className="d-flex flex-row align-items-start justify-content-between p-2"
                  style={{ width: "100%" }}
                >
                  <OfferDetailCard
                    type={"tag"}
                    option={"d-flex flex-column text-left"}
                    description={null}
                    tagList={selectedOffer && selectedOffer?.location}
                    title={"Offer valid location"}
                  />
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary"}
                    onClick={async () => {
                      try {
                        const resMerchant = await cooFindMerchantById(
                          selectedOffer.merchantId
                        );

                        const merchantData = resMerchant?.data?.data || {};
                        const merchantLocations = merchantData.location || [];

                        const newLocationList = merchantLocations.map(
                          (location) => {
                            const isActive =
                              selectedOffer?.location?.find(
                                (x) => x.id === location.id
                              )?.isActive ||
                              location?.isActive ||
                              false;

                            return {
                              id: location.id,
                              value: location.value || location.tagName,
                              count: location.count,
                              isActive: location.isActive,
                            };
                          }
                        );

                        const map = new Map();

                        [
                          ...newLocationList,
                          ...selectedOffer?.location,
                        ].forEach((item) => {
                          map.set(item.id, { ...map.get(item.id), ...item });
                        });

                        const result = Array.from(map.values());
                        console.log({ newLocationList });

                        this.props.openEditMerchantModal({
                          ...merchantData,
                          location: result,
                        });
                      } catch (error) {
                        console.error("Error fetching merchant data:", error);
                      }
                    }}
                  />
                </div>

                <div className="d-flex flex-row align-items-start justify-content-between p-2 border-top">
                  <OfferDetailCard
                    type={"tag"}
                    option={"d-flex flex-column text-left mb-2 mt-3"}
                    description={null}
                    tagList={
                      selectedOffer && selectedOffer?.cardTypes
                        ? selectedOffer?.cardTypes
                        : []
                    }
                    title={"Valid card"}
                  />
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary"}
                    onClick={async () => {
                      const resBank = await cooGetBankById(
                        selectedOffer.bankId
                      );
                      console.log({ resBank });

                      let bankData = resBank?.data?.data;
                      let newCardList = (bankData?.cardTypes || []).map(
                        (cardType) => ({
                          id: cardType.id,
                          value: cardType.value || cardType.cardName,
                          count: cardType.count,
                          isActive: cardType.isActive,
                        })
                      );
                      // const uniqueCardIds = new Set(
                      //   newCardList.map((card) => card.id)
                      // );
                      // const nonDuplicateTerms = selectedOffer?.cardTypes.filter(
                      //   (card) => !uniqueCardIds.has(card.id)
                      // );

                      const map = new Map();

                      [...newCardList, ...selectedOffer?.cardTypes].forEach(
                        (item) => {
                          map.set(item.id, { ...map.get(item.id), ...item });
                        }
                      );

                      const result = Array.from(map.values());
                      console.log(result);

                      this.props.openEditBankModal({
                        ...bankData,
                        cardTypes: result,
                        status: "auto",
                      });
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between p-2 border-top">
                  <OfferDetailCard
                    type={"text"}
                    option={"d-flex flex-column text-left mb-2 mt-3"}
                    description={
                      selectedOffer && selectedOffer?.promoCode
                        ? selectedOffer.promoCode
                        : "-"
                    }
                    title={"Promo code"}
                  />
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary"}
                    onClick={async () => {
                      this.props.openEditPromoDetailModal(selectedOffer);
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between p-2 border-top">
                  <div>
                    <OfferDetailCard
                      type={"text"}
                      option={"d-flex flex-column text-left mb-2 mt-3"}
                      description={null}
                      contactList={
                        selectedOffer &&
                        selectedOffer?.hotLine &&
                        selectedOffer?.hotLine.mobileNo
                      }
                      title={"Mobile no"}
                    />
                    <OfferDetailCard
                      type={"text"}
                      option={"d-flex flex-column text-left mb-2 mt-3"}
                      description={null}
                      contactList={
                        selectedOffer &&
                        selectedOffer?.hotLine &&
                        selectedOffer?.hotLine.email
                      }
                      title={"E-mail"}
                    />
                  </div>
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary ml-1 mt-1"}
                    onClick={async () => {
                      const resMerchant = await cooFindMerchantById(
                        selectedOffer?.merchantId
                      );
                      let merchantData = resMerchant?.data?.data;

                      this.props.openEditMerchantContactsModal({
                        ...merchantData,
                        hotLine: {
                          mobileNo: merchantData?.hotLine?.mobileNo || [],
                          email: merchantData?.hotLine?.email || [],
                        },
                      });
                    }}
                  />
                </div>

                <div className="d-flex flex-row align-items-start justify-content-between p-2 border-top border-bottom">
                  <div>
                    <OfferDetailCard
                      type={"text"}
                      option={"d-flex flex-column text-left mb-2 mt-3"}
                      description={
                        selectedOffer
                          ? selectedOffer?.offerStart && selectedOffer?.offerEnd
                            ? "Valid from " +
                              moment(selectedOffer?.offerStart).format(
                                "MMMM Do YYYY"
                              ) +
                              " to " +
                              moment(selectedOffer?.offerEnd).format(
                                "MMMM Do YYYY"
                              )
                            : !selectedOffer?.offerStart &&
                              selectedOffer?.offerEnd
                            ? "Valid till " +
                              momentTimezone
                                .tz(
                                  moment(selectedOffer?.offerEnd).format(),
                                  "Asia/Colombo"
                                )
                                .format("MMMM Do YYYY")
                            : "-"
                          : "-"
                      }
                      title={"Validity Date"}
                    />
                    {selectedOffer?.offerDateType === "other" && (
                      <div className={"customize-date-description"}>
                        Note : {selectedOffer?.customizeDate?.description}
                      </div>
                    )}
                    <OfferDetailCard
                      type={"text"}
                      option={"d-flex flex-column text-left mb-2 mt-3"}
                      description={
                        <>
                          {selectedOffer?.offerType === "off" && (
                            <>{selectedOffer?.offerRate}% off</>
                          )}
                          {selectedOffer?.offerType === "upto" && (
                            <>upto {selectedOffer?.offerRate}% off</>
                          )}
                          {selectedOffer?.offerType === "customize" && (
                            <>{selectedOffer?.offerRate}</>
                          )}
                          {selectedOffer?.offerType === "discount" && (
                            <>{selectedOffer?.offerRate}% discount</>
                          )}
                        </>
                      }
                      title={"Offer"}
                    />
                  </div>
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary"}
                    onClick={async () => {
                      this.props.openUpdateOfferModal(selectedOffer);
                    }}
                  />
                </div>
                <div className={"mt-3 mb-3 d-flex"}>
                  <OfferCardFooterItem
                    title={"Bank"}
                    img={
                      <img
                        className={"lading-bg"}
                        src={
                          selectedOffer &&
                          selectedOffer.bank &&
                          selectedOffer.bank.logoURL
                        }
                        alt="all offers in one place"
                      />
                    }
                    content={
                      selectedOffer &&
                      selectedOffer.bank &&
                      selectedOffer.bank.bankName
                    }
                    type={"bank"}
                  />
                  <OfferCardFooterItem
                    style={"category"}
                    title={"Category"}
                    content={
                      selectedOffer &&
                      selectedOffer.category &&
                      selectedOffer.category.categoryName
                    }
                    iconName={
                      selectedOffer &&
                      selectedOffer.category &&
                      selectedOffer.category.iconName
                    }
                    type={"icon"}
                  />
                  <OfferCardFooterItem
                    title={"Merchant"}
                    img={
                      <img
                        className={"lading-bg"}
                        src={
                          selectedOffer &&
                          selectedOffer.merchant &&
                          selectedOffer.merchant.logoURL
                        }
                        alt="all offers in one place"
                      />
                    }
                    content={
                      selectedOffer &&
                      selectedOffer.merchant &&
                      selectedOffer.merchant.merchantName
                    }
                    type={"bank"}
                  />
                  <OfferCardFooterItem
                    style={"views"}
                    title={"Views"}
                    content={selectedOffer?.views}
                    iconName={"eye"}
                    type={"icon"}
                  />
                  {isLogin && (
                    <OfferCardFooterItem
                      style={"favorite"}
                      title={"Favorite"}
                      iconName={"favorite"}
                      isFavorites={selectedOffer?.isFavorites}
                      content={selectedOffer?.interest}
                      type={"icon"}
                      onClick={onClickFavorite}
                    />
                  )}
                </div>

                <div className="d-flex flex-row align-items-center justify-content-between p-2 border-bottom">
                  <OfferDetailCard
                    type={"text"}
                    option={"d-flex flex-column text-left mb-2 mt-3"}
                    description={
                      selectedOffer &&
                      (selectedOffer?.description ||
                        selectedOffer?.validTimeDateDescription)
                    }
                    title={"Offer Description"}
                  />
                  <Button
                    text={"Edit"}
                    type={"btn-edit"}
                    size={"md secondary"}
                    onClick={async () => {
                      this.props.openEditDescriptionModal(selectedOffer);
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "24px" }}>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <OfferDetailCard
                      type={"text"}
                      option={"d-flex flex-column text-left mb-2 mt-3"}
                      description={""}
                      title={"Terms & Conditions"}
                    />
                    <Button
                      text={"Edit"}
                      type={"btn-edit"}
                      size={"md secondary ml-1 mt-1"}
                      onClick={async () => {
                        const resMerchant = await cooFindMerchantById(
                          selectedOffer?.merchantId
                        );
                        let merchantData = resMerchant?.data?.data;
                        let terms;
                        if (merchantData?.merchantId) {
                          terms = merchantData?.termsAndCondition || {};
                        }

                        const termsToAdd = {
                          bankId: selectedOffer?.bankId,
                          terms: terms,
                        };
                        this.props.onClickSelectedMerchant(merchantData);
                        this.props.onClickAddTermsIntoList(termsToAdd);
                        this.props.onClickOpenTermsAndConditions();
                      }}
                    />
                  </div>

                  {selectedOffer?.termsConditions &&
                  this.isArray(selectedOffer?.termsConditions) ? (
                    <>
                      {selectedOffer?.termsConditions &&
                        (selectedOffer?.termsConditions || []).map(
                          (item, index) => (
                            <TermsConditionsCard
                              description={item && item.value}
                            />
                          )
                        )}
                    </>
                  ) : (
                    <div className={"cco__mtc-tab"}>
                      <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={selectedOffer?.bank?.bankName}
                          key={selectedOffer?.bank?.bankId}
                        >
                          {selectedOffer && selectedOffer?.termsConditions && (
                            <div className="mt-2">
                              {selectedOffer?.termsConditions[
                                selectedOffer?.bank?.bankId
                              ]
                                ?.filter((x) => x.isActive)
                                ?.map((item, index) => (
                                  <TermsConditionsCard
                                    description={item && item.value}
                                  />
                                ))}
                            </div>
                          )}
                        </TabPane>
                      </Tabs>
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
            <div className={"ov__footer-action-wrapper"}>
              {this.state.userObject?.userType != "user" && !isCreateOffer && (
                <div style={{ width: "130px" }}>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    option={"mr-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      this.props.onClickOpenRejectReason();
                    }}
                    text={"Reject"}
                    size={"md"}
                    type={"btn-block-back"}
                  />
                </div>
              )}
              {!isCreateOffer && (
                <div style={{ width: "130px" }}>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      this.props.closeOfferViewModal();
                    }}
                    text={"Cancel"}
                    size={"md"}
                    type={"btn-block-back"}
                  />
                </div>
              )}
              {this.state.userObject?.userType != "user" &&
                ((selectedOffer && selectedOffer.status === "new") ||
                  isCreateOffer) && (
                  <div style={{ width: "174px" }}>
                    <Button
                      leftIconFill={true}
                      iconName={<RiCheckLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-2"}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        await this.onClickConfirm();
                      }}
                      LeftIconText={"Approve"}
                      text={""}
                      size={"md"}
                      type={"btn-block"}
                    />
                  </div>
                )}
              {this.state.userObject?.userType === "user" && isCreateOffer && (
                <div style={{ width: "158px" }}>
                  <Button
                    leftIconFill={true}
                    iconName={<RiCheckLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-2"}
                    isLoading={isLoading}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    onClick={async () => {
                      if (isCreateOffer) {
                        await this.onClickSaveData();
                      } else {
                        await this.onClickConfirm();
                      }
                    }}
                    LeftIconText={isCreateOffer ? "Done" : ""}
                    text={""}
                    size={"md"}
                    type={"btn-block"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isViewOffer: state.offerReducer.isViewOffer,
    isViewSyncOffer: state.offerReducer.isViewSyncOffer,
    isCreateOffer: state.offerReducer.isCreateOffer,
    selectOffer: state.offerReducer.selectedOffer,
    selectMerchant: state.merchantReducer.selectMerchant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
    clearOfferView: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_VIEW_MODAL, status: false }),
    onClickOpenRejectReason: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_REJECT_MODAL,
        payload: payload,
      }),
    openEditMerchantModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_MERCHANT_LOCATION,
        payload: payload,
      }),
    openEditPromoDetailModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_OFFER_PROMO_DETAILS,
        payload: payload,
      }),
    openEditBankModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_OFFER_BANK_CARD_TYPES,
        payload: payload,
      }),
    openEditDescriptionModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_OFFER_DESCRIPTION,
        payload: payload,
      }),
    openEditMerchantContactsModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_OFFER_CONTACTS,
        payload: payload,
      }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickOpenTermsAndConditions: () =>
      dispatch({ type: actionTypes.OPEN_TERMS_AND_CONDITIONS_MODAL }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    clearSelectedOfferDetails: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
    openUpdateOfferModal: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SYNC_OFFER_DETAILS_MODAL, payload }),
    onClickAddTermsIntoList: (payload) =>
      dispatch({ type: actionTypes.SELECTED_EXISTING_TERMS, payload: payload }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
