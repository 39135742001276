import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import moment from "moment";
import { Button, Tag } from "../..";
import { RadioBox } from "../../../components";
import * as actionTypes from "../../../store/actions/types";
import { connect } from "react-redux";
import { RiArrowRightLine } from "react-icons/ri";

const Index = (props) => {
  return (
    <>
      <div
        className={`cco__merchant-card__wrapper d-flex flex-row align-items-center ${
          props.isActive ? "isActive" : ""
        }`}
        onClick={props.onSelectedMerchant}
      >
        <div className={"cco__ocw__logo"}>
          <img src={props.logo} alt="creditcard offers" />
        </div>
        <div
          className={"d-flex"}
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column justify-content-center"}>
            <div className={"cco__ocw__name"}>{props.name}</div>
            <div
              className={"d-flex flex-row align-items-center"}
              style={{ gap: "8px" }}
            >
              {props.status && (
                <div className={"cco__ocw__tags d-flex flex-row"}>
                  <Tag type={"pending"} tagName={props.status} />
                </div>
              )}
              {props.offers && (
                <div className={"cco__ocw__tags d-flex flex-row"}>
                  <Tag
                    type={"username"}
                    tagName={"Active Offers" + "(" + props.offers + ")"}
                  />
                </div>
              )}
              {props.category && (
                <div className={"cco__ocw__tags d-flex flex-row"}>
                  <Tag type={"companyName"} tagName={props.category} />
                </div>
              )}
            </div>
            <div className="d-flex flex-row align-items-center">
              {props.createdAt && (
                <div className={"cco__ocw__time"}>
                  {moment(props && props.createdAt)
                    .startOf("day")
                    .fromNow()}
                </div>
              )}
              {props.webUrl && (
                <a
                  className={"cco__ocw__time ml-2"}
                  style={{ color: "#00a2ff" }}
                  href={"https://" + props.webUrl}
                >
                  {props.webUrl}
                </a>
              )}
            </div>
          </div>
        </div>
        {props.editable && (
          // <div
          //   className="bcw__action-icon d-flex flex-column justify-content-center"
          //   onClick={props.onClickEdit}
          // >
          //   <RiArrowRightLine />
          // </div>
          <div>
            <Button
              text={"Edit"}
              onClick={props.onClickEdit}
              type={"btn-edit"}
            />
          </div>
        )}
        {!props.preview && (
          <div>
            <RadioBox
              text={false}
              isSelect={props.isActive}
              onClick={async () => {}}
              size={"md"}
            />
          </div>
        )}
        {props.isEdit && (
          <div>
            <Button
              text={"edit"}
              type={"btn-edit"}
              size={"sm secondary"}
              onClick={props.onClickEditMerchant}
            />
          </div>
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

const mapStateToProps = (state) => {
  return {
    isOpenSearchMerchant: state.merchantReducer.isOpenSearchMerchant,
    selectMerchant: state.merchantReducer.selectMerchant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedOffer: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
