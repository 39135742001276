import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import {
  Input,
  MerchantDetailCard,
  PageHeader,
  Pagination,
} from "../../components";
import { Space, Spin } from "antd";
import * as actionTypes from "../../store/actions/types";

import { cooGetAllCategory } from "./service/category.service";
import { Scrollbars } from "react-custom-scrollbars";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      pageViewCount: 10,
      dataForm: {
        searchCategory: {
          key: "searchCategory",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search category name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount(prevProps) {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getCategory();
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      this.setState({ isLoading: true });
      await this.getCategory();
      if (!this.state.isLoading) {
        this.props.doneRefresh();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getCategory() {
    this.setState({ isLoading: true });
    let res = await cooGetAllCategory();
    this.setState({
      dataSource: res && res.data && res.data.data,
      isLoading: false,
    });
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  render() {
    const { dataSource, tags, isLoading, viewPointHeight, dataForm } =
      this.state;
    const columns = [
      {
        title: "#",
        dataIndex: "categoryId",
        key: "categoryId",
      },
      {
        title: "Icon Name",
        dataIndex: "iconName",
        key: "iconName",
      },
      {
        title: "Category Name",
        dataIndex: "categoryName",
        key: "categoryName",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <a href="#" onClick={() => this.props.onClickEditCategory(record)}>
              View
            </a>
            <a href="#" onClick={() => this.props.onClickEditCategory(record)}>
              Edit
            </a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div>
          <div className={"cco__page-header-wrapper"}>
            <PageHeader
              option={"d-flex justify-content-between"}
              isRefresh={true}
              isDate={false}
              isLoading={isLoading}
              onClickRefresh={async () => {
                let res = await this.getCategory();
              }}
              title={"Category"}
            />
          </div>
          <div className={"search-bar-wrapper mt-1"}>
            <Input
              elementConfig={dataForm.searchCategory.elementConfig}
              elementType={dataForm.searchCategory.elementType}
              required={dataForm.searchCategory.validation.required}
              isValid={!dataForm.searchCategory.valid}
              touched={dataForm.searchCategory.touched}
              value={dataForm.searchCategory.value}
              isShowRequired={false}
              size={"sm"}
              margin={"m-b-1 m-t-2"}
              label={dataForm.searchCategory.label}
              changed={
                (event) =>
                  this.inputHandleChange(event, dataForm.searchCategory.key)
                // console.log("event changed")
              }
            />
            {/*<div className={"search-action-left-wrp"}></div>*/}
          </div>
          <Scrollbars
            style={{ height: viewPointHeight - 210 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"cco__page-body-wrapper"}>
              {dataSource && dataSource.length > 0 && (
                <>
                  {dataSource
                    .filter((item) => {
                      if (dataForm.searchCategory.value === "") {
                        return item; // Corrected the return statement
                      } else if (
                        item.categoryName
                          .toLowerCase()
                          .includes(dataForm.searchCategory.value.toLowerCase())
                      ) {
                        return item; // Corrected the return statement
                      }
                    })
                    .map((item, index) => (
                      <MerchantDetailCard
                        merchantName={item?.categoryName}
                        isActive={false}
                        webURL={item?.categoryId}
                        cardType={"category"}
                        iconName={item?.iconName}
                        userType={this.props.userData?.userType}
                        status={item?.status}
                        createdAt={item?.createdAt}
                        onClickEdit={async () => {
                          this.props.onClickEditCategory(item);
                          //offer count
                        }}
                      />
                    ))}
                </>
              )}
              {this.state.isLoading && (
                <div style={{ margin: "0 50%", opacity: 0.5 }}>
                  <Spin size="large" spinning={isLoading} />
                </div>
              )}
            </div>
          </Scrollbars>
          <div className={"cco__pagination-wrapper"}>
            <Pagination pageSize={this.state.pageViewCount} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickEditCategory: (selectedData) =>
      dispatch({
        type: actionTypes.EDIT_CATEGORY,
        payload: selectedData,
      }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_CATEGORY,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
