import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";

import {
  BankCard,
  Button,
  ButtonIcon,
  InfoRowView,
  Input,
  MerchantDetailCard,
  MerchantSelectCard,
  MerchantSelectCardSmall,
  RecentOfferCard,
  SelectBox,
} from "../../../components";

import { Collapse, Popconfirm, Tabs } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import {
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCheckLine,
  RiCloseLine,
} from "react-icons/ri";
import {
  cooFindMerchantByName,
  cooGetAllMerchantByPage,
  cooGetOfferByMerchant,
  cooUpdateMerchantLocations,
  cooUpdateMerchantTerms,
} from "../../merchant/service/merchant.service";
import uuid from "react-uuid";
import Slider from "react-slick";
import CreateTermsAndConditions from "../ManageTermsAndConditions/createTermsAndConditions";
import { updateDraft } from "../../../api/draft";
import { updateOfferDetails } from "../../../api";

const { Panel } = Collapse;
const { TabPane } = Tabs;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        searchMerchant: {
          key: "searchMerchant",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Find merchant",
        },
        termsAndCondition: {
          key: "termsAndCondition",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        txtLocation: {
          key: "txtLocation",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "type location here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        mobileNo: {
          key: "mobileNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Mobile No",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        email: {
          key: "email",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Email",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.setState({
      formWizard: 1,
    });
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async updateMerchantTerms(newTermsList) {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsAndCondition);
    newTermsList.forEach((element) => {
      newTermsAndConditions.push(element);
    });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsAndCondition.length !== newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsAndCondition: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsAndCondition);
    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsAndCondition.length > newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsAndCondition: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async updateMerchantLocations(value) {
    const { selectedMerchant } = this.state;
    // const { selectLocations } = this.state;

    const newSelectLocations = [
      ...this.state.selectLocations,
      {
        id: uuid(),
        tagName: value,
        count: 0,
      },
    ];
    this.setState({
      selectLocations: newSelectLocations,
    });
    console.log("newSelectLocations", newSelectLocations);
    console.log(selectedMerchant?.location?.length, newSelectLocations.length);
    if (
      selectedMerchant &&
      selectedMerchant?.location?.length < newSelectLocations.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        location: newSelectLocations,
      };

      let res = await cooUpdateMerchantLocations(
        body,
        selectedMerchant.merchantId
      );
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async getAll() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });

    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      isLoading: false,
      formWizard: 1,
    });
  }

  async getAllMerchant() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });

    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      isLoading: false,
      formWizard: 1,
    });
  }

  async refresh() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });
    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      isLoading: false,
      selectLocations: [],
      termsConditions: [],
      formWizard: 1,
    });
  }

  onRemoveTermsAndConditions = (i) => {
    const { termsConditions } = this.state;

    termsConditions[i].isActive = !termsConditions[i].isActive;
    this.setState({ termsConditions });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async fetchSuggestions(query) {
    console.log("query", query);
    if (!query.trim()) {
      console.log("query", query.length);
      return;
    }
    try {
      const response = await cooFindMerchantByName(query?.trim());
      console.log("merchantSearch", response);
      if (response?.data?.data?.length > 0) {
        this.setState({
          dataSourceMerchant: response?.data?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  onSelectLocation = (_item, i) => {
    const { selectLocations } = this.state;
    selectLocations[i].isActive = !selectLocations[i].isActive;
    this.setState({ selectLocations });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectMerchant !== this.props.selectMerchant) {
      const updateForm = { ...this.state.dataForm };
      for (let key in updateForm) {
        updateForm[key].value = "";
        updateForm[key].touched = false;
      }
      const selectedMerchant = this.props.selectMerchant;
      const bankId =
        this.props.selectBank?.bankId || this.props.editOfferData?.bankId;
      if (selectedMerchant && selectedMerchant?.merchantId) {
        this.setState((state) => {
          let selectLocations = [];
          let termsConditions = [];
          for (let i = 0; i < selectedMerchant?.location?.length; i++) {
            selectLocations.push({
              id: selectedMerchant && selectedMerchant.location[i].id,
              tagName: selectedMerchant && selectedMerchant.location[i].tagName,
              count: selectedMerchant && selectedMerchant.location[i].count,
              isActive: false,
            });
          }

          if (
            bankId &&
            selectedMerchant?.termsAndCondition &&
            Object.keys(selectedMerchant?.termsAndCondition)?.length > 0 &&
            selectedMerchant?.termsAndCondition[bankId]
          ) {
            termsConditions = selectedMerchant?.termsAndCondition[bankId].map(
              (term) => ({
                id: term.id,
                value: term.value,
                count: term?.count,
                isActive: term.isActive,
              })
            );
          } else {
            if (
              selectedMerchant &&
              !Array.isArray(selectedMerchant?.termsAndCondition)
            ) {
              console.error("selectedTerms is not an array");
            } else {
              for (
                let i = 0;
                i < selectedMerchant?.termsAndCondition?.length;
                i++
              ) {
                const term =
                  selectedMerchant && selectedMerchant?.termsAndCondition[i];
                termsConditions.push({
                  id: term.id,
                  value: term.value,
                  count: term?.count,
                  isActive: term.isActive,
                });
              }
            }
          }
          state.selectLocations.concat(selectLocations);
          console.log("selectLocations:::", selectLocations);

          return {
            selectLocations,
            termsConditions,
          };
        });
      }
    }
    if (prevProps.isOpenSearchMerchant !== this.props.isOpenSearchMerchant) {
      const openMerchant = this.props.isOpenSearchMerchant;

      if (openMerchant) {
        this.setState({
          formWizard: 1,
          // selectedMerchantID: null,
          // selectedBankId: null,
          // selectedCategoryId: null,
        });
      }
    }

    if (prevProps.editMerchant !== this.props.editMerchant) {
      const editMerchant = this.props.editMerchant;

      if (editMerchant) {
        this.setState({
          formWizard: 1,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: editMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
        });
      }
    }
  }

  getBankName = (bankId) => {
    const bank = this.props.bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  render() {
    const {
      viewPointHeight,
      dataForm,
      dataSourceMerchant,
      selectedMerchantID,
      recentOffers,
      isLoading,
      formWizard,
      selectLocations,
      termsConditions,
      selectOffer,
      selectedBank,
      selectedCategory,
      settings,
      reservationEmail,
      reservationMobileNo,
    } = this.state;
    const { isEditMerchant, selectMerchant, editMerchant } = this.props;

    return (
      <>
        {this.props.isEditMerchant && (
          <section
            className={`coo__modal-main-wrapper animate__animated ${
              isEditMerchant ? "show-me" : "hide-me"
            } `}
          >
            <div
              className={"coo_modal-body-content fix-906 d-flex flex-column "}
              style={{ height: "97%" }}
            >
              <div
                className={
                  "d-flex flex-row justify-content-between align-items-center"
                }
              >
                <div className={"d-flex justify-content-between"}>
                  <div className={"coo_mbc__header mr-4"}>
                    {"Edit Merchant"}
                  </div>
                  <Button
                    text={"Refresh"}
                    type={"btn-edit"}
                    isLoading={false}
                    onClick={async () => {
                      await this.refresh();
                    }}
                    size={"sm primary"}
                  />
                </div>

                <div
                  className={`close-modal-wrapper `}
                  onClick={() => {
                    this.props.onClickCloseEditMerchant();
                    // this.props.onClickClearSelected();
                    // this.props.clearSearchMerchant();
                  }}
                >
                  <RiCloseLine />
                </div>
              </div>

              {formWizard === 1 && (
                <div className={"merchant-search-wrapper mb-3"}>
                  <div className={"mt-1"}>
                    <Input
                      elementConfig={dataForm.searchMerchant.elementConfig}
                      elementType={dataForm.searchMerchant.elementType}
                      required={dataForm.searchMerchant.validation.required}
                      isValid={!dataForm.searchMerchant.valid}
                      touched={dataForm.searchMerchant.touched}
                      value={dataForm.searchMerchant.value}
                      isShowRequired={false}
                      size={"sm"}
                      margin={"m-b-1 m-t-2"}
                      label={dataForm.searchMerchant.label}
                      changed={
                        async (event) => {
                          this.inputHandleChange(
                            event,
                            dataForm.searchMerchant.key
                          );
                          await this.fetchSuggestions(event.target.value);
                        }
                        // console.log("event changed")
                      }
                    />
                    {/*<div className={"search-action-left-wrp"}></div>*/}
                  </div>
                </div>
              )}

              <Scrollbars
                style={{ height: viewPointHeight - 220 }}
                thumbMinSize={30}
                renderScrollbarVertical={({ style, ...props }) => {
                  return (
                    <div
                      style={{
                        ...style,
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                      {...props}
                    />
                  );
                }}
                universal={true}
                autoHide
              >
                {/*merchant search */}

                {/*merchant list view */}
                {formWizard === 1 && (
                  <div className={"merchant-list-wrapper "}>
                    {dataSourceMerchant && dataSourceMerchant.length > 0 && (
                      <>
                        {dataSourceMerchant
                          .filter((item) => {
                            if (dataForm.searchMerchant.value === "") {
                              return item; // Corrected the return statement
                            } else if (
                              item.merchantName
                                ?.toLowerCase()
                                .includes(
                                  dataForm.searchMerchant.value.toLowerCase()
                                )
                            ) {
                              return item; // Corrected the return statement
                            }
                          })
                          .map((item, index) => (
                            <>
                              {/* <MerchantSelectCard
                                onSelectedMerchant={async () => {
                                  this.setState({ recentOffers: [] });
                                  this.setState({
                                    selectedMerchantID: item.merchantId,
                                    selectedMerchant: item,
                                  });

                                  console.log(
                                    "termsAndCondition",
                                    item.termsAndCondition
                                  );
                                  this.props.onClickSelectedMerchant(item);
                                  this.props.onLoadTermsAndCondition(
                                    item.termsAndCondition
                                  );
                                  await this.setState({
                                    selectTermsConditions:
                                      item.termsAndCondition,
                                    formWizard: 4,
                                  });

                                  const resOfferByMerchant =
                                    await cooGetOfferByMerchant(
                                      item.merchantId
                                    );
                                  if (
                                    resOfferByMerchant &&
                                    resOfferByMerchant.data &&
                                    resOfferByMerchant.data.status
                                  ) {
                                    this.setState({
                                      recentOffers:
                                        resOfferByMerchant &&
                                        resOfferByMerchant.data.data,
                                    });
                                  }
                                }}
                                createdAt={selectMerchant && item.createdAt}
                                isActive={
                                  item && item.merchantId === selectedMerchantID
                                }
                                preview={false}
                                editable={false}
                                name={item && item.merchantName}
                                location={item?.location}
                                logo={item && item.logoURL}
                                merchantId={item && item.merchantId}
                              /> */}
                              <MerchantSelectCardSmall
                                onSelectedMerchant={async () => {
                                  let body = {
                                    ...this.props?.editOfferData,
                                    merchantId: item?.merchantId,
                                  };

                                  //confirm data
                                  let res = await updateOfferDetails(
                                    body,
                                    this.props?.editOfferData?.offerId
                                  );
                                  console.log("updateOfferDetails", res);
                                  this.props.clearTermsAndCondition();
                                  this.setState({ recentOffers: [] });
                                  this.setState({
                                    selectedMerchantID: item.merchantId,
                                    selectedMerchant: item,
                                  });

                                  console.log(
                                    "termsAndCondition",
                                    item.termsAndCondition
                                  );
                                  this.props.onClickSelectedMerchant(item);
                                  if (item?.termsAndCondition) {
                                    this.props.onLoadTermsAndCondition(
                                      item.termsAndCondition
                                    );
                                  } else {
                                    this.props.onLoadTermsAndCondition({});
                                  }
                                  await this.setState({
                                    selectTermsConditions:
                                      item.termsAndCondition,
                                    formWizard: 2,
                                  });

                                  const resOfferByMerchant =
                                    await cooGetOfferByMerchant(
                                      item.merchantId
                                    );
                                  if (
                                    resOfferByMerchant &&
                                    resOfferByMerchant.data &&
                                    resOfferByMerchant.data.status
                                  ) {
                                    this.setState({
                                      recentOffers:
                                        resOfferByMerchant &&
                                        resOfferByMerchant.data.data,
                                    });
                                  }
                                }}
                                createdAt={selectMerchant && item.createdAt}
                                isActive={
                                  item && item.merchantId === selectedMerchantID
                                }
                                preview={false}
                                editable={false}
                                name={item && item.merchantName}
                                location={item?.location}
                                logo={item && item.logoURL}
                                merchantId={item && item.merchantId}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </div>
                )}

                {/*merchant modification */}
                {formWizard === 2 && (
                  <>
                    <div className={"merchant-list-wrapper "}>
                      <MerchantSelectCard
                        onSelectedMerchant={() => {
                          console.log("event fire...");
                        }}
                        isActive={true}
                        name={selectMerchant && selectMerchant.merchantName}
                        status={selectMerchant && selectMerchant.status}
                        createdAt={selectMerchant && selectMerchant.createdAt}
                        logo={selectMerchant && selectMerchant.logoURL}
                      />

                      {/*terms_condition-wrapper*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Terms & Conditions
                          </div>
                        </div>
                        <div className={"cco__mtc-tab"}>
                          <div>
                            <div className={"cco__mtc-tab mt-2"}>
                              <>
                                <div className={"cco__mtc-tab mt-2"}>
                                  <Tabs defaultActiveKey="1">
                                    <TabPane
                                      tab={this.getBankName(
                                        this.state.selectedBankId ||
                                          this.props.selectBank?.bankId
                                      )}
                                      key={
                                        this.state.selectedBankId ||
                                        this.props.selectBank?.bankId
                                      }
                                    >
                                      <CreateTermsAndConditions
                                        key={1}
                                        bankName={
                                          selectedBank &&
                                          (selectedBank.bankName ||
                                            this.state.selectedBank?.bankName)
                                        }
                                        bankId={
                                          this.props.selectBank?.bankId ||
                                          this.state.selectedBankId
                                        }
                                        termsAndCondition={
                                          selectMerchant &&
                                          selectMerchant.termsAndCondition
                                        }
                                      />
                                    </TabPane>
                                  </Tabs>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*location*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Location
                          </div>
                        </div>

                        <div className={"d-flex justify-content-between"}>
                          <div className="shw_textWrapper">
                            <Input
                              elementConfig={dataForm.txtLocation.elementConfig}
                              elementType={dataForm.txtLocation.elementType}
                              required={
                                dataForm.txtLocation.validation.required
                              }
                              isValid={!dataForm.txtLocation.valid}
                              touched={dataForm.txtLocation.touched}
                              value={dataForm.txtLocation.value}
                              isShowRequired={false}
                              size={"sm"}
                              margin={"m-b-1 m-t-2"}
                              label={dataForm.txtLocation.label}
                              changed={(event) => {
                                console.log(event);
                                this.inputHandleChange(
                                  event,
                                  dataForm.txtLocation.key
                                );
                              }}
                            />
                          </div>
                          <div className={"action-bar-right"}>
                            <Button
                              text={"Add to list"}
                              type={"btn-edit"}
                              size={"sm primary"}
                              onClick={async () => {
                                const updateForm = { ...this.state.dataForm };
                                if (updateForm.txtLocation.value) {
                                  await this.updateMerchantLocations(
                                    updateForm.txtLocation.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>

                        {selectMerchant && selectMerchant.location && (
                          <div className={"d-flex mt-1"}>
                            {(selectLocations || []).map((item, index) => (
                              <SelectBox
                                index={index}
                                remove={false}
                                isSelect={item.isActive}
                                select={true}
                                onClick={() =>
                                  this.onSelectLocation(item, index)
                                }
                                onRemove={() =>
                                  this.onSelectLocation(item, index)
                                }
                                format={"text"}
                                text={item.tagName}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      {/*Reservation Contact*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Reservation contact
                          </div>
                        </div>

                        <div
                          className={
                            " d-flex align-item-center justify-content-between mt-1"
                          }
                        >
                          <div className="shw_textWrapper">
                            <Input
                              elementConfig={dataForm.mobileNo.elementConfig}
                              elementType={dataForm.mobileNo.elementType}
                              required={dataForm.mobileNo.validation.required}
                              isValid={!dataForm.mobileNo.valid}
                              touched={dataForm.mobileNo.touched}
                              value={dataForm.mobileNo.value}
                              isShowRequired={false}
                              size={"sm"}
                              margin={"m-b-1 m-t-2"}
                              label={dataForm.mobileNo.label}
                              changed={(event) => {
                                console.log(event);
                                this.inputHandleChange(
                                  event,
                                  dataForm.mobileNo.key
                                );
                              }}
                            />
                          </div>
                          <div className={"action-bar-right"}>
                            <Button
                              text={"Add to list"}
                              type={"btn-edit"}
                              size={"sm primary"}
                              onClick={async () => {
                                const updateForm = { ...this.state.dataForm };
                                if (updateForm.mobileNo.value) {
                                  this.setState({
                                    reservationMobileNo:
                                      dataForm.mobileNo.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className={
                            "d-flex align-item-center justify-content-between mt-1"
                          }
                        >
                          <div className="shw_textWrapper">
                            <Input
                              elementConfig={dataForm.email.elementConfig}
                              elementType={dataForm.email.elementType}
                              required={dataForm.email.validation.required}
                              isValid={!dataForm.email.valid}
                              touched={dataForm.email.touched}
                              value={dataForm.email.value}
                              isShowRequired={false}
                              size={"sm"}
                              margin={"m-b-1 m-t-2"}
                              label={dataForm.email.label}
                              changed={(event) => {
                                console.log(event);
                                this.inputHandleChange(
                                  event,
                                  dataForm.email.key
                                );
                              }}
                            />
                          </div>
                          <div className={"action-bar-right"}>
                            <Button
                              text={"Add to list"}
                              type={"btn-edit"}
                              size={"sm primary"}
                              onClick={async () => {
                                const updateForm = { ...this.state.dataForm };
                                if (updateForm.email.value) {
                                  this.setState({
                                    reservationEmail: dataForm.email.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className={"d-flex  flex-row mt-1"}>
                          {reservationEmail && (
                            <SelectBox
                              index={0}
                              remove={false}
                              isSelect={true}
                              select={true}
                              // onClick={() => this.onSelectLocation(item, index)}
                              onRemove={() => {
                                if (reservationEmail) {
                                  this.setState({
                                    reservationEmail: null,
                                  });
                                }
                              }}
                              format={"text"}
                              text={reservationEmail}
                            />
                          )}

                          {reservationMobileNo && (
                            <SelectBox
                              index={0}
                              remove={false}
                              isSelect={true}
                              select={true}
                              // onClick={() => this.onSelectLocation(item, index)}
                              onRemove={() => {
                                if (reservationMobileNo) {
                                  this.setState({
                                    reservationMobileNo: null,
                                  });
                                }
                              }}
                              format={"text"}
                              text={reservationMobileNo}
                            />
                          )}
                        </div>
                      </div>
                      {/*location*/}

                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Recent offer
                          </div>
                        </div>

                        {recentOffers && recentOffers.length > 0 && (
                          <>
                            <Slider
                              {...settings}
                              className={
                                "rpmw__slider d-flex flex-row justify-content-center mb-2"
                              }
                            >
                              {((recentOffers && recentOffers) || []).map(
                                (item, index) => (
                                  <div className="rpmw__s-item">
                                    <RecentOfferCard
                                      header={item && item.header}
                                      label={item && item.label}
                                      status={item && item.status}
                                      bankLogo={
                                        item &&
                                        item?.bank[0] &&
                                        item?.bank[0].logoURL
                                      }
                                      isSelect={
                                        selectOffer
                                          ? selectOffer?.offerId ===
                                            item.offerId
                                          : false
                                      }
                                      createdAt={item && item.createdAt}
                                      imgUrl={item && item.bannerImageURL}
                                      onSelectedMerchant={() => {
                                        if (this.state.selectOffer) {
                                          this.setState({
                                            selectOffer: null,
                                          });
                                        } else {
                                          this.setState({
                                            selectOffer: item,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </Slider>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Scrollbars>

              {editMerchant && (
                <div
                  className={
                    "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="page-number">{formWizard} of 2</div>
                  <div>
                    {formWizard !== 1 && (
                      <Button
                        leftIconFill={true}
                        iconName={<RiArrowLeftLine />}
                        leftIcon={false}
                        rightIcon={false}
                        option={"ml-1"}
                        isLoading={false}
                        isActiveAnimation={false}
                        reactRightIcon={GetIcon("")}
                        onClick={async () => {
                          this.setState({ formWizard: formWizard - 1 });
                        }}
                        LeftIconText={null}
                        text={"Back"}
                        size={"md"}
                        type={"btn-secondary"}
                      />
                    )}

                    <Button
                      leftIconFill={true}
                      iconName={
                        formWizard === 2 ? (
                          <RiCheckLine />
                        ) : (
                          <RiArrowRightLine />
                        )
                      }
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (formWizard === 2) {
                          this.props.onClickSelectedMerchant(selectMerchant);
                          if (this.props.editOfferData) {
                            this.props.onClickSelectOffer(
                              this.props.editOfferData
                            );
                          } else {
                            this.props.setCreateOfferData({
                              selectOffer:
                                this.state.selectOffer ||
                                this.props.selectedOfferData,
                              selectedMerchant: selectMerchant,
                              selectedBank: selectedBank,
                              selectedCategory: selectedCategory,
                              selectLocations: selectLocations,
                              selectTerms: termsConditions,
                            });
                            if (
                              this.props.selectedOfferData &&
                              this.props.selectedOfferData?.draftId
                            ) {
                              let resDraft = await updateDraft(
                                {
                                  ...this.props.selectedOfferData,
                                  merchantId: selectMerchant?.merchantId,
                                },
                                this.props.selectedOfferData?.draftId
                              );
                              console.log({ resDraft });
                              this.props.refreshOffers();
                            }
                          }
                          this.props.onClickCloseEditMerchant();
                        } else if (
                          formWizard === 1 &&
                          selectedMerchantID !== null
                        ) {
                          this.setState({ formWizard: 2 });
                        } else {
                          return false;
                        }
                      }}
                      LeftIconText={null}
                      text={formWizard === 2 ? "Update" : "Next"}
                      size={"md"}
                      type={"btn-primary"}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditMerchant: state.offerReducer.isEditMerchant,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    bankData: state.bankReducer.bankData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseEditMerchant: () =>
      dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    clearTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.CLEAR_TERMS_AND_CONDITION }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
