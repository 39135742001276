import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import ConsoleComponent from "../containers/console";
import LoginPageComponent from "../containers/login";
import OtpVerificationComponent from "../containers/otpVerification";
import AuthenticationComponent from "../containers/authentication";
import BankAccountComponent from "../containers/BankAccountDetails";
import AccountBlockedComponent from "../containers/accountBlocked";

const Index = (props) => (
  <Switch>
    <PublicRoute component={LoginPageComponent} path="/sign-in" exact />
    <PublicRoute component={AuthenticationComponent} path="/authentication" exact />
    <PublicRoute component={OtpVerificationComponent} path="/verification" exact />
    <PublicRoute component={AccountBlockedComponent} path="/blocked" exact />
    <PublicRoute component={BankAccountComponent} path="/payment" exact />
    <PublicRoute component={ConsoleComponent} path="/" exact />
    <PublicRoute component={ConsoleComponent} path="/:module" exact />
    <PublicRoute component={ConsoleComponent} path="/:module/:module" exact />
    {/*<PublicRoute component={ConsoleComponent} path="/:module/:module/:module"/>*/}
    {/*<PublicRoute component={ConsoleComponent} path="/:module/:module/:module/:module"/>*/}
  </Switch>
);

export default Index;
