import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import {
  Button,
  ButtonIcon,
  MenuCardNew,
  ProfileBox,
} from "../../../../components";
import { Spin, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import Pending from "./Pending/index";
import Paid from "./Paid/index";
import Rejected from "./Rejected/index";
import {
  getAdminUser,
  getOffer,
  getSessionValidateStatus,
  getUserById,
} from "../../../../api";
import { getToken, userDetails } from "../../../../utils";

const { TabPane } = Tabs;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      pendingCount: 0,
      acceptedCount: 0,
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Pending",
          content: <Pending />,
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Paid",
          content: <Paid />,
        },
        {
          id: uuidv4(),
          key: 3,
          tabName: "Rejected",
          content: <Rejected />,
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    let state = await userDetails();
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      console.log("state::::", state);
      if (state && state.userId) {
        let res = await getUserById(state?.userId);
        console.log("getUserById", res);
        if (res && res.status) {
          const token = await getToken();
          let user = res.data;
          let data = {
            ...user,
            token: token,
          };
          console.log("getUserById:::", data);

          this.setState({
            user: data,
            isLoading: false,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { viewPointHeight, isLoading, tabJson, user } = this.state;
    const { isPayment } = this.props;
    console.log("user>>>>", user);

    return (
      <>
        <div
          className={"cco__mbc-group"}
          style={{ paddingBottom: "0", paddingLeft: "0", paddingRight: "0" }}
        >
          <div
            className={"d-flex flex-row justify-content-between"}
            style={{ marginLeft: "24px", marginRight: "24px" }}
          >
            <div className={"coo__mbcg-header"}>{"Payment"}</div>
            <div>
              <Button
                text={"Select All"}
                type={"btn-edit"}
                size={"lg secondary"}
                // onClick={onClick}
              />
            </div>
          </div>
          <div className={"cco__mbcg-tab-bar"}>
            <Tabs>
              {((tabJson && tabJson) || []).map((i, t) => (
                <TabPane tab={i.tabName} key={i.key}>
                  {this.state.isLoading && (
                    <div style={{ margin: "20% 50%", opacity: 0.5 }}>
                      <Spin size="large" spinning={isLoading} />
                    </div>
                  )}
                  {!this.state.isLoading && <>{i.content}</>}
                </TabPane>
              ))}
            </Tabs>
          </div>
          <div></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPayment: state.paymentReducer.isPayment,
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
