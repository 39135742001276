import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../../../store/actions/types";
import { Button, Input, FindMerchantCard } from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import {
  cooFindMerchantByName,
  cooGetAllMerchantByPage,
  cooGetMerchantOfferCount,
} from "../../../merchant/service/merchant.service";
import { Scrollbars } from "react-custom-scrollbars";
import { AutoComplete } from "antd";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      tags: [],
      tag: null,
      bankData: null,
      dataForm: {
        searchMerchant: {
          key: "searchMerchant",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  async getAll() {
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 100 });
    this.setState({
      dataSource: res && res.data && res.data.data,
      isLoading: false,
    });
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  autoCompleteInputHandleChange = (event, key) => {
    const updatedDataForm = {
      ...this.state.dataForm,
      [key]: {
        ...this.state.dataForm[key],
        value: event,
        touched: true,
      },
    };
    this.setState({ dataForm: updatedDataForm });
  };

  onSelectAutoComplete(data) {
    console.log("onSelect", data);
  }

  async fetchSuggestions(query) {
    console.log("query", query);
    if (!query.trim()) {
      console.log("query", query.length);
      this.setState({ option: [] });
      return;
    }
    try {
      const response = await cooFindMerchantByName(query?.trim());
      console.log("merchantSearch", response);
      const suggestions = response?.data?.data.map((merchant) => ({
        id: merchant.merchantId,
        value: merchant.merchantName,
        label: merchant.merchantName,
      }));
      this.setState({ option: suggestions });
      if (response?.data?.data?.length > 0) {
        this.setState({
          dataSource: response?.data?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  render() {
    const {
      isOpenFindTermsAndConditions,
      isSelectedExistingTerms,
      existingTermsList,
      termsAndConditionList,
      selectBankId,
    } = this.props;
    const { viewPointHeight, dataForm, tags, isLoading, dataSource, option } =
      this.state;

    return (
      <section
        className={`coo__modal-main-wrapper modal-index-top animate__animated ${
          isOpenFindTermsAndConditions ? "show-me" : "hide-me"
        } `}
      >
        <div className="row justify-content-md-center">
          <div className={"col-8"}>
            <div
              className={"coo_modal-body-content"}
              style={{
                zIndex: "999999",
                top: "1rem",
                padding: "32px 24px 24px 24px",
              }}
            >
              <div className={"coo_mbc__header mb-3"}>
                {"Find Terms and Conditions"}
              </div>
              <div
                className={`close-modal-wrapper__icon `}
                onClick={async () => {
                  this.props.closeModal();
                }}
              >
                <RiCloseLine />
              </div>
              <AutoComplete
                value={dataForm.searchMerchant.value}
                options={option}
                style={{ width: "100%" }}
                onSelect={(data) => {
                  this.onSelectAutoComplete(data);
                }}
                onChange={(event) =>
                  this.autoCompleteInputHandleChange(
                    event,
                    dataForm.searchMerchant.key
                  )
                }
                onSearch={async () => {
                  await this.fetchSuggestions(dataForm.searchMerchant.value);
                  // this.props.onClickClearSelected();
                  // this.props.clearSearchMerchant();
                }}
                placeholder="input here"
              />
              <Scrollbars
                style={{ height: viewPointHeight - 250 }}
                thumbMinSize={30}
                universal={true}
                autoHide
              >
                <div
                  className={
                    "coo_modal-content-wrapper d-flex flex-column mt-2"
                  }
                >
                  {dataSource && dataSource.length > 0 && (
                    <>
                      {dataSource
                        .filter((item) => {
                          if (dataForm.searchMerchant.value === "") {
                            return item; // Corrected the return statement
                          } else if (
                            item.merchantName
                              ?.toLowerCase()
                              .includes(
                                dataForm.searchMerchant.value.toLowerCase()
                              )
                          ) {
                            return item; // Corrected the return statement
                          }
                        })
                        .map((item, index) => (
                          <FindMerchantCard
                            logoURL={item?.logoURL}
                            merchantName={item?.merchantName}
                            isActive={
                              item?.merchantId ===
                              this.props.selectMerchantData?.merchantId
                            }
                            webURL={item?.webURL}
                            status={item?.status}
                            createdAt={item?.createdAt}
                            location={item?.location}
                            favoriteArea={item?.favoriteArea}
                            termsAndCondition={item?.termsAndCondition}
                            cardType={"merchant"}
                            bankData={this.props.bankData}
                            onClickDelete={async () => {
                              console.log("event fire....");
                            }}
                            onClickEdit={async () => {
                              this.props.onClickEditMerchant(item);
                              this.props.onLoadTermsAndCondition(
                                item?.termsAndCondition
                              );
                              console.log("onLoadTermsAndCondition", item);
                              //offer count
                              let res = await cooGetMerchantOfferCount(
                                item?.merchantId
                              );
                              if (res) {
                                console.log("totalCount", res);
                                this.props.setNoOfCount(
                                  Number(res?.data?.data[0]?.totalCount)
                                );
                              }
                            }}
                          />
                        ))}
                    </>
                  )}
                </div>
              </Scrollbars>
              <div className={"ftc__-footer-action-wrapper"}>
                <div>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    option={"mr-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      this.props.closeModal();
                    }}
                    text={"Cancel"}
                    size={"md"}
                    type={"btn-block-back"}
                  />
                </div>
                {isSelectedExistingTerms && (
                  <div>
                    <Button
                      leftIconFill={true}
                      iconName={<RiCheckLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        //todo confirm modal
                        console.log("existingTermsList", existingTermsList);
                        //
                        this.props.onClickAddTermsIntoList({
                          bankId: selectBankId,
                          terms: existingTermsList,
                        });
                        console.log(
                          "termsAndConditionList",
                          termsAndConditionList
                        );
                        this.props.closeModal();
                      }}
                      LeftIconText={"Update"}
                      text={""}
                      size={"md"}
                      type={"btn-block"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isBankCardType: state.offerReducer.isBankCardType,
    editBank: state.offerReducer.editBank,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectedBank: state.offerReducer.selectedBank,
    selectedCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    isOpenFindTermsAndConditions:
      state.termsReducer.isOpenFindTermsAndConditions,
    bankData: state.bankReducer.bankData,
    isSelectedExistingTerms: state.termsReducer.isSelectedExistingTerms,
    existingTermsList: state.termsReducer.existingTermsList,
    selectBankId: state.termsReducer.selectBankId,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () =>
      dispatch({ type: actionTypes.CLOSE_FIND_TERMS_AND_CONDITION }),
    updateSelectedBank: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SELECTED_BANK, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickAddTermsIntoList: (payload) =>
      dispatch({ type: actionTypes.ADD_EXISING_TERMS_INTO_LIST, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
