import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isTermsAndConditions: false,
  isNewTermsAndConditions: false,
  isOpenFindTermsAndConditions: false,
  termsAndConditionList: {},
  offerTermsAndConditionList: {},
  isSelectedExistingTerms: false,
  selectBankId: null,
  existingTermsList: {},
  isClearAll: false,
  isAddNew: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.OPEN_TERMS_AND_CONDITIONS_MODAL:
      draft.isTermsAndConditions = true;
      draft.isAddNew = false;
      return draft;
    case actionTypes.CLOSE_TERMS_AND_CONDITIONS_MODAL:
      draft.isTermsAndConditions = false;
      draft.selectBankId = null;
      draft.isSelectedExistingTerms = false;
      draft.isClearAll = false;
      draft.isAddNew = false;
      return draft;
    case actionTypes.OPEN_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL:
      draft.isNewTermsAndConditions = true;
      draft.termsAndConditionList = action.payload;
      return draft;
    case actionTypes.CLOSE_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL:
      draft.isNewTermsAndConditions = false;
      // draft.termsAndConditionList= {};
      return draft;
    case actionTypes.ADD_NEW_TERMS_INTO_LIST:
      console.log("ADD_NEW_TERMS_INTO_LIST", {
        ...draft.termsAndConditionList,
      });
      const { bankId, parts } = action.payload;
      const existingList = draft.existingTermsList
        ? draft.existingTermsList[action.payload?.bankId]
        : {};

      if (existingList) {
        draft.existingTermsList = { ...draft.existingTermsList } || {};

        if (!draft.existingTermsList[bankId]) {
          draft.existingTermsList[bankId] = [];
        }

        draft.existingTermsList[bankId].push(parts);
      } else {
        draft.termsAndConditionList = { ...draft.termsAndConditionList } || {};

        if (!draft.termsAndConditionList[bankId]) {
          draft.termsAndConditionList[bankId] = [];
        }

        draft.termsAndConditionList[bankId].push(parts);
      }
      draft.isAddNew = true;
      return draft;
    case actionTypes.EDIT_TERMS_INTO_LIST:
      console.log("EDIT_TERMS_INTO_LIST", action.payload?.bankId);
      const { id, newValue } = action.payload;
      const partList = draft.termsAndConditionList[action.payload?.bankId];
      const existing = draft.existingTermsList
        ? draft.existingTermsList[action.payload?.bankId]
        : {};
      if (partList) {
        const part = partList.find((item) => item.id === id);
        if (part) {
          part.value = newValue;
        }
      }
      if (existing) {
        const part = existing.find((item) => item.id === id);
        if (part) {
          part.value = newValue;
        }
      }
      return draft;
    case actionTypes.DELETE_TERMS_INTO_LIST:
      console.log("DELETE_TERMS_INTO_LIST");
      const { partId } = action.payload;
      const partListFilter =
        draft.termsAndConditionList[action.payload?.bankId];
      const existingTerms = draft.existingTermsList
        ? draft.existingTermsList[action.payload?.bankId]
        : {};
      if (partListFilter) {
        draft.termsAndConditionList[action.payload?.bankId] =
          partListFilter.filter((item) => item.id !== partId);
        if (draft.termsAndConditionList[action.payload?.bankId].length === 0) {
          delete draft.termsAndConditionList[action.payload?.bankId];
        }
      }
      if (existingTerms) {
        draft.existingTermsList[action.payload?.bankId] = existingTerms.filter(
          (item) => item.id !== partId
        );
        if (draft.existingTermsList[action.payload?.bankId].length === 0) {
          delete draft.existingTermsList[action.payload?.bankId];
        }
      }
      return draft;
    case actionTypes.CLEAR_ALL_TERMS_INTO_LIST:
      console.log("CLEAR_ALL_TERMS_INTO_LIST");
      const selectListFilter =
        draft.termsAndConditionList[action.payload?.bankId];
      const existTerms = draft.existingTermsList
        ? draft.existingTermsList[action.payload?.bankId]
        : {};
      if (selectListFilter) {
        draft.termsAndConditionList[action.payload?.bankId] = [];
        if (draft.termsAndConditionList[action.payload?.bankId].length === 0) {
          delete draft.termsAndConditionList[action.payload?.bankId];
        }
      }
      if (existTerms) {
        draft.existingTermsList[action.payload?.bankId] = [];
        if (draft.existingTermsList[action.payload?.bankId].length === 0) {
          delete draft.existingTermsList[action.payload?.bankId];
        }
      }
      draft.isClearAll = true;
      return draft;
    case actionTypes.LOAD_TERMS_AND_CONDITION:
      draft.termsAndConditionList = {};
      console.log("LOAD_TERMS_AND_CONDITION", action.payload);
      draft.termsAndConditionList = action.payload;
      return draft;
    case actionTypes.OPEN_FIND_TERMS_AND_CONDITION:
      draft.isOpenFindTermsAndConditions = true;
      return draft;
    case actionTypes.CLOSE_FIND_TERMS_AND_CONDITION:
      draft.isOpenFindTermsAndConditions = false;
      //draft.termsAndConditionList= {};
      return draft;
    case actionTypes.SELECTED_EXISTING_TERMS:
      draft.existingTermsList = {};
      console.log("SELECTED_EXISTING_TERMS", action);
      draft.existingTermsList = action.payload?.terms;
      draft.selectBankId = action.payload?.bankId;
      draft.isSelectedExistingTerms = true;
      // draft.termsAndConditionList = {};
      draft.isTermsAndConditions = true;
      console.log("SELECTED_EXISTING_TERMS", { ...draft.existingTermsList });
      return draft;
    case actionTypes.REMOVE__SELECTED_EXISTING_TERMS:
      draft.existingTermsList = {};
      draft.selectBankId = null;
      draft.isSelectedExistingTerms = false;
      draft.isAddNew = false;
      return draft;
    case actionTypes.ADD_EXISING_TERMS_INTO_LIST:
      console.log(
        "ADD_EXISING_TERMS_INTO_LIST",
        { ...draft.termsAndConditionList },
        action
      );
      if (action.payload && action.payload.bankId != null) {
        draft.termsAndConditionList = {
          ...draft.termsAndConditionList,
          [action.payload.bankId]: action.payload.terms,
        };
      } else {
        console.error(
          "Invalid payload for ADD_EXISTING_TERMS_INTO_LIST:",
          action.payload
        );
      }
      return draft;

    case actionTypes.CLEAR_TERMS_AND_CONDITION:
      draft.termsAndConditionList = {};
      draft.existingTermsList = {};
      draft.isAddNew = false;
      return draft;
    case actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION:
      draft.termsAndConditionList = {};
      draft.termsAndConditionList = action.payload;
      return draft;
    case actionTypes.CLEAR_OFFERS_TERMS_AND_CONDITION:
      draft.termsAndConditionList = {};
      draft.isAddNew = false;
      return draft;
    default:
      return draft;
  }
});

// if (Array.isArray(parts[bankId])) {
//   parts[bankId].forEach(part => {
//     console.log("part", part);
//
//   });
// }
