import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const saveBank = async (
  bankName,
  contactNo,
  webURL,
  description,
  logoURL,
  tags
) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      bankName: bankName,
      contactNo: contactNo,
      webURL: webURL,
      description: description,
      logoURL: logoURL,
      cardTypes: tags,
    };

    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/bank`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_BANK_API:", err);
    throw err;
  }
};

export const getBank = async () => {
  try {
    const _token = await getToken();
    console.log({ _token });
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/banks`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_BANK_API:", err);
    throw err;
  }
};
