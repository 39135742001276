import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const syncSeylanOffers = async (category, key, categoryId) => {
  // Build query parameters

  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    // Make the GET request with params and headers
    const params = {
      file: category,
      category: category,
      key: key,
      categoryId: categoryId,
    };
    const response = await axios.get(`${BASE_URL}/sync/seylan/offers`, {
      params,
      headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncSeylanOfferData = async (id) => {
  // Build query parameters

  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    const response = await axios.get(
      `${BASE_URL}/get/sync/seylan/offer/${id}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const syncSeylanOffersToSync = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/sync/seylan/offers-to-sync`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncSeylanOffers = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/seylan/sync/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};
