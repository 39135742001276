import axios from "axios";
import * as mockResponses from "../mock_data/apiResponses";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const saveOffer = async (
  offerId,
  userId,
  categoryId,
  merchantId,
  bankId,
  label,
  header,
  bannerImageURL,
  referenceURL,
  offerType,
  offerRate,
  offerDateType,
  offerStart,
  offerEnd,
  customizeDate,
  description,
  cardTypes,
  validDate,
  startTime,
  endTime,
  validTimeDateDescription,
  termsConditions,
  validLocation,
  hotLine,
  promoCode
) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      offerId: offerId,
      userId: userId,
      categoryId: categoryId,
      merchantId: merchantId,
      bankId: bankId,
      label: label,
      header: header,
      offerDateType: offerDateType,
      offerStart: offerStart,
      offerEnd: offerEnd,
      customizeDate: customizeDate,
      validDescription: "",
      cardTypes: cardTypes,
      offerType: offerType,
      offerRate: offerRate,
      location: validLocation,
      hotLine: hotLine,
      description: description,
      termsConditions: termsConditions,
      bannerImageURL: bannerImageURL,
      referenceURL: referenceURL,
      promoCode: promoCode,
      gallery: [],
      validDate: validDate,
      startTime: startTime,
      endTime: endTime,
      validTimeDateDescription: validTimeDateDescription,
    };

    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/offer`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_OFFER_API:", err);
    throw err;
  }
};

export const saveSyncOffer = async (body) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };

    // Get response
    let response = await axios.post(`${BASE_URL}/add/new/sync/offer`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_OFFER_API:", err);
    throw err;
  }
};

export const getOffer = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByUser = async (userId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/offers/by/user/${userId}`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByPage = async (pageNo, pageSize) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/page/${pageNo}/${pageSize}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByStatus = async (pageNo, pageSize, status) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/status/${pageNo}/${pageSize}/${status}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByBank = async (bankId, pageNo, pageSize) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/bank/${bankId}/${pageNo}/${pageSize}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByCategory = async (categoryId, pageNo, pageSize) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/category/${categoryId}/${pageNo}/${pageSize}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByBankAndCategory = async (
  bankId,
  categoryId,
  pageNo,
  pageSize
) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/${bankId}/${categoryId}/${pageNo}/${pageSize}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByCategoriesBanksMultiple = async (
  body,
  pageNo,
  pageSize
) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.post(
      `${BASE_URL}/get/offers/filter/multiple/${pageNo}/${pageSize}`,
      body,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_FILTER_BY_CATEGORY_BANK_API:", err);
    throw err;
  }
};

export const getOfferDetailsByOfferId = async (offerId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/${offerId}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByOfferHeader = async (query) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/autocomplete/${query}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getExpiringOffers = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/expiring/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const updateOffersMultiple = async (body) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(`${BASE_URL}/offers/update/multiple`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const updateOfferStatusMultiple = async (body) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(
      `${BASE_URL}/offer/status/update/multiple`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const updateOfferDetails = async (body, offerId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // const body = {
    //   categoryId: categoryId,
    //   merchantId: merchantId,
    //   bankId: bankId,
    //   label: label,
    //   header: header,
    //   offerDateType: offerDateType,
    //   offerStart: offerStart,
    //   offerEnd: offerEnd,
    //   customizeDate: customizeDate,
    //   validDescription: validDescription,
    //   cardTypes: cardTypes,
    //   offerType: offerType,
    //   offerRate: offerRate,
    //   location: location,
    //   description: description,
    //   termsConditions: termsConditions,
    //   bannerImageURL: bannerImageURL,
    //   referenceURL: referenceURL,
    //   gallery: gallery,
    //   validDate: validDate,
    //   startTime: startTime,
    //   endTime: endTime,
    //   validTimeDateDescription: validTimeDateDescription,
    // };

    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/offer/details/${offerId}`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_OFFER_API:", err);
    throw err;
  }
};

export const updateSyncOffer = async (body, offerId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };

    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/sync/offer/${offerId}`,
      body,
      {
        headers: headers,
      }
    );
    return response?.data;
  } catch (err) {
    console.error("ERR_GET_SAVED_OFFER_API:", err);
    throw err;
  }
};

export const updateOfferStatus = async (body, offerId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/offer/status/${offerId}`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const deleteOffer = async (offerId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(`${BASE_URL}/offer/delete/${offerId}`, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const deleteOfferTerms = async (offerId, bankId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.delete(
      `${BASE_URL}/delete/offer/terms/${offerId}/${bankId}`,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const getBanksOffersCount = async (bankId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/banks/offers/count/${bankId}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getTemporaryOffersSampath = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/sampath/temp/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncOffersSampath = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/sampath/sync/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const syncSampathOffers = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/sync/sampath/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const syncSampathOffersToSync = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/sync/sampath/offers-to-sync`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const syncNTBOffers = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/sync/ndb/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getTemporaryOffersNTB = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/ntb/temp/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncOffersNDB = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/ndb/sync/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getAllOffersURLs = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/offers/url`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};
