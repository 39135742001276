import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {RiCheckLine, RiCloseLine, RiDeleteBinLine} from "react-icons/ri";
import {MdOutlineEdit} from "react-icons/md";
import {Popconfirm} from "antd";

const Index = (props, onClickRemove, onClickEdit) => (
    <>
      {props.type === "default" && (
          <div
              className={`info-row-view-wrapper d-flex ${props.type} 
              ${props.style} ${props.isSelect ? "is_active" : ""}
              ${props.isEdit ? "active-me" : ""}`}
              onClick={props.onClick}
          >
            <div className={`info_text`}>{props.text}</div>
            <div className={`info-check-box ${props.isEdit ? 'active-me' : ''}`}>
              {props.isSelect && (
                  <>
                    <RiCloseLine/>
                  </>
              )}
            </div>
          </div>
      )}
      {props.type === "terms" && (
          <div
              className={`info-row-view-wrapper d-flex ${props.type} ${props.style} ${
                  props.isSelect ? "is_active" : ""
              }`}
              onClick={props.onClick}
          >
            <div
                className={
                  "d-flex felx-row align-items-center justify-content-between"
                }
            >
              <div className={"info-check-box"}>
                {props.isSelect && (
                    <>
                      <RiCheckLine/>
                    </>
                )}
              </div>
              <div className={`info_text`}>{props.text}</div>
            </div>
            <div
                className={
                  "irvw_action d-flex felx-row align-items-center justify-content-between"
                }
            >
              <div className={`irvw_a-edit ${props.isEdit ? 'active-me' : ''}`}
                   onClick={props?.onClickEdit}>
                <MdOutlineEdit/>
              </div>
              <Popconfirm
                  title="Remove the term"
                  description="Are you sure to remove this term?"
                  placement="topRight"
                  onConfirm={props.onClickConfirm}
                  onCancel={props.onClickCancel}
                  okText="Yes"
                  cancelText="No"
              >
                <div className={"irvw_a-delete"}>
                  <RiDeleteBinLine/>
                </div>
              </Popconfirm>
            </div>
          </div>
    )}
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "",
};

export default Index;
