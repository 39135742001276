import * as constants from "../consts";
import moment from "moment";
import {v4 as uuidv4} from 'uuid';
import {getAppBaseUrl} from "./environment";

export const formatCurrency = (num, currency) => {
    if (!currency) {
        currency = constants.DEF_CURRENCY
    }
    let options = {style: 'currency', currency: currency};
    const formatter = new Intl.NumberFormat('en-US', options);
    return formatter.format(num).replace(`${currency}`, '');
}

export const formatCardNo = (num) => {
    let temp = num.match(/.{1,4}/g);
    return temp.join(' ');
}

export const getAccountTypeByCode = code => {
    return code === constants.ACC_TYPE_SAVINGS ? constants.ACC_TYPE_SAVINGS_LABEL : constants.ACC_TYPE_Current_LABEL;
};

export const getCurrencyDescription = currency => {
    let description = currency;
    switch (currency) {
        case constants.CURRENCY_LKR:
            description = "Sri lanka Rupee (LKR)";
            break;
    }
    return description;
};

export const sleep = milliseconds => {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

export const isLogin = () => {
    return false;
};

export const getMonthString = monthNumber => {
    let month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    return month[monthNumber];
}

export const getMonthShortString = monthNumber => {
    let month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    return month[monthNumber];
}

export const getShortStringToMonth = monthStr => {
    let month = {
        "JAN": "01",
        "FEB": "02",
        "MAR": "03",
        "APR": "04",
        "MAY": "05",
        "JUN": "06",
        "JUL": "07",
        "AUG": "08",
        "SEP": "09",
        "OCT": "10",
        "NOV": "11",
        "DEC": "12"
    };
    return month[monthStr.toUpperCase()];
}

export const formatNtbNotificationDateStringToDateString = ntbString => {
    let dateItems = ntbString.split(" ")[0].split("-")
    return `${dateItems[2]}-${getShortStringToMonth(dateItems[1].toUpperCase())}-${dateItems[0]}`;
}

export const formatNtbDateStringToDateString = ntbString => {
    let dateItems = ntbString.split(" ")[0].split("-")
    return `${dateItems[0]}-${getShortStringToMonth(dateItems[1])}-${dateItems[2]}`;
}

export const formatNtbDateStringToNotificationTimeString = ntbString => {
    let items = ntbString.split(" ")
    let dateItems = items[0].split("-")
    return `${dateItems.join(" ")} ${moment(items[1], "HH:mm:ss").format("h:mm:ss A")}`;
}

export const formatMomentDateToString = dateTime => {
    return `${dateTime.format("DD")}-${getMonthShortString(dateTime.month())}-${dateTime.format("YYYY")}`
}

export const formatMomentDateToStringForValidateCustomer = dateTime => {
    return `${dateTime.format("DD")}-${getMonthShortString(dateTime.month())}-${dateTime.format("YYYY")}`
}

export const formatMomentDateToStringYYMM = dateTime => {
    return `${dateTime.format("YY")}${dateTime.format("MM")}`
}

export const capitalizeFirstLetter = input => {
    return input[0].toUpperCase() + input.slice(1);
}

export const capitalizeAllFirstLetters = input => {
    let words = input.split(" ");
    for (let x = 0; x < words.length; x++) {
        words[x] = words[x][0].toUpperCase() + words[x].slice(1);
    }
    return words.join(" ");
}

export const simpleFirstLetters = input => {
    let words = input.split(" ");
    for (let x = 1; x < words.length; x++) {
        words[x] = words[x][0].toLowerCase() + words[x].slice(1);
    }
    return words.join(" ");
}

export const getSimpleNameForAccType = input => {
    switch (input) {
        case constants.ACC_TYPE_SAVINGS:
            return constants.ACC_TYPE_SAVINGS_LABEL.toLowerCase();
        case constants.ACC_TYPE_CURRENT:
            return constants.ACC_TYPE_Current_LABEL.toLowerCase();
    }
    return undefined
}

export const getCardExpiryFormatted = input => {
    if (!input) {
        return "XX/XX"
    }
    if (input.includes("/")) {
        return input;
    }
    let ch = input.split("");
    if (ch.length !== 4) {
        return input;
    }
    return `${ch[2]}${ch[3]}/${ch[0]}${ch[1]}`
}

export const getDefaultSourceAccount = accs => {
    let firstItem = undefined;
    for (let rootItem of accs) {
        for (let item of rootItem.data) {
            if (!firstItem)
                firstItem = item;

            if (item.isPrimary)
                return item;

        }
    }
    return firstItem;
};

export const getTitleString = (input, isAllOthersLower) => {
    if (isAllOthersLower)
        input = input.toLowerCase();
    return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getTitleDateString = (input) => {
    let tokens = input.split("-")
    if (tokens[0].length === 1) {
        tokens[0] = `0${tokens[0]}`
    }
    tokens[1] = tokens[1].toLowerCase();
    tokens[1] = tokens[1].charAt(0).toUpperCase() + tokens[1].slice(1)
    return tokens.join("-");
};

export const getUuid = () => {
    return uuidv4();
}

export const getMaskedPhoneNumber = (input) => {
    if (!input)
        return "";

    input = input.trim();
    if (!input.startsWith("+")) {
        input = `+${input}`;
    }

    let inputArr = input.split("");
    if (inputArr.length >= 10) {
        for (let x = 0; x < inputArr.length; x++) {
            if (x > 3 && x < 10) {
                inputArr[x] = "*";
            }
        }
        return inputArr.join("");
    }

    return input;

}

export const getNowTimeBeautified = () => {
    //16-Aug-2021 12:56 PM
    let nt = moment()
    return `${nt.date()}-${nt.month() + 1}-${nt.year()} ${nt.format('hh:mm A')}`;
}

export const insertToArrayIndex = (arr, index, newArray) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    ...newArray,
    // part of the array after the specified index
    ...arr.slice(index)
]

export const in_array = (array, needle) => {
    for (let element of array) {
        if (element === needle) {
            return true;
        }
    }
    return false;
};

export const in_array_includes = (array, element) => {
    for (let needle of array) {
        if (element.includes(needle)) {
            return true;
        }
    }
    return false;
};

export const getDeepCopy = input => {
    return JSON.parse(JSON.stringify(input));
}

export const disableBackButton = () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
    });
}

export const preFillDate = date => {
    let dateParts = date.split("-");
    if (dateParts[0].length === 1) {
        dateParts[0] = `0${dateParts[0]}`
    }
    return dateParts.join("-")
}

export const stripAmount = input => {
    return input.trim().replaceAll(",", "");
}

export const getProxyImageUrl = url => {
    if (url && url.toLowerCase().includes("firebase")) {
        url = url.replace("https://firebasestorage.googleapis.com/", "");
        return `${getAppBaseUrl()}/image/${url}`
    }
    return url;
}

export const calculateTxLimitStatus = (globalLimit, userLimit, amount) => {
    if (globalLimit) {
        if (amount > globalLimit) {
            return "FAIL";
        } else {
            if (userLimit) {
                if (amount > userLimit) {
                    //user limit over.. request OTP
                    return "OTP";
                } else {
                    //under user limit
                    return "SUCCESS";
                }
            } else {
                //no user limit and under global limit. hence pass
                return "SUCCESS";
            }
        }
    } else {
        if (userLimit) {
            if (amount > userLimit) {
                //user limit over.. request OTP
                return "OTP";
            } else {
                //under user limit
                return "SUCCESS";
            }
        } else {
            //no user limit, no global limit
            return "SUCCESS"
        }
    }
}


export const formatPoints = (num) => {
    if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const sanitizeBase64Image = (img) => {
    return img.split(",")[1];
}
