import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { RiCheckLine } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`image-accepted-wrapper ${props.type} ${props.size} 
        `}
      onClick={props.onClick}
    >
      <div className="ia_icon"> {props.buttonIcon}</div>

      <div className="ia_title">{props.deviceName}</div>
      <div className="ia_text">{props.text}</div>
    </div>
  </>
);
Index.propTypes = {
  buttonIcon: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "dark",
  size: "md",
  icon: "<RiComputerFill/>",
  deviceName: "Desktop",
  text: "",
};

export default Index;
