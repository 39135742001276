import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className={`action-btn-wrapper ${props.isActive ? "active" : ""} ${
        props.size
      }`}
      onClick={props.onClick}
    >
      {props.btnName}
    </div>
  </>
);

Index.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "light",
  size: "sm",
  icon: "<RiCloseFill/>",
};

export default Index;
