import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";

import { BankCardSmall, Button, ButtonIcon, Input } from "../../../components";

import { Collapse } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCheckLine,
  RiCloseLine,
} from "react-icons/ri";
import { getBank } from "../../../api";
import { userDetails } from "../../../utils";
import { updateDraft } from "../../../api/draft";

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        searchBank: {
          key: "searchBank",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search bank name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Find bank",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.setState({
      formWizard: 1,
    });
    let user = await userDetails();
    if (user && user.userId) {
      await this.getAll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    this.setState({ isLoading: true });
    let resBank = await getBank();

    this.setState({
      dataSourceBanks: resBank && resBank.data,
      isLoading: false,
      formWizard: 1,
    });
  }

  async refresh() {
    this.setState({ isLoading: true });
    let resBank = await getBank();

    this.setState({
      dataSourceBanks: resBank && resBank.data,
      isLoading: false,
      selectLocations: [],
      termsConditions: [],
      formWizard: 1,
    });
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectMerchant !== this.props.selectMerchant) {
      const updateForm = { ...this.state.dataForm };
      for (let key in updateForm) {
        updateForm[key].value = "";
        updateForm[key].touched = false;
      }
      const selectedMerchant = this.props.selectMerchant;
      const bankId =
        this.props.selectBank?.bankId || this.props.editOfferData?.bankId;

      if (selectedMerchant?.merchantId) {
        this.setState((state) => {
          let selectLocations = [];
          let termsConditions = [];
          for (let i = 0; i < selectedMerchant?.location?.length; i++) {
            selectLocations.push({
              id: selectedMerchant && selectedMerchant.location[i].id,
              tagName: selectedMerchant && selectedMerchant.location[i].tagName,
              count: selectedMerchant && selectedMerchant.location[i].count,
              isActive: false,
            });
          }
          if (
            bankId &&
            selectedMerchant?.termsAndCondition &&
            Object.keys(selectedMerchant?.termsAndCondition).length > 0 &&
            selectedMerchant?.termsAndCondition[bankId]
          ) {
            termsConditions = selectedMerchant?.termsAndCondition[bankId].map(
              (term) => ({
                id: term.id,
                value: term.value,
                count: term?.count,
                isActive: term.isActive,
              })
            );
          } else {
            if (
              selectedMerchant &&
              !Array.isArray(selectedMerchant.termsAndCondition)
            ) {
              console.error("selectedTerms is not an array");
            } else {
              for (
                let i = 0;
                i < selectedMerchant?.termsAndCondition.length;
                i++
              ) {
                const term =
                  selectedMerchant && selectedMerchant.termsAndCondition[i];
                termsConditions.push({
                  id: term.id,
                  value: term.value,
                  count: term?.count,
                  isActive: term.isActive,
                });
              }
            }
          }
          state.selectLocations.concat(selectLocations);

          return {
            selectLocations,
            termsConditions,
          };
        });
      }
    }
    if (prevProps.isOpenSearchMerchant !== this.props.isOpenSearchMerchant) {
      const openMerchant = this.props.isOpenSearchMerchant;

      if (openMerchant) {
        this.setState({
          formWizard: 1,
          // selectedMerchantID: null,
          // selectedBankId: null,
          // selectedCategoryId: null,
        });
      }
    }
    if (prevProps.editBank !== this.props.editBank) {
      const editBank = this.props.editBank;

      if (editBank) {
        this.setState({
          formWizard: 1,
          selectedBank: editBank,
          selectedBankId: editBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
        });
      }
    }
  }

  render() {
    const {
      viewPointHeight,
      dataForm,
      dataSourceBanks,
      isLoading,
      formWizard,
      selectLocations,
      termsConditions,
      selectedBankId,
      selectedBank,
    } = this.state;
    const {
      selectMerchant,
      editBank,
      isEditBank,
      selectedOfferData,
      selectCategory,
    } = this.props;

    return (
      <>
        {this.props.isEditBank && (
          <section
            className={`coo__modal-main-wrapper animate__animated ${
              isEditBank ? "show-me" : "hide-me"
            } `}
          >
            <div
              className={"coo_modal-body-content fix-906 d-flex flex-column "}
              style={{ height: "97%" }}
            >
              <div
                className={
                  "d-flex flex-row justify-content-between align-items-center"
                }
              >
                <div className={"d-flex justify-content-between"}>
                  <div className={"coo_mbc__header mr-4"}>{"Edit Bank"}</div>
                  <Button
                    text={"Refresh"}
                    type={"btn-edit"}
                    isLoading={false}
                    onClick={async () => {
                      await this.refresh();
                    }}
                    size={"sm primary"}
                  />
                </div>

                <div
                  className={`close-modal-wrapper `}
                  onClick={() => {
                    this.props.onClickCloseEditModal();
                    // this.props.onClickClearSelected();
                    // this.props.clearSearchMerchant();
                  }}
                >
                  <RiCloseLine />
                </div>
              </div>

              {formWizard === 1 && (
                <div className={"merchant-search-wrapper mb-3"}>
                  <div className={"mt-1"}>
                    <Input
                      elementConfig={dataForm.searchBank.elementConfig}
                      elementType={dataForm.searchBank.elementType}
                      required={dataForm.searchBank.validation.required}
                      isValid={!dataForm.searchBank.valid}
                      touched={dataForm.searchBank.touched}
                      value={dataForm.searchBank.value}
                      isShowRequired={false}
                      size={"sm"}
                      margin={"m-b-1 m-t-2"}
                      label={dataForm.searchBank.label}
                      changed={
                        (event) =>
                          this.inputHandleChange(event, dataForm.searchBank.key)
                        // console.log("event changed")
                      }
                    />
                    {/*<div className={"search-action-left-wrp"}></div>*/}
                  </div>
                </div>
              )}

              <Scrollbars
                style={{ height: viewPointHeight - 220 }}
                thumbMinSize={30}
                renderScrollbarVertical={({ style, ...props }) => {
                  return (
                    <div
                      style={{
                        ...style,
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                      {...props}
                    />
                  );
                }}
                universal={true}
                autoHide
              >
                {/*banks list view */}
                {formWizard === 1 && (
                  <div className={"merchant-list-wrapper "}>
                    {dataSourceBanks && dataSourceBanks.length > 0 && (
                      <>
                        {dataSourceBanks
                          .filter((item) => {
                            console.log("clicked", item);
                            if (dataForm.searchBank.value === "") {
                              return item; // Corrected the return statement
                            } else if (
                              item.bankName
                                .toLowerCase()
                                .includes(
                                  dataForm.searchBank.value.toLowerCase()
                                )
                            ) {
                              return item; // Corrected the return statement
                            }
                          })
                          .map((item, index) => (
                            // <BankCard
                            //   onClick={async () => {
                            //     this.setState({
                            //       selectedBankId: item.bankId,
                            //       selectedBank: item,
                            //     });
                            //   }}
                            //   isActive={item && item.bankId === selectedBankId}
                            //   name={item && item.bankName}
                            //   logo={item && item.logoURL}
                            //   isEditButton={false}
                            // />
                            <BankCardSmall
                              onClick={async () => {
                                this.setState({
                                  selectedBankId: item.bankId,
                                  selectedBank: item,
                                });
                              }}
                              isActive={item && item.bankId === selectedBankId}
                              name={item && item.bankName}
                              logo={item && item.logoURL}
                              isEditButton={false}
                              bankId={item && item.bankId}
                            />
                          ))}
                      </>
                    )}
                  </div>
                )}
              </Scrollbars>

              {editBank && (
                <div
                  className={
                    "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="page-number"> 1</div>
                  <div>
                    <Button
                      leftIconFill={true}
                      iconName={<RiCheckLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (editBank?.bankId) {
                          this.props.setCreateOfferData({
                            selectOffer: selectedOfferData,
                            selectedMerchant: selectMerchant,
                            selectedBank: selectedBank,
                            selectedCategory: selectCategory,
                            selectLocations: selectLocations,
                            selectTerms: termsConditions,
                          });
                          if (selectedOfferData && selectedOfferData?.draftId) {
                            let resDraft = await updateDraft(
                              {
                                ...selectedOfferData,
                                bankId: selectedBank?.bankId,
                              },
                              selectedOfferData?.draftId
                            );
                            console.log({ resDraft });
                            this.props.refreshOffers();
                          }
                          this.props.onClickCloseEditModal();
                        } else {
                          return false;
                        }
                      }}
                      LeftIconText={null}
                      text={"Update"}
                      size={"md"}
                      type={"btn-primary"}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditBank: state.offerReducer.isEditBank,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseEditModal: () =>
      dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
