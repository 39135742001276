import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../store/actions/types";
import { Scrollbars } from "react-custom-scrollbars";

import { login, userDetails, userLogOut, getToken } from "../../utils";
import { authentication, otpVerify, reSendOtp } from "../../api";

import { GetIcon } from "../../config/icon";

import {
  Input,
  Button,
  JoinFormHeader,
  OtpResendTimer,
} from "../../components";
import { OpenNotification } from "../../config/notification";
import { setUser } from "../../store/actions";

class OtpVerificationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      noOfSpot: 2,
      active: 1,
      isLoading: false,
      isDisable: false,
      attempt: 0,
      otp: null,
      dataForm: {
        otpVerification: {
          key: "otpVerification",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0 0 0 0 0 0",
            minLength: "6",
            maxLength: "6",
          },
          value: "",
          validation: {
            required: true,
            minLength: 6,
            maxLength: 6,
          },
          valid: false,
          touched: false,
          label: "Enter 5 digit code here",
        },
      },
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // await userLogOut();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    if (
      inputIdentity === "otpVerification" &&
      updateSignUpForm[inputIdentity].value.length <= 5
    ) {
      this.setState({ isDisable: false });
    } else {
      this.setState({ isDisable: true });
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async sendVerify(userId, OTP) {
    this.setState({ isLoading: true, displayOtpResend: false });
    const token = await getToken();

    let res = await otpVerify(userId, OTP, token);
    if (res && res.data && res.data.status) {
      console.log("sign in user:::", res.data.data);
      this.setState({ isLoading: false, displayOtpResend: false });
      login(res.data.data.token);
      let data = {
        ...res.data.data.user,
        token: res.data.data.token,
      };
      await this.props.setUser(data);
      // this.props.onAddLoginUser(res.data.data);
      let user = await userDetails();
      console.log("user:::", user);
      // this.props.onClickCloseOTPVerify();
      if (user && user.userId) {
        this.props.history.push("/authentication");
      }
    } else {
      this.setState({ isLoading: false, displayOtpResend: false });
      //todo

      OpenNotification({
        title: "One time password",
        message: "Invalid authentication, Please check again OTP",
        type: "danger",
        container: "top-center",
      });
    }
  }

  async onOtpResend(userId, mobileNo, token) {
    this.setState({ isLoading: true });
    let res = await reSendOtp(userId, mobileNo, token);
    if (res && res.data && res.data.status) {
      this.setState({ isLoading: false, displayOtpResend: false });
    } else if (!res.data.status && res.data.data.attempt === 4) {
      OpenNotification({
        title: "Account blocked",
        message: res?.data?.message,
        type: "danger",
        container: "top-center",
      });
      this.props.history.push("/blocked");
    } else {
      return false;
    }
  }

  async onOtpEnter(otp) {
    console.log("hue otp entered", otp);
    this.setState({ isEnableLoginIn: true, otp: otp });
  }

  onCountdownFinish() {
    this.setState({ displayOtpResend: true, isEnableLoginIn: false });
  }

  fetchMobileNumber = (value) => {
    let newValue = value?.replace(/^[+0]/, "");
    return (
      "+" +
      newValue.replace(/\d{8}$/, "") +
      newValue.replace(/^\d{8}/, "*********")
    );
  };
  render() {
    const { dataForm, isLoading, displayOtpResend } = this.state;
    return (
      <>
        <div className={"main-fix-bg"} />
        <section className={"container"}>
          <div className="row ">
            <section className={"login-main-wrapper"}>
              <div className={"welcome-body-wrp"}>
                <div className={"login-b-left-wrp "}>
                  <div className={"welcome-body-header d-flex flex-column"}>
                    <JoinFormHeader
                      isLogo={true}
                      wizardNo={1}
                      history={this.props.history}
                      header={"Verify you mobile no"}
                    />
                    <div className={"cco__login__mobile"}>
                      {this.props.userData &&
                        this.fetchMobileNumber(this.props.userData?.contactNo)}
                    </div>
                  </div>
                  <div className={"cco__login-form-wrapper"}>
                    <div className={"data-form-wrapper flex-row "}>
                      <Input
                        elementConfig={dataForm.otpVerification.elementConfig}
                        elementType={dataForm.otpVerification.elementType}
                        required={dataForm.otpVerification.validation.required}
                        isinvalid={!dataForm.otpVerification.valid}
                        isShowPasswordStrengthMeter={false}
                        invalidReason={dataForm.otpVerification.invalidReason}
                        touched={dataForm.otpVerification.touched}
                        value={dataForm.otpVerification.value}
                        isRightIcon={false}
                        rightIcon={GetIcon("eye")}
                        size={"lg"}
                        margin={"m-t-0"}
                        label={dataForm.otpVerification.label}
                        changed={(event) =>
                          this.inputHandleChange(
                            event,
                            dataForm.otpVerification.key
                          )
                        }
                      />

                      <div className={"section-wrapper mt-1"}>
                        <div className={"login-form-OTP-message"}>
                          Code not received ? you can sen it again in
                        </div>
                        <div className={"re-send mt-1"}>
                          <OtpResendTimer
                            numberOfInputs={6}
                            timeInterval={20}
                            displayCountdown={!displayOtpResend}
                            countdownText={"Resend"}
                            onOtpEnter={this.onOtpEnter.bind(this)}
                            onCountdownFinish={this.onCountdownFinish.bind(
                              this
                            )}
                          />
                        </div>
                        {displayOtpResend && (
                          <div
                            className={"re-send link"}
                            onClick={async () => {
                              let token = getToken();
                              let user = await userDetails();
                              this.setState({
                                attempt: ++this.state.attempt,
                              });
                              if (this.state.attempt <= 3) {
                                await this.onOtpResend(
                                  user?.userId,
                                  this.props.userData.contactNo,
                                  token
                                );
                              }
                            }}
                          >
                            Resend
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={"login-footer-action-wrapper d-flex mt-4"}>
                    <Button
                      leftIcon={false}
                      rightIcon={false}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      disabled={!this.state.isDisable}
                      reactRightIcon={GetIcon("leftArrow")}
                      onClick={async () => {
                        let user = await userDetails();
                        await this.sendVerify(
                          user.userId,
                          dataForm.otpVerification.value
                        );
                      }}
                      text={"Sign in"}
                      size={"lg"}
                      type={"btn-block"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpVerificationComponent);
