import ApiRoutes from "../../../constants/api-routes";
import {ccoInstance} from "../../../service";


// export const cooCreateNewBank = async (body) =>
//     ccoInstance.post(`${ApiRoutes.CREATE_NEW_BANK}`, body);

export const cooGetAllAllObjectsFromS3Bucket = async (storeId, pageNo, status) =>
    ccoInstance.get(`${ApiRoutes.GET_ALL_MEDIA}`);

