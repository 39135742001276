import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import { ButtonIcon } from "../..";
import { GetIcon } from "../../../config/icon";

const Index = (props) => {
  return (
    <div
      className={`insight-card-wrapper d-flex flex-row justify-content-between align-items-center`}
      onClick={props.onClick}
    >
      <div className={`d-flex flex-column`}>
        <div className={"icw__id"}>{props.insightID}</div>
        <div className={"icw__details d-flex flex-row align-items-center"}>
          <div className={"icwd__date"}>{props.date}</div>|
          <div className={"icwd__time"}>{props.time}</div>
        </div>
      </div>
      <div
        className={
          "icw__action d-flex flex-column justify-content-center align-items-center align-content-center"
        }
      >
        {GetIcon("chevronRight")}
      </div>
    </div>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
