import * as actionTypes from '../actions/types';
import produce from 'immer';

const initialState = {
    activeKey: null,
    isActive: false,
    selectMediaData: [],
};

let newData = [];
export default produce((draft = initialState, action) => {
    const {type, payload} = action;
    console.log('mediaReducer', payload);
    switch (type) {
        case actionTypes.VIEW_MEDIA_INFO:
            draft.activeKey = payload && payload.key;
            draft.selectMediaData = payload;
            draft.isActive = true;
            return draft;
        case actionTypes.VIEW_MEDIA_INFO_CLEAR:
            draft.activeKey = null;
            draft.selectMediaData = [];
            draft.isActive = false;
            return draft;
        default:
            return draft
    }
});

