import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { RiMenu2Fill, RiMore2Fill } from "react-icons/ri";
import { Dropdown } from "antd";
import { Tag } from "../..";

const Index = (props) => {
  const menu = (
    <>
      <div className={"header-menu-wrapper"}>
        <div
          className={"hm__inner-content d-flex align-items-center"}
          onClick={props.onClickViewOffer}
        >
          <div className={"hmi-text"}>View Offers</div>
        </div>

        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"}>Active</div>
        </div>
        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"} onClick={props.onClickEdit}>
            Edit
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className={`cco__profile-card__wrapper d-flex flex-row`}>
        <div className={"cco__pcw__img"}>
          <div className={"img-wrapper"}>
            <img src={props.profileImgUrl} alt="creditcard offers" />
          </div>
        </div>
        <div
          className={
            "d-flex flex-row justify-content-between align-items-center"
          }
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column"}>
            <div className={"cco__pcw__name"}>{props.name}</div>
            {/* <div className={"cco__pcw__type"}>{props.userType}</div> */}
            <div className="d-flex">
              <Tag type={"admin"} tagName={props.userType} />
            </div>
          </div>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <div className={"cco__pcw__menu"}>
              <RiMore2Fill />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
