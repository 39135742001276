import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {BiX} from "react-icons/bi";
import Moment from "react-moment";

const Index = ({text, onClick, onRemoveTime, format, key, remove, style}) => (
    <>
        <div key={key} className={`tag-wrapper mr-1 d-flex ${style}`} onClick={onClick}>
            <div className={"text"}>
                {format === "time" && <Moment format="h:mm a">{text}</Moment>}

                {format === "text" && <>{text}</>}
                {format === "price" && <>Rs.{text}</>}
            </div>
            {remove && (
                <div className={"checkBox"} onClick={onRemoveTime}>
                    <BiX/>
                </div>
      )}
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "",
};

export default Index;
