import React, { useEffect, useState } from "react";
import "./styles.scss";
import "./mobile.scss";
import { Button, ButtonIcon } from "../../index";
import { OpenNotification } from "../../../config/notification";
import { GetIcon } from "../../../config/icon";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../store/actions/types";
import { cooGetOfferByMerchant } from "../../../containers/modal/ViewMerchantOffers/service/viewMerchantOffers.service";
import { cooGetAllBank } from "../../../containers/bank/service/bank.service";
import { FiCopy } from "react-icons/fi";

const Index = (props, isEdit) => {
  const [isExpand, setIsExpand] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [isSelectBank, setIsSelectBank] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [offers, setOffers] = useState(null);
  const { bankData } = useSelector(({ bankReducer }) => bankReducer);
  const dispatch = useDispatch();
  const cancel = (e) => {
    console.log(e);
  };
  const [dataForm, setDataForm] = useState({
    bank: {
      key: "bank",
      elementType: "select",
      elementConfig: {
        type: "text",
        placeholder: "",
        options: [],
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      label: "Select bank",
    },
  });

  const onLoadMerchantOffers = async () => {
    let resOfferByMerchant = await cooGetOfferByMerchant(props?.merchantId);
    if (resOfferByMerchant?.data?.data) {
      setOffers(resOfferByMerchant?.data?.data);
    }
  };

  const handleToggleExpand = async () => {
    setIsExpand(!isExpand);
    await onLoadMerchantOffers();
  };

  const confirm = (e) => {
    if (this.props.numOfOfferByMerchant > 0) {
      OpenNotification(
        "Unable to Delete Offer",
        "It seems there's an issue with deleting the offer.",
        "success"
      );
    }
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const handleBankChange = (bankId) => {
    console.log("handleBankChange", props?.termsAndCondition[bankId]);
    if (!selectedBankId) {
      setSelectedBankId(bankId);
      dispatch({
        type: actionTypes.SELECTED_EXISTING_TERMS,
        payload: { terms: props?.termsAndCondition[bankId], bankId: bankId },
      });
    } else {
      setSelectedBankId(null);
      dispatch({
        type: actionTypes.REMOVE__SELECTED_EXISTING_TERMS,
        payload: null,
      });
    }
  };

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  //==== Input Handle Change
  const inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = { ...dataForm };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "bank":
        val = event && event.target ? event.target.value : event;
        updateForm.bank.elementConfig.options.filter((x) => x.value === val);
        updatedFormElement.value = val;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    setDataForm(updateForm);
  };

  const syncBank = (res) => {
    let bankList = [];
    for (let bank of res) {
      bankList.push({
        id: bank.bankId,
        value: bank.bankId,
        displayValue: bank.bankName,
      });
    }
    return bankList;
  };

  // scrollbars start
  useEffect(() => {
    const updateForm = { ...dataForm };
    updateForm["bank"].elementConfig.options = syncBank(bankData);
    setDataForm(updateForm);
  }, []);

  const refreshBank = async (res) => {
    let resBank = await cooGetAllBank();
    const updateForm = { ...dataForm };
    console.log("resBank", resBank.data.data);
    updateForm["bank"].elementConfig.options = syncBank(
      resBank && resBank.data && resBank.data.data
    );
    setDataForm(updateForm);
  };

  const { TabPane } = Tabs;
  return (
    <div
      className={`cco__merchant-detail-card-small__wrapper  d-flex flex-row  align-items-center justify-content-between ${
        props.isActive ? "active-me" : ""
      } ${props.cardType} `}
    >
      <div className={"d-flex flex-row  align-items-center "}>
        <div className={"cco__mdcsw__category-icon"}>
          {GetIcon(props.iconName)}
        </div>

        <div className={"d-flex flex-column justify-content-center ml-3"}>
          {props.isEdit === true ? (
            <>
              {/* {props.preview && ( */}
              <>
                <div className={"cco__mdcsw__title"}>Offer Category</div>
                <div className={"cco__mdcsw__name"}>{props.merchantName}</div>
              </>
              {/* )} */}
            </>
          ) : (
            <>
              <div className={"cco__mdcsw__name"}>{props.merchantName}</div>
              <div
                className={
                  "cco__mdcsw__id d-flex flex-row justify-content-between align-items-center"
                }
              >
                CategoryId :{props.categoryId}
                <div className={"cco__mdcsw__copy"}>
                  <FiCopy />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={"cco__mdcsw-action d-flex"}>
        {props.isEdit === true ? (
          <div>
            <Button
              text={"Edit"}
              onClick={props.onClickEdit}
              type={"btn-edit"}
            />
          </div>
        ) : (
          <div className="d-flex flex-row justify-content-center align-items-center align-content-center">
            <ButtonIcon
              onClick={props.onClick}
              buttonIcon={GetIcon("chevronRight")}
              type={"collapse-icon"}
              size={"md"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
