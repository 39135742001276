import React, { useEffect, useState } from "react";
import { Button, Input, SelectBox } from "../../../../components";
import { updateHotLine } from "../../../../api";
import uuid from "react-uuid";
import { connect, useDispatch, useSelector } from "react-redux";

import * as actionTypes from "../../../../store/actions/types";

const ReservationContact = ({ merchantId }, props) => {
  const [dataForm, setDataForm] = useState({
    mobileNo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Mobile No",
        maxLength: 15,
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      key: "mobileNo",
      label: "Mobile No",
    },
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Email",
      },
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      key: "email",
      label: "Email",
    },
  });
  const [mobileList, setMobileList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const selectMerchant = useSelector(
    (state) => state.merchantReducer.selectMerchant
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.isEmail) {
      isValid = emailRegex.test(value) && isValid;
    }

    return isValid;
  };
  const dispatch = useDispatch();

  const inputHandleChange = (event, key) => {
    const updatedForm = { ...dataForm };
    const updatedFormElement = { ...updatedForm[key] };
    updatedFormElement.value = event.target.value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedForm[key] = updatedFormElement;
    setDataForm(updatedForm);
  };

  const addToMobileList = () => {
    const mobileNo = dataForm.mobileNo.value;
    if (mobileNo) {
      if (!mobileList.some((item) => item.contact === mobileNo)) {
        const mobileEntry = {
          id: uuid(),
          type: "mobileNo",
          icon: "mobileNo",
          contact: mobileNo,
          isActive: true,
        };
        setMobileList([...mobileList, mobileEntry]);
        const updatedForm = { ...dataForm };
        updatedForm.mobileNo.value = "";
        setDataForm(updatedForm);
        setError("");
        console.log(`Mobile number ${mobileNo} added to the list.`);
      } else {
        console.log(
          `Mobile number ${mobileNo} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      setError("Mobile number cannot be empty.");
    }
  };

  const addToEmailList = () => {
    const email = dataForm.email.value;
    if (email && emailRegex.test(email)) {
      if (!emailList.some((item) => item.contact === email)) {
        const emailEntry = {
          id: uuid(),
          type: "email",
          icon: "email",
          contact: email,
          isActive: true,
        };
        setEmailList([...emailList, emailEntry]);
        const updatedForm = { ...dataForm };
        updatedForm.email.value = "";
        setDataForm(updatedForm);
        setError("");
        console.log(`Email ${email} added to the list.`);
      } else {
        console.log(
          `Email ${email} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      setError(
        email ? "Email is not in a valid format." : "Email cannot be empty."
      );
    }
  };

  // const handleUpdateMerchantTerms = async () => {
  //   const body = {
  //     mobileNo: mobileList,
  //     email: emailList,
  //   };
  //   // try {
  //   let res = await updateHotLine(merchantId, body);
  //   console.log("Merchant::", res);
  //   if (res?.status) {
  //     let merchant = res?.data;
  //     dispatch({
  //       type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL,
  //       merchant,
  //     }),
  //       //   dispatch({
  //       //     type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
  //       //     payload: merchant.termsAndCondition,
  //       //   }),
  //       // props.onClickSelectedMerchant(merchant);
  //       // props.onLoadTermsAndCondition(merchant.termsAndCondition);
  //       console.log("Merchant terms updated successfully");
  //   } else {
  //     console.log("Failed to update merchant terms");
  //   }
  //   // } catch (error) {
  //   //   console.log("Failed to update merchant terms");
  //   // }
  // };

  const handleUpdateMerchantTerms = async () => {
    const body = {
      mobileNo: mobileList,
      email: emailList,
    };

    try {
      let res = await updateHotLine(merchantId, body);
      console.log("Merchant::", res);
      if (res?.status) {
        let merchant = res.data;
        dispatch({
          type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL,
          payload: merchant,
        });
        // dispatch({
        //   type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        //   payload: merchant.termsAndCondition,
        // });
        // props.onClickSelectedMerchant(merchant);
        // props.onLoadTermsAndCondition(merchant.termsAndCondition);
        console.log("Merchant terms updated successfully");
      } else {
        console.log("Failed to update merchant terms");
      }
    } catch (error) {
      console.log("Failed to update merchant terms");
    }
  };

  const handleEdit = (item, index, type) => {
    setIsEditing(true);
    setCurrentItem(item);
    setCurrentIndex(index);
    setDataForm({
      ...dataForm,
      [type]: {
        ...dataForm[type],
        value: item.contact,
        valid: true,
        touched: true,
      },
    });
  };

  const handleUpdate = () => {
    if (currentItem.type === "mobileNo") {
      const updatedMobileList = [...mobileList];
      updatedMobileList[currentIndex] = {
        ...currentItem,
        contact: dataForm.mobileNo.value,
      };
      setMobileList(updatedMobileList);
      console.log(`Mobile number ${dataForm.mobileNo.value} updated.`);
    } else {
      const updatedEmailList = [...emailList];
      updatedEmailList[currentIndex] = {
        ...currentItem,
        contact: dataForm.email.value,
      };
      setEmailList(updatedEmailList);
      console.log(`Email ${dataForm.email.value} updated.`);
    }
    setIsEditing(false);
    setCurrentItem(null);
    setCurrentIndex(null);
    setDataForm({
      mobileNo: {
        ...dataForm.mobileNo,
        value: "",
      },
      email: {
        ...dataForm.email,
        value: "",
      },
    });
  };
  useEffect(() => {
    console.log({ selectMerchant });
    if (selectMerchant?.hotLine) {
      console.log("hotline:::", selectMerchant?.hotLine);
      setMobileList(selectMerchant?.hotLine?.mobileNo);
      setEmailList(selectMerchant?.hotLine?.email);
    }
  }, [selectMerchant]);

  return (
    <div>
      <div className="mt-1">
        <div className="shw_textWrapper d-flex flex-row align-items-center ">
          <Input
            elementConfig={dataForm.mobileNo.elementConfig}
            elementType={dataForm.mobileNo.elementType}
            required={dataForm.mobileNo.validation.required}
            isValid={!dataForm.mobileNo.valid}
            touched={dataForm.mobileNo.touched}
            value={dataForm.mobileNo.value}
            isShowRequired={false}
            size={"sm"}
            margin={"m-b-1 m-t-2"}
            label={dataForm.mobileNo.label}
            changed={(event) => inputHandleChange(event, dataForm.mobileNo.key)}
          />
          <div className="action-bar-right d-flex mt-4 ml-2">
            <Button
              text={"Add to list"}
              type={"btn-edit"}
              size={"sm primary"}
              onClick={addToMobileList}
            />
          </div>
        </div>
        <div>
          {mobileList?.length > 0 && (
            <div className={"d-flex"}>
              {mobileList.map((mobile, index) => (
                // <div key={index}>{mobile.contact}</div>
                <SelectBox
                  index={index}
                  remove={false}
                  isSelect={true}
                  select={true}
                  // onClick={() => this.onSelectLocation(item, index)}
                  onRemove={() => {
                    if (mobile.contact) {
                      setMobileList(
                        mobileList.filter((item, i) => i !== index)
                      );
                    }
                  }}
                  format={"text"}
                  text={mobile.contact}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-1">
        <div className="shw_textWrapper d-flex flex-row align-items-center">
          <Input
            elementConfig={dataForm.email.elementConfig}
            elementType={dataForm.email.elementType}
            required={dataForm.email.validation.required}
            isValid={!dataForm.email.valid}
            touched={dataForm.email.touched}
            value={dataForm.email.value}
            isShowRequired={false}
            size={"sm"}
            margin={"m-b-1 m-t-2"}
            label={dataForm.email.label}
            changed={(event) => inputHandleChange(event, dataForm.email.key)}
          />
          <div className="action-bar-right d-flex mt-4 ml-2">
            <Button
              text={"Add to list"}
              type={"btn-edit"}
              size={"sm primary"}
              onClick={addToEmailList}
            />
          </div>
        </div>
        {emailList?.length > 0 && (
          <div className={"d-flex"}>
            {emailList.map((email, index) => (
              // <div key={index}>{email.contact}</div>
              <SelectBox
                index={index}
                remove={false}
                isSelect={true}
                select={true}
                // onClick={() => this.onSelectLocation(item, index)}
                onRemove={() => {
                  if (email.contact) {
                    setEmailList(emailList.filter((item, i) => i !== index));
                  }
                }}
                format={"text"}
                text={email.contact}
              />
            ))}
          </div>
        )}
      </div>

      <div className="action-bar-right mt-2">
        <Button
          text={"Update"}
          type={"btn-edit"}
          size={"sm primary"}
          onClick={handleUpdateMerchantTerms}
        />
      </div>

      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectMerchant: state.merchantReducer.selectMerchant,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationContact);
