import React, { Component } from "react";
import { connect } from "react-redux";

import "./styles.scss";
import { Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import Contactus from "./contactus";
import Subscribe from "./subscribe";
import { PageHeader } from "../../components";

const { TabPane } = Tabs;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      activeMerchant: null,
      dataSource: [],
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Contact Us",
          content: <Contactus />,
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Subscribe",
          content: <Subscribe />,
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  render() {
    const { tabJson, isLoading, viewPointHeight } = this.state;
    const {} = this.props;

    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={false}
            isDate={false}
            isLoading={isLoading}
            // onClickRefresh={async () => {
            //   let res = await this.getUsers();
            // }}
            title={"Subscriptions"}
          />
        </div>
        {/* <Scrollbars
          style={{ height: viewPointHeight - 80 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
        </Scrollbars> */}
        <div className={"cco__subscription-body-wrapper"}>
          <div className={"cco__sbw-tab"}>
            <Tabs>
              {((tabJson && tabJson) || []).map((i, t) => (
                <TabPane tab={i.tabName} key={i.key}>
                  <>{i.content}</>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
