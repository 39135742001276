import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

import "./styles.scss";
import {
  Button,
  DeviceSelectionCard,
  FileUpload,
  ImageAcceptedCard,
  Input,
  PageHeader,
  PaymentDetails,
  PreviewAdvertisementCard,
  SelectionCheckbox,
} from "../../components";
import { getAdminUser, uploadImage } from "../../api";

import uuid from "react-uuid";
import { GetIcon } from "../../config/icon";
import { Collapse, Select } from "antd";
import { ProfileSetting } from "../modal";

const { Panel } = Collapse;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      deviceType: "",
      creativeType: "",
      ratioType: "",
      userInterest: [],
      option: Select,
      selectData: [],
      imageURL: "",
      wizardNo: 1,
      dataForm: {
        advertisementName: {
          label: "Advertisement Name",
          key: "advertisementName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "name of your Advertisement",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageTitle: {
          label: "Title",
          key: "uploadImageTitle",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0/20",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageDescription: {
          label: "Description",
          key: "uploadImageDescription",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0/50",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageUrl: {
          label: "URL",
          key: "uploadImageUrl",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageAcButtonName: {
          label: "Action Button Name",
          key: " uploadImageAcButtonName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },

        location: {
          key: "location",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: " ",
            mode: "multiple",
            options: [
              { id: uuid(), value: "Dehiwala" },
              { id: uuid(), value: "Colombo" },
              { id: uuid(), value: "Kandy" },
              { id: uuid(), value: "Bandarwela" },
              { id: uuid(), value: "Ella" },
            ],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "City - (3)",
        },
        targetSearch: {
          // label: "Advertisement Name",
          key: "targetSearch",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getUsers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getUsers() {
    this.setState({ isLoading: true });
    let res = await getAdminUser();
    this.setState({
      dataSource: res && res.data,
      isLoading: false,
    });
  }
  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "location") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.uploadImage(result_base64);
  }

  async uploadImage(base64) {
    this.setState({ isImageUploading: true });
    let res = await uploadImage(base64, "user", "User");
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      this.setState({ imageURL: res.data?.data?.URL });
      this.setState({ isImageUploading: false });
    } else {
      this.setState({ isImageUploading: true });
    }
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  checkIsSelected(value) {
    let isValid = false;

    this.state.userInterest.forEach((element) => {
      if (element === value) {
        isValid = true;
      }
    });
    return isValid;
  }

  render() {
    const {
      tags,
      isLoading,
      userInterest,
      viewPointHeight,
      selectData,
      dataForm,
      wizardNo,
      wizardPanel,
      isImageUploading,
      deviceType,
      creativeType,
      ratioType,
    } = this.state;

    return (
      <>
        <div className="cco-advertisement">
          <div className={"cco__page-header-wrapper"}>
            <PageHeader
              option={"d-flex justify-content-between"}
              isRefresh={false}
              isDate={false}
              isLoading={isLoading}
              // onClickRefresh={async () => {
              //   let res = await this.getUsers();
              // }}
              title={"Create new Advertisement"}
            />
          </div>

          <Scrollbars
            style={{ height: viewPointHeight - 150 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            {wizardNo === 1 && (
              <div className={"container"}>
                <div className="row justify-content-md-center">
                  <div className={"col-12 col-lg-12 col-md-12 col-sm-12"}>
                    <div className={"cco__advertisement-body-wrapper"}>
                      <div className="cco__abw__content">
                        <div className="cco__abwc__title">
                          {"General Information"}
                        </div>
                        {/* <div className="cco__abwc__subTitle">{"Advertisement Name"}</div> */}
                        <Input
                          elementConfig={
                            dataForm.advertisementName.elementConfig
                          }
                          elementType={dataForm.advertisementName.elementType}
                          required={
                            dataForm.advertisementName.validation.required
                          }
                          isValid={!dataForm.advertisementName.isValid}
                          touched={dataForm.advertisementName.touched}
                          value={dataForm.advertisementName.value}
                          props={dataForm.advertisementName}
                          isShowRequired={true}
                          size={"md"}
                          margin={"m-b-2"}
                          label={dataForm.advertisementName.label}
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.advertisementName.key
                            )
                          }
                        />
                      </div>
                      {/* device selection card */}
                      <div className={"cco__abw__content "}>
                        <div className={"cco__abwc__title "}>
                          {"Device Type"}
                        </div>
                        <div className="cco_abwc_card d-flex">
                          <DeviceSelectionCard
                            style={"gap =20px"}
                            buttonIcon={GetIcon("desktop")}
                            deviceName="Desktop"
                            isSelect={deviceType === "Desktop"}
                            onClick={() => {
                              if (deviceType === "Desktop") {
                                this.setState({ deviceType: null });
                              } else {
                                this.setState({ deviceType: "Desktop" });
                              }
                            }}
                          />

                          <DeviceSelectionCard
                            buttonIcon={GetIcon("mobile")}
                            deviceName="Mobile"
                            isSelect={deviceType === "Mobile"}
                            onClick={() => {
                              if (deviceType === "Mobile") {
                                this.setState({ deviceType: null });
                              } else {
                                this.setState({ deviceType: "Mobile" });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {/* map */}
                      <div className="cco__abw__content">
                        <div className="cco__abwc__title">
                          {"Geotargeting"}
                          <span className="cco__abwc__subTitle">
                            {"Select which cities you want to target :"}
                          </span>
                        </div>
                        <div
                          className="cco_abwc_remove d-flex"
                          onClick={() => {
                            this.setState({ selectData: [] });
                          }}
                        >
                          Remove all
                        </div>
                        <Input
                          elementConfig={dataForm.location.elementConfig}
                          elementType={dataForm.location.elementType}
                          required={dataForm.location.validation.required}
                          isinvalid={!dataForm.location.valid}
                          touched={dataForm.location.touched}
                          value={selectData}
                          isShowRequired={true}
                          size={"sm"}
                          margin={"m-b-2"}
                          options={dataForm.location.options}
                          label={dataForm.location.label}
                          changed={(event) => {
                            this.setState({ selectData: event });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {wizardNo === 2 && (
              <div className={"container"}>
                <div className="row justify-content-md-center">
                  <div className={"col-12 col-lg-12 col-md-12 col-sm-12"}>
                    <div className={"cco__advertisement-body-wrapper"}>
                      <div className="cco_adbw_heading">Targeting</div>
                      <div className={" "}>
                        <Collapse
                          className="cco__abw__colWrapper"
                          // bordered={}
                          onChange={(key) => {
                            console.log(key);
                          }}
                          expandIconPosition={"end"}
                        >
                          <Panel
                            className="cco__abw__panelWrapper "
                            header={
                              <div className="cco_abw_panelHeader">
                                <div className="panelTitle">
                                  User interest -
                                  <span className="pt_sub">All selected</span>
                                </div>
                                <div className="subPanelTitle">
                                  Target users based on their interests.
                                </div>
                              </div>
                            }
                          >
                            <div className="cco_pw_search">
                              <Input
                                elementConfig={
                                  dataForm.targetSearch.elementConfig
                                }
                                elementType={dataForm.targetSearch.elementType}
                                required={
                                  dataForm.targetSearch.validation.required
                                }
                                isValid={!dataForm.targetSearch.isValid}
                                touched={dataForm.targetSearch.touched}
                                value={dataForm.targetSearch.value}
                                props={dataForm.targetSearch}
                                isShowRequired={true}
                                size={"md"}
                                margin={"m-b-2"}
                                label={dataForm.targetSearch.label}
                                changed={(event) =>
                                  this.inputHandleChange(
                                    event,
                                    dataForm.targetSearch.key
                                  )
                                }
                              />
                            </div>
                            <div className="cco_pw_checkbox_wrapper">
                              <SelectionCheckbox
                                selectionName="Arts & Entertainment"
                                isSelect={this.checkIsSelected(
                                  "Arts & Entertainment"
                                )}
                                onClick={() => {
                                  if (
                                    this.checkIsSelected("Arts & Entertainment")
                                  ) {
                                    let selectedItems = [
                                      ...this.state.userInterest,
                                    ];

                                    selectedItems = selectedItems.filter(
                                      (element) =>
                                        element !== "Arts & Entertainment"
                                    );
                                    this.setState({
                                      userInterest: selectedItems,
                                    });
                                  } else {
                                    this.setState({
                                      userInterest: [
                                        ...this.state.userInterest,
                                        "Arts & Entertainment",
                                      ],
                                    });
                                  }
                                }}
                              />
                              <SelectionCheckbox
                                selectionName="Autos & Vehicles"
                                isSelect={this.checkIsSelected(
                                  "Autos & Vehicles"
                                )}
                                onClick={() => {
                                  if (
                                    this.checkIsSelected("Autos & Vehicles")
                                  ) {
                                    let selectedItems = [
                                      ...this.state.userInterest,
                                    ];

                                    selectedItems = selectedItems.filter(
                                      (element) =>
                                        element !== "Autos & Vehicles"
                                    );
                                    this.setState({
                                      userInterest: selectedItems,
                                    });
                                  } else {
                                    this.setState({
                                      userInterest: [
                                        ...this.state.userInterest,
                                        "Autos & Vehicles",
                                      ],
                                    });
                                  }
                                }}
                              />
                              <SelectionCheckbox
                                selectionName="Beauty & Fitness"
                                isSelect={this.checkIsSelected(
                                  "Beauty & Fitness"
                                )}
                                onClick={() => {
                                  if (
                                    this.checkIsSelected("Beauty & Fitness")
                                  ) {
                                    let selectedItems = [
                                      ...this.state.userInterest,
                                    ];

                                    selectedItems = selectedItems.filter(
                                      (element) =>
                                        element !== "Beauty & Fitness"
                                    );
                                    this.setState({
                                      userInterest: selectedItems,
                                    });
                                  } else {
                                    this.setState({
                                      userInterest: [
                                        ...this.state.userInterest,
                                        "Beauty & Fitness",
                                      ],
                                    });
                                  }
                                }}
                              />
                              <SelectionCheckbox
                                selectionName="Books & Literature"
                                isSelect={this.checkIsSelected(
                                  "Books & Literature"
                                )}
                                onClick={() => {
                                  if (
                                    this.checkIsSelected("Books & Literature")
                                  ) {
                                    let selectedItems = [
                                      ...this.state.userInterest,
                                    ];

                                    selectedItems = selectedItems.filter(
                                      (element) =>
                                        element !== "Books & Literature"
                                    );
                                    this.setState({
                                      userInterest: selectedItems,
                                    });
                                  } else {
                                    this.setState({
                                      userInterest: [
                                        ...this.state.userInterest,
                                        "Books & Literature",
                                      ],
                                    });
                                  }
                                }}
                              />
                              <SelectionCheckbox
                                buttonIcon={"<RiCheckLine />"}
                                selectionName="Business & Industrial"
                                isSelect={this.checkIsSelected(
                                  "Business & Industrial"
                                )}
                                onClick={() => {
                                  if (
                                    this.checkIsSelected(
                                      "Business & Industrial"
                                    )
                                  ) {
                                    let selectedItems = [
                                      ...this.state.userInterest,
                                    ];

                                    selectedItems = selectedItems.filter(
                                      (element) =>
                                        element !== "Business & Industrial"
                                    );
                                    this.setState({
                                      userInterest: selectedItems,
                                    });
                                  } else {
                                    this.setState({
                                      userInterest: [
                                        ...this.state.userInterest,
                                        "Business & Industrial",
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {wizardNo === 3 && (
              <div className={"container"}>
                <div className="row justify-content-md-center">
                  <div className={"col-12 col-lg-12 col-md-12 col-sm-12"}>
                    <div className={"cco__advertisement-body-wrapper"}>
                      <div className="cco_adbw_heading">Creatives</div>
                      {/* panel 1 */}
                      <div className={" "}>
                        <Collapse
                          className="cco__abw__colWrapper"
                          // bordered={}
                          onChange={(key) => {
                            console.log(key);
                          }}
                          expandIconPosition={"end"}
                        >
                          <Panel
                            className="cco__abw__panelWrapper "
                            header={
                              <div className="cco_abw_panelHeader">
                                <div className="panelTitle">
                                  Step 01 - Select creative type
                                </div>
                                <div className="subPanelTitle">
                                  Follow the steps below to create creatives for
                                  your campaign.
                                </div>
                              </div>
                            }
                          >
                            <div className="cco_abwc3_card d-flex ">
                              <DeviceSelectionCard
                                style={"gap =20px"}
                                buttonIcon={GetIcon("desktop")}
                                deviceName="Pop-under"
                                isSelect={creativeType === "Pop-under"}
                                onClick={() => {
                                  if (creativeType === "Pop-under") {
                                    this.setState({
                                      creativeType: null,
                                      wizardPanel: 1,
                                    });
                                  } else {
                                    this.setState({
                                      creativeType: "Pop-under",
                                      wizardPanel: 1,
                                    });
                                  }
                                }}
                              />
                              <DeviceSelectionCard
                                style={"gap =20px"}
                                buttonIcon={GetIcon("desktop")}
                                deviceName="Banner"
                                isSelect={creativeType === "Banner"}
                                onClick={() => {
                                  if (creativeType === "Banner") {
                                    this.setState({
                                      creativeType: null,
                                      wizardPanel: 1,
                                    });
                                  } else {
                                    this.setState({
                                      creativeType: "Banner",
                                      wizardPanel: 1,
                                    });
                                  }
                                }}
                              />
                              <DeviceSelectionCard
                                style={"gap =20px"}
                                buttonIcon={GetIcon("desktop")}
                                deviceName="Native"
                                isSelect={creativeType === "Native"}
                                onClick={() => {
                                  if (creativeType === "Native") {
                                    this.setState({
                                      creativeType: null,
                                      wizardPanel: 1,
                                    });
                                  } else {
                                    this.setState({
                                      creativeType: "Native",
                                      wizardPanel: 2,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                      {/* panel 2 */}
                      <div className={" "}>
                        <Collapse
                          className="cco__abw__colWrapper"
                          // bordered={}
                          onChange={(key) => {
                            console.log(key);
                          }}
                          expandIconPosition={"end"}
                        >
                          <Panel
                            className="cco__abw__panelWrapper "
                            header={
                              <div className="cco_abw_panelHeader">
                                <div className="panelTitle">
                                  Step 02 : Fill creative properties
                                </div>
                                <div className="subPanelTitle">
                                  Fill creative properties
                                </div>
                              </div>
                            }
                          ></Panel>
                        </Collapse>
                      </div>
                      {/* panel 3 */}
                      <div className={" "}>
                        <Collapse
                          className="cco__abw__colWrapper"
                          // bordered={}
                          onChange={(key) => {
                            console.log(key);
                          }}
                          expandIconPosition={"end"}
                        >
                          <Panel
                            className="cco__abw__panelWrapper "
                            header={
                              <div className="cco_abw_panelHeader">
                                <div className="panelTitle">
                                  Step 03 : Upload image
                                </div>
                                <div className="subPanelTitle">
                                  Fill creative properties
                                </div>
                              </div>
                            }
                          >
                            {wizardPanel === 1 && (
                              <>
                                <div className="cco_pw_fileUpload_wrapper">
                                  <FileUpload
                                    title={"image"}
                                    isImageUploading={isImageUploading}
                                  />
                                  <div className={"img-upload-wrapper"}>
                                    {!isImageUploading && (
                                      <>
                                        <input
                                          id="eventImageUpload"
                                          type="file"
                                          onChange={async (media) => {
                                            await this.toBase64(
                                              document.querySelector(
                                                "#eventImageUpload"
                                              ).files[0]
                                            );
                                          }}
                                          hidden
                                        />
                                        <div
                                          className={"event-img-upload"}
                                          onClick={() => {
                                            document
                                              .getElementById(
                                                "eventImageUpload"
                                              )
                                              .click();
                                          }}
                                        >
                                          Upload Profile Image
                                          {this.state.fileName && (
                                            <div className={"file-details-wrp"}>
                                              <div className={"file-name"}>
                                                {this.state.fileName}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {isImageUploading && (
                                      <>
                                        <div className={"event-img-upload"}>
                                          Uploading...
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="cco_pw_imageAccepted_wrapper">
                                  <ImageAcceptedCard
                                    buttonIcon={GetIcon("desktop")}
                                    deviceName="Accepted image ratios for desktop:"
                                    text="Recommended image size: 600x500px"
                                    isSelect={ratioType === "Desktop"}
                                    onClick={() => {
                                      if (ratioType === "Desktop") {
                                        this.setState({
                                          ratioType: "",
                                        });
                                      } else {
                                        this.setState({
                                          ratioType: "Desktop",
                                        });
                                      }
                                    }}
                                  />
                                  <ImageAcceptedCard
                                    buttonIcon={GetIcon("mobile")}
                                    deviceName="Accepted image ratios for mobile:"
                                    text="Recommended image size: 320x480 px"
                                    isSelect={ratioType === "Mobile"}
                                    onClick={() => {
                                      if (ratioType === "Mobile") {
                                        this.setState({
                                          ratioType: "",
                                        });
                                      } else {
                                        this.setState({
                                          ratioType: "Mobile",
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {wizardPanel === 2 && (
                              <div class="container">
                                {/* right wrapper */}
                                <div class="col-12 col-md-9 col-sm-12 col-lg-9">
                                  <div className="cco_pw_fileUpload_wrapper">
                                    <FileUpload
                                      title={"image"}
                                      isImageUploading={isImageUploading}
                                    />
                                  </div>
                                  <div className="">
                                    {/* <div className="cco__abwc__subTitle">{"uploadImageTitle"}</div> */}
                                    <Input
                                      elementConfig={
                                        dataForm.uploadImageTitle.elementConfig
                                      }
                                      elementType={
                                        dataForm.uploadImageTitle.elementType
                                      }
                                      required={
                                        dataForm.uploadImageTitle.validation
                                          .required
                                      }
                                      isValid={
                                        !dataForm.uploadImageTitle.isValid
                                      }
                                      touched={
                                        dataForm.uploadImageTitle.touched
                                      }
                                      value={dataForm.uploadImageTitle.value}
                                      props={dataForm.uploadImageTitle}
                                      isShowRequired={true}
                                      size={"md"}
                                      margin={"m-b-2"}
                                      label={dataForm.uploadImageTitle.label}
                                      changed={(event) =>
                                        this.inputHandleChange(
                                          event,
                                          dataForm.uploadImageTitle.key
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="">
                                    {/* <div className="cco__abwc__subTitle">{"uploadImageDescription"}</div> */}
                                    <Input
                                      elementConfig={
                                        dataForm.uploadImageDescription
                                          .elementConfig
                                      }
                                      elementType={
                                        dataForm.uploadImageDescription
                                          .elementType
                                      }
                                      required={
                                        dataForm.uploadImageDescription
                                          .validation.required
                                      }
                                      isValid={
                                        !dataForm.uploadImageDescription.isValid
                                      }
                                      touched={
                                        dataForm.uploadImageDescription.touched
                                      }
                                      value={
                                        dataForm.uploadImageDescription.value
                                      }
                                      props={dataForm.uploadImageDescription}
                                      isShowRequired={true}
                                      size={"md"}
                                      margin={"m-b-2"}
                                      label={
                                        dataForm.uploadImageDescription.label
                                      }
                                      changed={(event) =>
                                        this.inputHandleChange(
                                          event,
                                          dataForm.uploadImageDescription.key
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="">
                                    {/* <div className="cco__abwc__subTitle">{"uploadImageUrl"}</div> */}
                                    <Input
                                      elementConfig={
                                        dataForm.uploadImageUrl.elementConfig
                                      }
                                      elementType={
                                        dataForm.uploadImageUrl.elementType
                                      }
                                      required={
                                        dataForm.uploadImageUrl.validation
                                          .required
                                      }
                                      isValid={!dataForm.uploadImageUrl.isValid}
                                      touched={dataForm.uploadImageUrl.touched}
                                      value={dataForm.uploadImageUrl.value}
                                      props={dataForm.uploadImageUrl}
                                      isShowRequired={true}
                                      size={"md"}
                                      margin={"m-b-2"}
                                      label={dataForm.uploadImageUrl.label}
                                      changed={(event) =>
                                        this.inputHandleChange(
                                          event,
                                          dataForm.uploadImageUrl.key
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="">
                                    {/* <div className="cco__abwc__subTitle">{"uploadImageAcButtonName"}</div> */}
                                    <Input
                                      elementConfig={
                                        dataForm.uploadImageAcButtonName
                                          .elementConfig
                                      }
                                      elementType={
                                        dataForm.uploadImageAcButtonName
                                          .elementType
                                      }
                                      required={
                                        dataForm.uploadImageAcButtonName
                                          .validation.required
                                      }
                                      isValid={
                                        !dataForm.uploadImageAcButtonName
                                          .isValid
                                      }
                                      touched={
                                        dataForm.uploadImageAcButtonName.touched
                                      }
                                      value={
                                        dataForm.uploadImageAcButtonName.value
                                      }
                                      props={dataForm.uploadImageAcButtonName}
                                      isShowRequired={true}
                                      size={"md"}
                                      margin={"m-b-2"}
                                      label={
                                        dataForm.uploadImageAcButtonName.label
                                      }
                                      changed={(event) =>
                                        this.inputHandleChange(
                                          event,
                                          dataForm.uploadImageAcButtonName.key
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                {/* left wrapper */}
                                <div class="col-12 col-md-3 col-sm-12 col-lg-3">
                                  <div className="previewAd-wrapper">
                                    <div
                                      className={"pw-title d-flex flex-column"}
                                    >
                                      Preview
                                    </div>
                                    <PreviewAdvertisementCard isImage={true} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Scrollbars>

          {/* next button */}
          <div className={" cco__advertisement-footer-wrapper container"}>
            <div className={"d-flex"}>
              {wizardNo > 1 && (
                <Button
                  leftIconFill={true}
                  iconName={GetIcon("rightArrow")}
                  leftIcon={false}
                  rightIcon={false}
                  option={"ml-1"}
                  isLoading={false}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("")}
                  LeftIconText={"Back"}
                  text={""}
                  size={"md"}
                  type={"btn-secondary"}
                  onClick={() => {
                    this.setState({ wizardNo: wizardNo - 1 });
                  }}
                />
              )}
              <Button
                rightIconFill={true}
                iconName={GetIcon("leftArrow")}
                leftIcon={false}
                rightIcon={false}
                option={"ml-1"}
                isLoading={false}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("")}
                rightIconText={"Next"}
                text={""}
                size={"md"}
                type={"btn-primary"}
                onClick={() => {
                  // if (dataForm.advertisementName.value!==""&&selectData?.length>0&&deviceType!=="") {
                  //   this.setState({ wizardNo: wizardNo + 1 });
                  // } else if(userInterest?.length>0) {
                  // this.setState({ wizardNo: wizardNo + 1 });
                  // }else if (creativeType?.length>0&&) {
                  // }
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
