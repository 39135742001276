import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IS_MOCK_DATA = process.env.REACT_APP_IS_MOCK_DATA === "TRUE";

export const createNewPayable = async ({
  offerId,
  userId,
  status,
  paymentStatus,
  offerStatus,
}) => {
  try {
    const body = {
      offerId: offerId,
      userId: userId,
      paymentStatus: paymentStatus,
      offerStatus: offerStatus,
    };

    // Get response
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.post(`${BASE_URL}/offer/payable`, body, headers);
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_MERCHANT_API:", err);
    throw err;
  }
};

export const updatePayable = async (body, merchantId) => {
  try {
    // Get response
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.put(
      `${BASE_URL}/update/merchant/details/${merchantId}`,
      body,
      headers
    );
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_MERCHANT_API:", err);
    throw err;
  }
};

export const getPendingPaymentsByUserId = async (userId, status) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/payment/amount/by/${userId}/${status}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};
