import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import { OpenNotification } from "../../../config/notification";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import {
  BankCardSmall,
  Button,
  ButtonIcon,
  GroupHeader,
  Input,
  MerchantDetailCardSmall,
  MerchantSelectCardSmall,
  PageHeader,
  RichTextComponent,
  SelectBox,
  Tag,
  UploadCard,
} from "../../../components";
import { GetIcon } from "../../../config/icon";
import {
  getBank,
  getCategory,
  getMerchant,
  updateOfferDetails,
  uploadImage,
} from "../../../api";
import ReactCrop from "react-image-crop";
import * as actionTypes from "../../../store/actions/types";
import { MdOpenInNew, MdOutlineCheckCircle } from "react-icons/md";
import { Tabs } from "antd";
import { cooFindMerchantById } from "../../merchant/service/merchant.service";
import { cooGetBankById } from "../../bank/service/bank.service";
import { saveDraft, updateDraft } from "../../../api/draft";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { setValue } from "../../../components/UI/RichTextBox/redux/rich-textbox-action";

const { TabPane } = Tabs;

class index extends Component {
  newEditorState = EditorState.createEmpty();
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      locations: [],
      customizeDate: [],
      location: null,
      merchant: [],
      bank: null,
      favoriteArea: null,
      favoriteAreas: [],
      termsAndConditions: [],
      merchantData: [],
      merchantList: [],
      bankData: [],
      isLoading: false,
      isLoadingDrafts: false,
      isCategoryLoading: false,
      isBankLoading: false,
      isMerchantLoading: false,
      fileName: null,
      isImageUploading: false,
      categoryOptions: [],
      tags: [],
      cardTypes: [],
      validLocation: [],
      termsAndCondition: null,
      event: {
        base64: null,
      },
      cropBase64: null,
      src: null,
      isUploadImage: false,
      crop: {
        unit: "px", // default, can be 'px' or '%'
        width: 800,
        x: 130,
        y: 50,
        aspect: 6 / 3,
      },
      option: [],
      mobileNoList: [],
      emailList: [],
      selectOfferId: "",
      editorState: this.newEditorState,
      draftHTMLValue: null,
      dataForm: {
        offerTitle: {
          key: "offerTitle",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer title",
        },
        offerHeader: {
          key: "offerHeader",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            maxLength: "100",
          },
          value: "",
          validation: {
            required: true,
            maxLength: "100",
          },
          valid: false,
          touched: false,
          label: "offer Header",
        },
        offerDescription: {
          key: "offerDescription",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer Description",
        },
        contactNo: {
          key: "contactNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Contact no",
        },
        userName: {
          key: "userName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "User Name",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
        offerRate: {
          key: "offerRate",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer Rate (%) ",
        },
        category: {
          key: "category",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer category",
        },
        offerType: {
          key: "offerType",
          elementType: "",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [
              { id: uuid(), value: "off", isActive: false },
              { id: uuid(), value: "upto", isActive: false },
              { id: uuid(), value: "discount", isActive: false },
              { id: uuid(), value: "customize", isActive: false },
            ],
            disabled: false,
          },
          value: "off",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer category",
        },
        merchant: {
          key: "merchant",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Merchant",
        },
        bank: {
          key: "bank",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Bank",
        },
        location: {
          key: "location",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Location",
        },
        promoCode: {
          key: "promoCode",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Promo Code",
        },
        offerValidity: {
          key: "offerValidity",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [
              { id: uuid(), key: "fromTo", value: "From - To" },
              { id: uuid(), key: "until", value: "Until" },
              { id: uuid(), key: "till", value: "till" },
              { id: uuid(), key: "on", value: "on" },
              { id: uuid(), key: "customize", value: "Customize" },
              { id: uuid(), key: "other", value: "Other" },
            ],
            disabled: false,
          },
          value: "From - To",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer Validity",
        },
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
        referenceURL: {
          key: "referenceURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Reference URL",
        },
        endDate: {
          key: "endDate",
          elementType: "datePicker",
          elementConfig: {
            label: "End Date",
            size: "md",
            width: "100%",
            placeholder: "DD-MM-YYYY",
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "End date",
        },
        startDate: {
          key: "startDate",
          elementType: "datePicker",
          elementConfig: {
            label: "End Date",
            size: "md",
            width: "100%",
            placeholder: "DD-MM-YYYY",
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Start date",
        },
        validDate: {
          key: "validDate",
          elementType: "datePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid date",
            disabled: false,
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Valid date",
        },
        startTime: {
          key: "startTime",
          elementType: "timePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid time",
            disabled: false,
          },
          value: "",
          timeValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Start time",
        },
        endTime: {
          key: "endTime",
          elementType: "timePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid time",
            disabled: false,
          },
          value: "",
          timeValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "End time",
        },
        validTimeDateDescription: {
          key: "validTimeDateDescription",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Happy hour",
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          label: "Valid (Time/Date) Description",
        },
        tag: {
          key: "tag",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "Ex : ABC Hotel ",
            tag: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Merchant",
        },
        termsAndConditions: {
          key: "termsAndConditions",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Terms and conditions",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.clearAll();
    await this.setAllData();
  }

  async setAllFieldsData() {
    this.setState({
      isCategoryLoading: true,
      isMerchantLoading: true,
      isBankLoading: true,
    });

    let resCategory = await getCategory();
    let resMerchant = await getMerchant();
    let resBank = await getBank();
    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["merchant"].elementConfig.options = await this.syncMerchant(
      resMerchant.data
    );
    this.state.merchantList = resMerchant.data;
    updateForm["bank"].elementConfig.options = await this.syncBank(
      resBank.data
    );
    console.log({ updateForm });
    this.setState({
      dataForm: updateForm,
      isCategoryLoading: !resCategory.data.length,
      isMerchantLoading: !resMerchant.data.length,
      isBankLoading: !resBank.data.length,
    });
  }

  async setAllData() {
    this.props.clearSelectedOfferDetails();
    this.props.clearSearchMerchant();
    this.props.clearTermsAndConditions();
    this.props.refreshOffers();
    this.setState({
      isCategoryLoading: true,
      isMerchantLoading: true,
      isBankLoading: true,
    });

    let resCategory = await getCategory();
    let resMerchant = await getMerchant();
    let resBank = await getBank();
    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["merchant"].elementConfig.options = await this.syncMerchant(
      resMerchant.data
    );
    this.state.merchantList = resMerchant.data;
    updateForm["bank"].elementConfig.options = await this.syncBank(
      resBank.data
    );
    console.log({ updateForm });
    this.setState({
      dataForm: updateForm,
      isCategoryLoading: !resCategory.data.length,
      isMerchantLoading: !resMerchant.data.length,
      isBankLoading: !resBank.data.length,
    });
  }

  async syncCategory(res) {
    let categoryList = [];
    this.setState({ isCategoryLoading: true });
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
        tags: category.tags,
      });
    }
    return categoryList;
  }

  async syncMerchant(res) {
    let categoryList = [];
    for (let merchant of res) {
      categoryList.push({
        id: merchant.merchantId,
        value: merchant.merchantName,
        displayValue: merchant.merchantName,
        logo: merchant.logoURL,
        location: merchant.location,
        termsAndCondition: merchant.termsAndCondition,
      });
    }
    return categoryList;
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        id: data.bankId,
        value: data.bankName,
        displayValue: data.bankName,
        logoURL: data.logoURL,
        cardTypes: data.cardTypes,
      });
    }
    return resList;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    const draftHTMLValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    this.setState({ draftHTMLValue });

    this.props.setValue(draftHTMLValue);
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;
    if (rules.required && !moment.isMoment(value)) {
      isValid = value && value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    let formatDate = "";
    if (
      inputIdentity === "startDate" ||
      inputIdentity === "endDate" ||
      inputIdentity === "validDate"
    ) {
      const parsedDate = moment(event._d, "YYYY-MM-DD");

      formatDate = parsedDate.format("YYYY-MM-DD");
    }
    if (inputIdentity === "startTime" || inputIdentity === "endTime") {
      const parsedDate = moment(event._d, "DD-MM-YYYY");

      formatDate = parsedDate.format("YYYY-MM-DD");
    }
    switch (inputIdentity) {
      case "location":
        val = event && event.target ? event.target.value : event;
        this.setState({
          location: val,
        });
        break;
      case "category":
        val = event && event.target ? event.target.value : event;
        let data = updateForm.category.elementConfig.options.filter(
          (x) => x.value === val
        );
        console.log("data", data);
        this.setState((state) => {
          if (val) {
            const favoriteAreas = state.favoriteAreas.concat({
              id: data && data[0].id,
              value: data && data[0].value,
              isActive: false,
              tags: data && data[0].tags,
            });

            let tags = [];
            for (
              let i = 0;
              i < data && data[0] && data[0].tags && data[0].tags.length;
              i++
            ) {
              tags.push({
                id: data && data[0].tags[i].id,
                value: data && data[0].tags[i].tagName,
                count: data && data[0].tags[i].count,
                isActive: false,
              });
            }
            tags.push({
              id: uuid(),
              value: "other",
              count: 0,
              isActive: false,
            });
            state.tags.concat(tags);
            return {
              favoriteAreas,
              tags,
            };
          } else {
            const favoriteAreas = [];
            const tags = [];
            return {
              favoriteAreas,
              tags,
            };
          }
        });
        updatedFormElement.value = val;
        break;
      case "merchant":
        val = event && event.target ? event.target.value : event;
        let dataMerchant = updateForm.merchant.elementConfig.options.filter(
          (x) => x.value === val
        );
        let selectMerchant = this.state.merchantList.filter(
          (x) => x.merchantName === val
        );
        console.log("selectMerchant", selectMerchant);
        this.setState((state) => {
          if (val) {
            const merchantData = state.merchantData.concat({
              id: dataMerchant && dataMerchant[0].id,
              value: dataMerchant && dataMerchant[0].value,
              termsAndCondition: selectMerchant?.termsAndCondition,
            });
            const merchant = state.merchant.concat({
              id: dataMerchant && dataMerchant[0].id,
              value: dataMerchant && dataMerchant[0].value,
              location: selectMerchant && selectMerchant[0]?.location,
              termsAndCondition:
                selectMerchant && selectMerchant[0]?.termsAndCondition,
            });
            console.log("location", dataMerchant);

            const updateForm = { ...this.state.dataForm };
            updateForm["offerTitle"].value =
              dataMerchant && dataMerchant[0].value;
            this.setState({ dataForm: updateForm, formIsValid: false });

            let validLocation = [];
            let termsAndConditions = [];
            for (let i = 0; i < dataMerchant[0]?.location?.length; i++) {
              validLocation.push({
                id: dataMerchant && dataMerchant[0].location[i].id,
                value: dataMerchant && dataMerchant[0].location[i].tagName,
                count: dataMerchant && dataMerchant[0].location[i].count,
                isActive: false,
              });
            }
            for (
              let i = 0;
              i < dataMerchant[0]?.termsAndCondition?.length;
              i++
            ) {
              termsAndConditions.push({
                id: dataMerchant && dataMerchant[0].termsAndCondition[i].id,
                value:
                  dataMerchant && dataMerchant[0].termsAndCondition[i].value,
                isActive: false,
              });
            }
            state.termsAndConditions.concat(termsAndConditions);
            state.validLocation.concat(validLocation);

            return {
              merchantData,
              merchant,
              validLocation,
              termsAndConditions,
            };
          } else {
            const merchantData = [];
            return {
              merchantData,
            };
          }
        });
        updatedFormElement.value = val;
        break;
      case "bank":
        val = event && event.target ? event.target.value : event;
        let dataBank = updateForm.bank.elementConfig.options.filter(
          (x) => x.value === val
        );
        console.log("bank", dataBank);
        this.setState((state) => {
          if (val) {
            const bankData = state.bankData.concat({
              id: dataBank && dataBank[0].id,
              value: dataBank && dataBank[0].value,
            });

            let cardTypes = [];
            for (let i = 0; i < dataBank[0].cardTypes.length; i++) {
              cardTypes.push({
                id: dataBank && dataBank[0].cardTypes[i].id,
                value: dataBank && dataBank[0].cardTypes[i].cardName,
                count: dataBank && dataBank[0].cardTypes[i].count,
                isActive: false,
              });
            }
            state.cardTypes.concat(cardTypes);
            return {
              bankData,
              cardTypes,
            };
          } else {
            const bankData = [];
            const cards = [];
            return {
              bankData,
              cards,
            };
          }
        });
        updatedFormElement.value = val;
        break;
      case "endDate":
        let dat_val = moment(event._d).format("YYYY-MM-DD");
        console.log("endDate", dat_val);
        //console.log("inputHandleChange", moment(event._d).format("DD-MM-YYYY"));
        updatedFormElement.value = event;
        updatedFormElement.dateValue = dat_val;
        break;
      case "startDate":
        let sdat_val = moment(event._d).format("YYYY-MM-DD");
        updatedFormElement.value = event;
        updatedFormElement.dateValue = sdat_val;
        break;
      case "validDate":
        let vdat_val = moment(event._d).format("YYYY-MM-DD");
        updatedFormElement.value = event;
        updatedFormElement.dateValue = vdat_val;
        break;
      case "startTime":
        let stime_val = moment(event._d).format("h:mm a");
        updatedFormElement.value = event;
        updatedFormElement.timeValue = stime_val;
        break;
      case "endTime":
        let etime_val = moment(event._d).format("h:mm a");
        updatedFormElement.value = event;
        updatedFormElement.timeValue = etime_val;
        break;
      case "termsAndConditions":
        let _event = event && event.target ? event.target.value : event;
        this.setState({
          termsAndCondition: _event,
        });
      default:
        updatedFormElement.value = event.target.value;
    }
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", updateForm);
  };

  async clearAll() {
    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      updateForm[key].value = "";
      updateForm[key].valid = false;
      updateForm[key].touched = false;
      if (key === "startDate" || key === "endDate" || key === "validDate") {
        updateForm[key].dateValue = "";
      }
      if (key === "startTime" || key === "endTime") {
        updateForm[key].timeValue = "";
      }
    }
    this.setState({ dataForm: updateForm });
  }
  async clearStateValues() {
    // this.props.clearSelectedOfferDetails();
    // this.props.clearSearchMerchant();
    // this.props.refreshOffers();
    this.setState({
      locations: [],
      merchantData: [],
      termsAndConditions: [],
      merchant: [],
      cardTypes: [],
      location: null,
      termsAndCondition: null,
      favoriteArea: null,
      isLoading: false,
      isCategoryLoading: false,
      isBankLoading: false,
      isMerchantLoading: false,
      merchantList: [],
      bankData: [],
      validLocation: [],
    });
  }

  async onClickSaveDraftData() {
    const { value } = this.props;
    this.setState({ isLoadingDrafts: true });
    let dataForm = this.state.dataForm;
    let state = this.state;

    const selectedBank = this.props.selectedBank;
    const selectCategory = this.props.selectedCategory;
    const selectMerchant = this.props.selectMerchant;

    let terms = { ...this.props.selectTerms };
    terms[selectedBank?.bankId] = state.termsAndConditions;

    if (this.props.selectedOfferData && this.props.selectedOfferData?.draftId) {
      let resDraft = await updateDraft(
        {
          categoryId: selectCategory?.categoryId,
          merchantId: selectMerchant?.merchantId,
          bankId: selectedBank?.bankId,
          label: dataForm.offerTitle.value,
          header: dataForm.offerHeader.value,
          bannerImageURL: dataForm.logoURL.value,
          referenceURL: dataForm.referenceURL.value,
          offerType: dataForm.offerType.value,
          offerRate: dataForm.offerRate.value,
          offerDateType: dataForm.offerValidity.value,
          offerStart: dataForm.startDate.dateValue,
          offerEnd: dataForm.endDate.dateValue,
          description: value ? value.payload : state.draftHTMLValue,
          cardTypes: state.cardTypes,
          validDate: dataForm.validDate.dateValue,
          startTime: dataForm.startTime.timeValue,
          endTime: dataForm.endTime.timeValue,
          validTimeDateDescription: dataForm.validTimeDateDescription.value,
          termsConditions: {
            [selectedBank?.bankId]: terms[selectedBank?.bankId],
          },
          location: state.validLocation,
          hotLine: {
            mobileNo: state.mobileNoList,
            email: state.emailList,
          },
          customizeDate: {
            endDate: dataForm.endDate.dateValue,
            description: dataForm.validTimeDateDescription.value,
          },
          promoCode: dataForm.promoCode.value,
          status: "New",
        },
        this.props.selectedOfferData?.draftId
      );
      console.log({ resDraft });
      if (resDraft && resDraft?.data && resDraft?.data.status) {
        this.props.refreshDraftLists();
        await this.clearAll();
        await this.clearStateValues();
        this.props.clearSelectedOfferDetails();
        this.props.clearSearchMerchant();
        this.setState({ isLoadingDrafts: false });
      }
    }
  }

  async onClickSaveData() {
    const { value } = this.props;
    console.log("event fire");
    this.setState({ isLoading: false });
    let dataForm = this.state.dataForm;
    let state = this.state;

    const selectedBank = this.props.selectedBank;
    const selectCategory = this.props.selectedCategory;
    const selectMerchant = this.props.selectMerchant;

    this.updateFormValidity(dataForm.offerTitle.key);
    this.updateFormValidity(dataForm.offerHeader.key);
    this.updateFormValidity(dataForm.offerDescription.key);
    this.updateFormValidity(dataForm.offerRate.key);
    this.updateFormValidity(dataForm.logoURL.key);

    if (!selectCategory) {
      this.updateFormValidity(dataForm.category.key);
    }

    if (state.validLocation.length === 0) {
      //this.updateFormValidity(dataForm.category.key);

      OpenNotification("Error", "Please select valid location !", "error");
      //return;
    }
    if (!selectMerchant) {
      this.updateFormValidity(dataForm.merchant.key);
    }
    if (!selectedBank) {
      this.updateFormValidity(dataForm.bank.key);
    }

    if (state.termsAndConditions.length === 0) {
      this.updateFormValidity(dataForm.termsAndConditions.key);
    }

    if (dataForm["offerValidity"].value === "fromTo") {
      this.updateFormValidity(dataForm.startDate.key);
      this.updateFormValidity(dataForm.endDate.key);
    }

    if (dataForm["offerValidity"].value === "until") {
      this.updateFormValidity(dataForm.endDate.key);
    }
    if (dataForm["offerValidity"].value === "till") {
      this.updateFormValidity(dataForm.endDate.key);
    }
    if (dataForm["offerValidity"].value === "on") {
      this.updateFormValidity(dataForm.endDate.key);
    }

    if (dataForm["offerValidity"].value === "customize") {
      this.updateFormValidity(dataForm.validDate.key);
      this.updateFormValidity(dataForm.startTime.key);
      this.updateFormValidity(dataForm.endTime.key);
      this.updateFormValidity(dataForm.validTimeDateDescription.key);
    }
    if (dataForm["offerValidity"].value === "other") {
      this.updateFormValidity(dataForm.endDate.key);
      this.updateFormValidity(dataForm.validTimeDateDescription.key);
    }

    console.log("dataForm", dataForm);

    if (dataForm["offerValidity"].value === "fromTo") {
      if (!dataForm.startDate.valid && !dataForm.endDate.valid) {
        OpenNotification(
          "Error",
          "Offer valid date is required (start/end-date)",
          "error"
        );
        return;
      }
      if (!dataForm.startDate.valid) {
        OpenNotification(
          "Error",
          "Offer valid date is required (start-date)",
          "error"
        );
        return;
      }
      if (!dataForm.endDate.valid) {
        OpenNotification(
          "Error",
          "Offer valid date is required (end-date)",
          "error"
        );
        return;
      }
    }
    if (dataForm["offerValidity"].value === "until") {
      if (!dataForm.endDate.valid) {
        OpenNotification(
          "Error",
          "Offer valid date is required (end-date)!",
          "error"
        );
        return;
      }
    }

    if (dataForm["offerValidity"].value === "till") {
      if (!dataForm.endDate.valid) {
        OpenNotification("Error", "Offer valid date is required!", "error");
        return;
      }
    }

    if (dataForm["offerValidity"].value === "on") {
      if (!dataForm.endDate.valid) {
        OpenNotification("Error", "Offer valid date is required!", "error");
        return;
      }
    }
    if (dataForm["offerValidity"].value === "customize") {
      if (!dataForm.validDate.valid) {
        OpenNotification("Error", "Offer valid date is required!", "error");
        return;
      }
      if (!dataForm.startTime.valid) {
        OpenNotification("Error", "start time is required!", "error");
        return;
      }
      if (!dataForm.endTime.valid) {
        OpenNotification("Error", "end time is required!", "error");
        return;
      }
    }
    if (dataForm["offerValidity"].value === "other") {
      if (!dataForm.endDate.valid) {
        OpenNotification("Error", "Offer valid date is required!", "error");
        return;
      }
    }

    if (
      dataForm.offerTitle.valid &&
      dataForm.offerRate.valid &&
      selectedBank &&
      state.termsAndConditions.length &&
      dataForm.offerHeader.valid &&
      this.props.userData
    ) {
      this.setState({ isLoading: true });

      let terms = { ...this.props.selectTerms };

      terms[selectedBank?.bankId] = state.termsAndConditions;

      // let selectTermsCondition = {}
      // if (terms[selectedBank?.bankId]) {
      //   selectTermsCondition = {[selectedBank?.bankId]: terms[selectedBank?.bankId]};
      // }

      //todo
      //confirm data

      console.log("terms save:::", terms);

      let body = {
        userId: this.props.userData.userId,
        category: selectCategory,
        merchant: selectMerchant,
        bank: selectedBank,
        label: dataForm.offerTitle.value,
        header: dataForm.offerHeader.value,
        bannerImageURL: dataForm.logoURL.value,
        referenceURL: dataForm.referenceURL.value,
        offerType: dataForm.offerType.value,
        offerRate: dataForm.offerRate.value,
        offerDateType: dataForm.offerValidity.value,
        offerStart: dataForm.startDate.dateValue,
        offerEnd: dataForm.endDate.dateValue,
        description: value ? value.payload : state.draftHTMLValue,
        cardTypes: state.cardTypes,
        validDate: dataForm.validDate.dateValue,
        startTime: dataForm.startTime.timeValue,
        endTime: dataForm.endTime.timeValue,
        validTimeDateDescription: dataForm.validTimeDateDescription.value,
        termsConditions: {
          [selectedBank?.bankId]: terms[selectedBank?.bankId],
        },
        location: state.validLocation,
        hotLine: {
          mobileNo: state.mobileNoList,
          email: state.emailList,
        },
        customizeDate: {
          endDate: dataForm.endDate.dateValue,
          description: dataForm.validTimeDateDescription.value,
        },
        promoCode: dataForm.promoCode.value,
        status: "New",
      };

      if (
        this.props.selectedOfferData &&
        this.props.selectedOfferData?.draftId
      ) {
        let resDraft = await updateDraft(
          {
            categoryId: selectCategory?.categoryId,
            merchantId: selectMerchant?.merchantId,
            bankId: selectedBank?.bankId,
            label: dataForm.offerTitle.value,
            header: dataForm.offerHeader.value,
            bannerImageURL: dataForm.logoURL.value,
            referenceURL: dataForm.referenceURL.value,
            offerType: dataForm.offerType.value,
            offerRate: dataForm.offerRate.value,
            offerDateType: dataForm.offerValidity.value,
            offerStart: dataForm.startDate.dateValue,
            offerEnd: dataForm.endDate.dateValue,
            description: value ? value.payload : state.draftHTMLValue,
            cardTypes: state.cardTypes,
            validDate: dataForm.validDate.dateValue,
            startTime: dataForm.startTime.timeValue,
            endTime: dataForm.endTime.timeValue,
            validTimeDateDescription: dataForm.validTimeDateDescription.value,
            termsConditions: {
              [selectedBank?.bankId]: terms[selectedBank?.bankId],
            },
            location: state.validLocation,
            hotLine: {
              mobileNo: state.mobileNoList,
              email: state.emailList,
            },
            customizeDate: {
              endDate: dataForm.endDate.dateValue,
              description: dataForm.validTimeDateDescription.value,
            },
            promoCode: dataForm.promoCode.value,
            status: "New",
          },
          this.props.selectedOfferData?.draftId
        );
        console.log({ resDraft });
      }
      this.props.onClickOpenOfferView(body);
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
      OpenNotification("Error", "Invalid data", "error");
    }
  }

  async onClickUpdateData() {
    const { value } = this.props;
    console.log("event fire");
    this.setState({ isLoading: false });
    let dataForm = this.state.dataForm;
    let state = this.state;

    const selectedBank = this.props.selectedBank;
    const selectCategory = this.props.selectedCategory;
    const selectMerchant = this.props.selectMerchant;
    const editOfferData = this.props.editOfferData;
    if (editOfferData && editOfferData?.offerId) {
      this.updateFormValidity(dataForm.offerTitle.key);
      this.updateFormValidity(dataForm.offerHeader.key);
      this.updateFormValidity(dataForm.offerDescription.key);
      this.updateFormValidity(dataForm.offerRate.key);
      this.updateFormValidity(dataForm.logoURL.key);

      if (!selectCategory) {
        this.updateFormValidity(dataForm.category.key);
      }

      if (state.validLocation.length === 0) {
        //this.updateFormValidity(dataForm.category.key);

        OpenNotification("Error", "Please select valid location !", "error");
        //return;
      }
      if (!selectMerchant) {
        this.updateFormValidity(dataForm.merchant.key);
      }
      if (!selectedBank) {
        this.updateFormValidity(dataForm.bank.key);
      }

      if (state.termsAndConditions.length === 0) {
        this.updateFormValidity(dataForm.termsAndConditions.key);
      }

      if (
        dataForm.offerTitle.valid &&
        dataForm.offerRate.valid &&
        selectedBank &&
        state.termsAndConditions.length &&
        dataForm.offerHeader.valid &&
        this.props.userData
      ) {
        this.setState({ isLoading: true });

        let terms = { ...this.props.selectTerms };

        terms[editOfferData?.bankId] = state.termsAndConditions;
        //todo
        console.log("terms save:::", terms);

        let body = {
          categoryId: selectCategory
            ? selectCategory?.categoryId
            : editOfferData.categoryId,
          merchantId: selectMerchant
            ? selectMerchant?.merchantId
            : editOfferData.merchantId,
          bankId: selectedBank ? selectedBank?.bankId : editOfferData.bankId,
          label: dataForm.offerTitle.value,
          header: dataForm.offerHeader.value,
          offerDateType: dataForm.offerValidity.value,
          offerStart:
            dataForm.startDate.dateValue != ""
              ? dataForm.startDate.dateValue
              : editOfferData.startDate,
          offerEnd:
            dataForm.endDate.dateValue != ""
              ? dataForm.endDate.dateValue
              : editOfferData.endDate,
          customizeDate: {
            endDate: dataForm.endDate.dateValue,
            description: dataForm.validTimeDateDescription.value,
          },
          validDescription: editOfferData?.validDescription,
          cardTypes: state.cardTypes,
          offerType: dataForm.offerType.value,
          offerRate: dataForm.offerRate.value,
          location: state.validLocation,
          hotLine: {
            mobileNo: state.mobileNoList,
            email: state.emailList,
          },
          description:
            value && value?.payload
              ? value?.payload
              : state.draftHTMLValue
              ? state.draftHTMLValue
              : editOfferData.description,
          termsConditions: {
            [selectedBank?.bankId]: terms[selectedBank?.bankId],
          },
          bannerImageURL: dataForm.logoURL.value,
          referenceURL: dataForm.referenceURL.value,
          gallery: [],
          validDate:
            dataForm.validDate.dateValue != ""
              ? dataForm.validDate.dateValue
              : editOfferData.validDate,
          startTime:
            dataForm.startTime.dateValue != ""
              ? dataForm.startTime.timeValue
              : editOfferData.startTime,
          endTime:
            dataForm.endTime.dateValue != ""
              ? dataForm.endTime.timeValue
              : editOfferData.endTime,
          promoCode:
            dataForm.promoCode.value != ""
              ? dataForm.promoCode.value
              : editOfferData.promoCode,
          validTimeDateDescription: dataForm.validTimeDateDescription.value,
        };

        //confirm data
        let res = await updateOfferDetails(body, editOfferData?.offerId);

        if (res && res.data && res.data.status) {
          this.setState({ isLoading: false });
          await this.clearAll();
          await this.clearStateValues();
          await this.setAllData();
          this.props.refreshOffers();
          this.props.clearSelectedOfferDetails();
          this.props.clearSearchMerchant();
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
        } else {
          this.setState({ isLoading: false });
          OpenNotification("error", res && res.data && res.data.error, "error");
          //await this.clearAll();
        }
      } else {
        this.setState({ isLoading: false });
        OpenNotification("Error", "Invalid data", "error");
      }
    }
  }

  //onClick add #tags
  onClickAddHashTag = () => {
    const dataForm = {
      ...this.state.dataForm,
    };
    console.log(...this.state.merchant, ...this.state.termsAndConditions);
    dataForm.this.setState((state) => {
      if (dataForm.keywords.value) {
        const favoriteAreas = state.favoriteAreas.concat({
          id: uuid(),
          tagName: dataForm.keywords.value,
          count: 0,
        });
        return {
          favoriteAreas,
          favoriteArea: null,
        };
      }
    });

    const updateForm = { ...this.state.dataForm };
    updateForm["keywords"].value = "";
  };

  onClickAddTermsAndConditions = () => {
    const dataForm = {
      ...this.state.dataForm,
    };
    this.setState((state) => {
      if (state.termsAndCondition) {
        const termsAndConditions = state.termsAndConditions.concat({
          id: uuid(),
          value: state.termsAndCondition,
          isActive: true,
          count: 0,
        });
        return {
          termsAndConditions,
          termsAndCondition: null,
        };
      }
    });
    dataForm.termsAndConditions.value = "";
  };

  onClickTermsAndConditions = (i) => {
    const { termsAndConditions } = this.state;
    let terms = { ...this.props.selectTerms };

    const updatedTermsConditions = termsAndConditions.map((term, index) => ({
      ...term,
      isActive: index === i ? !term.isActive : term.isActive,
    }));

    terms[this.props.selectedBank?.bankId] = updatedTermsConditions;

    this.props.onLoadTermsAndCondition(terms);

    this.setState({ termsAndConditions: updatedTermsConditions });
  };

  selectAllTermsAndConditions = async () => {
    let newTerms = [];
    await this.setState((prevState) => {
      const { termsAndConditions } = prevState;
      const allSelected = termsAndConditions.every((term) => term.isActive);
      console.log({ allSelected });
      const updatedTerms = termsAndConditions.map((term) => ({
        ...term,
        isActive: !allSelected,
      }));
      newTerms = updatedTerms;
      return { termsAndConditions: updatedTerms };
    });
    let terms = { ...this.props.selectTerms };
    terms[this.props.selectedBank?.bankId] = newTerms;

    // if (this.props.editOfferData) {
    //   const { editOfferData, onClickSelectOffer } = this.props;
    //   const bankId = editOfferData.bankId;

    //   const termsConditions =
    //     terms?.[bankId]?.length > 0
    //       ? terms?.[bankId]
    //       : editOfferData?.termsConditions?.[bankId]?.length > 0
    //       ? editOfferData?.termsConditions?.[bankId]
    //       : {};

    //   onClickSelectOffer({
    //     ...editOfferData,
    //     termsConditions:
    //       termsConditions && termsConditions.length > 0
    //         ? { [bankId]: termsConditions }
    //         : {},
    //   });
    // }
    this.props.onLoadTermsAndCondition(terms);
  };

  selectAllCardTypes = () => {
    const { cardTypes } = this.state;
    for (let idx = 0; idx < cardTypes.length; idx++) {
      if (cardTypes[idx].isActive) {
        cardTypes[idx].isActive = false;
      } else {
        cardTypes[idx].isActive = true;
      }
    }
    this.setState({ cardTypes });
  };

  onSelect = (_item, i) => {
    console.log("selectEventFire", { _item });
    const updateForm = { ...this.state.dataForm };
    updateForm["offerTitle"].value = _item.value;
    this.setState({ dataForm: updateForm, formIsValid: false });
  };

  onSelectCard = (_item, i) => {
    const { cardTypes } = this.state;
    // cardTypes[i].isActive = !cardTypes[i].isActive;
    // this.setState({ cardTypes });
    const updatedCardTypes = cardTypes.map((cardType, index) => {
      if (index === i) {
        return {
          ...cardType,
          isActive: !cardType.isActive,
        };
      }
      return cardType;
    });
    this.setState({ cardTypes: updatedCardTypes });
  };

  onSelectLocation = (_item, i) => {
    const { validLocation } = this.state;
    const updatedValidLocation = validLocation.map((location, index) => {
      if (index === i) {
        return {
          ...location,
          isActive: !location.isActive,
        };
      }
      return location;
    });
    this.setState({ validLocation: updatedValidLocation });
  };

  onSelectOffer = (_item, i) => {
    console.log("selectEventFire", { _item });
    const updateForm = { ...this.state.dataForm };
    updateForm["offerType"].value = _item.value;
    this.setState({ dataForm: updateForm, formIsValid: false });
  };

  onSelectOption = (_item, key) => {
    console.log("selectEventFire", { _item });
    const updateForm = { ...this.state.dataForm };
    updateForm[key].value = _item.key;
    this.setState({ dataForm: updateForm, formIsValid: false });
  };

  handleKeyPress = (event, key) => {
    if (event.key === "Enter") {
      if (key === "termsAndConditions") {
        this.onClickAddTermsAndConditions();
      } else {
        this.onClickAddHashTag();
        this.onClickAddLocation();
      }
    }
  };

  //onClick add #tags
  onClickAddLocation = () => {
    this.setState((state) => {
      if (state.location) {
        const locations = state.locations.concat({
          id: uuid(),
          tagName: state.location,
          count: 0,
        });
        return {
          locations,
          location: null,
        };
      }
    });

    const updateForm = { ...this.state.dataForm };
    updateForm["location"].value = "";
  };

  onRemove = (item, i) => {
    console.log("remove event fire...." + i);
    this.setState((state) => {
      const tags = state.tags.filter((item, j) => i !== j);
      return {
        tags,
      };
    });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const imageRefBase64 = this.imageRef.src;
      this.setState({
        base64: imageRefBase64,
        croppedImageUrl,
      });
    }
  }

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.uploadImage(result_base64);
  }

  async uploadImage(base64) {
    this.setState({ isImageUploading: true });
    let res = await uploadImage(this.state.cropBase64, "offer", "Offer");
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = res.data.data.URL;
      this.setState({ dataForm: updateForm });
      this.setState({ isImageUploading: false, src: null });
    } else {
      this.setState({ isImageUploading: true });
    }
  }

  onSelectFile = (e) => {
    console.log("event fire...");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onClickClearImage() {
    const updateForm = { ...this.state.dataForm };
    updateForm["logoURL"].value = "";
    this.setState({ dataForm: updateForm });
    this.setState({ isImageUploading: false, src: null });
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    this.setState({
      cropBase64: base64Image,
    });
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  closeCropperWindow() {
    const updateForm = { ...this.state.dataForm };
    updateForm["logoURL"].value = "";
    this.setState({
      dataForm: updateForm,
      isImageUploading: false,
      src: "",
    });
  }

  setSelectOfferData(selectOffer) {}

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedBank !== this.props.selectedBank ||
      prevProps.selectLocations !== this.props.selectLocations ||
      prevProps.selectTerms !== this.props.selectTerms ||
      prevProps.selectMerchant !== this.props.selectMerchant ||
      prevProps.selectedOfferData !== this.props.selectedOfferData
    ) {
      let currentCardTypes = [...this.state.cardTypes];

      this.setState({
        bankData: [],
        termsAndConditions: [],
      });
      const selectOffer = this.props.selectedOfferData;
      const selectedTerms = this.props.selectTerms;
      const selectedLocations = this.props.selectLocations;
      const selectedBank = this.props.selectedBank;
      const selectMerchant = this.props.selectMerchant;
      if (
        selectOffer &&
        selectMerchant &&
        selectedBank &&
        selectedLocations &&
        selectedTerms
      ) {
        await this.setAllFieldsData();
      }
      //this.onClickClearImage();

      if (selectedBank?.bankId) {
        const bankData = [
          ...this.state.bankData,
          {
            id: selectedBank.bankId,
            value: selectedBank.bankName,
          },
        ];

        const updateCardTypes =
          selectedBank?.cardTypes?.length > 0
            ? selectedBank?.cardTypes.map((cardType) => {
                const foundCardType = currentCardTypes.find(
                  (curr) => curr.value === (cardType.value || cardType.cardName)
                );
                console.log({ foundCardType });
                return {
                  id: cardType.id,
                  value: cardType.value || cardType.cardName,
                  count: cardType.count,
                  isActive: foundCardType
                    ? foundCardType.isActive
                    : cardType.isActive,
                };
              })
            : [];

        this.setState({ bankData, cardTypes: updateCardTypes });
      } else {
        this.setState({
          bankData: [],
          cardTypes: [],
        });
      }
      if (
        selectMerchant?.merchantId &&
        (selectMerchant?.hotLine || selectOffer?.hotLine)
      ) {
        this.setState({
          mobileNoList:
            selectMerchant?.hotLine?.mobileNo || selectOffer?.hotLine?.mobileNo,
          emailList:
            selectMerchant?.hotLine?.email || selectOffer?.hotLine?.email,
        });
      } else {
        this.setState({
          mobileNoList: [],
          emailList: [],
        });
      }

      if (selectedTerms || selectedLocations?.length > 0) {
        console.log("selectLocations:::", selectedLocations);
        let currentLocation = this.state.validLocation;
        const validLocation =
          selectedLocations?.length > 0
            ? selectedLocations.map((location) => {
                const foundLocation = currentLocation.find(
                  (curr) => curr.value === (location.value || location.tagName)
                );
                return {
                  id: location.id,
                  value: location.value || location.tagName,
                  count: location.count,
                  isActive: foundLocation
                    ? foundLocation.isActive
                    : location.isActive,
                };
              })
            : [];
        let termsAndConditions = [];
        if (selectedTerms[selectedBank?.bankId]) {
          termsAndConditions = selectedTerms[selectedBank?.bankId].map(
            (term) => ({
              id: term.id,
              value: term.value,
              count: term?.count,
              isActive: term.isActive,
            })
          );
        } else {
          if (!Array.isArray(selectedTerms)) {
            console.error("selectedTerms is not an array");
          } else {
            for (let i = 0; i < selectedTerms.length; i++) {
              const term = selectedTerms[i];
              termsAndConditions.push({
                id: term.id,
                value: term.value,
                count: term?.count,
                isActive: term.isActive,
              });
            }
          }
        }

        console.log("validLocation:::", validLocation);
        this.setState({ validLocation, termsAndConditions });
      } else {
        this.setState({
          validLocation: [],
          termsAndConditions: [],
        });
      }

      console.log("this.state.draftHTMLValue:::", this.state.draftHTMLValue);
      const updateForm = { ...this.state.dataForm };
      if (
        (selectOffer &&
          selectOffer?.offerId &&
          selectOffer?.offerId != this.state.selectOfferId) ||
        (selectOffer?.referenceURL !== updateForm["referenceURL"].value &&
          selectOffer?.bannerImageURL !== updateForm["logoURL"].value &&
          selectOffer?.offerDateType !== updateForm["offerValidity"].value &&
          selectOffer?.offerType !== updateForm["offerType"].value &&
          selectMerchant?.merchantName !== updateForm["offerTitle"].value &&
          selectOffer?.header !== updateForm["offerHeader"].value &&
          selectOffer?.offerRate !== updateForm["offerRate"].value)
      ) {
        if (selectOffer?.description && selectOffer?.description != "") {
          console.log("description>>>>", selectOffer?.description);
          //This code runs after the component updates, but only if `count` has changed.
          const contentBlock = htmlToDraft(selectOffer.description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState });
          }
        } else {
          this.setState({
            editorState: this.newEditorState,
          });
        }
        this.setState({
          selectOfferId: selectOffer?.offerId,
        });
        updateForm["logoURL"].value = "";
        updateForm["referenceURL"].value = selectOffer?.referenceURL;
        updateForm["logoURL"].value = selectOffer?.bannerImageURL;
        updateForm["offerValidity"].value = selectOffer?.offerDateType;
        updateForm["offerType"].value = selectOffer?.offerType;
        updateForm["offerTitle"].value = selectMerchant?.merchantName;
        // updateForm["offerTitle"].elementConfig.disabled = true;
        updateForm["offerHeader"].value = selectOffer?.header;
        updateForm["offerRate"].value = selectOffer?.offerRate;
        updateForm["promoCode"].value = selectOffer?.promoCode;
        updateForm["offerDescription"].value = selectOffer?.validDescription;
        updateForm["validTimeDateDescription"].value =
          selectOffer?.validTimeDateDescription;
        // updateForm["startDate"].value = moment(selectOffer?.offerStart).format(
        //   "YYYY-MM-DD"
        // );
        // updateForm["endDate"].value = moment(selectOffer?.offerEnd).format(
        //   "YYYY-MM-DD"
        // );
        this.setState({ dataForm: updateForm });
      }
    }

    if (prevProps.editOfferData !== this.props.editOfferData) {
      const selectEditOffer = this.props.editOfferData;
      const selectedTerms = this.props.selectTerms;
      const selectedLocations = this.props.selectLocations;
      const selectedBank = selectEditOffer?.bank;
      const selectMerchant = this.props.selectMerchant;

      this.setState({
        bankData: [],
        // cardTypes: [],
        // validLocation: [],
        termsAndConditions: [],
      });
      this.clearStateValues();
      if (
        selectEditOffer &&
        selectEditOffer.offerId !== this.state.selectOfferId
      ) {
        this.clearAll();
      }
      if (selectedBank?.bankName) {
        const bankData = [
          ...this.state.bankData,
          {
            id: selectedBank.bankId,
            value: selectedBank.bankName,
          },
        ];
        let currentCardTypes = [...this.state.cardTypes];
        const updateCardTypes =
          selectEditOffer?.cardTypes?.length > 0
            ? selectEditOffer?.cardTypes.map((cardType) => {
                const foundCardType = currentCardTypes.find(
                  (curr) => curr.value === (cardType.value || cardType.cardName)
                );
                console.log({ foundCardType });
                return {
                  id: cardType.id,
                  value: cardType.value || cardType.cardName,
                  count: cardType.count,
                  isActive: foundCardType
                    ? foundCardType.isActive
                    : cardType.isActive,
                };
              })
            : [];

        this.setState({ bankData, cardTypes: updateCardTypes });
        // const cardTypes =
        //   selectEditOffer?.cardTypes?.length > 0
        //     ? [
        //         ...selectEditOffer.cardTypes.map((cardType) => ({
        //           id: cardType.id,
        //           value: cardType.value,
        //           count: cardType.count,
        //           isActive: cardType.isActive,
        //         })),
        //       ]
        //     : [];

        // this.setState({ bankData, cardTypes });
      } else {
        this.setState({
          bankData: [],
          cardTypes: [],
        });
      }

      if (selectedTerms || selectedLocations?.length > 0) {
        let currentLocation = this.state.validLocation;
        const validLocation =
          currentLocation.length > 0
            ? selectedLocations?.length > 0
              ? selectedLocations.map((location) => {
                  const foundLocation = currentLocation.find(
                    (curr) =>
                      curr.value === (location.value || location.tagName)
                  );
                  return {
                    id: location.id,
                    value: location.value || location.tagName,
                    count: location.count,
                    isActive: foundLocation
                      ? foundLocation.isActive
                      : location.isActive,
                  };
                })
              : []
            : selectedLocations?.length > 0
            ? selectedLocations.map((location) => ({
                id: location.id,
                value: location.value || location.tagName,
                count: location.count,
                isActive: location.isActive,
              }))
            : [];

        let termsAndConditions = [];
        if (selectedTerms[selectedBank?.bankId]) {
          termsAndConditions = selectedTerms[selectedBank?.bankId].map(
            (term) => ({
              id: term.id,
              value: term.value,
              count: term?.count,
              isActive: term.isActive,
            })
          );
        } else {
          if (!Array.isArray(selectedTerms)) {
            console.error("selectedTerms is not an array");
          } else {
            for (let i = 0; i < selectedTerms.length; i++) {
              const term = selectedTerms[i];
              termsAndConditions.push({
                id: term.id,
                value: term.value,
                count: term?.count,
                isActive: term.isActive,
              });
            }
          }
        }

        console.log("validLocation:::", validLocation);
        this.setState({ validLocation, termsAndConditions });
      } else {
        this.setState({
          validLocation: [],
          termsAndConditions: [],
        });
      }
      if (selectMerchant?.hotLine || selectEditOffer?.hotLine) {
        this.setState({
          mobileNoList:
            selectMerchant?.hotLine?.mobileNo ||
            selectEditOffer?.hotLine?.mobileNo,
          emailList:
            selectMerchant?.hotLine?.email || selectEditOffer?.hotLine?.email,
        });
      } else {
        this.setState({
          mobileNoList: [],
          emailList: [],
        });
      }

      const updateForm = { ...this.state.dataForm };
      if (
        (selectEditOffer &&
          selectEditOffer.offerId &&
          selectEditOffer.offerId !== this.state.selectOfferId &&
          (!updateForm["referenceURL"].value ||
            !updateForm["logoURL"].value ||
            !updateForm["offerValidity"].value ||
            !updateForm["offerType"].value ||
            !updateForm["offerTitle"].value ||
            !updateForm["promoCode"].value ||
            !updateForm["offerHeader"].value ||
            !updateForm["offerRate"].value)) ||
        selectEditOffer.offerId !== this.state.selectOfferId
      ) {
        if (
          selectEditOffer?.description &&
          selectEditOffer?.description != ""
        ) {
          this.setState({ editorState: this.newEditorState });
          console.log("description>>>>", selectEditOffer?.description);
          //This code runs after the component updates, but only if `count` has changed.
          const contentBlock = htmlToDraft(selectEditOffer.description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState });
          }
        } else {
          this.setState({
            editorState: this.newEditorState,
          });
        }
        this.setState({
          selectOfferId: selectEditOffer?.offerId,
        });
        updateForm["logoURL"].value = "";
        updateForm["referenceURL"].value = selectEditOffer?.referenceURL;
        updateForm["logoURL"].value = selectEditOffer?.bannerImageURL;
        updateForm["offerValidity"].value = selectEditOffer?.offerDateType;
        updateForm["offerType"].value = selectEditOffer?.offerType;
        updateForm["offerTitle"].value = selectEditOffer?.label;
        updateForm["promoCode"].value = selectEditOffer?.promoCode;
        updateForm["offerTitle"].value = selectMerchant?.merchantName;
        updateForm["offerHeader"].value = selectEditOffer?.header;
        updateForm["offerRate"].value = selectEditOffer?.offerRate;
        updateForm["offerDescription"].value =
          selectEditOffer?.validDescription;
        updateForm["validTimeDateDescription"].value =
          selectEditOffer?.validTimeDateDescription;
        this.setState({ dataForm: updateForm });
      }
    }
    if (prevProps.isCreateOffer !== this.props.isCreateOffer) {
      if (!this.props.isCreateOffer) {
        await this.clearAll();
        await this.setAllData();
      }
    }
  }

  render() {
    const {
      value,
      selectedOfferData,
      selectMerchant,
      selectedBank,
      selectedCategory,
      selectedMerchant,
      editOfferData,
    } = this.props;
    const {
      dataForm,
      favoriteAreas,
      isLoading,
      isImageUploading,
      viewPointHeight,
      termsAndConditions,
      tags,
      cardTypes,
      validLocation,
      crop,
      src,
      isLoadingDrafts,
      editorState,
    } = this.state;

    return (
      <>
        {/*----- image crop section wrapper ------*/}
        {src && (
          <div className={"image-crop-area-wrapper"}>
            <div className={"col-lg-12"}>
              <div
                className={"modal-body-wrapper"}
                style={{ height: this.props.viewPointHeight }}
              >
                <div
                  className={"m-header-wrapper d-flex justify-content-between"}
                >
                  <div className={"m-h-title"}>Image Crop & Upload</div>
                  <div
                    className={"m-h-close"}
                    onClick={() => this.closeCropperWindow()}
                  >
                    Close
                  </div>
                </div>
                <div
                  className={
                    "image-crop-preview d-flex align-items-center justify-content-center"
                  }
                >
                  {/*<div className={'profile-logo-view-wrapper'}>*/}
                  {/*    {croppedImageUrl && (*/}
                  {/*        <img alt="Crop" src={croppedImageUrl}/>*/}
                  {/*    )}*/}
                  {/*</div>*/}
                  <div className={"upload-action-wrapper"}>
                    <div className={"profile-logo-wrapper ml-2 d-flex"}>
                      <div className={`BRattachment-wrapper light`}>
                        <>
                          <input
                            id="uploadNew"
                            type="file"
                            accept="image/*"
                            onChange={this.onSelectFile}
                            hidden
                          />
                          <div
                            className={"d-flex"}
                            onClick={() => {
                              document.getElementById("uploadNew").click();
                            }}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Upload New Image</div>
                          </div>
                        </>
                      </div>

                      <div className={"BRattachment-wrapper ml-3 light"}>
                        {!isImageUploading && (
                          <div
                            className={"d-flex"}
                            onClick={async () => {
                              await this.uploadImage();
                            }}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Save</div>
                          </div>
                        )}

                        {isImageUploading && (
                          <div className={"d-flex"}>
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Uploading...</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {src && (
                  <div
                    className={
                      "d-flex align-item-center justify-content-center"
                    }
                  >
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {this.props.refreshDrafts && (
          <div
            className={
              "d-flex flex-row justify-content-center align-items-center"
            }
          >
            <div className="cco__alert-message">
              <MdOutlineCheckCircle />
              <div>Saved draft!</div>
            </div>
          </div>
        )}
        <div className={"cco__cnc-right-content-wrapper p0 "}>
          <Scrollbars
            style={{
              height: selectedOfferData?.draftId
                ? viewPointHeight - 140
                : viewPointHeight - 90,
            }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <PageHeader option={"sm p-16"} title={"Create new Offer"} />
              {editOfferData?.offerId && (
                <div className={"offer-id"}>
                  Offer ID : {editOfferData?.offerId}
                </div>
              )}
            </div>

            <div className={"cco_common-form-content-wrapper "}>
              {/*===== Search keywords =======*/}

              <div className={"group-form"}>
                <div
                  className={"d-flex justify-content-between align-self-center"}
                >
                  <GroupHeader option={"mt-1 mb-2"} title={"Merchant"} />
                  {!editOfferData && (
                    <div
                      className={`btn-refresh-btn sm`}
                      onClick={() => {
                        this.props.onClickOpenSearchMerchant();
                      }}
                    >
                      Create new offer
                    </div>
                  )}
                  {editOfferData && (
                    <div
                      className={`btn-refresh-btn sm`}
                      onClick={async () => {
                        await this.clearAll();
                        this.props.clearSelectedOfferDetails();
                        this.props.clearSearchMerchant();
                      }}
                    >
                      Cancel Edit
                    </div>
                  )}
                </div>
                {/* <Input
                  elementConfig={dataForm.merchant.elementConfig}
                  elementType={dataForm.merchant.elementType}
                  required={dataForm.merchant.validation.required}
                  isValid={!dataForm.merchant.valid}
                  touched={dataForm.merchant.touched}
                  value={dataForm.merchant.value}
                  defaultValue={dataForm.merchant.defaultValue}
                  isLoading={isCategoryLoading}
                  isShowRequired={true}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.merchant.label}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.merchant.key)
                  }
                /> */}

                {selectedBank &&
                  selectMerchant &&
                  (selectMerchant?.merchantId ||
                    selectMerchant?.merchantName) && (
                    <>
                      {/* <MerchantSelectCard
                        onClickEdit={() => {
                          this.props.editOfferMerchant(selectMerchant);
                          // this.props.onClickOpenSearchMerchant();
                        }}
                        isActive={true}
                        preview={true}
                        editable={true}
                        name={selectMerchant && selectMerchant.merchantName}
                        status={selectMerchant && selectMerchant.status}
                        createdAt={selectMerchant && selectMerchant.createdAt}
                        logo={selectMerchant && selectMerchant.logoURL}
                        webUrl={selectMerchant && selectMerchant?.webURL}
                      /> */}
                      <MerchantSelectCardSmall
                        onClickEdit={() => {
                          this.props.editOfferMerchant(selectMerchant);
                          // this.props.onClickOpenSearchMerchant();
                        }}
                        isActive={true}
                        preview={true}
                        editable={true}
                        name={selectMerchant && selectMerchant.merchantName}
                        status={selectedCategory?.status}
                        category={selectedCategory?.categoryName}
                        logo={selectMerchant && selectMerchant.logoURL}
                      />
                    </>
                  )}
                {selectedBank &&
                  (selectedBank?.bankId || selectedBank?.bankName) && (
                    <>
                      {/* <BankCard
                        onClickEdit={() => {
                          this.props.editOfferBank(selectedBank);
                          // this.props.onClickOpenSearchMerchant();
                        }}
                        isActive={true}
                        preview={false}
                        title={"Bank"}
                        type={"bank"}
                        isEditButton={true}
                        name={selectedBank && selectedBank.bankName}
                        style={"mt-1 mb-1"}
                        logo={selectedBank && selectedBank.logoURL}
                      /> */}
                      <BankCardSmall
                        onClickEdit={() => {
                          this.props.editOfferBank(selectedBank);
                          // this.props.onClickOpenSearchMerchant();
                        }}
                        isActive={true}
                        preview={false}
                        //title={"Bank"}
                        style={"mt-1 mb-1"}
                        type={"bank"}
                        isEditButton={true}
                        name={selectedBank && selectedBank.bankName}
                        logo={selectedBank && selectedBank.logoURL}
                      />
                    </>
                  )}
                {selectedCategory &&
                  (selectedCategory?.categoryId ||
                    selectedCategory?.categoryName) && (
                    <>
                      {/* <MerchantDetailCard
                      merchantName={selectedCategory?.categoryName}
                      isActive={false}
                      webURL={selectedCategory?.categoryId}
                      cardType={"category"}
                      iconName={selectedCategory?.iconName}
                      status={selectedCategory?.status}
                      createdAt={selectedCategory?.createdAt}
                      onClickEdit={() => {
                        this.props.editOfferCategory(selectedCategory);
                        // this.props.onClickOpenSearchMerchant();
                      }}
                  /> */}
                      <MerchantDetailCardSmall
                        merchantName={selectedCategory?.categoryName}
                        isActive={true}
                        preview={false}
                        cardType={"category"}
                        iconName={selectedCategory?.iconName}
                        isEdit={true}
                        onClickEdit={() => {
                          this.props.editOfferCategory(selectedCategory);
                          // this.props.onClickOpenSearchMerchant();
                        }}
                      />
                    </>
                  )}

                {/*<BankCard*/}
                {/*    onClickEdit={() => {*/}

                {/*    }}*/}
                {/*    isActive={true}*/}
                {/*    preview={false}*/}
                {/*    iconName={selectedCategory && selectedCategory.iconName}*/}
                {/*    type={'category'}*/}
                {/*    title={"Offer category"}*/}
                {/*    name={selectedCategory && selectedCategory.categoryName}*/}
                {/*    style={"mt-1"}*/}
                {/*    logo={""}*/}
                {/*/>*/}
              </div>

              {/* <div className={"group-form"}>
                <GroupHeader option={"mt-1 mb-2"} title={"Search keywords"} />
               <Input
                  elementConfig={dataForm.category.elementConfig}
                  elementType={dataForm.category.elementType}
                  required={dataForm.category.validation.required}
                  isValid={!dataForm.category.valid}
                  touched={dataForm.category.touched}
                  value={dataForm.category.value}
                  defaultValue={dataForm.category.defaultValue}
                  isShowRequired={true}
                  size={"sm"}
                  isLoading={isCategoryLoading}
                  margin={"m-b-2"}
                  options={categoryOptions}
                  label={dataForm.category.label}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onClickAddTag={this.onClickAddHashTag.bind(this)}
                  changed={(event) => {
                    this.inputHandleChange(event, dataForm.category.key);
                  }}
                />

                <Input
                  elementConfig={dataForm.bank.elementConfig}
                  elementType={dataForm.bank.elementType}
                  required={dataForm.bank.validation.required}
                  isValid={!dataForm.bank.valid}
                  touched={dataForm.bank.touched}
                  value={dataForm.bank.value}
                  defaultValue={dataForm.bank.defaultValue}
                  isLoading={isBankLoading}
                  isShowRequired={true}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.bank.label}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.bank.key)
                  }
                />
              </div> */}

              {selectedMerchant !== null &&
                selectedBank !== null &&
                selectedCategory !== null && (
                  <>
                    <>
                      <div className={"group-form"}>
                        <div
                          className={
                            "d-flex justify-content-between align-self-center"
                          }
                        >
                          <GroupHeader
                            option={"mt-1 mb-2"}
                            title={"Offer valid card types"}
                          />
                          <div className="d-flex flex-row align-items-center">
                            <div
                              className={`btn-refresh-btn sm mr-1`}
                              onClick={() => {
                                this.selectAllCardTypes();
                              }}
                            >
                              Select all
                            </div>
                            <Button
                              text={"Edit"}
                              type={"btn-edit"}
                              size={"md secondary ml-1 mt-1"}
                              onClick={async () => {
                                let updateForm = this.state.dataForm;
                                const resBank = await cooGetBankById(
                                  editOfferData?.bankId || selectedBank.bankId
                                );
                                console.log({ resBank });

                                let bankData = resBank?.data?.data;
                                let newCardList = (
                                  bankData?.cardTypes || []
                                ).map((cardType) => ({
                                  id: cardType.id,
                                  value: cardType.value || cardType.cardName,
                                  count: cardType.count,
                                  isActive:
                                    cardTypes.find((x) => x.id === cardType.id)
                                      ?.isActive || cardType.isActive,
                                }));

                                const uniqueCardsIds = new Set();
                                console.log({ newCardList });

                                newCardList.forEach((card) =>
                                  uniqueCardsIds.add(card.id)
                                );

                                if (editOfferData) {
                                  const foundCards = cardTypes?.map((card) => {
                                    const foundCard =
                                      editOfferData?.cardTypes?.find(
                                        (curr) => curr.id === card.id
                                      );
                                    return foundCard || card;
                                  });

                                  const nonDuplicateCards = foundCards?.filter(
                                    (card) => {
                                      if (!uniqueCardsIds.has(card.id)) {
                                        uniqueCardsIds.add(card.id);
                                        return true;
                                      }
                                      return false;
                                    }
                                  );

                                  const transformedCards =
                                    nonDuplicateCards.map((card) => ({
                                      id: card.id,
                                      value: card.value || card.cardName,
                                      count: card.count,
                                      isActive:
                                        cardTypes.find((x) => x.id === card.id)
                                          ?.isActive || card.isActive,
                                    }));

                                  newCardList.push(...transformedCards);
                                }

                                this.props.openEditBankModal({
                                  ...bankData,
                                  cardTypes: newCardList,
                                });

                                this.props.onClickSelectedMerchant(
                                  selectMerchant
                                );
                              }}
                            />
                          </div>
                        </div>
                        {cardTypes && cardTypes.length > 0 && (
                          <div className={"multiple-line-wrapper mb-2"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {(cardTypes || []).map((item, index) => (
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={item.isActive}
                                  select={true}
                                  onClick={() => this.onSelectCard(item, index)}
                                  onRemove={() =>
                                    this.onSelectCard(item, index)
                                  }
                                  format={"text"}
                                  text={item.value || item.cardName}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </>

                    {/*valid location*/}

                    <>
                      <div className={"group-form"}>
                        <div className="d-flex flex-row justify-content-between align-items-start">
                          <GroupHeader
                            option={"mt-1 mb-2"}
                            title={"Select Offer valid location"}
                          />
                          <Button
                            text={"Edit"}
                            type={"btn-edit"}
                            size={"md secondary ml-1 mt-1"}
                            onClick={async () => {
                              let updateForm = this.state.dataForm;
                              const resMerchant = await cooFindMerchantById(
                                editOfferData?.merchantId ||
                                  selectMerchant.merchantId
                              );

                              let merchantData = resMerchant?.data?.data;
                              let newLocationList = (
                                merchantData?.location || []
                              ).map((location) => ({
                                id: location.id,
                                value: location.value || location.tagName,
                                count: location.count,
                                isActive:
                                  validLocation.find(
                                    (x) => x.id === location.id
                                  )?.isActive || location.isActive,
                              }));

                              console.log({ newLocationList });

                              const uniqueLocationsIds = new Set();

                              newLocationList.forEach((location) =>
                                uniqueLocationsIds.add(location.id)
                              );

                              if (editOfferData) {
                                const foundLocation = validLocation.map(
                                  (location) => {
                                    const foundLoc =
                                      editOfferData?.location?.find(
                                        (curr) => curr.id === location.id
                                      );
                                    return foundLoc || location;
                                  }
                                );

                                const nonDuplicateLocations =
                                  foundLocation.filter((location) => {
                                    if (!uniqueLocationsIds.has(location.id)) {
                                      uniqueLocationsIds.add(location.id);
                                      return true;
                                    }
                                    return false;
                                  });

                                const transformedLocations =
                                  nonDuplicateLocations.map((location) => ({
                                    id: location.id,
                                    value: location.value || location.tagName,
                                    count: location.count,
                                    isActive:
                                      validLocation.find(
                                        (x) => x.id === location.id
                                      )?.isActive || location.isActive,
                                  }));

                                newLocationList.push(...transformedLocations);
                              }

                              this.props.openEditMerchantModal({
                                ...merchantData,
                                location: newLocationList,
                              });
                            }}
                          />
                        </div>
                        {validLocation && validLocation.length > 0 && (
                          <div className={"multiple-line-wrapper mb-2"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {(validLocation || []).map((item, index) => (
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={item.isActive}
                                  select={true}
                                  onClick={() =>
                                    this.onSelectLocation(item, index)
                                  }
                                  onRemove={() =>
                                    this.onSelectLocation(item, index)
                                  }
                                  format={"text"}
                                  text={item.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </>

                    {/*reservation contacts*/}

                    <div className={"group-form"}>
                      <div className="d-flex flex-row justify-content-between align-items-start">
                        <GroupHeader
                          option={"mt-1 mb-2"}
                          title={"Merchant's reservation contacts"}
                        />
                        <Button
                          text={"Edit"}
                          type={"btn-edit"}
                          size={"md secondary ml-1 mt-1"}
                          onClick={async () => {
                            let updateForm = this.state.dataForm;
                            const resMerchant = await cooFindMerchantById(
                              editOfferData?.merchantId ||
                                selectMerchant.merchantId
                            );
                            let merchantData = resMerchant?.data?.data;

                            this.props.openEditMerchantContactsModal({
                              ...merchantData,
                              hotLine: {
                                mobileNo: merchantData?.hotLine?.mobileNo || [],
                                email: merchantData?.hotLine?.email || [],
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <label className={"label-validation-on"}>
                          Mobile No
                        </label>
                        {this.state.mobileNoList?.length > 0 ? (
                          <div className={"multiple-line-wrapper"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {this.state.mobileNoList.map((mobile, index) => (
                                // <div key={index}>{email.contact}</div>
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={mobile?.isActive}
                                  select={true}
                                  onClick={() => {
                                    const updatedMobileList =
                                      this.state.mobileNoList.map((item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            isActive: !item.isActive,
                                          };
                                        }
                                        return item;
                                      });
                                    this.setState({
                                      mobileNoList: updatedMobileList,
                                    });
                                  }}
                                  // onRemove={() => {
                                  //   if (mobile.contact) {
                                  //     this.setState({
                                  //       emailList:
                                  //         this.state.mobileNoList.filter(
                                  //           (item, i) => i !== index
                                  //         ),
                                  //     });
                                  //   }
                                  // }}
                                  format={"text"}
                                  text={mobile.contact}
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="d-flex flex-column mt-2">
                        <label className={"label-validation-on"}>email</label>
                        {this.state.emailList?.length > 0 ? (
                          <div className={"multiple-line-wrapper"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {this.state.emailList.map((email, index) => (
                                // <div key={index}>{email.contact}</div>
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={email?.isActive}
                                  select={true}
                                  onClick={() => {
                                    const updatedEmailList =
                                      this.state.emailList.map((item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            isActive: !item.isActive,
                                          };
                                        }
                                        return item;
                                      });
                                    this.setState({
                                      emailList: updatedEmailList,
                                    });
                                  }}
                                  onRemove={() => {
                                    // if (email.contact) {
                                    //   this.setState({
                                    //     emailList: this.state.emailList.filter(
                                    //       (item, i) => i !== index
                                    //     ),
                                    //   });
                                    // }
                                  }}
                                  format={"text"}
                                  text={email.contact}
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    {/* reference URL */}
                    <div className={"group-form"}>
                      <div className="d-flex flex-row justify-content-between">
                        <GroupHeader
                          option={"mt-1 mb-2"}
                          title={"Offer reference URL"}
                        />
                        {dataForm.referenceURL.value && (
                          <Button
                            text={<MdOpenInNew />}
                            type={"btn-edit"}
                            size={"sm secondary mr-2"}
                            onClick={() => {
                              dataForm.referenceURL &&
                                window.open(
                                  dataForm.referenceURL.value,
                                  "_blank"
                                );
                            }}
                          />
                        )}
                      </div>
                      <Input
                        elementConfig={dataForm.referenceURL.elementConfig}
                        elementType={dataForm.referenceURL.elementType}
                        required={dataForm.referenceURL.validation.required}
                        isValid={!dataForm.referenceURL.valid}
                        touched={dataForm.referenceURL.touched}
                        value={dataForm.referenceURL.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.referenceURL.label}
                        changed={(event) =>
                          this.inputHandleChange(
                            event,
                            dataForm.referenceURL.key
                          )
                        }
                      />
                    </div>

                    {/*==== Basic profile ======*/}
                    <div className={"group-form"}>
                      <UploadCard
                        title={"Basic profile"}
                        onSelectFile={this.onSelectFile.bind(this)}
                        isImageUploading={isImageUploading}
                        logoURL={dataForm.logoURL.value}
                      />
                      <div className={"d-flex mb-1"}>
                        <Button
                          text={"find image (google)"}
                          type={"btn-edit"}
                          size={"sm secondary mr-2"}
                          onClick={() => {
                            window.open(
                              `http://www.google.com/search?q=${
                                selectMerchant && selectMerchant.merchantName
                              }`,
                              "_blank"
                            );
                          }}
                        />
                      </div>
                      {/*{!dataForm.logoURL.value &&}*/}
                      {dataForm.logoURL.value && (
                        <div className={"cco__offer-image-banner-wrapper mb-4"}>
                          <img src={dataForm.logoURL.value} />

                          <div className={"cco__image-delete-wrapper"}>
                            <ButtonIcon
                              onClick={() => this.onClickClearImage()}
                              buttonIcon={GetIcon("close")}
                              type={"close"}
                              size={"md"}
                            />
                          </div>
                        </div>
                      )}
                      <Input
                        elementConfig={dataForm.logoURL.elementConfig}
                        elementType={dataForm.logoURL.elementType}
                        required={dataForm.logoURL.validation.required}
                        isValid={!dataForm.logoURL.valid}
                        touched={dataForm.logoURL.touched}
                        value={dataForm.logoURL.value}
                        isShowRequired={false}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.logoURL.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.logoURL.key)
                        }
                      />
                      <Input
                        elementConfig={dataForm.offerTitle.elementConfig}
                        elementType={dataForm.offerTitle.elementType}
                        required={dataForm.offerTitle.validation.required}
                        isValid={!dataForm.offerTitle.valid}
                        touched={dataForm.offerTitle.touched}
                        value={dataForm.offerTitle.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-1"}
                        label={dataForm.offerTitle.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.offerTitle.key)
                        }
                      />
                      {tags && (
                        <>
                          <div className={"multiple-line-wrapper mt-1"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {(tags || []).map((item, index) => (
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={
                                    dataForm.offerTitle.value === item.value
                                  }
                                  select={true}
                                  onClick={() => this.onSelect(item, index)}
                                  onRemove={() => this.onRemove(item, index)}
                                  format={"text"}
                                  text={item.value}
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      <Input
                        elementConfig={dataForm.offerHeader.elementConfig}
                        elementType={dataForm.offerHeader.elementType}
                        required={dataForm.offerHeader.validation.required}
                        isValid={!dataForm.offerHeader.valid}
                        touched={dataForm.offerHeader.touched}
                        value={dataForm.offerHeader.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2 mt-2"}
                        label={dataForm.offerHeader.label}
                        changed={(event) =>
                          this.inputHandleChange(
                            event,
                            dataForm.offerHeader.key
                          )
                        }
                      />
                      <Input
                        elementConfig={dataForm.promoCode.elementConfig}
                        elementType={dataForm.promoCode.elementType}
                        required={dataForm.promoCode.validation.required}
                        isValid={!dataForm.promoCode.valid}
                        touched={dataForm.promoCode.touched}
                        value={dataForm.promoCode.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.promoCode.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.promoCode.key)
                        }
                      />
                      <label>Description</label>
                      <div className={"rich-text-editor-wrapper"}>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            fontFamily: {
                              options: ["gothambook"],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                            fontSize: {
                              icon: undefined,
                              options: [11, 12, 13, 14, 18, 24],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                          }}
                        />
                        {/* <RichTextComponent
                          editOffer={editOfferData && editOfferData}
                        /> */}
                      </div>
                      {/* <Input
                            elementConfig={dataForm.offerDescription.elementConfig}
                            elementType={dataForm.offerDescription.elementType}
                            required={dataForm.offerDescription.validation.required}
                            isValid={!dataForm.offerDescription.valid}
                            touched={dataForm.offerDescription.touched}
                            value={dataForm.offerDescription.value}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.offerDescription.label}
                            changed={(event) =>
                              this.inputHandleChange(event, dataForm.offerDescription.key)
                            }
                          /> */}
                    </div>
                    {/*==== Offer details ======*/}
                    <div className={"group-form"}>
                      <GroupHeader
                        option={"mt-1 mb-2"}
                        title={"Offer details"}
                      />
                      <label>
                        Offer rate <b> *</b>
                      </label>
                      {dataForm.offerType.elementConfig.options && (
                        <>
                          <div className={"multiple-line-wrapper mt-1 mb-2"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {(
                                dataForm.offerType.elementConfig.options || []
                              ).map((item, index) => (
                                <SelectBox
                                  key={index}
                                  index={index}
                                  remove={false}
                                  isSelect={
                                    dataForm.offerType.value === item.value
                                  }
                                  select={true}
                                  onClick={() =>
                                    this.onSelectOffer(item, index)
                                  }
                                  onRemove={() => this.onRemove(item, index)}
                                  format={"text"}
                                  text={item.value}
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {dataForm.offerType.value && (
                        <Input
                          elementConfig={dataForm.offerRate.elementType}
                          elementType={dataForm.offerRate.elementType}
                          required={dataForm.offerRate.validation.required}
                          isValid={!dataForm.offerRate.valid}
                          touched={dataForm.offerRate.touched}
                          value={dataForm.offerRate.value}
                          isShowRequired={false}
                          size={"md"}
                          margin={"m-b-2"}
                          label={
                            dataForm.offerType.value === "customize"
                              ? "Offer description"
                              : dataForm.offerRate.label +
                                "- " +
                                dataForm.offerType.value
                          }
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.offerRate.key
                            )
                          }
                        />
                      )}

                      <label>
                        Offer validity <b> *</b>
                      </label>
                      {dataForm.offerValidity.elementConfig.options && (
                        <>
                          <div className={"multiple-line-wrapper  mb-2"}>
                            <div
                              className={"tag-inline-wrapper d-flex flex-wrap"}
                            >
                              {(
                                dataForm.offerValidity.elementConfig.options ||
                                []
                              ).map((item, index) => (
                                <SelectBox
                                  index={index}
                                  remove={false}
                                  isSelect={
                                    dataForm.offerValidity.value === item.key
                                  }
                                  select={true}
                                  onClick={() =>
                                    this.onSelectOption(item, "offerValidity")
                                  }
                                  onRemove={() => this.onRemove(item, index)}
                                  format={"text"}
                                  text={item.value}
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {dataForm.offerValidity.value === "until" && (
                        <div className={"mt-2"}>
                          <Input
                            elementConfig={dataForm.endDate.elementConfig}
                            elementType={dataForm.endDate.elementType}
                            required={dataForm.endDate.validation.required}
                            isValid={!dataForm.endDate.valid}
                            touched={dataForm.endDate.touched}
                            value={dataForm.endDate.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.endDate.label}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.endDate.key
                              )
                            }
                          />

                          {selectMerchant &&
                            dataForm.endDate.dateValue === "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.offerEnd ||
                                    editOfferData?.offerEnd
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.endDate.dateValue != "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.endDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                        </div>
                      )}

                      {dataForm.offerValidity.value === "till" && (
                        <div className={"mt-2"}>
                          <Input
                            elementConfig={dataForm.endDate.elementConfig}
                            elementType={dataForm.endDate.elementType}
                            required={dataForm.endDate.validation.required}
                            isValid={!dataForm.endDate.valid}
                            touched={dataForm.endDate.touched}
                            value={dataForm.endDate.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.endDate.label}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.endDate.key
                              )
                            }
                          />

                          {selectMerchant &&
                            dataForm.endDate.dateValue === "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.offerEnd ||
                                    editOfferData?.offerEnd
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.endDate.dateValue != "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.endDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                        </div>
                      )}

                      {dataForm.offerValidity.value === "on" && (
                        <div className={"mt-2"}>
                          <Input
                            elementConfig={dataForm.endDate.elementConfig}
                            elementType={dataForm.endDate.elementType}
                            required={dataForm.endDate.validation.required}
                            isValid={!dataForm.endDate.valid}
                            touched={dataForm.endDate.touched}
                            value={dataForm.endDate.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={"Valid date"}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.endDate.key
                              )
                            }
                          />
                        </div>
                      )}

                      {dataForm.offerValidity.value === "fromTo" && (
                        <div className={"mt-2"}>
                          <Input
                            elementConfig={dataForm.startDate.elementConfig}
                            elementType={dataForm.startDate.elementType}
                            required={dataForm.startDate.validation.required}
                            isValid={!dataForm.startDate.valid}
                            touched={dataForm.startDate.touched}
                            value={dataForm.startDate.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"mb-1"}
                            label={dataForm.startDate.label}
                            onChange={(event, date) => {
                              this.inputHandleChange(
                                event,
                                dataForm.startDate.key
                              );
                            }}
                          />
                          {selectMerchant &&
                            dataForm.startDate.dateValue === "" &&
                            (selectedOfferData?.offerStart ||
                              editOfferData?.offerStart) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.offerStart ||
                                    editOfferData?.offerStart
                                    // moment(
                                    //   selectedOfferData?.offerStart
                                    // ).format("DD-MM-YYYY") ||
                                    // moment(editOfferData?.offerStart).format(
                                    //   "DD-MM-YYYY"
                                    // )
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.startDate.dateValue != "" &&
                            (selectedOfferData?.offerStart ||
                              editOfferData?.offerStart) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.startDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                          <Input
                            elementConfig={dataForm.endDate.elementConfig}
                            elementType={dataForm.endDate.elementType}
                            required={dataForm.endDate.validation.required}
                            isValid={!dataForm.endDate.valid}
                            touched={dataForm.endDate.touched}
                            value={dataForm.endDate.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.endDate.label}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.endDate.key
                              )
                            }
                          />
                          {editOfferData &&
                            console.log("endate::", editOfferData?.offerEnd)}
                          {selectMerchant &&
                            dataForm.endDate.dateValue === "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.offerEnd ||
                                    editOfferData?.offerEnd
                                    // moment(selectedOfferData?.offerEnd).format(
                                    //   "DD-MM-YYYY"
                                    // ) ||
                                    // moment(editOfferData?.offerEnd).format(
                                    //   "DD-MM-YYYY"
                                    // )
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.endDate.dateValue != "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.endDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                        </div>
                      )}

                      {dataForm.offerValidity.value === "customize" && (
                        <div className={"customize-date-wrapper " + ".mt-2"}>
                          <Input
                            elementConfig={dataForm.validDate.elementConfig}
                            elementType={dataForm.validDate.elementType}
                            required={dataForm.validDate.validation.required}
                            isValid={!dataForm.validDate.valid}
                            touched={dataForm.validDate.touched}
                            value={dataForm.validDate.value}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.validDate.label}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.validDate.key
                              )
                            }
                          />
                          {selectMerchant &&
                            dataForm.validDate.dateValue === "" &&
                            (selectedOfferData?.validDate ||
                              editOfferData?.validDate) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.validDate ||
                                    editOfferData?.validDate
                                    // moment(selectedOfferData?.validDate).format(
                                    //   "YYYY-MM-DD"
                                    // ) ||
                                    // moment(editOfferData?.validDate).format(
                                    //   "YYYY-MM-DD"
                                    // )
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.validDate.dateValue != "" &&
                            (selectedOfferData?.validDate ||
                              editOfferData?.validDate) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.validDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                          <div className={"d-flex"}>
                            <div className={"col-6 pl-0 pr-0"}>
                              <Input
                                elementConfig={dataForm.startTime.elementConfig}
                                elementType={dataForm.startTime.elementType}
                                required={
                                  dataForm.startTime.validation.required
                                }
                                isValid={!dataForm.startTime.valid}
                                touched={dataForm.startTime.touched}
                                value={dataForm.startTime.value}
                                isShowRequired={true}
                                size={"md"}
                                margin={"m-b-2"}
                                label={dataForm.startTime.label}
                                onChange={(event, date) =>
                                  this.inputHandleChange(
                                    event,
                                    dataForm.startTime.key
                                  )
                                }
                              />
                              {selectMerchant &&
                                dataForm.startTime.timeValue === "" &&
                                (selectedOfferData?.startTime ||
                                  editOfferData?.startTime) && (
                                  <div className="mt-2">
                                    <Tag
                                      type={"pending"}
                                      tagName={
                                        moment(
                                          selectedOfferData?.startTime
                                        ).format("YYYY-MM-DD") ||
                                        moment(editOfferData.startTime).format(
                                          "YYYY-MM-DD"
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              {selectMerchant &&
                                dataForm.startTime.timeValue != "" &&
                                (selectedOfferData?.startTime ||
                                  editOfferData?.startTime) && (
                                  <div className="mt-2">
                                    <Tag
                                      type={"pending"}
                                      tagName={moment(
                                        dataForm.startTime.timeValue
                                      ).format("DD-MM-YYYY")}
                                    />
                                  </div>
                                )}
                            </div>
                            <div className={"col-6 pr-0"}>
                              <Input
                                elementConfig={dataForm.endTime.elementConfig}
                                elementType={dataForm.endTime.elementType}
                                required={dataForm.endTime.validation.required}
                                isValid={!dataForm.endTime.valid}
                                touched={dataForm.endTime.touched}
                                value={dataForm.endTime.value}
                                isShowRequired={true}
                                size={"md"}
                                margin={"m-b-2"}
                                label={dataForm.endTime.label}
                                onChange={(event, date) =>
                                  this.inputHandleChange(
                                    event,
                                    dataForm.endTime.key
                                  )
                                }
                              />
                              {selectMerchant &&
                                dataForm.endTime.timeValue === "" &&
                                (selectedOfferData?.endTime ||
                                  editOfferData?.endTime) && (
                                  <div className="mt-2">
                                    <Tag
                                      type={"pending"}
                                      tagName={
                                        moment(
                                          selectedOfferData?.endTime
                                        ).format("YYYY-MM-DD") ||
                                        moment(editOfferData?.endTime).format(
                                          "YYYY-MM-DD"
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              {selectMerchant &&
                                dataForm.endTime.timeValue != "" &&
                                (selectedOfferData?.endTime ||
                                  editOfferData?.endTime) && (
                                  <div className="mt-2">
                                    <Tag
                                      type={"pending"}
                                      tagName={moment(
                                        dataForm.endTime.timeValue
                                      ).format("DD-MM-YYYY")}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* <div className={"rich-text-editor-wrapper"}>
                              <RichTextComponent />
                             </div> */}
                          <Input
                            elementConfig={
                              dataForm.validTimeDateDescription.elementConfig
                            }
                            elementType={
                              dataForm.validTimeDateDescription.elementType
                            }
                            required={
                              dataForm.validTimeDateDescription.validation
                                .required
                            }
                            isValid={!dataForm.validTimeDateDescription.valid}
                            touched={dataForm.validTimeDateDescription.touched}
                            value={dataForm.validTimeDateDescription.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.validTimeDateDescription.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.validTimeDateDescription.key
                              )
                            }
                          />

                          {/*<Button*/}
                          {/*    leftIcon={false}*/}
                          {/*    rightIcon={false}*/}
                          {/*    isLoading={isLoading || isImageUploading}*/}
                          {/*    isActiveAnimation={false}*/}
                          {/*    reactRightIcon={GetIcon('leftArrow')}*/}
                          {/*    onClick={async () => {*/}
                          {/*        await this.onClickAddToList();*/}
                          {/*    }}*/}
                          {/*    text={'Add to list'}*/}
                          {/*    size={'md'}*/}
                          {/*    type={'btn-block-back'}/>*/}
                        </div>
                      )}
                      {dataForm.offerValidity.value === "other" && (
                        <div className={"customize-date-wrapper " + ".mt-2"}>
                          <Input
                            elementConfig={dataForm.endDate.elementConfig}
                            elementType={dataForm.endDate.elementType}
                            required={dataForm.endDate.validation.required}
                            isValid={!dataForm.endDate.valid}
                            touched={dataForm.endDate.touched}
                            value={dataForm.endDate.value}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.endDate.label}
                            onChange={(event, date) =>
                              this.inputHandleChange(
                                event,
                                dataForm.endDate.key
                              )
                            }
                          />
                          {selectMerchant &&
                            dataForm.endDate.dateValue === "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={
                                    selectedOfferData?.offerEnd ||
                                    editOfferData?.offerEnd
                                    // moment(selectedOfferData?.offerEnd).format(
                                    //   "DD-MM-YYYY"
                                    // ) ||
                                    // moment(editOfferData?.offerEnd).format(
                                    //   "DD-MM-YYYY"
                                    // )
                                  }
                                />
                              </div>
                            )}
                          {selectMerchant &&
                            dataForm.endDate.dateValue != "" &&
                            (selectedOfferData?.offerEnd ||
                              editOfferData?.offerEnd) && (
                              <div className="mt-2">
                                <Tag
                                  type={"pending"}
                                  tagName={moment(
                                    dataForm.endDate.dateValue
                                  ).format("DD-MM-YYYY")}
                                />
                              </div>
                            )}
                          <Input
                            elementConfig={
                              dataForm.validTimeDateDescription.elementConfig
                            }
                            elementType={
                              dataForm.validTimeDateDescription.elementType
                            }
                            required={
                              dataForm.validTimeDateDescription.validation
                                .required
                            }
                            isValid={!dataForm.validTimeDateDescription.valid}
                            touched={dataForm.validTimeDateDescription.touched}
                            value={dataForm.validTimeDateDescription.value}
                            isShowRequired={false}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.validTimeDateDescription.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.validTimeDateDescription.key
                              )
                            }
                          />

                          {/*<Button*/}
                          {/*    leftIcon={false}*/}
                          {/*    rightIcon={false}*/}
                          {/*    isLoading={isLoading || isImageUploading}*/}
                          {/*    isActiveAnimation={false}*/}
                          {/*    reactRightIcon={GetIcon('leftArrow')}*/}
                          {/*    onClick={async () => {*/}
                          {/*        await this.onClickAddToList();*/}
                          {/*    }}*/}
                          {/*    text={'Add to list'}*/}
                          {/*    size={'md'}*/}
                          {/*    type={'btn-block-back'}/>*/}
                        </div>
                      )}
                    </div>

                    {/*===== terms and conditions =======*/}
                    <div className={"group-form"}>
                      <div
                        className={
                          "d-flex justify-content-between align-self-center"
                        }
                      >
                        <GroupHeader
                          option={"mt-1 mb-2"}
                          title={"Terms and conditions"}
                        />
                        <div
                          className={
                            "d-flex flex-row justify-content-between align-self-center"
                          }
                        >
                          <div
                            className={`btn-refresh-btn sm mr-2`}
                            onClick={async () => {
                              let terms;
                              // Check if `editOfferData` and `editOfferData.offerId` exist
                              if (editOfferData?.offerId) {
                                let updateForm = this.state.dataForm;
                                let merchantData = updateForm[
                                  "merchant"
                                ].elementConfig.options.find(
                                  (x) => x.id === editOfferData?.merchantId
                                );
                                console.log({ merchantData });

                                // If `merchantData?.termsAndCondition` is empty, use an empty object
                                terms = merchantData?.termsAndCondition
                                  ? { ...merchantData.termsAndCondition }
                                  : {};
                                console.log({ terms });

                                const bankId = editOfferData.bankId;

                                if (!terms[bankId]) {
                                  terms[bankId] = [];
                                }

                                // Use a Set to track unique term IDs and avoid duplicates
                                const uniqueTermIds = new Set();

                                // Add existing terms from `terms[bankId]` to the Set
                                terms[bankId].forEach((term) =>
                                  uniqueTermIds.add(term.id)
                                );

                                // Map through `termsAndConditions` and find or add the corresponding term
                                const foundTerms = termsAndConditions.map(
                                  (term) => {
                                    const foundTerm =
                                      editOfferData.termsConditions[
                                        bankId
                                      ]?.find((curr) => curr.id === term.id);
                                    return foundTerm || term;
                                  }
                                );

                                // Filter out any duplicates based on term IDs
                                const nonDuplicateTerms = foundTerms.filter(
                                  (term) => {
                                    if (!uniqueTermIds.has(term.id)) {
                                      uniqueTermIds.add(term.id);
                                      return true;
                                    }
                                    return false;
                                  }
                                );

                                console.log("select terms>>>", terms[bankId]);
                                console.log({ nonDuplicateTerms });

                                // Instead of pushing into a spread array, directly modify `terms[bankId]`
                                terms[bankId] = [
                                  ...terms[bankId],
                                  ...nonDuplicateTerms,
                                ];

                                console.log("Updated terms>>>", terms[bankId]);
                              } else if (
                                selectMerchant?.termsAndCondition[
                                  selectedBank?.bankId
                                ] &&
                                selectMerchant?.termsAndCondition[
                                  selectedBank?.bankId
                                ].length !== termsAndConditions.length
                              ) {
                                let updateForm = this.state.dataForm;
                                let merchantData = updateForm[
                                  "merchant"
                                ].elementConfig.options.find(
                                  (x) => x.id === selectMerchant?.merchantId
                                );
                                console.log({ merchantData });

                                // Retrieve or initialize the terms object for the selected bank
                                terms = merchantData?.termsAndCondition || {};
                                const bankId = selectedBank.bankId;

                                if (!terms[bankId]) {
                                  terms[bankId] = [];
                                }

                                // Set to keep track of unique term IDs
                                const uniqueTermIds = new Set(
                                  terms[bankId].map((term) => term.id)
                                );
                                // Filter termsAndConditions, adding only new terms
                                const nonDuplicateTerms =
                                  termsAndConditions.filter(
                                    (term) => !uniqueTermIds.has(term.id)
                                  );

                                // Add new terms (non-duplicates) to the existing bank's terms
                                terms[bankId].push(...nonDuplicateTerms);
                              } else if (
                                !selectMerchant?.termsAndCondition[
                                  selectedBank?.bankId
                                ] ||
                                selectMerchant?.termsAndCondition[
                                  selectedBank?.bankId
                                ].length !==
                                  selectedOfferData?.termsConditions[
                                    selectedBank?.bankId
                                  ].length
                              ) {
                                let updateForm = this.state.dataForm;
                                let merchantData = updateForm[
                                  "merchant"
                                ].elementConfig.options.find(
                                  (x) => x.id === selectMerchant?.merchantId
                                );
                                console.log({ merchantData });

                                // Retrieve or initialize the terms object for the selected bank
                                terms = merchantData?.termsAndCondition || {};
                                const bankId = selectedBank.bankId;

                                if (!terms[bankId]) {
                                  terms[bankId] = [];
                                }

                                // Set to keep track of unique term IDs
                                const uniqueTermIds = new Set(
                                  terms[bankId].map((term) => term.id)
                                );

                                // Filter termsAndConditions, adding only new terms
                                const nonDuplicateTerms =
                                  selectedOfferData?.termsConditions[
                                    bankId
                                  ].filter(
                                    (term) => !uniqueTermIds.has(term.id)
                                  );

                                // Add new terms (non-duplicates) to the existing bank's terms
                                terms[bankId].push(...nonDuplicateTerms);
                              } else {
                                terms = selectMerchant?.termsAndCondition || {};
                                console.log({ selectMerchant });
                              }

                              // Prepare the data for the `onClickAddTermsIntoList` function
                              const termsToAdd = {
                                bankId:
                                  selectedBank?.bankId || editOfferData?.bankId,
                                terms: terms,
                              };
                              console.log({ termsToAdd });
                              // Trigger the functions with the prepared data
                              this.props.onClickAddTermsIntoList(termsToAdd);
                              this.props.onClickOpenTermsAndConditions();
                            }}
                          >
                            Manage Terms
                          </div>
                          <div
                            className={`btn-refresh-btn sm`}
                            onClick={() => {
                              this.selectAllTermsAndConditions();
                            }}
                          >
                            Select all
                          </div>
                        </div>
                      </div>
                      {/* <Input
                        elementConfig={
                          dataForm.termsAndConditions.elementConfig
                        }
                        elementType={dataForm.termsAndConditions.elementType}
                        required={
                          dataForm.termsAndConditions.validation.required
                        }
                        isValid={!dataForm.termsAndConditions.valid}
                        touched={dataForm.termsAndConditions.touched}
                        value={dataForm.termsAndConditions.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.termsAndConditions.label}
                        onKeyPress={this.handleKeyPress.bind(
                          this,
                          dataForm.termsAndConditions.key
                        )}
                        onClickAddTag={this.onClickAddTermsAndConditions.bind(
                          this
                        )}
                        changed={(event) => {
                          this.inputHandleChange(
                            event,
                            dataForm.termsAndConditions.key
                          );
                        }}
                      /> */}
                      <div className={"cco__mtc-tab mt-2"}>
                        <Tabs defaultActiveKey="1">
                          <TabPane
                            tab={selectedBank?.bankName}
                            key={selectedBank?.bankId}
                          >
                            {termsAndConditions && (
                              <>
                                <div className={"multiple-line-wrapper mt-1"}>
                                  <div
                                    className={
                                      "tag-inline-wrapper d-flex flex-wrap"
                                    }
                                  >
                                    {(termsAndConditions || []).map(
                                      (item, index) => (
                                        <SelectBox
                                          index={index}
                                          remove={false}
                                          isSelect={item.isActive}
                                          select={true}
                                          onClick={() =>
                                            this.onClickTermsAndConditions(
                                              index
                                            )
                                          }
                                          format={"text"}
                                          text={item.value}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </TabPane>
                        </Tabs>
                      </div>
                    </div>

                    {/* <div
                      className={
                        "login-footer-action-wrapper pl-4 pr-4 d-flex mt-4"
                      }
                    >
                      <div className={"mr-2"}>
                        <Button
                          leftIcon={false}
                          rightIcon={false}
                          disabled={false}
                          isLoading={isLoading || isImageUploading}
                          isActiveAnimation={false}
                          reactRightIcon={GetIcon("close")}
                          onClick={async () => {
                            await this.clearAll();
                            await this.setAllData();
                          }}
                          text={"Cancel"}
                          size={"md"}
                          type={"btn-block-back-secondary"}
                        />
                      </div>

                      <Button
                        leftIcon={false}
                        rightIcon={false}
                        disabled={
                          !selectedMerchant &&
                          !selectedBank &&
                          !selectedCategory
                        }
                        isLoading={isLoading || isImageUploading}
                        isActiveAnimation={false}
                        reactRightIcon={GetIcon("leftArrow")}
                        onClick={async () => {
                          if (editOfferData && editOfferData?.offerId) {
                            await this.onClickUpdateData();
                          } else {
                            await this.onClickSaveData();
                          }
                        }}
                        text={
                          editOfferData && editOfferData?.offerId
                            ? "Update"
                            : "Review"
                        }
                        size={"md"}
                        type={"btn-block-back"}
                      />
                    </div> */}
                  </>
                )}
            </div>
          </Scrollbars>

          <div
            className={
              "login-footer-action-wrapper pl-4 pr-4 mt-2 d-flex flex-column border-top pt-3"
            }
          >
            {selectedOfferData && selectedOfferData?.draftId && (
              <div style={{ width: "100%" }}>
                <Button
                  leftIcon={false}
                  rightIcon={false}
                  disabled={false}
                  isLoading={isLoadingDrafts}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("close")}
                  onClick={async () => {
                    await this.onClickSaveDraftData();
                  }}
                  text={"Save Draft"}
                  size={"md mb-2"}
                  type={"btn-block-back"}
                />
              </div>
            )}
            <div
              className="d-flex flex-row justify-content-between"
              style={{ width: "100%" }}
            >
              <div className={"mr-2"}>
                <Button
                  leftIcon={false}
                  rightIcon={false}
                  disabled={false}
                  isLoading={isLoading || isImageUploading}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("close")}
                  onClick={async () => {
                    await this.clearAll();
                    await this.clearStateValues();
                    await this.setAllData();
                  }}
                  text={"Cancel"}
                  size={"md"}
                  type={"btn-block-back-secondary"}
                />
              </div>

              <Button
                leftIcon={false}
                rightIcon={false}
                disabled={
                  !selectedMerchant && !selectedBank && !selectedCategory
                }
                isLoading={isLoading || isImageUploading}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("leftArrow")}
                onClick={async () => {
                  if (editOfferData && editOfferData?.offerId) {
                    await this.onClickUpdateData();
                  } else {
                    await this.onClickSaveData();
                  }
                }}
                text={
                  editOfferData && editOfferData?.offerId ? "Update" : "Review"
                }
                size={"md"}
                type={"btn-block-back"}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  value: state.richTextBox.value,
  selectedOfferData: state.offerReducer.selectedOfferData,
  selectMerchant: state.merchantReducer.selectMerchant,
  selectedMerchant: state.offerReducer.selectMerchant,
  selectedBank: state.offerReducer.selectedBank,
  selectedCategory: state.offerReducer.selectedCategory,
  selectLocations: state.offerReducer.selectLocations,
  selectTerms: state.offerReducer.selectTerms,
  editOfferData: state.offerReducer.editOfferData,
  isCreateOffer: state.offerReducer.isCreateOffer,
  refreshOffers: state.offerReducer.refreshOffers,
  refreshDrafts: state.offerReducer.refreshDrafts,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenSearchMerchant: () =>
      dispatch({ type: actionTypes.OPEN_SEARCH_MERCHANT_MODAL }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    clearSelectedOfferDetails: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    editOfferBank: (payload) =>
      dispatch({ type: actionTypes.EDIT_OFFER_BANK, payload: payload }),
    editOfferCategory: (payload) =>
      dispatch({ type: actionTypes.EDIT_OFFER_CATEGORY, payload: payload }),
    editOfferMerchant: (payload) =>
      dispatch({ type: actionTypes.EDIT_OFFER_MERCHANT, payload: payload }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_CREATE_MODAL,
        payload: payload,
      }),
    openEditBankModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_BANK_CARD_TYPES,
        payload: payload,
      }),
    openEditMerchantModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_MERCHANT_LOCATION,
        payload: payload,
      }),
    openEditMerchantContactsModal: (payload) =>
      dispatch({
        type: actionTypes.EDIT_OFFER_CONTACTS,
        payload: payload,
      }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    onClickOpenTermsAndConditions: () =>
      dispatch({ type: actionTypes.OPEN_TERMS_AND_CONDITIONS_MODAL }),
    clearTermsAndConditions: () =>
      dispatch({ type: actionTypes.CLEAR_TERMS_AND_CONDITION }),
    onClickAddTermsIntoList: (payload) =>
      dispatch({ type: actionTypes.SELECTED_EXISTING_TERMS, payload: payload }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
    refreshDraftLists: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    setValue,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
