import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

//Btn Type { btn-block }
import { RiCheckLine } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`radio-box-main-wrapper d-flex justify-content-between ${
        props.size
      } ${props.isSelect ? "is_active" : ""}`}
      onClick={props.onClick}
    >
      <div className={"radio-check-box"}>
        {props.isSelect && (
          <>
            <RiCheckLine />
          </>
        )}
      </div>

      {props && props.text && <div className={"radio-text"}>{props.text}</div>}
    </div>
  </>
);

Index.propTypes = {
  size: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "unknown",
  size: "md",
};

export default Index;
