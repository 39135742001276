import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {cssTransition, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "../src/assets/stylesheets/vendor/bootstrap.min.css";

import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';
import {keepTheme} from './utils/themes';

import rootReducer from './store/reducers/index';
import AppRouter from './routes/AppRouter';
import {injectStore} from "./api";

const createHistory = require('history');


const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

//inject store to api
injectStore(store);

const swirl = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut"
});



function App() {
    keepTheme();
    injectStore(store);
    return (
        <>
            <ToastContainer/>
            <Provider store={store}>
                <Router history={createHistory}>
                    <AppRouter/>
                </Router>
            </Provider>

        </>
    );
}

export default App;
