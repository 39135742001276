import {ENV_STATIC} from "../consts";

export const getEnv = () => {
    return process.env.REACT_APP_ENV;
}

export const getAppVersion = () => {
    return process.env.REACT_APP_APP_VERSION;
}

export const isEnvStatic = () => {
    return getEnv() === ENV_STATIC;
}

export const getAppBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL;
}

export const getAuthAppBaseUrl = () => {
    return process.env.REACT_APP_AUTH_APP_BASE_URL;
}

export const getCredentialTokenUrl = () => {
    return process.env.REACT_APP_TOKEN_CRED_URL;
}

export const getPinBlockKey = () => {
    return process.env.REACT_APP_PIN_BLOCK_KEY;
}
