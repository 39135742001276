import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className={`tag-main-wrapper d-flex flex-column align-items-center justify-content-center ${props.type} ${props.size}`}
    >
      <div className={`tmw__name`}>
        {props.tagName}
        {props.action && (
          <span className="tmw__action" onClick={props.onClick}>
            {props.action}
          </span>
        )}
      </div>
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "",
};

export default Index;
