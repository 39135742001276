import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import "react-image-crop/dist/ReactCrop.css";

import "./styles.scss";
import "./mobile.scss";
import { Button, CustomTag, Input, PageHeader } from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import { CheckValidity } from "../../../../utils";
import uuid from "react-uuid";
import {
  cooCreateNewCategory,
  cooUpdateCategory,
} from "../service/category.service";
import { OpenNotification } from "../../../../config/notification";
import * as actionTypes from "../../../../store/actions/types";

const CreateNewCategory = (props) => {
  const { selectCategoryData, isEditCategory } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );
  const [dataForm, setDataForm] = useState({
    categoryName: {
      label: "Category name",
      key: "categoryName",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Ex : food & restaurant",
      },
      value: "",
      validation: {
        required: true,
      },
      isValid: false,
      touched: false,
      invalidReason: "",
    },
    iconName: {
      label: "Icon name",
      key: "iconName",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Ex : food",
      },
      value: "",
      validation: {
        required: true,
      },
      isValid: false,
      touched: false,
      invalidReason: "",
    },
    keywords: {
      label: "Keywords",
      key: "keywords",
      elementType: "hasTag",
      elementConfig: {
        type: "text",
        placeholder: "Ex : Restaurant name",
      },
      value: "",
      validation: {
        required: false,
      },
      isValid: false,
      touched: false,
      invalidReason: "",
    },
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //READY FOR UPDATE DATA
  useEffect(() => {
    const updateForm = { ...dataForm };
    if (selectCategoryData?.categoryId) {
      updateForm["categoryName"].value =
        selectCategoryData && selectCategoryData.categoryName;
      updateForm["iconName"].value =
        selectCategoryData && selectCategoryData.iconName;
    } else {
      updateForm["categoryName"].value = "";
      updateForm["iconName"].value = "";
    }
    setDataForm(updateForm);

    let updateTags = [];

    let tagsList = selectCategoryData?.tags || [];

    tagsList.forEach((element) => {
      if (!element?.tagName) {
        updateTags.push({
          id: uuid(),
          tagName: element,
          count: 0,
        });
      } else {
        updateTags.push(element);
      }
    });

    console.log({ updateTags });
    setTags((selectCategoryData && updateTags) || []);
  }, [selectCategoryData]);

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      this.onClickAddHashTag();
      const updateForm = { ...this.state.dataForm };
      updateForm["keywords"].value = "";
      this.setState({ dataForm: updateForm, tag: null });
    }
  };

  //====== INPUT COMMON HANDLE CHANGE
  const handleChange = async (event, inputIdentity) => {
    const updateForm = {
      ...dataForm,
    };

    updateForm[inputIdentity].value = event;
    let validityRes = await CheckValidity(
      inputIdentity,
      updateForm[inputIdentity].value,
      updateForm[inputIdentity].validation
    );

    if (validityRes) {
      updateForm[inputIdentity].isValid = validityRes.isValid;
      updateForm[inputIdentity].invalidReason = validityRes.reason;
      updateForm[inputIdentity].value = event;
      updateForm[inputIdentity].touched = true;
      console.log("required_validation", validityRes);

      let formIsValid = false;
      for (let inputIdentifier in updateForm) {
        formIsValid = updateForm[inputIdentifier].isValid && formIsValid;
      }
      console.log("formIsValid", formIsValid);
      setDataForm(updateForm);
    }
  };

  //ONCLICK ADD KEYWORDS
  const onClickAddHashTag = async (value) => {
    console.log("tagvalue", value);
    const updateForm = {
      ...dataForm,
    };
    let index = tags.findIndex((x) => x.tagName === value);
    console.log("index", index);
    if (index < 0) {
      setTags((tags) => [
        ...tags,
        {
          id: uuid(),
          tagName: value,
          count: 0,
        },
      ]);

      updateForm["keywords"].value = "";
      setDataForm(updateForm);
      console.log({ tags });
    } else {
      //OpenNotification('error', 'duplicate keyword not allowed.Please check', 'error');
      console.error("index", index);
      updateForm["keywords"].isValid = true;
      updateForm["keywords"].touched = true;
      updateForm["keywords"].invalidReason =
        "duplicate keyword is not allowed.";
      setDataForm(updateForm);
      console.log("updateForm", dataForm);
    }
  };

  //====== ONCLICK REMOVE KEYWORDS
  const onRemoveHashTag = async (i) => {
    const temp = [...tags];
    temp.splice(i, 1);
    setTags(temp);
  };

  // const CheckValidity = (value, rules, key) => {
  //   let isValid = true;
  //   console.log("value", value);

  //   if (rules.required) {
  //     isValid = value && value?.trim() !== "" && isValid;
  //   }
  //   return isValid;
  // };

  //CHECKED AND UPDATE FORM VALIDATION STATUS BEFORE SAVE DATA
  const updateFormValidity = async (inputIdentity) => {
    const updateSignUpForm = dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    let isValidation = await CheckValidity(
      inputIdentity,
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.isValid = isValidation.isValid;
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    setDataForm(updateSignUpForm);
  };

  //CLEAR ALL FORM DATA
  const clearAll = async () => {
    setTags([]);
    setIsLoading(false);
    const updateForm = { ...dataForm };
    for (let key in updateForm) {
      console.log(updateForm[key]);
      updateForm[key].value = "";
      setDataForm(updateForm);
    }
  };

  //ONCLICK SAVE DATA
  const onClickSaveData = async () => {
    console.log("event fire");
    await updateFormValidity(dataForm["categoryName"].key);
    await updateFormValidity(dataForm["iconName"].key);
    try {
      if (dataForm["categoryName"].isValid && dataForm["iconName"].isValid) {
        setIsLoading(true);
        const body = {
          categoryName: dataForm["categoryName"].value,
          tags: tags,
          iconName: dataForm["iconName"].value,
          status: "ACTIVE",
        };
        let res = await cooCreateNewCategory(body);
        console.log("response-->", { res });
        if (res && res.data && res.data.status) {
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
          await clearAll();
        } else {
          OpenNotification(
            "error",
            res && res.data && res.data.message,
            "error"
          );
          //await clearAll();
          setIsLoading(false);
        }
      } else {
        OpenNotification("Error", "Invalid data", "error");
        await clearAll();
      }
    } catch (e) {
      OpenNotification("Error", e, "error");
      await clearAll();
    }
  };

  //ONCLICK UPDATE DATA
  const onClickUpdate = async () => {
    console.log("event fire");
    await updateFormValidity(dataForm["categoryName"].key);
    await updateFormValidity(dataForm["iconName"].key);
    try {
      if (dataForm["categoryName"].value && dataForm["iconName"].value) {
        setIsLoading(true);
        const body = {
          categoryName: dataForm["categoryName"].value,
          tags: tags,
          iconName: dataForm["iconName"].value,
          status: selectCategoryData?.status,
        };
        let res = await cooUpdateCategory(selectCategoryData?.categoryId, body);
        console.log("response-->", { res });
        if (res && res.data && res.data.status) {
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
          await clearAll();
        } else {
          OpenNotification(
            "error",
            res && res.data && res.data.message,
            "error"
          );
          //await clearAll();
          setIsLoading(false);
        }
      } else {
        OpenNotification("Error", "Invalid data", "error");
        await clearAll();
      }
    } catch (e) {
      OpenNotification("Error", e, "error");
      await clearAll();
    }
  };

  return (
    <>
      <div className={"cco__cnc-right-content-wrapper"}>
        <PageHeader option={"sm"} title={"Create new category"} />
        <div className={"cco_common-form-content-wrapper"}>
          <Input
            elementConfig={dataForm.categoryName.elementConfig}
            elementType={dataForm.categoryName.elementType}
            required={dataForm.categoryName.validation.required}
            isValid={!dataForm.categoryName.isValid}
            touched={dataForm.categoryName.touched}
            value={dataForm.categoryName.value}
            props={dataForm.categoryName}
            isShowRequired={true}
            size={"md"}
            margin={"m-b-2"}
            label={dataForm.categoryName.label}
            changed={async (e) => {
              e.preventDefault();
              await handleChange(e.target.value, dataForm.categoryName.key);
            }}
          />
          <Input
            elementConfig={dataForm.iconName.elementConfig}
            elementType={dataForm.iconName.elementType}
            required={dataForm.iconName.validation.required}
            isValid={!dataForm.iconName.isValid}
            touched={dataForm.iconName.touched}
            props={dataForm.iconName}
            value={dataForm.iconName.value}
            isShowRequired={true}
            size={"md"}
            margin={"m-b-2"}
            label={dataForm.iconName.label}
            changed={async (e) => {
              e.preventDefault();
              await handleChange(e.target.value, dataForm.iconName.key);
            }}
          />
          <Input
            elementConfig={dataForm.keywords.elementConfig}
            elementType={dataForm.keywords.elementType}
            required={dataForm.keywords.validation.required}
            isValid={!dataForm.keywords.isValid}
            touched={dataForm.keywords.touched}
            invalidReason={dataForm.keywords.invalidReason}
            props={dataForm.keywords}
            value={dataForm.keywords.value}
            isShowRequired={false}
            size={"md"}
            label={dataForm.keywords.label}
            onKeyPress={async (e) => {
              await handleKeyPress(dataForm.keywords.value);
            }}
            onClickAddTag={async (e) => {
              await onClickAddHashTag(dataForm.keywords.value);
            }}
            changed={async (e) => {
              e.preventDefault();
              await handleChange(e.target.value, dataForm.keywords.key);
            }}
          />

          {dataForm["keywords"].invalidReason && (
            <div className={"mt-2"}>
              <div className={"error-required"}>
                {dataForm["keywords"].invalidReason}
              </div>
            </div>
          )}

          {tags && (
            <>
              <div className={"multiple-line-wrapper mt-2 mb-2"}>
                <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                  {(tags || []).map((item, index) => (
                    <CustomTag
                      index={index}
                      remove={true}
                      onRemoveTime={async () => onRemoveHashTag(index)}
                      format={"text"}
                      text={item.tagName || item}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          <div className={"login-footer-action-wrapper d-flex mt-4"}>
            {!isEditCategory && (
              <>
                <Button
                  leftIcon={false}
                  rightIcon={false}
                  isLoading={isLoading}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("leftArrow")}
                  onClick={async () => {
                    await onClickSaveData();
                  }}
                  text={"Done"}
                  size={"md"}
                  type={"btn-block"}
                />
              </>
            )}
            {isEditCategory && (
              <div
                className={
                  "full-width-100 " +
                  "d-flex flex-column justify-content-center"
                }
              >
                <>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    isLoading={isLoading}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={async () => {
                      await onClickUpdate();
                    }}
                    text={"Update"}
                    size={"md"}
                    type={"btn-block"}
                  />
                </>

                <div className={"mt-2"}>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    isLoading={isLoading}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      props.cancelEditCategory();
                    }}
                    text={"Cancel"}
                    size={"full-width-100"}
                    type={"btn-delete"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelEditCategory: () =>
      dispatch({
        type: actionTypes.DONE_CATEGORY_UPDATE,
      }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_CATEGORY,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCategory);
