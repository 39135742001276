import React from "react";
import "./styles.scss";
import "./mobile.scss";
import Moment from "react-moment";
import { SelectBox } from "../../../components";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { Popover } from "antd";

const Index = (props) => {
  const content = (
    <>
      {(props.data || [])
        .filter((item) => item?.isActive)
        .slice(2)
        .map((item, index) => (
          <SelectBox
            index={index}
            type={"location"}
            remove={false}
            isSelect={true}
            select={false}
            format={"text"}
            text={item.value || item.tagName}
          />
        ))}
    </>
  );

  return (
    <>
      <div className={`offer-info-card-wrapper d-flex ${props.style}`}>
        <div className={"icon-wrapper"}>{props.icon}</div>
        <div className={"title-wrapper d-flex flex-column"}>
          <div className={"oi-title"}>{props.lable}</div>
          <div className={"oi-content"}>
            {props.offerDateType === "fromTo" && (
              <>
                Valid from <Moment format="DD MMMM">{props.offerStart}</Moment>{" "}
                to <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
              </>
            )}

            {props.type === "tag" && (
              <>
                <div className={"d-flex flex-wrap align-items-center"}>
                  {(props.data || [])
                    .filter((item) => item?.isActive)
                    .slice(0, 2)
                    .map((item, index) => (
                      <SelectBox
                        index={index}
                        type={"location"}
                        remove={false}
                        isSelect={true}
                        select={false}
                        format={"text"}
                        text={item.value || item.tagName}
                      />
                    ))}
                  {props.data.length > 2 && (
                    <Popover content={content}>
                      <PiDotsThreeCircleLight size={24} color="#b5b8c2" />
                    </Popover>
                  )}
                </div>
              </>
            )}

            {props.offerDateType === "until" && (
              <>
                <>
                  Valid until{" "}
                  <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
                </>
              </>
            )}

            {props.offerDateType === "till" && (
              <>
                Till <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
              </>
            )}
            {props.offerDateType === "on" && (
              <>
                Valid On <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
              </>
            )}
            {props.offerDateType === "other" && (
              <>
                Till <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
              </>
            )}
            {props.offerDateType === "customize" && (
              <>
                Till <Moment format="MMMM Do YYYY">{props.offerEnd}</Moment>
              </>
            )}

            {props.type === "text" && <>{props.title}</>}

            {props.type === "dateAgo" && (
              <>
                <Moment format={"MMM"}> {props.title} </Moment>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
