import {GetTranslation} from "../language";

const AmountRegex = /^[0-9]*$/g;

export const validateAmountInput = (oldValue, newValue) => {
    if (newValue.split("").filter(i => i !== "." && isNaN(parseInt(i))).length > 0) {
        return oldValue;
    }
    let decimalSections = newValue.split(".");
    if (decimalSections.length > 2) {
        return oldValue;
    }
    if (decimalSections.length === 2 && decimalSections[1].length > 2) {
        //only allow two decimal places
        return oldValue;
    }
    return newValue;
};

export const validateCardOrAccountNumber = (oldValue, newValue) => {
    if (newValue.split("").filter(i => isNaN(parseInt(i))).length > 0) {
        return oldValue;
    }
    return newValue;
};

export const validateUserPassword = (password) => {
    let res = {
        status: false,
        hasCharCount: password.length >= 8,
        hasLower: password.toUpperCase() != password,
        hasUpper: password.toLowerCase() != password,
        hasNumbers: /\d/.test(password)
    };
    res.status = (res.hasLower && res.hasUpper && res.hasNumbers && res.hasCharCount);
    return res;
};

export const validateCharLength = (oldValue, newValue, length) => {
    if (newValue.length > length)
        return oldValue;
    return newValue;
};

export const checkValidity = (fieldName, value, rules) => {
    let isValid = true;
    let reason = "";

    if (rules.required && typeof value === 'string') {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.regex && rules.regex !== "") {
        isValid = new RegExp(rules.regex).test(String(value)) && isValid
    }

    if (rules.email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValid = re.test(String(value).toLowerCase()) && isValid
    }

    if (fieldName === "password") {
        let res = validateUserPassword(value);
        if (!res.status) {
            isValid = false;
            if (!res.hasCharCount) {
                reason = GetTranslation("err_password_length")
            } else if (!res.hasLower) {
                reason = GetTranslation("err_password_lower_case")
            } else if (!res.hasUpper) {
                reason = GetTranslation("err_password_upper_case")
            } else if (!res.hasSpecial) {
                reason = GetTranslation("err_password_special_char")
            } else {
                reason = GetTranslation("err_password_should_have_number")
            }
        }
    }

    if (rules.minLength && rules.maxLength) {
        if (value.toString().length < rules.minLength) {
            isValid = false;
            reason = "Minimum length should be" + ` ${rules.minLength}`
        } else if (value.toString().length > rules.maxLength) {
            isValid = false;
            reason = "Maximum length should be" + ` ${rules.maxLength}`
        } else {
            isValid = true;
        }
    }

    if (rules.minValue && rules.maxValue) {
        if (value && parseFloat(value) >= rules.minValue) {
            isValid = true
            if (value && parseFloat(value) <= rules.maxValue) {
                isValid = true
            } else {
                reason = "Value should be smaller than" + ` ${rules.maxValue}`
                isValid = false;
            }
        } else {
            if (fieldName === "amount" || fieldName === "initialDeposit")
                reason = "Amount should be larger than" + ` ${rules.minValue}`
            else
                reason = "Value should be larger than" + ` ${rules.minValue}`
            isValid = false;
        }
    } else {
        if (rules.minValue) {
            if (value && parseFloat(value) >= rules.minValue) {
                isValid = true
            } else {
                if (fieldName === "amount")
                    reason = "Amount should be larger than" + ` ${rules.minValue}`
                else
                    reason = "Amount should be larger than" + ` ${rules.minValue}`
                isValid = false;
            }
        }

        if (rules.maxValue) {
            if (value && parseFloat(value) <= rules.maxValue) {
                isValid = true
            } else {
                if (fieldName === "changeLimit")
                    reason = "Entered new limit is larger than the maximum limit"
                else
                    reason = "Value should be smaller than" + ` ${rules.maxValue}`
                isValid = false;
            }
        }
    }

    return {isValid, reason}
}