import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { SelectBox } from "../../index";
import { RiCheckFill } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`cco__terms-card__wrapper d-flex flex-row align-items-center ${props.option}`}
    >
      <div
        className={
          "cco__tcw__check d-flex align-items-center align-content-center"
        }
      >
        <RiCheckFill />
      </div>

      <div className={"cco__tcw__desc"}>{props.description}</div>
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
