import React from "react";
import {
  RiAdvertisementLine,
  RiArchiveLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiBuilding2Line,
  RiCheckLine,
  RiCloseCircleLine,
  RiComputerFill,
  RiDashboardLine,
  RiEmpathizeLine,
  RiFileCopy2Line,
  RiFileCopyLine,
  RiFileUploadLine,
  RiGalleryLine,
  RiGroupLine,
  RiLockFill,
  RiPercentLine,
  RiSearchEyeFill,
  RiSearchLine,
  RiSmartphoneFill,
  RiThumbUpLine,
  RiUploadCloud2Line,
} from "react-icons/ri";
import {
  BiBuildingHouse,
  BiChair,
  BiDotsHorizontalRounded,
  BiHotel,
  BiPieChartAlt2,
  BiRestaurant,
  BiShoppingBag,
} from "react-icons/bi";
import {
  MdDirectionsCarFilled,
  MdKeyboardArrowUp,
  MdOnlinePrediction,
  MdOpenInNew,
  MdOutlineCardTravel,
  MdOutlineCastForEducation,
  MdOutlineEmail,
  MdOutlineHealthAndSafety,
  MdOutlineKeyboardArrowDown,
  MdOutlineRamenDining,
  MdOutlineSolarPower,
  MdOutlineWarningAmber,
  MdPayment,
  MdWorkspacePremium,
  MdNightlife,
} from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { PiDressBold } from "react-icons/pi";
import { LuBoxes } from "react-icons/lu";
import { CgSmileMouthOpen } from "react-icons/cg";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import { FiDollarSign, FiInfo, FiSliders, FiUser } from "react-icons/fi";
import { GiHeartNecklace } from "react-icons/gi";
import { LiaEnvelope } from "react-icons/lia";
import { FaRegCircleUser } from "react-icons/fa6";

export const GetIcon = (_iconType) => {
  switch (_iconType) {
    case "search":
      return <RiSearchLine />;
    case "shopping-cart":
      return <RiSearchEyeFill />;
    case "user":
      return <RiSearchEyeFill />;
    case "eye":
      return <RiSearchEyeFill />;
    case "leftArrow":
      return <RiArrowRightLine />;
    case "dashboard":
      return <RiDashboardLine />;
    case "bank":
      return <RiBuilding2Line />;
    case "offer":
      return <RiPercentLine />;
    case "merchant":
      return <RiEmpathizeLine />;
    case "category":
      return <RiSearchEyeFill />;
    case "profile":
      return <FaRegCircleUser />;
    case "upload":
      return <RiFileUploadLine />;
    case "delete":
      return <RiFileUploadLine />;
    case "privilege":
      return <RiLockFill />;
    case "close":
      return <RiCloseCircleLine />;
    case "media":
      return <RiGalleryLine />;
    case "copy":
      return <RiFileCopyLine />;
    case "advertisement":
      return <RiAdvertisementLine />;
    case "desktop":
      return <RiComputerFill />;
    case "mobile":
      return <RiSmartphoneFill />;
    case "iconClick":
      return <RiCheckLine />;
    case "hotel":
      return <BiHotel />;
    case "supermarket":
      return <BiShoppingBag />;
    case "online":
      return <MdOnlinePrediction />;
    case "electronics&furniture":
      return <BiChair />;
    case "health&insurance":
      return <MdOutlineHealthAndSafety />;
    case "fashion":
      return <PiDressBold />;
    case "restaurant":
      return <BiRestaurant />;
    case "dining":
      return <MdOutlineRamenDining />;
    case "travel":
      return <MdOutlineCardTravel />;
    case "premiumoffers":
      return <MdWorkspacePremium />;
    case "desktop":
      return <RiComputerFill />;
    case "mobile":
      return <RiSmartphoneFill />;
    case "rightArrow":
      return <RiArrowLeftLine />;
    case "clothes":
      return <RiArrowLeftLine />;
    case "fuel":
      return <BsFillFuelPumpFill />;
    case "fileUpload":
      return <RiUploadCloud2Line />;
    case "thumbsUp":
      return <RiThumbUpLine />;
    case "openWindow":
      return <MdOpenInNew />;
    case "warning":
      return <MdOutlineWarningAmber />;
    case "arrowDown":
      return <MdOutlineKeyboardArrowDown />;
    case "arrowUp":
      return <MdKeyboardArrowUp />;
    case "dashboardPie":
      return <BiPieChartAlt2 />;
    case "bulk":
      return <RiFileCopy2Line />;
    case "mail":
      return <MdOutlineEmail />;
    case "bankHouse":
      return <BiBuildingHouse />;
    case "categoryBox":
      return <LuBoxes />;
    case "usersFace":
      return <CgSmileMouthOpen />;
    case "greetingDots":
      return <BiDotsHorizontalRounded />;
    case "chevronRight":
      return <TbChevronRight />;
    case "chevronLeftt":
      return <TbChevronLeft />;
    case "user":
      return <FiUser />;
    case "dollar":
      return <FiDollarSign />;
    case "info":
      return <FiInfo />;
    case "solar":
      return <MdOutlineSolarPower />;
    case "Leisure & Lifestyle":
      return <MdNightlife />;
    case "Jewellery":
      return <GiHeartNecklace />;
    case "Pay Plans":
      return <MdPayment />;
    case "education":
      return <MdOutlineCastForEducation />;
    case "Auto":
      return <MdDirectionsCarFilled />;
    case "envelope":
      return <LiaEnvelope />;
    case "customer":
      return <RiGroupLine />;
    case "customerInfo":
      return <RiArchiveLine />;

    default:
      return "<icon>";
  }
};
