import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import "./styles.scss";
import {RiCloseLine} from "react-icons/ri";
import Manual from "../../createNewItems/CreateNewMerchant/manual";
import * as actionTypes from "../../../store/actions/types";

const Index = () => {
    const dispatch = useDispatch();
    const {isOpenCreateNewMerchant} = useSelector(({merchantReducer}) => merchantReducer);

    return (
        <div className={`coo__modal-main-wrapper animate__animated ${isOpenCreateNewMerchant ? "show-me" : "hide-me"}`}>
            <div className={"coo_modal-body-content content_merchant "}>
                <div
                    className={`close-modal-wrapper`}
                    onClick={() => {
                        dispatch({type: actionTypes.CLOSE_CREATE_NEW_MERCHANT});
                    }}>
                    <RiCloseLine/>
                </div>
                <Manual/>
            </div>
        </div>
    );
};

export default Index;
