import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { GetIcon } from "../../../config/icon";
import { RiCheckFill } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`company-card-wrapper ${props.isActive} ${props.size}`}
      onClick={props.onClick}
    >
      {props.type === "merchant" && (
        <div className="ccw__logo">
          {props.logo && (
            <div className={"ccw__logo-image"}>
              <img src={props.logo} alt="creditcard offers" />
            </div>
          )}

          {!props.logo && (
            <div className="ccw__logo-icon">{GetIcon("profile")}</div>
          )}
        </div>
      )}
      <div className="d-flex flex-column ml-2">
        {props.merchantName && (
          <div className="ccw__merchant-name">{props.merchantName}</div>
        )}
        {props.type === "merchant" && (
          <div className="ccw__merchant-id">
            {"MerchantId: "}
            {props.merchantId}
          </div>
        )}
      </div>
      <>
        {props.isActive && (
          <div
            className={
              "ccw__check d-flex align-items-center align-content-center"
            }
          >
            <RiCheckFill />
          </div>
        )}
      </>
    </div>
  </>
);

Index.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "light",
  size: "sm",
  icon: "<RiCloseFill/>",
};

export default Index;
