import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../store/actions/types";
import { Scrollbars } from "react-custom-scrollbars";

import { getToken, login, userDetails, userLogOut } from "../../utils";
import { authentication, newOTPVerify, updateUserPassword } from "../../api";

import { GetIcon } from "../../config/icon";

import { Input, Button, JoinFormHeader } from "../../components";
import { OpenNotification } from "../../config/notification";
import { setUser } from "../../store/actions";

class LoginPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      noOfSpot: 2,
      active: 1,
      isLoading: false,
      dataForm: {
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*************",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
        confirmPassword: {
          key: "confirmPassword",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "Confirm password",
          },
          value: "",
          validation: {
            required: true,
            minLength: 5,
            confirmPassword: true,
          },
          valid: false,
          touched: false,
          label: "Confirm Password",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  onClickSave = async () => {
    try {
      this.setState({ isLoading: true });
      const dataForm = this.state.dataForm;

      let body = {
        password: dataForm.password.value,
      };
      console.log("body:", body);
      //todo
      //validation
      //authentication
      let res = await updateUserPassword(body);

      console.log("updateUserPassword::", res);
      if (res && res.data && res.data.status) {
        let user = res.data.data;
        this.setState({ isLoading: false });

        await this.props.setUser(res.data.data);
        const token = await getToken();
        console.log("authentication:", res.token);

        this.props.history.push("/payment");
      } else {
        OpenNotification("error", "Invalid login details.", "error");
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error("GOT LOGIN ERROR:", e);
      OpenNotification("error", e, "error");
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { dataForm, isLoading } = this.state;
    const { loginUser } = this.props;
    return (
      <>
        <div className={"main-fix-bg"} />
        <section className={"container"}>
          <div className={"profile-wrapper"}>
            <div className=" d-flex flex-row">
              <div className={"profile-img"}>
                {loginUser?.profileAvatar && (
                  <img
                    alt={"credit card offers"}
                    src={loginUser?.profileAvatar}
                  />
                )}
              </div>
              <div className={"profile-details"}>
                <div className={"name"}>
                  Hi{" "}
                  {loginUser && loginUser?.firstName
                    ? loginUser?.firstName
                    : ""}
                </div>
                <div className={"type"}>{loginUser && loginUser?.userType}</div>
              </div>
            </div>
            <button
              className={"user-logout"}
              onClick={async (e) => {
                let userState = await userLogOut(e);
                window.location.replace("/sign-in");
                if (userState) {
                  // dispatch(clearUserDetails());
                  window.location.reload();
                  window.location.replace("/sign-in");
                }
              }}
            >
              Logout
            </button>
          </div>
          <div className="row ">
            <section className={"login-main-wrapper"}>
              <div className={"welcome-body-wrp"}>
                <div className={"login-b-left-wrp "}>
                  <div className={"welcome-body-header d-flex flex-column"}>
                    <JoinFormHeader
                      isLogo={true}
                      wizardNo={1}
                      history={this.props.history}
                      header={"Secure your account"}
                    />
                  </div>
                  <div className={"cco__login-form-wrapper"}>
                    <div className={"data-form-wrapper flex-row "}>
                      <Input
                        elementConfig={dataForm.password.elementConfig}
                        elementType={dataForm.password.elementType}
                        required={dataForm.password.validation.required}
                        isinvalid={!dataForm.password.valid}
                        isShowPasswordStrengthMeter={true}
                        invalidReason={dataForm.password.invalidReason}
                        touched={dataForm.password.touched}
                        value={dataForm.password.value}
                        isRightIcon={true}
                        rightIcon={GetIcon("eye")}
                        onClickRightIcon={() =>
                          this.onClickRightIcon(dataForm.password.key)
                        }
                        size={"lg"}
                        margin={"m-t-0"}
                        label={dataForm.password.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.password.key)
                        }
                      />
                      <Input
                        elementConfig={dataForm.confirmPassword.elementConfig}
                        elementType={dataForm.confirmPassword.elementType}
                        required={dataForm.confirmPassword.validation.required}
                        isinvalid={!dataForm.confirmPassword.valid}
                        isShowPasswordStrengthMeter={false}
                        invalidReason={dataForm.confirmPassword.invalidReason}
                        touched={dataForm.confirmPassword.touched}
                        value={dataForm.confirmPassword.value}
                        isRightIcon={false}
                        rightIcon={GetIcon("eye")}
                        size={"lg"}
                        margin={"m-t-24"}
                        label={dataForm.confirmPassword.label}
                        changed={(event) =>
                          this.inputHandleChange(
                            event,
                            dataForm.confirmPassword.key
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className={"login-footer-action-wrapper d-flex mt-4"}>
                    <Button
                      leftIcon={false}
                      rightIcon={false}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("leftArrow")}
                      onClick={() => this.onClickSave()}
                      text={"Done"}
                      size={"lg"}
                      type={"btn-block"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.userReducer.data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
