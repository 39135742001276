import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";

import { Collapse, Tabs } from "antd";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { cooUpdateMerchantTerms } from "../../merchant/service/merchant.service";
import CreateNewTermsAndConditions from "./CreateNewTermsAndConditions";
import FindTermsAndConditions from "./FindTermsAndConditions";
import { Scrollbars } from "react-custom-scrollbars";

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      dataForm: {
        termsConditions: {
          key: "termsConditions",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        searchBank: {
          key: "searchBank",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async updateMerchantTerms(newTermsList) {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsConditions);
    newTermsList.forEach((element) => {
      newTermsAndConditions.push(element);
    });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length !== newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsConditions);
    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length > newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async refresh() {
    this.setState({ isLoading: true });

    this.setState({
      isLoading: false,
      selectLocations: [],
      termsConditions: [],
      formWizard: 1,
    });
  }

  onRemoveTermsAndConditions = (i) => {
    const { termsConditions } = this.state;

    termsConditions[i].isActive = !termsConditions[i].isActive;
    this.setState({ termsConditions });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpenSearchMerchant !== this.props.isOpenSearchMerchant) {
      const openMerchant = this.props.isOpenSearchMerchant;

      if (openMerchant) {
        this.setState({
          formWizard: 1,
          // selectedMerchantID: null,
          // selectedBankId: null,
          // selectedCategoryId: null,
        });
      }
    }
    if (prevProps.editBank !== this.props.editBank) {
      const editBank = this.props.editBank;

      if (editBank) {
        this.setState({
          formWizard: 1,
          selectedBank: editBank,
          selectedBankId: editBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editCategory !== this.props.editCategory) {
      const editCategory = this.props.editCategory;

      if (editCategory) {
        this.setState({
          formWizard: 2,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: editCategory.categoryId,
          selectedCategory: editCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editMerchant !== this.props.editMerchant) {
      const editMerchant = this.props.editMerchant;

      if (editMerchant) {
        this.setState({
          formWizard: 3,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: editMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
  }

  selectAllTermsAndConditions = () => {
    const { termsConditions } = this.state;
    for (let idx = 0; idx < termsConditions.length; idx++) {
      if (termsConditions[idx].isActive) {
        termsConditions[idx].isActive = false;
      } else {
        termsConditions[idx].isActive = true;
      }
    }
    this.setState({ termsConditions });
  };

  render() {
    const {
      viewPointHeight,
      dataForm,
      termsConditions,
      selectedBankId,
      tabJson,
    } = this.state;
    const { isNewTermsAndConditions, selectMerchant, editOfferData, bankData } =
      this.props;

    const remappedData = bankData.map((item, index) => ({
      key: item.bankId, // Adjust the key as needed
      label: item.bankName,
      children: (
        <CreateNewTermsAndConditions
          key={index + 1}
          bankName={item.bankName}
          bankId={item.bankId}
        />
      ), // You can adjust the content as needed
    }));

    return (
      <>
        <section
          className={`coo__modal-main-wrapper z-100 animate__animated ${
            isNewTermsAndConditions ? "show-me" : "hide-me"
          }`}
        >
          <div
            className={"coo_modal-body-content fix-906 d-flex flex-column "}
            style={{ height: "93%", padding: "32px" }}
          >
            <FindTermsAndConditions />
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"coo_mbc__header mr-4"}>
                {"Create New Terms and Conditions"}
              </div>

              <div
                className={`close-modal-wrapper `}
                onClick={async () => {
                  this.props.onClickCloseTermsAndConditions();
                }}
              >
                <RiCloseLine />
              </div>
            </div>
            <Scrollbars
              style={{ height: viewPointHeight - 150 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"cco__mtc-tab mt-2"}>
                <Tabs defaultActiveKey="1" items={remappedData} />
              </div>
            </Scrollbars>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isNewTermsAndConditions: state.termsReducer.isNewTermsAndConditions,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    bankData: state.bankReducer.bankData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseTermsAndConditions: () =>
      dispatch({
        type: actionTypes.CLOSE_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL,
      }),
    onClickCloseSearchMerchant: () =>
      dispatch({ type: actionTypes.CLOSE_SEARCH_MERCHANT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
