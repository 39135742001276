import React, { useState } from "react";
import "./styles.scss";
import { Button, LinkComponent } from "../../components";
import { GetIcon } from "../../config/icon";

const Index = (props) => {
  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="blocked-main-wrapper form__padding d-flex flex-column align-items-center"
        style={{margin:"0 auto"}}>
          <div className={`img-component-wrapper`}>
            <img
              className={`img-component`}
              src={require("../../assets/img/Info_fill.png")}
              //alt="product item image"
            />
          </div>
          {/* <ImageComponent
          data={{
            imgUrl: require("../../assets/img/Info_fill.png"),
            size= "lg"
          }}
        /> */}
          <div className="bmw__head-title">
            This account is temporarily blocked
          </div>
          <div className="bmw__head-subtitle">Too many send OTP attempts.</div>
          <div className="bmw__message">
            your account has been temporarily blocked from taking this
            action.This block will expire in about hour.
          </div>
          <Button
            leftIcon={false}
            rightIcon={false}
            isActiveAnimation={false}
            reactRightIcon={GetIcon("leftArrow")}
            onClick={() => props.history.push("/sign-in")}
            text={"Ok"}
            size={"lg"}
            type={"btn-block"}
          />
          <LinkComponent
            size="md"
            urlName="Contact us"
            url="https://dev.creditcardoffers.lk/"
          />
        </div>
      </div>
    </>
  );
};
export default Index;
