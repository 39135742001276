import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";
import { Spin } from "antd";
import {
  EmptyData,
  Insightcard,
  OfferCountCard,
  PageHeader,
} from "../../components";
import { onClickOpenImpression } from "../../store/actions/impression";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      pageViewCount: 10,
      insightsList: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const {
      userDataSource,
      pendingOffers,
      acceptedOffers,
      isLoading,
      viewPointHeight,
      user,
      insightsList,
    } = this.state;
    const {} = this.props;

    return (
      <>
        <div className="cco-insights">
          <div className={"cco__page-header-wrapper"}>
            <PageHeader
              option={"d-flex justify-content-between"}
              isRefresh={true}
              isDate={false}
              isLoading={isLoading}
              // onClickRefresh={async () => {
              //   let res = await this.getUsers();
              // }}
              title={"Insights"}
            />
          </div>

          <>
            <Scrollbars
              style={{ height: viewPointHeight - 70 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              {isLoading && (
                <div style={{ margin: "10% 40%" }}>
                  <Spin size="large" spinning={isLoading} />
                </div>
              )}
              {!isLoading && (
                <>
                  {insightsList && insightsList.length === 0 && (
                    <div className={`cco__insights-body-wrapper `}>
                      <div className={"cco__ibw__count d-flex flex-row "}>
                        <OfferCountCard
                          title={"Total Insights"}
                          count={"250"}
                          size={"sm"}
                        />

                        <div style={{ minWidth: "190px" }}>
                          <OfferCountCard
                            title={"Impression"}
                            count={"40"}
                            size={"sm"}
                          />
                        </div>
                        {user?.userType != "user" && (
                          <div style={{ minWidth: "190px" }}>
                            <OfferCountCard
                              title={"Users"}
                              count={userDataSource ? userDataSource.length : 0}
                              size={"sm"}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <Insightcard
                          insightID={"6708074f7aa9969e3e0128ea"}
                          date={"03-OCT- 2024"}
                          time={"03 hours ago"}
                          onClick={() => this.props.onClickOpenImpression()}
                        />
                        <Insightcard
                          insightID={"6708074f7aa9969e3e0128ea"}
                          date={"03-OCT- 2024"}
                          time={"03 hours ago"}
                        />
                        <Insightcard
                          insightID={"6708074f7aa9969e3e0128ea"}
                          date={"03-OCT- 2024"}
                          time={"03 hours ago"}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {!isLoading && insightsList?.length > 0 && (
                <EmptyData
                  title={"Insights Not Found"}
                  imgUrl={require("../../assets/img/image.png")}
                />
              )}
            </Scrollbars>
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
    onClickOpenImpression: (payload) =>
      dispatch({
        type: actionTypes.OPEN_IMPRESSION_MODAL,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
