import ApiRoutes from "../../../constants/api-routes";
import { ccoInstance } from "../../../service";

///${pageNo}/${pageSize}
export const cooGetAllMerchantByPage = async ({ pageNo, pageSize }) =>
  ccoInstance.get(
    `${ApiRoutes.GET_ALL_MERCHANT_BY_PAGE}/${pageNo}/${pageSize}`
  );

export const cooGetAllMerchants = async () =>
  ccoInstance.get(`${ApiRoutes.GET_ALL_MERCHANT}`);

export const cooGetOfferByMerchant = async (merchantId) =>
  ccoInstance.get(`${ApiRoutes.GET_OFFER_BY_MERCHANT + merchantId}`);

export const cooUpdateMerchantTerms = async (body, merchantId) =>
  ccoInstance.put(`${ApiRoutes.UPDATE_MERCHANT_TERMS + merchantId}`, body);

export const cooUpdateMerchantLocations = async (body, merchantId) =>
  ccoInstance.put(`${ApiRoutes.UPDATE_MERCHANT_LOCATIONS + merchantId}`, body);

export const cooGetMerchantOfferCount = async (merchantId) =>
  ccoInstance.get(`${ApiRoutes.MERCHANT_OFFER_COUNT + merchantId}`);

export const cooFindMerchantByName = async (query) =>
  ccoInstance.get(`${ApiRoutes.FIND_MERCHANT_BY_NAME + query}`);

export const cooFindMerchantById = async (merchantId) =>
  ccoInstance.get(`${ApiRoutes.FIND_MERCHANT_BY_ID + merchantId}`);
