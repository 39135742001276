import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {GetIcon} from "../../../config/icon";
import {Button} from "../../../components";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
    let scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
    let clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }


  render() {
    const {selectMediaData,isActive} = this.props;
    return (
        <div>
          {isActive &&
              <div className={'media-info-wrapper'}>
                <div className={'media-view'}>
                  <div className={'img-wrapper'}>
                    <img
                        src={'https://cco-media-v4.s3.ap-southeast-1.amazonaws.com/' + selectMediaData.Key}
                        alt="creditcard offers"/>
                  </div>
                </div>

                <div className={'media-name'}>
                  <label>Media name</label>
                  <p>{selectMediaData.Key}</p>
                </div>
                <div className={'media-URL-wrp'}>
                  <label>URL</label>
                  <div className={'link'}>
                    {'https://cco-media-v4.s3.ap-southeast-1.amazonaws.com/' + selectMediaData.Key}
                  </div>
                  <div className={'copy-icon'}>{GetIcon('copy')}</div>
                </div>
                <div className={'other-info'}>
                  <label>Size</label>
                  <p>{selectMediaData.Size}</p>
                </div>

                <div className={"login-footer-action-wrapper footer-button"}>
                  <Button
                      leftIcon={false}
                      rightIcon={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("leftArrow")}
                      onClick={async () => {
                      }}
                      text={"Delete"}
                      size={"md"}
                      type={"btn-delete full-width-100"}
                  />
                </div>
              </div>
          }
        </div>
    );
  }
}

const
    mapStateToProps = state => {
      return {
        selectMediaData: state.mediaReducer.selectMediaData,
        isActive: state.mediaReducer.isActive,
      };
    };

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
