import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const PreviewAdvertisementCard = (props) => {
  return (
    <div
      className={"pw-previewCard-wrapper"}
      // style={styles.card}
    >
      {props.isImage && (
        <div className={"pcw-image-wrapper"}>
          <img src="" alt="" style={styles.image} />
        </div>
      )}
      <div className={"d-flex flex-column"} style={styles.textContainer}>
        <div>
          <h2 className={"pcw-card-title"}>Title</h2>
          <p className={"pcw-card-description"}>Description</p>
          {/* 
          <a href="http://www.ooge.lk" target="_blank" className={"ad-link"}>
            Sponsored . www.ooge.lk
          </a> */}
        </div>
        <button className={"pcw-acbuttonName-btn"}>Button name</button>
      </div>
    </div>
  );
};

const styles = {
  // card: {
  //   width: "214px",
  //   border: "1px solid black",
  //   backgroundColor: "rgba(255, 255, 255, 0.1)",
  // },
  image: {
    width: "200px",
    height: "136px",
    borderRadius: "11px",
    backgroundColor: "white",
  },
  textContainer: {
    //padding: '15px',
  },
  title: {
    fontSize: "12",
    margin: "0 0 10px 0",
  },
  description: {
    fontSize: "12px",
    margin: "0 0 10px 0",
  },
  // link: {
  //   fontSize: "14px",
  //   color: "#7d3af2",
  //   textDecoration: "none",
  //   display: "block",
  //   marginBottom: "10px",
  // },
  button: {
    padding: "4px 12px",
    fontSize: "10px",
    backgroundColor: "#C9CDDB",
    color: "black",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default PreviewAdvertisementCard;
