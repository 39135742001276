import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, SelectBox } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { updateOfferStatus, updateSyncOffer } from "../../../api";
import { cooUpdateMerchantLocations } from "../../merchant/service/merchant.service";
import { updateDraft } from "../../../api/draft";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      validLocation: [],
      tag: null,
      merchantData: null,
      selectedOffer: null,
      dataForm: {
        promoCode: {
          key: "promoCode",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Promo Code",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    if (this.props.isEditPromo) {
      this.initializeMerchantData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  initializeMerchantData() {
    if (this.props.selectOffer) {
      const selectOffer = this.props.selectOffer;

      console.log({ selectOffer });
      if (selectOffer?.offerId) {
        this.setState({ selectedOffer: selectOffer });
      }
    }
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateOfferDetails = async () => {
    this.setState({ isLoading: true });
    let dataForm = this.state.dataForm;
    let body = {
      ...this.props.selectOffer,
      promoCode: dataForm?.promoCode?.value,
    };

    let resOffer = await updateSyncOffer(body, this.props.selectOffer?.offerId);
    console.log("updateSyncOffer", resOffer);
    if (resOffer && resOffer.data && resOffer.data.status) {
      this.props.closeOfferViewModal();
      console.log(resOffer);
      this.props.onClickOpenOfferView(resOffer?.data?.data);
      this.props.closeEditModal();
      this.props.refreshSyncOffers();
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isEditPromo } = this.props;
    const { viewPointHeight, dataForm, validLocation, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isEditPromo ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-400"}
          style={{
            zIndex: "999999",
            top: "9%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className={"coo_mbc__reject-header"}>{"Edit Promo Code"}</div>

            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column mt-2"}>
            <Input
              elementConfig={dataForm.promoCode.elementConfig}
              elementType={dataForm.promoCode.elementType}
              required={dataForm.promoCode.validation.required}
              isinvalid={!dataForm.promoCode.valid}
              touched={dataForm.promoCode.touched}
              value={dataForm.promoCode.value}
              isShowRequired={true}
              size={"md"}
              margin={"m-b-2"}
              label={dataForm.promoCode.label}
              changed={(event) =>
                this.inputHandleChange(event, dataForm.promoCode.key)
              }
            />
          </div>
          <div style={{ marginTop: "24px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"mb-2"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              onClick={async () => {
                await this.updateOfferDetails();
              }}
              LeftIconText={"Update"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
            <Button
              leftIcon={false}
              rightIcon={false}
              option={""}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditPromo: state.offerReducer.isEditPromo,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectMerchant: state.merchantReducer.selectMerchant,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectedBank: state.offerReducer.selectedBank,
    selectedCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    closeEditModal: () => dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickSelectedMerchantLocations: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SELECTED_LOCATIONS, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
