import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import { ButtonIcon, Tag } from "../..";
import { GetIcon } from "../../../config/icon";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);

  const handleToggleExpand = async () => {
    setIsExpand(!isExpand);
    // await onLoadMerchantOffers();
  };

  return (
    <div className={`${isExpand ? "expand" : ""}`}>
      <div
        className={`impression-card-wrapper d-flex flex-row justify-content-between align-items-center`}
        onClick={props.onClick}
      >
        <div
          className={"d-flex flex-row justify-content-start align-items-center"}
        >
          <div className={"icw__img-wrapper"}>
            <img src={props.imgUrl} alt={"credit card"} />
          </div>
          <div className={"d-flex flex-column"}>
            <div className={"icw__title"}>{props.merchantName}</div>
            <div className={"d-flex flex-wrap"} style={{ gap: "2px" }}>
              <Tag type={"companyName"} tagName={props.companyName} />
              <Tag type={"companyName"} tagName={props.bankName} />
            </div>
          </div>
        </div>

        <div
          className={
            "icw__button d-flex flex-column justify-content-center align-items-center align-content-center"
          }
          onClick={handleToggleExpand}
        >
          {GetIcon(isExpand ? "arrowUp" : "arrowDown")}
        </div>
      </div>
      {isExpand && (
        <>
          <div className={"d-flex flex-row expand-wrapper"}>
            <div className={"d-flex flex-column ew__impression"}>
              <div className={"ew__i-title"}>Impression ID</div>
              <div className={"ew__i-id"}>{props.impressionId}</div>
            </div>
            <div className={"d-flex flex-column ew__offer"}>
              <div className={"ew__o-title"}>Offer ID</div>
              <div className={"ew__o-id"}>{props.offerId}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
