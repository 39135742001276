import React, { useEffect, useState } from "react";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  CustomTag,
  InfoRowView,
  Input,
  OfferCard,
  Tag,
} from "../../index";
import moment from "moment";
import { OpenNotification } from "../../../config/notification";
import { GetIcon } from "../../../config/icon";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../store/actions/types";
import { cooGetOfferByMerchant } from "../../../containers/modal/ViewMerchantOffers/service/viewMerchantOffers.service";
import { cooGetAllBank } from "../../../containers/bank/service/bank.service";
import { cooConvertMerchantTerms } from "../../../containers/createNewItems/CreateNewMerchant/manual/service/merchant.service";
import { RiPencilLine } from "react-icons/ri";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [isSelectBank, setIsSelectBank] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [offers, setOffers] = useState(null);
  const { bankData } = useSelector(({ bankReducer }) => bankReducer);
  const dispatch = useDispatch();
  const cancel = (e) => {
    console.log(e);
  };
  const [dataForm, setDataForm] = useState({
    bank: {
      key: "bank",
      elementType: "select",
      elementConfig: {
        type: "text",
        placeholder: "",
        options: [],
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      label: "Select bank",
    },
  });

  const onLoadMerchantOffers = async () => {
    let resOfferByMerchant = await cooGetOfferByMerchant(props?.merchantId);
    if (resOfferByMerchant?.data?.data) {
      setOffers(resOfferByMerchant?.data?.data);
    }
  };

  const handleToggleExpand = async () => {
    setIsExpand(!isExpand);
    await onLoadMerchantOffers();
  };

  const confirm = (e) => {
    if (this.props.numOfOfferByMerchant > 0) {
      OpenNotification(
        "Unable to Delete Offer",
        "It seems there's an issue with deleting the offer.",
        "success"
      );
    }
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const handleBankChange = (bankId) => {
    console.log("handleBankChange", props?.termsAndCondition[bankId]);
    if (!selectedBankId) {
      setSelectedBankId(bankId);
      dispatch({
        type: actionTypes.SELECTED_EXISTING_TERMS,
        payload: { terms: props?.termsAndCondition[bankId], bankId: bankId },
      });
    } else {
      setSelectedBankId(null);
      dispatch({
        type: actionTypes.REMOVE__SELECTED_EXISTING_TERMS,
        payload: null,
      });
    }
  };

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  //==== Input Handle Change
  const inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = { ...dataForm };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "bank":
        val = event && event.target ? event.target.value : event;
        updateForm.bank.elementConfig.options.filter((x) => x.value === val);
        updatedFormElement.value = val;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    setDataForm(updateForm);
  };

  const syncBank = (res) => {
    let bankList = [];
    for (let bank of res) {
      bankList.push({
        id: bank.bankId,
        value: bank.bankId,
        displayValue: bank.bankName,
      });
    }
    return bankList;
  };

  // scrollbars start
  useEffect(() => {
    const updateForm = { ...dataForm };
    updateForm["bank"].elementConfig.options = syncBank(bankData);
    setDataForm(updateForm);
  }, []);

  const refreshBank = async (res) => {
    let resBank = await cooGetAllBank();
    const updateForm = { ...dataForm };
    console.log("resBank", resBank.data.data);
    updateForm["bank"].elementConfig.options = syncBank(
      resBank && resBank.data && resBank.data.data
    );
    setDataForm(updateForm);
  };

  const { TabPane } = Tabs;
  return (
    <div className={`${isExpand ? "expanded" : ""}`}>
      <div
        className={`cco__merchant-detail-card__wrapper  d-flex flex-row justify-content-between ${
          props.isActive ? "active-me" : ""
        } ${props.cardType} `}
      >
        <div className={"d-flex flex-row "}>
          <div
            className={"img-wrapper mdcw__merchant-log"}
            style={{
              marginRight: "12px",
            }}
          >
            <img src={props.logoURL} alt="creditcard offers" />
            {props.cardType === "category" && (
              <div className={"cco__mdcw__category-icon"}>
                {GetIcon(props?.iconName)}
              </div>
            )}
          </div>
          <div
            className={
              "d-flex flex-column justify-content-start align-items-start align-content-start ml-3"
            }
          >
            <div className={"cco__mdcw__details"}>{props.merchantName}</div>

            <div className={"cco__mdcw__name"}>
              <div>{props.merchantId}</div>
              {/*<a href={props.webURL} target={"_blank"}>*/}
              {/*  {props.webURL}*/}
              {/*</a>*/}
            </div>
            <div className={"cco__mdcw__tags d-flex flex-row"}>
              <Tag type={"new"} tagName={props?.status} />
              {props.cardType === "category" && (
                <Tag type={"defualt"} tagName={props?.iconName} />
              )}
            </div>
            <div className={"cco__mdcw__time"}>
              {moment(props.createdAt).startOf("day").fromNow()}
            </div>

            {props.location && props.location?.length > 0 && (
              <div className={"d-flex flex-wrap"} style={{ maxWidth: "600px" }}>
                {(props?.location || []).map((item, manual) => (
                  <CustomTag
                    manual={manual}
                    remove={false}
                    format={"text"}
                    style={"light"}
                    text={item.tagName}
                    onClick={{}}
                    onRemoveTime={{}}
                    key={item?.key}
                  />
                ))}
              </div>
            )}

            <div className={"cco__mdcw__terms_count sm d-flex mt-1"}>
              {props.termsAndCondition && (
                <>
                  {isArray(props.termsAndCondition) ? (
                    // <div className={"terms-old"}>
                    <Tag
                      type={"record"}
                      tagName={
                        " No.of.terms -" + props.termsAndCondition?.length
                      }
                    />
                  ) : (
                    // No.of.terms - {props.termsAndCondition?.length}

                    // <div className={"terms-update"}>
                    <Tag
                      type={"record"}
                      tagName={
                        "No.of.terms - " +
                        Object.keys(props.termsAndCondition)?.length
                      }
                    />
                    // No.of.terms -{" "}
                    // {Object.keys(props.termsAndCondition)?.length}
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className={"merchant-details-action d-flex flex-wrap"}>
          {props.userType != "user" && (
            <div className={"mr-2"}>
              <ButtonIcon
                buttonIcon={<RiPencilLine />}
                onClick={props.onClickEdit}
                type={"collapse-icon"}
                size={"lg"}
              />
            </div>
          )}
          <div>
            <ButtonIcon
              onClick={handleToggleExpand}
              buttonIcon={GetIcon(isExpand ? "arrowUp" : "arrowDown")}
              type={"collapse-icon"}
              size={"lg"}
            />
          </div>
        </div>
      </div>
      {props.cardType === "merchant" && isExpand && (
        <div className="expanded-content">
          {/* Content to show when expanded */}
          <div className={"list-view-terms_condition mt-3"}>
            {isArray(props?.termsAndCondition) ? (
              <>
                {Array.isArray(props?.termsAndCondition) &&
                  props?.termsAndCondition?.length > 0 && (
                    <div
                      className={
                        "sb__body d-flex justify-content-between align-items-center"
                      }
                    >
                      <div className={"f-width mr-1"}>
                        <Input
                          elementConfig={dataForm.bank.elementConfig}
                          elementType={dataForm.bank.elementType}
                          required={dataForm.bank.validation.required}
                          isinvalid={!dataForm.bank.valid}
                          touched={dataForm.bank.touched}
                          value={dataForm.bank.value}
                          isShowRequired={true}
                          size={"sm"}
                          margin={"m-b-2"}
                          options={bankOptions}
                          label={dataForm.bank.label}
                          changed={(event) => {
                            inputHandleChange(event, dataForm.bank.key);
                          }}
                        />
                      </div>

                      <div className={"d-flex "}>
                        {bankData && (
                          <Button
                            text={"Refresh"}
                            type={"btn-edit"}
                            size={"sm secondary mr-2"}
                            onClick={async () => {
                              await refreshBank();
                            }}
                          />
                        )}
                        {dataForm.bank.value && (
                          <Button
                            text={"Update"}
                            type={"btn-edit"}
                            size={"sm primary mr-2"}
                            onClick={async () => {
                              const body = {
                                termsAndCondition: props?.termsAndCondition,
                                bankId: null,
                              };
                              if (dataForm.bank.value) {
                                body.bankId = dataForm.bank.value;
                                console.log(body);
                                let resBank = await cooConvertMerchantTerms(
                                  body,
                                  props?.merchantId
                                );
                                //props?.refreshAll();
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                {props?.termsAndCondition &&
                  (props?.termsAndCondition || []).map((item, index) => (
                    // <InfoRowView
                    //   type="default"
                    //   text={item.value}
                    //   isSelect={item.isActive}
                    //   style={"mb-1 justify-content-between align-items-center"}
                    // />
                    <InfoRowView
                      type={"terms"}
                      text={item.value}
                      isSelect={item.isActive}
                      style={"mb-1 justify-content-between align-items-center"}
                      onClickConfirm={item.onClickDelete}
                      onClick={item.onClick}
                      onClickEdit={item.onClickEdit}
                    />
                  ))}
              </>
            ) : (
              <div>
                <div className={"cco__mtc-tab mt-2"}>
                  <Tabs defaultActiveKey="1">
                    {props.termsAndCondition &&
                      Object.keys(props.termsAndCondition).length && (
                        <>
                          {Object.keys(props.termsAndCondition)?.map(
                            (bankId) => (
                              <TabPane
                                tab={`${getBankName(bankId)}`}
                                key={bankId}
                              >
                                <div
                                  className={`cco__terms-tab-wrapper ${
                                    selectedBankId === bankId
                                      ? "selected-terms"
                                      : null
                                  }`}
                                >
                                  {props.termsAndCondition[bankId].map(
                                    (term) => (
                                      // <InfoRowView
                                      //   key={term.id}
                                      //   type="default"
                                      //   text={term.value}
                                      //   isSelect={term.isActive}
                                      //   style={
                                      //     "mb-1 justify-content-between align-items-center"
                                      //   }
                                      // />
                                      <InfoRowView
                                        key={term.id}
                                        type={"terms"}
                                        text={term.value}
                                        isSelect={term.isActive}
                                        style={
                                          "mb-1 justify-content-between align-items-center"
                                        }
                                        onClickConfirm={term.onClickDelete}
                                        onClick={term.onClick}
                                        onClickEdit={term.onClickEdit}
                                      />
                                    )
                                  )}
                                </div>
                              </TabPane>
                            )
                          )}
                        </>
                      )}
                  </Tabs>
                </div>
              </div>
            )}
          </div>

          <div className={"coo__merchant_dc_offers-wrapper"}>
            {offers && offers.length > 0 && (
              <>
                {((offers && offers) || []).map((item, index) => (
                  <OfferCard
                    offerId={item && item?.offerId}
                    details={item && item?.header}
                    termsAndCondition={item && item?.termsConditions}
                    name={item && item.merchant && item.merchant?.merchantName}
                    bankName={item && item.bank && item.bank?.bankName}
                    logo={item && item?.bank && item?.bank?.logoURL}
                    imgUrl={item.bannerImageURL}
                    userName={item && item?.user?.firstName}
                    categoryName={item && item?.category?.categoryName}
                    createAt={item && item.createdAt}
                    type={"view"}
                    isCollapse={true}
                    status={
                      item &&
                      item?.status?.charAt(0).toUpperCase() +
                        item?.status?.slice(1)
                    }
                  />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
