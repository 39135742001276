import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import { Button, Input, OfferCard, PageHeader } from "../../components";
import { AutoComplete, Spin, Tabs } from "antd";
import {
  getAdminUser,
  getBank,
  getCategory,
  getMerchant,
  getOffer,
  getOfferByBank,
  getOfferByCategoriesBanksMultiple,
  getOfferByCategory,
  getOfferByOfferHeader,
  getSessionValidateStatus,
} from "../../api";
import { OfferConfirmModal } from "../modal";
import * as actionTypes from "../../store/actions/types";
import { userDetails, userLogOut } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import Pending from "./pendingOffers/index";
import Accepted from "./acceptedOffers/index";
import Rejected from "./rejectedOffers/index";
import Drafts from "./draftsOffers/index";
import QuickOffer from "../modal/QuickOffer";
import { cooFindMerchantByName } from "../merchant/service/merchant.service";
import { OpenNotification } from "../../config/notification";
import ExpiringOffers from "./expiringOffers";
const { TabPane } = Tabs;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      isCategoryLoading: false,
      isBankLoading: false,
      isMerchantLoading: false,
      merchantId: "",
      dataSource: [],
      dataSourceFlitter: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      userDataSource: [],
      categoryOptions: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      bankTotalCount: 0,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
      option: [],
      offerOption: [],
      bankIdsList: [],
      categoryIdsList: [],
      merchantIdsList: [],
      dataForm: {
        searchOffer: {
          key: "searchOffer",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search offer title here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        keywords: {
          key: "keywords",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select merchant",
            options: [],
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Merchant",
        },
        // category: {
        //   key: "category",
        //   elementType: "input",
        //   elementConfig: {
        //     type: "text",
        //     placeholder: "Search category",
        //   },
        //   value: "",
        //   validation: {
        //     required: false,
        //   },
        //   valid: false,
        //   touched: false,
        //   label: "",
        // },
        category: {
          key: "category",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select category",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Category",
        },
        bank: {
          key: "bank",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select bank",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Bank",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Pending Offers",
          content: <Pending />,
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Accepted Offers",
          content: <Accepted />,
        },
        {
          id: uuidv4(),
          key: 3,
          tabName: "Rejected Offers",
          content: <Rejected />,
        },
        {
          id: uuidv4(),
          key: 4,
          tabName: "Draft Offers",
          content: <Drafts />,
        },
        {
          id: uuidv4(),
          key: 5,
          tabName: "Expiring",
          content: <ExpiringOffers />,
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
    await this.setAllData();
  }

  async setAllData() {
    this.setState({
      isCategoryLoading: true,
      isMerchantLoading: true,
      isBankLoading: true,
    });

    let resCategory = await getCategory();
    let resMerchant = await getMerchant();
    let resBank = await getBank();
    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["keywords"].elementConfig.options = await this.syncMerchant(
      resMerchant.data
    );
    //  this.state.merchantList = resMerchant.data;
    updateForm["bank"].elementConfig.options = await this.syncBank(
      resBank.data
    );
    updateForm["category"].value = "";
    updateForm["keywords"].value = "";
    updateForm["bank"].value = "";

    this.setState({
      dataForm: updateForm,
      isCategoryLoading: !resCategory.data.length,
      isMerchantLoading: !resMerchant.data.length,
      isBankLoading: !resBank.data.length,
    });
  }

  async syncCategory(res) {
    let categoryList = [];
    this.setState({ isCategoryLoading: true });
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
        tags: category.tags,
      });
    }
    return categoryList;
  }

  async syncMerchant(res) {
    let categoryList = [];
    for (let merchant of res) {
      categoryList.push({
        id: merchant.merchantId,
        value: merchant.merchantName,
        displayValue: merchant.merchantName,
        logo: merchant.logoURL,
        location: merchant.location,
        termsAndCondition: merchant.termsAndCondition,
      });
    }
    return categoryList;
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        id: data.bankId,
        value: data.bankName,
        displayValue: data.bankName,
        logoURL: data.logoURL,
        cardTypes: data.cardTypes,
      });
    }
    return resList;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true });
      let user = await userDetails();
      if (user && user.userId) {
        let resUser = await getAdminUser();

        this.setState({
          userDataSource: resUser && resUser.data,
          isLoading: false,
        });
      }
      this.props.doneRefresh();
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  autoCompleteInputHandleChange = (event, key) => {
    const updatedDataForm = {
      ...this.state.dataForm,
      [key]: {
        ...this.state.dataForm[key],
        value: event,
        touched: true,
      },
    };
    this.setState({ dataForm: updatedDataForm });
  };

  async fetchSuggestions(query) {
    console.log("query", query);
    if (!query) {
      console.log("query", query.length);
      this.setState({ option: [] });
      return;
    }
    try {
      const response = await cooFindMerchantByName(query?.trim());
      console.log("merchantSearch", response);
      if (response?.data?.data?.length > 0) {
        const suggestions = response?.data?.data?.map((merchant) => ({
          id: merchant.merchantId,
          value: merchant.merchantName,
          label: merchant.merchantName,
        }));
        this.setState({ option: suggestions });
      } else {
        this.setState({ option: [] });
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  async fetchOfferSuggestions(query) {
    console.log("query", query);
    if (query && query != "") {
      const response = await getOfferByOfferHeader(query?.trim());
      console.log("merchantSearch", response);

      if (response?.data?.length > 0) {
        const suggestions = response?.data?.map((offer) => ({
          id: offer.offerId,
          value: offer.header,
          label: offer.header,
        }));
        this.setState({ offerOption: suggestions });
        this.setState({
          dataSource: response?.data,
        });
      } else {
        let updateForm = this.state.dataForm;
        updateForm["searchOffer"].value = "";
        updateForm["searchOffer"].touched = false;

        this.setState({
          dataForm: updateForm,
          dataSource: [],
          offerOption: [],
        });
      }
    } else {
      console.log("query", query.length);

      this.setState({
        dataSource: [],
        offerOption: [],
      });
      return;
    }
  }

  async fetchOfferByBankId(bankId, pageNo, pageSize) {
    console.log("query", bankId);
    this.setState({
      isLoading: true,
    });
    if (bankId && bankId != "") {
      const response = await getOfferByBank(bankId, pageNo, pageSize);
      console.log("getOfferByBank::", response);

      if (response?.data?.length > 0) {
        this.setState((prevState) => ({
          dataSourceFlitter: [
            ...prevState.dataSourceFlitter,
            ...response?.data,
          ],
          pageNo: pageNo,
          pageSize: pageSize,
          bankTotalCount: response?.totalCount,
          isLoading: false,
        }));
      } else {
        OpenNotification("error", response && response.message, "error");
        this.setState({
          dataSourceFlitter: [],
          pageNo: 1,
          pageSize: 10,
          bankTotalCount: 0,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        dataSourceFlitter: [],
        pageNo: 1,
        pageSize: 10,
        bankTotalCount: 0,
        isLoading: false,
      });
      return;
    }
  }

  async fetchOfferByCategoryId(categoryId, pageNo, pageSize) {
    console.log("query", categoryId);
    this.setState({
      isLoading: true,
    });
    if (categoryId && categoryId != "") {
      const response = await getOfferByCategory(categoryId, pageNo, pageSize);
      console.log("getOfferByCategory::", response);

      if (response?.data?.length > 0) {
        // const suggestions = response?.data?.map((offer) => ({
        //   id: offer.offerId,
        //   value: offer.header,
        //   label: offer.header,
        // }));
        // this.setState({ offerOption: suggestions });
        this.setState((prevState) => ({
          dataSourceFlitter: [
            ...prevState.dataSourceFlitter,
            ...response?.data,
          ],
          pageNo: pageNo,
          pageSize: pageSize,
          bankTotalCount: response?.totalCount,
          isLoading: false,
        }));
      } else {
        OpenNotification("error", response && response.message, "error");
        this.setState({
          dataSourceFlitter: [],
          pageNo: 1,
          pageSize: 10,
          bankTotalCount: 0,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        dataSourceFlitter: [],
        pageNo: 1,
        pageSize: 10,
        bankTotalCount: 0,
        isLoading: false,
      });
      return;
    }
  }

  onSelectAutoComplete(data) {
    console.log("onSelect", data);

    if (data) {
      let updateForm = this.state.dataForm;
      let merchantData = updateForm["keywords"].elementConfig.options.find(
        (x) => x.displayValue === data
      );
      this.setState({
        merchantId: merchantData?.id,
      });
    } else {
      this.setState({
        merchantId: "",
      });
    }
  }

  async onSearchFilter() {
    this.setState({
      isLoading: true,
      dataSourceFlitter: [],
    });

    try {
      let body = {
        bankIds: this.state.bankIdsList,
        categoryIds: this.state.categoryIdsList,
        merchantIds: this.state.merchantIdsList,
      };

      let res = await getOfferByCategoriesBanksMultiple(body, 1, 100);
      if (res && res.status) {
        const offers = await this.resetOffers(
          res && res.data,
          this.state.userDataSource
        );
        console.log("offers", res);
        this.setState((prevState) => ({
          dataSourceFlitter: [...prevState.dataSourceFlitter, ...offers],
          totalCount: res?.totalCount,
          isLoading: false,
        }));
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dataForm.keywords.value !== this.state.dataForm.keywords.value
    ) {
      if (
        this.state.dataForm.keywords.value === "" &&
        this.state.pendingOffers.length === 0
      ) {
        this.setState({ isLoading: true });
        await this.getAll();
      }
    }
    if (
      prevState.dataForm.searchOffer.value !==
      this.state.dataForm.searchOffer.value
    ) {
      if (this.state.dataForm.searchOffer.value === "") {
        let updateForm = this.state.dataForm;
        updateForm["searchOffer"].value = "";
        updateForm["searchOffer"].touched = false;

        this.setState({
          dataForm: updateForm,
          dataSource: [],
          offerOption: [],
        });
      }
    }
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      this.setState({ isLoading: true });
      await this.getAll();
      if (!this.state.isLoading) {
        this.props.doneRefresh();
      }
    }
  }

  render() {
    const {
      dataSource,
      tags,
      viewPointHeight,
      pendingOffers,
      acceptedOffers,
      isLoading,
      rejectedOffers,
      userDataSource,
      categoryOptions,
      dataForm,
      tabJson,
      option,
      offerOption,
      isCategoryLoading,
      isBankLoading,
      isMerchantLoading,
      dataSourceFlitter,
    } = this.state;
    return (
      <>
        <OfferConfirmModal />
        {/*<QuickOffer />*/}

        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={true}
            isLoading={isLoading}
            isDate={false}
            onClickRefresh={async () => {
              this.props.refreshOffersList();
              // await this.getAll();
            }}
            title={"Offers"}
          />
        </div>
        <div
          className={
            "search-bar-wrapper mt-1 d-flex flex-row justify-content-between align-content-center"
          }
          style={{ paddingBottom: "0" }}
        >
          <div className="d-flex flex-column gap-2" style={{ width: "100%" }}>
            <AutoComplete
              value={dataForm.searchOffer.value}
              options={offerOption}
              style={{ width: "100%", marginRight: "8px" }}
              onChange={(event) =>
                this.autoCompleteInputHandleChange(
                  event,
                  dataForm.searchOffer.key
                )
              }
              onSearch={async () => {
                await this.fetchOfferSuggestions(dataForm.searchOffer.value);
              }}
              placeholder="Search offer header here..."
            />
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.category.elementConfig}
                  elementType={dataForm.category.elementType}
                  required={dataForm.category.validation.required}
                  isValid={!dataForm.category.valid}
                  touched={dataForm.category.touched}
                  value={dataForm.category.value}
                  defaultValue={null}
                  label={dataForm.category.label}
                  isShowRequired={false}
                  size={"lg"}
                  isLoading={isCategoryLoading}
                  margin={"ml-2"}
                  options={[]}
                  onSearch={(event) => {
                    this.handleSearch(event, dataForm.category.key);
                  }}
                  // onClickAddTag={this.onClickAddHashTag.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.category.key);
                    let category = dataForm.category.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    console.log({ category });
                    if (category) {
                      updateList.push(category?.id);
                    }
                    await this.setState({ categoryIdsList: updateList });
                    if (!category) {
                      if (
                        this.state.bankIdsList?.length > 0 ||
                        this.state.merchantIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.bank.elementConfig}
                  elementType={dataForm.bank.elementType}
                  required={dataForm.bank.validation.required}
                  isValid={!dataForm.bank.valid}
                  touched={dataForm.bank.touched}
                  value={dataForm.bank.value}
                  defaultValue={null}
                  label={dataForm.bank.label}
                  isLoading={isBankLoading}
                  isShowRequired={false}
                  size={"lg"}
                  margin={"ml-2"}
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  // onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.bank.key);
                    let bank = dataForm.bank.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    console.log({ bank });
                    if (bank) {
                      updateList.push(bank?.id);
                    }
                    await this.setState({ bankIdsList: updateList });
                    if (!bank) {
                      if (
                        this.state.categoryIdsList?.length > 0 ||
                        this.state.merchantIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.keywords.elementConfig}
                  elementType={dataForm.keywords.elementType}
                  required={dataForm.keywords.validation.required}
                  isValid={!dataForm.keywords.valid}
                  touched={dataForm.keywords.touched}
                  value={dataForm.keywords.value}
                  defaultValue={null}
                  label={dataForm.keywords.label}
                  isLoading={isMerchantLoading}
                  isShowRequired={false}
                  size={"lg"}
                  margin={"ml-2"}
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  // onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.keywords.key);
                    let merchant = dataForm.keywords.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    if (merchant) {
                      updateList.push(merchant?.id);
                    }
                    await this.setState({ merchantIdsList: updateList });
                    if (!merchant) {
                      if (
                        this.state.bankIdsList?.length > 0 ||
                        this.state.categoryIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "search-action-left-wrp d-flex flex-column justify-content-center gap-2 align-items-center"
            }
          >
            <Button
              text={"Search"}
              type={"btn-edit"}
              size={"lg secondary"}
              disabled={
                this.state.bankIdsList?.length === 0 &&
                this.state.categoryIdsList?.length === 0 &&
                this.state.merchantIdsList?.length === 0
              }
              onClick={async () => {
                await this.onSearchFilter();
              }}
            />
            <Button
              text={"Cancel"}
              type={"btn-edit"}
              size={"lg secondary mr-2 ml-2"}
              onClick={() => {
                let updateForm = this.state.dataForm;
                updateForm["searchOffer"].value = "";
                updateForm["searchOffer"].touched = false;
                updateForm["keywords"].value = "";
                updateForm["keywords"].touched = false;
                updateForm["category"].value = "";
                updateForm["category"].touched = false;
                updateForm["bank"].value = "";
                updateForm["bank"].touched = false;

                this.setState({
                  dataForm: updateForm,
                  dataSource: [],
                  dataSourceFlitter: [],
                  offerOption: [],
                  option: [],
                  bankIdsList: [],
                  categoryIdsList: [],
                  merchantIdsList: [],
                  merchantId: "",
                });
              }}
            />
          </div>
        </div>
        {offerOption?.length === 0 &&
          dataForm.searchOffer.value === "" &&
          dataSource?.length === 0 &&
          dataSourceFlitter?.length === 0 && (
            <div className={"cco__offers-tab-bar"}>
              <Tabs>
                {((tabJson && tabJson) || []).map((i, t) => (
                  <TabPane tab={i.tabName} key={i.key}>
                    {this.state.isLoading && (
                      <div style={{ margin: "20% 50%", opacity: 0.5 }}>
                        <Spin size="large" spinning={isLoading} />
                      </div>
                    )}
                    {!this.state.isLoading && (
                      <>
                        {i.key === 1 ? (
                          <Pending
                            searchOfferTitle={
                              this.state.dataForm.searchOffer.value
                            }
                            selectMerchantId={this.state.merchantId}
                          />
                        ) : i.key === 2 ? (
                          <Accepted
                            searchOfferTitle={
                              this.state.dataForm.searchOffer.value
                            }
                            selectMerchantId={this.state.merchantId}
                          />
                        ) : i.key === 3 ? (
                          <Rejected
                            searchOfferTitle={
                              this.state.dataForm.searchOffer.value
                            }
                            selectMerchantId={this.state.merchantId}
                          />
                        ) : i.key === 4 ? (
                          <Drafts
                            searchOfferTitle={
                              this.state.dataForm.searchOffer.value
                            }
                            selectMerchantId={this.state.merchantId}
                          />
                        ) : i.key === 5 &&
                          this.state.userDataSource?.userType != "user" ? (
                          <ExpiringOffers
                            searchOfferTitle={
                              this.state.dataForm.searchOffer.value
                            }
                            selectMerchantId={this.state.merchantId}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          )}
        {offerOption?.length > 0 &&
          dataForm.searchOffer.value != "" &&
          dataSourceFlitter?.length === 0 &&
          dataSource.length > 0 && (
            <Scrollbars
              style={{ height: viewPointHeight - 80 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"cco__offer-page-body-wrapper"}>
                <>
                  {(dataSource || []).map((item, index) => (
                    <OfferCard
                      details={item && item.header}
                      name={item && item.merchant && item.merchant.merchantName}
                      bankName={item && item.bank && item.bank.bankName}
                      categoryName={item && item?.category?.categoryName}
                      logo={item && item.bank && item.bank.logoURL}
                      imgUrl={item.bannerImageURL}
                      userName={item && item?.user?.firstName}
                      createAt={item && item.createdAt}
                      status={
                        item &&
                        item?.status?.charAt(0).toUpperCase() +
                          item?.status?.slice(1)
                      }
                      onClickEdit={() => {
                        this.props.onClickClearSelected();
                        this.props.onClickSelectOffer(item);
                        this.props.onClickSelectedMerchant(item?.merchant);
                      }}
                      onClickViewOffer={
                        () => this.props.onClickOpenOfferView(item)
                        //console.log("event fire");
                      }
                    />
                  ))}
                </>
              </div>
            </Scrollbars>
          )}
        {dataSourceFlitter.length > 0 && (
          <Scrollbars
            style={{ height: viewPointHeight - 150 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"cco__offer-page-body-wrapper"}>
              <>
                {(dataSourceFlitter || []).map((item, index) => (
                  <OfferCard
                    details={item && item.header}
                    name={item && item.merchant && item.merchant.merchantName}
                    bankName={item && item.bank && item.bank.bankName}
                    categoryName={item && item?.category?.categoryName}
                    logo={item && item.bank && item.bank.logoURL}
                    imgUrl={item.bannerImageURL}
                    userName={item && item?.user?.firstName}
                    createAt={item && item.createdAt}
                    status={
                      item &&
                      item?.status?.charAt(0).toUpperCase() +
                        item?.status?.slice(1)
                    }
                    onClickEdit={() => {
                      this.props.onClickClearSelected();
                      this.props.onClickSelectOffer(item);
                      this.props.onClickSelectedMerchant(item?.merchant);
                    }}
                    onClickViewOffer={
                      () => this.props.onClickOpenOfferView(item)
                      //console.log("event fire");
                    }
                  />
                ))}
                {!isLoading &&
                  this.state.bankTotalCount -
                    this.state.pageNo * this.state.pageViewCount >
                    0 && (
                    <div
                      className={
                        "console__load-more-wrp d-flex justify-content-center mt-3 "
                      }
                    >
                      <Button
                        size={"sm"}
                        type={"btn-primary light"}
                        isLoading={isLoading}
                        onClick={async () => {
                          if (dataSourceFlitter?.length > 0) {
                            let bankData =
                              dataForm.bank.elementConfig.options.find(
                                (bank) =>
                                  bank.displayValue === dataForm.bank.value
                              );
                            console.log({ bankData });
                            await this.fetchOfferByBankId(
                              bankData?.id,
                              this.state.pageNo + 1,
                              10
                            );
                          }
                        }}
                        text={`Load more offers (${
                          this.state.bankTotalCount -
                          this.state.pageNo * this.state.pageViewCount
                        })`}
                      />
                    </div>
                  )}
              </>
            </div>
          </Scrollbars>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    refreshOffersList: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
