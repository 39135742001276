import React, {Component} from "react";
import './styles.scss'
import {cooGetAllAllObjectsFromS3Bucket} from "./service/media.service";
import {Scrollbars} from "react-custom-scrollbars";
import {connect} from "react-redux";
import {GetIcon} from "../../config/icon";
import {v4 as uuidv4} from "uuid";
import { Tabs } from "antd";
import Manual from "./mediaInfo/";
import Upload from "./upload/";
const { TabPane } = Tabs;

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPointWidth: 0,
            viewPointHeight: 0,
            isActiveShadow: false,
            isLoading: false,
            dataSource: [],
            tabJson: [
                {
                    id: uuidv4(),
                    key: 1,
                    tabName: "Media Info",
                    content: <Manual />,
                },
                {
                    id: uuidv4(),
                    key: 2,
                    tabName: "Upload",
                    content: <Upload />,
                },
            ],
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    };

    updateWindowDimensions() {
        this.setState({viewPointWidth: window.innerWidth, viewPointHeight: window.innerHeight});
        //window.removeEventListener('scroll', this.handleOnScroll);
    }

    handleOnScroll = () => {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        console.log("scrollTop : " + scrollTop);
        //console.log("scrollHeight : " + scrollHeight);
        //console.log("clientHeight :" + clientHeight);
    };

    async componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.handleOnScroll);
        await this.getAll()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleOnScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async getAll() {
        this.setState({isLoading: true});

        let response = await cooGetAllAllObjectsFromS3Bucket();
        console.log('get_all_media ==>', response.data.data);
        this.setState({
            dataSource: response && response.data && response.data.data,
            isLoading: false
        })
    }

    async onClickViewMoreInfo(item) {
        console.log('media_info', item);
    }


    render() {
        const {dataSource, tags, isLoading, viewPointHeight,tabJson} = this.state;
        const {selectMediaData, isActive} = this.props;
        console.log('selectMediaData', selectMediaData);
        return (
            <>
                <div className={'cco__page-header-wrapper'}>

                    <div className={'cco__page-body-wrapper'}>
                        <Scrollbars
                            style={{height: viewPointHeight - 80}}
                            thumbMinSize={30}
                            universal={true}
                            autoHide>
                            <Tabs>
                                {((tabJson && tabJson) || []).map((i, t) => (
                                    <TabPane tab={i.tabName} key={i.key}>
                                        <>{i.content}</>
                                    </TabPane>
                                ))}
                            </Tabs>

                        </Scrollbars>

                    </div>
                </div>

            </>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            selectMediaData: state.mediaReducer.selectMediaData,
            isActive: state.mediaReducer.isActive,
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {};
    };

export default connect(mapStateToProps, mapDispatchToProps)(index);



