import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, SelectBox, Tag } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { updateOffersMultiple, updateOfferStatus } from "../../../api";
import { cooUpdateMerchantLocations } from "../../merchant/service/merchant.service";
import { updateDraft } from "../../../api/draft";
import moment from "moment";
import { OpenNotification } from "../../../config/notification";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      merchantData: null,
      dataForm: {
        offerRate: {
          key: "offerRate",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer Rate (%) ",
        },
        offerType: {
          key: "offerType",
          elementType: "",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [
              { id: uuid(), value: "off", isActive: false },
              { id: uuid(), value: "upto", isActive: false },
              { id: uuid(), value: "discount", isActive: false },
              { id: uuid(), value: "customize", isActive: false },
            ],
            disabled: false,
          },
          value: "off",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer category",
        },
        offerValidity: {
          key: "offerValidity",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [
              { id: uuid(), key: "fromTo", value: "From - To" },
              { id: uuid(), key: "until", value: "Until" },
              { id: uuid(), key: "till", value: "till" },
              { id: uuid(), key: "on", value: "on" },
              { id: uuid(), key: "customize", value: "Customize" },
              { id: uuid(), key: "other", value: "Other" },
            ],
            disabled: false,
          },
          value: "fromTo",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Offer Validity",
        },
        endDate: {
          key: "endDate",
          elementType: "datePicker",
          elementConfig: {
            label: "End Date",
            size: "md",
            width: "100%",
            placeholder: "DD-MM-YYYY",
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "End date",
        },
        startDate: {
          key: "startDate",
          elementType: "datePicker",
          elementConfig: {
            label: "End Date",
            size: "md",
            width: "100%",
            placeholder: "DD-MM-YYYY",
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Start date",
        },
        validDate: {
          key: "validDate",
          elementType: "datePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid date",
            disabled: false,
          },
          value: "",
          dateValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Valid date",
        },
        startTime: {
          key: "startTime",
          elementType: "timePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid time",
            disabled: false,
          },
          value: "",
          timeValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Start time",
        },
        endTime: {
          key: "endTime",
          elementType: "timePicker",
          elementConfig: {
            type: "text",
            placeholder: "Valid time",
            disabled: false,
          },
          value: "",
          timeValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "End time",
        },
        validTimeDateDescription: {
          key: "validTimeDateDescription",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Happy hour",
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          label: "Valid (Time/Date) Description",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (value && rules.required) {
      isValid = value?.trim() !== "" && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    let formatDate = "";
    if (
      inputIdentity === "startDate" ||
      inputIdentity === "endDate" ||
      inputIdentity === "validDate"
    ) {
      const parsedDate = moment(event._d, "YYYY-MM-DD");

      formatDate = parsedDate.format("YYYY-MM-DD");
    }
    if (inputIdentity === "startTime" || inputIdentity === "endTime") {
      const parsedDate = moment(event._d, "DD-MM-YYYY");

      formatDate = parsedDate.format("YYYY-MM-DD");
    }
    switch (inputIdentity) {
      case "endDate":
        let dat_val = moment(event._d).format("YYYY-MM-DD");
        console.log("endDate", dat_val);
        //console.log("inputHandleChange", moment(event._d).format("DD-MM-YYYY"));
        updatedFormElement.value = event;
        updatedFormElement.dateValue = dat_val;
        break;
      case "startDate":
        let sdat_val = moment(event._d).format("YYYY-MM-DD");
        updatedFormElement.value = event;
        updatedFormElement.dateValue = sdat_val;
        break;
      case "validDate":
        let vdat_val = moment(event._d).format("YYYY-MM-DD");
        updatedFormElement.value = event;
        updatedFormElement.dateValue = vdat_val;
        break;
      case "startTime":
        let stime_val = moment(event._d).format("h:mm a");
        updatedFormElement.value = event;
        updatedFormElement.timeValue = stime_val;
        break;
      case "endTime":
        let etime_val = moment(event._d).format("h:mm a");
        updatedFormElement.value = event;
        updatedFormElement.timeValue = etime_val;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }
    if (
      inputIdentity === "offerRate" ||
      inputIdentity === "validTimeDateDescription"
    ) {
      updatedFormElement.valid = this.checkValidity(
        updatedFormElement.value,
        updatedFormElement.validation
      );
    } else {
      updatedFormElement.valid = true;
    }
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateOfferDetails = async () => {
    let dataForm = this.state.dataForm;
    if (dataForm["offerRate"].value === "") {
      const updateSignUpForm = this.state.dataForm;
      const updatedFormElement = updateSignUpForm["offerRate"];
      updatedFormElement.valid = false;
      updatedFormElement.touched = true;
      updateSignUpForm["offerRate"] = updatedFormElement;
      this.setState({ dataForm: updateSignUpForm });
      return;
    }

    if (dataForm["offerValidity"].value === "fromTo") {
      if (!dataForm.startDate.value === "" && !dataForm.endDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElement = updateSignUpForm["startDate"];
        const updatedFormElementEnd = updateSignUpForm["endDate"];
        updatedFormElement.valid = false;
        updatedFormElement.touched = true;
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["startDate"] = updatedFormElement;
        updateSignUpForm["endDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
      if (dataForm.startDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElement = updateSignUpForm["startDate"];
        updatedFormElement.valid = false;
        updatedFormElement.touched = true;
        updateSignUpForm["startDate"] = updatedFormElement;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
      if (dataForm.endDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["endDate"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["endDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
    }
    if (dataForm["offerValidity"].value === "until") {
      if (dataForm.endDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["endDate"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["endDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
    }

    if (dataForm["offerValidity"].value === "till") {
      if (dataForm.endDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["endDate"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["endDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
    }

    if (dataForm["offerValidity"].value === "on") {
      if (dataForm.endDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["endDate"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["endDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
    }
    if (dataForm["offerValidity"].value === "customize") {
      if (dataForm.validDate.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["validDate"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["validDate"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
      if (dataForm.startTime.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["startTime"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["startTime"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
      if (dataForm.endTime.value === "") {
        const updateSignUpForm = this.state.dataForm;
        const updatedFormElementEnd = updateSignUpForm["endTime"];
        updatedFormElementEnd.valid = false;
        updatedFormElementEnd.touched = true;
        updateSignUpForm["endTime"] = updatedFormElementEnd;
        this.setState({ dataForm: updateSignUpForm });
        return;
      }
    }
    this.setState({ isLoading: true });
    let body = {
      offerIds: this.props.selectOfferIds || [],
      offerType: dataForm.offerType.value,
      offerRate: dataForm.offerRate.value,
      offerDateType: dataForm.offerValidity.value,
      offerStart: dataForm.startDate.dateValue,
      offerEnd: dataForm.endDate.dateValue,
      validDate: dataForm.validDate.dateValue,
      startTime: dataForm.startTime.timeValue,
      endTime: dataForm.endTime.timeValue,
      validTimeDateDescription: dataForm.validTimeDateDescription.value,
    };

    let res = await updateOffersMultiple(body);
    console.log("updateOffersMultiple", res);
    if (res && res.data && res.data.status) {
      this.setState({ isLoading: false });

      this.props.refreshOffersList();
      OpenNotification(
        "success",
        res && res.data && res.data.message,
        "success"
      );
      this.props.closeEditModal();
    } else {
      this.setState({ isLoading: false });
    }
  };

  onSelectOffer = (_item, i) => {
    const updateForm = { ...this.state.dataForm };
    updateForm["offerType"].value = _item.value;
    this.setState({ dataForm: updateForm, formIsValid: false });
  };

  onSelectOption = (_item, key) => {
    const updateForm = { ...this.state.dataForm };
    updateForm[key].value = _item.key;
    this.setState({ dataForm: updateForm, formIsValid: false });
  };

  render() {
    const { isUpdateOfferModal, selectOfferIds } = this.props;
    const { viewPointHeight, dataForm, validLocation, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isUpdateOfferModal ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-400"}
          style={{
            zIndex: "999999",
            top: "8%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className="d-flex flex-column">
              <div className={"coo_mbc__reject-header"}>{"Update offers"}</div>
              <div className="coo_mbc__subtitle">
                No of offer selected : {selectOfferIds?.length || 0}
              </div>
            </div>
            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"m-1"}>
            <Scrollbars
              style={{
                height:
                  dataForm.offerValidity.value === "other" ||
                  dataForm.offerValidity.value === "customize"
                    ? viewPointHeight - 320
                    : dataForm.offerValidity.value === ""
                    ? viewPointHeight - 470
                    : viewPointHeight - 400,
              }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <label>
                Offer details <b> *</b>
              </label>
              {dataForm.offerType.elementConfig.options && (
                <>
                  <div className={"multiple-line-wrapper mt-1 mb-2"}>
                    <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                      {(dataForm.offerType.elementConfig.options || []).map(
                        (item, index) => (
                          <SelectBox
                            key={index}
                            index={index}
                            remove={false}
                            isSelect={dataForm.offerType.value === item.value}
                            select={true}
                            onClick={() => this.onSelectOffer(item, index)}
                            onRemove={() => this.onRemove(item, index)}
                            format={"text"}
                            text={item.value}
                          />
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
              {dataForm.offerType.value && (
                <Input
                  elementConfig={dataForm.offerRate.elementType}
                  elementType={dataForm.offerRate.elementType}
                  required={dataForm.offerRate.validation.required}
                  isValid={!dataForm.offerRate.valid}
                  touched={dataForm.offerRate.touched}
                  value={dataForm.offerRate.value}
                  isShowRequired={false}
                  size={"md"}
                  margin={"m-b-2"}
                  label={
                    dataForm.offerType.value === "customize"
                      ? "Offer description"
                      : dataForm.offerRate.label +
                        "- " +
                        dataForm.offerType.value
                  }
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.offerRate.key)
                  }
                />
              )}

              <label>
                Offer validity <b> *</b>
              </label>
              {dataForm.offerValidity.elementConfig.options && (
                <>
                  <div className={"multiple-line-wrapper  mb-2"}>
                    <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                      {(dataForm.offerValidity.elementConfig.options || []).map(
                        (item, index) => (
                          <SelectBox
                            index={index}
                            remove={false}
                            isSelect={dataForm.offerValidity.value === item.key}
                            select={true}
                            onClick={() =>
                              this.onSelectOption(item, "offerValidity")
                            }
                            onRemove={() => this.onRemove(item, index)}
                            format={"text"}
                            text={item.value}
                          />
                        )
                      )}
                    </div>
                  </div>
                </>
              )}

              {dataForm.offerValidity.value === "until" && (
                <div className={"mt-2"}>
                  <Input
                    elementConfig={dataForm.endDate.elementConfig}
                    elementType={dataForm.endDate.elementType}
                    required={dataForm.endDate.validation.required}
                    isValid={!dataForm.endDate.valid}
                    touched={dataForm.endDate.touched}
                    value={dataForm.endDate.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.endDate.label}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.endDate.key)
                    }
                  />

                  {dataForm.endDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.endDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {dataForm.offerValidity.value === "till" && (
                <div className={"mt-2"}>
                  <Input
                    elementConfig={dataForm.endDate.elementConfig}
                    elementType={dataForm.endDate.elementType}
                    required={dataForm.endDate.validation.required}
                    isValid={!dataForm.endDate.valid}
                    touched={dataForm.endDate.touched}
                    value={dataForm.endDate.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.endDate.label}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.endDate.key)
                    }
                  />

                  {dataForm.endDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.endDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {dataForm.offerValidity.value === "on" && (
                <div className={"mt-2"}>
                  <Input
                    elementConfig={dataForm.endDate.elementConfig}
                    elementType={dataForm.endDate.elementType}
                    required={dataForm.endDate.validation.required}
                    isValid={!dataForm.endDate.valid}
                    touched={dataForm.endDate.touched}
                    value={dataForm.endDate.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={"Valid date"}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.endDate.key)
                    }
                  />
                </div>
              )}

              {dataForm.offerValidity.value === "fromTo" && (
                <div className={"mt-2"}>
                  <Input
                    elementConfig={dataForm.startDate.elementConfig}
                    elementType={dataForm.startDate.elementType}
                    required={dataForm.startDate.validation.required}
                    isValid={!dataForm.startDate.valid}
                    touched={dataForm.startDate.touched}
                    value={dataForm.startDate.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"mb-1"}
                    label={dataForm.startDate.label}
                    onChange={(event, date) => {
                      this.inputHandleChange(event, dataForm.startDate.key);
                    }}
                  />
                  {dataForm.startDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.startDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                  <Input
                    elementConfig={dataForm.endDate.elementConfig}
                    elementType={dataForm.endDate.elementType}
                    required={dataForm.endDate.validation.required}
                    isValid={!dataForm.endDate.valid}
                    touched={dataForm.endDate.touched}
                    value={dataForm.endDate.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.endDate.label}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.endDate.key)
                    }
                  />
                  {dataForm.endDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.endDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {dataForm.offerValidity.value === "customize" && (
                <div className={"customize-date-wrapper " + "mt-2"}>
                  <Input
                    elementConfig={dataForm.validDate.elementConfig}
                    elementType={dataForm.validDate.elementType}
                    required={dataForm.validDate.validation.required}
                    isValid={!dataForm.validDate.valid}
                    touched={dataForm.validDate.touched}
                    value={dataForm.validDate.value}
                    isShowRequired={true}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.validDate.label}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.validDate.key)
                    }
                  />
                  {dataForm.validDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.validDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                  <div className={"d-flex"}>
                    <div className={"col-6 pl-0 pr-0"}>
                      <Input
                        elementConfig={dataForm.startTime.elementConfig}
                        elementType={dataForm.startTime.elementType}
                        required={dataForm.startTime.validation.required}
                        isValid={!dataForm.startTime.valid}
                        touched={dataForm.startTime.touched}
                        value={dataForm.startTime.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.startTime.label}
                        onChange={(event, date) =>
                          this.inputHandleChange(event, dataForm.startTime.key)
                        }
                      />
                      {dataForm.startTime.timeValue != "" && (
                        <div className="mt-2">
                          <Tag
                            type={"pending"}
                            tagName={moment(
                              dataForm.startTime.timeValue
                            ).format("DD-MM-YYYY")}
                          />
                        </div>
                      )}
                    </div>
                    <div className={"col-6 pr-0"}>
                      <Input
                        elementConfig={dataForm.endTime.elementConfig}
                        elementType={dataForm.endTime.elementType}
                        required={dataForm.endTime.validation.required}
                        isValid={!dataForm.endTime.valid}
                        touched={dataForm.endTime.touched}
                        value={dataForm.endTime.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.endTime.label}
                        onChange={(event, date) =>
                          this.inputHandleChange(event, dataForm.endTime.key)
                        }
                      />

                      {dataForm.endTime.timeValue != "" && (
                        <div className="mt-2">
                          <Tag
                            type={"pending"}
                            tagName={moment(dataForm.endTime.timeValue).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className={"rich-text-editor-wrapper"}>
                              <RichTextComponent />
                             </div> */}
                  <Input
                    elementConfig={
                      dataForm.validTimeDateDescription.elementConfig
                    }
                    elementType={dataForm.validTimeDateDescription.elementType}
                    required={
                      dataForm.validTimeDateDescription.validation.required
                    }
                    isValid={!dataForm.validTimeDateDescription.valid}
                    touched={dataForm.validTimeDateDescription.touched}
                    value={dataForm.validTimeDateDescription.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.validTimeDateDescription.label}
                    changed={(event) =>
                      this.inputHandleChange(
                        event,
                        dataForm.validTimeDateDescription.key
                      )
                    }
                  />

                  {/*<Button*/}
                  {/*    leftIcon={false}*/}
                  {/*    rightIcon={false}*/}
                  {/*    isLoading={isLoading || isImageUploading}*/}
                  {/*    isActiveAnimation={false}*/}
                  {/*    reactRightIcon={GetIcon('leftArrow')}*/}
                  {/*    onClick={async () => {*/}
                  {/*        await this.onClickAddToList();*/}
                  {/*    }}*/}
                  {/*    text={'Add to list'}*/}
                  {/*    size={'md'}*/}
                  {/*    type={'btn-block-back'}/>*/}
                </div>
              )}
              {dataForm.offerValidity.value === "other" && (
                <div className={"customize-date-wrapper " + ".mt-2"}>
                  <Input
                    elementConfig={dataForm.endDate.elementConfig}
                    elementType={dataForm.endDate.elementType}
                    required={dataForm.endDate.validation.required}
                    isValid={!dataForm.endDate.valid}
                    touched={dataForm.endDate.touched}
                    value={dataForm.endDate.value}
                    isShowRequired={true}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.endDate.label}
                    onChange={(event, date) =>
                      this.inputHandleChange(event, dataForm.endDate.key)
                    }
                  />

                  {dataForm.endDate.dateValue != "" && (
                    <div className="mt-2">
                      <Tag
                        type={"pending"}
                        tagName={moment(dataForm.endDate.dateValue).format(
                          "DD-MM-YYYY"
                        )}
                      />
                    </div>
                  )}
                  <Input
                    elementConfig={
                      dataForm.validTimeDateDescription.elementConfig
                    }
                    elementType={dataForm.validTimeDateDescription.elementType}
                    required={
                      dataForm.validTimeDateDescription.validation.required
                    }
                    isValid={!dataForm.validTimeDateDescription.valid}
                    touched={dataForm.validTimeDateDescription.touched}
                    value={dataForm.validTimeDateDescription.value}
                    isShowRequired={false}
                    size={"md"}
                    margin={"m-b-2"}
                    label={dataForm.validTimeDateDescription.label}
                    changed={(event) =>
                      this.inputHandleChange(
                        event,
                        dataForm.validTimeDateDescription.key
                      )
                    }
                  />
                </div>
              )}
            </Scrollbars>
          </div>
          <div
            className="d-flex flex-row justify-content-end align-items-center"
            style={{ marginTop: "24px" }}
          >
            <Button
              leftIconFill={true}
              iconName={<RiCloseLine />}
              rightIcon={false}
              option={"p-3 mr-1"}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={""}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-edit lg"}
            />
            <div style={{ width: "140px" }}>
              <Button
                leftIconFill={true}
                iconName={<RiCheckLine />}
                leftIcon={false}
                rightIcon={false}
                option={" p-2"}
                isLoading={isLoading}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("")}
                onClick={async () => {
                  await this.updateOfferDetails();
                }}
                LeftIconText={"Update"}
                text={""}
                size={"md"}
                type={"btn-block"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUpdateOfferModal: state.offerReducer.isUpdateOfferModal,
    selectOfferIds: state.offerReducer.selectOfferIds,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEditModal: () => dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),
    refreshOffersList: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
