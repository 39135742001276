import {LANG_CODE_SINHALA, LANG_CODE_TAMIL} from "../consts";

let store;

export const injectStoreForLanguage = _store => {
    if (_store) {
        store = _store
    }
};

export const GetTranslation = (key) => {

    return "en_US";
    // if (!store.getState().language) {
    //     return key;
    // }
    //
    // let langCode = "_SI";
    // switch (store.getState().language.currentLanguage) {
    //     case LANG_CODE_SINHALA:
    //         langCode = "_SI";
    //         break;
    //     case LANG_CODE_TAMIL:
    //         langCode = "_TA";
    //         break;
    // }

    // let item = store.getState().language.translations[key];
    // if (item) {
    //     return item[store.getState().language.currentLanguage] + langCode;
    // } else {
    //     if (key === "lbl_open_profile") {
    //         return "Open profile"
    //     } else if (key === "title_logout") {
    //         return "Logout"
    //     } else {
    //         return key;
    //     }
    // }
};
