import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../../store/actions/types";
import Scrollbars from "react-custom-scrollbars";
import {RiCloseLine,} from "react-icons/ri";
import {OfferCard} from "../../../components";


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  // getBankName = (bankId) => {
  //   const bank = bankData?.find(bank => bank.bankId === bankId);
  //   return bank ? bank?.bankName : "Unknown Bank";
  // };



  render() {
    const {
      viewPointHeight,
      isLoading,
    } = this.state;
    const {isOpenViewMerchantOffers, merchantOffers} = this.props;

    //console.log("resOfferByMerchant===>", recentOffers);
    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isOpenViewMerchantOffers ? "show-me" : "hide-me"
          } `}
        >
          <div
            className={"coo_modal-body-content fix-906 d-flex flex-column"}
            style={{ padding: "32px" }}
          >
            <div className={"d-flex flex-row justify-content-between align-items-center"}>
              <div className={"coo_mbc__header"}>{"View Merchant Offers"}</div>
              <div className={`close-modal-wrapper `}
                   onClick={() => this.props.onClickCloseViewMerchantOffers()}>
                <RiCloseLine/>
              </div>
            </div>

            <div className={"coo_modal-offer-title"}>
              <Scrollbars
                  style={{height: viewPointHeight - 220}}
                  thumbMinSize={30}
                  universal={true}
                  autoHide
              >
                {merchantOffers && merchantOffers.length > 0 && (
                    <>
                      {((merchantOffers && merchantOffers) || []).map(
                          (item, index) => (
                              <OfferCard
                                  details={item && item.header}
                                  termsAndCondition={item && item.termsConditions}
                                  name={
                                      item &&
                                      item.merchant &&
                                      item.merchant[0].merchantName
                                  }
                                  bankName={item && item.bank && item.bank[0]?.bankName}
                                  logo={item && item?.bank && item?.bank[0]?.logoURL}
                                  imgUrl={item.bannerImageURL}
                                  userName={item && item?.user?.firstName}
                                  createAt={item && item.createdAt}
                                  type={"view"}
                                  isCollapse={true}
                                  status={
                                      item &&
                                      item?.status?.charAt(0).toUpperCase() +
                                      item?.status?.slice(1)
                                  }
                              />
                          )
                      )}
                    </>
                )}
              </Scrollbars>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenViewMerchantOffers: state.merchantReducer.isOpenViewMerchantOffers,
    merchantOffers: state.merchantReducer.merchantOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseViewMerchantOffers: () =>
      dispatch({
        type: actionTypes.CLOSE_VIEW_MERCHANT_OFFERS_MODAL,
        status: false,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
