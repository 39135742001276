import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { OpenNotification } from "../../../../config/notification";
import * as xlsx from "xlsx";

import { Button, FileUploadCard } from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import { saveMerchant } from "../../../../api";
import { cooGetAllCategory } from "../service/category.service";
import uuid from "react-uuid";

class upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      locations: [],
      location: null,
      favoriteArea: null,
      favoriteAreas: [],
      isLoading: false,
      fileName: null,
      isImageUploading: false,
      categoryOptions: [],
      uploadFils: [],
      cropBase64: null,
      src: null,
      isUploadImage: false,
      crop: {
        unit: "px", // default, can be 'px' or '%'
        width: 100,
        x: 130,
        y: 50,
        aspect: 4 / 4,
      },
      eventImage: {
        imgBase64: null,
        fileName: null,
      },
      event: {
        base64: null,
      },

      dataForm: {
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Image URL",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    let res = await cooGetAllCategory();
    const updateForm = { ...this.state.dataForm };
    updateForm["keywords"].elementConfig.options = this.syncCategory(
      res && res.data && res.data.data
    );
    this.setState({ dataForm: updateForm });

    console.log("onload", res.data);
  }

  syncCategory(res) {
    let categoryList = [];
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
      });
    }
    return categoryList;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async clearAll() {
    this.setState({
      locations: [],
      favoriteAreas: [],
      location: null,
      favoriteArea: null,
      isLoading: false,
    });

    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      console.log(updateForm[key]);
      updateForm[key].value = "";
      this.setState({ dataForm: updateForm });
    }
  }

  async onClickSaveData() {
    console.log("event fire");
    let state = this.state;

    if (state.uploadFils.length > 0) {
      this.setState({ isLoading: true });
      let res = null;
      for (const element of state.uploadFils) {
        try {
          res = await saveMerchant(
            element.merchantName,
            element.contactNo,
            element.logoURL,
            element.webURL,
            [],
            element.location
          );

          // console.log(res.data);

          // if (res && res.data && !res.data.status) {
          //   continue;
          // }

          // Additional code if needed for successful response
        } catch (error) {
          console.error("Error saving merchant:", error);
          // Handle error if needed
        }
      }
      // for (let idx in state.uploadFils) {

      //   // if (res && res.data && !res.data.status) {
      //   //   // break;
      //   // }
      // }
      if (res && res.data && res?.data?.status) {
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
        const updateForm = { ...this.state.dataForm };
        updateForm["logoURL"].value = "";
        this.setState({
          isImageUploading: false,
          dataForm: updateForm,
          isLoading: false,
          uploadFils: [],
        });
        //   //await this.clearAll();
      } else {
        OpenNotification("error", res && res.data && res.data.error, "error");
        this.setState({ isLoading: false });
        // await this.clearAll();
      }
    } else {
      OpenNotification("Error", "Invalid data", "error");
      this.setState({ isLoading: false });
    }
  }

  readUploadFile = (e) => {
    e.preventDefault();
    // if (e.target.files) {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);

      //console.log("upload:::", json);
      this.setState({ isImageUploading: true });
      for (let idx in json) {
        if (json && json[idx] && json[idx].merchantName) {
          let location = json[idx]?.location
            ? ([] = json[idx]?.location.split(","))
            : [];

          let contactNo = json[idx]?.contactNo
            ? ([] = json[idx]?.contactNo
                .toString()
                .replace(/[()]/g, "")
                .split(/,|\//))
            : [];

          let locationUpdate = [];
          for (let i = 0; i < location.length; i++) {
            locationUpdate = [];
            locationUpdate.push({
              id: uuid(),
              tagName: location[i],
              count: "0",
            });
          }

          let body = {
            contactNo: contactNo,
            logoURL: json[idx].logoURL,
            location: locationUpdate,
            merchantName: json[idx].merchantName,
            webURL: json[idx].webURL,
            status: json[idx].status,
            favoriteArea: [],
          };
          console.log("body", body);

          this.state.uploadFils.push(body);
        }
      }
      console.log("list", this.state.uploadFils);
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = "Uploaded";
      this.setState({
        isImageUploading: false,
      });
      this.setState({
        dataForm: updateForm,
      });
    };
    reader.readAsArrayBuffer(files[0]);
    reader.readAsDataURL(files[0]);
    // }
  };

  render() {
    const { dataForm, isLoading, isImageUploading } = this.state;
    return (
      <>
        <div className="cco__cnc-upload-content-wrapper">
          {/*----- image crop section wrapper ------*/}

          <div className="group-form-upload">
            <FileUploadCard
              title={"Upload Merchant details"}
              onSelectFile={this.readUploadFile.bind(this)}
              isImageUploading={isImageUploading}
            />

            {dataForm.logoURL.value && (
              <div className="uploaded-wrapper">Uploaded!</div>
            )}
          </div>
          <div
            className={"login-footer-action-wrapper footer-button pl-4 pr-4"}
          >
            <Button
              leftIcon={false}
              rightIcon={false}
              isLoading={isLoading || isImageUploading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={async () => {
                await this.onClickSaveData();
              }}
              text={"Done"}
              size={"md"}
              type={"btn-block"}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(upload);
