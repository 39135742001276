import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import { Button, PageHeader } from "../../components";
import { Table, Space } from "antd";
import { getAdminUser } from "../../api";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getUsers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getUsers() {
    this.setState({ isLoading: true });
    let res = await getAdminUser();
    this.setState({
      dataSource: res && res.data,
      isLoading: false,
    });
  }

  render() {
    const { dataSource, tags, isLoading } = this.state;
    const columns = [
      {
        title: "firstName",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "contactNo",
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: "userType",
        dataIndex: "userType",
        key: "userType",
      },
      {
        title: "accessLevel",
        dataIndex: "accessLevel",
        key: "accessLevel",
      },
      {
        title: "email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <a href="#">View</a>
            <a href="#">Edit</a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={true}
            isDate={false}
            isLoading={isLoading}
            onClickRefresh={async () => {
              let res = await this.getUsers();
            }}
            title={"Users"}
          />
        </div>
        <div className={"cco__page-body-wrapper"}>
          <Table
            size={"small"}
            loading={isLoading}
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
