import React from 'react';
import './styles.scss'
import {GetIcon} from "../../../config/icon";
import {getMaskedPhoneNumber} from "../../../utils";

const Index = (props) => {
  return (
      <>
          <div className={'top-section-join-frm'}>
              {props.isLogo && (<>
                  <div className={'logo-section'}>
                      <img className={'join-form-logo sm'}
                           src={require("../../../assets/img/logo/logo_24.svg")}/>
                  </div>
              </>)}
          </div>

          {/*form-header-section*/}
          <div className={'form-header-section'}>
              <h1>{props.header}</h1>
              {props.wizardNo <= 2 && (
                  <span className={'join-form-sub-title'}>Discover
                  <span className={'highlight'}>best</span>credit card offers in one place</span>
              )}

              {props.wizardNo === 3 && (
                  <div className={"verify-mobile-msg d-fle mt-1 "}>
                      <span className={'join-form-sub-title'}>We send a code to
                      <span className={'highlight'}>{getMaskedPhoneNumber(props.mobileNo)}{" "}</span></span>
                      <a className={'change-no'}>Change</a>
                  </div>

              )}
          </div>
      </>
  )
};


Index.propTypes = {
};

Index.defaultProps = {
  header: 'text not found',
};

export default Index;
