import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { RiArchiveLine } from "react-icons/ri";
import { ButtonIcon, Tag } from "../..";
import { GetIcon } from "../../../config/icon";

const Index = (props) => {
  const fetchMobileNumber = (value) => {
    let newValue = value.replace("+", "");
    return newValue.replace(/\d{6}$/, "") + newValue.replace(/^\d{6}/, "*****");
  };
  return (
    <>
      <div className={`cco__customer_info_card__wrapper d-flex flex-row`}>
        <div className={"icon-wrapper"}>
          <RiArchiveLine />
        </div>

        <div
          className={
            "d-flex flex-row justify-content-between align-items-center"
          }
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column"}>
            <div className={"cco__cid__name"}>{props.name}</div>
            {/* <div className={"cco__pcw__type"}>{props.userType}</div> */}
            <div className={"cco_cid_info"}>
              MobileNo :{fetchMobileNumber(props.mobileNo)} | {props.date}
            </div>
            <div className="d-flex">
              <Tag
                type={
                  props.status === "Verified" || props.status === "verified"
                    ? "accept"
                    : props.status === "Unverified" ||
                      props.status === "unverified"
                    ? "new"
                    : "new"
                }
                tagName={props.status}
              />
            </div>
          </div>
          <div className={"cco__cid__menu"}>
            <ButtonIcon
              onClick={props.onClick}
              buttonIcon={GetIcon("chevronRight")}
              type={"collapse-icon"}
              size={"md"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
