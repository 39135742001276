import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { PasswordStrengthMeter } from "../../../components";

import { DatePicker, Select, TimePicker } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Index = (props) => {
  let inputElement = null;
  const dateFormat = "DD-MM-YYYY";

  //console.info('data', data);
  switch (props.elementType) {
    case "input":
      inputElement = (
        <>
          <input
            className={`form-control txt-input ${props.size} 
                    ${
                      props.isValid && props.required && props.touched
                        ? "validation-on border-red"
                        : ""
                    }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
            disabled={props.disabled}
          />
        </>
      );
      break;
    case "phoneNo":
      inputElement = (
        <>
          <input
            className={`form-control txt-input phone-no ${props.size} 
                ${
                  props.isValid && props.required && props.touched
                    ? "validation-on"
                    : ""
                }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
          <div className={"input-flg-wrapper d-flex"}>
            <div className={"flg-img-wpp"}>
              <img
                src={require("../../../assets/img/flags/LK.svg")}
                alt="online event colombo event"
              />
            </div>
            <div className={"country-code"}>+94</div>
          </div>
        </>
      );
      break;
    case "textArea":
      inputElement = (
        <>
          <textarea
            key={props.key}
            className={`form-control txt-input  text-area ${props.size} 
                ${
                  props.isValid && props.required && props.touched
                    ? "validation-on"
                    : ""
                }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
            disabled={props.disabled}
            rows={props.rows}
          />
        </>
      );
      break;
    case "select":
      inputElement = (
        <Select
          mode={props.elementConfig.mode}
          placeholder={props.elementConfig.placeholder}
          allowClear
          loading={props.isLoading}
          disabled={props.disabled}
          filterOption={(input, option) =>
            (option?.value).toLowerCase().includes(input?.toLowerCase())
          }
          showSearch
          defaultValue={props.defaultValue}
          className={`${props.size}
                     ${
                       props.isValid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          value={props.value}
          onChange={props.changed}
          onSearch={props.onSearch}
        >
          {props.elementConfig.options.map((option) => (
            <Option key={option.id} value={option.value}>
              {option.displayValue}
            </Option>
          ))}
        </Select>
      );
      break;
    case "dropdown":
      inputElement = (
        <Select
          placeholder={props.elementConfig.placeholder}
          className={`form-control drop-down drop-md ${props.size}
                     ${
                       props.isValid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          {...props.elementConfig}
          showSearch
          filterOption={(input, option) =>
            (option?.displayValue ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          loading={props.isLoading}
          allowClear
          onChange={props.changed}
        >
          {props.data &&
            props.data.map((d) => (
              <Option value={d.id} key={d.id}>
                {d[props.valueName]}
              </Option>
            ))}
        </Select>
      );
      break;
    case "datePicker":
      inputElement = (
        <DatePicker
          className={`date-picker ${props.size}
                     ${
                       props.isValid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          {...props.elementConfig}
          value={props.value}
          disabled={props.disabled}
          allowClear={true}
          // format={dateFormat}
          format={props.format}
          onChange={props.onChange}
        />
      );
      break;
    case "rangePicker":
      inputElement = (
        <RangePicker
          className={`${
            props.isValid && props.required && props.touched
              ? "validation-on"
              : ""
          }`}
          {...props.elementConfig}
          format={props.format}
          onChange={props.onChange}
        />
      );
      break;
    case "timePicker":
      inputElement = (
        <TimePicker
          onChange={props.onChange}
          format="h:mm a"
          allowClear
          className={`${props.size}
                     ${
                       props.isValid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
        />
      );
      break;
    case "hasTag":
      inputElement = (
        <div className={"input-element-wrp"}>
          <input
            className={`form-control txt-input ${props.size}
                    ${
                      props.isValid && props.required && props.touched
                        ? "validation-on"
                        : ""
                    }`}
            {...props.elementConfig}
            value={props.value}
            onKeyPress={props.onKeyPress}
            onChange={props.changed}
          />
          {props.value && (
            <div className={`tag-add-btn`} onClick={props.onClickAddTag}>
              Add
            </div>
          )}
        </div>
      );
      break;
    case "keywords":
      inputElement = (
        <div className={"input-element-wrp"}>
          <Select
            placeholder={props.elementConfig.placeholder}
            allowClear
            showSearch
            className={`${props.size} 
                     ${
                       props.isValid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
            onChange={props.changed}
          >
            {props.elementConfig.options.map &&
              props.elementConfig.options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.displayValue}
                </Option>
              ))}
          </Select>
          {props.value && (
            <div className={`tag-add-btn`} onClick={props.onClickAddTag}>
              Add
            </div>
          )}
        </div>
      );
      break;
    default:
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }
  return (
    <>
      <div
        className={`d-flex flex-column form-section-wrapper ${props.margin}  
        ${
          props.isValid && props.required && props.touched
            ? "validation-on"
            : ""
        }`}
        style={{ width: props.width }}
      >
        {props.label && (
          <label
            className={`${
              props.isValid && props.required && props.touched
                ? "label-validation-on"
                : "lbl"
            }`}
          >
            {props.label} {props.isShowRequired ? <b>*</b> : ""}
          </label>
        )}
        {inputElement}

        {props.isShowPasswordStrengthMeter && (
          <PasswordStrengthMeter
            password={props.value}
            fieldId="password"
            label="Password"
            placeholder="Enter Password"
            thresholdLength={8}
            minStrength={3}
            required
          />
        )}

        {props.isValid && props.required && props.touched && (
          <>
            {props.required && (
              <div className={"error-required"}>Filed is required. </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  elementType: PropTypes.string.isRequired,
};

Index.defaultProps = {
  elementType: "input",
};

export default Index;
