import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const uploadImage = async (base64, _type, title) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      base64: base64,
      type: _type,
      clientId: null,
      offerId: null,
      keywords: _type,
      title: title,
      createdBy: "createdBy",
    };
    // Get response
    let response = await axios.post(`${BASE_URL}/media/upload/image`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_UPLOAD_IMAGE_API:", err);
    throw err;
  }
};
