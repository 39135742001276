import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Dropdown, Menu } from "antd";
import { userLogOut } from "../../../utils";
import MenuItem from "antd/lib/menu/MenuItem";
import { Tag } from "../..";
import { render } from "@testing-library/react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/types";

const Index = (props) => {
  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={props.onClick}>
          Log out
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Setting
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={props.onClickProfile}
        >
          Profile View
        </a>
      ),
    },
  ];

  return (
    <>
      {props.isHide && (
        <Dropdown menu={{ items }} placement="topLeft">
          <div
            className={`g_button-Wrapper ${props.type} ${props.size} ${
              props.isHide ? "hide" : ""
            } `}
            // onClick={props.onClick}
          >
            <div className={`g_bw-lefIcon`}>
              <img src={props.imgUrl} alt={"Profile Picture"} />
            </div>
            {!props.isHide && (
              <>
                <div className={"g_bw-contain-wrapper"}>
                  <div className={"g_cw-title "}>{props.name}</div>
                  {/* <div className={"g_cw-subTitle"}>{props.type}</div> */}
                  <Tag type={"admin"} tagName={props.type} />
                  {/* <Tag
                      type={"admin"}
                      tagName={
                        user &&
                        user.userType.charAt(0).toUpperCase() +
                          user.userType.slice(1)
                      }
                    /> */}
                </div>

                <div className={`g_bw-rightIcon d-flex`}>{props.icon}</div>
              </>
            )}
          </div>
        </Dropdown>
      )}
      {!props.isHide && (
        <div
          className={`g_button-Wrapper ${props.type} ${props.size} ${
            props.isHide ? "hide" : ""
          } `}
          // onClick={props.onClick}
        >
          <div className={`g_bw-lefIcon`}>
            <img src={props.imgUrl} alt={"Profile Picture"} />
          </div>
          {!props.isHide && (
            <>
              <div className={"g_bw-contain-wrapper"}>
                <div className={"g_cw-title "}>{props.name}</div>
                {/* <div className={"g_cw-subTitle"}>{props.type}</div> */}
                <Tag type={"admin"} tagName={props.type} />
              </div>
              <Dropdown menu={{ items }} placement="topLeft">
                <div className={`g_bw-rightIcon d-flex`}>{props.icon}</div>
              </Dropdown>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isProfileSetting: state.profileReducer.isProfileSetting,
    user: state.profileReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenProfileSetting: (payload) => {
      console.log({ payload });
      dispatch({
        type: actionTypes.OPEN_PROFILE_SETTING_MODAL,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
