import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isProfileSetting: false,
  userData: "",
};

export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("profileReducer", payload);
  switch (type) {
    case actionTypes.OPEN_PROFILE_SETTING_MODAL:
      draft.userData = payload;
      draft.isProfileSetting = true;
      return draft;
    case actionTypes.CLOSE_PROFILE_SETTING_MODAL:
      draft.userData = null;
      draft.isProfileSetting = false;
      return draft;
    default:
      return draft;
  }
});
