import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Tag } from "../..";

const Index = (props) => {
  return (
    <>
      <div className={`profile-box-wrapper d-flex flex-column ${props.style}`}>
        {props.profileAvatar && (
          <div className={"pbw__avatar"}>
            <img src={props.profileAvatar} alt={"user-profile"} />
          </div>
        )}
        {!props.profileAvatar && (
          <div className={"pbw__avatar-background"}></div>
        )}
        <div className={"pbw__details d-flex flex-column"}>
          <div className={"pbwd__name"}>{props.userName}</div>
          <div className={"pbwd__email"}>{props.userEmail}</div>
          <div className={"d-flex flex-row"}>
            <Tag type={"admin"} tagName={props.userType} />
          </div>
        </div>
      </div>
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
