import React, { Component, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../../store/actions/types";
import { Spin } from "antd";
import { userDetails, userLogOut } from "../../../utils";
import {
  getAdminUser,
  getExpiringOffers,
  getOffer,
  getOfferByStatus,
  getSessionValidateStatus,
  updateOfferStatus,
  updateOfferStatusMultiple,
} from "../../../api";
import { Button, OfferCard, Tag } from "../../../components";
import { v4 as uuidv4 } from "uuid";
import { cooGetOfferByMerchant } from "../../merchant/service/merchant.service";
import moment from "moment";
import { OpenNotification } from "../../../config/notification";

class Expiring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      expiringOffers: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
      dataForm: {
        searchOffer: {
          key: "searchOffer",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search offer title here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        keywords: {
          key: "keywords",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "Search Merchant here...",
            options: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Pending Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Active Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 3,
          tabName: "Rejected Offers",
          content: "",
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  // async getAll() {
  //   let sessionStatus = await getSessionValidateStatus();
  //   console.log("sessionStatus", sessionStatus);
  //   if (sessionStatus?.data?.status) {
  //     this.setState({ isLoading: true });
  //     let user = await userDetails();
  //     if (user && user.userId) {
  //       let resOffersNew = await getOfferByStatus(1, 10, "new");

  //       const offersDataNew = await this.resetOffers(
  //         resOffersNew && resOffersNew.data,
  //         user
  //       );

  //       let pendingTotalCount =
  //         user?.userType != "user"
  //           ? resOffersNew?.totalCount || 0
  //           : offersDataNew?.length || 0;

  //       this.setState((prevState) => ({
  //         ...prevState,
  //         expiringOffers: offersDataNew || [],
  //         pendingTotalCount,
  //         user: user,
  //         isLoading: false,
  //       }));
  //       this.props.doneRefresh();
  //     }
  //   } else {
  //     userLogOut();
  //     this.props.history.push("/sign-in");
  //   }
  // }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  onClickUpdateOfferStatus = async (offerId, status) => {
    if (offerId) {
      this.setState({ isLoading: true });

      let body = {
        reason: "",
        comment: "",
        status: status,
      };

      let res = await updateOfferStatus(body, offerId);

      if (res && res.data && res.data.status) {
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
        this.props.refreshOffersList();
        await this.fetchExpiringOffers();
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
      return false;
    }
  };

  fetchExpiringOffers = async () => {
    this.setState({ isLoading: true });
    let resOffersNew = await getExpiringOffers();
    console.log({ resOffersNew });
    this.setState((prevState) => ({
      expiringOffers: resOffersNew && resOffersNew.data,
      pendingPageNo: prevState.pendingPageNo + 1,
      isLoading: false,
    }));
  };

  updateToExpiredOffer = async () => {
    this.setState({ isLoading: true });
    let offerIds = [];
    this.state.expiringOffers.forEach((element) => {
      if (element?.daysToExpire < 0 && offerIds?.length != 10) {
        offerIds.push(element.offerId);
      }
    });
    let resExpired = await updateOfferStatusMultiple({
      offerIds: offerIds,
    });
    console.log({ resExpired });

    OpenNotification(
      "success",
      resExpired && resExpired.data && resExpired.data.message,
      "success"
    );
    await this.fetchExpiringOffers();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchOfferTitle !== this.props.searchOfferTitle) {
      if (this.props.searchOfferTitle != "") {
        let updateDataForm = this.state.dataForm;
        updateDataForm.searchOffer.value = this.props.searchOfferTitle;
        this.setState({ dataForm: updateDataForm });
      } else {
        let updateDataForm = this.state.dataForm;
        updateDataForm.searchOffer.value = "";
        this.setState({ dataForm: updateDataForm });
      }
    }
  }

  render() {
    const { viewPointHeight, expiringOffers, isLoading, dataForm } = this.state;
    const { editOfferData } = this.props;

    // useEffect(() => {
    //   const fetchData = async () => {

    //   };

    //   fetchData();
    // }, [refreshOffers]);

    return (
      <>
        <div
          style={{
            paddingRight: "24px",
            paddingBottom: "24px",
            paddingLeft: "24px",
          }}
        >
          {isLoading && (
            <div style={{ margin: "10% 50%" }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
          {!isLoading && (
            <div className="d-flex flex-row justify-content-between mt-2 mb-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <Tag
                  type={"companyName p-2"}
                  tagName={"Ending soon : " + expiringOffers?.length}
                />
                {/* <div className={`tag-main-wrapper new p-2`}>
                  <div className="tmw__name">
                    Expired: 10 <span className="tmw__action"></span>
                  </div>
                </div> */}
                <Tag
                  type={expiringOffers?.length > 0 ? "new p-2" : "disabled p-2"}
                  tagName={"Expired: 10"}
                  action={"Update"}
                  onClick={async () => {
                    if (expiringOffers?.length > 0) {
                      await this.updateToExpiredOffer();
                    }
                  }}
                />
              </div>
              <Button
                type={"btn-edit"}
                size={"lg secondary"}
                isLoading={isLoading}
                onClick={async () => {
                  await this.fetchExpiringOffers();
                }}
                text={`Expiring offers before ${moment()
                  .add(5, "days")
                  .format("DD-MM-YYYY")}`}
              />
            </div>
          )}
          {!isLoading && (
            <>
              {/* Pending offers */}
              <div>
                <Scrollbars
                  style={{ height: viewPointHeight - 290 }}
                  thumbMinSize={30}
                  universal={true}
                  autoHide
                >
                  {expiringOffers && expiringOffers.length > 0 && (
                    <>
                      {expiringOffers
                        .filter((item) => {
                          if (dataForm.searchOffer.value === "") {
                            return item; // Corrected the return statement
                          } else if (
                            item.header
                              ?.toLowerCase()
                              .includes(
                                dataForm.searchOffer.value.toLowerCase()
                              )
                          ) {
                            return item; // Corrected the return statement
                          }
                        })
                        .map((item, index) => (
                          <OfferCard
                            offerId={item && item?.offerId}
                            details={item && item.header}
                            type={"expire"}
                            expireDays={item?.daysToExpire}
                            isActive={editOfferData?.offerId === item?.offerId}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            categoryName={item && item?.category?.categoryName}
                            userId={item && item?.user?.userId}
                            offerEndDate={item && item.offerEnd}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() =>
                              this.props.onClickOpenOfferView(item)
                            }
                            onClickExpireSoon={async () => {
                              await this.onClickUpdateOfferStatus(
                                item?.offerId,
                                "expireSoon"
                              );
                            }}
                            onClickExpired={async () => {
                              await this.onClickUpdateOfferStatus(
                                item?.offerId,
                                "expired"
                              );
                            }}
                            onClickEdit={() => {
                              this.props.onClickClearSelected();
                              this.props.onClickSelectOffer(item);
                              this.props.onClickSelectedMerchant({
                                ...item?.merchant,
                                termsAndCondition: item.termsConditions,
                              });
                            }}
                          />
                        ))}
                    </>
                  )}

                  {this.state.isLoading && (
                    <div style={{ margin: "0 50%", opacity: 0.5 }}>
                      <Spin size="large" spinning={isLoading} />
                    </div>
                  )}
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    refreshOffersList: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expiring);
