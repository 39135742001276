import ApiRoutes from "../../../../constants/api-routes";
import { ccoInstance } from "../../../../service";

export const cooCreateNewBank = async (body) =>
  ccoInstance.post(`${ApiRoutes.CREATE_NEW_BANK}`, body);

export const cooUpdateBank = async (body, bankId) =>
  ccoInstance.put(`${ApiRoutes.UPDATE_BANK}/${bankId}`, body);

export const cooUpdateBankCardTypes = async (body, bankId) =>
  ccoInstance.put(`${ApiRoutes.UPDATE_BANK_CARDS}/${bankId}`, body);
