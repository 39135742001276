import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

//Btn Type { btn-block }

const Index = (props) => (
  <>
    {!props.isLoading && (
      <button
        disabled={props.disabled}
        className={`btn  ${
          props.disabled ? props.type + " disable" : props.type
        } ${props.size} ${props.option}`}
        onClick={() => props.onClick()}
        onChange={() => props.onChange()}
        onKeyPress={props.onKeyPress}
      >
        <div className={`btn-inner-wrapper d-flex p-0 m-0`}>
          {props.leftIcon && (
            <div id={props.id} className={`btn-inner-icon left-icon-wrapper`}>
              {props.reactRightIcon}
            </div>
          )}
          {props.leftIconFill && (
            <>
              <div className={`left-fill-icon-wrapper mr-2`}>
                {props.iconName}
              </div>
              <div className={"left-fill-icon-text p-0 m-0"}>
                {props.LeftIconText}
              </div>
            </>
          )}
          <div className={"button-text-wrp p-0 m-0"}>{props.text}</div>
          {props.rightIconFill && (
            <>
              <div className={"left-fill-icon-text p-0 m-0"}>
                {props.rightIconText}
              </div>
              <div className={`left-fill-icon-wrapper ml-2`}>
                {props.iconName}
              </div>
            </>
          )}
          {props.rightIcon && (
            <div
              className={`btn-inner-icon right-icon-wrapper 
                ${props.isActiveAnimation ? "active-animation" : ""}`}
            >
              {props.reactRightIcon}
            </div>
          )}
        </div>
      </button>
    )}

    {props.isLoading && (
      <button
        className={`btn btn-primary disabled ${
          props.disabled ? props.type + " disable" : props.type
        } ${props.size}`}
      >
        <div className={`btn-inner-wrapper d-flex p-0 m-0`}>
          <div className={"button-text-wrp p-0 m-0"}>please wait..</div>
        </div>
      </button>
    )}
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "unknown",
};

export default Index;
