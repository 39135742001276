import React, { Component } from "react";
import { connect } from "react-redux";

import "./styles.scss";
import { Spin, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, Button, PageHeader } from "../../components";
import SiteMap from "./siteMap";

const { TabPane } = Tabs;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      isLoadingMerchant: false,
      activeMerchant: null,
      merchantData: [],
      tabName: "Merchant mapping",
      selectBank: "1",
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Site Map",
          content: <SiteMap />,
        },
      ],
      newMerchantList: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    //await this.getAll();
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
    console.log("data_form", this.state.dataForm);
  };

  async getAll() {
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  handleChange = (tabName) => {
    this.setState({
      selectBank: tabName,
    });
  };

  // async componentDidUpdate(prevProps, prevState) {
  //   if (prevState.selectBank !== this.state.selectBank) {
  //     this.setState({ newMerchantList: [] });
  //   }
  // }

  render() {
    const { tabJson, isLoading, viewPointHeight } = this.state;
    const {} = this.props;

    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={false}
            isDate={false}
            isLoading={isLoading}
            // onClickRefresh={async () => {
            //   let res = await this.getUsers();
            // }}
            title={"SEO Mapping"}
          />
        </div>

        <div className={"cco__offerPlanner-body-wrapper"}>
          {!isLoading && (
            <>
              {tabJson?.length > 0 && (
                <div className={"cco__obw-tab"}>
                  <Tabs onChange={this.handleChange}>
                    {((tabJson && tabJson) || []).map((i, t) => (
                      <TabPane tab={i.tabName} key={i.key}>
                        <>{i.content}</>
                      </TabPane>
                    ))}
                  </Tabs>
                </div>
              )}
            </>
          )}

          {isLoading && (
            <div style={{ margin: "0 50%", opacity: 0.5 }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
