import React from "react";
import {BiBug, BiCheckCircle, BiErrorCircle} from "react-icons/bi";
import {toast} from 'react-toastify';


const key = 'updatable';

//type : success/error/warn/info
export const OpenNotification = (
    title,
    description,
    type
) => {
    const position = toast.POSITION.TOP_CENTER;

    if (type === 'success') {
        toast.success(description, {
            position: position
        });
    }

    if (type === 'info') {
        toast.info(description, {
            position: position
        });
    }

    if (type === 'error') {
        toast.error(description, {
            position: position
        });
    }
};
