import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { RiCheckLine } from "react-icons/ri";
import { ButtonIcon } from "../..";

const Index = (props) => (
  <>
    <div
      className={`checkbox-selectionCard-wrapper ${
        props.isSelect ? "is_active" : ""
      } `}
      onClick={props.onClick}
    >
      <div className={`sc_checkbox`}>
        {props.isSelect && (
          <>
            <RiCheckLine />
          </>
        )}
      </div>
      <div className="sc_text">{props.selectionName}</div>
    </div>
  </>
);
Index.propTypes = {
  types: PropTypes.string.isRequired,
  selectionName: PropTypes.string.isRequired,
};
Index.defaultProps = {
  type: "dark",
  size: "md",
  selectionName: "Arts & Entertainment",
};

export default Index;
