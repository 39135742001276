import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import * as actionTypes from "../../../store/actions/types";
import { connect } from "react-redux";
import { FiCopy } from "react-icons/fi";
import { GetIcon } from "../../../config/icon";
import { Button, ButtonIcon } from "../..";

const Index = (props, isEditButton) => {
  return (
    <>
      <div
        className={`bank-card-small__wrapper d-flex flex-row align-items-center ${
          props.preview ? "preview" : props.isActive ? "isActive" : ""
        } ${props.style}`}
      >
        {props.logo && (
          <div
            className={
              "bcsw__logo d-flex flex-column justify-content-center align-items-center align-content-center"
            }
          >
            <img src={props.logo} alt="creditcard offers" />
          </div>
        )}
        {!props.logo && (
          <div className={"bcsw__icon"}>{GetIcon(props?.iconName)}</div>
        )}
        <div
          className={"d-flex"}
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column justify-content-center"}>
            {props.isEditButton === true ? (
              <>
                {/* {props.preview && ( */}
                <>
                  <div className={"bcsw__title"}>Bank</div>
                  <div className={"bcsw__name"}>{props.name}</div>
                </>
                {/* )} */}
              </>
            ) : (
              <>
                <div className={"bcsw__name"}>{props.name}</div>
                <div
                  className={
                    "bcsw__id d-flex flex-row justify-content-between align-items-center"
                  }
                >
                  bankId :{props.bankId}
                  <div className={"bcsw__copy"}>
                    <FiCopy />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!props.preview && (
          <>
            {props.isEditButton === true ? (
              <>
                <div>
                  <Button
                    text={"Edit"}
                    onClick={props.onClickEdit}
                    type={"btn-edit"}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-row justify-content-center align-items-center align-content-center">
                  <ButtonIcon
                    onClick={props.onClick}
                    buttonIcon={GetIcon("chevronRight")}
                    type={"collapse-icon"}
                    size={"md"}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

const mapStateToProps = (state) => {
  return {
    isOpenSearchMerchant: state.merchantReducer.isOpenSearchMerchant,
    selectMerchant: state.merchantReducer.selectMerchant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedOffer: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
