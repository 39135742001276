import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className={`btn-icon-wrapper d-flex flex-column align-items-center align-content-center justify-content-center ${props.type} ${props.size}`}
      onClick={props.onClick}
    >
      <div className="icon_wrapper d-flex flex-column align-items-center justify-content-center  align-content-center">
        {props.buttonIcon}
      </div>
    </div>
  </>
);

Index.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "light",
  size: "sm",
  icon: "<RiCloseFill/>",
};

export default Index;
