import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import ReservationContact from "./reservationContact";

import * as actionTypes from "../../../store/actions/types";

import {
  BankCardSmall,
  Button,
  ButtonIcon,
  Input,
  MerchantDetailCardSmall,
  MerchantSelectCard,
  MerchantSelectCardSmall,
  RecentOfferCard,
  SelectBox,
} from "../../../components";
import { AutoComplete, Collapse, Tabs } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import {
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCheckLine,
  RiCloseLine,
} from "react-icons/ri";
import {
  cooFindMerchantByName,
  cooGetAllMerchantByPage,
  cooGetOfferByMerchant,
  cooUpdateMerchantLocations,
  cooUpdateMerchantTerms,
} from "../../merchant/service/merchant.service";
import uuid from "react-uuid";
import { getBank, getCategory } from "../../../api";
import Slider from "react-slick";
import { userDetails } from "../../../utils";
import { v4 as uuidv4 } from "uuid";
import CreateTermsAndConditions from "../ManageTermsAndConditions/createTermsAndConditions";
import { NewMerchant } from "../index";
import { saveDraft } from "../../../api/draft";
import { OpenNotification } from "../../../config/notification";

const { TabPane } = Tabs;

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectTermsConditions: [],
      suggestions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        searchMerchant: {
          key: "searchMerchant",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        searchBank: {
          key: "searchBank",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search bank name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        searchCategory: {
          key: "searchCategory",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search category name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        termsAndCondition: {
          key: "termsAndCondition",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        txtLocation: {
          key: "txtLocation",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "type location here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        mobileNo: {
          key: "mobileNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Mobile No",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        email: {
          key: "email",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Email",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      isPartEdit: false,
      partId: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    let user = await userDetails();
    if (user && user.userId) {
      await this.getAll();
    }
    await this.clearAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  clearAll = async () => {
    this.setState({
      formWizard: 1,
      selectOffer: null,
      selectedBank: null,
      selectedCategory: null,
      selectLocations: null,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
    });
  };

  // async updateMerchantTerms(newTermsList) {
  //   const { selectMerchant } = this.props;
  //   const { termsConditions, selectedBankId } = this.state;
  //   let newTermsAndConditions = [...this.state.termsConditions];
  //   let bankId = selectedBankId ? selectedBankId : "6636780a73e7209d15fc5184";
  //   let terms = [];
  //   newTermsList.forEach((element) => {
  //     terms.push(element);
  //   });
  //   newTermsAndConditions[bankId] = terms;

  //   let length = 0;

  //   // newTermsAndConditions.forEach((item) => {
  //   //   if (Array.isArray(item)) {
  //   //     length += item.length;
  //   //   } else if (typeof item === "object" && item !== null) {
  //   //     const nestedArray = Object.values(item).find((value) =>
  //   //       Array.isArray(value)
  //   //     );
  //   //     length += nestedArray ? nestedArray.length : 1;
  //   //   }
  //   // });
  //   console.log({ newTermsAndConditions });

  //   // if (selectMerchant && selectMerchant.termsAndCondition.length !== length) {
  //   this.setState({
  //     termsConditions: newTermsAndConditions,
  //   });
  //   this.setState({ isLoading: true });
  //   let body = {
  //     termsAndCondition: newTermsAndConditions,
  //   };
  //   // let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
  //   // console.log("cooUpdateMerchantLocations", res);
  //   // if (res && res.data && res.data.status) {
  //   //   this.setState({ isLoading: false });
  //   //   let merchant = res.data.data;
  //   //   this.props.onClickSelectedMerchant(merchant);
  //   // } else {
  //   this.setState({ isLoading: false });
  //   // }
  //   // }
  // }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsAndCondition);
    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    //todo

    // if (
    //   selectMerchant &&
    //   selectMerchant.termsAndCondition.length > newTermsAndConditions.length
    // ) {
    //   this.setState({ isLoading: true });
    //   let body = {
    //     termsAndCondition: newTermsAndConditions,
    //   };
    //
    //   console.log("cooUpdateMerchantLocations", res);
    //   if (res && res.data && res.data.status) {
    //     this.setState({ isLoading: false });
    //     let merchant = res.data.data;
    //     this.props.onClickSelectedMerchant(merchant);
    //   } else {
    //     this.setState({ isLoading: false });
    //   }
    // }
  }

  async updateMerchantLocations(value) {
    const { selectedMerchant } = this.state;
    // const { selectLocations } = this.state;

    const newSelectLocations = [
      ...this.state.selectLocations,
      {
        id: uuid(),
        tagName: value,
        count: 0,
      },
    ];
    this.setState({
      selectLocations: newSelectLocations,
    });

    if (
      selectedMerchant &&
      selectedMerchant?.location?.length < newSelectLocations.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        location: newSelectLocations,
      };

      let res = await cooUpdateMerchantLocations(
        body,
        selectedMerchant.merchantId
      );
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async getAll() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });
    let resCategory = await getCategory();
    let resBank = await getBank();

    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      dataSourceBanks: resBank && resBank.data,
      dataSourceCategories: resCategory && resCategory.data,
      isLoading: false,
      // selectLocations: [],
      // termsConditions: [],
      formWizard: 1,
    });
  }

  async getAllMerchant() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });

    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      isLoading: false,
      formWizard: 1,
    });
  }

  async refresh() {
    this.setState({ isLoading: true });
    let resCategory = await getCategory();
    let resBank = await getBank();
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });
    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      dataSourceBanks: resBank && resBank.data,
      dataSourceCategories: resCategory && resCategory.data,
      isLoading: false,
      selectLocations: this.state.selectLocations || [],
      termsConditions: this.state.termsConditions || [],
      // formWizard: 1,
    });
  }

  async refreshMerchants() {
    this.setState({ isLoading: true });
    let res = await cooGetAllMerchantByPage({ pageNo: 1, pageSize: 300 });
    this.setState({
      dataSourceMerchant: res && res.data && res.data.data,
      isLoading: false,
    });
  }

  onRemoveTermsAndConditions = (i) => {
    const { termsConditions } = this.state;

    termsConditions[i].isActive = !termsConditions[i].isActive;
    this.setState({ termsConditions });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  onSelectLocation = (_item, i) => {
    const { selectLocations } = this.state;
    selectLocations[i].isActive = !selectLocations[i].isActive;
    this.setState({ selectLocations });
  };

  selectAllTermsAndConditions = () => {
    const { termsConditions } = this.state;
    let terms = { ...this.props.termsAndConditionList };

    const updatedTermsConditions = termsConditions.map((term, index) => ({
      ...term,
      isActive: !term.isActive,
    }));

    terms[this.state.selectedBankId] = updatedTermsConditions;

    this.props.onLoadTermsAndCondition(terms);

    this.setState({ termsConditions: updatedTermsConditions });
  };

  isArray = (value) => {
    return Array.isArray(value);
  };

  displayListData = (list) => {
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        console.log(`Bank ID: ${key}`);
        list[key].forEach((item) => {});
      }
    }
  };

  handleEditPart = (partId, value) => {
    this.setState({ isPartEdit: true, partId: partId });
    const updateForm = {
      ...this.state.dataForm,
    };
    //updatedFormElement.touched = true;
    updateForm["termsAndCondition"].value = value;
    this.setState({ dataForm: updateForm });
  };
  clearTermsAndBox = () => {
    const updateForm = { ...this.state.dataForm };
    updateForm["termsAndCondition"].value = "";
    this.setState({ dataForm: updateForm });
  };

  handleAddTermsAndCondition = async () => {
    if (this.state.dataForm?.termsAndCondition?.value) {
      const { value } = this.state.dataForm.termsAndCondition;
      const bankId = this.state.selectedBankId;
      // Split the input value by the regex pattern
      const regex = /\r?\n/;
      const parts = this.state.dataForm.termsAndCondition.value
        ?.split(regex)
        .filter((part) => part.trim() !== "");
      console.log("parts", parts.length);

      // Array to hold the terms and conditions to be added
      const termsAndConditionList = this.state.termsConditions;

      if (parts.length > 1) {
        // Handle case where there are multiple parts
        parts.forEach((part) => {
          if (part.trim()) {
            // Ensure the part is not an empty string
            if (!this.isDuplicate(part, bankId)) {
              termsAndConditionList.push(
                this.createTermCondition(part, bankId)
              );
            } else {
              console.warn("already existing.");
            }
          }
        });
      } else {
        // Handle case where it's a single line
        if (!this.isDuplicate(value, bankId)) {
          // this.props.clearTermsAndCondition();
          termsAndConditionList.push(this.createTermCondition(value, bankId));
        } else {
          console.warn("already existing.");
        }
      }
      // Add the new terms and conditions
      termsAndConditionList.forEach((term) => {
        this.props.addNewTermsAndCondition({
          parts: term,
          bankId: bankId,
        });
      });
      // Update state and clear the input box
      this.setState({ termsConditions: termsAndConditionList });
      await this.updateMerchantTerms();
      this.clearTermsAndBox();
    }
  };

  isDuplicate = (value, bankId) => {
    const { selectTermsConditions } = this.state;
    console.log("termsAndConditionList", this.state.selectTermsConditions);
    if (selectTermsConditions[bankId]) {
      return selectTermsConditions[bankId].some((term) => term.value === value);
    } else {
      console.warn(`No terms and conditions found for bankId: ${bankId}`);
      return false;
    }
  };

  createTermCondition = (value, bankId) => ({
    id: uuidv4(),
    value: value.trim(),
    bankId: bankId,
    isActive: false,
    count: 0,
  });

  onClickFormatTermsAndCondition = (partId, value) => {
    const { dataForm } = this.state;
    if (dataForm.termsAndCondition.value) {
      const regex = /\r?\n/;
      const parts = dataForm.termsAndCondition.value
        ?.split(regex)
        .filter((part) => part.trim() !== "");
      // Log the split parts to verify
      console.log("format_list", parts);
    }
  };

  async updateMerchantTerms() {
    const terms = this.props.editOfferData?.offerId
      ? { ...this.props.existingTermsList }
      : { ...this.props.termsAndConditionList };

    let body = {
      termsAndCondition: terms,
    };
    console.log("cooUpdateMerchantLocations", body);
    let res = await cooUpdateMerchantTerms(
      body,
      this.props.selectMerchant.merchantId
    );
    console.log("cooUpdateMerchantLocations", res);
    if (res && res.data && res.data.status) {
      this.setState({
        isLoading: false,
      });
      let merchant = res.data.data;
      this.props.onClickSelectedMerchant(merchant);
      this.props.onLoadTermsAndCondition(terms);
    }
  }

  onClickTermsAndConditions = (i) => {
    const { termsConditions } = this.state;
    let terms = { ...this.props.termsAndConditionList };

    const updatedTermsConditions = termsConditions.map((term, index) => ({
      ...term,
      isActive: index === i ? !term.isActive : term.isActive,
    }));

    terms[this.state.selectedBankId] = updatedTermsConditions;

    this.props.onLoadTermsAndCondition(terms);

    this.setState({ termsConditions: updatedTermsConditions });
  };

  selectCreatedNewMerchant = async (item) => {
    this.setState({ recentOffers: [] });
    this.setState({
      selectedMerchantID: item.merchantId,
      selectedMerchant: item,
    });

    console.log("termsAndCondition", item.termsAndCondition);
    this.props.onClickSelectedMerchant(item);
    this.props.onLoadTermsAndCondition(item.termsAndCondition);
    await this.setState({
      selectTermsConditions: item.termsAndCondition,
      formWizard: 4,
    });

    const resOfferByMerchant = await cooGetOfferByMerchant(item.merchantId);
    if (
      resOfferByMerchant &&
      resOfferByMerchant.data &&
      resOfferByMerchant.data.status
    ) {
      this.setState({
        recentOffers: resOfferByMerchant && resOfferByMerchant.data.data,
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectMerchant !== this.props.selectMerchant) {
      const updateForm = { ...this.state.dataForm };
      for (let key in updateForm) {
        updateForm[key].value = "";
        updateForm[key].touched = false;
      }
      const selectedMerchant = this.props.selectMerchant;

      this.setState((state) => {
        let selectLocations = [];
        let termsConditions = [];
        for (let i = 0; i < selectedMerchant?.location?.length; i++) {
          selectLocations.push({
            id: selectedMerchant && selectedMerchant.location[i].id,
            tagName: selectedMerchant && selectedMerchant.location[i].tagName,
            count: selectedMerchant && selectedMerchant.location[i].count,
            isActive: false,
          });
        }
        // for (let i = 0; i < selectedMerchant?.termsAndCondition?.length; i++) {
        //   termsConditions.push({
        //     id: selectedMerchant && selectedMerchant.termsAndCondition[i].id,
        //     value:
        //       selectedMerchant && selectedMerchant.termsAndCondition[i].value,
        //     isActive: false,
        //   });
        // }
        // state.termsConditions.concat(termsConditions);
        // state.selectLocations?.concat(selectLocations);

        return {
          selectLocations,
          // termsConditions,
        };
      });
    }
    if (prevProps.isOpenSearchMerchant !== this.props.isOpenSearchMerchant) {
      const openMerchant = this.props.isOpenSearchMerchant;

      if (openMerchant) {
        this.setState({
          formWizard: 1,
        });
        await this.clearAll();
      }
    }
    if (prevProps.createdMerchant !== this.props.createdMerchant) {
      const newMerchant = this.props.createdMerchant;

      if (newMerchant?.merchantId) {
        await this.selectCreatedNewMerchant(newMerchant);
        this.props.onLoadTermsAndCondition(newMerchant?.termsAndCondition);
      }
    }
    if (prevProps.editBank !== this.props.editBank) {
      const editBank = this.props.editBank;

      if (editBank) {
        this.setState({
          formWizard: 1,
          selectedBank: editBank,
          selectedBankId: editBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editCategory !== this.props.editCategory) {
      const editCategory = this.props.editCategory;

      if (editCategory) {
        this.setState({
          formWizard: 2,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: editCategory.categoryId,
          selectedCategory: editCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editMerchant !== this.props.editMerchant) {
      const editMerchant = this.props.editMerchant;

      if (editMerchant) {
        this.setState({
          formWizard: 3,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: editMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    // if (prevState.termsConditions !== this.state.termsConditions) {
    //   const terms = this.state.termsConditions[this.state.selectedBankId];
    //   let selectTermsConditions = [];
    //   console.log(selectTermsConditions, terms);
    //   if (this.state.selectTermsConditions.length > 0) {
    //     if (terms && terms.length > 0) {
    //       terms.forEach((element) => {
    //         selectTermsConditions.push(element);
    //       });
    //     } else {
    //       this.state.termsConditions.forEach((element) => {
    //         selectTermsConditions.push(element);
    //       });
    //     }
    //   } else {
    //     selectTermsConditions =
    //       terms && terms.length > 0 ? terms : this.state.termsConditions;
    //   }

    //   // selectTermsConditions =
    //   //   terms && terms.length > 0
    //   //     ? [...selectTermsConditions, terms]
    //   //     : [...selectTermsConditions, this.state.termsConditions];

    //   this.setState({
    //     selectTermsConditions,
    //   });
    // }
  }

  getBankName = (bankId) => {
    const bank = this.props.bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  onSuggestionSelected = (event, { suggestion }) => {
    // Handle the selection of a suggestion if needed
    console.log("Selectedsuggestion:", suggestion);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {};

  autoCompleteInputHandleChange = (event, key) => {
    const updatedDataForm = {
      ...this.state.dataForm,
      [key]: {
        ...this.state.dataForm[key],
        value: event,
        touched: true,
      },
    };
    this.setState({ dataForm: updatedDataForm });
  };

  checkAutoCompleteValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  };

  async fetchSuggestions(query) {
    console.log("query", query);
    if (!query.trim()) {
      console.log("query", query.length);
      this.setState({ option: [] });
      return;
    }
    try {
      const response = await cooFindMerchantByName(query?.trim());
      console.log("merchantSearch", response);
      const suggestions = response?.data?.data.map((merchant) => ({
        id: merchant.merchantId,
        value: merchant.merchantName,
        label: merchant.merchantName,
      }));
      this.setState({ option: suggestions });
      if (response?.data?.data?.length > 0) {
        this.setState({
          dataSourceMerchant: response?.data?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  onSelectAutoComplete(data) {
    console.log("onSelect", data);
  }

  onSelect = async (data) => {
    console.log("onSelect", data);
  };

  groupTermsByBankId = (termsAndConditionArray) => {
    return termsAndConditionArray.reduce((acc, term) => {
      const { bankId } = term;
      // If the bankId is not already a key in the accumulator, add it with an empty array
      if (!acc[bankId]) {
        acc[bankId] = [];
      }
      // Push the current term into the array for the corresponding bankId
      acc[bankId].push(term);
      return acc;
    }, {});
  };

  handleAddMobile = (mobileNo) => {
    console.log("handleAddMobile", mobileNo);
    this.setState((prevState) => ({
      mobileList: [...prevState.mobileList, mobileNo],
    }));
  };

  handleAddEmail = (email) => {
    console.log("handleAddEmail", email);
    this.setState((prevState) => ({
      setEmailList: [...prevState.emailList, email],
    }));
  };

  render() {
    const {
      viewPointHeight,
      dataForm,
      dataSourceMerchant,
      dataSourceBanks,
      dataSourceCategories,
      selectedMerchantID,
      recentOffers,
      isLoading,
      formWizard,
      selectLocations,
      termsConditions,
      selectTermsConditions,
      selectOffer,
      selectedBankId,
      selectedCategoryId,
      selectedBank,
      selectedCategory,
      viewPointWidth,
      settings,
      reservationEmail,
      reservationMobileNo,
      suggestions,
      option,
      isPartEdit,
      partId,
    } = this.state;
    const {
      isOpenSearchMerchant,
      selectMerchant,
      editBank,
      editCategory,
      editMerchant,
      selectedOfferData,
      selectBank,
      selectCategory,
      isOpenCreateNewMerchant,
    } = this.props;

    return (
      <>
        {this.props.isOpenSearchMerchant && (
          <section
            className={`coo__modal-main-wrapper animate__animated ${
              isOpenSearchMerchant ? "show-me" : "hide-me"
            } ${isOpenCreateNewMerchant ? "z-100" : ""} `}
          >
            <div
              className={"coo_modal-body-content fix-906 d-flex flex-column "}
              style={{ height: "97%" }}
            >
              <div
                className={
                  "d-flex flex-row justify-content-between align-items-center"
                }
              >
                <div className={"coo_mbc__header"}>{"Create new offer"}</div>

                <div
                  className={`close-modal-wrapper `}
                  onClick={() => {
                    this.props.onClickCloseSearchMerchant();
                    // this.props.onClickClearSelected();
                    // this.props.clearSearchMerchant();
                  }}
                >
                  <RiCloseLine />
                </div>
              </div>

              {formWizard === 1 && (
                <>
                  <div className={"coo_mbc__subheader"}>{"SELECT BANK"}</div>
                  <div
                    className={
                      "merchant-search-wrapper mb-3 d-flex flex-row justify-content-between align-items-center align-content-center"
                    }
                  >
                    <div className={""} style={{ width: "100%" }}>
                      <Input
                        elementConfig={dataForm.searchBank.elementConfig}
                        elementType={dataForm.searchBank.elementType}
                        required={dataForm.searchBank.validation.required}
                        isValid={!dataForm.searchBank.valid}
                        touched={dataForm.searchBank.touched}
                        value={dataForm.searchBank.value}
                        isShowRequired={false}
                        size={"sm"}
                        margin={"m-b-1 m-t-2"}
                        label={dataForm.searchBank.label}
                        changed={
                          (event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.searchBank.key
                            )
                          // console.log("event changed")
                        }
                      />
                    </div>
                    <div className={"search-action-left-wrp ml-2"}>
                      <Button
                        text={"Refresh"}
                        type={"btn-edit"}
                        size={"lg secondary"}
                        isLoading={false}
                        onClick={async () => {
                          await this.refresh();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {formWizard === 2 && (
                <>
                  <div className={"coo_mbc__subheader"}>
                    {"SELECT CATEGORY"}
                  </div>
                  <div
                    className={
                      "merchant-search-wrapper mb-3 d-flex flex-row justify-content-between align-items-center align-content-center"
                    }
                  >
                    <div className={""} style={{ width: "100%" }}>
                      <Input
                        elementConfig={dataForm.searchCategory.elementConfig}
                        elementType={dataForm.searchCategory.elementType}
                        required={dataForm.searchCategory.validation.required}
                        isValid={!dataForm.searchCategory.valid}
                        touched={dataForm.searchCategory.touched}
                        value={dataForm.searchCategory.value}
                        isShowRequired={false}
                        size={"sm"}
                        margin={"m-b-1 m-t-2"}
                        label={dataForm.searchCategory.label}
                        changed={
                          (event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.searchCategory.key
                            )
                          // console.log("event changed")
                        }
                      />
                    </div>
                    <div className={"search-action-left-wrp ml-2"}>
                      <Button
                        text={"Refresh"}
                        type={"btn-edit"}
                        size={"lg secondary"}
                        isLoading={false}
                        onClick={async () => {
                          await this.refresh();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {formWizard === 3 && (
                <>
                  <NewMerchant />
                  <div className={"coo_mbc__subheader"}>
                    {"SELECT MERCHANT"}
                  </div>
                  <div
                    className={
                      "merchant-search-wrapper mb-3 d-flex flex-row justify-content-between align-items-center align-content-center"
                    }
                  >
                    <div className={""} style={{ width: "100%" }}>
                      <AutoComplete
                        value={dataForm.searchMerchant.value}
                        options={option}
                        style={{ width: "100%" }}
                        onSelect={(data) => {
                          this.onSelectAutoComplete(data);
                        }}
                        onChange={(event) =>
                          this.autoCompleteInputHandleChange(
                            event,
                            dataForm.searchMerchant.key
                          )
                        }
                        onSearch={async () => {
                          await this.fetchSuggestions(
                            dataForm.searchMerchant.value
                          );
                          // this.props.onClickClearSelected();
                          // this.props.clearSearchMerchant();
                        }}
                        placeholder="Search merchant name here..."
                      />
                    </div>
                    <div
                      className={"search-action-left-wrp d-flex ml-2"}
                      style={{ gap: "8px" }}
                    >
                      <Button
                        text={"Refresh"}
                        type={"btn-edit"}
                        size={"lg secondary"}
                        isLoading={false}
                        onClick={async () => {
                          await this.refresh();
                        }}
                      />
                      <Button
                        text={"Create new merchant"}
                        type={"btn-edit"}
                        size={"lg secondary"}
                        isLoading={false}
                        onClick={() => {
                          this.props.onClickOpenCreateMerchant();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <Scrollbars
                style={{ height: viewPointHeight - 250 }}
                thumbMinSize={30}
                renderScrollbarVertical={({ style, ...props }) => {
                  return (
                    <div
                      style={{
                        ...style,
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                      {...props}
                    />
                  );
                }}
                universal={true}
                autoHide
              >
                {/*merchant list view */}
                {formWizard === 3 && (
                  <div className={"merchant-list-wrapper "}>
                    {dataSourceMerchant && dataSourceMerchant.length > 0 && (
                      <>
                        {dataSourceMerchant
                          .filter((item) => {
                            if (dataForm.searchMerchant.value === "") {
                              return item; // Corrected the return statement
                            } else if (
                              item.merchantName
                                ?.toLowerCase()
                                .includes(
                                  dataForm.searchMerchant.value.toLowerCase()
                                )
                            ) {
                              return item; // Corrected the return statement
                            }
                          })
                          .map((item, index) => (
                            <>
                              {/* <MerchantSelectCard
                                onSelectedMerchant={async () => {
                                  this.setState({ recentOffers: [] });
                                  this.setState({
                                    selectedMerchantID: item.merchantId,
                                    selectedMerchant: item,
                                  });

                                  console.log(
                                    "termsAndCondition",
                                    item.termsAndCondition
                                  );
                                  // if (Array.isArray(item.termsAndCondition)) {
                                  //   //console.log('found array');
                                  //   const x = this.groupTermsByBankId(item.termsAndCondition);
                                  //   console.log(x);
                                  //   this.props.onLoadTermsAndCondition(
                                  //       x
                                  //   );
                                  // }
                                  //
                                  // if (item.termsAndCondition && typeof item.termsAndCondition === 'object') {
                                  //   console.log('found object');
                                  // }

                                  this.props.onClickSelectedMerchant(item);
                                  this.props.onLoadTermsAndCondition(
                                    item.termsAndCondition
                                  );
                                  await this.setState({
                                    selectTermsConditions:
                                      item.termsAndCondition,
                                    formWizard: 4,
                                  });

                                  const resOfferByMerchant =
                                    await cooGetOfferByMerchant(
                                      item.merchantId
                                    );
                                  if (
                                    resOfferByMerchant &&
                                    resOfferByMerchant.data &&
                                    resOfferByMerchant.data.status
                                  ) {
                                    this.setState({
                                      recentOffers:
                                        resOfferByMerchant &&
                                        resOfferByMerchant.data.data,
                                    });
                                  }
                                }}
                                createdAt={selectMerchant && item.createdAt}
                                isActive={
                                  item && item.merchantId === selectedMerchantID
                                }
                                name={item && item.merchantName}
                                status={item && item.status}
                                logo={item && item.logoURL}
                              /> */}
                              <MerchantSelectCardSmall
                                onSelectedMerchant={async () => {
                                  this.props.clearTermsAndCondition();
                                  this.setState({ recentOffers: [] });
                                  this.setState({
                                    selectedMerchantID: item.merchantId,
                                    selectedMerchant: {
                                      ...item,
                                      termsAndCondition:
                                        item.termsAndCondition || {},
                                    },
                                  });

                                  console.log(
                                    "termsAndCondition",
                                    item.termsAndCondition
                                  );
                                  this.props.onClickSelectedMerchant({
                                    ...item,
                                    termsAndCondition:
                                      item.termsAndCondition || {},
                                  });
                                  this.props.onLoadTermsAndCondition(
                                    item.termsAndCondition || {}
                                  );
                                  await this.setState({
                                    selectTermsConditions:
                                      item.termsAndCondition || {},
                                    formWizard: 4,
                                  });

                                  const resOfferByMerchant =
                                    await cooGetOfferByMerchant(
                                      item.merchantId
                                    );
                                  if (
                                    resOfferByMerchant &&
                                    resOfferByMerchant.data &&
                                    resOfferByMerchant.data.status
                                  ) {
                                    this.setState({
                                      recentOffers:
                                        resOfferByMerchant &&
                                        resOfferByMerchant.data.data,
                                    });
                                  }
                                }}
                                createdAt={selectMerchant && item.createdAt}
                                isActive={
                                  item && item.merchantId === selectedMerchantID
                                }
                                cardType={"merchant"}
                                preview={false}
                                editable={false}
                                name={item && item.merchantName}
                                location={item?.location}
                                logo={item && item.logoURL}
                                merchantId={item && item.merchantId}
                                termsAndCondition={item?.termsAndCondition}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </div>
                )}

                {/*merchant modification */}
                {formWizard === 4 && (
                  <>
                    <div className={"merchant-list-wrapper "}>
                      <MerchantSelectCard
                        onSelectedMerchant={() => {
                          console.log("event fire...");
                        }}
                        isEdit={false}
                        isActive={true}
                        name={selectMerchant && selectMerchant.merchantName}
                        status={selectMerchant && selectMerchant.status}
                        offers={false}
                        category={false}
                        createdAt={selectMerchant && selectMerchant.createdAt}
                        logo={selectMerchant && selectMerchant.logoURL}
                      />

                      {/*terms_condition-wrapper*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Terms & Conditions
                          </div>
                        </div>

                        {/* <Input
                          elementConfig={
                            dataForm.termsAndCondition.elementConfig
                          }
                          elementType={dataForm.termsAndCondition.elementType}
                          required={
                            dataForm.termsAndCondition.validation.required
                          }
                          isValid={!dataForm.termsAndCondition.valid}
                          touched={dataForm.termsAndCondition.touched}
                          value={dataForm.termsAndCondition.value}
                          isShowRequired={false}
                          size={"sm"}
                          margin={"m-b-1 m-t-2"}
                          label={dataForm.termsAndCondition.label}
                          changed={(event) => {
                            this.inputHandleChange(
                              event,
                              dataForm.termsAndCondition.key
                            );
                          }}
                        /> */}

                        {/* <div className={"list-view-terms_condition mt-3"}>
                          {selectMerchant &&
                            termsConditions &&
                            (selectTermsConditions || []).map((item, index) => (
                              <Popconfirm
                                title="Remove the term"
                                description="Are you sure to remove this term?"
                                placement="topRight"
                                onConfirm={() =>
                                  this.onRemoveTermsAndConditions(index)
                                }
                                onCancel={() => {
                                  const { termsConditions } = this.state;

                                  termsConditions.splice(index, 1);
                                  // const updatedTermsConditions = termsConditions.filter(
                                  //   (item) => item.id !== i
                                  // );
                                  this.setState({ termsConditions });
                                  console.log(this.state.termsConditions);
                                  this.removeMerchantTerms();
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <InfoRowView
                                  type="default"
                                  text={item.value}
                                  isSelect={item.isActive}
                                  style={
                                    "mb-1 justify-content-between align-items-center"
                                  }
                                  onClick={() =>
                                    this.onRemoveTermsAndConditions(index)
                                  }
                                />
                              </Popconfirm>
                            ))}
                        </div> */}
                        <div className={"cco__mtc-tab"}>
                          <div>
                            <div className={"cco__mtc-tab mt-2"}>
                              {/* {Object.keys(this.props.termsAndConditionList)
                                ?.length > 0 &&
                              Object.keys(
                                this.props.termsAndConditionList
                              ).find((id) => id === selectedBankId) ? (
                                <>
                                  <div className={"cco__mtc-tab mt-2"}>
                                    <Tabs defaultActiveKey="1">
                                      {this.props.termsAndConditionList &&
                                        typeof this.props
                                          .termsAndConditionList === "object" &&
                                        Object.keys(
                                          this.props.termsAndConditionList
                                        )
                                          .filter((id) => id === selectedBankId)
                                          .map((bankId) => (
                                            <TabPane
                                              tab={this.getBankName(bankId)}
                                              key={bankId}
                                            >
                                              <div
                                                className={`cco__terms-tab-wrapper`}
                                              >
                                                {termsConditions.map(
                                                  (term, idx) => (
                                                    <InfoRowView
                                                      key={term.id}
                                                      type="default"
                                                      text={term.value}
                                                      isSelect={term.isActive}
                                                      style={
                                                        "mb-1 justify-content-between align-items-center"
                                                      }
                                                      onClick={() =>
                                                        this.onClickTermsAndConditions(
                                                          idx
                                                        )
                                                      }
                                                    />
                                                  )
                                                )}
                                              </div>
                                            </TabPane>
                                          ))}
                                    </Tabs>
                                  </div>
                                </>
                              ) : ( */}
                              <>
                                <div className={"cco__mtc-tab mt-2"}>
                                  <Tabs defaultActiveKey="1">
                                    <TabPane
                                      tab={this.getBankName(selectedBankId)}
                                      key={selectedBankId}
                                    >
                                      <CreateTermsAndConditions
                                        key={1}
                                        bankName={
                                          selectedBank && selectedBank.bankName
                                        }
                                        bankId={selectedBankId}
                                        termsAndCondition={
                                          this.props.termsAndConditionList
                                        }
                                      />
                                      {/* <Input
                                              elementConfig={
                                                dataForm.termsAndCondition
                                                  .elementConfig
                                              }
                                              elementType={
                                                dataForm.termsAndCondition
                                                  .elementType
                                              }
                                              required={
                                                dataForm.termsAndCondition
                                                  .validation.required
                                              }
                                              isValid={
                                                !dataForm.termsAndCondition
                                                  .valid
                                              }
                                              touched={
                                                dataForm.termsAndCondition
                                                  .touched
                                              }
                                              value={
                                                dataForm.termsAndCondition.value
                                              }
                                              isShowRequired={false}
                                              size={"sm"}
                                              margin={"m-b-1 m-t-2"}
                                              label={
                                                dataForm.termsAndCondition.label
                                              }
                                              changed={(event) => {
                                                this.inputHandleChange(
                                                  event,
                                                  dataForm.termsAndCondition.key
                                                );
                                              }}
                                            />
                                            {!isPartEdit && (
                                              <Button
                                                text={"Add to list"}
                                                type={"btn-edit mt-2"}
                                                isLoading={false}
                                                onClick={async () => {
                                                  await this.handleAddTermsAndCondition();
                                                }}
                                                size={"sm primary"}
                                              />
                                            )}

                                            {isPartEdit && (
                                              <div
                                                className={
                                                  "tc_part_edit-action-wrapper d-flex"
                                                }
                                              >
                                                {dataForm.termsAndCondition
                                                  .value && (
                                                  <Button
                                                    text={"update"}
                                                    type={"btn-edit mt-2 mr-2"}
                                                    isLoading={false}
                                                    onClick={() => {
                                                      this.props.onClickEdit({
                                                        bankId: selectedBankId,
                                                        id: partId,
                                                        newValue:
                                                          dataForm
                                                            .termsAndCondition
                                                            .value,
                                                      });
                                                      this.setState({
                                                        partId: null,
                                                        isPartEdit: false,
                                                      });
                                                      OpenNotification(
                                                        "Success",
                                                        "Done",
                                                        "success"
                                                      );
                                                    }}
                                                    size={"sm primary"}
                                                  />
                                                )}

                                                <Button
                                                  text={"cancel"}
                                                  type={"btn-edit mt-2"}
                                                  isLoading={false}
                                                  onClick={() =>
                                                    this.setState({
                                                      partId: null,
                                                      isPartEdit: false,
                                                    })
                                                  }
                                                  size={"sm primary"}
                                                />
                                              </div>
                                            )}

                                            {termsConditions &&
                                              termsConditions.length > 0 && (
                                                <div
                                                  className={`cco__terms-tab-wrapper`}
                                                >
                                                  {termsConditions.map(
                                                    (term, idx) => (
                                                      <InfoRowView
                                                        key={term.id}
                                                        type="default"
                                                        text={term.value}
                                                        isSelect={term.isActive}
                                                        style={
                                                          "mb-1 justify-content-between align-items-center"
                                                        }
                                                        onClick={() =>
                                                          this.onClickTermsAndConditions(
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              )} */}
                                    </TabPane>
                                  </Tabs>
                                </div>
                              </>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <CreateNewTermsAndConditions
                        key={1}
                        bankName={this.getBankName(selectedBankId)}
                        bankId={selectedBankId}
                      /> */}

                      {/*location*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Location
                          </div>
                        </div>

                        <div className={"d-flex justify-content-between"}>
                          <div className="shw_textWrapper">
                            <Input
                              elementConfig={dataForm.txtLocation.elementConfig}
                              elementType={dataForm.txtLocation.elementType}
                              required={
                                dataForm.txtLocation.validation.required
                              }
                              isValid={!dataForm.txtLocation.valid}
                              touched={dataForm.txtLocation.touched}
                              value={dataForm.txtLocation.value}
                              isShowRequired={false}
                              size={"sm"}
                              margin={"m-b-1 m-t-2"}
                              label={dataForm.txtLocation.label}
                              changed={(event) => {
                                console.log(event);
                                this.inputHandleChange(
                                  event,
                                  dataForm.txtLocation.key
                                );
                              }}
                            />
                          </div>
                          <div className={"action-bar-right"}>
                            <Button
                              text={"Add to list"}
                              type={"btn-edit"}
                              size={"sm primary"}
                              onClick={async () => {
                                const updateForm = { ...this.state.dataForm };
                                if (updateForm.txtLocation.value) {
                                  await this.updateMerchantLocations(
                                    updateForm.txtLocation.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>

                        {selectMerchant && selectMerchant.location && (
                          <div className={"d-flex mt-1"}>
                            {(selectLocations || []).map((item, index) => (
                              <SelectBox
                                index={index}
                                remove={false}
                                isSelect={item.isActive}
                                select={true}
                                onClick={() =>
                                  this.onSelectLocation(item, index)
                                }
                                onRemove={() =>
                                  this.onSelectLocation(item, index)
                                }
                                format={"text"}
                                text={item.tagName}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      {/*Reservation Contact*/}
                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Reservation contact
                          </div>
                        </div>
                        <ReservationContact merchantId={selectedMerchantID} />

                        <div className={"d-flex  flex-row mt-1"}>
                          {reservationEmail && (
                            <SelectBox
                              index={0}
                              remove={false}
                              isSelect={true}
                              select={true}
                              // onClick={() => this.onSelectLocation(item, index)}
                              onRemove={() => {
                                if (reservationEmail) {
                                  this.setState({
                                    reservationEmail: null,
                                  });
                                }
                              }}
                              format={"text"}
                              text={reservationEmail}
                            />
                          )}

                          {reservationMobileNo && (
                            <SelectBox
                              index={0}
                              remove={false}
                              isSelect={true}
                              select={true}
                              // onClick={() => this.onSelectLocation(item, index)}
                              onRemove={() => {
                                if (reservationMobileNo) {
                                  this.setState({
                                    reservationMobileNo: null,
                                  });
                                }
                              }}
                              format={"text"}
                              text={reservationMobileNo}
                            />
                          )}
                        </div>
                      </div>
                      {/*location*/}

                      <div
                        className={
                          "terms_condition-wrapper d-flex flex-column  mt-3"
                        }
                      >
                        <div
                          className={
                            "section-header-wrp d-flex justify-content-between"
                          }
                        >
                          <div className={"section-header mt-2 mb-2"}>
                            Recent offer
                          </div>
                        </div>

                        {recentOffers && recentOffers.length > 0 && (
                          <>
                            <Slider
                              {...settings}
                              className={
                                "rpmw__slider d-flex flex-row justify-content-center mb-2"
                              }
                            >
                              {((recentOffers && recentOffers) || []).map(
                                (item, index) => (
                                  <div className="rpmw__s-item">
                                    <RecentOfferCard
                                      header={item && item.header}
                                      label={item && item.label}
                                      status={item && item.status}
                                      bankLogo={
                                        item && item?.bank[0]
                                          ? item?.bank[0].logoURL
                                          : item?.bank?.logoURL
                                      }
                                      isSelect={
                                        selectOffer
                                          ? selectOffer?.offerId ===
                                            item.offerId
                                          : false
                                      }
                                      createdAt={item && item.createdAt}
                                      imgUrl={item && item.bannerImageURL}
                                      onSelectedMerchant={() => {
                                        if (
                                          selectOffer &&
                                          selectOffer.offerId
                                        ) {
                                          this.setState({
                                            selectOffer: null,
                                          });
                                        } else {
                                          this.setState({
                                            selectOffer: item,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </Slider>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/*banks list view */}
                {formWizard === 1 && (
                  <div className={"merchant-list-wrapper "}>
                    {dataSourceBanks && dataSourceBanks.length > 0 && (
                      <>
                        {dataSourceBanks
                          .filter((item) => {
                            if (dataForm.searchBank.value === "") {
                              return item; // Corrected the return statement
                            } else if (
                              item.bankName
                                .toLowerCase()
                                .includes(
                                  dataForm.searchBank.value.toLowerCase()
                                )
                            ) {
                              return item; // Corrected the return statement
                            }
                          })
                          .map((item, index) => (
                            <>
                              {/* <BankCard
                                onClick={async () => {
                                  this.setState({
                                    selectedBankId: item.bankId,
                                    selectedBank: item,
                                  });
                                  this.setState({ formWizard: 2 });
                                }}
                                isActive={
                                  item && item.bankId === selectedBankId
                                }
                                name={item && item.bankName}
                                logo={item && item.logoURL}
                                isEditButton={false}
                              /> */}
                              <BankCardSmall
                                onClick={async () => {
                                  await this.clearAll();
                                  this.setState({
                                    selectedBankId: item.bankId,
                                    selectedBank: item,
                                  });
                                  this.setState({ formWizard: 2 });
                                }}
                                isActive={
                                  item && item.bankId === selectedBankId
                                }
                                name={item && item.bankName}
                                logo={item && item.logoURL}
                                isEditButton={false}
                                bankId={item && item.bankId}
                              />
                            </>
                          ))}
                      </>
                    )}
                  </div>
                )}

                {/*categories list view */}
                {formWizard === 2 && (
                  <div className={"merchant-list-wrapper "}>
                    {dataSourceCategories &&
                      dataSourceCategories.length > 0 && (
                        <>
                          {dataSourceCategories
                            .filter((item) => {
                              if (dataForm.searchCategory.value === "") {
                                return item; // Corrected the return statement
                              } else if (
                                item.categoryName
                                  .toLowerCase()
                                  .includes(
                                    dataForm.searchCategory.value.toLowerCase()
                                  )
                              ) {
                                return item; // Corrected the return statement
                              }
                            })
                            .map((item, index) => (
                              <>
                                {/* <MerchantDetailCard
                                merchantName={item?.categoryName}
                                isActive={
                                  item && item.categoryId === selectedCategoryId
                                }
                                webURL={item?.categoryId}
                                cardType={"category"}
                                iconName={item?.iconName}
                                status={item?.status}
                                createdAt={item?.createdAt}
                                btnText={"select"}
                                onClickEdit={async () => {
                                  this.setState({
                                    selectedCategoryId: item.categoryId,
                                    selectedCategory: item,
                                    formWizard: 3,
                                  });
                                }}
                              /> */}
                                <MerchantDetailCardSmall
                                  merchantName={item?.categoryName}
                                  isActive={
                                    item &&
                                    item.categoryId === selectedCategoryId
                                  }
                                  iconName={item?.iconName}
                                  categoryId={item && item.categoryId}
                                  onClick={async () => {
                                    await this.setState({
                                      selectedCategoryId: item.categoryId,
                                      selectedCategory: item,
                                      formWizard: 3,
                                    });
                                  }}
                                />
                              </>
                            ))}
                        </>
                      )}
                  </div>
                )}
              </Scrollbars>
              {!editBank && !editCategory && !editMerchant && (
                <div
                  className={
                    "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="page-number">{formWizard} of 4</div>
                  <div>
                    {formWizard !== 1 && (
                      <Button
                        leftIconFill={true}
                        iconName={<RiArrowLeftLine />}
                        leftIcon={false}
                        rightIcon={false}
                        option={"ml-1"}
                        isLoading={false}
                        isActiveAnimation={false}
                        reactRightIcon={GetIcon("")}
                        onClick={async () => {
                          if (formWizard === 4) {
                            this.setState({
                              formWizard: 3,
                              termsConditions: [],
                              selectTermsConditions: [],
                            });
                          } else if (formWizard === 3) {
                            this.setState({ formWizard: 2 });
                          } else if (formWizard === 2) {
                            this.setState({ formWizard: 1 });
                          } else {
                            return false;
                          }
                        }}
                        LeftIconText={null}
                        text={"Back"}
                        size={"md"}
                        type={"btn-secondary"}
                      />
                    )}

                    <Button
                      leftIconFill={true}
                      iconName={
                        formWizard === 4 ? (
                          <RiCheckLine />
                        ) : (
                          <RiArrowRightLine />
                        )
                      }
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (
                          formWizard === 4 &&
                          selectMerchant !== "" &&
                          selectedBank !== "" &&
                          selectedCategoryId !== null &&
                          selectedCategory !== ""
                        ) {
                          console.log("draft save::::", selectMerchant);
                          let resDraft = await saveDraft({
                            userId: this.props.userData?.userId,
                            categoryId: selectedCategory?.categoryId,
                            merchantId: selectMerchant?.merchantId,
                            bankId: selectedBank?.bankId,
                            label: this.state.selectOffer?.label,
                            header: this.state.selectOffer?.header,
                            bannerImageURL:
                              this.state.selectOffer?.bannerImageURL,
                            referenceURL: this.state.selectOffer?.referenceURL,
                            offerType: this.state.selectOffer?.offerType,
                            offerRate: this.state.selectOffer?.offerRate,
                            offerDateType:
                              this.state.selectOffer?.offerDateType,
                            offerStart: this.state.selectOffer?.offerStart,
                            offerEnd: this.state.selectOffer?.offerEnd,
                            description: this.state.selectOffer?.description,
                            cardTypes: selectedBank?.cardTypes,
                            validDate: this.state.selectOffer?.validDate,
                            startTime: this.state.selectOffer?.startTime,
                            endTime: this.state.selectOffer?.endTime,
                            validTimeDateDescription:
                              this.state.selectOffer?.validTimeDateDescription,
                            termsConditions: {
                              [selectedBank?.bankId]:
                                selectMerchant?.termsAndCondition[
                                  selectedBank?.bankId
                                ] || [],
                            },
                            location: selectLocations,
                            hotLine: selectMerchant?.hotLine,
                            customizeDate: {
                              endDate:
                                this.state.selectOffer?.customizeDate?.endDate,
                              description:
                                this.state.selectOffer?.customizeDate
                                  ?.description,
                            },
                            promoCode: this.state.selectOffer?.promoCode,
                            status: "New",
                          });
                          console.log({ resDraft });
                          if (
                            resDraft &&
                            resDraft.data &&
                            resDraft.data.status
                          ) {
                            OpenNotification(
                              "success",
                              "Draft saved successfully!",
                              "success"
                            );
                            this.props.setCreateOfferData({
                              draftOffer: resDraft?.data?.data,
                              selectOffer: resDraft?.data?.data,
                              selectedMerchant: selectMerchant,
                              selectedBank: selectedBank,
                              selectedCategory: selectedCategory,
                              selectLocations: selectLocations,
                              selectTerms: this.props.termsAndConditionList,
                            });
                            if (
                              this.props.isAddNew &&
                              !this.props.isTermsAndConditions
                              // ||
                              // Object.keys(selectMerchant.termsAndCondition)
                              //   .length !==
                              //   Object.keys(this.props.termsAndConditionList)
                              //     .length
                            ) {
                              const terms =
                                Object.keys(this.props.termsAndConditionList)
                                  .length > 0
                                  ? { ...this.props.termsAndConditionList }
                                  : { ...this.props.existingTermsList };

                              const transformedTerms = Object.keys(
                                terms
                              ).reduce((acc, key) => {
                                acc[key] = terms[key].map((x) => ({
                                  id: x.id,
                                  bankId: x.bankId,
                                  value: x.value,
                                  count: x.count,
                                  isActive: false,
                                }));
                                return acc;
                              }, {});

                              let body = {
                                termsAndCondition: transformedTerms,
                              };
                              console.log("cooUpdateMerchantLocations", body);

                              let res = await cooUpdateMerchantTerms(
                                body,
                                selectMerchant.merchantId
                              );
                              console.log("cooUpdateMerchantLocations", res);
                              if (res && res.data && res.data.status) {
                                // this.setState({isLoading: false});
                                let merchant = res.data.data;
                                this.props.onClickSelectedMerchant(merchant);
                                this.props.onClickCloseSearchMerchant();
                                this.props.refreshOffers();
                              } else {
                                this.setState({ isLoading: false });
                              }
                            }
                            this.props.onClickCloseSearchMerchant();
                            this.props.refreshOffers();
                          }
                        } else if (
                          formWizard === 3 &&
                          selectedMerchantID !== null
                        ) {
                          this.setState({ formWizard: 4 });
                        } else if (
                          formWizard === 2 &&
                          selectedCategoryId !== null
                        ) {
                          this.setState({ formWizard: 3 });
                          await this.refreshMerchants();
                        } else if (
                          formWizard === 1 &&
                          selectedBankId !== null
                        ) {
                          this.setState({ formWizard: 2 });
                        } else {
                          return false;
                        }
                      }}
                      LeftIconText={null}
                      text={formWizard === 4 ? "Create new offer" : "Next"}
                      size={"md"}
                      type={"btn-primary"}
                    />
                  </div>
                </div>
              )}
              {(editBank || editCategory || editMerchant) && (
                <div
                  className={
                    "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="page-number">{formWizard} of 4</div>
                  <div>
                    {formWizard !== 1 && (
                      <Button
                        leftIconFill={true}
                        iconName={<RiArrowLeftLine />}
                        leftIcon={false}
                        rightIcon={false}
                        option={"ml-1"}
                        isLoading={false}
                        isActiveAnimation={false}
                        reactRightIcon={GetIcon("")}
                        onClick={async () => {
                          this.setState({ formWizard: formWizard - 1 });
                        }}
                        LeftIconText={null}
                        text={"Back"}
                        size={"md"}
                        type={"btn-block"}
                      />
                    )}

                    <Button
                      leftIconFill={true}
                      iconName={
                        formWizard === 4 ? (
                          <RiCheckLine />
                        ) : (
                          <RiArrowRightLine />
                        )
                      }
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (editBank?.bankId) {
                          this.props.setCreateOfferData({
                            selectOffer: this.state.selectOffer,
                            selectedMerchant: selectMerchant,
                            selectedBank: selectedBank,
                            selectedCategory: selectCategory,
                            selectLocations: selectLocations,
                            selectTerms: this.props.termsAndConditionList,
                          });
                          this.props.onClickCloseSearchMerchant();
                        } else if (
                          formWizard === 3 &&
                          editCategory.categoryId
                        ) {
                          this.props.setCreateOfferData({
                            selectOffer: this.state.selectOffer,
                            selectedMerchant: selectMerchant,
                            selectedBank: selectedBank,
                            selectedCategory: selectedCategory,
                            selectLocations: selectLocations,
                            selectTerms: this.props.termsAndConditionList,
                          });
                          this.props.onClickCloseSearchMerchant();
                        } else if (formWizard === 4) {
                          this.props.setCreateOfferData({
                            selectOffer: this.state.selectOffer,
                            selectedMerchant: selectMerchant,
                            selectedBank: selectedBank,
                            selectedCategory: selectedCategory,
                            selectLocations: selectLocations,
                            selectTerms: this.props.termsAndConditionList,
                          });
                          this.props.onClickCloseSearchMerchant();
                        } else if (formWizard === 2) {
                          this.setState({ formWizard: 3 });
                        } else if (
                          formWizard === 1 &&
                          selectedMerchantID !== null
                        ) {
                          this.setState({ formWizard: 2 });
                        } else {
                          return false;
                        }
                      }}
                      LeftIconText={null}
                      text={formWizard === 4 ? "Create new offer" : "Next"}
                      size={"md"}
                      type={"btn-block"}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    isOpenSearchMerchant: state.merchantReducer.isOpenSearchMerchant,
    createdMerchant: state.merchantReducer.createdMerchant,
    isOpenCreateNewMerchant: state.merchantReducer.isOpenCreateNewMerchant,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    isTermsAndConditions: state.termsReducer.isTermsAndConditions,
    isAddNew: state.termsReducer.isAddNew,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
    existingTermsList: state.termsReducer.existingTermsList,
    bankData: state.bankReducer.bankData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseSearchMerchant: () =>
      dispatch({ type: actionTypes.CLOSE_SEARCH_MERCHANT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickOpenTermsAndConditions: () =>
      dispatch({ type: actionTypes.OPEN_TERMS_AND_CONDITIONS_MODAL }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    onClickAddTermsIntoList: (payload) =>
      dispatch({ type: actionTypes.SELECTED_EXISTING_TERMS, payload: payload }),
    addNewTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.ADD_NEW_TERMS_INTO_LIST, payload: data }),
    clearTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.CLEAR_TERMS_AND_CONDITION }),
    onClickEdit: (data) =>
      dispatch({ type: actionTypes.EDIT_TERMS_INTO_LIST, payload: data }),
    onClickEnableEditOption: (data) =>
      dispatch({ type: actionTypes.EDIT_TERMS_INTO_LIST, payload: data }),
    onClickDelete: (data) =>
      dispatch({ type: actionTypes.DELETE_TERMS_INTO_LIST, payload: data }),
    onClickOpenCreateMerchant: (data) =>
      dispatch({ type: actionTypes.OPEN_CREATE_NEW_MERCHANT, payload: data }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
