import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import { Button, ButtonIcon, CustomTag, GroupHeader, Input } from "../..";

import { RiFileUploadLine } from "react-icons/ri";
import { GetIcon } from "../../../config/icon";
import { Scrollbars } from "react-custom-scrollbars";

const Index = (props) => (
  <>
    <GroupHeader option={"mt-1 mb-2"} title={props.title} />

    <div className={"img-upload-wrapper"}>
      {!props.isImageUploading && (
        <>
          <input
            id="eventFileUpload"
            type="file"
            onChange={props.onSelectFile}
            hidden
          />
          <div
            className={"event-img-upload"}
            onClick={() => {
              document.getElementById("eventFileUpload").click();
            }}
          >
            Upload File
            {props.fileName && (
              <div className={"file-details-wrp"}>
                <div className={"file-name"}>{props.fileName}</div>
              </div>
            )}
          </div>
        </>
      )}
      {props.isImageUploading && (
        <>
          <div className={"event-img-upload file-details-wrp file-name"}>
            Uploading...
          </div>
        </>
      )}
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
