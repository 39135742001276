import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../store/actions/types";
import { Scrollbars } from "react-custom-scrollbars";

import { login, userDetails, userLogOut } from "../../utils";
import { authentication, getBank, updateUserBankDetails } from "../../api";

import { GetIcon } from "../../config/icon";

import {
  Input,
  Button,
  JoinFormHeader,
  FilterColor_1,
  FilterColor_2,
} from "../../components";
import { OpenNotification } from "../../config/notification";
import { setUser } from "../../store/actions";
import { RiArrowRightLine, RiLogoutCircleRLine } from "react-icons/ri";

class BankAccountComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      noOfSpot: 2,
      active: 1,
      isLoading: false,
      isBankLoading: false,
      dataForm: {
        bank: {
          key: "bank",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "Ex : ABC Bank ",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          selectId: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Bank",
        },
        name: {
          key: "name",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Account holder name",
        },
        accountNo: {
          key: "accountNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Account no",
        },
        branch: {
          key: "branch",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Branch",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.setState({
      isBankLoading: true,
    });
    let resBank = await getBank();
    const updateForm = { ...this.state.dataForm };

    updateForm["bank"].elementConfig.options = await this.syncBank(
      resBank.data
    );
    this.setState({
      dataForm: updateForm,
      isBankLoading: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        id: data.bankId,
        value: data.bankName,
        displayValue: data.bankName,
        logoURL: data.logoURL,
        cardTypes: data.cardTypes,
      });
    }
    return resList;
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "bank":
        val = event && event.target ? event.target.value : event;
        let dataBank = updateForm.bank.elementConfig.options.find(
          (x) => x.value === val
        );
        updatedFormElement.value = val;
        updatedFormElement.selectId = dataBank?.id;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
    console.log({ updateForm });
  };

  onClickSaveBankDetails = async () => {
    try {
      this.setState({ isLoading: true });
      const dataForm = this.state.dataForm;

      let body = {
        bankId: dataForm.bank.selectId,
        bankName: dataForm.bank.value,
        accountHolderName: dataForm.name.value,
        accountNo: dataForm.accountNo.value,
        branch: dataForm.branch.value,
      };
      // console.log("body:", body);
      //todo
      //validation
      //authentication
      let res = await updateUserBankDetails(body);

      console.log("authentication:", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let user = res.data.data.user;
        await login(res.data.data.token);
        await this.props.setUser(user);
        this.props.history.push("/");
      } else {
        OpenNotification("error", "Invalid login details.", "error");
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error("GOT LOGIN ERROR:", e);
      OpenNotification("error", e, "error");
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { dataForm, isLoading, isBankLoading } = this.state;
    const { loginUser } = this.props;
    return (
      <>
        <div className={"main-fix-bg"} />
        {/* <div className={"join-artworkTop"}>
          <img src={require("../../assets/img/join_artWork_top.svg")} />
        </div> */}

        <section className={"container"}>
          <div className={"profile-wrapper"}>
            <div className=" d-flex flex-row">
              <div className={"profile-img"}>
                {loginUser?.profileAvatar && (
                  <img
                    alt={"credit card offers"}
                    src={loginUser?.profileAvatar}
                  />
                )}
              </div>
              <div className={"profile-details"}>
                <div className={"name"}>
                  Hi{" "}
                  {loginUser && loginUser?.firstName
                    ? loginUser?.firstName
                    : ""}
                </div>
                <div className={"type"}>{loginUser && loginUser?.userType}</div>
              </div>
            </div>
            <button
              className={"user-logout"}
              onClick={async (e) => {
                let userState = await userLogOut(e);
                window.location.replace("/sign-in");
                if (userState) {
                  // dispatch(clearUserDetails());
                  window.location.reload();
                  window.location.replace("/sign-in");
                }
              }}
            >
              Logout
            </button>
          </div>
          <div className="row ">
            <section className={"login-main-wrapper"}>
              <div className={"welcome-body-wrp"}>
                <div className={"login-b-left-wrp "}>
                  <div className={"welcome-body-header d-flex flex-column"}>
                    <JoinFormHeader
                      isLogo={true}
                      wizardNo={1}
                      history={this.props.history}
                      header={"Setup bank account"}
                    />
                  </div>
                  <div className={"cco__login-form-wrapper"}>
                    <>
                      <Input
                        elementConfig={dataForm.bank.elementConfig}
                        elementType={dataForm.bank.elementType}
                        required={dataForm.bank.validation.required}
                        isValid={!dataForm.bank.valid}
                        touched={dataForm.bank.touched}
                        value={dataForm.bank.value}
                        defaultValue={dataForm.bank.defaultValue}
                        isLoading={isBankLoading}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.bank.label}
                        // onKeyPress={this.handleKeyPress.bind(this)}
                        // onClickAddTag={this.onClickAddLocation.bind(this)}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.bank.key)
                        }
                      />

                      <Input
                        elementConfig={dataForm.name.elementConfig}
                        elementType={dataForm.name.elementType}
                        required={dataForm.name.validation.required}
                        isinvalid={!dataForm.name.valid}
                        touched={dataForm.name.touched}
                        value={dataForm.name.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.name.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.name.key)
                        }
                      />
                      <Input
                        elementConfig={dataForm.accountNo.elementConfig}
                        elementType={dataForm.accountNo.elementType}
                        required={dataForm.accountNo.validation.required}
                        isinvalid={!dataForm.accountNo.valid}
                        touched={dataForm.accountNo.touched}
                        value={dataForm.accountNo.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.accountNo.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.accountNo.key)
                        }
                      />
                      <Input
                        elementConfig={dataForm.branch.elementConfig}
                        elementType={dataForm.branch.elementType}
                        required={dataForm.branch.validation.required}
                        isinvalid={!dataForm.branch.valid}
                        touched={dataForm.branch.touched}
                        value={dataForm.branch.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.branch.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.branch.key)
                        }
                      />
                    </>
                  </div>

                  <div className={"login-footer-action-wrapper d-flex mt-4"}>
                    <Button
                      leftIcon={false}
                      rightIcon={false}
                      rightIconFill={true}
                      iconName={<RiArrowRightLine />}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={() => this.onClickSaveBankDetails()}
                      text={"Done"}
                      size={"lg"}
                      type={"btn-block"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className={"filter-bg-1"}>
            <FilterColor_1 />
          </div>
          <div className={"filter-bg-2"}>
            <FilterColor_2 />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.userReducer.data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountComponent);
