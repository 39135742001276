import React from "react";
import "./styles.scss";
import "./mobile.scss";
import { GetIcon } from "../../../config/icon";

const Index = (props) => (
  <>
    <div
      className={`footer-card-item-wrapper d-flex ${props.type} ${props.style}`}
      onClick={props.onClick}
    >
      {props.type === "bank" && (
        <div className={`offer-bank-img-wrapper ${props.bank}`}>
          {props.img}
        </div>
      )}

      {props.type === "icon" && (
        <>
          <div
            className={`offer-card-icon-wrapper ${
              props.isLoading ? "loading" : ""
            } ${props?.isFavorites ? "active-icon" : ""}`}
          >
            <div className="spinner">
              <div className="preloder">
                <div className="loder" />
              </div>
            </div>
            {GetIcon(props.iconName)}
          </div>
        </>
      )}

      <div className={"offer-ct-text-wrapper d-flex"}>
        <div className={"oct-title"}>{props.title}</div>
        <div className={"oct-header"}>{props.content}</div>
      </div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
