import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import {
  Button,
  OfferCard,
  OfferCountCard,
  PageHeader,
  Payment,
  ProfileCard,
} from "../../components";
import * as actionTypes from "../../store/actions/types";
import {
  getAdminUser,
  getOffer,
  getSessionValidateStatus,
  createNewPayable,
  getOfferByStatus,
  getPendingPaymentsByUserId,
} from "../../api";
import { Spin } from "antd";
import { userDetails, userLogOut } from "../../utils";
import { onClickOpenPayment } from "../../store/actions/payment";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
      pendingPaymentAmount: 0,
      pendingPaymentCount: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true });
      let user = await userDetails();
      console.log("user", user);
      if (user && user.userId) {
        let resOffersNew = await getOfferByStatus(1, 10, "new");
        let resOffersAccept = await getOfferByStatus(1, 10, "accepted");
        let resOffersReject = await getOfferByStatus(1, 10, "rejected");
        let resPayments = await getPendingPaymentsByUserId(
          user?.userId,
          "pending"
        );

        let resUser = await getAdminUser();
        console.log("adminUser", resUser);

        const offersDataNew = await this.resetOffers(
          resOffersNew && resOffersNew.data,
          user
        );
        const offersDataAccept = await this.resetOffers(
          resOffersAccept && resOffersAccept.data,
          user
        );
        const offersDataReject = await this.resetOffers(
          resOffersReject && resOffersReject.data,
          user
        );

        let acceptedTotalCount =
          user?.userType != "user"
            ? resOffersAccept?.totalCount || 0
            : offersDataAccept?.length || 0;
        let rejectedTotalCount =
          user?.userType != "user"
            ? resOffersReject?.totalCount || 0
            : offersDataReject?.length || 0;
        let pendingTotalCount =
          user?.userType != "user"
            ? resOffersNew?.totalCount || 0
            : offersDataNew?.length || 0;

        this.setState((prevState) => ({
          ...prevState,
          pendingOffers: offersDataNew || [],
          acceptedOffers: offersDataAccept || [],
          rejectedOffers: offersDataReject || [],
          acceptedTotalCount,
          rejectedTotalCount,
          pendingTotalCount,
          userDataSource: resUser && resUser.data,
          user: user,
          isLoading: false,
          pendingPaymentAmount: resPayments?.data?.totalPendingAmount,
          pendingPaymentCount: resPayments?.data?.pendingPaymentCount,
        }));
      }
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  pushAcceptedItems = async () => {
    let resOffersAccept = await getOfferByStatus(
      this.state.pageNo + 1,
      10,
      "accepted"
    );
    const acceptedItems = await this.resetOffers(
      resOffersAccept && resOffersAccept.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      acceptedOffers: [...prevState.acceptedOffers, ...acceptedItems],
      pageNo: prevState.pageNo + 1,
    }));
  };

  pushPendingItems = async () => {
    let resOffersNew = await getOfferByStatus(
      this.state.pendingPageNo + 1,
      10,
      "new"
    );
    const acceptedItems = await this.resetOffers(
      resOffersNew && resOffersNew.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      pendingOffers: [...prevState.pendingOffers, ...acceptedItems],
      pendingPageNo: prevState.pendingPageNo + 1,
    }));
  };

  pushRejectedItems = async () => {
    let resOffersReject = await getOfferByStatus(
      this.state.rejectPageNo + 1,
      10,
      "rejected"
    );
    const acceptedItems = await this.resetOffers(
      resOffersReject && resOffersReject.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      rejectedOffers: [...prevState.rejectedOffers, ...acceptedItems],
      rejectPageNo: prevState.rejectPageNo + 1,
    }));
  };

  async componentDidUpdate(prevProps, prevState) {
    // if (prevState.offerDataSource !== this.state.offerDataSource) {
    //   const { userData } = this.props;
    //   let offerList = this.state.offerDataSource;
    //   let pendingOffers = [];
    //   let acceptedOffers = [];
    //   let rejectedOffers = [];
    //   if (offerList.length > 0) {
    //     for (let offer of offerList) {
    //       if (userData?.userType === "admin") {
    //         if (offer.status === "new") {
    //           pendingOffers.push(offer);
    //         } else if (offer.status === "accepted") {
    //           acceptedOffers.push(offer);
    //         } else if (offer.status === "rejected") {
    //           rejectedOffers.push(offer);
    //         }
    //       } else if (
    //         userData?.userType != "admin" &&
    //         offer?.userId === userData?.userId
    //       ) {
    //         if (offer.status === "new") {
    //           pendingOffers.push(offer);
    //         } else if (offer.status === "accepted") {
    //           acceptedOffers.push(offer);
    //         }
    //       }
    //     }
    //   }
    //   // console.log({ pendingOffers, acceptedOffers });

    //   this.setState((prevState) => ({
    //     ...prevState,
    //     pendingOffers: pendingOffers,
    //     acceptedOffers: acceptedOffers,
    //     rejectedOffers,
    //   }));
    // }
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      this.setState({ isLoading: true });
      await this.getAll();
      if (!this.state.isLoading) {
        this.props.doneRefresh();
      }
    }
  }

  render() {
    const {
      offerDataSource,
      userDataSource,
      pendingOffers,
      acceptedOffers,
      isLoading,
      viewPointHeight,
      rejectedOffers,
      user,
      pendingPaymentAmount,
      pendingPaymentCount,
    } = this.state;
    const {} = this.props;
    console.log("userDataSource", userDataSource);

    return (
      <>
        <div className={`cco__dashboard-header-wrapper`}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={false}
            isDate={true}
            // onClickRefresh={async () => {
            //   let res = await this.getUsers();
            // }}
            title={"Dashboard"}
          />
        </div>
        <Scrollbars
          style={{ height: viewPointHeight - 80 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div className={`cco__dashboard-body-wrapper container`}>
            {isLoading && (
              <div style={{ margin: "10% 40%" }}>
                <Spin size="large" spinning={isLoading} />
              </div>
            )}
            {!isLoading && (
              <>
                <div className={"cco__dbw__count d-flex flex-row "}>
                  <OfferCountCard
                    title={"Active offers"}
                    count={acceptedOffers ? this.state.acceptedTotalCount : 0}
                    size={"lg"}
                  />

                  <OfferCountCard
                    title={"Pending offers"}
                    count={pendingOffers ? this.state.pendingTotalCount : 0}
                    size={"lg"}
                  />
                  {user?.userType != "user" && (
                    <OfferCountCard
                      title={"Active users"}
                      count={userDataSource ? userDataSource.length : 0}
                      size={"lg"}
                    />
                  )}
                </div>
                <div className={"cco__dbw__content "}>
                  <div className={"cco__dbwc__title"}>{"Payment"}</div>
                  <Payment
                    details={"Upcoming payment"}
                    price={"LKR " + pendingPaymentAmount?.toFixed(2)}
                    status={"Pending"}
                    records={"No.of recorders (" + pendingPaymentCount + ")"}
                    onClickView={() => this.props.onClickOpenPayment()}
                  />
                </div>

                {/* Pending offers */}
                <div
                  className={"cco__dbw__content "}
                  // style={{ paddingTop: "24px", paddingBottom: "24px" }}
                >
                  <div
                    className={"cco__dbwc__title"}
                    //style={{ paddingLeft: "24px" }}
                  >
                    {"Pending offers"}
                  </div>
                  <Scrollbars
                    style={{ height: 400 }}
                    thumbMinSize={30}
                    universal={true}
                    autoHide
                  >
                    {pendingOffers && pendingOffers.length > 0 && (
                      <>
                        {(pendingOffers || []).map((item, index) => (
                          <OfferCard
                            offerId={item && item?.offerId}
                            details={item && item.header}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            categoryName={item && item?.category?.categoryName}
                            userId={item && item?.user?.userId}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() =>
                              this.props.onClickOpenOfferView(item)
                            }
                          />
                        ))}
                      </>
                    )}
                    {!isLoading &&
                      this.state.pendingTotalCount -
                        this.state.pendingPageNo * this.state.pageViewCount >
                        0 && (
                        <div
                          className={
                            "console__load-more-wrp d-flex justify-content-center mt-3"
                          }
                        >
                          <Button
                            size={"sm"}
                            type={"btn-primary light"}
                            isLoading={isLoading}
                            onClick={async () => {
                              if (acceptedOffers?.length > 0) {
                                await this.pushPendingItems();
                              }
                            }}
                            text={`Load more offers (${
                              this.state.pendingTotalCount -
                              this.state.pendingPageNo *
                                this.state.pageViewCount
                            })`}
                          />
                        </div>
                      )}
                    {this.state.isLoading && (
                      <div style={{ margin: "0 50%", opacity: 0.5 }}>
                        <Spin size="large" spinning={isLoading} />
                      </div>
                    )}
                  </Scrollbars>
                </div>

                {/* Accepted offers */}
                <div
                  className={"cco__dbw__content"}
                  // style={{ paddingTop: "24px", paddingBottom: "24px" }}
                >
                  <div
                    className={"cco__dbwc__title"}
                    //style={{ paddingLeft: "24px" }}
                  >
                    {"Accepted offers"}
                  </div>
                  <Scrollbars
                    style={{ height: 400 }}
                    thumbMinSize={30}
                    universal={true}
                    autoHide
                  >
                    {acceptedOffers && acceptedOffers.length > 0 && (
                      <>
                        {(acceptedOffers || []).map((item, index) => (
                          <OfferCard
                            offerId={item && item?.offerId}
                            details={item && item.header}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            categoryName={item && item?.category?.categoryName}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() =>
                              this.props.onClickOpenOfferView(item)
                            }
                          />
                        ))}
                      </>
                    )}
                    {!isLoading &&
                      this.state.acceptedTotalCount -
                        this.state.pageNo * this.state.pageViewCount >
                        0 && (
                        <div
                          className={
                            "console__load-more-wrp d-flex justify-content-center mt-3"
                          }
                        >
                          <Button
                            size={"sm"}
                            type={"btn-primary light"}
                            isLoading={isLoading}
                            onClick={async () => {
                              if (acceptedOffers?.length > 0) {
                                await this.pushAcceptedItems();
                              }
                            }}
                            text={`Load more offers (${
                              this.state.acceptedTotalCount -
                              this.state.pageNo * this.state.pageViewCount
                            })`}
                          />
                        </div>
                      )}
                    {this.state.isLoading && (
                      <div style={{ margin: "0 50%", opacity: 0.5 }}>
                        <Spin size="large" spinning={isLoading} />
                      </div>
                    )}
                  </Scrollbars>
                </div>

                {/* Rejected offers */}
                <div
                  className={"cco__dbw__content"}
                  // style={{ paddingTop: "24px", paddingBottom: "24px" }}
                >
                  <div
                    className={"cco__dbwc__title"}
                    //style={{ paddingLeft: "24px" }}
                  >
                    {"Rejected offers"}
                  </div>
                  <Scrollbars
                    style={{ height: 400 }}
                    thumbMinSize={30}
                    universal={true}
                    autoHide
                  >
                    {rejectedOffers && rejectedOffers.length > 0 && (
                      <>
                        {(rejectedOffers || []).map((item, index) => (
                          <OfferCard
                            offerId={item && item?.offerId}
                            details={item && item.header}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            categoryName={item && item?.category?.categoryName}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() =>
                              this.props.onClickOpenOfferView(item)
                            }
                          />
                        ))}
                      </>
                    )}
                    {!isLoading &&
                      this.state.rejectedTotalCount -
                        this.state.rejectPageNo * this.state.pageViewCount >
                        0 && (
                        <div
                          className={
                            "console__load-more-wrp d-flex justify-content-center mt-3"
                          }
                        >
                          <Button
                            size={"sm"}
                            type={"btn-primary light"}
                            isLoading={isLoading}
                            onClick={async () => {
                              if (acceptedOffers?.length > 0) {
                                await this.pushRejectedItems();
                              }
                            }}
                            text={`Load more offers (${
                              this.state.rejectedTotalCount -
                              this.state.rejectPageNo * this.state.pageViewCount
                            })`}
                          />
                        </div>
                      )}
                    {this.state.isLoading && (
                      <div style={{ margin: "0 50%", opacity: 0.5 }}>
                        <Spin size="large" spinning={isLoading} />
                      </div>
                    )}
                  </Scrollbars>
                </div>

                {/* Users profile */}
                {user && user?.userType != "user" && (
                  <>
                    <div
                      className={"cco__dbw__content"}
                      style={{ padding: "24px" }}
                    >
                      <div
                        className={"cco__dbwc__title"}
                        style={{ paddingBottom: "16px" }}
                      >
                        {"Active users"}
                      </div>
                      {userDataSource && userDataSource.length > 0 && (
                        <>
                          {(userDataSource || []).map((item, index) => (
                            <ProfileCard
                              profileImgUrl={item && item.profileAvatar}
                              name={
                                item && item.firstName + " " + item.lastName
                              }
                              userType={
                                item?.userType?.charAt(0).toUpperCase() +
                                item?.userType?.slice(1)
                              }
                              onClickViewOffer={() =>
                                this.props.onClickOpenPayment(item)
                              }
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Scrollbars>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
    onClickOpenPayment: (payload) =>
      dispatch({
        type: actionTypes.OPEN_PAYMENT_MODAL,
        payload: payload,
      }),
    onClickOpenPaymentDetails: (payload) =>
      dispatch({
        type: actionTypes.OPEN_PAYMENT_DETAILS_MODAL,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
