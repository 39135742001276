import React, { Component } from "react";
import { connect } from "react-redux";

import "./styles.scss";
import { Spin, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";

import {
  CustomerInfoCard,
  EmptyData,
  OfferCountCard,
  PageHeader,
} from "../../components";
import Scrollbars from "react-custom-scrollbars";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      activeMerchant: null,
      dataSource: [],
      tabJson: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  render() {
    const { tabJson, isLoading, viewPointHeight } = this.state;
    const {} = this.props;

    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={true}
            isDate={false}
            isLoading={isLoading}
            // onClickRefresh={async () => {
            //   let res = await this.getUsers();
            // }}
            title={"Customer"}
          />
        </div>
        <Scrollbars
          style={{ height: viewPointHeight - 80 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div className={"cco__customer-body-wrapper container"}>
            {isLoading && (
              <div style={{ margin: "10% 40%" }}>
                <Spin size="large" spinning={isLoading} />
              </div>
            )}
            {!isLoading && (
              <>
                <div>
                  <div className={"cco__cbw__count d-flex flex-row "}>
                    <OfferCountCard
                      title={"Total Customer"}
                      count={"6"}
                      size={"lg"}
                    />
                    <OfferCountCard
                      title={"Deactivate Customer"}
                      count={"6"}
                      size={"lg"}
                    />
                    <OfferCountCard title={"Block"} count={"0"} size={"lg"} />
                  </div>
                </div>
                <div className="cco__cbw_customerInfo_wrapper">
                  <CustomerInfoCard
                    name="Damith"
                    mobileNo="0768857691"
                    date="03-OCT-2024"
                    status="Verified"
                  />
                  <CustomerInfoCard
                    name="Damith"
                    mobileNo="0768857691"
                    date=" 03-OCT-2024"
                    status="Verified"
                  />
                  <CustomerInfoCard
                    name="Damith"
                    mobileNo="0768857691"
                    date=" 03-OCT-2024"
                    status="Verified"
                  />
                  <CustomerInfoCard
                    name="Damith"
                    mobileNo="0768857691"
                    date=" 03-OCT-2024"
                    status="Verified"
                  />
                  <CustomerInfoCard
                    name="Damith"
                    mobileNo="0768857691"
                    date=" 03-OCT-2024"
                    status="Verified"
                  />
                </div>
              </>
            )}
            {/* {!isLoading && (
              <EmptyData
                title={"Customer Data Not Found"}
                imgUrl={require("../../assets/img/customer_empty.png")}
              />
            )} */}
          </div>
        </Scrollbars>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
