import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import { OpenNotification } from "../../../../config/notification";

import { cooCreateNewBank, cooUpdateBank } from "../service/bank.service";

import { Button, CustomTag, Input, PageHeader } from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import { uploadImage } from "../../../../api";
import * as actionTypes from "../../../../store/actions/types";
import Scrollbars from "react-custom-scrollbars";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      tags: [],
      tag: null,
      isLoading: false,
      isImageUploading: false,
      dataForm: {
        bankName: {
          key: "bankName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Sampth bank",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Bank name",
        },
        contactNo: {
          key: "contactNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : 0113 222 222",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Contact no",
        },
        webURL: {
          key: "webURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : www.bank.com",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Web URL",
        },
        description: {
          key: "description",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "-",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Description",
        },
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : https://picsum.photos/200",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
        thumbnailURL: {
          key: "thumbnailURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : https://picsum.photos/200",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Thumbnail Logo",
        },
        keywords: {
          key: "keywords",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Visa",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Card type",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async clearAll() {
    this.setState({
      tags: [],
      tag: null,
      isLoading: false,
    });

    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      console.log(updateForm[key]);
      updateForm[key].value = "";
      this.setState({ dataForm: updateForm });
    }
  }

  async onClickSaveData() {
    console.log("event fire");
    let dataForm = this.state.dataForm;

    this.updateFormValidity(dataForm.bankName.key);
    this.updateFormValidity(dataForm.contactNo.key);
    this.updateFormValidity(dataForm.logoURL.key);

    if (
      dataForm.bankName.valid &&
      dataForm.contactNo.valid &&
      dataForm.logoURL.valid
    ) {
      this.setState({ isLoading: true });

      const body = {
        bankName: dataForm.bankName.value,
        contactNo: dataForm.contactNo.value,
        webURL: dataForm.webURL.value,
        description: dataForm.description.value,
        logoURL: dataForm.logoURL.value,
        thumbnailURL: dataForm.thumbnailURL.value,
        cardTypes: this.state.tags,
        termsAndCondition: false,
        isEmailVerification: false,
        isBusinessVerification: false,
        isAdminVerification: false,
        isAccountVerified: false,
        username: null,
        password: null,
        address: null,
        status: "ACTIVE",
      };
      let res = await cooCreateNewBank(body);
      console.log("createnewbank", res.data);

      if (res && res.data && res.data.status) {
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
        await this.clearAll();
      } else {
        OpenNotification("error", res && res.data && res.data.error, "error");
        this.setState({ isLoading: false });
        await this.clearAll();
      }
    } else {
      OpenNotification("Error", "Invalid data", "error");
      this.setState({ isLoading: false });
    }
  }

  async onClickUpdateBankData() {
    console.log("event fire");
    let dataForm = this.state.dataForm;
    const selectBank = this.props.selectBankData;
    if (selectBank && selectBank?.bankId) {
      this.updateFormValidity(dataForm.bankName.key);
      this.updateFormValidity(dataForm.contactNo.key);
      this.updateFormValidity(dataForm.logoURL.key);

      if (
        dataForm.bankName.valid &&
        dataForm.contactNo.valid &&
        dataForm.logoURL.valid
      ) {
        this.setState({ isLoading: true });

        const body = {
          bankName: dataForm.bankName.value,
          contactNo: dataForm.contactNo.value,
          webURL: dataForm.webURL.value,
          description: dataForm.description.value,
          logoURL: dataForm.logoURL.value,
          thumbnailURL: dataForm.thumbnailURL.value,
          cardTypes: this.state.tags,
        };
        let res = await cooUpdateBank(body, selectBank.bankId);
        console.log("cooUpdateBank", res.data);

        if (res && res.data && res.data.status) {
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
          await this.clearAll();
          this.props.refreshBankList();
        } else {
          OpenNotification("error", res && res.data && res.data.error, "error");
          this.setState({ isLoading: false });
          await this.clearAll();
        }
      } else {
        OpenNotification("Error", "Invalid data", "error");
        this.setState({ isLoading: false });
      }
    } else {
      OpenNotification("Error", "Update error", "error");
      this.setState({ isLoading: false });
    }
  }

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.uploadImage(result_base64);
  }

  async uploadImage(base64) {
    this.setState({ isImageUploading: true });
    let res = await uploadImage(base64, "bank", "Bank Name");
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = res.data.data.URL;
      this.setState({ dataForm: updateForm });
      this.setState({ isImageUploading: false });
    } else {
      this.setState({ isImageUploading: true });
    }
  }

  //onClick add #tags
  onClickAddCardType = () => {
    this.setState((state) => {
      if (state.tag) {
        const tags = state.tags.concat({
          id: uuid(),
          cardName: state.tag,
          count: 0,
        });
        return {
          tags,
          tag: null,
        };
      }
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onClickAddCardType();
      const updateForm = { ...this.state.dataForm };
      updateForm["keywords"].value = "";
      this.setState({ dataForm: updateForm, tag: null });
    }
  };

  onRemoveCards = (i) => {
    console.log("remove event fire...." + i);
    this.setState((state) => {
      const tags = state.tags.filter((item, j) => i !== j);
      return {
        tags,
      };
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectBankData !== this.props.selectBankData) {
      await this.clearAll();
      const selectBank = this.props.selectBankData;

      if (selectBank && selectBank?.bankId) {
        const updateForm = { ...this.state.dataForm };

        updateForm["webURL"].value = selectBank?.webURL;
        updateForm["logoURL"].value = selectBank?.logoURL;
        updateForm["thumbnailURL"].value = selectBank?.thumbnailURL;
        updateForm["bankName"].value = selectBank?.bankName;
        updateForm["contactNo"].value = selectBank?.contactNo;
        updateForm["description"].value = selectBank?.description;
        // updateForm["offerHeader"].value = selectBank?.header;
        // updateForm["offerRate"].value = selectBank?.offerRate;

        const tags = [
          ...this.state.tags,
          ...selectBank?.cardTypes?.map((cardType) => ({
            id: cardType.id,
            cardName: cardType?.value || cardType?.tagName || cardType.cardName,
            count: cardType.count,
            isActive: false,
          })),
        ];

        this.setState({ dataForm: updateForm, tags });
      }
    }
  }

  render() {
    const { dataForm, tags, isLoading, isImageUploading, viewPointHeight } =
      this.state;
    const { selectBankData } = this.props;

    return (
      <div className={"cco__cnc-right-content-wrapper p0"}>
        <PageHeader option={"sm p-16"} title={"Create new bank"} />
        <div className={"cco_common-form-content-wrapper "}>
          <Scrollbars
            style={{ height: viewPointHeight - 220 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"group-form"}>
              {!dataForm.logoURL.value && (
                <div className={"img-upload-wrapper"}>
                  {!isImageUploading && (
                    <>
                      <input
                        id="eventImageUpload"
                        type="file"
                        onChange={async (media) => {
                          await this.toBase64(
                            document.querySelector("#eventImageUpload").files[0]
                          );
                        }}
                        hidden
                      />
                      <div
                        className={"event-img-upload"}
                        onClick={() => {
                          document.getElementById("eventImageUpload").click();
                        }}
                      >
                        Upload Bank Logo
                        {this.state.fileName && (
                          <div className={"file-details-wrp"}>
                            <div className={"file-name"}>
                              {this.state.fileName}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {isImageUploading && (
                    <>
                      <div className={"event-img-upload"}>Uploading...</div>
                    </>
                  )}
                </div>
              )}
              {dataForm.logoURL.value && (
                <div className={"cco__cnc-image-merchant-wrapper mb-4"}>
                  <img src={dataForm.logoURL.value} />
                </div>
              )}

              <Input
                elementConfig={dataForm.logoURL.elementConfig}
                elementType={dataForm.logoURL.elementType}
                required={dataForm.logoURL.validation.required}
                isinvalid={!dataForm.logoURL.valid}
                touched={dataForm.logoURL.touched}
                value={dataForm.logoURL.value}
                isShowRequired={false}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.logoURL.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.logoURL.key)
                }
              />
            </div>
            <div className={"group-form"}>
              {dataForm.thumbnailURL.value && (
                <div className={"cco__cnc-image-merchant-wrapper mb-4"}>
                  <img src={dataForm.thumbnailURL.value} />
                </div>
              )}
              <Input
                elementConfig={dataForm.thumbnailURL.elementConfig}
                elementType={dataForm.thumbnailURL.elementType}
                required={dataForm.thumbnailURL.validation.required}
                isinvalid={!dataForm.thumbnailURL.valid}
                touched={dataForm.thumbnailURL.touched}
                value={dataForm.thumbnailURL.value}
                isShowRequired={false}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.thumbnailURL.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.thumbnailURL.key)
                }
              />
            </div>

            <div className={"group-form"}>
              <Input
                elementConfig={dataForm.bankName.elementConfig}
                elementType={dataForm.bankName.elementType}
                required={dataForm.bankName.validation.required}
                isinvalid={!dataForm.bankName.valid}
                touched={dataForm.bankName.touched}
                value={dataForm.bankName.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.bankName.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.bankName.key)
                }
              />
              <Input
                elementConfig={dataForm.contactNo.elementConfig}
                elementType={dataForm.contactNo.elementType}
                required={dataForm.contactNo.validation.required}
                isinvalid={!dataForm.contactNo.valid}
                touched={dataForm.contactNo.touched}
                value={dataForm.contactNo.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.contactNo.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.contactNo.key)
                }
              />
              <Input
                elementConfig={dataForm.webURL.elementConfig}
                elementType={dataForm.webURL.elementType}
                required={dataForm.webURL.validation.required}
                isinvalid={!dataForm.webURL.valid}
                touched={dataForm.webURL.touched}
                value={dataForm.webURL.value}
                isShowRequired={false}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.webURL.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.webURL.key)
                }
              />
              <Input
                elementConfig={dataForm.description.elementConfig}
                elementType={dataForm.description.elementType}
                required={dataForm.description.validation.required}
                isinvalid={!dataForm.description.valid}
                touched={dataForm.description.touched}
                value={dataForm.description.value}
                isShowRequired={false}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.description.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.description.key)
                }
              />
            </div>
            <div className={"group-form"}>
              <Input
                elementConfig={dataForm.keywords.elementConfig}
                elementType={dataForm.keywords.elementType}
                required={dataForm.keywords.validation.required}
                isinvalid={!dataForm.keywords.valid}
                touched={dataForm.keywords.touched}
                value={dataForm.keywords.value}
                isShowRequired={false}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.keywords.label}
                onKeyPress={this.handleKeyPress.bind(this)}
                onClickAddTag={this.onClickAddCardType.bind(this)}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.keywords.key)
                }
              />
              {tags && (
                <>
                  <div className={"multiple-line-wrapper mb-2"}>
                    <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                      {(tags || []).map((item, index) => (
                        <CustomTag
                          index={index}
                          remove={true}
                          onRemoveTime={() => this.onRemoveCards(index)}
                          format={"text"}
                          text={item?.value || item?.tagName || item?.cardName}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
          <div className={"login-footer-action-wrapper d-flex mt-4 pl-4 pr-4"}>
            <Button
              leftIcon={false}
              rightIcon={false}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={async () => {
                if (selectBankData && selectBankData?.bankId) {
                  await this.onClickUpdateBankData();
                } else {
                  await this.onClickSaveData();
                }
              }}
              text={
                selectBankData && selectBankData?.bankId ? "Update" : "Done"
              }
              size={"md"}
              type={"btn-block"}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectBankData: state.bankReducer.selectBankData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    refreshBankList: () =>
      dispatch({
        type: actionTypes.REFRESH_BANK_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
