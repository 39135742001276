import React from "react";
import * as actionTypes from '../actions/types';
import {RiBillLine} from "react-icons/ri";

const initialState = {
    isConfirmOffer: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_OFFER_CONFIRM_MODAL:
            return {
                ...state,
                isConfirmOffer: true,
            };
        case actionTypes.CLOSE_OFFER_CONFIRM_MODAL:
            return {
                ...state,
                isConfirmOffer: false,
            };

        default:
            return state
    }
};

