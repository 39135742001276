import axios from "axios";
import { getToken } from "../utils";

// const BASE_URL = process.env.REACT_APP_BASE_AWS_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authentication = async (username, password) => {
  console.log("body:", username, password);
  try {
    const options = {
      headers: {
        "Content-Type": "encoding/json",
      },
      // params: {
      //     client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      //     client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      //     grant_type: process.env.REACT_APP_AUTH_GRANT_TYPE
      // }
    };

    const body = {
      userName: username,
      password: password,
    };

    // //TODO remove later
    // return {
    //     "access_token": process.env.REACT_APP_TEMP_ACCESS_TOKEN,
    //     "scope": "am_application_scope default",
    //     "token_type": "Bearer",
    //     "refresh_token": "1//xEoDL4iW3cxlI7yDbSRFYNG01kVKM2C-259HOF2aQbI",
    //     "expires_in": 3600
    // }
    console.log("bodyFF:", body);

    // Get Access Token
    let response = await axios.post(`${BASE_URL}/admin/authentication`, body);
    return response.data;
  } catch (err) {
    console.error("ERR_LOGIN_API:", err);
    throw err;
  }
};

export const reSendOtp = async (userId, mobileNo, token) => {
  try {
    const body = {
      userId: userId,
      mobileNo: mobileNo,
      token: token,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    return await axios.post(`${BASE_URL}/resend/verification/admin/otp`, body, {
      headers,
    });
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    return false;
  }
};

export const otpVerify = async (userId, OTP, token) => {
  try {
    const body = {
      otpCode: OTP,
      token: token,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.post(`${BASE_URL}/verify/admin/mobile`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    throw err;
  }
};

export const newOTPVerify = async (
  userId,
  verificationType,
  token,
  mobileNo
) => {
  console.log("newOTPVerify:", userId, verificationType, token, mobileNo);
  try {
    const body = {
      verificationType: verificationType,
      token: token,
      mobileNo: mobileNo,
      userId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.post(
      `${BASE_URL}/create/new/admin/otp/verification`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    throw err;
  }
};

export const updateUserPassword = async (body) => {
  try {
    let token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    return await axios.put(`${BASE_URL}/update/admin/user/password`, body, {
      headers: headers,
    });
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    return false;
  }
};

export const updateUserBankDetails = async (body) => {
  try {
    let token = await getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    return await axios.put(`${BASE_URL}/update/admin/user/bank/details`, body, {
      headers: headers,
    });
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    return false;
  }
};


export const getSessionValidateStatus = async () => {
  try {
    const token = getToken();
    console.log('getSessionValidateStatus', token);
    const headers = {
      "Content-Type": "application/json",
    };
    // Get response
    let response = await axios.get(
        `${BASE_URL}/session/validate/` + token,
        {
          headers: headers,
        }
    );
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  } finally {

  }
}
