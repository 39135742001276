import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div className={`cco__offer-count-card__wrapper ${props.size}`}>
      <div className={"cco__occw__title"}>{props.title}</div>
      <div className={"cco__occw__count"}>{props.count}</div>
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
