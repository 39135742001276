import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Button, Tag } from "../..";

const Index = (props) => (
  <div className={`cco__payment-card__wrapper  `}>
    <div className="d-flex flex-column">
      <div className={"cco__pcw__details"}>{props.details}</div>
      <div className={"cco__pcw__price"}>{props.price}</div>
      <div className={"cco__pcw__tags d-flex flex-row"}>
        <Tag type={"pending"} tagName={props.status} />
        <Tag type={"record"} tagName={props.records} />
      </div>
    </div>
    <div className="">
      <Button text={"View"} onClick={props.onClickView} type={"btn-accept"} />
    </div>
  </div>
);

Index.defaultProps = {
  title: "",
};
export default Index;
