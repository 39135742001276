import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const syncHNBOffers = async (category, categoryId) => {
  // Build query parameters

  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    // Make the GET request with params and headers
    const params = {
      category: category,
      categoryId: categoryId,
    };
    const response = await axios.get(
      `${BASE_URL}/sync/hnb/offers/${category}/${categoryId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncHNBOfferData = async (id) => {
  // Build query parameters

  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    const response = await axios.get(`${BASE_URL}/get/sync/hnb/offer/${id}`, {
      headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const syncHNBOffersToSync = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/sync/hnb/offers-to-sync`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};

export const getSyncHNBOffers = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/hnb/sync/offers`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_TEMP_OFFER_API:", err);
    throw err;
  }
};
