import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { RiCheckLine } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`device-selection-wrapper ${props.type} ${props.size} ${
        props.isSelect ? "is_active" : ""
      }`}
      onClick={props.onClick}
    >
      <div className="dc_icon"> {props.buttonIcon}</div>

      <div className="dc_text">{props.deviceName}</div>
      <div
        className="dc_checkbox"
        onClick={props.onClick}
        onChange={() => props.onChange()}
      >
        {props.isSelect && (
          <>
            <RiCheckLine />
          </>
        )}
      </div>
    </div>
  </>
);
Index.propTypes = {
  buttonIcon: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "dark",
  size: "md",
  icon: "<RiComputerFill/>",
  deviceName: "Desktop",
};

export default Index;
