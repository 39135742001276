import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../../store/actions/types";
import { Spin } from "antd";
import { userDetails, userLogOut } from "../../../utils";
import {
  getAdminUser,
  getOffer,
  getOfferByStatus,
  getSessionValidateStatus,
} from "../../../api";
import { Button, OfferCard } from "../../../components";
import { v4 as uuidv4 } from "uuid";
import { cooGetOfferByMerchant } from "../../merchant/service/merchant.service";

class pending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      userDataSource: [],
      categoryOptions: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
      dataForm: {
        searchOffer: {
          key: "searchOffer",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search offer title here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        keywords: {
          key: "keywords",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "Search Merchant here...",
            options: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Pending Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "Active Offers",
          content: "",
        },
        {
          id: uuidv4(),
          key: 3,
          tabName: "Rejected Offers",
          content: "",
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true });
      let user = await userDetails();
      if (user && user.userId) {
        // let resOffer = await getOffer();

        let resOffersReject = await getOfferByStatus(1, 10, "rejected");

        const offersDataReject = await this.resetOffers(
          resOffersReject && resOffersReject.data,
          user
        );

        let rejectedTotalCount =
          user?.userType != "user"
            ? resOffersReject?.totalCount || 0
            : offersDataReject?.length || 0;

        this.setState((prevState) => ({
          ...prevState,
          rejectedOffers: offersDataReject || [],
          rejectedTotalCount,
          user: user,
          isLoading: false,
        }));
        this.props.doneRefresh();
      }
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  async getOffersByMerchant(merchantId) {
    this.setState({ isLoading: true });
    let user = await userDetails();
    if (user && user.userId && merchantId) {
      const resOfferByMerchant = await cooGetOfferByMerchant(merchantId);
      console.log("resOfferByMerchant", resOfferByMerchant);
      if (
        resOfferByMerchant &&
        resOfferByMerchant.data &&
        resOfferByMerchant.data.status
      ) {
        const offersDataNew = resOfferByMerchant?.data?.data;

        let pendingTotalCount = resOfferByMerchant?.data?.totalCount || 0;

        this.setState((prevState) => ({
          ...prevState,
          pendingOffers: offersDataNew || [],
          pendingTotalCount,
          isLoading: false,
        }));
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } else {
      await this.getAll();
    }
  }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  pushRejectedItems = async () => {
    let resOffersReject = await getOfferByStatus(
      this.state.rejectPageNo + 1,
      10,
      "rejected"
    );
    const acceptedItems = await this.resetOffers(
      resOffersReject && resOffersReject.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      rejectedOffers: [...prevState.rejectedOffers, ...acceptedItems],
      rejectPageNo: prevState.rejectPageNo + 1,
    }));
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      this.setState({ isLoading: true });
      await this.getAll();
      if (!this.state.isLoading) {
        this.props.doneRefresh();
      }
    }
    if (prevProps.searchOfferTitle !== this.props.searchOfferTitle) {
      let updateDataForm = this.state.dataForm;
      updateDataForm.searchOffer.value = this.props.searchOfferTitle;
      this.setState({ dataForm: updateDataForm });
    }

    if (prevProps.selectMerchantId !== this.props.selectMerchantId) {
      this.setState({ isLoading: true });
      if (this.props.selectMerchantId !== "") {
        await this.getOffersByMerchant(this.props.selectMerchantId);
      } else {
        await this.getAll();
      }
    }

    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      if (this.props.refreshOffers) {
        this.setState((prevState) => ({ ...prevState, isLoading: true }));
        await this.getAll();
      }
    }
  }

  render() {
    const {
      dataSource,
      tags,
      viewPointHeight,
      pendingOffers,
      acceptedOffers,
      isLoading,
      rejectedOffers,
      userDataSource,
      categoryOptions,
      dataForm,
    } = this.state;
    const { editOfferData } = this.props;

    return (
      <>
        <div
          style={{
            paddingRight: "24px",
            paddingBottom: "24px",
            paddingLeft: "24px",
          }}
        >
          {isLoading && (
            <div style={{ margin: "10% 50%" }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
          {!isLoading && (
            <>
              {/* Rejected offers */}
              <div>
                <Scrollbars
                  style={{ height: viewPointHeight - 290 }}
                  thumbMinSize={30}
                  universal={true}
                  autoHide
                >
                  {rejectedOffers && rejectedOffers.length > 0 && (
                    <>
                      {rejectedOffers
                        .filter((item) => {
                          if (dataForm.searchOffer.value === "") {
                            return item; // Corrected the return statement
                          } else if (
                            item.header
                              ?.toLowerCase()
                              .includes(
                                dataForm.searchOffer.value.toLowerCase()
                              )
                          ) {
                            return item; // Corrected the return statement
                          }
                        })
                        .map((item, index) => (
                          <OfferCard
                            offerId={item && item?.offerId}
                            details={item && item.header}
                            isActive={editOfferData?.offerId === item?.offerId}
                            name={
                              item &&
                              item.merchant &&
                              item.merchant.merchantName
                            }
                            bankName={item && item.bank && item.bank.bankName}
                            logo={item && item.bank && item.bank.logoURL}
                            imgUrl={item.bannerImageURL}
                            userName={item && item?.user?.firstName}
                            categoryName={item && item?.category?.categoryName}
                            offerEndDate={item && item.offerEnd}
                            createAt={item && item.createdAt}
                            status={
                              item &&
                              item?.status?.charAt(0).toUpperCase() +
                                item?.status?.slice(1)
                            }
                            onClickViewOffer={() =>
                              this.props.onClickOpenOfferView(item)
                            }
                            onClickEdit={() => {
                              this.props.onClickClearSelected();
                              this.props.onClickSelectOffer(item);
                              this.props.onClickSelectedMerchant({
                                ...item?.merchant,
                                termsAndCondition: item.termsConditions,
                              });
                            }}
                          />
                        ))}
                    </>
                  )}
                  {!isLoading &&
                    this.state.rejectedTotalCount -
                      this.state.rejectPageNo * this.state.pageViewCount >
                      0 && (
                      <div
                        className={
                          "console__load-more-wrp d-flex justify-content-center mt-3 "
                        }
                      >
                        <Button
                          size={"sm"}
                          type={"btn-primary light"}
                          isLoading={isLoading}
                          onClick={async () => {
                            if (rejectedOffers?.length > 0) {
                              await this.pushRejectedItems();
                            }
                          }}
                          text={`Load more offers (${
                            this.state.rejectedTotalCount -
                            this.state.rejectPageNo * this.state.pageViewCount
                          })`}
                        />
                      </div>
                    )}
                  {this.state.isLoading && (
                    <div style={{ margin: "0 50%", opacity: 0.5 }}>
                      <Spin size="large" spinning={isLoading} />
                    </div>
                  )}
                </Scrollbars>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(pending);
