import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import { Table, Space } from "antd";
import { getAdminUser } from "../../../api";
import {
  Button,
  FileUpload,
  Input,
  PageHeader,
  PreviewAdvertisementCard,
} from "../../../components";
import uuid from "react-uuid";
import { GetIcon } from "../../../config/icon";
import { RiCheckLine } from "react-icons/ri";

class createNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      isSelect: null,
      dataForm: {
        advertisementName: {
          label: "Advertisement Name",
          key: "advertisementName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Name of your Advertisement",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageTitle: {
          label: "Title",
          key: "uploadImageTitle",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0/20",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageDescription: {
          label: "Description",
          key: "uploadImageDescription",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0/50",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageUrl: {
          label: "URL",
          key: "uploadImageUrl",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
        uploadImageAcButtonName: {
          label: "Action Button Name",
          key: " uploadImageAcButtonName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getUsers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getUsers() {
    this.setState({ isLoading: true });
    let res = await getAdminUser();
    this.setState({
      dataSource: res && res.data,
      isLoading: false,
    });
  }
  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "location") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  render() {
    const { viewPointHeight, dataForm, isImageUploading } = this.state;

    return (
      <>
        <div className="cco-advertisement-test">
          <Scrollbars
            style={{ height: viewPointHeight - 190 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"container"}>
              <div className="row justify-content-md-center">
                <div className={"col-12 col-lg-12 col-md-12 col-sm-12"}>
                  <div className={"cco__at-body-wrapper"}>
                    <div className="cco__atbw__content">
                      <Input
                        elementConfig={dataForm.advertisementName.elementConfig}
                        elementType={dataForm.advertisementName.elementType}
                        required={
                          dataForm.advertisementName.validation.required
                        }
                        isValid={!dataForm.advertisementName.isValid}
                        touched={dataForm.advertisementName.touched}
                        value={dataForm.advertisementName.value}
                        props={dataForm.advertisementName}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.advertisementName.label}
                        changed={(event) =>
                          this.inputHandleChange(
                            event,
                            dataForm.advertisementName.key
                          )
                        }
                      />
                      <div className="d-flex flex-row">
                        <div class="col-12 col-md-9 col-sm-12 col-lg-9">
                          <div className="cco__fileUpload_wrapper">
                            <FileUpload
                              title={"Image"}
                              isImageUploading={isImageUploading}
                            />
                          </div>

                          <Input
                            elementConfig={
                              dataForm.uploadImageTitle.elementConfig
                            }
                            elementType={dataForm.uploadImageTitle.elementType}
                            required={
                              dataForm.uploadImageTitle.validation.required
                            }
                            isValid={!dataForm.uploadImageTitle.isValid}
                            touched={dataForm.uploadImageTitle.touched}
                            value={dataForm.uploadImageTitle.value}
                            props={dataForm.uploadImageTitle}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.uploadImageTitle.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.uploadImageTitle.key
                              )
                            }
                          />

                          <Input
                            elementConfig={
                              dataForm.uploadImageDescription.elementConfig
                            }
                            elementType={
                              dataForm.uploadImageDescription.elementType
                            }
                            required={
                              dataForm.uploadImageDescription.validation
                                .required
                            }
                            isValid={!dataForm.uploadImageDescription.isValid}
                            touched={dataForm.uploadImageDescription.touched}
                            value={dataForm.uploadImageDescription.value}
                            props={dataForm.uploadImageDescription}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.uploadImageDescription.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.uploadImageDescription.key
                              )
                            }
                          />

                          <Input
                            elementConfig={
                              dataForm.uploadImageUrl.elementConfig
                            }
                            elementType={dataForm.uploadImageUrl.elementType}
                            required={
                              dataForm.uploadImageUrl.validation.required
                            }
                            isValid={!dataForm.uploadImageUrl.isValid}
                            touched={dataForm.uploadImageUrl.touched}
                            value={dataForm.uploadImageUrl.value}
                            props={dataForm.uploadImageUrl}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.uploadImageUrl.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.uploadImageUrl.key
                              )
                            }
                          />
                          <Input
                            elementConfig={
                              dataForm.uploadImageAcButtonName.elementConfig
                            }
                            elementType={
                              dataForm.uploadImageAcButtonName.elementType
                            }
                            required={
                              dataForm.uploadImageAcButtonName.validation
                                .required
                            }
                            isValid={!dataForm.uploadImageAcButtonName.isValid}
                            touched={dataForm.uploadImageAcButtonName.touched}
                            value={dataForm.uploadImageAcButtonName.value}
                            props={dataForm.uploadImageAcButtonName}
                            isShowRequired={true}
                            size={"md"}
                            margin={"m-b-2"}
                            label={dataForm.uploadImageAcButtonName.label}
                            changed={(event) =>
                              this.inputHandleChange(
                                event,
                                dataForm.uploadImageAcButtonName.key
                              )
                            }
                          />
                        </div>

                        {/* left wrapper */}
                        <div class="col-12 col-md-3 col-sm-12 col-lg-3">
                          <div className="cco__preview-wrapper">
                            <div className={"cco__pw-title d-flex flex-column"}>
                              Preview
                            </div>
                            <PreviewAdvertisementCard isImage={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>

          {/* next button */}
          <div className={" cco__advertisement-test-footer-wrapper container"}>
            <div className={"d-flex"}>
              <Button
                leftIconFill={true}
                iconName={<RiCheckLine />}
                leftIcon={false}
                rightIcon={false}
                option={""}
                isLoading={false}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("")}
                onClick={async () => {}}
                LeftIconText={null}
                text={"Done"}
                size={"md"}
                type={"btn-block"}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(createNew);
