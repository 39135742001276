import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllFeedbacks = async () => {
  try {
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/feedbacks`);
    return response.data;
  } catch (err) {
    console.log("ERR_ADD_NEW_FEEDBACK_API:", err);
    throw err;
  }
};
