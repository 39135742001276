import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { uploadImage } from "../../../api";

import { Button, Input, SelectBox } from "../../../components";
import { GetIcon } from "../../../config/icon";
import { OpenNotification } from "../../../config/notification";

class upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      tags: [],
      tag: null,
      isLoading: false,
      isImageUploading: false,
      uploadFils: [],
      base64: null,
      dataForm: {
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : https://picsum.photos/200",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
        title: {
          key: "title",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Hotel name",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Image title",
        },
      },
      mediaType: [
        { type: "offer", isActive: true },
        { type: "bank", isActive: false },
        { type: "client", isActive: false },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value && value.trim() !== "" && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "keywords":
        val = event && event.target ? event.target.value : event;
        let data = updateForm.keywords.elementConfig.options.filter(
          (x) => x.value === val
        );
        this.setState((state) => {
          if (val) {
            const favoriteAreas = state.favoriteAreas.concat({
              id: data && data[0].id,
              value: data && data[0].value,
            });
            return {
              favoriteAreas,
            };
          } else {
            const favoriteAreas = [];
            return {
              favoriteAreas,
            };
          }
        });
        updatedFormElement.value = val;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async clearAll() {
    this.setState({
      tags: [],
      tag: null,
      isLoading: false,
    });

    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      console.log(updateForm[key]);
      updateForm[key].value = "";
      this.setState({ dataForm: updateForm });
    }
  }

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.setState({ base64: result_base64, isLoading: false });
  }

  async uploadImage(base64, _type) {
    this.setState({ isImageUploading: true });
    let dataForm = this.state.dataForm;
    let res = await uploadImage(base64, _type, dataForm.title.value);
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = res.data.data.URL;
      this.setState({ dataForm: updateForm });
      this.setState({ isImageUploading: false });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isImageUploading: true });
      this.setState({ isLoading: false });
    }
  }

  async onSelect(item, index) {
    const { mediaType } = this.state;
    for (let i in mediaType) {
      mediaType[i].isActive = false;
    }
    mediaType[index].isActive = !mediaType[index].isActive;
    this.setState({ mediaType });
  }

  async onClickUpload() {
    this.setState({ isLoading: true });
    const { mediaType, dataForm, base64 } = this.state;
    const isActiveType = mediaType.find((x) => x.isActive === true);

    if (isActiveType && dataForm.title.value !== "") {
      const _type = isActiveType.type;
      await this.uploadImage(base64, _type);
    } else {
      this.setState({ isLoading: false });
      OpenNotification("error", "Media type required", "error");
    }
  }

  render() {
    const {
      dataForm,
      isLoading,
      isImageUploading,
      crop,
      src,
      base64,
      mediaType,
    } = this.state;
    return (
      <>
        <div className="">
          {!base64 && (
            <div>
              {/*----- image crop section wrapper ------*/}
              <div className={"img-upload-wrapper"}>
                <>
                  <input
                    id="eventImageUpload"
                    type="file"
                    onChange={async (media) => {
                      await this.toBase64(
                        document.querySelector("#eventImageUpload").files[0]
                      );
                    }}
                    hidden
                  />
                  <div
                    className={"event-img-upload"}
                    onClick={() => {
                      document.getElementById("eventImageUpload").click();
                    }}
                  >
                    Upload Image
                    {this.state.fileName && (
                      <div className={"file-details-wrp"}>
                        <div className={"file-name"}>{this.state.fileName}</div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          )}

          {base64 && (
            <div>
              {/*----- image crop section wrapper ------*/}
              <div className={"img-uploaded-wrapper"}>
                <>
                  <input
                    id="eventImageUpload"
                    type="file"
                    onChange={async (media) => {
                      await this.toBase64(
                        document.querySelector("#eventImageUpload").files[0]
                      );
                    }}
                    hidden
                  />
                  <div
                    className={"event-img-upload"}
                    onClick={() => {
                      document.getElementById("eventImageUpload").click();
                    }}
                  >
                    Re Upload
                    {this.state.fileName && (
                      <div className={"file-details-wrp"}>
                        <div className={"file-name"}>{this.state.fileName}</div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          )}

          <Input
            elementConfig={dataForm.title.elementConfig}
            elementType={dataForm.title.elementType}
            required={dataForm.title.validation.required}
            isValid={!dataForm.title.valid}
            touched={dataForm.title.touched}
            value={dataForm.title.value}
            isShowRequired={true}
            size={"md"}
            margin={"m-b-2"}
            label={dataForm.title.label}
            changed={(event) =>
              this.inputHandleChange(event, dataForm.title.key)
            }
          />

          <label className={`label-validation-on`}>
            Media type <b>*</b>
          </label>
          <div className={"multiple-line-wrapper mt-1"}>
            <div className={"tag-inline-wrapper d-flex flex-wrap"}>
              {(mediaType || []).map((item, index) => (
                <SelectBox
                  index={index}
                  remove={false}
                  isSelect={item.isActive === true}
                  select={true}
                  onClick={() => this.onSelect(item, index)}
                  onRemove={() => this.onRemove(item, index)}
                  format={"text"}
                  text={item.type}
                />
              ))}
            </div>
          </div>
          <div className={"login-footer-action-wrapper footer-button"}>
            <Button
              leftIcon={false}
              rightIcon={false}
              isLoading={isLoading || isImageUploading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={async () => {
                await this.onClickUpload();
              }}
              text={"Done"}
              size={"md"}
              type={"btn-block"}
            />
          </div>

          {!isImageUploading && dataForm["logoURL"].value && (
            <div className={"media-info-wrapper"}>
              <div className={"media-view"}>
                <div className={"img-wrapper"}>
                  <img
                    src={dataForm["logoURL"].value}
                    alt="creditcard offers"
                  />
                </div>
              </div>

              <div className={"media-URL-wrp"}>
                <label>URL</label>
                <div className={"link"}>{dataForm["logoURL"].value}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(upload);
