import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";
import { Spin } from "antd";
import {
  getExpiringOffers,
  getOfferByStatus,
  getSessionValidateStatus,
  updateOfferStatus,
  updateOfferStatusMultiple,
} from "../../api";
import { Button, OfferCard, PageHeader, Tag } from "../../components";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { OpenNotification } from "../../config/notification";
import { userDetails, userLogOut } from "../../utils";

class Expiring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      expiringOffers: [],
      userDataSource: [],
      pageNo: 1,
      pendingPageNo: 1,
      rejectPageNo: 1,
      acceptedTotalCount: 0,
      pendingTotalCount: 0,
      rejectedTotalCount: 0,
      pageViewCount: 10,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true });
      let user = await userDetails();
      if (user && user.userId) {
        let resOffersNew = await getOfferByStatus(1, 10, "expired");

        const offersDataExpired = await this.resetOffers(
          resOffersNew && resOffersNew.data,
          user
        );

        let pendingTotalCount =
          user?.userType != "user"
            ? resOffersNew?.totalCount || 0
            : offersDataExpired?.length || 0;

        this.setState((prevState) => ({
          ...prevState,
          expiringOffers: offersDataExpired || [],
          pendingTotalCount,
          user: user,
          isLoading: false,
        }));
        this.props.doneRefresh();
      }
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  pushPendingItems = async () => {
    let resOffersNew = await getOfferByStatus(
      this.state.pendingPageNo + 1,
      10,
      "expired"
    );
    const acceptedItems = await this.resetOffers(
      resOffersNew && resOffersNew.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      expiringOffers: [...prevState.expiringOffers, ...acceptedItems],
      pendingPageNo: prevState.pendingPageNo + 1,
    }));
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchOfferTitle !== this.props.searchOfferTitle) {
      if (this.props.searchOfferTitle != "") {
        let updateDataForm = this.state.dataForm;
        updateDataForm.searchOffer.value = this.props.searchOfferTitle;
        this.setState({ dataForm: updateDataForm });
      } else {
        let updateDataForm = this.state.dataForm;
        updateDataForm.searchOffer.value = "";
        this.setState({ dataForm: updateDataForm });
      }
    }
  }

  render() {
    const { viewPointHeight, expiringOffers, isLoading, dataForm } = this.state;
    const { editOfferData } = this.props;

    return (
      <>
        <div className="cco-advertisement">
          <div className={"cco__page-header-wrapper"}>
            <PageHeader
              option={"d-flex justify-content-between"}
              isRefresh={false}
              isDate={false}
              isLoading={isLoading}
              // onClickRefresh={async () => {
              //   let res = await this.getUsers();
              // }}
              title={"Expired Offers"}
            />
          </div>
          {isLoading && (
            <div style={{ margin: "10% 50%" }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
          {!isLoading && (
            <>
              <Scrollbars
                style={{ height: viewPointHeight - 80 }}
                thumbMinSize={30}
                universal={true}
                autoHide
              >
                {expiringOffers && expiringOffers.length > 0 && (
                  <>
                    {(expiringOffers || []).map((item, index) => (
                      <OfferCard
                        offerId={item && item?.offerId}
                        details={item && item.header}
                        isActive={editOfferData?.offerId === item?.offerId}
                        name={
                          item && item.merchant && item.merchant.merchantName
                        }
                        type={"view"}
                        bankName={item && item.bank && item.bank.bankName}
                        logo={item && item.bank && item.bank.logoURL}
                        imgUrl={item.bannerImageURL}
                        userName={item && item?.user?.firstName}
                        categoryName={item && item?.category?.categoryName}
                        userId={item && item?.user?.userId}
                        offerEndDate={item && item.offerEnd}
                        createAt={item && item.createdAt}
                        status={
                          item &&
                          item?.status?.charAt(0).toUpperCase() +
                            item?.status?.slice(1)
                        }
                        onClickViewOffer={() =>
                          this.props.onClickOpenOfferView(item)
                        }
                      />
                    ))}
                  </>
                )}
                {!isLoading &&
                  this.state.pendingTotalCount -
                    this.state.pendingPageNo * this.state.pageViewCount >
                    0 && (
                    <div
                      className={
                        "console__load-more-wrp d-flex justify-content-center mt-3"
                      }
                    >
                      <Button
                        size={"sm"}
                        type={"btn-primary light"}
                        isLoading={isLoading}
                        onClick={async () => {
                          if (expiringOffers?.length > 0) {
                            await this.pushPendingItems();
                          }
                        }}
                        text={`Load more offers (${
                          this.state.pendingTotalCount -
                          this.state.pendingPageNo * this.state.pageViewCount
                        })`}
                      />
                    </div>
                  )}
                {this.state.isLoading && (
                  <div style={{ margin: "0 50%", opacity: 0.5 }}>
                    <Spin size="large" spinning={isLoading} />
                  </div>
                )}
              </Scrollbars>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    refreshOffersList: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expiring);
