import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";

import { MerchantSelectCard } from "../../../components";

import { Collapse, Tabs } from "antd";
import { RiCloseLine } from "react-icons/ri";
import {
  cooFindMerchantById,
  cooUpdateMerchantTerms,
} from "../../merchant/service/merchant.service";
import { v4 as uuidv4 } from "uuid";
import CreateTermsAndConditions from "./createTermsAndConditions";
import FindTermsAndConditions from "../CreateManageTermsAndConditions/FindTermsAndConditions";

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      tabJson: [
        {
          id: uuidv4(),
          key: 1,
          tabName: "Sampath bank",
          content: <CreateTermsAndConditions />,
        },
        {
          id: uuidv4(),
          key: 2,
          tabName: "NDB Bank",
          content: "",
        },
      ],
      dataForm: {
        termsConditions: {
          key: "termsConditions",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async updateMerchantTerms(newTermsList) {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsConditions);
    newTermsList.forEach((element) => {
      newTermsAndConditions.push(element);
    });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length !== newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsConditions);
    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length > newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  onRemoveTermsAndConditions = (i) => {
    const { termsConditions } = this.state;

    termsConditions[i].isActive = !termsConditions[i].isActive;
    this.setState({ termsConditions });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpenSearchMerchant !== this.props.isOpenSearchMerchant) {
      const openMerchant = this.props.isOpenSearchMerchant;

      if (openMerchant) {
        this.setState({
          formWizard: 1,
          // selectedMerchantID: null,
          // selectedBankId: null,
          // selectedCategoryId: null,
        });
      }
    }
    if (prevProps.editBank !== this.props.editBank) {
      const editBank = this.props.editBank;

      if (editBank) {
        this.setState({
          formWizard: 1,
          selectedBank: editBank,
          selectedBankId: editBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editCategory !== this.props.editCategory) {
      const editCategory = this.props.editCategory;

      if (editCategory) {
        this.setState({
          formWizard: 2,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: editCategory.categoryId,
          selectedCategory: editCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
    if (prevProps.editMerchant !== this.props.editMerchant) {
      const editMerchant = this.props.editMerchant;

      if (editMerchant) {
        this.setState({
          formWizard: 3,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: editMerchant.merchantId,
          selectedCategoryId: this.props.selectCategory.categoryId,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
          selectOffer: null,
        });
      }
    }
  }

  selectAllTermsAndConditions = () => {
    const { termsConditions } = this.state;
    for (let idx = 0; idx < termsConditions.length; idx++) {
      if (termsConditions[idx].isActive) {
        termsConditions[idx].isActive = false;
      } else {
        termsConditions[idx].isActive = true;
      }
    }
    this.setState({ termsConditions });
  };

  render() {
    const {
      viewPointHeight,
      dataForm,
      termsConditions,
      selectedBankId,
      tabJson,
    } = this.state;
    const {
      isTermsAndConditions,
      selectMerchant,
      editOfferData,
      bankData,
      selectCategory,
      selectBank,
      selectBankId,
      existingTermsList,
    } = this.props;

    const remappedData = bankData
      .filter((x) => x.bankId === selectBankId)
      .map((item, index) => ({
        key: item.bankId, // Adjust the key as needed
        label: item.bankName,
        children: (
          <CreateTermsAndConditions
            key={index + 1}
            bankName={item.bankName}
            bankId={item.bankId}
          />
        ), // You can adjust the content as needed
      }));
    return (
      <>
        <FindTermsAndConditions />
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isTermsAndConditions ? "show-me" : "hide-me"
          } `}
        >
          <div
            className={"coo_modal-body-content fix-906 d-flex flex-column "}
            style={{
              zIndex: "999999",
              padding: "32px 24px 24px 24px",
              height: "90%",
            }}
          >
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"coo_mbc__header mr-4"}>
                {"Manage Terms and Conditions"}
              </div>

              <div
                className={`close-modal-wrapper `}
                onClick={async () => {
                  let resMerchant = await cooFindMerchantById(
                    this.props.selectMerchant?.merchantId
                  );
                  let merchant = resMerchant?.data?.data;
                  if (this.props.isTermsAndConditions) {
                    this.props.onClickSelectedMerchant(merchant);
                    if (this.props.editOfferData) {
                      const terms =
                        Object.keys(this.props.termsAndConditionList).length > 0
                          ? { ...this.props.termsAndConditionList }
                          : { ...this.props.existingTermsList };

                      const transformedTerms = Object.keys(terms).reduce(
                        (acc, key) => {
                          acc[key] = terms[key].map((x) => ({
                            id: x.id,
                            bankId: x.bankId,
                            value: x.value,
                            count: x.count,
                            isActive: false,
                          }));
                          return acc;
                        },
                        {}
                      );
                      const { editOfferData, onClickSelectOffer } = this.props;
                      const bankId = editOfferData.bankId;
                      const termsConditions =
                        transformedTerms?.[bankId]?.length > 0
                          ? transformedTerms?.[bankId]
                          : editOfferData?.termsConditions?.[bankId]?.length > 0
                          ? editOfferData?.termsConditions?.[bankId]
                          : {};

                      onClickSelectOffer({
                        ...editOfferData,
                        termsConditions:
                          termsConditions && termsConditions.length > 0
                            ? { [bankId]: termsConditions }
                            : {},
                      });
                    } else {
                      this.props.setCreateOfferData({
                        selectOffer: this.props.selectedOfferData,
                        selectedMerchant: merchant,
                        selectedBank: this.props.selectBank,
                        selectedCategory: this.props.selectCategory,
                        selectLocations: this.props.selectLocations,
                        selectTerms: merchant.termsAndCondition,
                      });
                    }
                  }
                  this.props.onClickCloseTermsAndConditions();
                }}
              >
                <RiCloseLine />
              </div>
            </div>
            <MerchantSelectCard
              onSelectedMerchant={() => {
                console.log("event fire...");
              }}
              preview={true}
              isActive={false}
              isEdit={true}
              name={
                editOfferData
                  ? editOfferData.merchant &&
                    editOfferData.merchant.merchantName
                  : selectMerchant && selectMerchant.merchantName
              }
              status={editOfferData && editOfferData.status}
              offers={"15"}
              category={
                editOfferData
                  ? editOfferData.category &&
                    editOfferData.category.categoryName
                  : selectCategory && selectCategory.categoryName
              }
              createdAt={
                editOfferData
                  ? editOfferData.createdAt
                  : selectMerchant && selectMerchant.createdAt
              }
              logo={
                editOfferData
                  ? editOfferData.merchant && editOfferData.merchant.logoURL
                  : selectMerchant && selectMerchant.logoURL
              }
            />
            {!editOfferData && (
              <div className={"cco__mtc-tab mt-2"}>
                <Tabs defaultActiveKey="1" items={remappedData} />
              </div>
            )}
            {editOfferData && (
              <div className={"cco__mtc-tab"}>
                <Tabs>
                  <TabPane
                    tab={editOfferData.bank && editOfferData.bank.bankName}
                    key={editOfferData.bank && editOfferData.bankId}
                  >
                    <CreateTermsAndConditions
                      bankName={
                        editOfferData.bank && editOfferData.bank.bankName
                      }
                      bankId={editOfferData && editOfferData.bankId}
                    />
                  </TabPane>
                </Tabs>
              </div>
            )}

            {/* {!editOfferData && (
              <div
                className={
                  "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                }
              >
                <div>
                  <Button
                    leftIconFill={true}
                    iconName={<RiCloseLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    onClick={async () => {
                      this.props.onClickCloseTermsAndConditions();
                    }}
                    LeftIconText={null}
                    text={"Cancel"}
                    size={"md"}
                    type={"btn-secondary"}
                  />

                  <Button
                    leftIconFill={true}
                    iconName={<RiCheckLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    //onClick={async () => {}}
                    LeftIconText={null}
                    text={"Done"}
                    size={"md"}
                    type={"btn-primary"}
                  />
                </div>
              </div>
            )} */}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTermsAndConditions: state.termsReducer.isTermsAndConditions,
    selectBankId: state.termsReducer.selectBankId,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    bankData: state.bankReducer.bankData,
    existingTermsList: state.termsReducer.existingTermsList,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseTermsAndConditions: () =>
      dispatch({ type: actionTypes.CLOSE_TERMS_AND_CONDITIONS_MODAL }),
    onClickCloseSearchMerchant: () =>
      dispatch({ type: actionTypes.CLOSE_SEARCH_MERCHANT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
