import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import moment from "moment";
import { Button, ButtonIcon, InfoRowView, RadioBox, Tag } from "../..";
import { GetIcon } from "../../../config/icon";
import { Tabs } from "antd";
import { RiCheckLine } from "react-icons/ri";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);

  const handleToggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const { TabPane } = Tabs;
  const menu = (
    <>
      <div className={"header-menu-wrapper"}>
        <div
          className={"hm__inner-content d-flex align-items-center"}
          onClick={props.onClickViewOffer}
        >
          <div className={"hmi-text"}>View offer</div>
        </div>

        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"}>Active</div>
        </div>
        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"} onClick={props.onClickEdit}>
            Edit
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div
      className={`cco__payment-offer-card__wrapper d-flex flex-row justify-content-between align-items-center ${
        props.isSelect ? "active-me" : ""
      } `}
      onClick={props.onClickSelectOffer}
    >
      <div className={"d-flex flex-row align-items-center"}>
        <div
          className={"cco__pocw-check-box"}
          onClick={props.onClickSelectOffer}
          onChange={() => props.onChange()}
        >
          {props.isSelect && (
            <>
              <RiCheckLine />
            </>
          )}
        </div>
        <div className={"d-flex flex-row"}>
          <div>
            <div className={"img-wrapper"}>
              <img src={props.imgUrl} alt="creditcard offers" />
            </div>
            <div className={"cco__pocw__logo"}>
              <img src={props.logo} alt="creditcard offers" />
            </div>
          </div>
          <div
            className={"cco__pocw-body"}
            style={{ width: "100%", paddingLeft: "12px" }}
          >
            <div className={"cco__pocw__details"}>{props.details}</div>
            <div className={"cco__pocw__name"}>{props.name}</div>
            <div className={"cco__pocw__tags d-flex flex-row"}>
              <Tag type={"new"} tagName={props.status} />
              <Tag type={"username"} tagName={props.userName} />
              <Tag type={"companyName"} tagName={props.bankName} />
            </div>
            <div className={"cco__pocw__time"}>
              {moment(props.createAt).startOf("day").fromNow()}
            </div>
          </div>
        </div>
      </div>
      <div className={"cco__pocwb__menu"}>
        <ButtonIcon
          onClick={props.onClickViewOffer}
          buttonIcon={GetIcon("chevronRight")}
          type={"collapse-icon"}
          size={"md"}
        />
      </div>
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
