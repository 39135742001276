import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, SelectBox } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { updateOfferStatus, updateSyncOffer } from "../../../api";
import { cooUpdateMerchantLocations } from "../../merchant/service/merchant.service";
import { updateDraft } from "../../../api/draft";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { setValue } from "../../../components/UI/RichTextBox/redux/rich-textbox-action";

class Index extends Component {
  newEditorState = EditorState.createEmpty();
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      validLocation: [],
      tag: null,
      merchantData: null,
      selectedOffer: null,
      editorState: this.newEditorState,
      draftHTMLValue: null,
      dataForm: {
        keywords: {
          key: "keywords",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Colombo",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Location Name",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    if (this.props.isEditOfferDescription) {
      this.initializeMerchantData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  initializeMerchantData() {
    const selectEditOffer = this.props.selectOffer;

    const updateForm = { ...this.state.dataForm };
    if (selectEditOffer?.description && selectEditOffer?.description != "") {
      this.setState({ editorState: this.newEditorState });
      console.log("description>>>>", selectEditOffer?.description);
      //This code runs after the component updates, but only if `count` has changed.
      const contentBlock = htmlToDraft(selectEditOffer.description);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    } else {
      this.setState({
        editorState: this.newEditorState,
      });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    const draftHTMLValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    this.setState({ draftHTMLValue });

    this.props.setValue(draftHTMLValue);
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateDescription = async () => {
    const { value } = this.props;
    this.setState({ isLoading: true });
    let body = {
      ...this.props.selectOffer,
      description:
        value && value?.payload
          ? value?.payload
          : this.state.draftHTMLValue
          ? this.state.draftHTMLValue
          : this.props.selectOffer.description,
    };

    let resOffer = await updateSyncOffer(body, this.props.selectOffer?.offerId);
    console.log("updateSyncOffer", resOffer);
    if (resOffer && resOffer.data && resOffer.data.status) {
      this.props.closeOfferViewModal();
      console.log(resOffer);
      this.props.onClickOpenOfferView(resOffer?.data?.data);
      this.props.closeEditModal();
      this.props.refreshSyncOffers();
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isEditOfferDescription } = this.props;
    const { viewPointHeight, dataForm, editorState, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isEditOfferDescription ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-552"}
          style={{
            zIndex: "999999",
            top: "9%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className={"coo_mbc__reject-header"}>{"Edit description"}</div>

            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column mt-2"}>
            <div className={"rich-text-editor-wrapper"}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  fontFamily: {
                    options: ["gothambook"],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  fontSize: {
                    icon: undefined,
                    options: [11, 12, 13, 14, 18, 24],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"mb-2"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              onClick={async () => {
                await this.updateDescription();
              }}
              LeftIconText={"Update"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
            <Button
              leftIcon={false}
              rightIcon={false}
              option={""}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditOfferDescription: state.offerReducer.isEditOfferDescription,
    value: state.richTextBox.value,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEditModal: () => dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),

    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
    setValue,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
