import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { ButtonIcon, Tag } from "../..";
import { GetIcon } from "../../../config/icon";
import { RiCheckLine } from "react-icons/ri";

const fetchEmail = (value) => {
  return value.replace(
    /^[ ]*(([^@\s]+))(@(?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i,
    "*************$3"
  );
};

const Index = (props) => (
  <>
    {/*  card */}

    <div className="cco_dc-details-wrapper">
      <div className="cco_dc-dw-info-wrapper">
        <div className="cco_dw-iw-title">{props.title}</div>
        {props.type === "name" && (
          <div className="cco_dw-iw-detail">{props.detail}</div>
        )}
        {props.type === "email" && (
          <div className="cco_dw-iw-detail">{props.detail} </div>
        )}
        {props.type === "userType" && (
          <div className="d-flex">
            <Tag type={"admin"} tagName={props.detail} />
          </div>
        )}

        {props.type === "access" && (
          <div className="d-flex flex-row ">
            {props.accessType?.read && (
              <Tag type={"pending mr-2"} tagName={"Read"} />
            )}
            {props.accessType?.write && (
              <Tag type={"pending mr-2"} tagName={"Write"} />
            )}
            {props.accessType?.delete && (
              <Tag type={"pending"} tagName={"Delete"} />
            )}
          </div>
        )}
      </div>
      <div
        className={`cco_dc-right-wrapper ${props.isSelect ? "is_active" : " "}`}
      >
        {props.rightType === "btn" && (
          <ButtonIcon
            onClick={props.onSelectedMerchant}
            buttonIcon={GetIcon("chevronRight")}
            type={"collapse-icon"}
            size={"md"}
          />
        )}{" "}
        {props.rightType === "select" && (
          <div
            className={`cco_rw-checkbox ${props.isSelect ? "active" : ""}`}
            onClick={props.onClick}
            onChange={() => props.onChange()}
          >
            {props.isSelect && (
              <>
                <RiCheckLine />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  </>
);
export default Index;
