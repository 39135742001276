import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";
import { Spin } from "antd";
import {
  getBank,
  getCategory,
  getMerchant,
  getOffer,
  getOfferByCategoriesBanksMultiple,
  getOfferByPage,
  getOfferByStatus,
  getSessionValidateStatus,
} from "../../api";
import {
  BulkOfferCard,
  Button,
  EmptyData,
  Input,
  OfferCard,
  PageHeader,
  Pagination,
} from "../../components";
import { userDetails, userLogOut } from "../../utils";
import { GetIcon } from "../../config/icon";

class BulkAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isLoading: false,
      dataSource: [],
      offersList: [],
      selectOffers: [],
      bankIdsList: [],
      categoryIdsList: [],
      merchantIdsList: [],
      pageNo: 1,
      pendingPageNo: 1,
      pageViewCount: 100,
      totalCount: 0,
      isCategoryLoading: false,
      isBankLoading: false,
      isMerchantLoading: false,
      dataForm: {
        category: {
          key: "category",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select category",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Category",
        },
        merchant: {
          key: "merchant",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select merchant",
            options: [],
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Merchant",
        },
        bank: {
          key: "bank",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select Bank",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Bank",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
    await this.setAllData();
  }

  async setAllData() {
    this.setState({
      isCategoryLoading: true,
      isMerchantLoading: true,
      isBankLoading: true,
    });

    let resCategory = await getCategory();
    let resMerchant = await getMerchant();
    let resBank = await getBank();
    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["merchant"].elementConfig.options = await this.syncMerchant(
      resMerchant.data
    );
    //  this.state.merchantList = resMerchant.data;
    updateForm["bank"].elementConfig.options = await this.syncBank(
      resBank.data
    );

    this.setState({
      dataForm: updateForm,
      isCategoryLoading: !resCategory.data.length,
      isMerchantLoading: !resMerchant.data.length,
      isBankLoading: !resBank.data.length,
    });
  }

  async syncCategory(res) {
    let categoryList = [];
    this.setState({ isCategoryLoading: true });
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
        tags: category.tags,
      });
    }
    return categoryList;
  }

  async syncMerchant(res) {
    let categoryList = [];
    for (let merchant of res) {
      categoryList.push({
        id: merchant.merchantId,
        value: merchant.merchantName,
        displayValue: merchant.merchantName,
        logo: merchant.logoURL,
        location: merchant.location,
        termsAndCondition: merchant.termsAndCondition,
      });
    }
    return categoryList;
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        id: data.bankId,
        value: data.bankName,
        displayValue: data.bankName,
        logoURL: data.logoURL,
        cardTypes: data.cardTypes,
      });
    }
    return resList;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async getAll() {
    let sessionStatus = await getSessionValidateStatus();
    if (sessionStatus?.data?.status) {
      this.setState({ isLoading: true, offersList: [] });
      let user = await userDetails();
      if (user && user.userId) {
        let resOffersNew = await getOfferByPage(1, 100);

        console.log("sessionStatus", resOffersNew);
        const offersData = await this.resetOffers(
          resOffersNew && resOffersNew.data,
          user
        );

        this.setState((prevState) => ({
          ...prevState,
          offersList: offersData || [],
          user: user,
          isLoading: false,
          totalCount: resOffersNew?.totalCount,
        }));
        this.props.doneRefresh();
      }
    } else {
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  async resetOffers(offerList, user) {
    let resetList = [];
    if (user?.userType != "user") {
      resetList = offerList;
    } else {
      offerList.forEach((element) => {
        if (user?.userType === "user" && user?.userId === element?.userId) {
          resetList.push(element);
        }
      });
    }
    return resetList;
  }

  pushPendingItems = async () => {
    let resOffersNew = await getOfferByStatus(
      this.state.pendingPageNo + 1,
      10,
      "expired"
    );
    const acceptedItems = await this.resetOffers(
      resOffersNew && resOffersNew.data,
      this.state.userDataSource
    );
    this.setState((prevState) => ({
      offersList: [...prevState.offersList, ...acceptedItems],
      pendingPageNo: prevState.pendingPageNo + 1,
    }));
  };

  checkIsSelected = (id) => {
    let selected = false;
    this.state.selectOffers.forEach((element) => {
      if (element === id) {
        selected = true;
      }
    });
    return selected;
  };

  async onSearchFilter() {
    this.setState({
      isLoading: true,
      offersList: [],
    });

    try {
      let body = {
        bankIds: this.state.bankIdsList,
        categoryIds: this.state.categoryIdsList,
        merchantIds: this.state.merchantIdsList,
      };

      let res = await getOfferByCategoriesBanksMultiple(body, 1, 100);
      if (res && res.status) {
        const offers = await this.resetOffers(
          res && res.data,
          this.state.userDataSource
        );
        console.log("offers", res);
        this.setState((prevState) => ({
          offersList: [...prevState.offersList, ...offers],
          totalCount: res?.totalCount,
          isLoading: false,
        }));
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  async onChangePage(pageNo, pageSize) {
    console.log(pageNo, pageSize);
    if (this.state.isLoading) return;

    this.setState({ isLoading: true, offersList: [], pageViewCount: pageSize });

    try {
      let resOffersNew = null;
      if (
        this.state.bankIdsList?.length > 0 ||
        this.state.categoryIdsList?.length > 0 ||
        this.state.merchantIdsList?.length > 0
      ) {
        let body = {
          bankIds: this.state.bankIdsList,
          categoryIds: this.state.categoryIdsList,
          merchantIds: this.state.merchantIdsList,
        };

        resOffersNew = await getOfferByCategoriesBanksMultiple(
          body,
          pageNo,
          pageSize
        );
      } else {
        resOffersNew = await getOfferByPage(pageNo, pageSize);
      }
      console.log("resOffersNew>>>", resOffersNew.data);
      if (resOffersNew && resOffersNew.status) {
        const offers = await this.resetOffers(
          resOffersNew && resOffersNew.data,
          this.state.userDataSource
        );
        this.setState((prevState) => ({
          offersList: [...prevState.offersList, ...offers],
          totalCount: resOffersNew?.totalCount,
          isLoading: false,
        }));
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoading: false });
    }
  }

  handleSearch = (searchValue, inputIdentifier) => {
    console.log("Search term:", searchValue);

    // Optionally, filter your options or perform an async operation here
    if (searchValue) {
      this.setState((prevState) =>
        prevState.dataForm[inputIdentifier]?.elementType?.options?.filter(
          (option) =>
            option.value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      return false;
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshOffers !== this.props.refreshOffers) {
      if (this.props.refreshOffers) {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: true,
          selectOffers: [],
        }));
        await this.getAll();
      }
    }
  }

  render() {
    const {
      viewPointHeight,
      offersList,
      isLoading,
      dataForm,
      isBankLoading,
      isCategoryLoading,
      isMerchantLoading,
    } = this.state;
    const { editOfferData } = this.props;

    return (
      <>
        <div className="cco-advertisement">
          <div className={"cco__page-header-wrapper"}>
            <PageHeader
              option={"d-flex justify-content-between"}
              isRefresh={true}
              isDate={false}
              isLoading={isLoading}
              onClickRefresh={async () => {
                let updateForm = this.state.dataForm;
                updateForm["bank"].value = "";
                updateForm["bank"].touched = false;
                updateForm["category"].value = "";
                updateForm["category"].touched = false;
                updateForm["merchant"].value = "";
                updateForm["merchant"].touched = false;

                this.setState({
                  dataForm: updateForm,
                  bankIdsList: [],
                  categoryIdsList: [],
                  merchantIdsList: [],
                });
                await this.getAll();
              }}
              title={"Bulk Action"}
            />
          </div>

          <div
            className={
              "search-bar-wrapper mt-1 d-flex flex-row justify-content-between align-content-center"
            }
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{ width: "70%" }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.category.elementConfig}
                  elementType={dataForm.category.elementType}
                  required={dataForm.category.validation.required}
                  isValid={!dataForm.category.valid}
                  touched={dataForm.category.touched}
                  value={dataForm.category.value}
                  defaultValue={null}
                  label={dataForm.category.label}
                  isShowRequired={false}
                  size={"lg"}
                  isLoading={isCategoryLoading}
                  margin={"ml-2"}
                  options={[]}
                  onSearch={(event) => {
                    this.handleSearch(event, dataForm.category.key);
                  }}
                  // onClickAddTag={this.onClickAddHashTag.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.category.key);
                    let category = dataForm.category.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    if (category) {
                      updateList.push(category?.id);
                    }
                    await this.setState({ categoryIdsList: updateList });
                    if (!category) {
                      if (
                        this.state.bankIdsList?.length > 0 ||
                        this.state.merchantIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.bank.elementConfig}
                  elementType={dataForm.bank.elementType}
                  required={dataForm.bank.validation.required}
                  isValid={!dataForm.bank.valid}
                  touched={dataForm.bank.touched}
                  value={dataForm.bank.value}
                  defaultValue={null}
                  label={dataForm.bank.label}
                  isLoading={isBankLoading}
                  isShowRequired={false}
                  size={"lg"}
                  margin={"ml-2"}
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  // onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.bank.key);
                    let bank = dataForm.bank.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    if (bank) {
                      updateList.push(bank?.id);
                    }
                    await this.setState({ bankIdsList: updateList });
                    if (!bank) {
                      if (
                        this.state.categoryIdsList?.length > 0 ||
                        this.state.merchantIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <Input
                  elementConfig={dataForm.merchant.elementConfig}
                  elementType={dataForm.merchant.elementType}
                  required={dataForm.merchant.validation.required}
                  isValid={!dataForm.merchant.valid}
                  touched={dataForm.merchant.touched}
                  value={dataForm.merchant.value}
                  defaultValue={null}
                  label={dataForm.merchant.label}
                  isLoading={isMerchantLoading}
                  isShowRequired={false}
                  size={"lg"}
                  margin={"ml-2"}
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  // onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.merchant.key);
                    let merchant = dataForm.merchant.elementConfig.options.find(
                      (x) => x.value === event
                    );
                    let updateList = [];
                    console.log({ merchant });
                    if (merchant) {
                      updateList.push(merchant?.id);
                    }
                    await this.setState({ merchantIdsList: updateList });
                    if (!merchant) {
                      if (
                        this.state.bankIdsList?.length > 0 ||
                        this.state.categoryIdsList?.length > 0
                      ) {
                        await this.onSearchFilter();
                      } else {
                        await this.getAll();
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div
              className={
                "search-action-left-wrp d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div>
                <Button
                  text={"Cancel"}
                  type={"btn-edit"}
                  size={"lg secondary mr-2 ml-2"}
                  disabled={
                    this.state.bankIdsList?.length === 0 &&
                    this.state.categoryIdsList?.length === 0 &&
                    this.state.merchantIdsList?.length === 0
                  }
                  onClick={async () => {
                    let updateForm = this.state.dataForm;
                    updateForm["bank"].value = "";
                    updateForm["bank"].touched = false;
                    updateForm["category"].value = "";
                    updateForm["category"].touched = false;
                    updateForm["merchant"].value = "";
                    updateForm["merchant"].touched = false;

                    this.setState({
                      dataForm: updateForm,
                      bankIdsList: [],
                      categoryIdsList: [],
                      merchantIdsList: [],
                    });
                    await this.getAll();
                  }}
                />
              </div>
              <div>
                <Button
                  text={"Search"}
                  type={"btn-edit"}
                  size={"lg secondary"}
                  disabled={
                    this.state.bankIdsList?.length === 0 &&
                    this.state.categoryIdsList?.length === 0 &&
                    this.state.merchantIdsList?.length === 0
                  }
                  onClick={async () => {
                    await this.onSearchFilter();
                  }}
                />
              </div>
            </div>
          </div>

          <Scrollbars
            style={{ height: viewPointHeight - 215 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            {isLoading && (
              <div style={{ margin: "10% 50%" }}>
                <Spin size="large" spinning={isLoading} />
              </div>
            )}
            {!isLoading && (
              <>
                {offersList && offersList.length > 0 && (
                  <>
                    {(offersList || []).map((item, index) => (
                      <BulkOfferCard
                        offerId={item && item?.offerId}
                        details={item && item.header}
                        isActive={this.checkIsSelected(item?.offerId)}
                        name={
                          item && item.merchant && item.merchant.merchantName
                        }
                        type={"bulk"}
                        bankName={item && item.bank && item.bank.bankName}
                        logo={item && item.bank && item.bank.logoURL}
                        imgUrl={item.bannerImageURL}
                        userName={item && item?.user?.firstName}
                        categoryName={item && item?.category?.categoryName}
                        userId={item && item?.user?.userId}
                        offerEndDate={item && item.offerEnd}
                        createAt={item && item.createdAt}
                        status={
                          item &&
                          item?.status?.charAt(0).toUpperCase() +
                            item?.status?.slice(1)
                        }
                        onClickPreview={() =>
                          this.props.onClickOpenOfferView(item)
                        }
                        onClick={() => {
                          let isAvailable = this.state.selectOffers.find(
                            (id) => id === item?.offerId
                          );
                          if (isAvailable) {
                            this.setState({
                              selectOffers: this.state.selectOffers.filter(
                                (id) => id !== item?.offerId
                              ),
                            });
                          } else {
                            let updateList = [...this.state.selectOffers];
                            updateList.push(item?.offerId);
                            this.setState({ selectOffers: updateList });
                          }
                        }}
                      />
                    ))}
                  </>
                )}
                {/* {!isLoading &&
                  this.state.pendingTotalCount -
                    this.state.pendingPageNo * this.state.pageViewCount >
                    0 && (
                    <div
                      className={
                        "console__load-more-wrp d-flex justify-content-center mt-3"
                      }
                    >
                      <Button
                        size={"sm"}
                        type={"btn-primary light"}
                        isLoading={isLoading}
                        onClick={async () => {
                          if (offersList?.length > 0) {
                            await this.pushPendingItems();
                          }
                        }}
                        text={`Load more offers (${
                          this.state.pendingTotalCount -
                          this.state.pendingPageNo * this.state.pageViewCount
                        })`}
                      />
                    </div>
                  )} */}
              </>
            )}
            {!isLoading && offersList?.length === 0 && (
              <EmptyData
                title={"Bulk Action Not Found"}
                imgUrl={require("../../assets/img/bulk-empty.png")}
              />
            )}
          </Scrollbars>
          {/* next button */}
          <div className={" cco__footer-wrapper "}>
            <div className="cco__footer-text">
              No of offer selected : {this.state.selectOffers?.length || 0}
            </div>
            <Pagination
              total={this.state.totalCount}
              pageSize={this.state.pageViewCount}
              onChange={async (page, pageSize) => {
                if (this.state.offersList?.length > 0) {
                  await this.onChangePage(page, pageSize);
                }
              }}
            />
            <Button
              leftIconFill={true}
              iconName={GetIcon("iconClick")}
              leftIcon={false}
              rightIcon={false}
              option={"ml-1"}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              LeftIconText={"Update filed"}
              text={""}
              size={"md"}
              type={"btn-primary"}
              onClick={() => {
                if (this.state.selectOffers?.length > 0) {
                  this.props.openUpdateOfferModal(this.state.selectOffers);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshOffers: state.offerReducer.refreshOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    openUpdateOfferModal: (payload) =>
      dispatch({ type: actionTypes.UPDATE_OFFER_DETAILS_MODAL, payload }),
    refreshOffersList: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkAction);
