import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../../../store/actions/types";
import {Button, InfoRowView, Input} from "../../../../components";
import Scrollbars from "react-custom-scrollbars";
import {cooUpdateMerchantTerms} from "../../../merchant/service/merchant.service";
import {userDetails} from "../../../../utils";
import {OpenNotification} from "../../../../config/notification";
import {RiCheckLine, RiCloseLine} from "react-icons/ri";
import {GetIcon} from "../../../../config/icon";
import uuid from "react-uuid";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectTermsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      dataSource: [],
      dataForm: {
        termsConditions: {
          key: "termsConditions",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      isPartEdit: false,
      partId: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.setState({
      formWizard: 1,
    });
    let user = await userDetails();
    if (user && user.userId) {
      await this.getAll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickConfirm = (_step) => {};

  async updateMerchantTerms(newTermsList) {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];

    let bankId = this.props.bankId
      ? this.props.bankId
      : "6636780a73e7209d15fc5184";
    let terms = [];
    newTermsList.forEach((element) => {
      terms.push(element);
    });
    newTermsAndConditions[bankId] = terms;

    console.log({ newTermsAndConditions });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    // if (
    //   selectMerchant &&
    //   selectMerchant.termsConditions.length !== newTermsAndConditions.length
    // ) {
    //   // this.setState({ isLoading: true });
    //   // let body = {
    //   //   termsConditions: newTermsAndConditions,
    //   // };
    //   // let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
    //   // console.log("cooUpdateMerchantLocations", res);
    //   // if (res && res.data && res.data.status) {
    //   //   this.setState({ isLoading: false });
    //   //   let merchant = res.data.data;
    //   //   this.props.onClickSelectedMerchant(merchant);
    //   // } else {
    //   //   this.setState({ isLoading: false });
    //   // }
    // }
  }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];
    console.log(newTermsAndConditions, selectMerchant.termsConditions);
    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length > newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  async getAll() {
    this.setState({ isLoading: true });

    this.setState({
      isLoading: false,
      formWizard: 1,
    });
  }

  async getAllMerchant() {
    this.setState({ isLoading: true });

    this.setState({
      isLoading: false,
      formWizard: 1,
    });
  }

  onRemoveTermsAndConditions = (i) => {
    this.props.onClickDelete({ bankId: this.props.bankId });
  };
  onRemoveTermsAndConditions = (i) => {
    this.props.onClickDelete({ bankId: this.props.bankId });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate', "fire..");
    if (prevState.termsConditions !== this.state.termsConditions) {
      const terms = this.state.termsConditions[this.state.selectedBankId];
      let selectTermsConditions =
        this.state.selectTermsConditions.length > 0
          ? this.state.selectTermsConditions
          : [];
      console.log(selectTermsConditions, terms);
      if (this.state.selectTermsConditions.length > 0) {
        if (terms && terms.length > 0) {
          terms.forEach((element) => {
            selectTermsConditions.push(element);
          });
        } else {
          this.state.termsConditions.forEach((element) => {
            selectTermsConditions.push(element);
          });
        }
      } else {
        selectTermsConditions =
          terms && terms.length > 0 ? terms : this.state.termsConditions;
      }
      this.setState({
        selectTermsConditions,
      });
    }
  }

  selectAllTermsAndConditions = () => {
    const { termsConditions } = this.state;
    for (let idx = 0; idx < termsConditions.length; idx++) {
      if (termsConditions[idx].isActive) {
        termsConditions[idx].isActive = false;
      } else {
        termsConditions[idx].isActive = true;
      }
    }
    this.setState({ termsConditions });
  };

  displayListData = (list) => {
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        console.log(`Bank ID: ${key}`);
        list[key].forEach((item) => {});
      }
    }
  };

  handleEditPart = (partId, value) => {
    this.setState({ isPartEdit: true, partId: partId });
    const updateForm = {
      ...this.state.dataForm,
    };
    //updatedFormElement.touched = true;
    updateForm["termsConditions"].value = value;
    this.setState({ dataForm: updateForm });
  };

  onClickFormatTermsAndCondition = (partId, value) => {
    const { dataForm } = this.state;
    if (dataForm.termsConditions.value) {
      const regex = /\r?\n/;
      const parts = dataForm.termsConditions.value
        ?.split(regex)
        .filter((part) => part.trim() !== "");
      // Log the split parts to verify
      console.log("format_list", parts);
    }
  };
  clearTermsAndBox = () => {
    const updateForm = { ...this.state.dataForm };
    updateForm["termsConditions"].value = "";
    this.setState({ dataForm: updateForm });
  };

  render() {
    const {
      viewPointHeight,
      dataForm,
      termsConditions,
      selectedBankId,
      selectTermsConditions,
      isPartEdit,
      partId,
    } = this.state;
    const { editOfferData, bankId, termsAndConditionList } = this.props;

    return (
      <>
        {/*terms_condition-wrapper*/}
        <div className={"manage-terms_condition-wrapper d-flex flex-column"}>
          <div
            className={
              "mtcw__section-header-wrp d-flex flex-row align-items-center justify-content-between"
            }
          >
            <div className={"mtcw__shw_header"}>
              Create new Terms & Conditions - {this.props.bankName}
            </div>
            <div className={"action-bar-right d-flex"}>
              <Button
                text={"Find"}
                type={"btn-edit"}
                size={"sm secondary mr-2"}
                onClick={() => {
                  this.props.onClickOpenFindAndCondition();
                }}
              />

              {dataForm.termsConditions.value && (
                <>
                  <Button
                    text={"Format"}
                    type={"btn-edit"}
                    size={"sm secondary mr-2"}
                    onClick={() => {
                      this.onClickFormatTermsAndCondition();
                    }}
                  />
                  <Button
                    text={"Clear"}
                    type={"btn-edit"}
                    size={"sm secondary mr-2"}
                    onClick={() => {
                      const updateForm = { ...this.state.dataForm };
                      updateForm["termsConditions"].value = "";
                      this.setState({ dataForm: updateForm });
                    }}
                  />
                </>
              )}
            </div>
          </div>

          <Input
            elementConfig={dataForm.termsConditions.elementConfig}
            elementType={dataForm.termsConditions.elementType}
            required={dataForm.termsConditions.validation.required}
            isValid={!dataForm.termsConditions.valid}
            touched={dataForm.termsConditions.touched}
            value={dataForm.termsConditions.value}
            isShowRequired={false}
            size={"sm"}
            margin={"m-b-1 m-t-2"}
            label={dataForm.termsConditions.label}
            changed={(event) => {
              this.inputHandleChange(event, dataForm.termsConditions.key);
            }}
          />
          {!isPartEdit && (
            <Button
              text={"Add to list"}
              type={"btn-edit mt-2"}
              isLoading={false}
              onClick={async () => {
                if (dataForm.termsConditions.value) {
                  const regex = /\r?\n/; // Regular expression to split by newline
                  const parts = dataForm.termsConditions.value
                    .split(regex)
                    .filter((part) => part.trim() !== ""); // Split and filter empty parts

                  // Log the split parts to verify
                  console.log("format_list", parts);

                  const bankId = this.props.bankId; // Get bankId from props

                  // Create a copy of the current state
                  let termsAndConditionList =
                    this.props.termsAndConditionList || {};

                  // Track if any duplicates are found
                  let duplicateFound = false;
                  // Find duplication value
                  for (let i = 0; i < parts.length; i++) {
                    // Check if the value already exists in the current state
                    //const isDuplicate = termsAndConditionList.some(term => term.value === parts[i] && term.bankId === bankId);
                    // Add new terms and conditions
                    this.props.addNewTermsAndCondition({
                      parts: {
                        id: uuid(),
                        value: parts[i],
                        bankId: bankId,
                        isActive: false,
                        count: 0,
                      },
                      bankId: bankId,
                    });
                  }
                  // Update the state with the new terms and conditions list
                  this.setState({ termsAndConditionList });
                  this.clearTermsAndBox();
                  // Log the updated list
                  console.log("termsAndConditionList", termsAndConditionList);
                }
                //await this.updateMerchantTerms(data);
              }}
              size={"sm primary"}
            />
          )}

          {isPartEdit && (
            <div className={"tc_part_edit-action-wrapper d-flex"}>
              {dataForm.termsConditions.value && (
                <Button
                  text={"update"}
                  type={"btn-edit mt-2 mr-2"}
                  isLoading={false}
                  onClick={() => {
                    this.props.onClickEdit({
                      bankId: bankId,
                      id: partId,
                      newValue: dataForm.termsConditions.value,
                    });
                    this.setState({ partId: null, isPartEdit: false });
                    OpenNotification("Success", "Done", "success");
                  }}
                  size={"sm primary"}
                />
              )}

              <Button
                text={"cancel"}
                type={"btn-edit mt-2"}
                isLoading={false}
                onClick={() =>
                  this.setState({ partId: null, isPartEdit: false })
                }
                size={"sm primary"}
              />
            </div>
          )}
        </div>
        <Scrollbars
          style={{ height: viewPointHeight - 430 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div className={"mtcw_list-view"}>
            <div
              className={
                "mtcw__section-header-wrp d-flex flex-row align-items-center justify-content-between"
              }
            >
              <div className={"mtcw__shw_header "}>
                Terms & Conditions
                {/*({termsAndConditionList[this.props?.bankId]?.length})*/}
              </div>
              <div className={"action-bar-right"}>
                <Button
                  text={"select all"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={() => {
                    console.log("termsAndConditionList", termsAndConditionList);
                    //this.selectAllTermsAndConditions();
                  }}
                />
                <Button
                  text={"clear all"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={() => {
                    // const updateForm = {...this.state.dataForm};
                    // updateForm["termsAndCondition"].value = "";
                    // this.setState({dataForm: updateForm});
                  }}
                />
              </div>
            </div>

            <div>
              {this.props.bankId &&
                termsAndConditionList &&
                termsAndConditionList[this.props.bankId] && (
                  <div>
                    {this.props.termsAndConditionList[this.props.bankId].map(
                      (item, index) => (
                        <div key={item.id}>
                          <InfoRowView
                            type="terms"
                            text={item?.value}
                            isSelect={item?.id === partId}
                            style="mb-1 justify-content-between align-items-center"
                            onClickConfirm={() =>
                              this.props.onClickDelete({
                                bankId: this.props.bankId,
                                partId: item?.id,
                              })
                            }
                            onClickEdit={() =>
                              this.handleEditPart(item?.id, item?.value)
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                )}
            </div>

            {/*{termsAndConditionList[this.props.bankId]?.map((item, index) => (*/}
            {/*    <>*/}
            {/*      <InfoRowView*/}
            {/*          type={"terms"}*/}
            {/*          text={item.value}*/}
            {/*          isSelect={item.isActive}*/}
            {/*          style={"mb-1 justify-content-between align-items-center"}*/}
            {/*          onClick={() => this.onRemoveTermsAndConditions(index)}*/}
            {/*          onClickConfirm={() =>*/}
            {/*              this.onRemoveTermsAndConditions(index)*/}
            {/*          }*/}
            {/*          //  onClickCancel={() => }*/}
            {/*      />*/}
            {/*    </>*/}
            {/*))}*/}
          </div>
        </Scrollbars>
        {this.props.bankId &&
          termsAndConditionList &&
          Object.keys(termsAndConditionList).length > 0 && (
            <div
              className={
                "ctcn__-footer-action-wrapper d-flex align-items-center justify-content-between pt-2"
              }
            >
              <div>
                <div className={"sm__footer-info d-flex flex-column "}>
                  {this.props.bankName}
                  <br />
                  {this.props.bankId}
                </div>
              </div>
              <div className={"d-flex"}>
                <Button
                  leftIconFill={true}
                  iconName={<RiCloseLine />}
                  leftIcon={false}
                  rightIcon={false}
                  option={"ml-1"}
                  isLoading={false}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("")}
                  onClick={() => {
                    this.props.onClickCloseTermsAndConditions();
                  }}
                  LeftIconText={null}
                  text={"Cancel"}
                  size={"md"}
                  type={"btn-block-back-secondary"}
                />

                <Button
                  leftIconFill={true}
                  iconName={<RiCheckLine />}
                  leftIcon={false}
                  rightIcon={false}
                  option={"ml-1"}
                  isLoading={false}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("")}
                  onClick={() => {
                    //alert('event fire...');
                    console.log(this.props.termsAndConditionList);
                    this.props.onClickCloseTermsAndConditions();
                  }}
                  LeftIconText={null}
                  text={"Done"}
                  size={"md"}
                  type={"btn-block-back"}
                />
              </div>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTermsAndConditions: state.termsReducer.isTermsAndConditions,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    addNewTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.ADD_NEW_TERMS_INTO_LIST, payload: data }),
    onClickEdit: (data) =>
      dispatch({ type: actionTypes.EDIT_TERMS_INTO_LIST, payload: data }),
    onClickEnableEditOption: (data) =>
      dispatch({ type: actionTypes.EDIT_TERMS_INTO_LIST, payload: data }),
    onClickDelete: (data) =>
      dispatch({ type: actionTypes.DELETE_TERMS_INTO_LIST, payload: data }),
    onClickOpenFindAndCondition: (data) =>
      dispatch({
        type: actionTypes.OPEN_FIND_TERMS_AND_CONDITION,
        payload: data,
      }),
    onClickCloseTermsAndConditions: () =>
      dispatch({
        type: actionTypes.CLOSE_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
