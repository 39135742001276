import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import moment from "moment";
import { Button, CustomTag, Input } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { updateOfferStatus, updateSyncOffer } from "../../../api";
import { cooUpdateBankCardTypes } from "../../createNewItems/CreateNewBank/service/bank.service";
import { updateDraft } from "../../../api/draft";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      tags: [],
      tag: null,
      bankData: null,
      dataForm: {
        keywords: {
          key: "keywords",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Visa",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Card type",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    if (this.props.isBankCardType) {
      this.initializeData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  initializeData() {
    this.setState({
      bankData: null,
      tags: [],
    });
    const selectedBank = this.props.editBank;

    console.log({ selectedBank });
    if (selectedBank?.bankId) {
      const tags =
        selectedBank?.cardTypes?.length > 0
          ? [
              ...this.state.tags,
              ...selectedBank.cardTypes.map((cardType) => ({
                id: cardType.id,
                value: cardType.cardName || cardType.value,
                count: cardType.count,
                isActive:
                  selectedBank?.status === "auto" ? true : cardType.isActive,
              })),
            ]
          : [];

      this.setState({ bankData: selectedBank, tags });
    } else {
      this.setState({
        bankData: null,
        tags: [],
      });
    }
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateBankCardTypes = async () => {
    this.setState({ isLoading: true });
    let body = {
      cardTypes: this.state.tags,
    };

    let bankId = this.state.bankData?.bankId || this.props.editBank?.bankId;

    let res = await cooUpdateBankCardTypes(body, bankId);
    console.log("cooUpdateBankCardTypes", res);
    if (res && res.data && res.data.status) {
      let bank = res.data.data;
      if (
        this.props.editBank?.status === "auto" ||
        (this.props.selectOffer?.userId === "auto" &&
          this.props.selectOffer?.status !== "accepted")
      ) {
        let offerBody = {
          ...this.props.selectOffer,
          cardTypes: bank?.cardTypes,
        };

        let resOffer = await updateSyncOffer(
          offerBody,
          this.props.selectOffer?.offerId
        );
        this.props.closeOfferViewModal();
        console.log(resOffer);
        this.props.onClickOpenOfferView(resOffer?.data?.data);
        this.props.refreshSyncOffers();
      } else {
        this.props.updateSelectedBank({
          ...this.props.editOfferData,
          bank: bank,
          cardTypes: bank.cardTypes,
        });
        this.props.onClickSelectedMerchant(this.props.selectMerchant);
        console.log("cooUpdateBankCardTypes", this.props.editOfferData);
        if (this.props.editOfferData) {
          let newCardList = (bank.cardTypes || []).map((cardType) => ({
            id: cardType.id,
            value: cardType.value || cardType.cardName,
            count: cardType.count,
            isActive: this.state.tags.find((x) => x.id === cardType.id)
              ?.isActive,
          }));
          this.props.updateSelectedBank({
            ...this.props.editOfferData,
            bank: {
              ...bank,
              cardTypes: newCardList,
            },
            cardTypes: newCardList,
          });
          this.props.onClickSelectOffer({
            ...this.props.editOfferData,
            bank: {
              ...bank,
              cardTypes: newCardList,
            },
            cardTypes: newCardList,
          });
        } else {
          this.props.setCreateOfferData({
            selectOffer: this.props.selectedOfferData,
            selectedMerchant: this.props.selectMerchant,
            selectedBank: bank,
            selectedCategory: this.props.selectedCategory,
            selectLocations: this.props.selectLocations,
            selectTerms: this.props.selectTerms,
          });
          // this.props.onClickSelectOffer({
          //   ...this.props.selectedOfferData,
          //   location: merchant.location,
          // });
          if (
            this.props.selectedOfferData &&
            this.props.selectedOfferData?.draftId
          ) {
            let resDraft = await updateDraft(
              {
                ...this.props.selectedOfferData,
                cardTypes: bank.cardTypes,
              },
              this.props.selectedOfferData?.draftId
            );
            console.log({ resDraft });
            this.props.refreshOffers();
          }
        }
      }
      this.setState({ isLoading: false });
      this.props.closeEditModal();
    } else {
      this.setState({ isLoading: false });
    }
  };

  //onClick add #tags
  onClickAddCardType = () => {
    this.setState((state) => {
      if (state.tag) {
        const tags =
          this.props.editBank?.status === "auto"
            ? state.tags.concat({
                id: uuid(),
                value: state.tag,
                count: 0,
                isActive: true,
              })
            : state.tags.concat({
                id: uuid(),
                cardName: state.tag,
                count: 0,
              });
        return {
          tags,
          tag: null,
        };
      }
    });
    const updateForm = { ...this.state.dataForm };
    updateForm["keywords"].value = "";
    this.setState({ dataForm: updateForm });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onClickAddCardType();
      const updateForm = { ...this.state.dataForm };
      updateForm["keywords"].value = "";
      this.setState({ dataForm: updateForm, tags: null });
    }
  };

  onRemoveCards = (i) => {
    console.log("remove event fire...." + i);
    this.setState((state) => {
      const tags = state.tags.filter((item, j) => i !== j);
      return {
        tags,
      };
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.editBank !== this.props.editBank) {
      this.setState({
        bankData: null,
        tags: [],
      });
      const selectedBank = this.props.editBank;

      console.log({ selectedBank });
      if (selectedBank?.bankId) {
        const tags =
          selectedBank?.cardTypes?.length > 0
            ? [
                ...this.state.tags,
                ...selectedBank.cardTypes.map((cardType) => ({
                  id: cardType.id,
                  cardName: cardType.cardName || cardType.value,
                  count: cardType.count,
                  isActive: cardType.isActive,
                })),
              ]
            : [];

        this.setState({ bankData: selectedBank, tags });
      } else {
        this.setState({
          bankData: null,
          tags: [],
        });
      }
    }
  }

  render() {
    const { isBankCardType } = this.props;
    const { viewPointHeight, dataForm, tags, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isBankCardType ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-400"}
          style={{
            zIndex: "999999",
            top: "9%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className={"coo_mbc__reject-header"}>{"Edit cards types"}</div>
            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column mt-3"}>
            <Input
              elementConfig={dataForm.keywords.elementConfig}
              elementType={dataForm.keywords.elementType}
              required={dataForm.keywords.validation.required}
              isinvalid={!dataForm.keywords.valid}
              touched={dataForm.keywords.touched}
              value={dataForm.keywords.value}
              isShowRequired={true}
              size={"md"}
              margin={"m-b-2"}
              label={dataForm.keywords.label}
              onKeyPress={this.handleKeyPress.bind(this)}
              onClickAddTag={this.onClickAddCardType.bind(this)}
              changed={(event) =>
                this.inputHandleChange(event, dataForm.keywords.key)
              }
            />
            {tags && (
              <>
                <div className={"multiple-line-wrapper mb-2"}>
                  <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                    {(tags || []).map((item, index) => (
                      <CustomTag
                        index={index}
                        remove={true}
                        onRemoveTime={() => this.onRemoveCards(index)}
                        format={"text"}
                        text={item.cardName || item?.value}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: "24px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"mb-2"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              onClick={async () => {
                await this.updateBankCardTypes();
              }}
              LeftIconText={"Update"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
            <Button
              leftIcon={false}
              rightIcon={false}
              option={""}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isBankCardType: state.offerReducer.isBankCardType,
    editBank: state.offerReducer.editBank,
    selectMerchant: state.merchantReducer.selectMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectedBank: state.offerReducer.selectedBank,
    selectedCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEditModal: () =>
      dispatch({ type: actionTypes.EDIT_BANK_CARD_TYPES_CLOSE }),
    updateSelectedBank: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SELECTED_BANK, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
