import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  selectedOfferData: "",
  editOfferData: null,
  selectedOffer: null,
  isViewOffer: false,
  isViewSyncOffer: false,
  isCreateOffer: false,
  selectOffer: null,
  selectedMerchant: null,
  selectedBank: null,
  selectedCategory: null,
  selectLocations: null,
  selectTerms: null,
  refreshOffers: false,
  refreshDrafts: false,
  refreshSyncOffers: false,
  numOfOfferByMerchant: 0,
  editMerchant: null,
  editBank: null,
  editCategory: null,
  isBankCardType: false,
  isOfferBankCardType: false,
  isEditLocation: false,
  isEditMerchant: false,
  isEditBank: false,
  isEditCategory: false,
  isEditContacts: false,
  isEditPromo: false,
  isSaveDraft: false,
  draftOffer: null,
  selectOfferIds: [],
  isUpdateOfferModal: false,
  isUpdateSyncOfferModal: false,
  isEditOfferDescription: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("offerReducers", payload);
  switch (type) {
    case actionTypes.VIEW_OFFER_DETAILS:
      draft.editOfferData = payload;
      draft.selectedBank = payload.bank;
      draft.selectedCategory = payload.category;
      draft.selectLocations = payload.location;
      draft.selectTerms = payload.termsConditions;
      return draft;
    // case actionTypes.DELETE_OFFER_TERMS_INTO_LIST:
    // console.log("DELETE_TERMS_INTO_LIST");
    // const { partId } = action.payload;
    // if (draft.selectTerms) {
    //   const partListFilter = draft.selectTerms[action.payload?.bankId];

    //   if (partListFilter) {
    //     draft.selectTerms[action.payload?.bankId] = partListFilter.filter(
    //       (item) => item.id !== partId
    //     );
    //     if (draft.selectTerms[action.payload?.bankId].length === 0) {
    //       delete draft.selectTerms[action.payload?.bankId];
    //     }
    //   }
    // }

    // return draft;
    case actionTypes.CLEAR_ALL_OFFER_TERMS_INTO_LIST:
      console.log("CLEAR_ALL_TERMS_INTO_LIST");
      const selectListFilter = draft.selectTerms[action.payload?.bankId];

      if (selectListFilter) {
        draft.selectTerms[action.payload?.bankId] = [];
        if (draft.selectTerms[action.payload?.bankId].length === 0) {
          delete draft.selectTerms[action.payload?.bankId];
        }
      }

      return draft;

    case actionTypes.EDIT_BANK_CARD_TYPES:
      draft.isBankCardType = true;
      draft.editBank = payload;
      return draft;
    case actionTypes.EDIT_BANK_CARD_TYPES_CLOSE:
      draft.isBankCardType = false;
      draft.editBank = null;
      return draft;
    case actionTypes.EDIT_OFFER_BANK_CARD_TYPES:
      draft.isOfferBankCardType = true;
      draft.editBank = payload;
      return draft;
    case actionTypes.EDIT_OFFER_BANK_CARD_TYPES_CLOSE:
      draft.isOfferBankCardType = false;
      draft.editBank = null;
      return draft;
    case actionTypes.EDIT_MERCHANT_LOCATION:
      draft.isEditLocation = true;
      draft.editMerchant = payload;
      return draft;
    case actionTypes.EDIT_MERCHANT_LOCATION_CLOSE:
      draft.isEditLocation = false;
      draft.editMerchant = null;
      return draft;
    case actionTypes.EDIT_MERCHANT_CONTACTS_CLOSE:
      draft.editMerchant = null;
      draft.isEditContacts = false;
      return draft;
    case actionTypes.UPDATE_OFFER_DETAILS_MODAL:
      draft.isUpdateOfferModal = true;
      draft.selectOfferIds = payload;
      return draft;
    case actionTypes.UPDATE_SYNC_OFFER_DETAILS_MODAL:
      draft.isUpdateSyncOfferModal = true;
      draft.selectedOffer = payload;
      return draft;
    case actionTypes.OPEN_OFFER_VIEW_MODAL:
      draft.selectedOffer = payload;
      draft.isViewOffer = true;
      return draft;
    case actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL:
      draft.selectedOffer = payload;
      draft.isViewSyncOffer = true;
      return draft;
    case actionTypes.OPEN_OFFER_CREATE_MODAL:
      draft.selectedOffer = payload;
      draft.isViewOffer = true;
      draft.isCreateOffer = true;
      return draft;
    case actionTypes.EDIT_OFFER_BANK:
      draft.editBank = payload;
      draft.isEditBank = true;
      return draft;
    case actionTypes.EDIT_OFFER_CATEGORY:
      draft.editCategory = payload;
      draft.isEditCategory = true;
      return draft;
    case actionTypes.EDIT_OFFER_MERCHANT:
      draft.isEditMerchant = true;
      draft.editMerchant = payload;
      return draft;
    case actionTypes.EDIT_OFFER_DESCRIPTION:
      draft.isEditOfferDescription = true;
      draft.selectedOffer = payload;
      return draft;
    case actionTypes.EDIT_OFFER_PROMO_DETAILS:
      draft.isEditPromo = true;
      draft.selectedOffer = payload;
      return draft;
    case actionTypes.EDIT_OFFER_CONTACTS:
      console.log("EDIT_OFFER_CONTACTS", payload);
      draft.editMerchant = payload;
      draft.isEditContacts = true;
      return draft;
    case actionTypes.CLOSE_OFFER_VIEW_MODAL:
      // draft.selectedOffer = null;
      draft.isViewOffer = false;
      draft.isViewSyncOffer = false;
      // draft.isCreateOffer = false;
      return draft;
    case actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL:
      draft.selectedOffer = null;
      draft.isViewOffer = false;
      draft.isViewSyncOffer = false;
      // draft.isCreateOffer = false;
      return draft;
    case actionTypes.CLEAR_OFFER_VIEW_MODAL:
      draft.selectedOffer = null;
      draft.isViewOffer = false;
      draft.isViewSyncOffer = false;
      draft.isCreateOffer = false;
      return draft;
    case actionTypes.REFRESH_SYNC_OFFERS_LIST:
      draft.refreshSyncOffers = true;
      return draft;
    case actionTypes.REFRESH_OFFERS_LIST:
      draft.refreshOffers = true;
      return draft;
    case actionTypes.DONE_REFRESH_OFFERS:
      draft.refreshOffers = false;
      draft.refreshDrafts = false;
      draft.refreshSyncOffers = false;
      return draft;
    case actionTypes.REFRESH_DRAFTS_LIST:
      draft.refreshDrafts = true;
      return draft;
    case actionTypes.DONE_REFRESH_DRAFTS:
      draft.refreshDrafts = false;
      return draft;
    case actionTypes.SET_NO_OFFER:
      draft.numOfOfferByMerchant = payload;
      return draft;
    case actionTypes.UPDATE_SELECTED_BANK:
      draft.selectedBank = payload.selectedBank;
      draft.selectedOfferData = payload.selectOffer;
      return draft;
    case actionTypes.UPDATE_SELECTED_LOCATIONS:
      draft.selectLocations = payload.location;
      draft.selectedOfferData = payload.selectOffer;
      return draft;
    case actionTypes.CREATE_OFFER_DETAILS:
      draft.draftOffer = payload.draftOffer;
      draft.selectedOfferData = payload.selectOffer;
      draft.selectedMerchant = payload.selectedMerchant;
      draft.selectedBank = payload.selectedBank;
      draft.selectedCategory = payload.selectedCategory;
      draft.selectLocations = payload.selectLocations;
      draft.selectTerms = payload.selectTerms;
      draft.isSaveDraft = true;
      return draft;
    case actionTypes.CLEAR_OFFER_DETAILS:
      draft.draftOffer = null;
      draft.selectedOfferData = null;
      draft.selectedMerchant = null;
      draft.selectedBank = null;
      draft.selectedCategory = null;
      draft.selectLocations = null;
      draft.selectTerms = null;
      draft.isSaveDraft = false;
      draft.editOfferData = null;
      draft.selectedOfferData = "";
      return draft;
    case actionTypes.CLOSE_EDIT_MODAL:
      draft.isEditMerchant = false;
      draft.isEditBank = false;
      draft.isEditCategory = false;
      draft.isUpdateOfferModal = false;
      draft.isUpdateSyncOfferModal = false;
      draft.isEditOfferDescription = false;
      draft.isEditPromo = false;
      return draft;
    case actionTypes.ADD_TERMS_AND_CONDITION:
      console.log("ADD_TERMS_AND_CONDITION", payload.selectTerms);
      draft.selectTerms = payload.selectTerms;
      return draft;
    default:
      return draft;
  }
});
