function setTheme(themeName) {

    localStorage.setItem('theme', themeName);
    let classList = document.documentElement.classList;
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
    document.documentElement.classList.add(themeName);
}

function keepTheme() {
    if (localStorage.getItem('theme')) {
        if (localStorage.getItem('theme') === 'dark-theme') {
            setTheme('dark-theme');
        } else if (localStorage.getItem('theme') === 'light-theme') {
            setTheme('light-theme')
        }
    } else {
        setTheme('light-theme')
    }
}

module.exports = {
    setTheme,
    keepTheme
};
