import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { BiX } from "react-icons/bi";
import Moment from "react-moment";

const Index = (props) => (
  <>
    <div
      key={props.key}
      className={`selection-wrapper mr-1 d-flex ${
        props.isSelect ? "is-active" : ""
      }`}
      onClick={props.onClick}
    >
      <div className={"text"}>
        {props.format === "time" && (
          <Moment format="h:mm a">{props.text}</Moment>
        )}

        {props.format === "text" && <>{props.text}</>}
        {props.format === "price" && <>Rs.{props.text}</>}
      </div>
      {props.remove && (
        <div className={"selection-wrp-checkBox"} onClick={props.onSelect}>
          <BiX />
        </div>
      )}
      {props.select && (
        <div
          className={`selection-wrp-checkBox ${props.isSelect}`}
          onClick={props.onRemove}
        >
          {props.isSelect && <BiX />}
        </div>
      )}
    </div>
  </>
);

Index.propTypes = {
};

Index.defaultProps = {
  text: "",
};

export default Index;
