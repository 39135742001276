import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IS_MOCK_DATA = process.env.REACT_APP_IS_MOCK_DATA === "TRUE";

export const saveMerchant = async (
  merchantName,
  contactNo,
  logoURL,
  webURL,
  favoriteArea,
  location,
  hotLine,
  termsAndConditionList
) => {
  try {
    const body = {
      merchantName: merchantName,
      contactNo: contactNo,
      webURL: webURL,
      logoURL: logoURL,
      favoriteArea: favoriteArea,
      location: location,
      hotLine: hotLine,
      description: null,
      termsAndConditionList: termsAndConditionList,
    };

    // Get response
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.post(`${BASE_URL}/create/new/merchant`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_MERCHANT_API:", err);
    throw err;
  }
};

export const getMerchant = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/merchant`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_MERCHANT_API:", err);
    throw err;
  }
};

export const updateMerchant = async (body, merchantId) => {
  try {
    // Get response
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.put(
      `${BASE_URL}/update/merchant/details/${merchantId}`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_MERCHANT_API:", err);
    throw err;
  }
};

export const updateMerchantTerms = async (merchantId, termsAndCondition) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = { termsAndCondition: termsAndCondition };

    // if (IS_MOCK_DATA) {
    //   return {
    //     status: "000",
    //     message: "Merchant was updated successful",
    //     validation: [],
    //   };
    // }
    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/merchant/terms/${merchantId}`,
      body,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_UPDATE_MERCHANT_TERMS_API:", err);
    throw err;
  }
};

export const updateHotLine = async (merchantId, hotLine) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = { hotLine: hotLine };
    let response = await axios.put(
      `${BASE_URL}/update/merchant/hotline/${merchantId}`,
      body,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_UPDATE_MERCHANT_TERMS_API:", err);
    throw err;
  }
};

export const deleteMerchant = async (merchantId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.delete(
      `${BASE_URL}/delete/merchant/${merchantId}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (err) {
    console.error("ERR_DELETE_MERCHANT_API:", err);
    throw err;
  }
};
