import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isPayment: false,
  selectPaymentOffer: null,
  isPaymentDetails: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("paymentReducer", payload);
  switch (type) {
    case actionTypes.OPEN_PAYMENT_MODAL:
      draft.isPayment = true;
      draft.selectPaymentOffer = payload;
      return draft;
    case actionTypes.CLOSE_PAYMENT_MODAL:
      draft.isPayment = false;
      draft.selectPaymentOffer = null;
      return draft;
    case actionTypes.OPEN_PAYMENT_DETAILS_MODAL:
      draft.isPaymentDetails = true;
      return draft;
    case actionTypes.CLOSE_PAYMENT_DETAILS_MODAL:
      draft.isPaymentDetails = false;
      return draft;
    default:
      return draft;
  }
});
