export const CURRENCY_LKR = "LKR";

export const DEF_CURRENCY = CURRENCY_LKR;

export const ACC_TYPE_SAVINGS = "SBA";
export const ACC_TYPE_CURRENT = "ODA";
export const ACC_TYPE_SAVINGS_LABEL = "Savings";
export const ACC_TYPE_Current_LABEL = "Current";

export const TX_TYPE_BILL_PAYMENT = "Bill payment";
export const TX_TYPE_FUND_TRANSFER = "Fund transfers";
export const TX_TYPE_CARD_PAYMENT = "Card payments";
export const TX_TYPE_WITHDRAWAL = "Withdrawals";
export const USER_LANDING_MESSAGE = "USER_LANDING_MESSAGE";
export const DEF_SUBSCRIPTION_ID = "DEF_SUBSCRIPTION_ID";
export const ENV_STATIC = "ENV_STATIC";

export const INVESTMENT_TYPE_FD = "TDA";
export const INVESTMENT_TYPE_PLANNER = "SBA";

export const CARD_TYPE_DEBIT = "debit";
export const CARD_TYPE_CREDIT = "credit";
export const CARD_STATUS_NORMAL = "NORM";

export const DEF_TX_PAGE_SIZE = 5;

export const PAY_NETWORK_CEFT = "CEFT";

export const BANK_CODE_NTB = "1000";
export const BANK_NAME_NTB = "Nations Trust Bank";

export const NTB_BILLER_CODE = "1002";
export const BILLER_CARD_NTB_NAME = "Nations Cards"
export const NTB_BANK_NAME = "Nations Trust Bank"

export const PAYMENT_SCHEDULE_TYPE_IMMEDIATELY = "IMM"
export const PAYMENT_SCHEDULE_TYPE_LATER = "FUT"
export const PAYMENT_SCHEDULE_TYPE_SCHEDULE = "STN"


//Product validations
export const PROD_VALIDATION_BILL_PAYMENT = "BILLPAYMENT";
export const PROD_VALIDATION_FD_TRANS_NTB = "FUNDTRANSFERTONTBACC";
export const PROD_VALIDATION_FD_TRANS_OTHER = "OTHERBANKFUNDTRANSFER";
export const PROD_VALIDATION_FD_TRANS_OWN = "OWNACCOUNTFUNDTRANSFER";
export const PROD_VALIDATION_PAY_CC = "PAYCREDITCARD";


export const LANG_CODE_ENGLISH = "en_US";
export const LANG_CODE_SINHALA = "si_LK";
export const LANG_CODE_TAMIL = "ta_LK";

export const LOCAL_STORAGE_LANGUAGE_KEY = "language";


//TX LIMIT TYPES
export const LIMIT_TYPE_TRANS_OWN_NTB = 1;
export const LIMIT_TYPE_TRANS_OTHER_NTB = 2;
export const LIMIT_TYPE_TRANS_OTHER_BANK = 3;
export const LIMIT_TYPE_PAY_OWN_NTB_CARDS = 4;
export const LIMIT_TYPE_PAY_OTHER_NTB_CARDS = 5;
export const LIMIT_TYPE_PAY_OTHER_BANK_CARDS = 6;
export const LIMIT_TYPE_CC_CARD_ADVANCE = 7;
export const LIMIT_TYPE_BILL_PAY = 8;

export const NOTIFICATION_FETCH_DELAY = 30000;


export const TX_CODE_FUND_TRANSFER = "FT";
export const TX_CODE_CARD_PAYMENT = "CP";
export const TX_CODE_BILL_PAYMENT = "BP";
