import React, {Component} from "react";
import {connect} from "react-redux";
import './styles.scss'
import {PageHeader} from "../../components"
import {cooGetAllAllObjectsFromS3Bucket} from "./service/media.service";
import {Scrollbars} from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPointWidth: 0,
            viewPointHeight: 0,
            isActiveShadow: false,
            isLoading: false,
            dataSource: [],
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    };

    updateWindowDimensions() {
        this.setState({viewPointWidth: window.innerWidth, viewPointHeight: window.innerHeight});
        //window.removeEventListener('scroll', this.handleOnScroll);
    }

    handleOnScroll = () => {
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;
        console.log("scrollTop : " + scrollTop);
        //console.log("scrollHeight : " + scrollHeight);
        //console.log("clientHeight :" + clientHeight);
    };

    async componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.handleOnScroll);
        await this.getAll()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleOnScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async getAll() {
        this.setState({isLoading: true});

        let response = await cooGetAllAllObjectsFromS3Bucket();
        console.log('get_all_media ==>', response.data.data);
        this.setState({
            dataSource: response && response.data && response.data.data,
            isLoading: false
        })
    }

    async onClickViewMoreInfo(item) {
        console.log('media_info', item);
    }


    render() {
        const {dataSource, tags, isLoading, viewPointHeight} = this.state;
        return (
            <>
                <div className={'cco__page-header-wrapper'}>
                    <PageHeader
                        option={'d-flex justify-content-between'}
                        isRefresh={true}
                        isDate={false}
                        onClickRefresh={async () => {
                            let res = await this.getAll();
                        }}
                        title={'Media'}/>

                    <div className={'cco__page-body-wrapper'}>

                        <Scrollbars
                            style={{height: viewPointHeight - 80}}
                            thumbMinSize={30}
                            universal={true}
                            autoHide>
                            <div className={'media-wrapper'}>
                                {(dataSource || []).map((item, index) => (
                                    <div className={'col-sm-2 col-md-4 col-lg-4 col-xl-4 col-2'}>
                                        <div className={'media-inner-wrapper'}
                                             onClick={() => {
                                                 this.props.onClickSelectMedia(item);
                                             }}>
                                            <div className={'img-wrapper'}>
                                                <img
                                                    src={'https://cco-media-v4.s3.ap-southeast-1.amazonaws.com/' + item.Key}
                                                    alt="creditcard offers"/>
                                            </div>
                                            {/*<div className={'media-name'}>*/}
                                            {/*    {item.Key}*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Scrollbars>

                    </div>
                </div>

            </>
        )
    }
}

const
    mapStateToProps = state => {
        return {};
    };

const
    mapDispatchToProps = dispatch => {
        return {
            onClickSelectMedia: (data) => dispatch({type: actionTypes.VIEW_MEDIA_INFO, payload: data}),
            onClickSelectMediaClear: () => dispatch({type: actionTypes.VIEW_MEDIA_INFO_CLEAR}),
        };
    };

export default connect(mapStateToProps, mapDispatchToProps)(index);



