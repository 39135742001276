import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";
import { OpenNotification } from "../../../config/notification";

import {
  Button,
  OfferCardFooterItem,
  OfferDetailCard,
  Tag,
  TermsConditionsCard,
} from "../../../components";

import { Collapse, Tabs } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import moment from "moment";
import {
  createNewPayable,
  getUserById,
  saveOffer,
  updateOfferStatus,
} from "../../../api";
import { cooUpdateMerchantTerms } from "../../merchant/service/merchant.service";
import { userDetails } from "../../../utils";

const { TabPane } = Tabs;

const momentTimezone = require("moment-timezone");

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      userObject: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };
  async fetchMyAPI() {
    //let response =  getToken();
    let response = await userDetails();
    console.log("response", response);
    this.setState({ userObject: response });
    let resUser = await getUserById(response?.userId);
    console.log("getUserById::", resUser);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  isArray = (value) => {
    return Array.isArray(value);
  };

  onClickConfirm = async () => {
    let selectOffer = this.props.selectedOffer;
    if (selectOffer && selectOffer.offerId) {
      this.setState({ isLoading: true });

      let body = {
        reason: "",
        comment: "",
        status: "accepted",
      };

      let res = await updateOfferStatus(body, selectOffer.offerId);
      let resPayable = await createNewPayable({
        offerId: selectOffer?.offerId,
        userId: selectOffer?.user?.userId,
        paymentStatus: "pending",
        offerStatus: "accepted",
      });

      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        this.props.refreshOffers();
        this.props.closeOfferViewModal();
      }
    } else {
      return false;
    }
  };

  async onClickSaveData() {
    this.setState({ isLoading: true });

    const selectedOffer = this.props.selectedOffer;
    if (selectedOffer && this.props.isCreateOffer) {
      //todo
      //confirm data
      let res = await saveOffer(
        selectedOffer?.offerId,
        selectedOffer?.userId,
        selectedOffer?.category?.categoryId,
        selectedOffer?.merchant?.merchantId,
        selectedOffer?.bank?.bankId,
        selectedOffer?.label,
        selectedOffer?.header,
        selectedOffer?.bannerImageURL,
        selectedOffer?.referenceURL,
        selectedOffer?.offerType,
        selectedOffer?.offerRate,
        selectedOffer?.offerDateType,
        selectedOffer?.offerStart,
        selectedOffer?.offerEnd,
        selectedOffer?.customizeDate,
        selectedOffer?.description,
        selectedOffer?.cardTypes,
        selectedOffer?.validDate,
        selectedOffer?.startTime,
        selectedOffer?.endTime,
        selectedOffer?.validTimeDateDescription,
        selectedOffer?.termsConditions,
        selectedOffer?.location,
        selectedOffer?.hotLine,
        selectedOffer?.promoCode
      );

      // if (
      //   Object.keys(this.props.selectMerchant?.termsAndCondition).length > 0 &&
      //   (Object.keys(this.props.selectMerchant?.termsAndCondition).find(
      //     (id) => id != selectedOffer?.bank?.bankId
      //   ) ||
      //     this.props.selectMerchant?.termsAndCondition[
      //       selectedOffer?.bank?.bankId
      //     ].length !==
      //       selectedOffer?.termsConditions[selectedOffer?.bank?.bankId].length)
      // ) {
      //   let body = {
      //     termsAndCondition: selectedOffer?.termsConditions,
      //   };

      //   let resUpdate = await cooUpdateMerchantTerms(
      //     body,
      //     selectedOffer?.merchant?.merchantId
      //   );
      //   // let resUpdate = await updateMerchantTerms(
      //   //   selectedOffer?.merchantId,
      //   //   selectedOffer?.termsConditions
      //   // );
      //   console.log("update merchant::", resUpdate);
      // }
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        // await this.clearAll();
        this.props.clearSelectedOfferDetails();
        this.props.clearSearchMerchant();
        this.props.clearOfferView();
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
      } else {
        this.setState({ isLoading: false });
        OpenNotification("error", res && res.data && res.data.error, "error");
        //await this.clearAll();
      }
    } else {
      this.setState({ isLoading: false });
      OpenNotification("Error", "Invalid data", "error");
    }
  }

  render() {
    const { viewPointHeight, isLoading } = this.state;
    const {
      isViewOffer,
      selectedOffer,
      isCreateOffer,
      onClickFavorite,
      isLogin,
    } = this.props;

    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isViewOffer ? "show-me" : "hide-me"
          } `}
        >
          <div
            className={
              "coo_modal-body-content fix-906 cco__mbc-view d-flex flex-column"
            }
            style={{
              top: 0,
            }}
          >
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"coo_mbc__header"}>{"Offer View"}</div>
              <div
                className={`close-modal-wrapper `}
                onClick={() => this.props.closeOfferViewModal()}
              >
                <RiCloseLine />

                {/* <ButtonIcon
                type={"close"}
                onClick={() => this.props.onClickMe()}
                size={"md"}
                buttonIcon={GetIcon("close")}
              /> */}
              </div>
            </div>

            <div
              className={
                "coo_modal-offer-title d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div className={"d-flex flex-row align-items-center"}>
                <div className={"coo_modal_merchant-logo"}>
                  <img
                    src={selectedOffer && selectedOffer?.merchant?.logoURL}
                  />
                </div>
                <div className={"d-flex flex-column"}>
                  <div className={"coo__offer-title"}>
                    {selectedOffer &&
                      selectedOffer?.merchant &&
                      selectedOffer?.merchant?.merchantName}{" "}
                    - {selectedOffer?.offerId}
                  </div>
                  <h3> {selectedOffer && selectedOffer?.header}</h3>
                  <div className={"coo__offer-refLink"}>
                    <a
                      href={selectedOffer && selectedOffer?.referenceURL}
                      target={"_blank"}
                    >
                      {selectedOffer && selectedOffer?.referenceURL}
                    </a>
                  </div>
                </div>
              </div>

              <Tag
                type={
                  selectedOffer?.status === "new"
                    ? "new"
                    : selectedOffer?.status === "Accepted" ||
                      selectedOffer?.status === "accepted"
                    ? "accept"
                    : selectedOffer?.status === "Rejected" ||
                      selectedOffer?.status === "rejected"
                    ? "reject"
                    : "new"
                }
                tagName={selectedOffer?.status}
                size={"lg"}
              />
            </div>
            <div className={"coo_modal_banner_wrapper"}>
              <img src={selectedOffer && selectedOffer?.bannerImageURL} />

              <div className={"coo__offer-tag"}>
                {selectedOffer?.offerType === "off" && (
                  <>{selectedOffer?.offerRate}% off</>
                )}
                {selectedOffer?.offerType === "upto" && (
                  <>upto {selectedOffer?.offerRate}% off</>
                )}
                {selectedOffer?.offerType === "customize" && (
                  <>{selectedOffer?.offerRate}</>
                )}
                {selectedOffer?.offerType === "discount" && (
                  <>{selectedOffer?.offerRate}% discount</>
                )}
              </div>
            </div>
            <Scrollbars
              style={{ height: viewPointHeight - 450 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"coo_modal-content-wrapper d-flex flex-column"}>
                <OfferDetailCard
                  type={"tag"}
                  option={"d-flex flex-column text-left mb-2 "}
                  description={null}
                  tagList={selectedOffer && selectedOffer?.location}
                  title={"Offer valid location"}
                />

                <OfferDetailCard
                  type={"tag"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={null}
                  tagList={selectedOffer && selectedOffer?.cardTypes}
                  title={"Valid card"}
                />

                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={
                    selectedOffer && selectedOffer?.promoCode
                      ? selectedOffer.promoCode
                      : "-"
                  }
                  title={"Promo code"}
                />
                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={null}
                  contactList={
                    selectedOffer &&
                    selectedOffer?.hotLine &&
                    selectedOffer?.hotLine.mobileNo
                  }
                  title={"Mobile no"}
                />
                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={null}
                  contactList={
                    selectedOffer &&
                    selectedOffer?.hotLine &&
                    selectedOffer?.hotLine.email
                  }
                  title={"E-mail"}
                />

                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={
                    selectedOffer
                      ? selectedOffer?.offerStart && selectedOffer?.offerEnd
                        ? "Valid from " +
                          moment(selectedOffer?.offerStart).format(
                            "MMMM Do YYYY"
                          ) +
                          " to " +
                          moment(selectedOffer?.offerEnd).format("MMMM Do YYYY")
                        : !selectedOffer?.offerStart && selectedOffer?.offerEnd
                        ? "Valid till " +
                          momentTimezone
                            .tz(
                              moment(selectedOffer?.offerEnd).format(),
                              "Asia/Colombo"
                            )
                            .format("MMMM Do YYYY")
                        : "-"
                      : "-"
                  }
                  title={"Validity Date"}
                />
                {selectedOffer?.offerDateType === "other" && (
                  <div className={"customize-date-description"}>
                    Note : {selectedOffer?.customizeDate?.description}
                  </div>
                )}
                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={
                    <>
                      {selectedOffer?.offerType === "off" && (
                        <>{selectedOffer?.offerRate}% off</>
                      )}
                      {selectedOffer?.offerType === "upto" && (
                        <>upto {selectedOffer?.offerRate}% off</>
                      )}
                      {selectedOffer?.offerType === "customize" && (
                        <>{selectedOffer?.offerRate}</>
                      )}
                      {selectedOffer?.offerType === "discount" && (
                        <>{selectedOffer?.offerRate}% discount</>
                      )}
                    </>
                  }
                  title={"Offer"}
                />
                <div className={"mt-3 mb-3 d-flex"}>
                  <OfferCardFooterItem
                    title={"Bank"}
                    img={
                      <img
                        className={"lading-bg"}
                        src={
                          selectedOffer &&
                          selectedOffer.bank &&
                          selectedOffer.bank.logoURL
                        }
                        alt="all offers in one place"
                      />
                    }
                    content={
                      selectedOffer &&
                      selectedOffer.bank &&
                      selectedOffer.bank.bankName
                    }
                    type={"bank"}
                  />
                  <OfferCardFooterItem
                    style={"category"}
                    title={"Category"}
                    content={
                      selectedOffer &&
                      selectedOffer.category &&
                      selectedOffer.category.categoryName
                    }
                    iconName={
                      selectedOffer &&
                      selectedOffer.category &&
                      selectedOffer.category.iconName
                    }
                    type={"icon"}
                  />
                  <OfferCardFooterItem
                    title={"Merchant"}
                    img={
                      <img
                        className={"lading-bg"}
                        src={
                          selectedOffer &&
                          selectedOffer.merchant &&
                          selectedOffer.merchant.logoURL
                        }
                        alt="all offers in one place"
                      />
                    }
                    content={
                      selectedOffer &&
                      selectedOffer.merchant &&
                      selectedOffer.merchant.merchantName
                    }
                    type={"bank"}
                  />
                  <OfferCardFooterItem
                    style={"views"}
                    title={"Views"}
                    content={selectedOffer?.views}
                    iconName={"eye"}
                    type={"icon"}
                  />
                  {isLogin && (
                    <OfferCardFooterItem
                      style={"favorite"}
                      title={"Favorite"}
                      iconName={"favorite"}
                      isFavorites={selectedOffer?.isFavorites}
                      content={selectedOffer?.interest}
                      type={"icon"}
                      onClick={onClickFavorite}
                    />
                  )}
                </div>
                <OfferDetailCard
                  type={"text"}
                  option={"d-flex flex-column text-left mb-2 mt-3"}
                  description={
                    selectedOffer &&
                    (selectedOffer?.description ||
                      selectedOffer?.validTimeDateDescription)
                  }
                  title={"Offer Description"}
                />

                <div style={{ paddingBottom: "24px" }}>
                  <OfferDetailCard
                    type={"text"}
                    option={"d-flex flex-column text-left mb-2 mt-3"}
                    description={""}
                    title={"Terms & Conditions"}
                  />
                  {this.isArray(selectedOffer?.termsConditions) ? (
                    <>
                      {selectedOffer?.termsConditions &&
                        (selectedOffer?.termsConditions || []).map(
                          (item, index) => (
                            <TermsConditionsCard
                              description={item && item.value}
                            />
                          )
                        )}
                    </>
                  ) : (
                    <div className={"cco__mtc-tab mt-2"}>
                      <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={selectedOffer?.bank?.bankName}
                          key={selectedOffer?.bank?.bankId}
                        >
                          {selectedOffer && (
                            <div className="mt-2">
                              {selectedOffer?.termsConditions[
                                selectedOffer?.bank?.bankId
                              ]
                                ?.filter((x) => x.isActive)
                                ?.map((item, index) => (
                                  <TermsConditionsCard
                                    description={item && item.value}
                                  />
                                ))}
                            </div>
                          )}
                        </TabPane>
                      </Tabs>
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
            <div className={"ov__footer-action-wrapper"}>
              {this.state.userObject?.userType != "user" && !isCreateOffer && (
                <div style={{ width: "130px" }}>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    option={"mr-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      this.props.onClickOpenRejectReason();
                    }}
                    text={"Reject"}
                    size={"md"}
                    type={"btn-block-back"}
                  />
                </div>
              )}
              {!isCreateOffer && (
                <div style={{ width: "130px" }}>
                  <Button
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("leftArrow")}
                    onClick={() => {
                      this.props.closeOfferViewModal();
                    }}
                    text={"Cancel"}
                    size={"md"}
                    type={"btn-block-back"}
                  />
                </div>
              )}
              {this.state.userObject?.userType != "user" &&
                ((selectedOffer && selectedOffer.status === "new") ||
                  selectedOffer?.status === "rejected" ||
                  isCreateOffer) && (
                  <div style={{ width: "174px" }}>
                    <Button
                      leftIconFill={true}
                      iconName={<RiCheckLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-2"}
                      isLoading={isLoading}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (isCreateOffer) {
                          await this.onClickSaveData();
                        } else {
                          await this.onClickConfirm();
                        }
                      }}
                      LeftIconText={
                        isCreateOffer ? "Sent to Review" : "Approve"
                      }
                      text={""}
                      size={"md"}
                      type={"btn-block"}
                    />
                  </div>
                )}
              {this.state.userObject?.userType === "user" && isCreateOffer && (
                <div style={{ width: "158px" }}>
                  <Button
                    leftIconFill={true}
                    iconName={<RiCheckLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-2"}
                    isLoading={isLoading}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    onClick={async () => {
                      if (isCreateOffer) {
                        await this.onClickSaveData();
                      } else {
                        await this.onClickConfirm();
                      }
                    }}
                    LeftIconText={isCreateOffer ? "Done" : ""}
                    text={""}
                    size={"md"}
                    type={"btn-block"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isViewOffer: state.offerReducer.isViewOffer,
    isCreateOffer: state.offerReducer.isCreateOffer,
    selectedOffer: state.offerReducer.selectedOffer,
    selectMerchant: state.merchantReducer.selectMerchant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeOfferViewModal: () =>
      dispatch({ type: actionTypes.CLOSE_OFFER_VIEW_MODAL, status: false }),
    clearOfferView: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_VIEW_MODAL, status: false }),
    onClickOpenRejectReason: (payload) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_REJECT_MODAL,
        payload: payload,
      }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    clearSelectedOfferDetails: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_OFFERS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
