import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {TOKEN_KEY} from "../utils";
import {getApiDateTime, getApiLanguageCodeFormatted, getApiUuid, getUuid} from "../utils";

let store

export const injectStore = _store => {
    if (_store) {
        store = _store
    }
};

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem(TOKEN_KEY);;
    return config;
});

export * from './auth';
export * from './category';
export * from './bank';
export * from './media';
export * from './merchant';
export * from './offer';
export * from './user';
export * from './payable';
export * from './NDB';
