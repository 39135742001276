import React from "react";

export const TOKEN_KEY = "iszkQAdmin@creditcard9SQdT";
const jwt = require("jsonwebtoken");

export const login = (token) => {
  return new Promise((resolve, reject) => {
    try {
      // Store the token in localStorage
      localStorage.setItem(TOKEN_KEY, token);
      // Resolve the promise successfully
      resolve();
    } catch (error) {
      // Reject the promise if an error occurs
      reject(error);
    }
  });
};

export const getToken = () => {
  // console.log('get token....');
  return localStorage.getItem(TOKEN_KEY);
};

export const userLogOut = () => {
  console.log("remove token....");
  localStorage.removeItem(TOKEN_KEY);
  return true;
};

export const isValidLogin = async () => {
  const token = await getToken();
  if (token) {
    let decoded = jwt.decode(token);
    if (decoded && decoded.loginUser) {
      return true;
    } else {
      console.log("User cant be access main console : logout");
      return false;
    }
  } else {
    console.log("User cant be access main console : logout");
    return false;
  }
};

export const userDetails = async () => {
  //console.info('get  user details....');
  const token = localStorage.getItem(TOKEN_KEY);
  console.log("tokenUserDetails", jwt.decode(token));
  if (token) {
    console.log(jwt.decode(token));
    return jwt.decode(token);
  } else {
    console.info("user  details not found....");
    return false;
  }
};
