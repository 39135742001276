/**
 * api routes details
 * @author damith
 */

const ApiRoutes = {
  //BANK
  CREATE_NEW_BANK: "/create/new/bank",
  GET_ALL_BANK: "/get/all/banks",
  GET_BANK_BY_ID: "/get/bank/by",
  UPDATE_BANK: "/update/bank/details",
  UPDATE_BANK_CARDS: "/update/bank/cards",

  //CATEGORY
  CREATE_NEW_CATEGORY: "/create/new/category",
  GET_ALL_CATEGORY: "/get/all/categories",
  UPDATE_CATEGORY: "/update/category",

  //MERCHANT
  CREATE_NEW_MERCHANT: "/create/new/merchant",
  GET_ALL_MERCHANT: "/get/all/merchant",
  GET_ALL_MERCHANT_BY_PAGE: "/get/all/merchant/by",
  GET_OFFER_BY_MERCHANT: "/get/offer/publish/by/merchant/",
  UPDATE_MERCHANT_TERMS: "/update/merchant/terms/",
  FIND_MERCHANT_BY_NAME: "get/merchants/autocomplete/",
  FIND_MERCHANT_BY_ID: "get/merchant/by/",
  // UPDATE_MERCHANT_TERMS: "/update/merchant/new/terms/",
  UPDATE_MERCHANT_LOCATIONS: "/update/merchant/locations/",
  UPDATE_MERCHANT_STATUS: "/update/offer/status",
  MERCHANT_OFFER_COUNT: "/get/merchant/by/offer/count/",
  MERCHANT_CONVERT_TERMS: "/convert/merchant/terms/",

  //Media
  GET_ALL_MEDIA: "/get/all/media",
};

export default ApiRoutes;
