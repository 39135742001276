import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
  value: null,
};

const RichTextBoxReducer = createSlice({
  name: "RichTextBoxReducer",
  initialState: initialState,
  reducers: {
    setValue: (state, payload) => {
      return {
        ...state,
        value: payload,
      };
    },
    clearValue: (state, payload) => {
      return {
        ...state,
        value: null,
      };
    },
  },
});

export default RichTextBoxReducer;
