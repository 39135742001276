import axios from "axios";
import { getAppBaseUrl } from "../utils/environment";
import { getToken } from "../utils/auth";

//axios.defaults.withCredentials=true;
const ccoInstance = axios.create({
  baseURL: getAppBaseUrl(),
});

ccoInstance.interceptors.request.use(
  async (config) => {
    let accessToken = await getToken();
    config.headers = {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    //read token from state
    // let accessToken = store.getState().profile.auth.accessToken;
    // if (accessToken) {
    //     config.headers.common.Authorization = `Bearer ${accessToken}`;
    // }
    //read username from state
    // let username = store.getState().profile.user.username;
    // if (username) {
    //     config.headers.common.username = username;
    // }
    //read subscriptionId from state

    //set common headers
    //config.headers.common['Content-Type'] = "application/json";
    //config.headers.common['datetime'] = getApiDateTime();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
ccoInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      delete axios.defaults.headers.common["Authorization"];
      return ccoInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default ccoInstance;
