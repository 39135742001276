import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  selectedOfferData: "",
  selectedOffer: null,
  isOfferReject: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("rejectReducer", payload);
  switch (type) {
    case actionTypes.OPEN_OFFER_REJECT_MODAL:
      draft.selectedOffer = payload;
      draft.isOfferReject = true;
      return draft;
    case actionTypes.CLOSE_OFFER_REJECT_MODAL:
      draft.selectedOffer = null;
      draft.isOfferReject = false;
      return draft;
    default:
      return draft;
  }
});
