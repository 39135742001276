import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => {
 
  return (
    <div
      className={`link-main-wrapper 
        ${props.mainLayerStyles} ${props.size}`}
      onClick={props.onClick}
    >
      <div className={"d-flex justify-content-center"}>
        <div className={`lmw__urlName`}>
          <a href={props.url}> {props.urlName}</a>
        </div>
      </div>
    </div>
  );
};

Index.propTypes = {
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlName: PropTypes.string.isRequired,
};
Index.defaultProps = {
  message: "unknown",
  url: "unknown",
  urlName: "unknown",
};

export default Index;
