import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../../store/actions/types";
import {
  getPendingPaymentsByUserId,
  getSessionValidateStatus,
  getUserById,
} from "../../../api";
import { getToken, userDetails } from "../../../utils";
import Payment from "./Payment";
import Profile from "./Profile";
import { onClickClosePayment } from "../../../store/actions/payment";
import { FiDollarSign, FiUser } from "react-icons/fi";
import { ButtonIcon, MenuCardNew, ProfileBox } from "../../../components";
import { GetIcon } from "../../../config/icon";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      pendingOffers: [],
      acceptedOffers: [],
      rejectedOffers: [],
      pendingCount: 0,
      acceptedCount: 0,
      formWizard: 1,
      pendingPaymentAmount: 0,
      pendingPaymentCount: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    let state = await userDetails();
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      console.log("state::::", state);
      if (state && state.userId) {
        let res = await getUserById(state?.userId);
        console.log("getUserById", res);
        if (res && res.status) {
          const token = await getToken();
          let user = res.data;
          let data = {
            ...user,
            token: token,
          };

          // let resPayments = await getPendingPaymentsByUserId(
          //   user?.userId,
          //   "pending"
          // );
          // console.log("resPayments:::", resPayments);

          this.setState({
            user: data,
            isLoading: false,
            // pendingPaymentAmount: resPayments?.data?.totalPendingAmount,
            // pendingPaymentCount: resPayments?.data?.pendingPaymentCount,
            // offerDataSource: resPayments?.data?.offers,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { viewPointHeight, isLoading, tabJson, user, formWizard } =
      this.state;
    const { isPayment, location } = this.props;

    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated  ${
            isPayment ? "show-me animate__slideInLeft" : "hide-me"
          }`}
          style={{ zIndex: "9999" }}
        >
          <div
            className={"coo_modal-body-content d-flex flex-row full-height"}
            style={{ padding: "22px" }}
          >
            <div className={"d-flex flex-column"}>
              <div className={"d-flex flex-row"}>
                <ButtonIcon
                  onClick={() => this.props.onClickClosePayment()}
                  buttonIcon={GetIcon("chevronLeftt")}
                  type={"collapse-icon"}
                  size={"md"}
                />
                <div className={"cco__mbc-back"}>Back</div>
              </div>
              <div className={"cco__mbc-profile"}>
                <ProfileBox
                  profileAvatar={user && user.profileAvatar}
                  userName={user && user.firstName + " " + user.lastName}
                  userEmail={user && user.userName}
                  userType={
                    user &&
                    user.userType.charAt(0).toUpperCase() +
                      user.userType.slice(1)
                  }
                />
              </div>
              <div>
                <MenuCardNew
                  icon={<FiUser />}
                  text={"Profile"}
                  onClick={async () => {
                    if (formWizard === 2) {
                      this.setState({
                        formWizard: 1,
                      });
                    }
                  }}
                  isActive={formWizard === 1 ? true : false}
                />
                <MenuCardNew
                  icon={<FiDollarSign />}
                  text={"Payments"}
                  onClick={async () => {
                    if (formWizard === 1) {
                      this.setState({
                        formWizard: 2,
                      });
                    }
                  }}
                  isActive={formWizard === 2 ? true : false}
                />
              </div>
            </div>
            <div className={"d-flex flex-column"} style={{ width: "100%" }}>
              {formWizard === 1 && (
                <>
                  <Profile />
                </>
              )}
              {formWizard === 2 && (
                <>
                  <Payment />
                </>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPayment: state.paymentReducer.isPayment,
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClosePayment: () =>
      dispatch({ type: actionTypes.CLOSE_PAYMENT_MODAL, status: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
