import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isImpression: false,
  selectedInsight: null,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("impressionReducer", payload);
  switch (type) {
    case actionTypes.OPEN_IMPRESSION_MODAL:
      draft.isImpression = true;
      draft.selectedInsight = payload;
      return draft;
    case actionTypes.CLOSE_IMPRESSION_MODAL:
      draft.isImpression = false;
      draft.selectedInsight = null;
      return draft;
    default:
      return draft;
  }
});
