import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./styles.scss";
import "./mobile.scss";

import { getToken, login, userLogOut } from "../../utils";
import {
  authentication,
  getSessionValidateStatus,
  newOTPVerify,
} from "../../api";

import { GetIcon } from "../../config/icon";

import {
  Button,
  FilterColor_1,
  FilterColor_2,
  Input,
  JoinFormHeader,
} from "../../components";
import { OpenNotification } from "../../config/notification";
import { setUser } from "../../store/actions";

class LoginPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      noOfSpot: 2,
      active: 1,
      isLoading: false,
      isDisable: false,
      dataForm: {
        userName: {
          key: "userName",
          elementType: "input",
          elementConfig: {
            type: "userName",
            placeholder: "myusername",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Username",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*******************",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    console.log("getToken", getToken());

    // let sessionStatus = await getSessionValidateStatus();
    // console.log("sessionStatus", sessionStatus);
    // if (!sessionStatus?.data?.status) {
    //   userLogOut();
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  onClickLogin = async () => {
    try {
      this.setState({ isLoading: true });
      const dataForm = this.state.dataForm;
      //await userLogOut();
      let body = {
        userName: dataForm.userName.value,
        password: dataForm.password.value,
      };
      console.log("body:", body);
      //todo
      //validation
      //authentication
      let res = await authentication(
        dataForm.userName.value,
        dataForm.password.value
      );

      console.log("authentication:", res);
      if (res && res && res.status) {
        let user = res.data;
        this.setState({ isLoading: false });
        await login(res.token);
        let data = {
          ...user,
          token: res.token,
        };
        await this.props.setUser(data);
        let process = user.profileProcess;
        this.props.history.push("/");
        if (!process["isMobileVerification"]) {
          if (user && user.userId) {
            const token = getToken();
            console.log("authentication:", res.token);

            let mobileNo = user.contactNo.replace("+94", "");
            let resOtp = await newOTPVerify(
              user.userId,
              "MOBILE",
              res.token,
              mobileNo
            );
            if (resOtp && resOtp.data && resOtp.data.status) {
              this.props.history.push("/verification");
            }
          }
        } else if (!process["isPassword"]) {
          this.props.history.push("/authentication");
        } else if (!process["isBankDetails"]) {
          this.props.history.push("/payment");
        } else if (!process["isStatus"]) {
          // handle isStatus true case
        } else {
          this.props.history.push("/");
        }
      } else {
        OpenNotification("error", "Invalid login details.", "error");
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error("GOT LOGIN ERROR:", e);
      OpenNotification("error", e, "error");
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dataForm.password.value !==
        this.state.dataForm.password.value ||
      prevState.dataForm.userName.value !== this.state.dataForm.userName.value
    ) {
      if (
        this.state.dataForm.password.value != "" &&
        this.state.dataForm.userName.value != ""
      ) {
        this.setState({ isDisable: true });
      } else {
        this.setState({ isDisable: false });
      }
    }
  }

  render() {
    const { dataForm, isLoading } = this.state;
    return (
      <>
        <div className={"main-fix-bg"} />
        <div className={"join-artworkTop"}>
          <img src={require("../../assets/img/join_artWork_top.svg")} />
        </div>
        <section className={"container"}>
          <div className="row ">
            <section className={"login-main-wrapper"}>
              <div className={"welcome-body-wrp"}>
                <div className={"login-b-left-wrp "}>
                  <div className={"welcome-body-header d-flex flex-column"}>
                    <JoinFormHeader
                      isLogo={true}
                      wizardNo={1}
                      history={this.props.history}
                      header={"Sign in"}
                    />
                  </div>
                  <div className={"cco__login-form-wrapper"}>
                    <>
                      <Input
                        elementConfig={dataForm.userName.elementConfig}
                        elementType={dataForm.userName.elementType}
                        required={dataForm.userName.validation.required}
                        isinvalid={!dataForm.userName.valid}
                        touched={dataForm.userName.touched}
                        value={dataForm.userName.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-b-2"}
                        label={dataForm.userName.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.userName.key)
                        }
                      />

                      <Input
                        elementConfig={dataForm.password.elementConfig}
                        elementType={dataForm.password.elementType}
                        required={dataForm.password.validation.required}
                        isinvalid={!dataForm.password.valid}
                        touched={dataForm.password.touched}
                        value={dataForm.password.value}
                        isShowRequired={true}
                        size={"md"}
                        margin={"m-t-0"}
                        label={dataForm.password.label}
                        changed={(event) =>
                          this.inputHandleChange(event, dataForm.password.key)
                        }
                      />
                    </>
                  </div>
                  <div class="checkbox mb-3">
                    <div
                      class="cf-turnstile"
                      data-sitekey="1x00000000000000000000AA"
                      data-callback="javascriptCallback"
                      data-theme="light"
                    ></div>
                  </div>
                  <div className={"login-footer-action-wrapper d-flex mt-4"}>
                    <Button
                      leftIcon={false}
                      rightIcon={false}
                      isLoading={isLoading}
                      disabled={!this.state.isDisable}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("leftArrow")}
                      onClick={() => this.onClickLogin()}
                      text={"Sign in"}
                      size={"lg"}
                      type={"btn-block"}
                    />
                  </div>
                </div>
                <div className={"app-version"}>
                  Version {process.env.REACT_APP_VERSION}
                </div>
              </div>
            </section>
          </div>
          <div className={"filter-bg-1"}>
            <FilterColor_1 />
          </div>
          <div className={"filter-bg-2"}>
            <FilterColor_2 />
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
