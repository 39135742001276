import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./styles.scss";
import "./mobile.scss";
import {
  AdminUser,
  Advertisement,
  AdvertisementTest,
  Bank,
  Category,
  ExpiredOffers,
  Insights,
  Media,
  MediaInfo,
  Merchant,
  Offer,
  Subscriptions,
  UserPrivilege,
  Customer,
  OfferPlanner,
  SEOMapping,
} from "../../containers";
import { getToken, userDetails, userLogOut } from "../../utils";
import {
  CreateNewBank,
  CreateNewCategory,
  CreateNewMerchant,
  CreateNewOffer,
  CreateNewPrivilege,
  CreateNewUser,
} from "../createNewItems";
import Dashboard from "../dashboard";
import {
  CreateManageTermsAndConditions,
  EditCardsTypeModal,
  EditLocationsModal,
  EditReservationContacts,
  EditSelectedMerchantModal,
  ManageTermsAndConditionsModal,
  OfferViewModal,
  PaymentDetailsModal,
  PaymentModal,
  ProfileSetting,
  RejectReasonModal,
  SearchMerchantModal,
  ViewMerchantOffers,
  ViewPaymentsModal,
  NewMerchant,
  UpdateOfferDetails,
  Impression,
  SyncOfferViewModal,
  UpdateSyncOfferDetails,
  EditDescription,
  EditPromoDetails,
  EditOfferCardsTypeModal,
} from "../modal";
import * as actionTypes from "../../store/actions/types";
import { getSessionValidateStatus, getUserById } from "../../api";
import { setUser } from "../../store/actions";
import { Spin } from "antd";
import EditSelectedBank from "../modal/EditSelectedBank";
import EditSelectedCategory from "../modal/EditSelectedCategory";
import { cooGetAllBank } from "../bank/service/bank.service";
import { LeftMenuCard } from "./template";
import BulkAction from "../bulkAction";

class ConsoleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      notifications: [],
      userData: null,
      pageNo: 0,
      limit: 20,
      isLoading: false,
      isHide: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.timer = null;
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = (event) => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);

    let scrollTopF = event.srcElement.body.scrollTop;
    console.log("scrollTopF", scrollTopF);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    //this.props.onClickOfferVIew();
    //this.props.onClickOfferReject();

    //
    //let sessionStatus = await getSessionValidateStatus();
    let state = await userDetails();
    // console.log("isValidLogin", await isValidLogin());
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      console.log("state::::", state);
      if (state && state.userId) {
        let res = await getUserById(state?.userId);
        console.log("getUserById", res);
        if (res && res.status) {
          const token = await getToken();
          let user = res.data;
          let data = {
            ...user,
            token: token,
          };
          console.log("getUserById:::", data);
          // await login(res.token);
          let process = user.profileProcess;

          if (!process["isMobileVerification"]) {
            // let mobileNo = user.contactNo.replace("+94", "");
            // let res = await newOTPVerify(user.userId, "MOBILE", token, mobileNo);
            // if (res && res.data && res.data.status) {
            this.props.history.push("/verification");
            // }
          } else if (!process["isPassword"]) {
            this.props.history.push("/authentication");
          } else if (!process["isBankDetails"]) {
            this.props.history.push("/payment");
          } else if (!process["isStatus"]) {
            // handle isStatus true case
          } else {
            this.setState({ isLoading: false });
            this.props.history.push("/");
          }
          if (user?.isAccountBlock) {
            this.setState({ isLoading: false });
            userLogOut();
            this.props.history.push("/blocked");
          } else {
            await this.props.setUser(data);
            // this.props.history.push("/");
            this.setState({ userData: data, isLoading: false });
            // this.props.history.push("/dashboard");
            let resBank = await cooGetAllBank();
            this.props.setOnLoadBank(resBank?.data?.data);
          }
        }
      } else {
        this.setState({ isLoading: false });
        userLogOut();
        this.props.history.push("/sign-in");
      }
    } else {
      this.setState({ isLoading: false });
      userLogOut();
      this.props.history.push("/sign-in");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    //clear timer and stop
  }

  onClickGoPage(_pageName) {
    console.log(_pageName);
    switch (_pageName) {
      case "dashboard":
        this.props.history.push("/");
        return;
      case "category":
        this.props.history.push("/category");
        return;
      case "bank":
        this.props.history.push("/bank");
        return;
      case "merchant":
        this.props.history.push("/merchant");
        return;
      case "offers":
        this.props.history.push("/offers");
        return;
      case "expired-offers":
        this.props.history.push("/expired-offers");
        return;
      case "bulk-action":
        this.props.history.push("/bulk-action");
        return;
      case "users":
        this.props.history.push("/users");
        return;
      case "privilege":
        this.props.history.push("/privilege");
        return;
      case "media":
        this.props.history.push("/media");
        return;
      case "advertisement":
        this.props.history.push("/advertisement");
        return;
      case "advertisement-test":
        this.props.history.push("/advertisement-test");
        return;
      case "subscriptions":
        this.props.history.push("/subscriptions");
        return;
      case "customer":
        this.props.history.push("/customer");
        return;
      case "offer-planner":
        this.props.history.push("/offer-planner");
        return;
      case "insights":
        this.props.history.push("/insights");
        return;
      case "seo-mapping":
        this.props.history.push("/seo-mapping");
        return;
      default:
        return;
    }
  }
  onClickLogOut() {
    userLogOut();
    this.props.history.push("/sign-in");
  }

  render() {
    const { location, pageName, isViewOffer, isViewSyncOffer, isOfferReject } =
      this.props;
    const { viewPointWidth, viewPointHeight, isLoading } = this.state;

    return (
      <>
        {this.props.isViewOffer && <OfferViewModal />}
        {this.props.isViewSyncOffer && <SyncOfferViewModal />}
        {this.props.isOfferReject && <RejectReasonModal />}
        {this.props.isOpenSearchMerchant && <SearchMerchantModal />}
        {this.props.isOpenViewMerchantOffers && <ViewMerchantOffers />}
        {this.props.isBankCardType && <EditCardsTypeModal />}
        {this.props.isEditLocation && <EditLocationsModal />}
        {this.props.isEditMerchant && <EditSelectedMerchantModal />}
        {this.props.isEditBank && <EditSelectedBank />}
        {this.props.isEditCategory && <EditSelectedCategory />}
        {this.props.isEditPromo && <EditPromoDetails />}
        {this.props.isTermsAndConditions && <ManageTermsAndConditionsModal />}
        {this.props.isNewTermsAndConditions && (
          <CreateManageTermsAndConditions />
        )}
        {this.props.isPayment && <ViewPaymentsModal />}
        {this.props.isPaymentDetails && <PaymentDetailsModal />}
        {this.props.isEditContacts && <EditReservationContacts />}
        {this.props.isProfileSetting && <ProfileSetting />}
        {this.props.isImpression && <Impression />}
        {this.props.isUpdateOfferModal && <UpdateOfferDetails />}
        {this.props.isUpdateSyncOfferModal && <UpdateSyncOfferDetails />}
        {this.props.isEditOfferDescription && <EditDescription />}
        {this.props.isOfferBankCardType && <EditOfferCardsTypeModal />}

        {!isLoading && (
          <div className={"cco__console-wrapper"}>
            <LeftMenuCard
              pageName={location.pathname}
              onClickGoPage={(_pageName) => this.onClickGoPage(_pageName)}
              onClick={() => this.setState({ isHide: !this.state.isHide })}
              isHide={this.state.isHide}
              onClickLogOut={() => this.onClickLogOut()}
            />
          </div>
        )}
        {this.props.isLoading && (
          <div style={{ margin: "20% 50%", opacity: 0.5 }}>
            <Spin size="large" spinning={this.props.isLoading} />
          </div>
        )}
        {!isLoading && (
          <>
            {location.pathname === "/" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/" && !isLoading && (
                    <Dashboard history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/advertisement" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/advertisement" && (
                    <Advertisement history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/advertisement-test" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/advertisement-test" && (
                    <AdvertisementTest history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/expired-offers" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/expired-offers" && (
                    <ExpiredOffers history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/bulk-action" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/bulk-action" && (
                    <BulkAction history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/subscriptions" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/subscriptions" && !isLoading && (
                    <Subscriptions history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/customer" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/customer" && !isLoading && (
                    <Customer history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/insights" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/insights" && !isLoading && (
                    <Insights history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/offer-planner" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/offer-planner" && !isLoading && (
                    <OfferPlanner history={this.props.history} />
                  )}
                </div>
              </>
            ) : location.pathname === "/seo-mapping" ? (
              <>
                <div
                  className={`cco_console-dashboard-wrapper ${
                    this.state.isHide ? "hide" : ""
                  }`}
                >
                  {location.pathname === "/seo-mapping" && !isLoading && (
                    <SEOMapping history={this.props.history} />
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`cco_console-content-wrapper ${
                    this.state.userData?.userType === "user" &&
                    location.pathname === "/category" &&
                    location.pathname === "/bank"
                      ? "no-right"
                      : ""
                  } ${this.state.isHide ? "hide" : ""}`}
                >
                  <div
                    className={`col-lg-12 pl-0 pr-0 ml-80px ${
                      this.state.isHide ? "hide" : ""
                    } `}
                  >
                    {/* {location.pathname === '/dashboard' && <Dashboard history={this.props.history}/>} */}
                    {location.pathname === "/category" && (
                      <Category history={this.props.history} />
                    )}
                    {location.pathname === "/bank" && (
                      <Bank history={this.props.history} />
                    )}
                    {location.pathname === "/merchant" && (
                      <Merchant history={this.props.history} />
                    )}
                    {location.pathname === "/offers" && (
                      <Offer history={this.props.history} />
                    )}
                    {location.pathname === "/users" && (
                      <AdminUser history={this.props.history} />
                    )}
                    {location.pathname === "/privilege" && (
                      <UserPrivilege history={this.props.history} />
                    )}
                    {location.pathname === "/media" && (
                      <Media history={this.props.history} />
                    )}
                  </div>
                </div>

                <div
                  className={`cco__console-form-section ${
                    this.state.userData?.userType === "user" &&
                    location.pathname === "/category" &&
                    location.pathname === "/bank"
                      ? "no-right"
                      : ""
                  }`}
                >
                  {this.state.userData?.userType != "user" && (
                    <>
                      {location.pathname === "/category" && (
                        <CreateNewCategory history={this.props.history} />
                      )}
                      {location.pathname === "/bank" && (
                        <CreateNewBank history={this.props.history} />
                      )}
                    </>
                  )}
                  {location.pathname === "/merchant" && (
                    <CreateNewMerchant history={this.props.history} />
                  )}
                  {location.pathname === "/offers" && (
                    <CreateNewOffer history={this.props.history} />
                  )}
                  {location.pathname === "/users" && (
                    <CreateNewUser history={this.props.history} />
                  )}
                  {location.pathname === "/privilege" && (
                    <CreateNewPrivilege history={this.props.history} />
                  )}
                  {location.pathname === "/media" && (
                    <MediaInfo history={this.props.history} />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isViewOffer: state.offerReducer.isViewOffer,
    isViewSyncOffer: state.offerReducer.isViewSyncOffer,
    isTermsAndConditions: state.termsReducer.isTermsAndConditions,
    isOpenSearchMerchant: state.merchantReducer.isOpenSearchMerchant,
    isUpdateOfferModal: state.offerReducer.isUpdateOfferModal,
    isUpdateSyncOfferModal: state.offerReducer.isUpdateSyncOfferModal,
    isBankCardType: state.offerReducer.isBankCardType,
    isEditLocation: state.offerReducer.isEditLocation,
    isEditMerchant: state.offerReducer.isEditMerchant,
    isNewTermsAndConditions: state.termsReducer.isNewTermsAndConditions,
    isEditBank: state.offerReducer.isEditBank,
    isEditCategory: state.offerReducer.isEditCategory,
    isEditContacts: state.offerReducer.isEditContacts,
    isEditOfferDescription: state.offerReducer.isEditOfferDescription,
    isEditPromo: state.offerReducer.isEditPromo,
    isOfferReject: state.rejectReducer.isOfferReject,
    isOpenViewMerchantOffers: state.merchantReducer.isOpenViewMerchantOffers,
    isPayment: state.paymentReducer.isPayment,
    isPaymentDetails: state.paymentReducer.isPaymentDetails,
    isProfileSetting: state.profileReducer.isProfileSetting,
    isImpression: state.impressionReducer.isImpression,
    isOfferBankCardType: state.offerReducer.isOfferBankCardType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOnLoadBank: (bankData) =>
        dispatch({ type: actionTypes.SET_BANK_DATA, payload: bankData }),
      onClickOfferVIew: () =>
        dispatch({ type: actionTypes.CLOSE_OFFER_VIEW_MODAL, status: false }),
      onClickOfferReject: () =>
        dispatch({ type: actionTypes.CLOSE_OFFER_REJECT_MODAL, status: false }),
      setUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleComponent);
