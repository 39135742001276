import React from "react";
import "./styles.scss";
import { Popover } from "antd";

const Index = (props) => (
  <>
    {props.isHide && (
      <div
        className={`menu-card-wrapper d-flex ${
          props.isActive ? "active" : ""
        } ${props.type} ${props.size} ${props.isHide ? "hide" : ""} `}
        onClick={props.onClick}
      >
        <div className={"mc-left-wrapper d-flex"}>
          <Popover
            content={<div className={"mc-text"}>{props.text}</div>}
            placement="right"
          >
            <div className={`mc-left-icon`}>{props.icon}</div>
          </Popover>
          {!props.isHide && <div className={"mc-text"}>{props.text}</div>}
        </div>
      </div>
    )}
    {!props.isHide && (
      <div
        className={`menu-card-wrapper d-flex ${
          props.isActive ? "active" : ""
        } ${props.type} ${props.size} ${props.isHide ? "hide" : ""} `}
        onClick={props.onClick}
      >
        <div className={"mc-left-wrapper d-flex"}>
          <div className={`mc-left-icon`}>{props.icon}</div>
          {!props.isHide && <div className={"mc-text"}>{props.text}</div>}
        </div>
      </div>
    )}
  </>
);
export default Index;
