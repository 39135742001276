import { combineReducers } from "redux";
import formReducer from "./formReducer";
import categoryReducer from "./categoryReducer";
import mediaReducer from "./mediaReducer";
import RichTextBoxReducer from "../../components/UI/RichTextBox/redux/rich-textbox-reducer";
import { persistReducer } from "redux-persist";
import offerReducer from "./offerReducer";
import rejectReducer from "./rejectReducer";
import userReducer from "./userReducer";
import merchantReducer from "./merchantReducer";
import bankReducer from "./bankReducer";
import termsReducer from "./termsReducer";
import paymentReducer from "./paymentReducer";
import profileReducer from "./profileReducer";
import impressionReducer from "./impressionReducer";

const rootReducer = combineReducers({
  form: formReducer,
  categoryReducer: categoryReducer,
  mediaReducer: mediaReducer,
  offerReducer: offerReducer,
  rejectReducer: rejectReducer,
  userReducer: userReducer,
  merchantReducer: merchantReducer,
  bankReducer: bankReducer,
  termsReducer: termsReducer,
  paymentReducer: paymentReducer,
  profileReducer: profileReducer,
  impressionReducer: impressionReducer,

  richTextBox: persistReducer(
    { key: "richTextBox", storage: sessionStorage },
    RichTextBoxReducer.reducer
  ),
});

export default rootReducer;
