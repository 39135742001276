import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { MerchantDetailCard, PageHeader, Pagination } from "../../components";
import { Image, Space, Spin } from "antd";
import { cooGetAllBank } from "./service/bank.service";
import * as actionTypes from "../../store/actions/types";
import { Scrollbars } from "react-custom-scrollbars";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
      pageViewCount: 10,
      dataForm: {
        searchBank: {
          key: "searchBank",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search merchant name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    this.setState({ isLoading: true });
    let resBank = await cooGetAllBank();
    console.log(
      "get all bank ==>",
      resBank && resBank.data && resBank.data.data
    );
    this.setState({
      dataSource: resBank && resBank.data && resBank.data.data,
      isLoading: false,
    });

    this.props.setOnLoadBank(resBank?.data?.data);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshBank !== this.props.refreshBank) {
      this.setState({ isLoading: true });
      await this.getAll();
      if (!this.state.isLoading) {
        this.props.doneEditBank();
      }
    }
  }

  render() {
    const { dataSource, tags, isLoading, viewPointHeight, dataForm } =
      this.state;
    const columns = [
      {
        title: "",
        key: "logoURL",
        render: (text, record) => {
          return <Image width={60} src={record.logoURL} />;
        },
      },
      {
        title: "#",
        dataIndex: "bankId",
        key: "bankId",
      },
      {
        title: "Bank Name",
        dataIndex: "bankName",
        key: "bankName",
      },
      {
        title: "Contact no",
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: "Web URL",
        dataIndex: "webURL",
        key: "webURL",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <a href="#">View</a>
            <a href="#" onClick={() => this.props.onClickEditBank(record)}>
              Edit
            </a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div className={"cco__page-header-wrapper"}>
          <PageHeader
            option={"d-flex justify-content-between"}
            isRefresh={true}
            isDate={false}
            isLoading={isLoading}
            onClickRefresh={async () => {
              let res = await this.getAll();
            }}
            title={"Bank"}
          />
        </div>
        <Scrollbars
          style={{ height: viewPointHeight - 155 }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div className={"cco__page-body-wrapper"}>
            {dataSource && dataSource.length > 0 && (
              <>
                {dataSource
                  .filter((item) => {
                    console.log("clicked", item);
                    if (dataForm.searchBank.value === "") {
                      return item; // Corrected the return statement
                    } else if (
                      item.merchantName
                        .toLowerCase()
                        .includes(dataForm.searchBank.value.toLowerCase())
                    ) {
                      return item; // Corrected the return statement
                    }
                  })
                  .map((item, index) => (
                    <MerchantDetailCard
                      logoURL={item?.logoURL}
                      merchantName={item?.bankName}
                      isActive={false}
                      webURL={item?.webURL}
                      status={item?.status}
                      userType={this.props.userData?.userType}
                      createdAt={item?.createdAt}
                      cardType={"bank"}
                      onClickEdit={async () => {
                        this.props.onClickEditBank(item);
                        //offer count
                      }}
                    />
                  ))}
              </>
            )}
            {this.state.isLoading && (
              <div style={{ margin: "0 50%", opacity: 0.5 }}>
                <Spin size="large" spinning={isLoading} />
              </div>
            )}
          </div>
        </Scrollbars>
        <div className={"cco__pagination-wrapper"}>
          <Pagination pageSize={this.state.pageViewCount} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  refreshBank: state.bankReducer.refreshBank,
  userData: state.userReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickEditBank: (selectedData) =>
      dispatch({
        type: actionTypes.EDIT_BANK,
        payload: selectedData,
      }),
    setOnLoadBank: (bankData) =>
      dispatch({
        type: actionTypes.SET_BANK_DATA,
        payload: bankData,
      }),
    doneEditBank: () =>
      dispatch({
        type: actionTypes.DONE_BANK_UPDATE,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
