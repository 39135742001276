import React, { useState } from "react";
import axios from "axios";
import "./styles.scss";
import { AutoComplete } from "antd";

const Index = (props, value, option, onSelect, onChange, onSearch) => {
  //   const [options, setOptions] = useState([]);
  //   const fetchSuggestions = async (query) => {
  //     if (query.trim().length === 0) {
  //       setOptions([]);
  //       return;
  //     }

  //     try {
  //       const response = await axios.get(
  //         `http://localhost:6003/get/merchants/autocomplete/${value}`
  //       );
  //       console.log("response", response);
  //       const suggestions = response?.data?.data.map((merchant) => ({
  //         id: merchant.merchantId,
  //         value: merchant.merchantName,
  //         label: merchant.merchantName,
  //       }));
  //       setOptions(suggestions);
  //     } catch (error) {
  //       console.error("Error fetching suggestions:", error);
  //     }
  //   };

  return (
    <>
      <AutoComplete
        value={props.value}
        option={props.options}
        style={{ width: "100%" }}
        onSelect={props.onSelect}
        onChange={props.onChange}
        onSearch={props.onSearch}
        placeholder={props.placeholder}
      />
    </>
  );
};

export default Index;
