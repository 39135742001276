import React, { Component } from "react";
import "./styles.scss";
import { FileUpload, Input } from "../..";
import { Button, DetailsCard, ProfileTitle } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/types";
import { RiCloseLine } from "react-icons/ri";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  fetchEmail = (value) => {
    return value?.replace(
      /^([a-zA-Z])[a-zA-Z0-9._%+-]*(?=@)/,
      (match, p1) => p1 + "*".repeat(match.length - 1)
    );
  };

  render() {
    const { isProfileSetting, userData } = this.props;
    const { viewPointHeight } = this.state;
    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isProfileSetting ? "show-me" : "hide-me"
          } `}
        >
          <div
            className={
              "coo_modal-body-content cco_profileSetting-modal-wrapper fix-550 d-flex flex-column"
            }
            style={{
              zIndex: "999999",
              top: "10%",
              padding: "24px 24px 24px 24px",
            }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              <ProfileTitle title="Profile settings" />

              <div
                className={`close-modal-wrapper`}
                onClick={() => this.props.onClickCloseProfileSetting()}
              >
                <RiCloseLine />
              </div>
            </div>
            <Scrollbars
              style={{ height: viewPointHeight - 320 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className="cco_psmw-avatar">Avatar</div>
              {/* image wrapper */}
              <div className="cco_psmw-profile-wrapper d-flex flex-row">
                <div className="cco_psmw-pw-image">
                  <img src={userData?.profileAvatar} alt={"Profile Picture"} />
                </div>
                <div className="cco_psmw-pw-imageUpload ">
                  <Button
                    rightIconFill={true}
                    rightIcon={false}
                    iconName={GetIcon("fileUpload")}
                    text={"Upload"}
                    onClick={() => {
                      document.getElementById("eventImageUpload").click();
                    }}
                    type={"btn-upload"}
                  />
                </div>
              </div>
              <DetailsCard
                title={"Name"}
                type={"name"}
                detail={userData?.firstName + userData?.lastName}
                rightType={"btn"}
              />

              <DetailsCard
                title={"Email"}
                type={"email"}
                detail={this.fetchEmail(userData?.userName)}
                rightType={"btn"}
              />
              <DetailsCard
                title={"User Type"}
                type={"userType"}
                detail={userData?.userType}
              />
              <DetailsCard
                title={"Contact no"}
                type={"name"}
                detail={userData?.contactNo}
                rightType={"select"}
                isSelect={userData?.profileProcess?.isMobileVerification}
              />
              <DetailsCard
                title={"Access"}
                type={"access"}
                accessType={userData?.accessType}
                rightType={"btn"}
              />
            </Scrollbars>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isProfileSetting: state.profileReducer.isProfileSetting,
    userData: state.profileReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseProfileSetting: () =>
      dispatch({
        type: actionTypes.CLOSE_PROFILE_SETTING_MODAL,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
