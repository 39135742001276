import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";

import {
  Button,
  ButtonIcon,
  Input,
  MerchantDetailCardSmall,
} from "../../../components";

import { Collapse } from "antd";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import {
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import { getCategory } from "../../../api";
import { userDetails } from "../../../utils";
import { updateDraft } from "../../../api/draft";

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        searchCategory: {
          key: "searchCategory",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Search category name here...",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Find category",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    this.setState({
      formWizard: 1,
    });
    let user = await userDetails();
    if (user && user.userId) {
      await this.getAll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    this.setState({ isLoading: true });
    let resCategory = await getCategory();
    this.setState({
      dataSourceCategories: resCategory && resCategory.data,
      isLoading: false,
      formWizard: 1,
    });
  }

  async refresh() {
    this.setState({ isLoading: true });
    let resCategory = await getCategory();

    this.setState({
      dataSourceCategories: resCategory && resCategory.data,
      isLoading: false,
      selectLocations: [],
      termsConditions: [],
      formWizard: 1,
    });
  }

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectMerchant !== this.props.selectMerchant) {
      const updateForm = { ...this.state.dataForm };
      for (let key in updateForm) {
        updateForm[key].value = "";
        updateForm[key].touched = false;
      }
      const selectedMerchant = this.props.selectMerchant;
      const bankId =
        this.props.selectBank?.bankId || this.props.editOfferData?.bankId;

      if (selectedMerchant?.merchantId) {
        this.setState((state) => {
          let selectLocations = [];
          let termsConditions = [];
          for (let i = 0; i < selectedMerchant?.location?.length; i++) {
            selectLocations.push({
              id: selectedMerchant && selectedMerchant.location[i].id,
              tagName: selectedMerchant && selectedMerchant.location[i].tagName,
              count: selectedMerchant && selectedMerchant.location[i].count,
              isActive: false,
            });
          }
          if (
            bankId &&
            selectedMerchant?.termsAndCondition &&
            Object.keys(selectedMerchant?.termsAndCondition).length > 0 &&
            selectedMerchant?.termsAndCondition[bankId]
          ) {
            termsConditions = selectedMerchant?.termsAndCondition[bankId].map(
              (term) => ({
                id: term.id,
                value: term.value,
                count: term?.count,
                isActive: term.isActive,
              })
            );
          } else {
            if (
              selectedMerchant &&
              !Array.isArray(selectedMerchant.termsAndCondition)
            ) {
              console.error("selectedTerms is not an array");
            } else {
              for (
                let i = 0;
                i < selectedMerchant?.termsAndCondition.length;
                i++
              ) {
                const term =
                  selectedMerchant && selectedMerchant.termsAndCondition[i];
                termsConditions.push({
                  id: term.id,
                  value: term.value,
                  count: term?.count,
                  isActive: term.isActive,
                });
              }
            }
          }
          state.selectLocations.concat(selectLocations);

          return {
            selectLocations,
            termsConditions,
          };
        });
      }
    }

    if (prevProps.editCategory !== this.props.editCategory) {
      const editCategory = this.props.editCategory;

      if (editCategory) {
        this.setState({
          formWizard: 2,
          selectedBank: this.props.selectBank,
          selectedBankId: this.props.selectBank?.bankId,
          selectedMerchantID: this.props.selectMerchant.merchantId,
          selectedCategoryId: editCategory.categoryId,
          selectedCategory: editCategory,
          selectLocations: this.props.selectLocations,
          termsConditions: this.props.selectTerms,
        });
      }
    }
  }

  render() {
    const {
      viewPointHeight,
      dataForm,
      dataSourceCategories,
      isLoading,
      formWizard,
      selectLocations,
      termsConditions,
      selectedCategoryId,
      selectedBank,
      selectedCategory,
    } = this.state;
    const { isEditCategory, selectMerchant, editCategory, selectedOfferData } =
      this.props;

    return (
      <>
        {this.props.isEditCategory && (
          <section
            className={`coo__modal-main-wrapper animate__animated ${
              isEditCategory ? "show-me" : "hide-me"
            } `}
          >
            <div
              className={"coo_modal-body-content fix-906 d-flex flex-column "}
              style={{ height: "97%" }}
            >
              <div
                className={
                  "d-flex flex-row justify-content-between align-items-center"
                }
              >
                <div className={"d-flex justify-content-between"}>
                  <div className={"coo_mbc__header mr-4"}>{"Edit Bank"}</div>
                  <Button
                    text={"Refresh"}
                    type={"btn-edit"}
                    isLoading={false}
                    onClick={async () => {
                      await this.refresh();
                    }}
                    size={"sm primary"}
                  />
                </div>

                <div
                  className={`close-modal-wrapper `}
                  onClick={() => {
                    this.props.onClickCloseEditModal();
                    // this.props.onClickClearSelected();
                    // this.props.clearSearchMerchant();
                  }}
                >
                  <RiCloseLine />
                </div>
              </div>
              <div className={"merchant-search-wrapper mb-3"}>
                <div className={"mt-1"}>
                  <Input
                    elementConfig={dataForm.searchCategory.elementConfig}
                    elementType={dataForm.searchCategory.elementType}
                    required={dataForm.searchCategory.validation.required}
                    isValid={!dataForm.searchCategory.valid}
                    touched={dataForm.searchCategory.touched}
                    value={dataForm.searchCategory.value}
                    isShowRequired={false}
                    size={"sm"}
                    margin={"m-b-1 m-t-2"}
                    label={dataForm.searchCategory.label}
                    changed={
                      (event) =>
                        this.inputHandleChange(
                          event,
                          dataForm.searchCategory.key
                        )
                      // console.log("event changed")
                    }
                  />
                  {/*<div className={"search-action-left-wrp"}></div>*/}
                </div>
              </div>

              <Scrollbars
                style={{ height: viewPointHeight - 220 }}
                thumbMinSize={30}
                renderScrollbarVertical={({ style, ...props }) => {
                  return (
                    <div
                      style={{
                        ...style,
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                      {...props}
                    />
                  );
                }}
                universal={true}
                autoHide
              >
                {/*categories list view */}
                <div className={"merchant-list-wrapper "}>
                  {dataSourceCategories && dataSourceCategories.length > 0 && (
                    <>
                      {dataSourceCategories
                        .filter((item) => {
                          if (dataForm.searchCategory.value === "") {
                            return item; // Corrected the return statement
                          } else if (
                            item.categoryName
                              .toLowerCase()
                              .includes(
                                dataForm.searchCategory.value.toLowerCase()
                              )
                          ) {
                            return item; // Corrected the return statement
                          }
                        })
                        .map((item, index) => (
                          <>
                            {/* <MerchantDetailCard
                              merchantName={item?.categoryName}
                              isActive={
                                item && item.categoryId === selectedCategoryId
                              }
                              webURL={item?.categoryId}
                              cardType={"category"}
                              iconName={item?.iconName}
                              status={item?.status}
                              createdAt={item?.createdAt}
                              btnText={"select"}
                              onClick={async () => {
                                this.setState({
                                  selectedCategoryId: item.categoryId,
                                  selectedCategory: item,
                                  formWizard: 1,
                                });
                              }}
                            /> */}
                            <MerchantDetailCardSmall
                              merchantName={item?.categoryName}
                              isActive={
                                item && item.categoryId === selectedCategoryId
                              }
                              iconName={item?.iconName}
                              categoryId={item && item.categoryId}
                              onClick={async () => {
                                this.setState({
                                  selectedCategoryId: item.categoryId,
                                  selectedCategory: item,
                                  formWizard: 1,
                                });
                              }}
                            />
                          </>
                        ))}
                    </>
                  )}
                </div>
              </Scrollbars>
              {editCategory && (
                <div
                  className={
                    "sm-footer-action-wrapper d-flex justify-content-between align-items-center"
                  }
                >
                  <div className="page-number">1</div>
                  <div>
                    <Button
                      leftIconFill={true}
                      iconName={<RiArrowRightLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={async () => {
                        if (formWizard === 1 && selectedCategory.categoryId) {
                          console.log("editCategory", selectedCategory);
                          if (this.props.editOfferData) {
                            await this.props.onClickSelectOffer({
                              ...this.props.editOfferData,
                              categoryId: selectedCategory?.categoryId,
                              category: selectedCategory,
                            });
                          } else {
                            this.props.setCreateOfferData({
                              selectOffer: selectedOfferData,
                              selectedMerchant: selectMerchant,
                              selectedBank: selectedBank,
                              selectedCategory: selectedCategory,
                              selectLocations: selectLocations,
                              selectTerms: termsConditions,
                            });

                            if (
                              selectedOfferData &&
                              selectedOfferData?.draftId
                            ) {
                              let resDraft = await updateDraft(
                                {
                                  ...selectedOfferData,
                                  categoryId: selectedCategory?.categoryId,
                                },
                                selectedOfferData?.draftId
                              );
                              console.log({ resDraft });
                              this.props.refreshOffers();
                            }
                          }
                          this.props.onClickCloseEditModal();
                        } else {
                          return false;
                        }
                      }}
                      LeftIconText={null}
                      text={"Update"}
                      size={"md"}
                      type={"btn-primary"}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditCategory: state.offerReducer.isEditCategory,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseEditModal: () =>
      dispatch({ type: actionTypes.CLOSE_EDIT_MODAL }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
