import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div className={`cco__gh__wrapper ${props.option}`}>
      <div className={"cco__gh__left-wrapper d-flex"}>
        <div className={"cco__gh__title"}>{props.title}</div>
      </div>

      {props.isRefresh && (
        <>
          <div className={"cco__gh__refresh-btn-wrapper"}>
            <div className={`btn-refresh-btn`} onClick={props.onClickRefresh}>
              Refresh
            </div>
          </div>
        </>
      )}
        {props.isRefresh && (
        <>
          <div className={"cco__gh__refresh-btn-wrapper"}>
            <div className={`btn-refresh-btn`} onClick={props.onClickRefresh}>
              Refresh
            </div>
          </div>
        </>
      )}
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
