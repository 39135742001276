export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

//FUND TRANSFER
export const OPEN_OFFER_CONFIRM_MODAL = "OPEN_OFFER_CONFIRM_MODAL";
export const CLOSE_OFFER_CONFIRM_MODAL = "CLOSE_OFFER_CONFIRM_MODAL";

//FUND TRANSFER
export const OPEN_OFFER_REJECT_MODAL = "OPEN_OFFER_REJECT_MODAL";
export const CLOSE_OFFER_REJECT_MODAL = "CLOSE_OFFER_REJECT_MODAL";

//FUND TRANSFER
export const OPEN_PAYMENT_MODAL = "OPEN_PAYMENT_MODAL";
export const CLOSE_PAYMENT_MODAL = "CLOSE_PAYMENT_MODAL";
export const OPEN_PAYMENT_DETAILS_MODAL = "OPEN_PAYMENT_DETAILS_MODAL";
export const CLOSE_PAYMENT_DETAILS_MODAL = "CLOSE_PAYMENT_DETAILS_MODAL";

//FUND TRANSFER
export const OPEN_IMPRESSION_MODAL = "OPEN_IMPRESSION_MODAL";
export const CLOSE_IMPRESSION_MODAL = "CLOSE_IMPRESSION_MODAL";

//FUND TRANSFER
export const OPEN_TERMS_AND_CONDITIONS_MODAL =
  "OPEN_TERMS_AND_CONDITIONS_MODAL";
export const CLOSE_TERMS_AND_CONDITIONS_MODAL =
  "CLOSE_TERMS_AND_CONDITIONS_MODAL";
export const OPEN_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL =
  "OPEN_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL";
export const CLOSE_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL =
  "CLOSE_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL";
export const ADD_NEW_TERMS_INTO_LIST = "ADD_NEW_TERMS_INTO_LIST";
export const EDIT_TERMS_INTO_LIST = "EDIT_TERMS_INTO_LIST";
export const DELETE_TERMS_INTO_LIST = "DELETE_TERMS_INTO_LIST";
export const CLEAR_ALL_TERMS_INTO_LIST = "CLEAR_ALL_TERMS_INTO_LIST";
export const LOAD_TERMS_AND_CONDITION = "LOAD_TERMS_AND_CONDITION";
export const OPEN_FIND_TERMS_AND_CONDITION = "OPEN_FIND_TERMS_AND_CONDITION";
export const CLOSE_FIND_TERMS_AND_CONDITION = "CLOSE_FIND_TERMS_AND_CONDITION";

//FUND TRANSFER
export const OPEN_OFFER_VIEW_MODAL = "OPEN_OFFER_VIEW_MODAL";
export const OPEN_SYNC_OFFER_VIEW_MODAL = "OPEN_SYNC_OFFER_VIEW_MODAL";
export const CLOSE_OFFER_VIEW_MODAL = "CLOSE_OFFER_VIEW_MODAL";
export const CLOSE_SYNC_OFFER_VIEW_MODAL = "CLOSE_SYNC_OFFER_VIEW_MODAL";
export const CLEAR_OFFER_VIEW_MODAL = "CLEAR_OFFER_VIEW_MODAL";
export const REFRESH_OFFERS_LIST = "REFRESH_OFFERS_LIST";
export const DONE_REFRESH_OFFERS = "DONE_REFRESH_OFFERS";
export const REFRESH_DRAFTS_LIST = "REFRESH_DRAFTS_LIST";
export const DONE_REFRESH_DRAFTS = "DONE_REFRESH_DRAFTS";
export const EDIT_OFFER_BANK = "EDIT_OFFER_BANK";
export const EDIT_OFFER_CATEGORY = "EDIT_OFFER_CATEGORY";
export const EDIT_OFFER_MERCHANT = "EDIT_OFFER_MERCHANT";
export const EDIT_OFFER_CONTACTS = "EDIT_OFFER_CONTACTS";
export const EDIT_OFFER_DESCRIPTION = "EDIT_OFFER_DESCRIPTION";
export const EDIT_OFFER_PROMO_DETAILS = "EDIT_OFFER_PROMO_DETAILS";
export const CLOSE_EDIT_MODAL = "CLOSE_EDIT_MODAL";
export const OPEN_OFFER_CREATE_MODAL = "OPEN_OFFER_CREATE_MODAL";
export const REFRESH_SYNC_OFFERS_LIST = "REFRESH_SYNC_OFFERS_LIST";
export const DONE_REFRESH_SYNC_OFFERS = "DONE_REFRESH_SYNC_OFFERS";

export const SET_USER_DATA = "SET_USER_DATA";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const ADD_LOGIN_USER = "ADD_LOGIN_USER";
export const SET_SELECT_USER_DATA = "SET_SELECT_USER_DATA";
export const CLEAR_SELECT_USER_DATA = "CLEAR_SELECT_USER_DATA";
export const REFRESH_USER_LIST = "REFRESH_USER_LIST";
export const DONE_REFRESH_USERS = "DONE_REFRESH_USERS";
export const LOG_OUT = "LOG_OUT";
export const GET_USER_BY_TYPE_PENDING = "GET_USER_BY_TYPE_PENDING";
export const GET_USER_BY_TYPE = "GET_USER_BY_TYPE";
export const GET_USER_BY_TYPE_FAILURE = "GET_USER_BY_TYPE_FAILURE";
export const GET_PRIVATE_USER_DETAILS_BY_ID_PENDING =
  "GET_PRIVATE_USER_DETAILS_BY_ID_PENDING";
export const GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE =
  "GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE";
export const GET_PRIVATE_USER_DETAILS_BY_ID = "GET_PRIVATE_USER_DETAILS_BY_ID";
export const UPDATE_PRIVATE_USER_PROFILE_IMAGE =
  "UPDATE_PRIVATE_USER_PROFILE_IMAGE";

//CATEGORY
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DONE_CATEGORY_UPDATE = "DONE_CATEGORY_UPDATE";
export const REFRESH_CATEGORY_LIST = "REFRESH_CATEGORY_LIST";
export const DONE_REFRESH_CATEGORY = "DONE_REFRESH_CATEGORY";

//BANK
export const EDIT_BANK = "EDIT_BANK";
export const DONE_BANK_UPDATE = "DONE_BANK_UPDATE";
export const REFRESH_BANK_LIST = "REFRESH_BANK_LIST";
export const EDIT_BANK_CARD_TYPES = "EDIT_BANK_CARD_TYPES";
export const EDIT_BANK_CARD_TYPES_CLOSE = "EDIT_BANK_CARD_TYPES_CLOSE";
export const EDIT_OFFER_BANK_CARD_TYPES = "EDIT_OFFER_BANK_CARD_TYPES";
export const EDIT_OFFER_BANK_CARD_TYPES_CLOSE =
  "EDIT_OFFER_BANK_CARD_TYPES_CLOSE";
export const SET_BANK_DATA = "SET_BANK_DATA";

//MERCHANT
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const REFRESH_MERCHANT_LIST = "REFRESH_MERCHANT_LIST";
export const DONE_MERCHANT_UPDATE = "DONE_MERCHANT_UPDATE";
export const SET_NO_OFFER = "SET_NO_OFFER";
export const EDIT_MERCHANT_LOCATION = "EDIT_MERCHANT_LOCATION";
export const EDIT_MERCHANT_LOCATION_CLOSE = "EDIT_MERCHANT_LOCATION_CLOSE";
export const EDIT_MERCHANT_CONTACTS_CLOSE = "EDIT_MERCHANT_CONTACTS_CLOSE";
export const CLEAR_SELECTED_MERCHANT = "CLEAR_SELECTED_MERCHANT";
export const ON_LOAD_MERCHANT_HAS_OFFERS = "ON_LOAD_MERCHANT_HAS_OFFERS";
export const CLEAR_ON_LOAD_MERCHANT_HAS_OFFERS =
  "CLEAR_ON_LOAD_MERCHANT_HAS_OFFERS";
export const OPEN_VIEW_MERCHANT_OFFERS_MODAL =
  "OPEN_VIEW_MERCHANT_OFFERS_MODAL";
export const CLOSE_VIEW_MERCHANT_OFFERS_MODAL =
  "CLOSE_VIEW_MERCHANT_OFFERS_MODAL";

//MEDIA
export const VIEW_MEDIA_INFO = "VIEW_MEDIA_INFO";
export const VIEW_MEDIA_INFO_CLEAR = "VIEW_MEDIA_INFO_CLEAR";

//OFFER
export const VIEW_OFFER_DETAILS = "VIEW_OFFER_DETAILS";
export const CLEAR_OFFER_DETAILS = "CLEAR_OFFER_DETAILS";
export const CREATE_OFFER_DETAILS = "CREATE_OFFER_DETAILS";
export const UPDATE_SELECTED_BANK = "UPDATE_SELECTED_BANK";
export const UPDATE_SELECTED_LOCATIONS = "UPDATE_SELECTED_LOCATIONS";
export const UPDATE_OFFER_DETAILS_MODAL = "UPDATE_OFFER_DETAILS_MODAL";
export const UPDATE_SYNC_OFFER_DETAILS_MODAL =
  "UPDATE_SYNC_OFFER_DETAILS_MODAL";

export const DELETE_OFFER_TERMS_INTO_LIST = "DELETE_TERMS_INTO_LIST";
export const CLEAR_ALL_OFFER_TERMS_INTO_LIST = "CLEAR_ALL_TERMS_INTO_LIST";

//SEARCH MERCHANT
export const OPEN_SEARCH_MERCHANT_MODAL = "OPEN_SEARCH_MERCHANT_MODAL";
export const CLOSE_SEARCH_MERCHANT_MODAL = "CLOSE_SEARCH_MERCHANT_MODAL";
export const SELECTED_SEARCH_MERCHANT_MODAL = "SELECTED_SEARCH_MERCHANT_MODAL";
export const CLEAR_SEARCH_MERCHANT = "CLEAR_SEARCH_MERCHANT";
export const SELECTED_OFFER = "SELECTED_OFFER";
export const OPEN_CREATE_NEW_MERCHANT = "OPEN_CREATE_NEW_MERCHANT";
export const CLOSE_CREATE_NEW_MERCHANT = "CLOSE_CREATE_NEW_MERCHANT";

export const SELECTED_EXISTING_TERMS = "SELECTED_EXISTING_TERMS";
export const REMOVE__SELECTED_EXISTING_TERMS =
  "REMOVE__SELECTED_EXISTING_TERMS";
export const ADD_EXISING_TERMS_INTO_LIST = "ADD_EXISING_TERMS_INTO_LIST";
export const CLEAR_TERMS_AND_CONDITION = "CLEAR_TERMS_AND_CONDITION";
export const CLEAR_ALL_TERMS_AND_CONDITION = "CLEAR_ALL_TERMS_AND_CONDITION";
export const ON_LOAD_OFFERS_TERMS_AND_CONDITION =
  "ON_LOAD_OFFERS_TERMS_AND_CONDITION";
export const CLEAR_OFFERS_TERMS_AND_CONDITION =
  "CLEAR_OFFERS_TERMS_AND_CONDITION";
export const ADD_TERMS_AND_CONDITION = "ADD_TERMS_AND_CONDITION";

// PROFILE VIEW
export const OPEN_PROFILE_SETTING_MODAL = "OPEN_PROFILE_SETTING_MODAL";
export const CLOSE_PROFILE_SETTING_MODAL = "CLOSE_PROFILE_SETTING_MODAL";
