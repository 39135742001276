import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div className={`cco__details-card__wrapper ${props.option}`}>
      <div className={"cco__details-card__title"}>{props.title}</div>

      {props.type === "text" && props.title != "Offer Description" && (
        <div className={"cco__details-card__desc"}>{props.description}</div>
      )}

      {props.type === "text" && props.title === "Offer Description" && (
        <div
          className={"cco__details-card__desc"}
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        />
      )}

      {props.type === "text" &&
        (props.title === "Mobile no" || props.title === "E-mail") && (
          <div className={"d-flex flex-row"} style={{ marginTop: "-8px" }}>
            {props?.contactList
              ?.filter((x) => x.isActive)
              .map((item, index) => (
                <div
                  className={`cco__details-card__desc mr-2 ${
                    props.contactList.length > 1 ? "mobile-border-left" : ""
                  }`}
                >
                  {item.contact}
                </div>
              ))}
          </div>
        )}

      {props.type === "tag" && (
        <div className={"d-flex flex-wrap mt-1"}>
          {props?.tagList
            ?.filter((x) => x.isActive)
            .map((item, index) => (
              <div className={"cco__details-card__tag mr-1"}>
                {item.value || item?.tagName || item?.cardName}
              </div>
            ))}
        </div>
      )}
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
