import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, WebOfferCard } from "../../../components";
import { Scrollbars } from "react-custom-scrollbars";
import { Spin, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  getBanksOffersCount,
  getCategory,
  getSyncOffersSampath,
  syncSampathOffers,
  syncSampathOffersToSync,
} from "../../../api";
const { TabPane } = Tabs;

class SampathBankOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      isLoadingOffers: false,
      offerData: [],
      tempOffersCount: 0,
      syncOffersCount: 0,
      selectStatus: "new",
      categoryId: null,
      dataForm: {
        category: {
          key: "category",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select category",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: "new",
          tabName: "New",
          content: "",
        },
        {
          id: uuidv4(),
          key: "approved",
          tabName: "Approved",
          content: "",
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getOffersCounts();
    await this.setAllData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async setAllData() {
    let resCategory = await getCategory();

    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["category"].value = "";

    this.setState({
      dataForm: updateForm,
      isLoading: false,
    });
  }

  async syncCategory(res) {
    let categoryList = [];
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
        tags: category.tags,
      });
    }
    return categoryList;
  }

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value =
      inputIdentity === "categoryName" ? event.target.value : event;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async getOffersCounts() {
    let res = await getBanksOffersCount("6636780a73e7209d15fc5184");
    console.log("getOffersCounts ==>", res);
    if (res?.data?.status) {
      this.setState({
        tempOffersCount: res?.data?.data?.tempOffers,
        syncOffersCount: res?.data?.data?.syncOffers,
      });
    }
  }

  async onClickSyncOffersFromBank() {
    let res = await syncSampathOffersToSync();
    if (res?.success) {
      await this.getOffersCounts();
      console.log(res?.message);
    }
  }

  async onClickViewOffers() {
    this.setState({ isLoadingOffers: true });
    try {
      let resOffers = await getSyncOffersSampath();
      if (resOffers?.data?.status) {
        console.log("getSyncOffersSampath ==>", resOffers?.data);
        this.setState({
          offerData: resOffers && resOffers.data && resOffers?.data?.data,
          isLoadingOffers: false,
        });
        this.props.doneRefresh();
      } else {
        this.setState({ isLoadingOffers: false });
      }
    } catch (error) {
      console.error("Error fetching data", error);
      this.setState({ isLoadingOffers: false });
    }
  }

  onClickSyncOffers = async () => {
    this.setState({ isLoading: true });
    let res = await syncSampathOffers();
    console.log({ res });
    if (res?.success) {
      console.log(res?.message);
      await this.getOffersCounts();
      // // let resMerchant = await getTemporaryOffersSampath();
      // // console.log({ resMerchant });
      // // if (resMerchant?.data?.status) {
      // //   console.log(resMerchant?.data);
      //   this.setState({
      //     newMerchantList: resMerchant?.data?.data,
      //     isLoadingMerchant: false,
      //   });
      // }

      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleChange = (tabName) => {
    this.setState({
      selectStatus: tabName,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    console.log("Prev Props:", prevProps.refreshSyncOffers);
    console.log("Current Props:", this.props.refreshSyncOffers);
    if (prevProps.refreshSyncOffers !== this.props.refreshSyncOffers) {
      console.log("refreshSync::", this.props.refreshSyncOffers);
      if (this.props.refreshSyncOffers) {
        await this.onClickViewOffers();
      }
    }
  }

  render() {
    const {
      viewPointHeight,
      offerData,
      isLoading,
      tabJson,
      isLoadingOffers,
      selectStatus,
      dataForm,
      categoryId,
    } = this.state;
    const { editOfferData } = this.props;

    return (
      <>
        <div className="d-flex flex-row justify-content-between">
          <div
            className={"sync-offer-view-wrapper d-flex gap-2"}
            style={{ width: "100%" }}
          >
            <div className="d-flex">
              <Button
                text={
                  "Sync Temp to  Sync offers (" +
                  this.state.tempOffersCount +
                  ")"
                }
                type={"btn-edit  md"}
                size={"lg secondary"}
                isLoading={isLoading}
                onClick={async () => {
                  await this.onClickSyncOffersFromBank();
                }}
              />
            </div>

            <div className="d-flex">
              <Button
                text={"View offers (" + this.state.syncOffersCount + ")"}
                type={"btn-edit  md"}
                size={"lg secondary"}
                isLoading={isLoadingOffers}
                onClick={async () => {
                  await this.onClickViewOffers();
                }}
              />
            </div>

            <Input
              elementConfig={dataForm.category.elementConfig}
              elementType={dataForm.category.elementType}
              required={dataForm.category.validation.required}
              isValid={!dataForm.category.valid}
              touched={dataForm.category.touched}
              value={dataForm.category.value}
              defaultValue={null}
              label={dataForm.category.label}
              isShowRequired={false}
              size={"lg"}
              margin={""}
              width={"10%"}
              options={[]}
              changed={async (event) => {
                this.inputHandleChange(event, dataForm.category.key);
                let value = event;

                let categoryId = dataForm.category.elementConfig.options.find(
                  (cat) => cat.value === value
                )?.id;

                console.log({ categoryId });
                await this.setState({
                  categoryId: categoryId,
                });
              }}
            />
          </div>
          {offerData?.length > 0 && (
            <div className="d-flex">
              <Button
                text={"Sync offers"}
                type={"btn-edit"}
                size={"lg secondary"}
                isLoading={this.state.isLoading}
                onClick={async () => await this.onClickSyncOffers()}
              />
            </div>
          )}
        </div>
        <div className={"sync-offers-view-wrapper"}>
          {!isLoadingOffers && offerData && offerData.length === 0 && (
            <div className={"offer-not-found-wrapper"}>
              <h2>Offer not found</h2>

              <Button
                text={"Sync offers"}
                type={"btn-edit  md"}
                // disabled={dataForm.categoryName.value ? false : true}
                size={"lg secondary"}
                isLoading={this.state.isLoading}
                onClick={async () => await this.onClickSyncOffers()}
              />
            </div>
          )}

          {offerData && offerData.length > 0 && (
            <>
              <Tabs onChange={this.handleChange}>
                {((tabJson && tabJson) || []).map((i, t) => (
                  <TabPane tab={i.tabName} key={i.key}></TabPane>
                ))}
              </Tabs>
              <Scrollbars
                style={{ height: viewPointHeight - 290 }}
                thumbMinSize={30}
                universal={true}
                autoHide
              >
                <div className={"container mt-3"}>
                  {(offerData || [])
                    .filter((item) => {
                      if (selectStatus === "" && categoryId === "") {
                        return item;
                      } else if (categoryId && item.categoryId === categoryId) {
                        if (
                          item.status?.toLowerCase() ===
                          selectStatus.toLowerCase()
                        ) {
                          return item;
                        }
                      } else if (
                        !categoryId &&
                        item.status?.toLowerCase() ===
                          selectStatus.toLowerCase()
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <>
                        <WebOfferCard
                          key={index}
                          id={`${item.offerId}`}
                          animate={"animate__fadeIn"}
                          isFavorites={false}
                          isLoading={isLoading}
                          onClick={async () => {
                            this.props.onClickOpenOfferView(item);
                          }}
                          categoryData={item?.category}
                          bankData={item?.bank}
                          data={item}
                        />
                      </>
                    ))}
                </div>
              </Scrollbars>
            </>
          )}
          {(offerData?.length === 0 && this.state.isLoading) ||
            (isLoadingOffers && (
              <div style={{ margin: "0 50%", opacity: 0.5 }}>
                <Spin size="large" spinning={isLoading || isLoadingOffers} />
              </div>
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshSyncOffers: state.offerReducer.refreshSyncOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),

    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_SYNC_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampathBankOffers);
