import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import { Table, Space } from "antd";
import { getAdminUser } from "../../../api";
import { getAllSubscribers } from "../../../api/subsribe";

class subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.fetchSubscribers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async fetchSubscribers() {
    this.setState({ isLoading: true });
    let res = await getAllSubscribers();
    this.setState({
      dataSource: res && res.data,
      isLoading: false,
    });
  }

  render() {
    const { dataSource, tags, isLoading } = this.state;
    const columns = [
      {
        title: "Insight Id",
        dataIndex: "insightId",
        key: "insightId",
      },
      {
        title: "Email",
        dataIndex: "subscribeEmail",
        key: "email",
      },
    ];
    return (
      <>
        <div className={"cco__sbw-table"}>
          <Table
            size={"small"}
            loading={isLoading}
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(subscribe);
