import axios from "axios";
import * as mockResponses from "../mock_data/apiResponses";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const saveDraft = async (body) => {
  try {
    console.log({ body });
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // const body = {
    //   userId: userId,
    //   categoryId: categoryId,
    //   merchantId: merchantId,
    //   bankId: bankId,
    //   label: label,
    //   header: header,
    //   offerDateType: offerDateType,
    //   offerStart: offerStart,
    //   offerEnd: offerEnd,
    //   customizeDate: customizeDate,
    //   validDescription: "",
    //   cardTypes: cardTypes,
    //   offerType: offerType,
    //   offerRate: offerRate,
    //   location: validLocation,
    //   hotLine: hotLine,
    //   description: description,
    //   termsConditions: termsConditions,
    //   bannerImageURL: bannerImageURL,
    //   referenceURL: referenceURL,
    //   promoCode: promoCode,
    //   gallery: [],
    //   validDate: validDate,
    //   startTime: startTime,
    //   endTime: endTime,
    //   validTimeDateDescription: validTimeDateDescription,
    // };

    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/draft`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_GET_SAVED_OFFER_API:", err);
    throw err;
  }
};

export const getAllDrafts = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/drafts`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getDraftById = async (offerId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/draft/by/${offerId}`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const updateDraft = async (body, draftId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/draft/details/${draftId}`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};

export const deleteDraft = async (draftId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.put(`${BASE_URL}/draft/delete/${draftId}`, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_UPDATE_OFFER_API:", err);
    throw err;
  }
};
