import React from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { Button } from "../..";

const EmptyData = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="container-fluid">
        <div className={`empty-data-wrapper `}>
          {props.imgUrl && (
            <div className={"edw__img-wrapper"}>
              <img src={props.imgUrl} alt="empty data" />
            </div>
          )}
          <div className="d-flex flex-column justify-content-center align-content-center align-items-center">
            <h2 className="edw__title">{props.title}</h2>
            <p className="edw__subtitle">{props.description}</p>
          </div>
        </div>
        <div className="d-flex flex-row edw__button mt-3">
          {props.isButton && (
            <Button
              btnName="Add New Guest"
              buttonType="primary-blue"
              buttonSize="sm"
              iconNameLeft="plus"
              // isLoading: isLoadingBtn,
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default EmptyData;
