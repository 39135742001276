import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isOpenSearchMerchant: false,
  isOpenCreateNewMerchant: false,
  selectMerchantData: [],
  selectMerchant: [],
  validLocation: [],
  refreshMerchant: false,
  createNew: false,
  isOpenViewMerchantOffers: false,
  merchantOffers: [],
  createdMerchant: null,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("Merchant_Reducers", payload);
  console.log("Merchant_Reducers_type", type);
  switch (type) {
    case actionTypes.OPEN_SEARCH_MERCHANT_MODAL:
      draft.createNew = true;
      draft.isOpenSearchMerchant = true;
      draft.createdMerchant = null;
      return draft;
    case actionTypes.CLOSE_SEARCH_MERCHANT_MODAL:
      draft.isOpenSearchMerchant = false;
      draft.createNew = false;
      draft.createdMerchant = null;
      return draft;
    case actionTypes.SELECTED_SEARCH_MERCHANT_MODAL:
      draft.selectMerchant = payload;
      return draft;
    case actionTypes.CLEAR_SEARCH_MERCHANT:
      draft.selectMerchant = [];
      return draft;
    case actionTypes.EDIT_MERCHANT:
      draft.selectMerchantData = payload;
      return draft;
    case actionTypes.REFRESH_MERCHANT_LIST:
      draft.refreshMerchant = true;
      return draft;
    case actionTypes.DONE_MERCHANT_UPDATE:
      draft.selectMerchantData = [];
      draft.refreshMerchant = false;
      return draft;
    case actionTypes.CLEAR_SELECTED_MERCHANT:
      draft.selectMerchantData = [];
      //draft.selectMerchant = [];
      return draft;
    case actionTypes.OPEN_VIEW_MERCHANT_OFFERS_MODAL:
      draft.isOpenViewMerchantOffers = true;
      draft.selectMerchantData = payload;
      return draft;
    case actionTypes.CLOSE_VIEW_MERCHANT_OFFERS_MODAL:
      draft.isOpenViewMerchantOffers = false;
      return draft;
    case actionTypes.ON_LOAD_MERCHANT_HAS_OFFERS:
      console.log("ON_LOAD_MERCHANT_HAS_OFFERS", payload);
      draft.merchantOffers = payload;
      return draft;
    case actionTypes.CLEAR_ON_LOAD_MERCHANT_HAS_OFFERS:
      draft.merchantOffers = [];
      return draft;
    case actionTypes.OPEN_CREATE_NEW_MERCHANT:
      console.log("merchant===>", actionTypes.OPEN_CREATE_NEW_MERCHANT);
      draft.isOpenCreateNewMerchant = true;
      draft.selectMerchant = [];
      return draft;
    case actionTypes.CLOSE_CREATE_NEW_MERCHANT:
      console.log("merchant===>", actionTypes.CLOSE_CREATE_NEW_MERCHANT);
      draft.isOpenCreateNewMerchant = false;
      draft.createdMerchant = payload;
      return draft;
    default:
      return draft;
  }
});
