import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import moment from "moment";
import { Button, ButtonIcon, InfoRowView, Tag } from "../..";
import { GetIcon } from "../../../config/icon";
import { Popconfirm, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteOffer, deleteOfferTerms } from "../../../api";
import { OpenNotification } from "../../../config/notification";
import * as actionTypes from "../../../store/actions/types";
import { deleteDraft } from "../../../api/draft";
import { RiCheckLine } from "react-icons/ri";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { bankData } = useSelector(({ bankReducer }) => bankReducer);
  const dispatch = useDispatch();

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const onClickDeleteTerms = async (offerId) => {
    if (selectedBankId) {
      let res = await deleteOfferTerms(offerId, selectedBankId);

      if (res?.data?.status) {
        OpenNotification(res?.data?.message);

        dispatch({
          type: actionTypes.REFRESH_MERCHANT_LIST,
        });
      }
    }
  };

  const correctTimeZone = (createAtUTC) => {
    // Convert the UTC time to Sri Lankan time (UTC+05:30)
    const createAtSL = moment(createAtUTC).utcOffset("+05:30");

    // Display time from now (e.g., "3 days ago")
    const timeFromNow = createAtSL.fromNow();

    return timeFromNow;
  };

  const { TabPane } = Tabs;

  return (
    <div
      className={`${isExpand ? "offer-expanded" : ""}`}
      onClick={props.onClick}
    >
      <div
        className={`cco__bulk-offer-card__wrapper d-flex flex-row align-items-center ${
          props.isActive ? "active" : ""
        } ${
          props.type === "expire" ? (props.expireDays < 0 ? "expired" : "") : ""
        }`}
      >
        <div className="d-flex flex-row align-items-center gap-1">
          {props.type === "bulk" && (
            <div
              className={`cco__radio-check-box ${
                props.isActive ? "active" : ""
              }`}
              onClick={props.onClick}
            >
              {props.isActive && (
                <>
                  <RiCheckLine />
                </>
              )}
            </div>
          )}
          <div className="d-flex flex-row">
            <div className={"img-wrapper"}>
              <img src={props.imgUrl} alt="creditcard offers" />
            </div>
            <div className={"cco__bocw__logo"}>
              <img src={props.logo} alt="creditcard offers" />
            </div>
          </div>
        </div>
        <div
          className={
            "cco__bocw-body d-flex flex-row justify-content-between align-items-center"
          }
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column"}>
            <div className={"cco__bocw__details"}>{props.details}</div>
            <div className={"cco__bocw__name"}>{props.name}</div>
            <div className={"cco__bocw__tags d-flex flex-row"}>
              <Tag
                type={
                  props.status === "new"
                    ? "new"
                    : props.status === "Accepted" || props.status === "accepted"
                    ? "accept"
                    : props.status === "Rejected" || props.status === "rejected"
                    ? "reject"
                    : "new"
                }
                tagName={props.status}
              />
              <Tag type={"username"} tagName={props.userName} />
              <Tag type={"companyName"} tagName={props.bankName} />
              <Tag type={"companyName"} tagName={props.categoryName} />
            </div>
            {props.type === "expire" && (
              <div className={"cco__bocw__time"}>
                {correctTimeZone(props.createAt)} |
                {/* {" "}
                <span className={"cco__ocwt-endDate"}>
                  End Date {props.offerEndDate}
                </span>
                | */}
                {props.expireDays < 0 ? " expired " : " expires "}
                {correctTimeZone(props.offerEndDate)}
              </div>
            )}
            {props.type != "expire" && (
              <div className={"cco__bocw__time"}>
                {correctTimeZone(props.createAt)} |{" "}
                <span className={"cco__bocw-endDate"}>
                  End Date {props.offerEndDate}
                </span>
              </div>
            )}
            {props.offerId && (
              <div
                className="cco__offer-url"
                onClick={() => {
                  props.offerId &&
                    window.open(
                      "https://creditcardoffers.lk/offer-view/" +
                        props.offerId +
                        "/" +
                        props.bankName +
                        "/" +
                        props.categoryName,
                      "_blank"
                    );
                }}
              >
                Preview
              </div>
            )}
          </div>
          <div>
            <ButtonIcon
              onClick={props.onClickPreview}
              buttonIcon={GetIcon("chevronRight")}
              type={"collapse-icon"}
              size={"lg"}
            />
          </div>
        </div>
      </div>
      <>
        {isExpand && (
          <div className="expanded-content">
            <div className="coo__merchant-dc_delete-btn">
              <Popconfirm
                title={
                  "Remove this " + getBankName(selectedBankId) + "'s terms?"
                }
                description={
                  "Are you sure to remove this " +
                  getBankName(selectedBankId) +
                  "'s terms?"
                }
                placement="topRight"
                onConfirm={async () => {
                  if (props.offerId) {
                    await onClickDeleteTerms(props.offerId);
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  text={
                    selectedBankId
                      ? "Delete (" + getBankName(selectedBankId) + ")"
                      : "Delete"
                  }
                  type={"btn-edit mt-2"}
                  isLoading={false}
                  onClick={() => {}}
                  size={"sm primary"}
                />
              </Popconfirm>
            </div>
            {/* Content to show when expanded */}
            <div className={"list-view-terms_condition mt-3"}>
              {isArray(props.termsAndCondition) ? (
                <>
                  {props.termsAndCondition &&
                    (props.termsAndCondition || [])?.map((item, index) => (
                      // <InfoRowView
                      //   type="default"
                      //   text={item.value}
                      //   isSelect={item.isActive}
                      //   style={
                      //     "mb-1 justify-content-between align-items-center"
                      //   }
                      // />
                      <InfoRowView
                        type={"terms"}
                        text={item.value}
                        isSelect={item.isActive}
                        style={
                          "mb-1 justify-content-between align-items-center"
                        }
                        onClickConfirm={item.onClickDelete}
                        onClick={item.onClick}
                        onClickEdit={item.onClickEdit}
                      />
                    ))}
                </>
              ) : (
                <div>
                  <div className={"cco__mtc-tab mt-2"}>
                    <Tabs
                      defaultActiveKey="1"
                      onChange={(bankId) => {
                        setSelectedBankId(bankId);
                      }}
                    >
                      {Object.keys(props?.termsAndCondition).map((bankId) => (
                        <TabPane tab={`${getBankName(bankId)}`} key={bankId}>
                          <div className={`cco__terms-tab-wrapper `}>
                            <div
                              className={"cco__terms_selection-wrapper"}
                            ></div>
                            {props?.termsAndCondition[bankId].map((term) => (
                              // <InfoRowView
                              //   key={term.id}
                              //   type="default"
                              //   text={term.value}
                              //   isSelect={term.isActive}
                              //   style={
                              //     "mb-1 justify-content-between align-items-center"
                              //   }
                              // />
                              <InfoRowView
                                key={term.id}
                                type={"terms"}
                                text={term.value}
                                isSelect={term.isActive}
                                style={
                                  "mb-1 justify-content-between align-items-center"
                                }
                                onClickConfirm={term.onClickDelete}
                                onClick={term.onClick}
                                onClickEdit={term.onClickEdit}
                              />
                            ))}
                          </div>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
