import React, { useEffect, useState } from "react";
import "./styles.scss";
import { GetIcon } from "../../../../config/icon";
import { getUserById } from "../../../../api";
import { useDispatch } from "react-redux";
import { userDetails } from "../../../../utils";
import * as actionTypes from "../../../../store/actions/types";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import { FilterColor_3, Greeting, MenuCard } from "../../../../components";
import { MdOutlineTimerOff } from "react-icons/md";
import { TbSeo } from "react-icons/tb";

const Index = (props) => {
  const [state, setState] = useState({ userObject: {} });
  const [viewPointWidth, setViewPointWidth] = useState(0);
  const [viewPointHeight, setViewPointHeight] = useState(0);
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // scrollbars start
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("scroll", handleOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setViewPointWidth(window.innerWidth);
    setViewPointHeight(window.innerHeight);
  };

  const handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  useEffect(() => {
    async function fetchMyAPI() {
      //let response =  getToken();
      let response = await userDetails();
      console.log("response", response);

      let resUser = await getUserById(response?.userId);
      console.log("getUserById::", resUser);
      setState({ userObject: resUser?.data });
      props.setUserDetails(resUser?.data);
    }

    fetchMyAPI();
    console.log("getToken");
    return () => {};
  }, []);

  return (
    <>
      <div
        className={`cco__left-menu-container ${props.option} ${
          props.isHide ? "hide" : ""
        }`}
      >
        <div
          className={`cco__logo-wrapper  ${props.isHide ? "hide" : ""} `}
          onClick={props.onClick}
        >
          <img
            className={"cco__logo-svg"}
            width={"50"}
            // onClick={() => props.onClickGoPage("dashboard")}
            src={require("../../../../assets/img/logo/logo_lk 1.svg")}
            alt="credit card offers"
          />
        </div>

        {/*---- profile details goes here ------*/}
        {/* <div className={"profile-logo-wrapper"}>
          <div className={"profile-avatar-wrapper"}>
            <img src={state.userObject.profileAvatar} alt={"user-profile"} />
          </div>
          <div className={"profile-name"}>Hi, {state.userObject.firstName}</div>
          <div className={"user-type"}>{state.userObject.userType}</div>
          <div
            className={"user-logout"}
            onClick={async (e) => {
              let userState = await userLogOut(e);
              window.location.replace("/");
              if (userState) {
                // dispatch(clearUserDetails());
                window.location.reload();
              }
            }}
          >
            <RiLogoutCircleRLine />
            <>Logout</>
          </div>
        </div> */}

        <Scrollbars
          onScroll={handleOnScroll}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
          style={{
            height: viewPointHeight - 250,
          }}
        >
          <div className="cco_mw-title">MENU</div>
          <div className={`cco_menu-wrapper ${props.isHide ? "hide" : ""} `}>
            <MenuCard
              icon={GetIcon("dashboardPie")}
              onClick={() => props.onClickGoPage("dashboard")}
              isActive={props.pageName === "/"}
              size={"md"}
              isHide={props.isHide}
              text={"Dashboard"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("bank")}
              isActive={props.pageName === "/bank"}
              icon={GetIcon("bankHouse")}
              size={"md"}
              isHide={props.isHide}
              text={"Bank"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("category")}
              isActive={props.pageName === "/category"}
              icon={GetIcon("categoryBox")}
              size={"md"}
              isHide={props.isHide}
              text={"Category"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("merchant")}
              isActive={props.pageName === "/merchant"}
              icon={GetIcon("merchant")}
              size={"md"}
              isHide={props.isHide}
              text={"Merchant"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("offers")}
              isActive={props.pageName === "/offers"}
              icon={GetIcon("offer")}
              size={"md"}
              isHide={props.isHide}
              text={"Offers"}
            />
            {state?.userObject?.userType != "user" && (
              <>
                <MenuCard
                  onClick={() => props.onClickGoPage("expired-offers")}
                  isActive={props.pageName === "/expired-offers"}
                  icon={<MdOutlineTimerOff />}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Expired Offers"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("bulk-action")}
                  isActive={props.pageName === "/bulk-action"}
                  icon={GetIcon("bulk")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Bulk Action"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("users")}
                  isActive={props.pageName === "/users"}
                  icon={GetIcon("usersFace")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Users"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("privilege")}
                  isActive={props.pageName === "/privilege"}
                  icon={GetIcon("privilege")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Users Privilege"}
                />
              </>
            )}
            <MenuCard
              onClick={() => props.onClickGoPage("media")}
              isActive={props.pageName === "/media"}
              icon={GetIcon("media")}
              size={"md"}
              isHide={props.isHide}
              text={"Media"}
            />
            {state?.userObject?.userType != "user" && (
              <>
                <MenuCard
                  onClick={() => props.onClickGoPage("advertisement")}
                  isActive={props.pageName === "/advertisement"}
                  icon={GetIcon("advertisement")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Advertisement"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("advertisement-test")}
                  isActive={props.pageName === "/advertisement-test"}
                  icon={GetIcon("advertisement")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Advertisement Test"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("subscriptions")}
                  isActive={props.pageName === "/subscriptions"}
                  icon={GetIcon("thumbsUp")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Subscriptions"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("customer")}
                  isActive={props.pageName === "/customer"}
                  icon={GetIcon("customer")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Customer"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("offer-planner")}
                  isActive={props.pageName === "/offer-planner"}
                  icon={GetIcon("offer")}
                  size={"md"}
                  text={"Offer Planner"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("insights")}
                  isActive={props.pageName === "/insights"}
                  icon={GetIcon("envelope")}
                  size={"md"}
                  isHide={props.isHide}
                  text={"Insights"}
                />
                <MenuCard
                  onClick={() => props.onClickGoPage("seo-mapping")}
                  isActive={props.pageName === "/seo-mapping"}
                  icon={<TbSeo />}
                  size={"md"}
                  text={"SEO mapping"}
                />
              </>
            )}
          </div>
        </Scrollbars>
        <FilterColor_3 />
        <div className="cco_greeting-wrapper">
          <Greeting
            imgUrl={state.userObject.profileAvatar}
            icon={GetIcon("greetingDots")}
            name={"Hi," + state.userObject.firstName}
            onClickProfile={() =>
              props.onClickOpenProfileSetting(state.userObject)
            }
            type={state.userObject.userType}
            isHide={props.isHide}
            onClick={props.onClickLogOut}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isViewOffer: state.offerReducer.isViewOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (payload) =>
      dispatch({
        type: actionTypes.SET_USER_DATA,
        payload: payload,
      }),
    onClickOpenProfileSetting: (payload) => {
      console.log({ payload });
      dispatch({
        type: actionTypes.OPEN_PROFILE_SETTING_MODAL,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
