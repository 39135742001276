import React, {useState} from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import moment from "moment";
import {Button, ButtonIcon, InfoRowView, SelectBox, Tag} from "../..";
import * as actionTypes from "../../../store/actions/types";
import {connect, useDispatch, useSelector} from "react-redux";
import {GetIcon} from "../../../config/icon";
import {FiCopy} from "react-icons/fi";
import {Tabs} from "antd";
import {OpenNotification} from "../../../config/notification";
import {cooGetMerchantOfferCount} from "../../../containers/merchant/service/merchant.service";

const Index = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [offers, setOffers] = useState(null);
  const [noOfOffers, setNoOfOffers] = useState(0);
  const [isLoadingNoOfOffer, setIsLoadingNoOfOffer] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const {bankData} = useSelector(({bankReducer}) => bankReducer);
  const {isSelectedExistingTerms} = useSelector(
      ({termsReducer}) => termsReducer
  );

  const dispatch = useDispatch();

  const handleToggleExpand = async () => {
    setIsExpand(!isExpand);
    setIsLoadingNoOfOffer(true);
    let res = await cooGetMerchantOfferCount(props?.merchantId);
    setSelectedBankId(Number(res?.data?.data[0]?.totalCount));
    setIsLoadingNoOfOffer(true);
  };

  const confirm = (e) => {
    if (props.numOfOfferByMerchant > 0) {
      OpenNotification(
        "Unable to Delete Offer",
        "It seems there's an issue with deleting the offer.",
        "success"
      );
    } else {
      // delete
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  const handleBankChange = (bankId) => {
    console.log("handleBankChange", props?.termsAndCondition[bankId]);
    if (!selectedBankId) {
      setSelectedBankId(bankId);
      dispatch({
        type: actionTypes.SELECTED_EXISTING_TERMS,
        payload: { terms: props?.termsAndCondition[bankId], bankId: bankId },
      });
    } else {
      setSelectedBankId(null);
      dispatch({
        type: actionTypes.REMOVE__SELECTED_EXISTING_TERMS,
        payload: null,
      });
    }
  };

  const getBankName = (bankId) => {
    const bank = bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  const { TabPane } = Tabs;

  // const onLoadMerchantOffers = async () => {
  //   let resOfferByMerchant = await cooGetOfferByMerchant(props?.merchantId);
  //   if (resOfferByMerchant?.data?.data) {
  //     setOffers(resOfferByMerchant?.data?.data);
  //   }
  // };

  return (
    <>
      <div
        className={`cco__merchant-card-small__wrapper d-flex flex-row align-items-center ${
          props.isActive ? "isActive" : ""
        }`}
      >
        {props.editable === true ? (
          <div className={"cco__mcsw__logo sm"}>
            <img src={props.logo} alt="creditcard offers" />
          </div>
        ) : (
          <div className={"cco__mcsw__logo lg"}>
            <img src={props.logo} alt="creditcard offers" />
          </div>
        )}

        <div
          className={"d-flex"}
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column justify-content-center"}>
            <div className={"cco__mcsw__name"}>{props.name}</div>
            {!props.editable && (
              <div
                className={"cco__mcsw__id d-flex flex-row align-items-center"}
              >
                MerchantId :{props.merchantId}
                <div className={"cco__mcsw__copy"}>
                  <FiCopy />
                </div>
              </div>
            )}
            <div className={"d-flex flex-row align-items-center"}>
              {props.createdAt && (
                <div className={"cco__mcsw__time"}>
                  {moment(props && props.createdAt)
                    .startOf("day")
                    .fromNow()}
                </div>
              )}

              <div
                className={"d-flex flex-row align-items-center"}
                style={{ gap: "8px" }}
              >
                {props.status && (
                  <div className={"cco__mcsw__tags d-flex flex-row"}>
                    <Tag type={"pending"} tagName={props.status} />
                  </div>
                )}
                {props.offers && (
                  <div className={"cco__mcsw__tags d-flex flex-row"}>
                    <Tag
                      type={"username"}
                      tagName={"Active Offers" + "(" + props.offers + ")"}
                    />
                  </div>
                )}
                {props.category && (
                  <div className={"cco__mcsw__tags d-flex flex-row"}>
                    <Tag type={"companyName"} tagName={props.category} />
                  </div>
                )}
                {props.location && (
                  <div className={"cco__mcsw__tags d-flex flex-wrap"}>
                    {(props?.location || []).map((item, manual) => (
                      <Tag type={"location"} tagName={item.tagName} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {props.editable && (
          <div>
            <Button
              text={"Edit"}
              onClick={props.onClickEdit}
              type={"btn-edit"}
            />
          </div>
        )}
        {!props.preview && (
          <div
            className={
              "d-flex flex-row justify-content-between align-items-center"
            }
          >
            {props.cardType === "merchant" && (
              <ButtonIcon
                onClick={handleToggleExpand}
                buttonIcon={GetIcon(isExpand ? "arrowUp" : "arrowDown")}
                type={"collapse-icon"}
                size={"md"}
              />
            )}
            <div className={"d-flex flex-column justify-content-center ml-2"}>
              <ButtonIcon
                onClick={props.onSelectedMerchant}
                buttonIcon={GetIcon("chevronRight")}
                type={"collapse-icon"}
                size={"md"}
              />
            </div>
          </div>
        )}
        {props.isEdit && (
          <div>
            <Button
              text={"edit"}
              type={"btn-edit"}
              size={"sm secondary"}
              onClick={props.onClickEditMerchant}
            />
          </div>
        )}
      </div>
      {isExpand && (
        <div className="expanded-content">
          <div>No.Of.offers : {noOfOffers}</div>
          {/* Content to show when expanded */}
          <div className={"list-view-terms_condition mt-3"}>
            {isArray(props?.termsAndCondition) ? (
              <>
                {props?.termsAndCondition &&
                    (props?.termsAndCondition || []).map((item, index) => (
                        <InfoRowView
                            type="default"
                            text={item.value}
                            isSelect={item.isActive}
                            style={"mb-1 justify-content-between align-items-center"}
                        />
                    ))}
              </>
            ) : (
              <div>
                <div className={"cco__mtc-tab mt-2"}>
                  {props?.termsAndCondition && props?.termsAndCondition?.length > 0 &&
                      <Tabs defaultActiveKey="1">
                        {Object.keys(props?.termsAndCondition).map((bankId) => (
                            <TabPane tab={`${getBankName(bankId)}`} key={bankId}>
                              <div
                                  className={`cco__terms-tab-wrapper ${
                                      selectedBankId === bankId ? "selected-terms" : null
                                  }`}
                              >
                                <div className={"cco__terms_selection-wrapper"}>
                                  <SelectBox
                                      index={bankId}
                                      remove={false}
                                      isSelect={selectedBankId === bankId}
                                      select={true}
                                      onClick={() => handleBankChange(bankId)}
                                      // onRemove={() =>
                                      //     //this.onSelectLocation(item, index)
                                      // }
                                      format={"text"}
                                      text={getBankName(bankId)}
                                  />
                                </div>
                                {props?.termsAndCondition[bankId].map((term) => (
                                    <InfoRowView
                                        key={term.id}
                                        type="default"
                                        text={term.value}
                                        isSelect={term.isActive}
                                        style={
                                          "mb-1 justify-content-between align-items-center"
                                        }
                                    />
                                ))}
                              </div>
                            </TabPane>
                        ))}
                      </Tabs>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

const mapStateToProps = (state) => {
  return {
    isOpenSearchMerchant: state.merchantReducer.isOpenSearchMerchant,
    selectMerchant: state.merchantReducer.selectMerchant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedOffer: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
