import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, SelectBox } from "../../../components";
import { GetIcon } from "../../../config/icon";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import {
  updateHotLine,
  updateOfferStatus,
  updateSyncOffer,
} from "../../../api";
import { updateDraft } from "../../../api/draft";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      validLocation: [],
      tag: null,
      merchantData: null,
      mobileList: [],
      emailList: [],
      dataForm: {
        mobileNo: {
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Mobile No",
            maxLength: 15,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          key: "mobileNo",
          label: "Mobile No",
        },
        email: {
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "Email",
          },
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: false,
          key: "email",
          label: "Email",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    if (this.props.isEditContacts) {
      this.initializeData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  initializeData() {
    this.setState({
      mobileList: [],
      emailList: [],
    });
    const selectedMerchant = this.props.editMerchant;
    console.log({ selectedMerchant });

    if (selectedMerchant?.merchantId && selectedMerchant?.hotLine) {
      this.setState({
        mobileList: selectedMerchant?.hotLine?.mobileNo,
        emailList: selectedMerchant?.hotLine?.email,
      });
    } else {
      this.setState({
        mobileList: [],
        emailList: [],
      });
    }
  }

  onClickReject = async () => {
    let selectOffer = this.props.selectedOffer;
    if (selectOffer && selectOffer.offerId) {
      let { dataForm } = this.state;

      this.setState({ isLoading: true });

      let body = {
        // reason: dataForm.rejectReason.value,
        // comment: dataForm.comment.value,
        status: "rejected",
      };

      let res = await updateOfferStatus(body, selectOffer.offerId);

      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        this.props.refreshOffers();
        this.props.closeEditModal();
        this.props.closeOfferViewModal();
      }
    } else {
      return false;
    }
  };

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateMerchantContacts = async () => {
    this.setState({ isLoading: true });
    const body = {
      mobileNo: this.state.mobileList,
      email: this.state.emailList,
    };

    let merchantId =
      this.props.selectMerchant?.merchantId ||
      this.props.editMerchant?.merchantId;
    try {
      let res = await updateHotLine(merchantId, body);
      console.log("Merchant::", res);
      if (res?.status) {
        let merchant = res.data;
        if (this.props.selectOffer?.userId === "auto") {
          let offerBody = {
            ...this.props.selectOffer,
            hotLine: merchant.hotLine,
          };

          let resOffer = await updateSyncOffer(
            offerBody,
            this.props.selectOffer?.offerId
          );
          this.props.closeOfferViewModal();
          console.log(resOffer);
          this.props.onClickOpenOfferView(resOffer?.data?.data);
          this.props.refreshSyncOffers();
        } else {
          this.props.onClickSelectedMerchant(merchant);
          // this.props.onClickSelectedMerchantLocations({
          //   selectOffer: this.props.selectedOfferData || this.props.editOfferData,
          //   selectedMerchant: merchant,
          //   selectLocations: this.props.selectLocations,
          // });
          if (this.props.editOfferData) {
            this.props.onClickSelectOffer(this.props.editOfferData);
          } else {
            this.props.setCreateOfferData({
              selectOffer: this.props.selectedOfferData,
              selectedMerchant: merchant,
              selectedBank: this.props.selectedBank,
              selectedCategory: this.props.selectedCategory,
              selectLocations: this.props.selectLocations,
              selectTerms: this.props.selectTerms,
            });

            if (
              this.props.selectedOfferData &&
              this.props.selectedOfferData?.draftId
            ) {
              let resDraft = await updateDraft(
                {
                  ...this.props.selectedOfferData,
                  hotLine: merchant.hotLine,
                },
                this.props.selectedOfferData?.draftId
              );
              console.log({ resDraft });
              this.props.refreshOffers();
            }
            // this.props.onClickSelectOffer({
            //   ...this.props.selectedOfferData,
            //   location: merchant.location,
            // });
          }
        }
        this.setState({ isLoading: false });
        this.props.closeEditModal();
      } else {
        this.setState({ isLoading: false });
        console.log("Failed to update merchant terms");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log("Failed to update merchant terms");
    }
  };

  addToMobileList = () => {
    const mobileNo = this.state.dataForm.mobileNo.value;
    if (mobileNo) {
      if (!this.state.mobileList.some((item) => item.contact === mobileNo)) {
        const mobileEntry = {
          id: uuid(),
          type: "mobileNo",
          icon: "mobileNo",
          contact: mobileNo,
          isActive: true,
        };
        this.setState({ mobileList: [...this.state.mobileList, mobileEntry] });
        const updatedForm = { ...this.state.dataForm };
        updatedForm.mobileNo.value = "";
        this.setState({ dataForm: updatedForm, error: "" });
        console.log(`Mobile number ${mobileNo} added to the list.`);
      } else {
        console.log(
          `Mobile number ${mobileNo} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      this.setState({ error: "Mobile number cannot be empty." });
    }
  };

  addToEmailList = () => {
    const email = this.state.dataForm.email.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && emailRegex.test(email)) {
      if (!this.state.emailList.some((item) => item.contact === email)) {
        const emailEntry = {
          id: uuid(),
          type: "email",
          icon: "email",
          contact: email,
          isActive: true,
        };
        this.setState({ emailList: [...this.state.emailList, emailEntry] });
        const updatedForm = { ...this.state.dataForm };
        updatedForm.email.value = "";
        this.setState({ dataForm: updatedForm, error: "" });
        console.log(`Email ${email} added to the list.`);
      } else {
        console.log(
          `Email ${email} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      this.setState({
        error: email
          ? "Email is not in a valid format."
          : "Email cannot be empty.",
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.editMerchant !== this.props.editMerchant) {
      this.setState({
        mobileList: [],
        emailList: [],
      });

      const selectedMerchant = this.props.editMerchant;
      console.log("editMerchant", this.props.editMerchant);
      console.log({ selectedMerchant });

      if (selectedMerchant?.merchantId && selectedMerchant?.hotLine) {
        this.setState({
          mobileList: selectedMerchant.hotLine.mobileNo || [],
          emailList: selectedMerchant.hotLine.email || [],
        });
      } else {
        this.setState({
          mobileList: [],
          emailList: [],
        });
      }
    }
  }

  render() {
    const { isEditContacts } = this.props;
    const { viewPointHeight, dataForm, validLocation, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isEditContacts ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-400"}
          style={{
            zIndex: "999999",
            top: "9%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className={"coo_mbc__reject-header"}>
              {"Edit reservation contacts"}
            </div>
            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column mt-2"}>
            <div className="mt-1">
              <div className="shw_textWrapper d-flex flex-row align-items-center ">
                <Input
                  elementConfig={dataForm.mobileNo.elementConfig}
                  elementType={dataForm.mobileNo.elementType}
                  required={dataForm.mobileNo.validation.required}
                  isValid={!dataForm.mobileNo.valid}
                  touched={dataForm.mobileNo.touched}
                  value={dataForm.mobileNo.value}
                  isShowRequired={false}
                  size={"sm"}
                  margin={"m-b-1 m-t-2"}
                  label={dataForm.mobileNo.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.mobileNo.key)
                  }
                />
                <div className="action-bar-right d-flex mt-4 ml-2">
                  <Button
                    text={"Add to list"}
                    type={"btn-edit"}
                    size={"sm primary"}
                    onClick={this.addToMobileList}
                  />
                </div>
              </div>
              <div>
                <div className={"d-flex"}>
                  {this.state.mobileList?.length > 0 &&
                    this.state.mobileList.map((mobile, index) => (
                      // <div key={index}>{mobile.contact}</div>
                      <SelectBox
                        index={index}
                        remove={false}
                        isSelect={true}
                        select={true}
                        // onClick={() => this.onSelectLocation(item, index)}
                        onRemove={() => {
                          if (mobile.contact) {
                            this.setState({
                              mobileList: this.state.mobileList.filter(
                                (item, i) => i !== index
                              ),
                            });
                          }
                        }}
                        format={"text"}
                        text={mobile.contact}
                      />
                    ))}
                </div>
              </div>
            </div>

            <div className="mt-1">
              <div className="shw_textWrapper d-flex flex-row align-items-center">
                <Input
                  elementConfig={dataForm.email.elementConfig}
                  elementType={dataForm.email.elementType}
                  required={dataForm.email.validation.required}
                  isValid={!dataForm.email.valid}
                  touched={dataForm.email.touched}
                  value={dataForm.email.value}
                  isShowRequired={false}
                  size={"sm"}
                  margin={"m-b-1 m-t-2"}
                  label={dataForm.email.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.email.key)
                  }
                />
                <div className="action-bar-right d-flex mt-4 ml-2">
                  <Button
                    text={"Add to list"}
                    type={"btn-edit"}
                    size={"sm primary"}
                    onClick={this.addToEmailList}
                  />
                </div>
              </div>
              <div className={"d-flex"}>
                {this.state.emailList?.length > 0 &&
                  this.state.emailList.map((email, index) => (
                    // <div key={index}>{email.contact}</div>
                    <SelectBox
                      index={index}
                      remove={false}
                      isSelect={true}
                      select={true}
                      // onClick={() => this.onSelectLocation(item, index)}
                      onRemove={() => {
                        if (email.contact) {
                          this.setState({
                            emailList: this.state.emailList.filter(
                              (item, i) => i !== index
                            ),
                          });
                        }
                      }}
                      format={"text"}
                      text={email.contact}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"mb-2"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              onClick={async () => {
                await this.updateMerchantContacts();
              }}
              LeftIconText={"Update"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
            <Button
              leftIcon={false}
              rightIcon={false}
              option={""}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditContacts: state.offerReducer.isEditContacts,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectMerchant: state.merchantReducer.selectMerchant,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectedBank: state.offerReducer.selectedBank,
    selectedCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEditModal: () =>
      dispatch({ type: actionTypes.EDIT_MERCHANT_CONTACTS_CLOSE }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickSelectedMerchantLocations: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SELECTED_LOCATIONS, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
