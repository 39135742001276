import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import * as actionTypes from "../../../../store/actions/types";
import { Button, InfoRowView, Input } from "../../../../components";
import Scrollbars from "react-custom-scrollbars";
import { cooUpdateMerchantTerms } from "../../../merchant/service/merchant.service";
import { OpenNotification } from "../../../../config/notification";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";
import { v4 as uuidv4 } from "uuid";
import { updateDraft } from "../../../../api/draft";
import { updateSyncOffer } from "../../../../api";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      dataSourceMerchant: [],
      dataSourceBanks: [],
      dataSourceCategories: [],
      isLoading: false,
      selectedMerchantID: null,
      selectedBankId: null,
      selectedCategoryId: null,
      selectedBank: null,
      selectedCategory: null,
      formWizard: 1,
      recentOffers: [],
      selectLocations: [],
      termsConditions: [],
      selectTermsConditions: [],
      selectOffer: null,
      selectedMerchant: null,
      reservationMobileNo: null,
      reservationEmail: null,
      isAddNew: false,
      isClearAll: false,
      dataSource: [],
      dataForm: {
        termsConditions: {
          key: "termsConditions",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "add terms and condition here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      isPartEdit: false,
      partId: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    // create a variable called "name" and store the name attribute in it
    // this variable's value will be unique for each text area
    let name = e.target.name;
    // set that to state, along with the input value
    this.setState({ name: e.target.value });
  };

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async updateMerchantTerms() {
    const terms =
      Object.keys(this.props.termsAndConditionList).length > 0
        ? { ...this.props.termsAndConditionList }
        : { ...this.props.existingTermsList };

    const transformedTerms = Object.keys(terms).reduce((acc, key) => {
      acc[key] = terms[key].map((x) => ({
        id: x.id,
        bankId: x.bankId,
        value: x.value,
        count: x.count,
        isActive: false,
      }));
      return acc;
    }, {});

    let body = {
      termsAndCondition: transformedTerms,
    };
    console.log("cooUpdateMerchantLocations", body);
    let res = await cooUpdateMerchantTerms(
      body,
      this.props.selectMerchant.merchantId
    );
    console.log("cooUpdateMerchantLocations", res);
    if (res && res.data && res.data.status) {
      let merchant = res.data.data;
      this.props.onClickSelectedMerchant(merchant);
      this.props.onLoadTermsAndCondition(terms);
      if (this.props.editOfferData?.offerId) {
        this.props.onClickSelectOffer({
          ...this.props.editOfferData,
          termsConditions: {
            [this.props.editOfferData?.bankId]:
              merchant?.termsAndCondition[this.props.editOfferData?.bankId],
          },
        });
      } else {
        this.props.setCreateOfferData({
          selectOffer: this.props.selectOffer,
          selectedMerchant: merchant,
          selectedBank: this.props.selectBank,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          selectTerms: merchant?.termsAndCondition,
        });
      }
      this.setState({
        isLoading: false,
        isClearAll: false,
      });
      // this.props.onClickCloseTermsAndConditions();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async removeMerchantTerms() {
    const { selectMerchant } = this.props;
    const { termsConditions } = this.state;
    let newTermsAndConditions = [...this.state.termsConditions];

    // newTermsList.forEach((element) => {
    //   newTermsAndConditions.push(element);
    // });
    this.setState({
      termsConditions: newTermsAndConditions,
    });

    if (
      selectMerchant &&
      selectMerchant.termsConditions.length > newTermsAndConditions.length
    ) {
      this.setState({ isLoading: true });
      let body = {
        termsConditions: newTermsAndConditions,
      };

      let res = await cooUpdateMerchantTerms(body, selectMerchant.merchantId);
      console.log("cooUpdateMerchantLocations", res);
      if (res && res.data && res.data.status) {
        this.setState({ isLoading: false });
        let merchant = res.data.data;
        this.props.onClickSelectedMerchant(merchant);
        this.props.setCreateOfferData({
          selectOffer: this.props.selectOffer,
          selectedMerchant: merchant,
          selectedBank: this.props.selectBank,
          selectedCategory: this.props.selectCategory,
          selectLocations: this.props.selectLocations,
          selectTerms: merchant?.termsAndCondition,
        });
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  onRemoveTermsAndConditions = (i) => {
    this.props.onClickDelete({ bankId: this.props.bankId });
  };
  onRemoveTermsAndConditions = (i) => {
    this.props.onClickDelete({ bankId: this.props.bankId });
  };

  inputHandleChange = (event, inputIdentity) => {
    console.log("event", event, inputIdentity);

    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value = event.target.value;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });

    console.log("data_form", this.state.dataForm);
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.termsConditions !== this.state.termsConditions) {
      const terms = this.state.termsConditions[this.props.bankId];
      let selectTermsConditions =
        this.state.selectTermsConditions.length > 0
          ? this.state.selectTermsConditions
          : [];
      console.log(selectTermsConditions, terms);
      if (this.state.selectTermsConditions.length > 0) {
        if (terms && terms.length > 0) {
          terms.forEach((element) => {
            selectTermsConditions.push(element);
          });
        } else {
          this.state.termsConditions.forEach((element) => {
            selectTermsConditions.push(element);
          });
        }
      } else {
        selectTermsConditions =
          terms && terms.length > 0 ? terms : this.state.termsConditions;
      }
      this.setState({
        selectTermsConditions,
      });
    }
    if (prevProps.isClearAll !== this.props.isClearAll) {
      if (this.props.isClearAll) {
        // Call the updateMerchantTerms function
        await this.updateMerchantTerms();
      }
    }
    // if (prevProps.termsAndConditionList !== this.props.termsAndConditionList) {
    //   const terms = this.props.termsAndConditionList[this.props.bankId];
    //   let newTermsConditions = { ...this.state.selectTermsConditions };
    //   let termsConditions = [...(newTermsConditions[this.props.bankId] || [])];
    //
    //   if (terms && terms?.length > 0) {
    //     terms.forEach((element, idx) => {
    //       if (termsConditions[idx]?.id != element.id) {
    //         termsConditions.push(element);
    //       }
    //     });
    //     newTermsConditions[this.props.bankId] = termsConditions;
    //
    //     this.setState({
    //       selectTermsConditions: newTermsConditions,
    //     });
    //   } else {
    //     console.log("terms", terms);
    //   }
    // }
    // if (prevProps.existingTermsList !== this.props.existingTermsList) {
    //   console.log("terms:::::", this.props.existingTermsList);
    //   //todo
    //   //please explain code
    //   const terms = this.props.existingTermsList[this.props.bankId];
    //   let newTermsConditions = { ...this.state.selectTermsConditions };
    //   let termsConditions = [...(newTermsConditions[this.props.bankId] || [])];
    //
    //   console.log("terms", terms, newTermsConditions);
    //   if (terms && terms?.length > 0) {
    //     terms.forEach((element, idx) => {
    //       if (termsConditions[idx]?.id != element.id) {
    //         termsConditions.push(element);
    //       }
    //     });
    //     newTermsConditions[this.props.bankId] = termsConditions;
    //     console.log("terms", newTermsConditions);
    //
    //     this.setState({
    //       selectTermsConditions: newTermsConditions,
    //     });
    //   }
    // }
  }

  selectAllTermsAndConditions = () => {
    const { termsConditions } = this.state;
    for (let idx = 0; idx < termsConditions.length; idx++) {
      if (termsConditions[idx].isActive) {
        termsConditions[idx].isActive = false;
      } else {
        termsConditions[idx].isActive = true;
      }
    }
    this.setState({ termsConditions });
  };

  isArray = (value) => {
    return Array.isArray(value);
  };

  displayListData = (list) => {
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        console.log(`Bank ID: ${key}`);
        list[key].forEach((item) => {});
      }
    }
  };

  handleEditPart = (partId, value) => {
    this.setState({ isPartEdit: true, partId: partId });
    const updateForm = {
      ...this.state.dataForm,
    };
    //updatedFormElement.touched = true;
    updateForm["termsConditions"].value = value;
    this.setState({ dataForm: updateForm });
  };
  clearTermsAndBox = () => {
    const updateForm = { ...this.state.dataForm };
    updateForm["termsConditions"].value = "";
    this.setState({ dataForm: updateForm });
  };

  handleAddTermsAndCondition = () => {
    if (this.state.dataForm?.termsConditions?.value) {
      const { value } = this.state.dataForm.termsConditions;
      const bankId = this.props.bankId;
      // Split the input value by the regex pattern
      const regex = /\r?\n/;
      const parts = this.state.dataForm.termsConditions.value
        ?.split(regex)
        .filter((part) => part.trim() !== "");
      console.log("parts", parts.length);

      // Array to hold the terms and conditions to be added
      const termsAndConditionList = [];

      if (parts.length > 1) {
        // Handle case where there are multiple parts
        parts.forEach((part) => {
          if (part.trim()) {
            // Ensure the part is not an empty string
            if (!this.isDuplicate(part, bankId)) {
              termsAndConditionList.push(
                this.createTermCondition(part, bankId)
              );
            } else {
              console.warn("already existing.");
            }
          }
        });
      } else {
        // Handle case where it's a single line
        if (!this.isDuplicate(value, bankId)) {
          // this.props.clearTermsAndCondition();
          termsAndConditionList.push(this.createTermCondition(value, bankId));
        } else {
          console.warn("already existing.");
        }
      }
      // Add the new terms and conditions
      termsAndConditionList.forEach((term) => {
        this.props.addNewTermsAndCondition({
          parts: term,
          bankId: bankId,
        });
      });
      // Update state and clear the input box
      this.setState({ termsAndConditionList, isAddNew: true });
      this.clearTermsAndBox();
    }
  };

  isDuplicate = (value, bankId) => {
    const { selectTermsConditions } = this.state;
    console.log("termsAndConditionList", this.state.selectTermsConditions);
    if (selectTermsConditions[bankId]) {
      return selectTermsConditions[bankId].some((term) => term.value === value);
    } else {
      console.warn(`No terms and conditions found for bankId: ${bankId}`);
      return false;
    }
  };

  createTermCondition = (value, bankId) => ({
    id: uuidv4(),
    value: value.trim(),
    bankId: bankId,
    isActive: false,
    count: 0,
  });

  onClickFormatTermsAndCondition = (partId, value) => {
    const { dataForm } = this.state;
    if (dataForm.termsConditions.value) {
      const regex = /\r?\n/;
      const parts = dataForm.termsConditions.value
        ?.split(regex)
        .filter((part) => part.trim() !== "");
      // Log the split parts to verify
      console.log("format_list", parts);
    }
  };

  render() {
    const {
      viewPointHeight,
      viewPointWidth,
      dataForm,
      termsConditions,
      selectedBankId,
      selectTermsConditions,
      isPartEdit,
      partId,
      termsAndCondition,
      isAddNew,
    } = this.state;
    const {
      editOfferData,
      selectMerchant,
      bankId,
      existingTermsList,
      termsAndConditionList,
    } = this.props;

    return (
      <>
        {/*terms_condition-wrapper*/}
        <Scrollbars
          style={{
            height:
              viewPointWidth >= 1028
                ? viewPointHeight - 380
                : viewPointHeight - 600,
          }}
          thumbMinSize={30}
          universal={true}
          autoHide
        >
          <div
            className={"manage-terms_condition-wrapper d-flex flex-column mb-4"}
          >
            <div
              className={
                "mtcw__section-header-wrp d-flex flex-row align-items-center justify-content-between"
              }
            >
              <div className={"mtcw__shw_header"}>
                Create new Terms & Conditions - {this.props.bankName}
              </div>
              <div className={"action-bar-right d-flex"}>
                <Button
                  text={"Format"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={() => {
                    this.onClickFormatTermsAndCondition();
                  }}
                />
                <Button
                  text={"Find"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={() => {
                    this.props.onClickOpenFindAndCondition();
                  }}
                />
                {dataForm.termsConditions.value && (
                  <Button
                    text={"Clear"}
                    type={"btn-edit"}
                    size={"sm secondary mr-2"}
                    onClick={() => {
                      this.clearTermsAndBox();
                    }}
                  />
                )}
              </div>
            </div>

            <Input
              elementConfig={dataForm.termsConditions.elementConfig}
              elementType={dataForm.termsConditions.elementType}
              required={dataForm.termsConditions.validation.required}
              isValid={!dataForm.termsConditions.valid}
              touched={dataForm.termsConditions.touched}
              value={dataForm.termsConditions.value}
              isShowRequired={false}
              size={"sm"}
              margin={"m-b-1 m-t-2"}
              label={dataForm.termsConditions.label}
              changed={(event) => {
                this.inputHandleChange(event, dataForm.termsConditions.key);
              }}
            />
            {!isPartEdit && (
              <Button
                text={"Add to list"}
                type={"btn-edit mt-2"}
                isLoading={false}
                onClick={async () => {
                  this.handleAddTermsAndCondition();
                }}
                size={"sm primary"}
              />
            )}

            {isPartEdit && (
              <div className={"tc_part_edit-action-wrapper d-flex"}>
                {dataForm.termsConditions.value && (
                  <Button
                    text={"update"}
                    type={"btn-edit mt-2 mr-2"}
                    isLoading={false}
                    onClick={async () => {
                      this.props.onClickEdit({
                        bankId: bankId,
                        id: partId,
                        newValue: dataForm.termsConditions.value,
                      });
                      this.setState({
                        isAddNew: true,
                      });
                      if (
                        this.state.selectTermsConditions &&
                        Object.keys(this.state.selectTermsConditions).length > 0
                      ) {
                        const updateTerms = {
                          ...this.state.selectTermsConditions,
                        };

                        const partList = updateTerms[bankId];
                        if (partList) {
                          const updatedPartList = partList.map((item) => {
                            if (item.id === partId) {
                              return {
                                ...item,
                                value: dataForm.termsConditions.value,
                              };
                            }
                            return item;
                          });
                          updateTerms[bankId] = updatedPartList;
                        }

                        await this.setState({
                          selectTermsConditions: updateTerms,
                          isAddNew: true,
                        });
                      }

                      this.clearTermsAndBox();
                      this.setState({ partId: null, isPartEdit: false });
                      OpenNotification("Success", "Done", "success");
                    }}
                    size={"sm primary"}
                  />
                )}

                <Button
                  text={"cancel"}
                  type={"btn-edit mt-2"}
                  isLoading={false}
                  onClick={() =>
                    this.setState({ partId: null, isPartEdit: false })
                  }
                  size={"sm primary"}
                />
              </div>
            )}
          </div>
          <div className={"mtcw_list-view"}>
            <div
              className={
                "mtcw__section-header-wrp d-flex flex-row align-items-center justify-content-between"
              }
            >
              <div className={"mtcw__shw_header "}>
                Terms & Conditions
                {/*({termsAndConditionList[this.props?.bankId]?.length})*/}
              </div>
              <div className={"action-bar-right"}>
                <Button
                  text={"clear all"}
                  type={"btn-edit"}
                  size={"sm secondary mr-2"}
                  onClick={async () => {
                    await this.props.onClickClearAllTerms({
                      bankId: this.props.bankId,
                    });
                    if (this.props.editOfferData?.offerId) {
                      await this.props.onClickClearAllOfferTerms({
                        bankId: this.props.bankId,
                      });
                    }
                    this.setState({ isClearAll: true });
                    if (Object.keys(selectTermsConditions).length > 0) {
                      // Get the current state of selectTermsConditions
                      let selectTermsConditions = {
                        ...this.state.selectTermsConditions,
                      };
                      console.log({ selectTermsConditions });
                      // Update the state using setState
                      await this.setState((state) => {
                        // Filter out the item to be deleted
                        const updateTerms = [];

                        // Update the selectTermsConditions object with the filtered items
                        selectTermsConditions[this.props.bankId] = updateTerms;

                        // Check if the array is empty, and if so, delete the property
                        if (
                          selectTermsConditions[this.props.bankId].length === 0
                        ) {
                          delete selectTermsConditions[this.props.bankId];
                        }

                        return { selectTermsConditions };
                      });
                      this.setState({ isClearAll: true });
                    }
                  }}
                />
              </div>
            </div>
            {/* {this.isArray(selectTermsConditions) ? (
              <>
                {selectTermsConditions &&
                  (selectTermsConditions || []).map((item, index) => (
                    <InfoRowView
                      type="terms"
                      text={item?.value}
                      isSelect={this.props.editOfferData?.offerId? item.isActive:item?.id === partId}
                      style="mb-1 justify-content-between align-items-center"
                      onClickConfirm={async () => {
                        this.props.onClickDelete({
                          bankId: this.props.bankId,
                          partId: item?.id,
                        });
                        let selectTermsConditions = [
                          ...this.state.selectTermsConditions,
                        ];
                        await this.setState((state) => {
                          const updateTerms =
                            state.selectTermsConditions.filter(
                              (i, j) => i.id !== item.id
                            );
                          selectTermsConditions = updateTerms;
                          return {
                            selectTermsConditions,
                          };
                        });

                        await this.updateMerchantTerms();
                      }}
                      onClickEdit={() =>
                        this.handleEditPart(item?.id, item?.value)
                      }
                    />
                  ))}
              </>
            ) : ( */}
            <>
              {this.props.existingTermsList &&
                Object.keys(this.props.termsAndConditionList).length === 0 &&
                Object.keys(this.props.existingTermsList).length > 0 && (
                  <>
                    {this.props.existingTermsList?.[bankId]?.map(
                      (item, index) => (
                        <div key={item.id}>
                          <InfoRowView
                            type="terms"
                            text={item?.value}
                            isSelect={
                              this.props.editOfferData?.offerId
                                ? item.isActive
                                : item?.id === partId
                            }
                            style="mb-1 justify-content-between align-items-center"
                            onClickConfirm={async () => {
                              await this.props.onClickDelete({
                                bankId: this.props.bankId,
                                partId: item?.id,
                              });
                              if (this.props.editOfferData?.offerId) {
                                await this.props.onClickDeleteOfferTerms({
                                  bankId: this.props.bankId,
                                  partId: item?.id,
                                });
                              }

                              if (!editOfferData?.offerId) {
                                // Call the updateMerchantTerms function
                                await this.updateMerchantTerms();
                              }
                            }}
                            onClick={() => this.setState({ partId: item?.id })}
                            onClickEdit={() =>
                              this.handleEditPart(item?.id, item?.value)
                            }
                          />
                        </div>
                      )
                    )}
                  </>
                )}
              {this.props.existingTermsList &&
                Object.keys(this.props.termsAndConditionList).length > 0 &&
                Object.keys(this.props.existingTermsList).length > 0 && (
                  <>
                    {this.props.existingTermsList?.[bankId]?.map(
                      (item, index) => (
                        <div key={item.id}>
                          <InfoRowView
                            type="terms"
                            text={item?.value}
                            isSelect={
                              this.props.editOfferData?.offerId
                                ? item.isActive
                                : item?.id === partId
                            }
                            style="mb-1 justify-content-between align-items-center"
                            onClickConfirm={async () => {
                              await this.props.onClickDelete({
                                bankId: this.props.bankId,
                                partId: item?.id,
                              });
                              if (this.props.editOfferData?.offerId) {
                                await this.props.onClickDeleteOfferTerms({
                                  bankId: this.props.bankId,
                                  partId: item?.id,
                                });
                              }
                              // Get the current state of selectTermsConditions
                              let selectTermsConditions = {
                                ...this.state.selectTermsConditions,
                              };
                              if (
                                Object.keys(selectTermsConditions).length > 0
                              ) {
                                // Update the state using setState
                                await this.setState((state) => {
                                  // Filter out the item to be deleted
                                  const updateTerms =
                                    state.selectTermsConditions[
                                      this.props.bankId
                                    ].filter((i) => i.id !== item.id);

                                  // Update the selectTermsConditions object with the filtered items
                                  selectTermsConditions[this.props.bankId] =
                                    updateTerms;

                                  // Check if the array is empty, and if so, delete the property
                                  if (
                                    selectTermsConditions[this.props.bankId]
                                      .length === 0
                                  ) {
                                    delete selectTermsConditions[
                                      this.props.bankId
                                    ];
                                  }
                                  return {
                                    selectTermsConditions,
                                  };
                                });
                              }
                              if (!editOfferData?.offerId) {
                                // Call the updateMerchantTerms function
                                await this.updateMerchantTerms();
                              }
                            }}
                            onClick={() => this.setState({ partId: item?.id })}
                            onClickEdit={() =>
                              this.handleEditPart(item?.id, item?.value)
                            }
                          />
                        </div>
                      )
                    )}
                  </>
                )}
              {this.props.termsAndConditionList && (
                <>
                  {Object.keys(this.props.termsAndConditionList).length > 0 &&
                    Object.keys(this.props.existingTermsList).length === 0 &&
                    this.props.termsAndConditionList?.[bankId]?.map(
                      (item, index) => (
                        <div key={item.id}>
                          <InfoRowView
                            type="terms"
                            text={item?.value}
                            isSelect={
                              this.props.editOfferData?.offerId
                                ? item.isActive
                                : item?.id === partId
                            }
                            style="mb-1 justify-content-between align-items-center"
                            onClickConfirm={async () => {
                              console.log(
                                "terms delete",
                                this.props.termsAndConditionList
                              );
                              await this.props.onClickDelete({
                                bankId: this.props.bankId,
                                partId: item?.id,
                              });

                              if (this.props.editOfferData?.offerId) {
                                await this.props.onClickDeleteOfferTerms({
                                  bankId: this.props.bankId,
                                  partId: item?.id,
                                });
                              }

                              if (
                                Object.keys(selectTermsConditions).length > 0
                              ) {
                                // Get the current state of selectTermsConditions
                                let selectTermsConditions = {
                                  ...this.state.selectTermsConditions,
                                };

                                // Update the state using setState
                                await this.setState((state) => {
                                  // Filter out the item to be deleted
                                  const updateTerms = {
                                    ...state.selectTermsConditions[
                                      this.props.bankId
                                    ].filter((i) => i.id !== item.id),
                                  };
                                  // Update the selectTermsConditions object with the filtered items
                                  selectTermsConditions[this.props.bankId] =
                                    updateTerms;
                                  if (
                                    selectTermsConditions[this.props.bankId]
                                      .length === 0
                                  ) {
                                    delete selectTermsConditions[
                                      this.props.bankId
                                    ];
                                  }
                                  return {
                                    selectTermsConditions,
                                  };
                                });
                              }
                              if (!editOfferData?.offerId) {
                                // Call the updateMerchantTerms function
                                await this.updateMerchantTerms();
                              }
                            }}
                            onClick={() => this.setState({ partId: item?.id })}
                            onClickEdit={() =>
                              this.handleEditPart(item?.id, item?.value)
                            }
                          />
                        </div>
                      )
                    )}
                </>
              )}
            </>
            {/* )} */}

            {/*{termsAndConditionList[this.props.bankId]?.map((item, index) => (*/}
            {/*    <>*/}
            {/*      <InfoRowView*/}
            {/*          type={"terms"}*/}
            {/*          text={item.value}*/}
            {/*          isSelect={item.isActive}*/}
            {/*          style={"mb-1 justify-content-between align-items-center"}*/}
            {/*          onClick={() => this.onRemoveTermsAndConditions(index)}*/}
            {/*          onClickConfirm={() =>*/}
            {/*              this.onRemoveTermsAndConditions(index)*/}
            {/*          }*/}
            {/*          //  onClickCancel={() => }*/}
            {/*      />*/}
            {/*    </>*/}
            {/*))}*/}
          </div>
        </Scrollbars>
        {/* {isAddNew && ( */}
        {isAddNew && this.props.isTermsAndConditions && (
          <div className={"d-flex mt-3"}>
            <div
              className={
                "ctcn__-footer-action-wrapper d-flex align-items-center justify-content-between"
              }
            >
              <div className={"sm__footer-info d-flex flex-column mr-2"}>
                {this.props.bankName}
                <br />
                {this.props.bankId}
              </div>
              <div>
                {this.props.isTermsAndConditions && (
                  <Button
                    leftIconFill={true}
                    iconName={<RiCloseLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    onClick={() => {
                      this.props.onClickCloseTermsAndConditions();
                    }}
                    LeftIconText={null}
                    text={"Cancel"}
                    size={"md"}
                    type={"btn-back"}
                  />
                )}

                <Button
                  leftIconFill={true}
                  iconName={<RiCheckLine />}
                  leftIcon={false}
                  rightIcon={false}
                  option={"ml-1"}
                  isLoading={false}
                  disabled={!isAddNew}
                  isActiveAnimation={false}
                  reactRightIcon={GetIcon("")}
                  onClick={async () => {
                    this.setState({ isLoading: true });
                    const terms =
                      Object.keys(this.props.termsAndConditionList).length > 0
                        ? { ...this.props.termsAndConditionList }
                        : { ...this.props.existingTermsList };

                    const transformedTerms = Object.keys(terms).reduce(
                      (acc, key) => {
                        acc[key] = terms[key].map((x) => ({
                          id: x.id,
                          bankId: x.bankId,
                          value: x.value,
                          count: x.count,
                          isActive:
                            selectMerchant?.status === "auto" ||
                            this.props.selectOffer?.userId === "auto"
                              ? true
                              : false,
                        }));
                        return acc;
                      },
                      {}
                    );

                    let body = {
                      termsAndCondition: transformedTerms,
                    };

                    let res = await cooUpdateMerchantTerms(
                      body,
                      selectMerchant.merchantId
                    );

                    if (selectMerchant?.status !== "auto") {
                      this.props.addTermsAndCondition({
                        selectTerms: this.state.selectTermsConditions,
                      });
                    }
                    console.log("cooUpdateMerchantLocations", res);
                    if (res && res.data && res.data.status) {
                      // this.setState({isLoading: false});
                      let merchant = res.data.data;
                      if (
                        selectMerchant?.status === "auto" ||
                        this.props.selectOffer?.userId === "auto"
                      ) {
                        let offerBody = {
                          ...this.props.selectOffer,
                          termsConditions: {
                            [this.props.selectOffer?.bankId]:
                              transformedTerms[this.props.selectOffer?.bankId],
                          },
                        };

                        let resOffer = await updateSyncOffer(
                          offerBody,
                          this.props.selectOffer?.offerId
                        );
                        this.props.closeOfferViewModal();
                        console.log(resOffer);
                        this.props.onClickOpenOfferView(resOffer?.data?.data);
                      } else {
                        this.props.onClickSelectedMerchant(merchant);
                        if (this.props.editOfferData?.offerId) {
                          this.props.onClickAddTermsIntoList(
                            body.termsAndCondition
                          );
                          this.props.onClickSelectOffer({
                            ...this.props.editOfferData,
                            termsConditions: {
                              [this.props.editOfferData?.bankId]:
                                body.termsAndCondition[
                                  this.props.editOfferData?.bankId
                                ],
                            },
                          });
                        }
                        if (
                          this.props.selectedOfferData &&
                          this.props.selectedOfferData?.draftId
                        ) {
                          this.props.onClickAddTermsIntoList(
                            merchant.termsAndCondition
                          );
                          console.log("terms::>>", merchant?.termsAndCondition);
                          let resDraft = await updateDraft(
                            {
                              ...this.props.selectedOfferData,
                              termsConditions: {
                                [this.props?.bankId]:
                                  merchant?.termsAndCondition[
                                    this.props?.bankId
                                  ],
                              },
                            },
                            this.props.selectedOfferData?.draftId
                          );
                          console.log({ resDraft });
                          if (this.props.isTermsAndConditions) {
                            this.props.onClickSelectedMerchant(selectMerchant);
                            if (this.props.editOfferData) {
                              this.props.onClickSelectOffer({
                                ...this.props.editOfferData,
                                termsConditions: {
                                  [this.props.editOfferData?.bankId]:
                                    body.termsAndCondition[
                                      this.props.editOfferData?.bankId
                                    ],
                                },
                              });
                            } else {
                              this.props.setCreateOfferData({
                                selectOffer: this.props.selectedOfferData,
                                selectedMerchant: merchant,
                                selectedBank: this.props.selectBank,
                                selectedCategory: this.props.selectCategory,
                                selectLocations: this.props.selectLocations,
                                selectTerms: merchant.termsAndCondition,
                              });
                            }
                          }
                          this.props.refreshOffers();
                          this.props.refreshSyncOffers();
                        }
                      }
                      this.setState({ isAddNew: false });
                      this.props.onClickCloseTermsAndConditions();
                    } else {
                      this.setState({ isLoading: false });
                    }
                  }}
                  LeftIconText={null}
                  text={"Update"}
                  size={"md"}
                  type={"btn-primary"}
                />
              </div>
            </div>
          </div>
        )}
        {/* {!isAddNew &&
          editOfferData?.offerId &&
          editOfferData?.termsConditions[bankId]?.length !==
            this.props.existingTermsList[bankId]?.length && (
            <div className={"d-flex mt-3"}>
              <div
                className={
                  "ctcn__-footer-action-wrapper d-flex align-items-center justify-content-between"
                }
              >
                <div className={"sm__footer-info d-flex flex-column mr-2"}>
                  {this.props.bankName}
                  <br />
                  {this.props.bankId}
                </div>
                <div>
                  {this.props.isTermsAndConditions && (
                    <Button
                      leftIconFill={true}
                      iconName={<RiCloseLine />}
                      leftIcon={false}
                      rightIcon={false}
                      option={"ml-1"}
                      isLoading={false}
                      isActiveAnimation={false}
                      reactRightIcon={GetIcon("")}
                      onClick={() => {
                        this.props.onClickCloseTermsAndConditions();
                      }}
                      LeftIconText={null}
                      text={"Cancel"}
                      size={"md"}
                      type={"btn-back"}
                    />
                  )}

                  <Button
                    leftIconFill={true}
                    iconName={<RiCheckLine />}
                    leftIcon={false}
                    rightIcon={false}
                    option={"ml-1"}
                    isLoading={false}
                    disabled={
                      editOfferData?.termsConditions[bankId]?.length ===
                      this.props.existingTermsList[bankId]?.length
                    }
                    isActiveAnimation={false}
                    reactRightIcon={GetIcon("")}
                    onClick={async () => {
                      this.setState({ isLoading: true });
                      console.log(
                        "Terms>>>",
                        this.props.termsAndConditionList,
                        this.props.existingTermsList
                      );
                      const terms =
                        Object.keys(this.props.termsAndConditionList).length > 0
                          ? { ...this.props.termsAndConditionList }
                          : { ...this.props.existingTermsList };

                      const transformedTerms = Object.keys(terms).reduce(
                        (acc, key) => {
                          acc[key] = terms[key].map((x) => ({
                            id: x.id,
                            bankId: x.bankId,
                            value: x.value,
                            count: x.count,
                            isActive: false,
                          }));
                          return acc;
                        },
                        {}
                      );

                      let body = {
                        termsAndCondition: transformedTerms,
                      };
                      console.log("cooUpdateMerchantLocations", body);

                      this.props.addTermsAndCondition({
                        selectTerms: this.state.selectTermsConditions,
                      });
                      let res = await cooUpdateMerchantTerms(
                        body,
                        selectMerchant.merchantId
                      );
                      console.log("cooUpdateMerchantLocations", res);
                      if (res && res.data && res.data.status) {
                        // this.setState({isLoading: false});
                        let merchant = res.data.data;
                        this.props.onClickSelectedMerchant(merchant);
                        if (this.props.editOfferData?.offerId) {
                          this.props.onClickAddTermsIntoList(
                            merchant.termsAndCondition
                          );
                        }
                        if (
                          this.props.selectedOfferData &&
                          this.props.selectedOfferData?.draftId
                        ) {
                          this.props.onClickAddTermsIntoList(
                            merchant.termsAndCondition
                          );
                          console.log("terms::>>", merchant?.termsAndCondition);
                          let resDraft = await updateDraft(
                            {
                              ...this.props.selectedOfferData,
                              termsConditions: {
                                [this.props?.bankId]:
                                  merchant?.termsAndCondition[
                                    this.props?.bankId
                                  ],
                              },
                            },
                            this.props.selectedOfferData?.draftId
                          );
                          console.log({ resDraft });
                          if (this.props.isTermsAndConditions) {
                            this.props.onClickSelectedMerchant(selectMerchant);
                            if (this.props.editOfferData) {
                              this.props.onClickSelectOffer(
                                this.props.editOfferData
                              );
                            } else {
                              this.props.setCreateOfferData({
                                selectOffer: this.props.selectedOfferData,
                                selectedMerchant: merchant,
                                selectedBank: this.props.selectBank,
                                selectedCategory: this.props.selectCategory,
                                selectLocations: this.props.selectLocations,
                                selectTerms: merchant.termsAndCondition,
                              });
                            }
                          }
                          this.props.refreshOffers();
                        }
                        this.setState({ isAddNew: false });
                        this.props.onClickCloseTermsAndConditions();
                      } else {
                        this.setState({ isLoading: false });
                      }
                    }}
                    LeftIconText={null}
                    text={"Update"}
                    size={"md"}
                    type={"btn-primary"}
                  />
                </div>
              </div>
            </div>
          )} */}
        {/* )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTermsAndConditions: state.termsReducer.isTermsAndConditions,
    isClearAll: state.termsReducer.isClearAll,
    selectMerchant: state.merchantReducer.selectMerchant,
    editBank: state.offerReducer.editBank,
    editCategory: state.offerReducer.editCategory,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectBank: state.offerReducer.selectedBank,
    selectCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    termsAndConditionList: state.termsReducer.termsAndConditionList,
    existingTermsList: state.termsReducer.existingTermsList,
    isSelectedExistingTerms: state.termsReducer.isSelectedExistingTerms,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    clearSearchMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SEARCH_MERCHANT }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    addNewTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.ADD_NEW_TERMS_INTO_LIST, payload: data }),
    clearTermsAndCondition: (data) =>
      dispatch({ type: actionTypes.CLEAR_TERMS_AND_CONDITION }),
    onClickEdit: (data) =>
      dispatch({ type: actionTypes.EDIT_TERMS_INTO_LIST, payload: data }),
    onClickDeleteOfferTerms: (data) =>
      dispatch({
        type: actionTypes.DELETE_OFFER_TERMS_INTO_LIST,
        payload: data,
      }),
    onClickClearAllOfferTerms: (data) =>
      dispatch({
        type: actionTypes.DELETE_OFFER_TERMS_INTO_LIST,
        payload: data,
      }),
    onClickEditDelete: (data) =>
      dispatch({ type: actionTypes.DELETE_TERMS_INTO_LIST, payload: data }),
    onClickClearAllTerms: (data) =>
      dispatch({ type: actionTypes.CLEAR_ALL_TERMS_INTO_LIST, payload: data }),
    onClickOpenFindAndCondition: (data) =>
      dispatch({
        type: actionTypes.OPEN_FIND_TERMS_AND_CONDITION,
        payload: data,
      }),
    onClickCloseTermsAndConditions: () =>
      dispatch({ type: actionTypes.CLOSE_TERMS_AND_CONDITIONS_MODAL }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    addTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ADD_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    onClickAddTermsIntoList: (payload) =>
      dispatch({ type: actionTypes.SELECTED_EXISTING_TERMS, payload: payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    onClickDelete: (data) =>
      dispatch({ type: actionTypes.DELETE_TERMS_INTO_LIST, payload: data }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
