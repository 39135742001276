import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, WebOfferCard } from "../../../components";
import { Spin } from "antd";
import { getAllOffersURLs } from "../../../api";
import { OpenNotification } from "../../../config/notification";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isLoading: false,
      offerUrls: [],
      dataForm: {
        urlTextArea: {
          key: "urlTextArea",
          elementType: "textArea",
          elementConfig: {
            type: "select",
            placeholder: "Fetch Offers",
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async onClickGetAllOffersURLs() {
    this.setState({ isLoading: true });
    let resOffers = await getAllOffersURLs();
    console.log("resOffers", resOffers);
    if (resOffers?.status) {
      this.setState({
        offerUrls: resOffers && resOffers.data,
        isLoading: false,
        dataForm: {
          ...this.state.dataForm,
          urlTextArea: {
            ...this.state.dataForm.urlTextArea,
            value: resOffers && resOffers.data?.toString(),
          },
        },
      });
    }
  }

  formatUrls(input) {
    const urls = input.split(","); // Split the string by commas
    const formattedUrls = urls.map((url) => `'${url.trim()}'`); // Trim whitespace and wrap in single quotes
    return formattedUrls.join(",\n    "); // Join with comma and proper indentation
  }

  handleCopy = async () => {
    const { dataForm } = this.state;
    try {
      await navigator.clipboard.writeText(
        this.formatUrls(dataForm.urlTextArea.value)
      );
      OpenNotification("success", "Success", "success");
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const { isLoading, dataForm } = this.state;

    return (
      <>
        <div className="d-flex flex-row justify-content-between mt-3 mb-3">
          <div className={"d-flex"}>
            <Button
              text={"Fetch Offers URLs"}
              type={"btn-edit  md"}
              size={"lg secondary"}
              isLoading={isLoading}
              onClick={async () => {
                await this.onClickGetAllOffersURLs();
              }}
            />
          </div>
          <div className={"copy-btn-wrapper"}>
            <Button
              text={"Copy URLs"}
              type={"btn-edit  md"}
              size={"lg secondary"}
              disabled={dataForm.urlTextArea.value === "" ? true : false}
              onClick={async () => await this.handleCopy()}
            />
          </div>
        </div>
        <div className={"offers-view-wrapper"}>
          <>
            <Input
              elementConfig={dataForm.urlTextArea.elementConfig}
              elementType={dataForm.urlTextArea.elementType}
              required={dataForm.urlTextArea.validation.required}
              isValid={!dataForm.urlTextArea.valid}
              touched={dataForm.urlTextArea.touched}
              value={dataForm.urlTextArea.value}
              defaultValue={null}
              label={dataForm.urlTextArea.label}
              isShowRequired={false}
              rows={20}
              size={"lg"}
              disabled={dataForm.urlTextArea.value === "" ? true : false}
            />
          </>
          {isLoading && (
            <div style={{ margin: "0 50%", opacity: 0.5 }}>
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshSyncOffers: state.offerReducer.refreshSyncOffers,
  editOfferUrls: state.offerReducer.editOfferUrls,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),

    setCreateOfferUrls: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_SYNC_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
