import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isValidLogin} from '../utils';

const PrivateRoute = ({component: Component, ...rest}) => {
    console.log('Private Route event fire....');
    return (
        <Route {...rest}
               render={props => (isValidLogin() ? <Component {...props} /> : <Redirect to="/"/>
               )}/>
    );
};

export default PrivateRoute;
