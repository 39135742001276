import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import moment from "moment";
import {
  Button,
  FileUpload,
  Input,
  PaymentDetails,
  ProfileTitle,
  Tag,
} from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine } from "react-icons/ri";
import {
  getSessionValidateStatus,
  getUserById,
  updateOfferStatus,
} from "../../../api";
import { getToken, userDetails } from "../../../utils";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      dataForm: {
        comment: {
          label: "Comment",
          key: "Comment",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    let state = await userDetails();
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      console.log("state::::", state);
      if (state && state.userId) {
        let res = await getUserById(state?.userId);
        console.log("getUserById", res);
        if (res && res.status) {
          const token = await getToken();
          let user = res.data;
          let data = {
            ...user,
            token: token,
          };
          console.log("getUserById:::", data);

          this.setState({
            user: data,
            isLoading: false,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    console.log(event, inputIdentity);
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  render() {
    const { isPaymentDetails, isImageUploading } = this.props;
    const { viewPointHeight, dataForm, isLoading, user } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated  ${
          isPaymentDetails ? "show-me" : "hide-me"
        }`}
        style={{ zIndex: "999999" }}
      >
        <div
          className={"coo_modal-body-content fix-536 d-flex flex-column"}
          style={{ padding: "20px", height: "550px", top: "10%", left: "30%" }}
        >
          <div className={"cco_pmw-header"}>
            <ProfileTitle title="Payment Details" />
          </div>
          <Scrollbars
            style={{ height: viewPointHeight - 350 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className="cco_payment-modal-body-wrapper">
              <div className="cco_pmw-profileInfo-wrapper">
                <div className="cco_pmw-pw-image">
                  <img
                    src={user && user.profileAvatar}
                    alt={"Profile Picture"}
                  />
                </div>
                {/* profile details */}
                <div className="cco_pmw-pw-details-wrapper">
                  <div className="cco_pmw-pw-userName">
                    {user && user.firstName + " " + user.lastName}
                  </div>
                  <div className="cco_pmw-pw-userMail">
                    {user && user.userName}
                  </div>
                  <div className={"d-flex flex-row"}>
                    <Tag
                      type={"admin"}
                      tagName={
                        user &&
                        user.userType.charAt(0).toUpperCase() +
                          user.userType.slice(1)
                      }
                    />
                  </div>
                </div>
              </div>
              {/* invoice date */}
              <div className="cco_pmw-invoice-wrapper">
                <div className="cco_pmw-invoice">Invoice date</div>
                <div className="cco_pmw-iDate">26/Jul/2024</div>
              </div>
              {/* tags */}
              <div className="cco_pmw_box-wrapper">
                <div className="cco_pmw-bw-tags-Wrapper">
                  <div className="cco_pmw-tw-title">Description</div>
                  <div className="cco_pmw-tw-details">Add Offers</div>
                </div>
                <div className="cco_pmw-bw-tags-Wrapper">
                  <div className="cco_pmw-tw-title">QTY</div>
                  <div className="cco_pmw-tw-details">120 (x5)</div>
                </div>
                <div className="cco_pmw-bw-tags-Wrapper">
                  <div className="cco_pmw-tw-title">Total</div>
                  <div className="cco_pmw-tw-details">LKR 600.00</div>
                </div>
              </div>
              <div className="cco_pmw-comment-wrapper">
                <Input
                  elementConfig={dataForm.comment.elementConfig}
                  elementType={dataForm.comment.elementType}
                  required={dataForm.comment.validation.required}
                  isValid={!dataForm.comment.isValid}
                  touched={dataForm.comment.touched}
                  value={dataForm.comment.value}
                  props={dataForm.comment}
                  isShowRequired={false}
                  size={"sm"}
                  margin={"m-b-2"}
                  label={dataForm.comment.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.comment.key)
                  }
                />
              </div>
              <div className="cco_pmw-fileUpload-wrapper">
                <FileUpload
                  title={"Upload receipt"}
                  isImageUploading={isImageUploading}
                />
              </div>
            </div>
          </Scrollbars>
          <div className={"cco_payment-modal-footer-action-wrapper"}>
            <div style={{ width: "100px" }}>
              <Button
                leftIcon={false}
                rightIcon={false}
                option={"mr-1"}
                isLoading={false}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("leftArrow")}
                onClick={() => {
                  this.props.onClickClosePaymentDetails();
                }}
                text={"Cancel"}
                size={"md"}
                type={"btn-block-back"}
              />
            </div>
            <div style={{ width: "102px" }}>
              <Button
                leftIconFill={true}
                iconName={<RiCheckLine />}
                leftIcon={false}
                rightIcon={false}
                option={"ml-1"}
                isLoading={isLoading}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("")}
                // onClick={async () => {
                //   await this.onClickReject();
                // }}
                LeftIconText={"Done"}
                text={""}
                size={"md"}
                type={"btn-block"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPaymentDetails: state.paymentReducer.isPaymentDetails,
    userData: state.userReducer.userData,
    refreshOffers: state.offerReducer.refreshOffers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickClosePaymentDetails: () =>
      dispatch({
        type: actionTypes.CLOSE_PAYMENT_DETAILS_MODAL,
        status: false,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
