import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import { OpenNotification } from "../../../config/notification";

import { Scrollbars } from "react-custom-scrollbars";

import { PageHeader, Input, Button, CustomTag } from "../../../components";
import { GetIcon } from "../../../config/icon";
import { createUser, getPrivilege, uploadImage } from "../../../api";

import {
  checkValidity,
  validateCharLength,
  validateUserPassword,
} from "../../../utils";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      tags: [],
      tag: null,
      isLoading: false,
      isImageUploading: false,
      dataForm: {
        firstName: {
          key: "firstName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Damith",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "First name",
        },
        lastName: {
          key: "lastName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Premakumara",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Last name",
        },
        contactNo: {
          key: "contactNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : 0768 222 333",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Mobile no",
        },
        email: {
          key: "email",
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "Ex : info@creditcardoffer.lk",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Email address",
        },
        profileAvatar: {
          key: "profileAvatar",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : https://picsum.photos/200",
            disabled: false,
          },
          value: "https://avatars.dicebear.com/api/male/mamad.svg",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*******************",
          },
          value: "creditcard@123",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "New password",
        },
        confirmPassword: {
          key: "confirmPassword",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*******************",
          },
          value: "creditcard@123",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Confirm password",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    let res = await getPrivilege();
    console.log("getPrivilege", res);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "password":
      case "currentPassword":
      case "confirmPassword":
        updatedFormElement.value = validateCharLength(
          updatedFormElement.value,
          event.target.value.toString(),
          20
        );
        break;
      default:
        let newValue = "";

        if (event.target && event.target.value) {
          newValue = event.target.value.length === 0 ? "" : event.target.value;
        } else if (typeof event === "string") {
          newValue = event;
        }

        updatedFormElement.value = newValue;
        break;
    }

    let validityRes = checkValidity(
      inputIdentity,
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = validityRes.isValid;
    updatedFormElement.invalidReason = validityRes.reason;

    if (validityRes.isValid && inputIdentity === "confirmPassword") {
      //check if passwords match
      if (updateSignUpForm.password.value !== updatedFormElement.value) {
        updatedFormElement.valid = false;
        updatedFormElement.invalidReason = "Password not match";
      }
    }

    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async clearAll() {
    this.setState({
      isLoading: false,
    });

    // const updateForm = {...this.state.dataForm};
    // for (let key in updateForm) {
    //     console.log(updateForm[key]);
    //     updateForm[key].value = '';
    //     this.setState({dataForm: updateForm});
    // }
  }

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.uploadImage(result_base64);
  }

  async uploadImage(base64) {
    this.setState({ isImageUploading: true });
    let res = await uploadImage(base64, "privilege", "Privilege");
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      const updateForm = { ...this.state.dataForm };
      updateForm["profileAvatar"].value = res.data.URL;
      this.setState({ dataForm: updateForm });
      this.setState({ isImageUploading: false });
    } else {
      this.setState({ isImageUploading: true });
    }
  }

  //
  async onClickSaveData() {
    console.log("event fire");
    let dataForm = this.state.dataForm;

    this.updateFormValidity(dataForm.firstName.key);
    this.updateFormValidity(dataForm.lastName.key);
    this.updateFormValidity(dataForm.contactNo.key);
    this.updateFormValidity(dataForm.email.key);
    this.updateFormValidity(dataForm.password.key);

    if (
      dataForm.firstName.valid &&
      dataForm.contactNo.valid &&
      dataForm.lastName.valid &&
      dataForm.email.valid
    ) {
      // this.setState({isLoading: true});
      let res = await createUser(
        dataForm.firstName.value,
        dataForm.lastName.value,
        dataForm.contactNo.value,
        "1",
        "10",
        dataForm.email.value,
        dataForm.password.value,
        "https://avatars.dicebear.com/api/male/mamad.svg"
      );
      console.log("data", res.data);
      if (res && res.data && res.data.status) {
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
        // await this.clearAll();
      } else {
        OpenNotification("error", res && res.data && res.data.error, "error");
        this.setState({ isLoading: false });
        // await this.clearAll();
      }
    } else {
      OpenNotification("Error", "Invalid data", "error");
      //this.setState({isLoading: false});
    }
  }

  render() {
    const { dataForm, tags, isLoading, isImageUploading, viewPointHeight } =
      this.state;
    return (
      <div className={"cco__cnc-right-content-wrapper p0"}>
        <PageHeader option={"sm p-16"} title={"Create new privilege"} />
        <Scrollbars
          autoHide
          ref={(e) => (this.scrollbar = e)}
          renderView={(props) => (
            <div
              {...props}
              style={{
                ...props.style,
                paddingBottom: "16px",
                overflowX: "hidden",
              }}
            />
          )}
          style={{ height: viewPointHeight - 70 }}
        >
          <div className={"cco_common-form-content-wrapper "}>
            <div className={"group-form"}>
              <Input
                elementConfig={dataForm.firstName.elementConfig}
                elementType={dataForm.firstName.elementType}
                required={dataForm.firstName.validation.required}
                isinvalid={!dataForm.firstName.valid}
                touched={dataForm.firstName.touched}
                value={dataForm.firstName.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.firstName.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.firstName.key)
                }
              />
              <Input
                elementConfig={dataForm.lastName.elementConfig}
                elementType={dataForm.lastName.elementType}
                required={dataForm.lastName.validation.required}
                isinvalid={!dataForm.lastName.valid}
                touched={dataForm.lastName.touched}
                value={dataForm.lastName.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.lastName.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.lastName.key)
                }
              />
              <Input
                elementConfig={dataForm.contactNo.elementConfig}
                elementType={dataForm.contactNo.elementType}
                required={dataForm.contactNo.validation.required}
                isinvalid={!dataForm.contactNo.valid}
                touched={dataForm.contactNo.touched}
                value={dataForm.contactNo.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.contactNo.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.contactNo.key)
                }
              />
              <Input
                elementConfig={dataForm.email.elementConfig}
                elementType={dataForm.email.elementType}
                required={dataForm.email.validation.required}
                isinvalid={!dataForm.email.valid}
                touched={dataForm.email.touched}
                value={dataForm.email.value}
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.email.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.email.key)
                }
              />

              <Input
                elementConfig={dataForm.password.elementConfig}
                elementType={dataForm.password.elementType}
                required={dataForm.password.validation.required}
                isinvalid={!dataForm.password.valid}
                touched={dataForm.password.touched}
                value={dataForm.password.value}
                invalidReason={dataForm.password.invalidReason}
                isShowRequired={true}
                isRightIcon={true}
                rightIcon={GetIcon("upload")}
                onClickRightIcon={() =>
                  this.onClickRightIcon(dataForm.password.key)
                }
                isShowPasswordStrengthMeter={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.password.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.password.key)
                }
              />

              <Input
                elementConfig={dataForm.confirmPassword.elementConfig}
                elementType={dataForm.confirmPassword.elementType}
                required={dataForm.confirmPassword.validation.required}
                isinvalid={!dataForm.confirmPassword.valid}
                invalidReason={dataForm.confirmPassword.invalidReason}
                touched={dataForm.confirmPassword.touched}
                value={dataForm.confirmPassword.value}
                isRightIcon={false}
                onClickRightIcon={() =>
                  this.onClickRightIcon(dataForm.confirmPassword.key)
                }
                isShowRequired={true}
                size={"md"}
                margin={"m-b-2"}
                label={dataForm.confirmPassword.label}
                changed={(event) =>
                  this.inputHandleChange(event, dataForm.confirmPassword.key)
                }
              />
            </div>
            <div
              className={"login-footer-action-wrapper d-flex mt-4 pl-4 pr-4"}
            >
              <Button
                leftIcon={false}
                rightIcon={false}
                isLoading={isLoading}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("leftArrow")}
                onClick={async () => {
                  await this.onClickSaveData();
                }}
                text={"Done"}
                size={"md"}
                type={"btn-block"}
              />
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
