import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import * as actionTypes from "../../../store/actions/types";
import { OpenNotification } from "../../../config/notification";

import { Button, OfferDetailCard } from "../../../components";

import { Collapse, Select, Tabs } from "antd";
import { GetIcon } from "../../../config/icon";
import { bindActionCreators } from "redux";

const { Panel } = Collapse;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickConfirm = (_step) => {};

  render() {
    const {} = this.state;
    const { isConfirmOffer } = this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isConfirmOffer ? "show-me" : "hide-me"
        } `}
      >
        <div className={"coo_modal-body-content fix-550 d-flex flex-column"}>
          <div className={"coo_modal_banner_wrapper"}>
            <img
              src={
                "https://images.unsplash.com/photo-1470290449668-02dd93d9420a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
              }
            />
            <div className={"coo_modal-offer-title d-flex align-items-center"}>
              <div className={"coo_modal_merchant-logo"}>
                <img
                  src={
                    "https://images.unsplash.com/photo-1601158935942-52255782d322?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                  }
                />
              </div>
              <div className={"d-flex flex-column"}>
                <div className={"coo__offer-title"}>Hotel Name</div>
                <h1>Amagi Beach, Marawila - 50% off </h1>
              </div>
            </div>
            <div className={"coo__offer-tag"}>30% off</div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column"}>
            <OfferDetailCard
              type={"tag"}
              option={"d-flex flex-column text-left mb-2 "}
              description={null}
              tagList={[
                { id: 1, value: "Kalmunai" },
                { id: 1, value: "Marawila" },
                { id: 1, value: "Anuradhapura" },
              ]}
              title={"Offer valid location"}
            />

            <OfferDetailCard
              type={"tag"}
              option={"d-flex flex-column text-left mb-2 "}
              description={null}
              tagList={[
                { id: 1, value: "Visa" },
                { id: 1, value: "Debit" },
                { id: 1, value: "Master" },
              ]}
              title={"Valid card"}
            />
            <OfferDetailCard
              type={"text"}
              option={"d-flex flex-column text-left mb-2 mt-3"}
              description={
                "Buy 1 Get 1 FREE on selected 6-inch Subs every Monday with HSBC Credit Cards"
              }
              title={"Offer"}
            />
            <OfferDetailCard
              type={"text"}
              option={"d-flex flex-column text-left mb-2 mt-3"}
              description={"Valid from 01 April to 30 June 2021"}
              title={"Validity"}
            />
            <OfferDetailCard
              type={"text"}
              option={"d-flex flex-column text-left mb-2 mt-3"}
              description={
                "Enjoy 55% discount on double and triple room bookings on full board basis stays at Ahaspokuna Bush Walks \n" +
                "Camp, Belihuloya for all Sampath Mastercard, Visa Credit Cardholders and Sampath Bank \n" +
                "American Express® Platinum Ultramiles Credit Cardmembers."
              }
              title={"Offer Description"}
            />

            <div className={"login-footer-action-wrapper  d-flex mt-4"}>
              <Button
                leftIcon={false}
                rightIcon={false}
                option={"mr-1"}
                isLoading={false}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("leftArrow")}
                onClick={() => {
                  this.props.onClickMe();
                }}
                text={"Cancel"}
                size={"md"}
                type={"btn-block-back"}
              />
              <Button
                leftIcon={false}
                rightIcon={false}
                option={"ml-1"}
                isLoading={false}
                isActiveAnimation={false}
                reactRightIcon={GetIcon("leftArrow")}
                onClick={async () => {
                  await this.onClickSaveData();
                }}
                text={"Confirm"}
                size={"md"}
                type={"btn-block"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConfirmOffer: state.form.isConfirmOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickMe: () =>
      dispatch({ type: actionTypes.CLOSE_OFFER_CONFIRM_MODAL, status: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
