import ApiRoutes from "../../../constants/api-routes";
import { ccoInstance } from "../../../service";

export const cooCreateNewBank = async (body) =>
  ccoInstance.post(`${ApiRoutes.CREATE_NEW_BANK}`, body);

export const cooGetAllBank = async (storeId, pageNo, status) =>
  ccoInstance.get(`${ApiRoutes.GET_ALL_BANK}`);

export const cooGetBankById = async (bankId) =>
  ccoInstance.get(`${ApiRoutes.GET_BANK_BY_ID}/${bankId}`);
