import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { bindActionCreators } from "redux";
import { setValue } from "./redux/rich-textbox-action";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

//https://jpuri.github.io/react-draft-wysiwyg/#/demo
class RichTextComponent extends Component {
  newEditorState = EditorState.createEmpty();
  state = {
    editorState: this.newEditorState,
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.editOffer !== this.props.editOffer) {
      console.log("editOfferData changed:", this.props.editOffer.description);
      if (this.props.editOffer?.description) {
        //This code runs after the component updates, but only if `count` has changed.
        const contentBlock = htmlToDraft(this.props.editOffer.description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({ editorState });
        }
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    this.props.setValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            fontFamily: {
              options: ["gothambook"],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            fontSize: {
              icon: undefined,
              options: [11, 12, 13, 14, 18, 24],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
          }}
        />
        {/*draftToHtml(convertToRaw(editorState.getCurrentContent()))*/}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    value: state.richTextBox.value,
    editOfferData: state.offerReducer.editOfferData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setValue,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RichTextComponent);
