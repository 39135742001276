import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import { OpenNotification } from "../../../../config/notification";

import { Scrollbars } from "react-custom-scrollbars";

import {
  Button,
  ButtonIcon,
  CustomTag,
  GroupHeader,
  InfoRowView,
  Input,
  PageHeader,
  SelectBox,
  UpdateTermsButton,
  UploadCard,
} from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import {
  deleteMerchant,
  saveMerchant,
  updateMerchant,
  uploadImage,
} from "../../../../api";
import { cooGetAllCategory } from "../service/category.service";
import ReactCrop from "react-image-crop";
import * as actionTypes from "../../../../store/actions/types";
import { cooGetAllBank } from "../../../bank/service/bank.service";
import { cooConvertMerchantTerms } from "./service/merchant.service";
import { Popconfirm, Tabs } from "antd";
import { cooGetOfferByMerchant } from "../../../modal/ViewMerchantOffers/service/viewMerchantOffers.service";

class manual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      locations: [],
      location: null,
      favoriteArea: null,
      favoriteAreas: [],
      isLoading: false,
      fileName: null,
      isImageUploading: false,
      categoryOptions: [],
      bankOptions: [],
      cropBase64: null,
      src: null,
      isUploadImage: false,
      mobileList: [],
      emailList: [],
      error: [],
      user: null,
      crop: {
        unit: "px", // default, can be 'px' or '%'
        width: 100,
        x: 130,
        y: 50,
        aspect: 4 / 4,
      },
      eventImage: {
        imgBase64: null,
        fileName: null,
      },
      event: {
        base64: null,
      },
      isSelectBank: false,
      dataForm: {
        merchantName: {
          key: "merchantName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Merchant name",
        },
        BRno: {
          key: "BRno",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Business registration no",
        },
        webURL: {
          key: "webURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Web URL",
        },
        contactNo: {
          key: "contactNo",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            maxLength: 15,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Contact no",
        },
        userName: {
          key: "userName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "User Name",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
        keywords: {
          key: "keywords",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Merchant category",
        },
        bank: {
          key: "bank",
          elementType: "select",
          elementConfig: {
            type: "text",
            placeholder: "",
            options: [],
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Select bank",
        },
        location: {
          key: "location",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: " ",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Location",
        },
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
        mobileNo: {
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Mobile No",
            maxLength: 15,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          key: "mobileNo",
          label: "Mobile No",
        },
        email: {
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "Email",
          },
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: false,
          key: "email",
          label: "Email",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  checkValidity = (value, rules) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;

    if (rules.required) {
      isValid = value?.trim() !== "" && isValid;
    }

    if (rules.isEmail) {
      isValid = emailRegex?.test(value) && isValid;
    }

    return isValid;
  };

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  inputHandleChange = (event, key) => {
    const updatedForm = { ...this.state.dataForm };
    const updatedFormElement = { ...updatedForm[key] };
    updatedFormElement.value = event.target.value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedForm[key] = updatedFormElement;
    this.setState({ dataForm: updatedForm });
  };

  addToMobileList = () => {
    const mobileNo = this.state.dataForm.mobileNo.value;
    if (mobileNo) {
      if (!this.state.mobileList.some((item) => item.contact === mobileNo)) {
        const mobileEntry = {
          id: uuid(),
          type: "mobileNo",
          icon: "mobileNo",
          contact: mobileNo,
          isActive: true,
        };
        this.setState({ mobileList: [...this.state.mobileList, mobileEntry] });
        const updatedForm = { ...this.state.dataForm };
        updatedForm.mobileNo.value = "";
        this.setState({ dataForm: updatedForm, error: "" });
        console.log(`Mobile number ${mobileNo} added to the list.`);
      } else {
        console.log(
          `Mobile number ${mobileNo} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      this.setState({ error: "Mobile number cannot be empty." });
    }
  };

  addToEmailList = () => {
    const email = this.state.dataForm.email.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && emailRegex.test(email)) {
      if (!this.state.emailList.some((item) => item.contact === email)) {
        const emailEntry = {
          id: uuid(),
          type: "email",
          icon: "email",
          contact: email,
          isActive: true,
        };
        this.setState({ emailList: [...this.state.emailList, emailEntry] });
        const updatedForm = { ...this.state.dataForm };
        updatedForm.email.value = "";
        this.setState({ dataForm: updatedForm, error: "" });
        console.log(`Email ${email} added to the list.`);
      } else {
        console.log(
          `Email ${email} is already in the list. Ignoring duplicate.`
        );
      }
    } else {
      this.setState({
        error: email
          ? "Email is not in a valid format."
          : "Email cannot be empty.",
      });
    }
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    let res = await cooGetAllCategory();
    let resBank = await cooGetAllBank();
    const updateForm = { ...this.state.dataForm };
    console.log("componentDidMount", this.props.bankData);
    updateForm["keywords"].elementConfig.options = this.syncCategory(
      res && res.data && res.data.data
    );
    updateForm["bank"].elementConfig.options = this.syncBank(
      resBank && resBank.data && resBank.data.data
    );
    this.setState({ dataForm: updateForm });

    console.log("onload", res.data);
  }

  syncCategory(res) {
    let categoryList = [];
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
      });
    }
    return categoryList;
  }

  syncBank(res) {
    let bankList = [];
    for (let bank of res) {
      bankList.push({
        id: bank.bankId,
        value: bank.bankId,
        displayValue: bank.bankName,
      });
    }
    return bankList;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules, key) {
    let isValid = true;
    console.log("value", value);

    if (rules.required) {
      if (key === "contactNo") {
        isValid = value[0]?.trim() !== "" && isValid;
      } else {
        isValid = value && value?.trim() !== "" && isValid;
      }
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      updatedFormElement.key
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "keywords":
        val = event && event.target ? event.target.value : event;
        let data = updateForm.keywords.elementConfig.options.filter(
          (x) => x.value === val
        );
        this.setState((state) => {
          if (val) {
            const favoriteAreas = state.favoriteAreas.concat({
              id: data && data[0].id,
              value: data && data[0].value,
            });
            return {
              favoriteAreas,
            };
          } else {
            const favoriteAreas = [];
            return {
              favoriteAreas,
            };
          }
        });
        updatedFormElement.value = val;
        break;
      case "bank":
        val = event && event.target ? event.target.value : event;
        updateForm.bank.elementConfig.options.filter((x) => x.value === val);
        updatedFormElement.value = val;
        break;
      default:
        updatedFormElement.value = event.target.value;
    }

    if (inputIdentity === "location") {
      let _event = event && event.target ? event.target.value : event;
      console.log("event", _event);
      this.setState({
        location: _event,
      });
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  async clearAll() {
    this.setState({
      locations: [],
      favoriteAreas: [],
      mobileList: [],
      emailList: [],
      location: null,
      favoriteArea: null,
      isLoading: false,
    });

    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      if (updateForm.hasOwnProperty(key)) {
        console.log(updateForm[key]);
        updateForm[key].value = "";
      }
    }

    this.props.onClearTerms();
    this.setState({ dataForm: updateForm });
  }

  async resetAll() {
    this.props.clearSelectMerchant();
    this.clearAll();
    this.props.refreshMerchantList();
  }

  async onClickSaveData() {
    console.log("event fire");
    let dataForm = this.state.dataForm;
    let state = this.state;

    this.updateFormValidity(dataForm.merchantName.key);
    this.updateFormValidity(dataForm.contactNo.key);
    this.updateFormValidity(dataForm.logoURL.key);

    if (state.favoriteAreas.length === 0) {
      this.updateFormValidity(dataForm.keywords.key);
    }
    if (state.locations.length === 0) {
      this.updateFormValidity(dataForm.location.key);
    }

    if (
      dataForm.merchantName.valid &&
      dataForm.contactNo.valid &&
      dataForm.logoURL.valid
    ) {
      this.setState({ isLoading: true });
      let hotLine = {
        mobileNo: state.mobileList,
        email: state.emailList,
      };
      let res = await saveMerchant(
        dataForm.merchantName.value,
        dataForm.contactNo.value,
        dataForm.logoURL.value,
        dataForm.webURL.value,
        this.state.favoriteAreas,
        this.state.locations,
        hotLine,
        this.props.termsAndConditionList
      );
      console.log(res.data);
      if (res && res.data && res.data.status) {
        if (this.props.isOpenCreateNewMerchant) {
          this.props.closeCreateMerchantModal(res?.data?.data);
          this.props.onLoadTermsAndCondition(res?.data?.data.termsAndCondition);
        }
        OpenNotification(
          "success",
          res && res.data && res.data.message,
          "success"
        );
        await this.resetAll();
      } else {
        OpenNotification("error", res && res.data && res.data.error, "error");
        this.setState({ isLoading: false });
        // await this.clearAll();
      }
    } else {
      OpenNotification("Error", "Invalid data", "error");
      this.setState({ isLoading: false });
    }
  }

  async onClickUpdateMerchant() {
    console.log("onClickUpdateMerchant", this.props.termsAndConditionList);
    let dataForm = this.state.dataForm;
    let state = this.state;
    const termsAndConditionList = this.props.termsAndConditionList;
    const selectMerchant = this.props.selectMerchantData;
    if (selectMerchant && selectMerchant?.merchantId) {
      this.updateFormValidity(dataForm.merchantName.key);
      this.updateFormValidity(dataForm.contactNo.key);
      this.updateFormValidity(dataForm.logoURL.key);

      if (state.favoriteAreas.length === 0) {
        this.updateFormValidity(dataForm.keywords.key);
      }
      if (state.locations.length === 0) {
        this.updateFormValidity(dataForm.location.key);
      }

      if (
        dataForm.merchantName.valid &&
        dataForm.contactNo.valid &&
        dataForm.logoURL.valid
      ) {
        this.setState({ isLoading: true });
        let body = {
          merchantName: dataForm.merchantName.value,
          contactNo: dataForm.contactNo.value,
          logoURL: dataForm.logoURL.value,
          webURL: dataForm.webURL.value,
          favoriteArea: this.state.favoriteAreas,
          termsAndCondition: termsAndConditionList || {},
          location: this.state.locations,
          hotLine: {
            mobileNo: state.mobileList,
            email: state.emailList,
          },
          description: null,
          userName: dataForm.userName.value,
          password: dataForm.password.value,
        };
        console.log("update_body", body);

        let res = await updateMerchant(body, selectMerchant?.merchantId);
        console.log(res.data);
        if (res && res.data && res.data.status) {
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
          await this.resetAll();
          this.setState({ isLoading: false });
        } else {
          OpenNotification("error", res && res.data && res.data.error, "error");
          this.setState({ isLoading: false });
          // await this.clearAll();
        }
      } else {
        OpenNotification("Error", "Invalid data", "error");
        this.setState({ isLoading: false });
      }
    } else {
      OpenNotification("Error", "Update error", "error");
      this.setState({ isLoading: false });
    }
  }

  //onClick add #tags
  onClickAddHashTag = () => {
    const dataForm = {
      ...this.state.dataForm,
    };

    dataForm.this.setState((state) => {
      if (dataForm.keywords.value) {
        const favoriteAreas = state.favoriteAreas.concat({
          id: uuid(),
          tagName: dataForm.keywords.value,
          count: 0,
        });
        return {
          favoriteAreas,
          favoriteArea: null,
        };
      }
    });

    const updateForm = { ...this.state.dataForm };
    updateForm["keywords"].value = "";
  };

  onRemoveHashTag = (i) => {
    console.log("remove event fire...." + i);
    this.setState((state) => {
      const favoriteAreas = state.favoriteAreas.filter((item, j) => i !== j);
      return {
        favoriteAreas,
      };
    });
  };

  handleKeyPress = (event, key) => {
    if (event.key === "Enter") {
      // this.onClickAddHashTag();
      this.onClickAddLocation();
    }
  };

  //onClick add #tags
  onClickAddLocation = () => {
    this.setState((state) => {
      console.log("location", state.location);
      if (state.location) {
        const locations = state.locations.concat({
          id: uuid(),
          tagName: state.location,
          count: 0,
        });
        return {
          locations,
          location: null,
        };
      }
    });

    const updateForm = { ...this.state.dataForm };
    updateForm["location"].value = "";
  };

  onRemoveLocation = (i) => {
    console.log("remove event fire...." + i);
    this.setState((state) => {
      const locations = state.locations.filter((item, j) => i !== j);
      return {
        locations,
      };
    });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    this.setState({
      cropBase64: base64Image,
    });
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async toBase64() {
    const file = document.querySelector("#eventImageUpload").files[0];
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    this.uploadImage(result_base64);
  }

  async uploadImage() {
    this.setState({ isImageUploading: true });
    let res = await uploadImage(this.state.cropBase64, "merchant", "Merchant");
    console.log("Imageupload", res);
    if (res && res.data && res.data.status) {
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = res.data.data.URL;
      this.setState({ dataForm: updateForm });
      this.setState({ isImageUploading: false, src: null });
    } else {
      this.setState({ isImageUploading: true });
    }
  }

  onSelectFile = (e) => {
    console.log("event fire...");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      const imageRefBase64 = this.imageRef.src;
      this.setState({
        base64: imageRefBase64,
        croppedImageUrl,
      });
    }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onClickClearImage() {
    const updateForm = { ...this.state.dataForm };
    updateForm["logoURL"].value = "";
    this.setState({ dataForm: updateForm });
    this.setState({ isImageUploading: false, src: null });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectMerchantData !== this.props.selectMerchantData) {
      await this.clearAll();
      const selectMerchant = this.props.selectMerchantData;

      if (selectMerchant && selectMerchant?.merchantId) {
        const updateForm = { ...this.state.dataForm };

        updateForm["webURL"].value = selectMerchant?.webURL;
        updateForm["logoURL"].value = selectMerchant?.logoURL;
        updateForm["BRno"].value = selectMerchant?.BRno;
        updateForm["contactNo"].value = selectMerchant?.contactNo;
        updateForm["merchantName"].value = selectMerchant?.merchantName;
        updateForm["userName"].value = selectMerchant?.userName;
        updateForm["password"].value = selectMerchant?.password;

        const locations = [
          ...this.state.locations,
          ...selectMerchant?.location?.map((location) => ({
            id: location.id,
            tagName: location.tagName,
            count: location.count,
          })),
        ];

        const favoriteAreas = [
          ...this.state.favoriteAreas,
          ...selectMerchant?.favoriteArea?.map((area) => ({
            id: area.id,
            value: area.value,
            count: area.count,
          })),
        ];
        if (selectMerchant?.termsAndCondition) {
          console.log("terms get:::", selectMerchant?.termsAndCondition);
          this.props.onLoadTermsAndCondition(selectMerchant?.termsAndCondition);
        }
        if (selectMerchant?.hotLine) {
          this.setState({
            mobileList: selectMerchant?.hotLine?.mobileNo,
            emailList: selectMerchant?.hotLine?.email,
          });
        }

        this.setState({ dataForm: updateForm, locations, favoriteAreas });
      }
    }
  }

  validateTermsAndConditionList = () => {
    return Object.keys(this.props.termsAndConditionList).length > 0;
  };

  viewTermsAndCondition = () => {
    const hasTerms =
      this.props.termsAndConditionList &&
      typeof this.props.termsAndConditionList === "object" &&
      Object.keys(this.props.termsAndConditionList).length > 0;

    console.log("hasTerms", hasTerms);
    console.log("termsAndConditionList", this.props.termsAndConditionList);
    return (
      <div>
        {hasTerms ? (
          Object.keys(this.props.termsAndConditionList)?.map((key) => (
            <div>
              {hasTerms ? (
                Object.keys(this.props.termsAndConditionList).map((key) => (
                  <div key={key}>
                    <h3>Bank ID: {key}</h3>
                    {this.props.termsAndConditionList[key].map((item) => (
                      <div key={item.id}>
                        <div>{item.value}</div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>No terms and conditions available</p>
              )}
            </div>
          ))
        ) : (
          <p>No terms and conditions available</p>
        )}
      </div>
    );
  };

  isArray = (value) => {
    return Array.isArray(value);
  };

  getBankName = (bankId) => {
    const bank = this.props.bankData?.find((bank) => bank.bankId === bankId);
    return bank ? bank?.bankName : "Unknown Bank";
  };

  async onClickOpenMerchantOffers() {
    this.props.onClickUnloadMerchantOffers();
    const selectMerchant = this.props.selectMerchantData;
    let resOfferByMerchant = await cooGetOfferByMerchant(
      selectMerchant?.merchantId
    );
    if (resOfferByMerchant?.data?.data) {
      this.props.onClickLoadMerchantOffers(resOfferByMerchant?.data?.data);
      this.props.onClickOpenViewMerchantOffers();
    }
  }

  //   const resOfferByMerchant =
  //       await cooGetOfferByMerchant(item.merchantId);
  //   if (
  //       resOfferByMerchant &&
  //   resOfferByMerchant.data &&
  //   resOfferByMerchant.data.status
  // ) {
  //   this.setState({
  //                   recentOffers:
  //                       resOfferByMerchant &&
  //   resOfferByMerchant.data.data,
  // });
  // }
  // }}

  render() {
    const {
      dataForm,
      favoriteAreas,
      isLoading,
      isImageUploading,
      locations,
      categoryOptions,
      bankOptions,
      viewPointHeight,
      isEnableEdit,
      crop,
      croppedImageUrl,
      src,
      isUploadImage,
      isSelectBank,
    } = this.state;
    const {
      selectMerchantData,
      numOfOfferByMerchant,
      bankData,
      termsAndConditionList,
    } = this.props;

    const { TabPane } = Tabs;
    return (
      <>
        {/*----- image crop section wrapper ------*/}
        {src && (
          <div className={"image-crop-area-wrapper"}>
            <div className={"col-lg-12"}>
              <div
                className={"modal-body-wrapper"}
                style={{ height: this.props.viewPointHeight - 100 }}
              >
                <div
                  className={"m-header-wrapper d-flex justify-content-between"}
                >
                  <div className={"m-h-title"}>Merchant Logo Upload</div>
                  <div
                    className={"m-h-close"}
                    onClick={() => this.onClickCloseUploadImage()}
                  >
                    Close
                  </div>
                </div>
                <div
                  className={
                    "image-crop-preview d-flex align-items-center justify-content-center"
                  }
                >
                  {/*<div className={'profile-logo-view-wrapper'}>*/}
                  {/*    {croppedImageUrl && (*/}
                  {/*        <img alt="Crop" src={croppedImageUrl}/>*/}
                  {/*    )}*/}
                  {/*</div>*/}
                  <div className={"upload-action-wrapper"}>
                    <div className={"profile-logo-wrapper ml-2 d-flex"}>
                      <div className={`BRattachment-wrapper light`}>
                        <>
                          <input
                            id="uploadNew"
                            type="file"
                            accept="image/*"
                            onChange={this.onSelectFile}
                            hidden
                          />
                          <div
                            className={"d-flex"}
                            onClick={() => {
                              document.getElementById("uploadNew").click();
                            }}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Upload New Image</div>
                          </div>
                        </>
                      </div>

                      <div className={"BRattachment-wrapper ml-3 light"}>
                        {!isImageUploading && (
                          <div
                            className={"d-flex"}
                            onClick={async () => {
                              await this.uploadImage();
                            }}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Save</div>
                          </div>
                        )}

                        {isImageUploading && (
                          <div className={"d-flex"}>
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Uploading...</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {src && (
                  <div
                    className={
                      "d-flex align-item-center justify-content-center"
                    }
                  >
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={"cco__cnc-right-content-wrapper p0 "}>
          <Scrollbars
            style={{ height: viewPointHeight - 140 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <PageHeader option={"sm p-16"} title={"Create new Merchant"} />

            <div className={"cco_common-form-content-wrapper "}>
              <>
                {selectMerchantData?.merchantId && (
                  <div className={"merchant-offer-count "}>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex flex-column">
                        <div>
                          No of offers : <b>{numOfOfferByMerchant}</b>
                        </div>
                        <div>
                          Merchant Id : {selectMerchantData?.merchantId}
                        </div>
                      </div>

                      {/*<div>*/}
                      {/*  {numOfOfferByMerchant > 0 && (*/}
                      {/*    <>*/}
                      {/*      <Button*/}
                      {/*        text={"View"}*/}
                      {/*        onClick={() => this.onClickOpenMerchantOffers()}*/}
                      {/*        type={"btn-edit"}*/}
                      {/*      />*/}
                      {/*    </>*/}
                      {/*  )}*/}
                      {/*</div>*/}
                    </div>

                    <div className={"merchant-action-wrapper mt-2"}>
                      {this.props.userData?.userType != "user" && (
                        <Popconfirm
                          title="Are you sure you want to remove this merchant?"
                          description="Are you sure to remove this merchant?"
                          placement="topRight"
                          icon={GetIcon("warning")}
                          onConfirm={async () => {
                            if (selectMerchantData?.merchantId) {
                              this.setState({ isLoading: true });
                              let res = await deleteMerchant(
                                selectMerchantData?.merchantId
                              );
                              console.log(res.data);
                              if (res && res.data && res.data.status) {
                                OpenNotification(
                                  "success",
                                  res && res.data && res.data.message,
                                  "success"
                                );
                                this.setState({ isLoading: false });
                                this.resetAll();
                                this.props.refreshMerchantList();
                              } else {
                                OpenNotification(
                                  "error",
                                  res && res.data && res.data.error,
                                  "error"
                                );
                                this.setState({ isLoading: false });
                                // await this.clearAll();
                              }
                            }
                          }}
                          onCancel={async () => {
                            await this.resetAll();
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          {!Number.isFinite(numOfOfferByMerchant) && (
                            <Button
                              text={"Delete"}
                              type={"btn-delete"}
                              size={"sm secondary mr-2"}
                              onClick={async () => {
                                // let resBank = await cooGetAllBank();
                                // this.props.setOnLoadBank(resBank?.data?.data);
                                // this.props.openTermsAndCondition();
                              }}
                            />
                          )}
                        </Popconfirm>
                      )}
                      {selectMerchantData?.merchantId && (
                        <Button
                          text={"Cancel edit"}
                          type={"btn-edit"}
                          size={"sm secondary mr-2"}
                          onClick={async () => {
                            this.props.refreshMerchantList();
                            await this.clearAll();
                            this.props.onClearTerms();
                            // let resBank = await cooGetAllBank();
                            // this.props.setOnLoadBank(resBank?.data?.data);
                            // this.props.openTermsAndCondition();
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>

              <div className={"group-form"}>
                <UploadCard
                  title={"Basic profile"}
                  onSelectFile={this.onSelectFile.bind(this)}
                  isImageUploading={isImageUploading}
                  logoURL={dataForm.logoURL.value}
                />

                {dataForm.logoURL.value && (
                  <div className={"cco__cnc-image-merchant-wrapper mb-4"}>
                    <img src={dataForm.logoURL.value} />
                    <div className={"cco__image-delete-wrapper"}>
                      <ButtonIcon
                        onClick={() => this.onClickClearImage()}
                        buttonIcon={GetIcon("close")}
                        type={"close"}
                        size={"md"}
                      />
                    </div>
                  </div>
                )}

                <Input
                  elementConfig={dataForm.logoURL.elementConfig}
                  elementType={dataForm.logoURL.elementType}
                  required={dataForm.logoURL.validation.required}
                  isinvalid={!dataForm.logoURL.valid}
                  touched={dataForm.logoURL.touched}
                  value={dataForm.logoURL.value}
                  isShowRequired={false}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.logoURL.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.logoURL.key)
                  }
                />

                <Input
                  elementConfig={dataForm.merchantName.elementConfig}
                  elementType={dataForm.merchantName.elementType}
                  required={dataForm.merchantName.validation.required}
                  isinvalid={!dataForm.merchantName.valid}
                  touched={dataForm.merchantName.touched}
                  value={dataForm.merchantName.value}
                  isShowRequired={true}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.merchantName.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.merchantName.key)
                  }
                />
                <Input
                  elementConfig={dataForm.contactNo.elementConfig}
                  elementType={dataForm.contactNo.elementType}
                  required={dataForm.contactNo.validation.required}
                  isinvalid={!dataForm.contactNo.valid}
                  touched={dataForm.contactNo.touched}
                  value={dataForm.contactNo.value}
                  isShowRequired={true}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.contactNo.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.contactNo.key)
                  }
                />

                <Input
                  elementConfig={dataForm.webURL.elementConfig}
                  elementType={dataForm.webURL.elementType}
                  required={dataForm.webURL.validation.required}
                  isinvalid={!dataForm.webURL.valid}
                  touched={dataForm.webURL.touched}
                  value={dataForm.webURL.value}
                  isShowRequired={false}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.webURL.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.webURL.key)
                  }
                />
              </div>

              {/*Search keywords*/}
              <div className={"group-form"}>
                <GroupHeader option={"mt-1 mb-2"} title={"Search keywords"} />
                <Input
                  elementConfig={dataForm.keywords.elementConfig}
                  elementType={dataForm.keywords.elementType}
                  required={dataForm.keywords.validation.required}
                  isinvalid={!dataForm.keywords.valid}
                  touched={dataForm.keywords.touched}
                  value={dataForm.keywords.value}
                  isShowRequired={true}
                  size={"sm"}
                  margin={"m-b-2"}
                  options={categoryOptions}
                  label={dataForm.keywords.label}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onClickAddTag={this.onClickAddHashTag.bind(this)}
                  changed={(event) => {
                    this.inputHandleChange(event, dataForm.keywords.key);
                  }}
                />
                {favoriteAreas && (
                  <>
                    <div className={"multiple-line-wrapper mb-2"}>
                      <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                        {(favoriteAreas || []).map((item, manual) => (
                          <CustomTag
                            manual={manual}
                            remove={true}
                            onRemoveTime={() => this.onRemoveHashTag(manual)}
                            format={"text"}
                            text={item.value}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )}

                <Input
                  elementConfig={dataForm.location.elementConfig}
                  elementType={dataForm.location.elementType}
                  required={dataForm.location.validation.required}
                  isinvalid={!dataForm.location.valid}
                  touched={dataForm.location.touched}
                  value={dataForm.location.value}
                  isShowRequired={true}
                  size={"md"}
                  margin={"m-b-2"}
                  label={dataForm.location.label}
                  onKeyPress={this.handleKeyPress.bind(this)}
                  onClickAddTag={this.onClickAddLocation.bind(this)}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.location.key)
                  }
                />

                {locations && (
                  <>
                    <div className={"multiple-line-wrapper mb-2"}>
                      <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                        {(locations || []).map((item, manual) => (
                          <CustomTag
                            manual={manual}
                            remove={true}
                            onRemoveTime={() => this.onRemoveLocation(manual)}
                            format={"text"}
                            text={item.tagName}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/*Reservation Contacts*/}
              <div className={"group-form"}>
                <GroupHeader option={"mt-1 mb-2"} title={"Search keywords"} />
                <div className="mt-1">
                  <div className="shw_textWrapper d-flex flex-row align-items-center ">
                    <Input
                      elementConfig={dataForm.mobileNo.elementConfig}
                      elementType={dataForm.mobileNo.elementType}
                      required={dataForm.mobileNo.validation.required}
                      isValid={!dataForm.mobileNo.valid}
                      touched={dataForm.mobileNo.touched}
                      value={dataForm.mobileNo.value}
                      isShowRequired={false}
                      size={"sm"}
                      margin={"m-b-1 m-t-2"}
                      label={dataForm.mobileNo.label}
                      changed={(event) =>
                        this.inputHandleChange(event, dataForm.mobileNo.key)
                      }
                    />
                    <div className="action-bar-right d-flex mt-4 ml-2">
                      <Button
                        text={"Add to list"}
                        type={"btn-edit"}
                        size={"sm primary"}
                        onClick={this.addToMobileList}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={"d-flex"}>
                      {this.state.mobileList?.map((mobile, index) => (
                        // <div key={index}>{mobile.contact}</div>
                        <SelectBox
                          index={index}
                          remove={false}
                          isSelect={true}
                          select={true}
                          // onClick={() => this.onSelectLocation(item, index)}
                          onRemove={() => {
                            if (mobile.contact) {
                              this.setState({
                                mobileList: this.state.mobileList.filter(
                                  (item, i) => i !== index
                                ),
                              });
                            }
                          }}
                          format={"text"}
                          text={mobile.contact}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="shw_textWrapper d-flex flex-row align-items-center">
                    <Input
                      elementConfig={dataForm.email.elementConfig}
                      elementType={dataForm.email.elementType}
                      required={dataForm.email.validation.required}
                      isValid={!dataForm.email.valid}
                      touched={dataForm.email.touched}
                      value={dataForm.email.value}
                      isShowRequired={false}
                      size={"sm"}
                      margin={"m-b-1 m-t-2"}
                      label={dataForm.email.label}
                      changed={(event) =>
                        this.inputHandleChange(event, dataForm.email.key)
                      }
                    />
                    <div className="action-bar-right d-flex mt-4 ml-2">
                      <Button
                        text={"Add to list"}
                        type={"btn-edit"}
                        size={"sm primary"}
                        onClick={this.addToEmailList}
                      />
                    </div>
                  </div>
                  <div className={"d-flex"}>
                    {this.state.emailList?.map((email, index) => (
                      // <div key={index}>{email.contact}</div>
                      <SelectBox
                        index={index}
                        remove={false}
                        isSelect={true}
                        select={true}
                        // onClick={() => this.onSelectLocation(item, index)}
                        onRemove={() => {
                          if (email.contact) {
                            this.setState({
                              emailList: this.state.emailList.filter(
                                (item, i) => i !== index
                              ),
                            });
                          }
                        }}
                        format={"text"}
                        text={email.contact}
                      />
                    ))}
                  </div>
                </div>
              </div>

              {/*Public profile*/}
              {/*<div className={"group-form"}>*/}
              {/*  <GroupHeader option={"mt-1 mb-2"} title={"Public profile"}/>*/}
              {/*  <Input*/}
              {/*      elementConfig={dataForm.BRno.elementConfig}*/}
              {/*      elementType={dataForm.BRno.elementType}*/}
              {/*      required={dataForm.BRno.validation.required}*/}
              {/*      isinvalid={!dataForm.BRno.valid}*/}
              {/*      touched={dataForm.BRno.touched}*/}
              {/*      value={dataForm.BRno.value}*/}
              {/*      isShowRequired={false}*/}
              {/*      size={"md"}*/}
              {/*      margin={"m-b-2"}*/}
              {/*      label={dataForm.BRno.label}*/}
              {/*      changed={(event) =>*/}
              {/*          this.inputHandleChange(event, dataForm.BRno.key)*/}
              {/*      }*/}
              {/*  />*/}
              {/*  <Input*/}
              {/*      elementConfig={dataForm.userName.elementConfig}*/}
              {/*      elementType={dataForm.userName.elementType}*/}
              {/*      required={dataForm.userName.validation.required}*/}
              {/*      isinvalid={!dataForm.userName.valid}*/}
              {/*      touched={dataForm.userName.touched}*/}
              {/*      value={dataForm.userName.value}*/}
              {/*      isShowRequired={false}*/}
              {/*      size={"md"}*/}
              {/*      margin={"m-b-2"}*/}
              {/*      label={dataForm.userName.label}*/}
              {/*      changed={(event) =>*/}
              {/*          this.inputHandleChange(event, dataForm.userName.key)*/}
              {/*      }*/}
              {/*  />*/}
              {/*  <Input*/}
              {/*      elementConfig={dataForm.password.elementConfig}*/}
              {/*      elementType={dataForm.password.elementType}*/}
              {/*      required={dataForm.password.validation.required}*/}
              {/*      isinvalid={!dataForm.password.valid}*/}
              {/*      touched={dataForm.password.touched}*/}
              {/*      value={dataForm.password.value}*/}
              {/*      isShowRequired={false}*/}
              {/*      size={"md"}*/}
              {/*      margin={"m-b-2"}*/}
              {/*      label={dataForm.password.label}*/}
              {/*      changed={(event) =>*/}
              {/*          this.inputHandleChange(event, dataForm.password.key)*/}
              {/*      }*/}
              {/*  />*/}
              {/*</div>*/}

              {/*terms and conditions */}
              <div className={"group-form"}>
                <div className={"d-flex justify-content-between"}>
                  <GroupHeader
                    option={"mt-1 mb-2"}
                    title={"Terms and condition"}
                  />
                  <div className={"d-flex"}>
                    <Button
                      text={"Add new"}
                      type={"btn-edit"}
                      size={"sm secondary mr-2"}
                      onClick={async () => {
                        let resBank = await cooGetAllBank();
                        this.props.setOnLoadBank(resBank?.data?.data);
                        this.props.openTermsAndCondition(
                          selectMerchantData?.termsAndCondition
                        );
                      }}
                    />

                    {/*//todo*/}
                    {this.isArray(selectMerchantData?.termsAndCondition) &&
                      selectMerchantData?.termsAndCondition?.length > 0 && (
                        <Button
                          text={
                            dataForm.bank.value
                              ? "update version"
                              : "Select bank"
                          }
                          type={"btn-edit"}
                          size={"sm secondary mr-2"}
                          onClick={async () => {
                            const body = {
                              termsAndCondition:
                                selectMerchantData?.termsAndCondition,
                              bankId: null,
                            };
                            console.log({
                              termsAndCondition:
                                selectMerchantData?.termsAndCondition,
                            });
                            console.log({
                              istermsAndCondition: this.isArray(
                                selectMerchantData?.termsAndCondition
                              ),
                            });

                            if (dataForm.bank.value) {
                              body.bankId = dataForm.bank.value;
                              let resBank = await cooConvertMerchantTerms(
                                body,
                                selectMerchantData?.merchantId
                              );
                              OpenNotification(
                                "success",
                                "Updated terms and condition",
                                "success"
                              );
                              this.resetAll();
                            } else {
                              this.setState({ isSelectBank: true });
                            }

                            // this.props.setOnLoadBank(resBank?.data?.data);
                            // this.props.openTermsAndCondition();
                          }}
                        />
                      )}
                  </div>
                </div>
                <div className={"select__bank"}>{dataForm.bank.value}</div>

                <div className={"t&c__viwer_wrapper"}>
                  {/*{this.viewTermsAndCondition()}*/}
                  <div className={"list-view-terms_condition mt-3"}>
                    {isSelectBank && (
                      <div className={"select-bank_wrapper"}>
                        <div className={"sb__title"}>Select bank</div>
                        <div className={"sb__body"}>
                          <Input
                            elementConfig={dataForm.bank.elementConfig}
                            elementType={dataForm.bank.elementType}
                            required={dataForm.bank.validation.required}
                            isinvalid={!dataForm.bank.valid}
                            touched={dataForm.bank.touched}
                            value={dataForm.bank.value}
                            isShowRequired={true}
                            size={"sm"}
                            margin={"m-b-2"}
                            options={bankOptions}
                            label={dataForm.bank.label}
                            onKeyPress={this.handleKeyPress.bind(this)}
                            onClickAddTag={this.onClickAddHashTag.bind(this)}
                            changed={(event) => {
                              this.inputHandleChange(event, dataForm.bank.key);
                            }}
                          />
                        </div>
                        <div className={"sb__footer d-flex"}>
                          <Button
                            text={"Cancel"}
                            type={"btn-block-back"}
                            size={"md mr-2"}
                            onClick={async () => {
                              this.setState({ isSelectBank: false });
                            }}
                          />
                          {dataForm.bank.value && (
                            <Button
                              text={"Done"}
                              type={"btn-block"}
                              size={"md mr-2"}
                              onClick={async () => {
                                this.setState({ isSelectBank: false });
                                console.log(dataForm.bank.value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {this.isArray(this.props.termsAndConditionList) ? (
                      <>
                        {this.props.termsAndConditionList &&
                          (this.props.termsAndConditionList || []).map(
                            (item, index) => (
                              <InfoRowView
                                type="default"
                                text={item.value}
                                isSelect={item.isActive}
                                style={
                                  "mb-1 justify-content-between align-items-center"
                                }
                              />
                            )
                          )}
                      </>
                    ) : (
                      <div>
                        <div className={"cco__mtc-tab mt-2"}>
                          <Tabs defaultActiveKey="1">
                            {this.props.termsAndConditionList &&
                              typeof this.props.termsAndConditionList ===
                                "object" &&
                              Object.keys(this.props.termsAndConditionList).map(
                                (bankId) => (
                                  <TabPane
                                    tab={this.getBankName(bankId)}
                                    key={bankId}
                                  >
                                    <div className={`cco__terms-tab-wrapper`}>
                                      {this.props.termsAndConditionList[
                                        bankId
                                      ].map((term) => (
                                        <InfoRowView
                                          key={term.id}
                                          type="default"
                                          text={term.value}
                                          isSelect={term.isActive}
                                          style={
                                            "mb-1 justify-content-between align-items-center"
                                          }
                                        />
                                      ))}
                                    </div>
                                  </TabPane>
                                )
                              )}
                          </Tabs>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>

          <div className={"login-footer-action-wrapper pl-4 pr-4 d-flex mt-4"}>
            {selectMerchantData?.merchantId && (
              <Button
                text={"Cancel"}
                type={"btn-block-back"}
                size={"md mr-2"}
                onClick={async () => {
                  this.resetAll();
                  // let resBank = await cooGetAllBank();
                  // this.props.setOnLoadBank(resBank?.data?.data);
                  // this.props.openTermsAndCondition();
                }}
              />
            )}
            <Button
              leftIcon={false}
              rightIcon={false}
              isLoading={isLoading || isImageUploading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={async () => {
                console.log("termsAndConditionList", termsAndConditionList);
                if (selectMerchantData && selectMerchantData?.merchantId) {
                  await this.onClickUpdateMerchant();
                } else {
                  await this.onClickSaveData();
                }
              }}
              text={
                selectMerchantData && selectMerchantData?.merchantId
                  ? "Update"
                  : "Done"
              }
              size={"md"}
              type={"btn-block"}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectMerchantData: state.merchantReducer.selectMerchantData,
  isOpenCreateNewMerchant: state.merchantReducer.isOpenCreateNewMerchant,
  numOfOfferByMerchant: state.offerReducer.numOfOfferByMerchant,
  termsAndConditionList: state.termsReducer.termsAndConditionList,
  bankData: state.bankReducer.bankData,
  userData: state.userReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    refreshMerchantList: () =>
      dispatch({ type: actionTypes.REFRESH_MERCHANT_LIST }),
    openTermsAndCondition: (payload) =>
      dispatch({
        type: actionTypes.OPEN_CREATE_NEW_TERMS_AND_CONDITIONS_MODAL,
        payload: payload,
      }),
    onLoadTermsAndCondition: (termsAndCondition) =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFERS_TERMS_AND_CONDITION,
        payload: termsAndCondition,
      }),
    clearSelectMerchant: () =>
      dispatch({ type: actionTypes.CLEAR_SELECTED_MERCHANT }),
    setOnLoadBank: (bankData) =>
      dispatch({ type: actionTypes.SET_BANK_DATA, payload: bankData }),

    onClickOpenViewMerchantOffers: (payload) =>
      dispatch({
        type: actionTypes.OPEN_VIEW_MERCHANT_OFFERS_MODAL,
        payload: payload,
      }),
    onClearTerms: () =>
      dispatch({
        type: actionTypes.CLEAR_TERMS_AND_CONDITION,
      }),
    onClickLoadMerchantOffers: (payload) =>
      dispatch({
        type: actionTypes.ON_LOAD_MERCHANT_HAS_OFFERS,
        payload: payload,
      }),
    closeCreateMerchantModal: (payload) => {
      dispatch({
        type: actionTypes.CLOSE_CREATE_NEW_MERCHANT,
        payload: payload,
      });
    },
    onClickUnloadMerchantOffers: (payload) =>
      dispatch({
        type: actionTypes.CLEAR_ON_LOAD_MERCHANT_HAS_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(manual);
