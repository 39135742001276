import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import moment from "moment";
import {
  Button,
  ButtonIcon,
  FileUpload,
  ImpressionCard,
  Input,
  PaymentDetails,
  ProfileTitle,
  Tag,
} from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import {
  getSessionValidateStatus,
  getUserById,
  updateOfferStatus,
} from "../../../api";
import { getToken, userDetails } from "../../../utils";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      offerDataSource: [],
      userDataSource: [],
      dataForm: {
        comment: {
          label: "Comment",
          key: "Comment",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);

    let state = await userDetails();
    let sessionStatus = await getSessionValidateStatus();
    console.log("sessionStatus", sessionStatus);
    if (sessionStatus?.data?.status) {
      console.log("state::::", state);
      if (state && state.userId) {
        let res = await getUserById(state?.userId);
        console.log("getUserById", res);
        if (res && res.status) {
          const token = await getToken();
          let user = res.data;
          let data = {
            ...user,
            token: token,
          };
          console.log("getUserById:::", data);

          this.setState({
            user: data,
            isLoading: false,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    console.log(event, inputIdentity);
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  render() {
    const { isImpression, isImageUploading } = this.props;
    const { viewPointHeight, dataForm, isLoading, user } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated  ${
          isImpression ? "show-me" : "hide-me"
        }`}
        style={{ zIndex: "9999999" }}
      >
        <div
          className={`coo_modal-body-content cco__impression_modal-wrapper full-height d-flex flex-column animate__animated ${
            isImpression ? "animate__fadeInRight" : "animate__fadeOutLeft"
          }`}
        >
          <div
            className={"d-flex flex-row justify-content-between"}
            style={{ marginBottom: "12px" }}
          >
            <div>
              <ProfileTitle title="Impression" />
              <div className={"cco__imw-subheader"}>No of Recorded : 103</div>
            </div>
            <div
              className={`close-modal-wrapper`}
              onClick={() => {
                this.props.onClickCloseImpression();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <Scrollbars
            style={{ height: viewPointHeight - 110 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className="cco_payment-modal-body-wrapper">
              <ImpressionCard
                imgUrl={require("../../../assets/img/Rectangle 1664.png")}
                merchantName={"Merchant Name"}
                companyName={"Hotel and Restaurant"}
                bankName={"Sampath bank"}
                impressionId={"123121212121212121212"}
                offerId={"123121212121212121212"}
              />
            </div>
          </Scrollbars>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isImpression: state.impressionReducer.isImpression,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseImpression: () =>
      dispatch({
        type: actionTypes.CLOSE_IMPRESSION_MODAL,
        status: false,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
