import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { OpenNotification } from "../../../../config/notification";
import * as xlsx from "xlsx";

import { Button, FileUploadCard } from "../../../../components";
import { GetIcon } from "../../../../config/icon";
import { cooCreateNewCategory } from "../service/category.service";

class upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      tags: [],
      tag: null,
      isLoading: false,
      isImageUploading: false,
      uploadFils: [],
      dataForm: {
        logoURL: {
          key: "logoURL",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : https://picsum.photos/200",
            disabled: false,
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async clearAll() {
    this.setState({
      tags: [],
      tag: null,
      isLoading: false,
    });

    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      console.log(updateForm[key]);
      updateForm[key].value = "";
      this.setState({ dataForm: updateForm });
    }
  }
  //ONCLICK SAVE DATA
  async onClickSaveData() {
    console.log("event fire");
    let dataForm = this.state.dataForm;
    let state = this.state;

    if (state.uploadFils.length > 0) {
      this.setState({ isLoading: true });
      for (let idx in state.uploadFils) {
        let filesList = [...state.uploadFils];

        let res = await cooCreateNewCategory(filesList[idx]);
        console.log("response-->", { res });

        if (res && res.data && res.data.status) {
          this.setState({ isLoading: false });
          OpenNotification(
            "success",
            res && res.data && res.data.message,
            "success"
          );
          await this.clearAll();
        } else {
          OpenNotification("error", res && res.data && res.data.error, "error");
          this.setState({ isLoading: false });
          await this.clearAll();
        }
      }
      this.setState({ uploadFils: [] });
    } else {
      OpenNotification("Error", "Invalid data", "error");
      this.setState({ isLoading: false });
    }
  }

  readUploadFile = (e) => {
    e.preventDefault();
    // if (e.target.files) {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      console.log("upload:::", json);
      this.setState({ isImageUploading: true });
      for (let idx in json) {
        if (json && json[idx] && json[idx].categoryName) {
          let body = {
            categoryName: json[idx].categoryName,
            tags: [],
            iconName: json[idx].iconName,
            status: "ACTIVE",
          };
          this.state.uploadFils.push(body);
        }
      }
      const updateForm = { ...this.state.dataForm };
      updateForm["logoURL"].value = "Uploaded";
      this.setState({
        isImageUploading: false,
      });
      this.setState({
        dataForm: updateForm,
      });
    };
    reader.readAsArrayBuffer(files[0]);
    reader.readAsDataURL(files[0]);
    // }
  };

  render() {
    const { dataForm, isLoading, isImageUploading, crop, src } = this.state;
    return (
      <>
        <div className="cco__cnc-upload-content-wrapper">
          <div className="group-form-upload">
            {/*----- image crop section wrapper ------*/}
            <FileUploadCard
              title={"Upload Bank details"}
              onSelectFile={this.readUploadFile.bind(this)}
              isImageUploading={isImageUploading}
            />
            {dataForm.logoURL.value && (
              <div className="uploaded-wrapper">Uploaded!</div>
            )}
          </div>
          <div
            className={"login-footer-action-wrapper footer-button pl-4 pr-4"}
          >
            <Button
              leftIcon={false}
              rightIcon={false}
              isLoading={isLoading || isImageUploading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={async () => {
                await this.onClickSaveData();
              }}
              text={"Done"}
              size={"md"}
              type={"btn-block"}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(upload);
