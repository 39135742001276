import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isEditBank: false,
  selectBankData: [],
  refreshBank: false,
  bankData:[],
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  //console.log('BankReducers', payload);
  console.log("bankReducers", payload);
  switch (type) {
    case actionTypes.EDIT_BANK:
      draft.isEditBank = true;
      draft.selectBankData = payload;
      return draft;
    case actionTypes.REFRESH_BANK_LIST:
      draft.refreshBank = true;
      return draft;
    case actionTypes.DONE_BANK_UPDATE:
      draft.isEditBank = false;
      draft.selectBankData = [];
      draft.refreshBank = false;
      return draft;
    case actionTypes.SET_BANK_DATA:
      draft.bankData = payload;
      return draft;
    default:
      return draft;
  }
});
