import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div className="cco_modal-title-wrapper d-flex">
      <div className="cco_mtw-title">{props.title}</div>
    </div>
  </>
);
export default Index;
