import React, { useEffect, useState } from "react";
import "./styles.scss";

import { GetIcon } from "../../../../config/icon";
import { Greeting, MenuCard } from "../../../../components";

import { getToken, userDetails, userLogOut } from "../../../../utils";
import * as actionTypes from "../../../../store/actions/types";
import { getUserById } from "../../../../api";
import { connect, useDispatch } from "react-redux";
import { RiLogoutCircleRLine } from "react-icons/ri";
import Scrollbars from "react-custom-scrollbars";
import { TbSeo } from "react-icons/tb";

const Index = (props) => {
  const [state, setState] = useState({ userObject: {} });
  const [viewPointWidth, setViewPointWidth] = useState(0);
  const [viewPointHeight, setViewPointHeight] = useState(0);
  const dispatch = useDispatch();

  // scrollbars start
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("scroll", handleOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setViewPointWidth(window.innerWidth);
    setViewPointHeight(window.innerHeight);
  };

  const handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  useEffect(() => {
    async function fetchMyAPI() {
      //let response =  getToken();
      let response = await userDetails();
      console.log("response", response);
      setState({ userObject: response });
      let resUser = await getUserById(response?.userId);
      console.log("getUserById::", resUser);
      props.setUserDetails(resUser?.data);
    }

    fetchMyAPI();
    console.log("getToken");
    return () => {};
  }, []);

  return (
    <>
      <div className={`cco__left-menu-container ${props.option}`}>
        <div className={"cco__logo-wrapper"}>
          <img
            className={"cco__logo-svg"}
            width={"180"}
            onClick={() => props.onClickGoPage("dashboard")}
            src={require("../../../../assets/img/logo/logo_24.svg")}
            alt="credit card offers"
          />
        </div>

        {/*---- profile details goes here ------*/}
        <div className={"profile-logo-wrapper"}>
          <div className={"profile-avatar-wrapper"}>
            <img src={state.userObject.profileAvatar} alt={"user-profile"} />
          </div>
          <div className={"profile-name"}>Hi, {state.userObject.firstName}</div>
          <div className={"user-type"}>{state.userObject.userType}</div>
          <div
            className={"user-logout"}
            onClick={async (e) => {
              let userState = await userLogOut(e);
              window.location.replace("/");
              if (userState) {
                // dispatch(clearUserDetails());
                window.location.reload();
              }
            }}
          >
            <RiLogoutCircleRLine />
            <>Logout</>
          </div>
        </div>

        <Scrollbars
          onScroll={handleOnScroll}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
          style={{
            height: viewPointHeight - 330,
          }}
        >
          <div className={"cco_menu-wrapper"}>
            <MenuCard
              icon={GetIcon("dashboard")}
              onClick={() => props.onClickGoPage("dashboard")}
              isActive={props.pageName === "/"}
              size={"md"}
              text={"Dashboard"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("bank")}
              isActive={props.pageName === "/bank"}
              icon={GetIcon("bank")}
              size={"md"}
              text={"Bank"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("category")}
              isActive={props.pageName === "/category"}
              icon={GetIcon("category")}
              size={"md"}
              text={"Category"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("merchant")}
              isActive={props.pageName === "/merchant"}
              icon={GetIcon("merchant")}
              size={"md"}
              text={"Merchant"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("offers")}
              isActive={props.pageName === "/offers"}
              icon={GetIcon("offer")}
              size={"md"}
              text={"Offers"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("users")}
              isActive={props.pageName === "/users"}
              icon={GetIcon("user")}
              size={"md"}
              text={"Users"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("privilege")}
              isActive={props.pageName === "/privilege"}
              icon={GetIcon("privilege")}
              size={"md"}
              text={"Users Privilege"}
            />
            <MenuCard
              onClick={() => props.onClickGoPage("media")}
              isActive={props.pageName === "/media"}
              icon={GetIcon("media")}
              size={"md"}
              text={"Media"}
            />
            <MenuCard
              onClick={() => props.onClickGoPage("advertisement")}
              isActive={props.pageName === "/advertisement"}
              icon={GetIcon("advertisement")}
              size={"md"}
              text={"Advertisement"}
            />

            <MenuCard
              onClick={() => props.onClickGoPage("advertisement-test")}
              isActive={props.pageName === "/advertisement-test"}
              icon={GetIcon("advertisement")}
              size={"md"}
              text={"Advertisement Test"}
            />
            <MenuCard
              onClick={() => props.onClickGoPage("subscriptions")}
              isActive={props.pageName === "/subscriptions"}
              icon={GetIcon("thumbsUp")}
              size={"md"}
              text={"Subscriptions"}
            />
            <MenuCard
              onClick={() => props.onClickGoPage("insights")}
              isActive={props.pageName === "/insights"}
              icon={GetIcon("envelope")}
              size={"md"}
              text={"Insights"}
            />
            <MenuCard
              onClick={() => props.onClickGoPage("seo-mapping")}
              isActive={props.pageName === "/seo-mapping"}
              icon={<TbSeo />}
              size={"md"}
              text={"SEO mapping"}
            />
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

// Index.propTypes = {
//     accountName: PropTypes.string.isRequired,
//     noOfAccount: PropTypes.string.isRequired,
// };

Index.defaultProps = {
  accountName: "unknown",
  noOfAccount: "0",
};

const mapStateToProps = (state) => {
  return {
    isViewOffer: state.offerReducer.isViewOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (payload) =>
      dispatch({
        type: actionTypes.SET_USER_DATA,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
