import axios from "axios";
import { getToken } from "../utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createUser = async (
  firstName,
  lastName,
  contactNo,
  userType,
  accessType,
  userName,
  password,
  profileAvatar
) => {
  try {
    const body = {
      firstName: firstName,
      lastName: lastName,
      contactNo: contactNo,
      userType: userType,
      accessType: accessType,
      userName: userName,
      password: password,
      profileAvatar: profileAvatar,
    };

    console.log("body", body);

    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/admin/user`, body);
    return response;
  } catch (err) {
    console.error("ERR_CREATE_USER:", err);
    throw err;
  }
};

export const getAdminUser = async () => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/admin/users`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_BANK_API:", err);
    throw err;
  }
};

export const getUserById = async (userId) => {
  try {
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/user/by/${userId}`,
      headers
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_USER_BY_ID:", err);
    throw err;
  }
};

export const updateUser = async (
  userId,
  firstName,
  lastName,
  contactNo,
  userType,
  accessType,
  userName,
  profileAvatar
) => {
  try {
    const body = {
      firstName: firstName,
      lastName: lastName,
      contactNo: contactNo,
      userType: userType,
      accessType: accessType,
      userName: userName,
      profileAvatar: profileAvatar,
    };

    console.log("body", body);

    // Get response
    let response = await axios.put(
      `${BASE_URL}/update/admin/user/${userId}`,
      body
    );
    return response;
  } catch (err) {
    console.error("ERR_CREATE_USER:", err);
    throw err;
  }
};

export const getPrivilege = async () => {
  try {
    const options = { data: {} };
    const _token = await getToken();
    const headers = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + _token,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let response = await axios.get(`${BASE_URL}/admin/privilege/all`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_PRIVILEGE_API:", err);
    throw err;
  }
};
