import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, WebOfferCard } from "../../../components";
import { Scrollbars } from "react-custom-scrollbars";
import { Spin, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";
import { OpenNotification } from "../../../config/notification";
import { getBanksOffersCount, getCategory } from "../../../api";
import {
  getSyncSeylanOfferData,
  getSyncSeylanOffers,
  syncSeylanOffers,
  syncSeylanOffersToSync,
} from "../../../api/Seylan";
const { TabPane } = Tabs;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: "100px",
      isActiveShadow: false,
      isLoading: false,
      isLoadingSync: false,
      isLoadingOffers: false,
      isLoadingOfferData: false,
      offerData: [],
      tempOffersCount: 0,
      syncOffersCount: 0,
      selectStatus: "new",
      categoryId: null,
      isCategoryLoading: false,
      dataForm: {
        categoryName: {
          key: "categoryName",
          elementType: "input",
          elementConfig: {
            type: "select",
            placeholder: "Select category",
            options: [],
            disabled: false,
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        category: {
          key: "category",
          elementType: "select",
          elementConfig: {
            type: "select",
            placeholder: "Select category",
            options: [],
            disabled: false,
          },
          value: "",
          defaultValue: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
      tabJson: [
        {
          id: uuidv4(),
          key: "new",
          tabName: "New",
          content: "",
        },
        {
          id: uuidv4(),
          key: "approved",
          tabName: "Approved",
          content: "",
        },
      ],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async onClickSyncOffersFromBank() {
    this.setState({ isLoadingOffers: true });
    try {
      await syncSeylanOffersToSync();
      await this.getOffersCounts();
      this.setState({ isLoadingOffers: false });
    } catch (error) {
      console.error("Error fetching offers:", error);
      this.setState({ isLoadingOffers: false });
    }
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getOffersCounts();
    await this.setAllData();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async setAllData() {
    let resCategory = await getCategory();

    const updateForm = { ...this.state.dataForm };
    updateForm["category"].elementConfig.options = await this.syncCategory(
      resCategory.data
    );
    updateForm["category"].value = "";

    this.setState({
      dataForm: updateForm,
      isLoading: false,
      isLoadingSync: false,
      isLoadingOffers: false,
      isLoadingOfferData: false,
    });
  }

  async syncCategory(res) {
    let categoryList = [];
    for (let category of res) {
      categoryList.push({
        id: category.categoryId,
        value: category.categoryName,
        displayValue: category.categoryName,
        tags: category.tags,
      });
    }
    return categoryList;
  }

  async getOffersCounts() {
    let res = await getBanksOffersCount("665e0dcd489e37a8e8a86bde");
    if (res?.data?.status) {
      console.log("res", res);
      this.setState({
        tempOffersCount: res?.data?.data?.tempOffers,
        syncOffersCount: res?.data?.data?.syncOffers,
      });
    }
  }

  onClickViewOffers = async () => {
    try {
      this.setState({ isLoadingOffers: true });
      const resOffers = await getSyncSeylanOffers();
      // Check if the response contains valid offer data
      console.log("resOffers", resOffers);

      if (resOffers?.data?.status && resOffers?.data?.data) {
        this.setState({
          offerData: resOffers.data.data,
          isLoadingOffers: false,
        });
      } else {
        console.warn("No offers found");
        this.setState({ isLoadingOffers: false });
      }

      console.log("offerData", this.state.offerData);
    } catch (error) {
      console.error("Error fetching offers:", error);
      this.setState({ isLoadingOffers: false }); // Ensure the loading state is updated even in case of an error
    }
  };

  onClickSyncOffers = async () => {
    this.setState({ isLoadingSync: true });
    if (this.state.categoryId) {
      const category_name = this.state.dataForm.categoryName.value;
      console.log("onClickSyncOffers", category_name);

      let key = "_6VIozcROUfxCgdxPKuw0";
      try {
        const resOffers = await syncSeylanOffers({
          category_name,
          key,
          categoryId: this.state.categoryId,
        });
        console.log("resOffers", resOffers?.data);
        if (resOffers?.status) {
          await this.getOffersCounts();
          OpenNotification(
            "success",
            resOffers && resOffers.data && resOffers.data.message,
            "success"
          );
        } else {
          OpenNotification(
            "error",
            resOffers && resOffers.data && resOffers.data.message,
            "error"
          );
        }
      } catch (error) {
        console.error("Error fetching offers:", error);
        this.setState({ isLoadingSync: false });
      }
    } else {
      console.error("Category ID is required");
      OpenNotification(
        "error",
        "This category is not available in the system",
        "error"
      );
    }
    this.setState({ isLoadingSync: false });
  };

  inputHandleChange = (event, inputIdentity) => {
    let val = null;
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    //updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    updatedFormElement.value =
      inputIdentity === "categoryName" ? event.target.value : event;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  onClickRefresh = async () => {
    await this.onClickViewOffers();
    this.setState({
      isLoadingSync: false,
      isLoadingOffers: false,
      isLoading: false,
      dataForm: {
        ...this.state.dataForm,
        categoryName: {
          ...this.state.dataForm.categoryName,
          value: "",
        },
        category: {
          ...this.state.dataForm.category,
          value: "",
        },
      },
    });
  };

  handleChange = (tabName) => {
    this.setState({
      selectStatus: tabName,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshSyncOffers !== this.props.refreshSyncOffers) {
      if (this.props.refreshSyncOffers) {
        await this.onClickViewOffers();
      }
    }
  }

  render() {
    const {
      viewPointHeight,
      offerData,
      isLoading,
      dataForm,
      isCategoryLoading,
      tabJson,
      selectStatus,
      categoryId,
    } = this.state;
    const { editOfferData } = this.props;

    return (
      <>
        <div>
          <div className={"sync-offer-view-wrapper d-flex p-8"}>
            <div
              className={
                "ndb-get-offer-view- d-flex align-items-center justify-content-between mr-3"
              }
            >
              <div style={{ width: "100%", gap: "10px" }} className={"d-flex"}>
                <Input
                  elementConfig={dataForm.categoryName.elementConfig}
                  elementType={dataForm.categoryName.elementType}
                  required={dataForm.categoryName.validation.required}
                  isValid={!dataForm.categoryName.valid}
                  touched={dataForm.categoryName.touched}
                  value={dataForm.categoryName.value}
                  defaultValue={null}
                  label={dataForm.categoryName.label}
                  isShowRequired={false}
                  width={"100%"}
                  size={"lg"}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.categoryName.key);
                  }}
                />

                <Input
                  elementConfig={dataForm.category.elementConfig}
                  elementType={dataForm.category.elementType}
                  required={dataForm.category.validation.required}
                  isValid={!dataForm.category.valid}
                  touched={dataForm.category.touched}
                  value={dataForm.category.value}
                  defaultValue={null}
                  label={dataForm.category.label}
                  isShowRequired={false}
                  size={"lg"}
                  isLoading={isCategoryLoading}
                  disabled={
                    !dataForm?.categoryName?.value && offerData?.length === 0
                  }
                  margin={""}
                  width={"100%"}
                  options={[]}
                  changed={async (event) => {
                    this.inputHandleChange(event, dataForm.category.key);
                    let value = event;

                    let categoryId =
                      dataForm.category.elementConfig.options.find(
                        (cat) => cat.value === value
                      )?.id;

                    console.log({ categoryId });
                    await this.setState({
                      categoryId: categoryId,
                    });
                  }}
                  // changed={(event) =>
                  //   this.inputHandleChange(event, dataForm.categoryName.key)
                  // }
                />
              </div>
              <div className={"ml-2"} style={{ width: "20%" }}>
                <Button
                  text={"Sync offers"}
                  type={"btn-edit  sm"}
                  disabled={dataForm.categoryName.value ? false : true}
                  size={"lg secondary"}
                  isLoading={this.state.isLoadingSync}
                  onClick={() => this.onClickSyncOffers()}
                />
              </div>
            </div>
            <Button
              text={"Sync Temp to offer (" + this.state.tempOffersCount + ")"}
              type={"btn-edit sm"}
              size={"lg secondary"}
              isLoading={this.state.isLoading || this.state.isLoadingOffers}
              onClick={() => this.onClickSyncOffersFromBank()}
            />

            <Button
              text={"View offers (" + this.state.syncOffersCount + ")"}
              type={"btn-edit sm"}
              size={"lg secondary"}
              disabled={this.state.isLoadingOffers}
              onClick={() => this.onClickViewOffers()}
            />
            <Button
              text={"Refresh"}
              type={"btn-edit sm"}
              size={"lg secondary"}
              disabled={this.state.isLoadingOffers}
              onClick={() => this.onClickRefresh()}
            />
          </div>
        </div>

        <div className={"col-md-12 col-sm-6"}>
          {this.state.isLoading && (
            <div
              style={{
                top: "30%",
                left: "50%",
                position: "absolute",
                zIndex: 1000,
              }}
            >
              <Spin size="large" spinning={isLoading} />
            </div>
          )}
          {!isLoading && offerData?.length > 0 && (
            <Scrollbars
              style={{ height: viewPointHeight - 290 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <Tabs onChange={this.handleChange}>
                {((tabJson && tabJson) || []).map((i, t) => (
                  <TabPane tab={i.tabName} key={i.key}></TabPane>
                ))}
              </Tabs>
              <div className={"container mt-3"}>
                {(offerData || [])
                  .filter((item) => {
                    if (selectStatus === "" && categoryId === "") {
                      return item;
                    } else if (categoryId && item.categoryId === categoryId) {
                      if (
                        item.status?.toLowerCase() ===
                        selectStatus.toLowerCase()
                      ) {
                        return item;
                      }
                    } else if (
                      !categoryId &&
                      item.status?.toLowerCase() === selectStatus.toLowerCase()
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <>
                      <WebOfferCard
                        key={index}
                        id={`${item.offerId}`}
                        animate={"animate__fadeIn"}
                        isFavorites={false}
                        isLoading={isLoading}
                        onClick={async () => {
                          this.setState({ isLoading: true });
                          if (item?.isSynced) {
                            this.props.onClickOpenOfferView(item);
                          } else {
                            let result = await getSyncSeylanOfferData(
                              item.offerId
                            );
                            console.log("result", result);
                            if (result?.data?.status && result?.data?.status) {
                              let offer = result.data.data;
                              this.props.onClickOpenOfferView(offer);
                            }
                          }
                          this.setState({ isLoading: false });
                          // this.props.onClickOpenOfferView(item);
                        }}
                        categoryData={item?.category}
                        MerchantData={item?.merchant}
                        bankData={item?.bank}
                        data={item}
                      />
                    </>
                  ))}
              </div>
            </Scrollbars>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
  refreshSyncOffers: state.offerReducer.refreshSyncOffers,
  editOfferData: state.offerReducer.editOfferData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    onClickClearSelected: () =>
      dispatch({ type: actionTypes.CLEAR_OFFER_DETAILS }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),

    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),

    doneRefresh: () =>
      dispatch({
        type: actionTypes.DONE_REFRESH_OFFERS,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
