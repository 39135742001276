import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import uuid from "react-uuid";
import * as actionTypes from "../../../store/actions/types";
import { Button, Input, SelectBox } from "../../../components";
import { GetIcon } from "../../../config/icon";
import Scrollbars from "react-custom-scrollbars";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { updateOfferStatus, updateSyncOffer } from "../../../api";
import { cooUpdateMerchantLocations } from "../../merchant/service/merchant.service";
import { updateDraft } from "../../../api/draft";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      validLocation: [],
      tag: null,
      merchantData: null,
      selectedOffer: null,
      dataForm: {
        keywords: {
          key: "keywords",
          elementType: "hasTag",
          elementConfig: {
            type: "text",
            placeholder: "Ex : Colombo",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Location Name",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
    if (this.props.isEditLocation) {
      this.initializeMerchantData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  initializeMerchantData() {
    this.setState({
      merchantData: null,
      validLocation: [],
    });

    const selectedMerchant = this.props.editMerchant;
    console.log("{ editMerchant }", this.props.editMerchant);

    if (selectedMerchant?.merchantId) {
      console.log({ selectedMerchant });
      const validLocation =
        selectedMerchant?.location?.length > 0
          ? selectedMerchant?.location.map((location) => ({
              id: location.id,
              tagName: location.tagName || location.value,
              count: location.count,
              isActive: location.isActive,
            }))
          : [];

      this.setState({ merchantData: selectedMerchant, validLocation });
    }
    if (this.props.selectOffer) {
      const selectOffer = this.props.selectOffer;

      console.log({ selectOffer });
      if (selectOffer?.offerId) {
        this.setState({ selectedOffer: selectOffer });
      }
    }
  }

  //=== Check form validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  }

  updateFormValidity = (inputIdentity) => {
    const updateSignUpForm = this.state.dataForm;
    const updatedFormElement = updateSignUpForm[inputIdentity];
    console.log(updatedFormElement);
    // updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    this.setState({ dataForm: updateSignUpForm });
  };

  //==== Input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    if (inputIdentity === "keywords") {
      let _event = event && event.target ? event.target.value : event;
      this.setState({
        tag: _event,
      });
    }
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  updateMerchantLocations = async () => {
    this.setState({ isLoading: true });
    let body = {
      location: this.state.validLocation,
    };

    let merchantId =
      this.props.selectMerchant?.merchantId ||
      this.props.editMerchant?.merchantId;

    let res = await cooUpdateMerchantLocations(body, merchantId);
    console.log("cooUpdateMerchantLocations", res);
    if (res && res.data && res.data.status) {
      let merchant = res.data.data;
      console.log("select merchant", this.props.editMerchant);

      if (this.props.selectOffer?.userId === "auto") {
        let offerBody = {
          ...this.props.selectOffer,
          location: merchant?.location?.map((location) => {
            return {
              id: location.id,
              value: location.tagName || location.value,
              count: location.count,
              isActive: true,
            };
          }),
        };

        let resOffer = await updateSyncOffer(
          offerBody,
          this.props.selectOffer?.offerId
        );
        this.props.closeOfferViewModal();
        console.log(resOffer);
        this.props.onClickOpenOfferView(resOffer?.data?.data);
        this.props.refreshSyncOffers();
      } else {
        this.props.onClickSelectedMerchant(merchant);
        this.props.onClickSelectedMerchantLocations({
          selectOffer: this.props.selectedOfferData || this.props.editOfferData,
          selectedMerchant: merchant,
          selectLocations: merchant.location,
        });
        if (this.props.editOfferData) {
          this.props.onClickSelectOffer({
            ...this.props.editOfferData,
            location: merchant.location,
          });
        } else {
          this.props.setCreateOfferData({
            selectOffer: this.props.selectedOfferData,
            selectedMerchant: merchant,
            selectedBank: this.props.selectedBank,
            selectedCategory: this.props.selectedCategory,
            selectLocations: merchant.location,
            selectTerms: this.props.selectTerms,
          });
          // this.props.onClickSelectOffer({
          //   ...this.props.selectedOfferData,
          //   location: merchant.location,
          // });
          if (
            this.props.selectedOfferData &&
            this.props.selectedOfferData?.draftId
          ) {
            let resDraft = await updateDraft(
              {
                ...this.props.selectedOfferData,
                location: merchant.location,
              },
              this.props.selectedOfferData?.draftId
            );
            console.log({ resDraft });
            this.props.refreshOffers();
          }
        }
      }
      this.setState({ isLoading: false });
      this.props.closeEditModal();
    } else {
      this.setState({ isLoading: false });
    }
  };

  //onClick add #validLocation
  onClickAddCardType = () => {
    this.setState((state) => {
      if (state.validLocation) {
        const validLocation =
          this.props.editMerchant?.status === "auto" ||
          this.props.selectOffer?.userId === "auto"
            ? state.validLocation.concat({
                id: uuid(),
                value: state.tag,
                count: 0,
                isActive: true,
              })
            : state.validLocation.concat({
                id: uuid(),
                tagName: state.tag,
                count: 0,
                isActive: false,
              });
        return {
          validLocation,
          tag: null,
        };
      }
    });
    const updateForm = { ...this.state.dataForm };
    updateForm["keywords"].value = "";
    this.setState({ dataForm: updateForm });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.onClickAddCardType();
    }
  };

  onRemoveCards = (i) => {
    this.setState((state) => {
      const validLocation = state.validLocation.filter((item, j) => i !== j);
      return {
        validLocation,
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editMerchant !== this.props.editMerchant) {
      this.setState({
        merchantData: null,
        validLocation: [],
      });
      const selectedMerchant = this.props.editMerchant;
      console.log("{ editMerchant }", this.props.editMerchant);

      if (selectedMerchant?.merchantId) {
        console.log({ selectedMerchant });
        const validLocation =
          selectedMerchant?.location?.length > 0
            ? selectedMerchant?.location.map((location) => ({
                id: location.id,
                tagName: location.tagName || location.value,
                count: location.count,
                isActive: location.isActive,
              }))
            : [];

        this.setState({ merchantData: selectedMerchant, validLocation });
      } else {
        this.setState({
          merchantData: null,
          validLocation: [],
        });
      }
    }
    if (prevProps.selectOffer !== this.props.selectOffer) {
      const selectOffer = this.props.selectOffer;

      console.log({ selectOffer });
      if (selectOffer?.offerId) {
        this.setState({ selectedOffer: selectOffer });
      }
    }
  }

  render() {
    const { isEditLocation } = this.props;
    const { viewPointHeight, dataForm, validLocation, isLoading } = this.state;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isEditLocation ? "show-me" : "hide-me"
        } `}
      >
        <div
          className={"coo_modal-body-content fix-400"}
          style={{
            zIndex: "999999",
            top: "9%",
            padding: "24px",
            borderRadius: "32px",
          }}
        >
          <div
            className={
              "d-flex flex-row justify-content-between align-content-center align-items-center"
            }
          >
            <div className={"coo_mbc__reject-header"}>{"Edit locations"}</div>

            <div
              className={`close-modal-wrapper `}
              style={{ position: "inherit" }}
              onClick={() => {
                this.props.closeEditModal();
              }}
            >
              <RiCloseLine />
            </div>
          </div>

          <div className={"coo_modal-content-wrapper d-flex flex-column mt-2"}>
            <Input
              elementConfig={dataForm.keywords.elementConfig}
              elementType={dataForm.keywords.elementType}
              required={dataForm.keywords.validation.required}
              isinvalid={!dataForm.keywords.valid}
              touched={dataForm.keywords.touched}
              value={dataForm.keywords.value}
              isShowRequired={true}
              size={"md"}
              margin={"m-b-2"}
              label={dataForm.keywords.label}
              onKeyPress={this.handleKeyPress.bind(this)}
              onClickAddTag={this.onClickAddCardType.bind(this)}
              changed={(event) =>
                this.inputHandleChange(event, dataForm.keywords.key)
              }
            />

            {validLocation && (
              <>
                <Scrollbars
                  style={{ height: 100 }}
                  thumbMinSize={30}
                  universal={true}
                  autoHide
                >
                  <div className={"multiple-line-wrapper mb-2"}>
                    <div className={"tag-inline-wrapper d-flex flex-wrap"}>
                      {(validLocation || []).map((item, index) => (
                        <SelectBox
                          index={index}
                          remove={false}
                          isSelect={true}
                          select={true}
                          onClick={() => this.onRemoveCards(index)}
                          onRemove={() => this.onRemoveCards(index)}
                          format={"text"}
                          text={item.tagName || item.value}
                        />
                      ))}
                    </div>
                  </div>
                </Scrollbars>
              </>
            )}
          </div>
          <div style={{ marginTop: "24px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"mb-2"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              onClick={async () => {
                await this.updateMerchantLocations();
              }}
              LeftIconText={"Update"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
            <Button
              leftIcon={false}
              rightIcon={false}
              option={""}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              onClick={() => {
                this.props.closeEditModal();
              }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditLocation: state.offerReducer.isEditLocation,
    editMerchant: state.offerReducer.editMerchant,
    selectedOfferData: state.offerReducer.selectedOfferData,
    selectMerchant: state.merchantReducer.selectMerchant,
    selectedMerchant: state.offerReducer.selectMerchant,
    selectedBank: state.offerReducer.selectedBank,
    selectedCategory: state.offerReducer.selectedCategory,
    selectLocations: state.offerReducer.selectLocations,
    selectTerms: state.offerReducer.selectTerms,
    editOfferData: state.offerReducer.editOfferData,
    selectOffer: state.offerReducer.selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenOfferView: (payload) =>
      dispatch({
        type: actionTypes.OPEN_SYNC_OFFER_VIEW_MODAL,
        payload: payload,
      }),
    closeEditModal: () =>
      dispatch({ type: actionTypes.EDIT_MERCHANT_LOCATION_CLOSE }),
    onClickSelectedMerchant: (payload) =>
      dispatch({ type: actionTypes.SELECTED_SEARCH_MERCHANT_MODAL, payload }),
    onClickSelectedMerchantLocations: (payload) =>
      dispatch({ type: actionTypes.UPDATE_SELECTED_LOCATIONS, payload }),
    onClickSelectOffer: (data) =>
      dispatch({ type: actionTypes.VIEW_OFFER_DETAILS, payload: data }),
    setCreateOfferData: (data) =>
      dispatch({ type: actionTypes.CREATE_OFFER_DETAILS, payload: data }),
    closeOfferViewModal: () =>
      dispatch({
        type: actionTypes.CLOSE_SYNC_OFFER_VIEW_MODAL,
        status: false,
      }),
    refreshOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_DRAFTS_LIST,
      }),
    refreshSyncOffers: () =>
      dispatch({
        type: actionTypes.REFRESH_SYNC_OFFERS_LIST,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
