import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isEditCategory: false,
  selectCategoryData: [],
  refreshCategory: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  //console.log('categoryReducers', payload);
  console.log("categoryReducers", payload);
  switch (type) {
    case actionTypes.EDIT_CATEGORY:
      draft.isEditCategory = true;
      draft.selectCategoryData = payload;
      return draft;
    case actionTypes.DONE_CATEGORY_UPDATE:
      draft.isEditCategory = false;
      draft.selectCategoryData = [];
      return draft;
    case actionTypes.REFRESH_CATEGORY_LIST:
      draft.refreshCategory = true;
      return draft;
    case actionTypes.DONE_REFRESH_CATEGORY:
      draft.refreshCategory = false;
      return draft;
    default:
      return draft;
  }
});
