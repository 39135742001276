import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import moment from "moment";
import { Button } from "../..";

const Index = (props) => (
  <>
    <div className={`cco__ph__wrapper ${props.option}`}>
      <div className={"cco__ph__left-wrapper d-flex"}>
        <div className={"cco__ph__title"}>{props.title}</div>
      </div>

      {props.isRefresh && (
        <>
          {/* <div className={"cco__ph__refresh-btn-wrapper"}>
            <div className={`btn-refresh-btn`} onClick={props.onClickRefresh}>
              Refresh
            </div>
          </div> */}
          <Button
            text={"Refresh"}
            type={"btn-edit"}
            size={"lg secondary"}
            isLoading={props.isLoading}
            onClick={props.onClickRefresh}
          />
        </>
      )}
      {props.isDate && (
        <>
          <div className={"cco__ph__date-wrapper"}>
            <div className={`cco__ph__date`}>
              {moment().format("DD-MMM-YYYY")}
            </div>
          </div>
        </>
      )}
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
