import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { FileUpload, Input, Button } from "../..";
import { RiCheckLine } from "react-icons/ri";
import { GetIcon } from "../../../config/icon";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        comment: {
          label: "Comment",
          key: "Comment",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "",
          },
          value: "",
          validation: {
            required: true,
          },
          isValid: false,
          touched: false,
          invalidReason: "",
        },
      },
    };
  }
  render() {
    const {
      isLoading,
      isSelect,
      viewPointHeight,
      selectData,
      dataForm,
      isImageUploading,
    } = this.state;

    return (
      <div className="cco_payment-modal-body-wrapper">
        <div className="cco_pmw-header">Payment Details</div>
        <div className="cco_pmw-profileInfo-wrapper">
          <div className="cco_pmw-pw-image">
            <img
              src={require("../../../assets/img/Ellipse 1.png")}
              alt={"Profile Picture"}
            />
          </div>
          {/* profile details */}
          <div className="cco_pmw-pw-details-wrapper">
            <div className="cco_pmw-pw-userName">Damith premakumara</div>
            <div className="cco_pmw-pw-userMail">
              {"dpremakumara@gmail.com"}
            </div>
            <div className="cco_pmw-pw-userType">Admin</div>
          </div>
        </div>
        {/* invoice date */}
        <div className="cco_pmw-invoice-wrapper">
          <div className="cco_pmw-invoice">Invoice date</div>
          <div className="cco_pmw-iDate">26/Jul/2024</div>
        </div>
        {/* tags */}
        <div className="cco_pmw_box-wrapper">
          <div className="cco_pmw-bw-tags-Wrapper">
            <div className="cco_pmw-tw-title">Description</div>
            <div className="cco_pmw-tw-details">Add Offers</div>
          </div>
          <div className="cco_pmw-bw-tags-Wrapper">
            <div className="cco_pmw-tw-title">QTY</div>
            <div className="cco_pmw-tw-details">120 (x5)</div>
          </div>
          <div className="cco_pmw-bw-tags-Wrapper">
            <div className="cco_pmw-tw-title">Total</div>
            <div className="cco_pmw-tw-details">LKR 600.00</div>
          </div>
        </div>
        <div className="cco_pmw-comment-wrapper">
          <Input
            elementConfig={dataForm.comment.elementConfig}
            elementType={dataForm.comment.elementType}
            required={dataForm.comment.validation.required}
            isValid={!dataForm.comment.isValid}
            touched={dataForm.comment.touched}
            value={dataForm.comment.value}
            props={dataForm.comment}
            isShowRequired={false}
            size={"sm"}
            margin={"m-b-2"}
            label={dataForm.comment.label}
            changed={(event) =>
              this.inputHandleChange(event, dataForm.comment.key)
            }
          />
        </div>
        <div className="cco_pmw-fileUpload-wrapper">
          <FileUpload
            title={"Upload receipt"}
            isImageUploading={isImageUploading}
          />
        </div>
        <div className={"cco_payment-modal-footer-action-wrapper"}>
          <div style={{ width: "130px" }}>
            <Button
              leftIcon={false}
              rightIcon={false}
              option={"mr-1"}
              isLoading={false}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("leftArrow")}
              // onClick={() => {
              //   this.props.closeRejectOfferModal();
              // }}
              text={"Cancel"}
              size={"md"}
              type={"btn-block-back"}
            />
          </div>
          <div style={{ width: "158px" }}>
            <Button
              leftIconFill={true}
              iconName={<RiCheckLine />}
              leftIcon={false}
              rightIcon={false}
              option={"ml-1"}
              isLoading={isLoading}
              isActiveAnimation={false}
              reactRightIcon={GetIcon("")}
              // onClick={async () => {
              //   await this.onClickReject();
              // }}
              LeftIconText={"Done"}
              text={""}
              size={"md"}
              type={"btn-block"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
